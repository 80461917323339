import { StyleSheet } from 'react-native';
import { Fonts, Metrics, Colors } from 'themes';

export default StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    label: {
        fontFamily: Fonts.type.base,
        fontWeight: '300',
        fontSize: Metrics.normalize(13),
        color: Colors.grayTextColor,
        marginBottom: 3,
    },
    value: {
        fontFamily: Fonts.type.base,
        fontWeight: '500',
        fontSize: Metrics.normalize(15),
        color: Colors.darkTextColor,
    },
});

import React from 'react';
import { TouchableOpacity, View, Image, Text } from 'react-native';
import Voca from 'voca';

import AddressPickerStyles from './styles/AddressPickerStyles';
import { Images } from '../../themes';

interface Props {
    item: any;
    index: number;
    suggestionSelected: Function;
}

const MapLandingSuggestion = ({ item, index, suggestionSelected }: Props) => {
    const title = item.structured_formatting.main_text;
    const subTitle = item.structured_formatting.secondary_text;
    const icon = Images.mapLanding.suggestion;
    return (
        <TouchableOpacity
            onPress={() => suggestionSelected(item)}
            key={`${title} buttonView ${index}`}
            testID={`item_suggestion_${index}`}
        >
            <View
                key={`${title} view ${index}`}
                style={AddressPickerStyles.suggestionItemContainer}
            >
                <View
                    key={`${title} iconView ${index}`}
                    style={AddressPickerStyles.suggestionsItemLeftContainer}
                >
                    <View style={AddressPickerStyles.suggestionIconView}>
                        <Image
                            key={`${title} image ${index}`}
                            source={icon}
                            style={AddressPickerStyles.suggestionIconWitoutBackground}
                            resizeMode="contain"
                        />
                    </View>
                </View>
                <View style={AddressPickerStyles.textView} key={`${title} innerView ${index}`}>
                    <Text
                        key={`${title} title ${index}`}
                        allowFontScaling={false}
                        style={AddressPickerStyles.suggestionTitle}
                    >
                        {title}
                    </Text>
                    <Text
                        key={`${title} subtitle ${index}`}
                        allowFontScaling={false}
                        style={AddressPickerStyles.suggestionDescription}
                    >
                        {subTitle}
                    </Text>
                </View>
            </View>
        </TouchableOpacity>
    );
};

export default MapLandingSuggestion;

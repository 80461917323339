import { StyleSheet } from 'react-native';
import { Fonts, Metrics, Colors } from 'themes';

export default StyleSheet.create({
    container: {
        backgroundColor: Colors.grayBackgroundColor,
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        justifyContent: 'flex-end',
    },
    rowContainer: {
        flexDirection: 'row',
        height: 70,
        alignItems: 'center',
        marginBottom: 10,
        paddingHorizontal: 16,
        backgroundColor: 'white',
    },
    logo: {
        height: 50,
        width: 50,
        borderRadius: 25,
    },
    detailsView: {
        marginHorizontal: 10,
    },
    nameText: {
        fontFamily: Fonts.type.base,
        fontSize: 18,
        fontWeight: '500',
        color: Colors.black,
        marginBottom: 4,
    },
    detailText: {
        fontFamily: Fonts.type.base,
        fontSize: 15,
        fontWeight: '400',
        color: Colors.detailsGrayColor,
        marginBottom: 4,
    },
    headerText: {
        fontFamily: Fonts.type.base,
        fontSize: 14,
        fontWeight: '700',
        color: Colors.black,
        marginHorizontal: 20,
        marginVertical: 10,
    },
    descriptionText: {
        fontFamily: Fonts.type.base,
        fontSize: 10,
        fontWeight: '400',
        color: Colors.grayTextColor,
        marginBottom: 8,
    },
    valueRow: {
        marginVertical: 8,
        flexDirection: 'row',
        alignItems: 'center',
    },
    valueTitleText: {
        fontFamily: Fonts.type.base,
        fontSize: 15,
        fontWeight: '700',
        color: Colors.darkTextColor,
    },
    valueSmallTitleText: {
        fontFamily: Fonts.type.base,
        fontSize: 13,
        fontWeight: '400',
        color: Colors.darkTextColor,
    },
    valueCurrencyText: {
        color: Colors.grayTextColor,
    },
    valueText: {
        fontFamily: Fonts.type.base,
        fontSize: 19,
        fontWeight: '600',
        color: Colors.obiTeal,
    },
    valueSmallText: {
        fontFamily: Fonts.type.base,
        fontSize: 15,
        fontWeight: '400',
        color: Colors.darkTextColor,
    },
    disclaimerText: {
        fontFamily: Fonts.type.base,
        fontSize: 13,
        fontWeight: '400',
        color: Colors.grayTextColor,
        margin: 16,
        textAlign: 'justify',
    },
    deeplinkText: {
        fontFamily: Fonts.type.base,
        fontWeight: '700',
        fontSize: 13,
        opacity: 0.9,
        // marginTop: 6,
        color: Colors.darkTextColor,
    },
    icon: {
        height: 25,
        width: 30,
        marginRight: 10,
    },
    separator: {
        backgroundColor: Colors.detailsGrayColor,
        height: 1,
        opacity: 0.2,
    },
    shadowView: {
        paddingHorizontal: 16,
        paddingVertical: 8,
        backgroundColor: 'white',
        marginHorizontal: 6,
        borderRadius: 12,
    },
    handleDeeplinkButton: {
        alignSelf: 'center',
    },
    handleDeeplinkButtonContainer: {
        flexDirection: 'row',
        marginTop: 15,
        marginBottom: 15,
        alignItems: 'center',
        justifyContent: 'center',
    },
    handleDeeplinkButtonImage: {
        marginLeft: 7,
        width: 10,
        height: 10,
        // marginTop: 3,
    },
    empty: {
        flex: 1,
    },
    scrollView: {
        marginBottom: 10,
    },
    touchableContainer: {
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    cardIconContainer: {
        // shadowColor: 'black',
        // shadowRadius: 3,
        // shadowOffset: {
        //     height: 2,
        //     width: 2,
        // },
        // shadowOpacity: 0.1,
        borderRadius: 3,
        padding: 3,
        backgroundColor: 'white',
        marginRight: 8,
        flex: 0.5,
    },
    cardDescription: {
        fontFamily: Fonts.type.base,
        fontWeight: '400',
        fontSize: 14,
        color: Colors.darkTextColor,
        backgroundColor: 'transparent',
    },
    paymentChange: {
        fontFamily: Fonts.type.base,
        fontWeight: '500',
        fontSize: 13,
        color: Colors.obiTeal,
    },
    paymentIcon: {
        width: 8,
        height: 10,
        margin: 3,
    },
    gradientButton: {
        marginHorizontal: 16,
    },
    cardImage: {
        width: undefined,
        height: 16,
    },
    flexOne: {
        flex: 1,
    },
});

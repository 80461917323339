import Axios, { AxiosPromise } from 'axios';
import { LatLng } from 'react-native-maps';

import getEnv from 'modules/config/config';

import proxy from '../Proxy';
import { TomTomGetDirectionResponse } from 'domain/api/geolocation';

export type TomTomTravelMode = 'car' | 'pedestrian' | 'bicycle';

const instance = Axios.create({
    baseURL: 'https://api.tomtom.com',
    timeout: 10000,
});

class TomTomClient {
    apiKey: string;

    constructor() {
        this.apiKey = getEnv('TOMTOM_KEY');
        instance.interceptors.request.use(request => {
            console.log('[TomTomClient] Starting Request:', { ...request });
            return __DEV__ ? request : proxy(request);
        });
        instance.interceptors.response.use(
            response => {
                console.log('[TomTomClient] Response:', { ...response });
                return response;
            },
            error => {
                console.log('[TomTomClient] Response error:', { ...error });
                return Promise.reject(error);
            }
        );
    }

    getDirection = (
        pickupLocation: LatLng,
        destinationLocation: LatLng,
        travelMode: TomTomTravelMode
    ): AxiosPromise<TomTomGetDirectionResponse> => {
        return instance.get(
            `routing/1/calculateRoute/${pickupLocation.latitude},${pickupLocation.longitude}:${
                destinationLocation.latitude
            },${destinationLocation.longitude}/json`,
            {
                params: {
                    key: this.apiKey,
                    travelMode,
                },
            }
        );
    };

    getLocationNameForCoordinates = ({ latitude, longitude }: LatLng): AxiosPromise<any> =>
        instance.get(`search/2/reverseGeocode/${latitude},${longitude}.json`, {
            params: {
                key: this.apiKey,
            },
        });
}

export default new TomTomClient();

import { StyleSheet } from 'react-native';
import { Colors } from '../../../../themes';

export default StyleSheet.create({
    itemContainer: {
        backgroundColor: Colors.grayBackgroundColor,
        padding: 4,
        paddingTop: 8,
    },
    headerText: {
        color: Colors.grayTextColor,
    },
});

import { StyleSheet } from 'react-native';
import { Colors, Fonts } from '../../../../themes';

export default StyleSheet.create({
    errorMessage: {
        color: Colors.obiTeal,
        marginTop: 10,
    },
    errorMessageInner: {
        textDecorationLine: 'underline',
        fontWeight: '600',
        fontFamily: Fonts.type.base,
    },
});

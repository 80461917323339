import React, { Component } from 'react';
import { TouchableOpacity, Text, Animated, Easing, Image, Platform } from 'react-native';
import { observer } from 'mobx-react';
import CircularProgress from 'modules/circularProgress/circularProgress';

import FareRefreshStyles from './styles/FareRefreshStyles';
import TaxiStore from '../../../../stores/TaxiStore';
import styles from '../styles/styles';
import { Colors, Images } from 'themes';

const AnimatedCircularProgress = Animated.createAnimatedComponent(CircularProgress);

interface Props {
    taxiStore: TaxiStore;
}

interface State {
    fillAnimation: Animated.Value;
}

@observer
export default class FaresRefreshButton extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            fillAnimation: new Animated.Value(0),
        };
    }

    componentDidMount() {
        const {
            taxiStore: {
                taxiResultsStore: { setResetFaresAnimation },
            },
        } = this.props;
        setResetFaresAnimation(this.reset);
        this.animate(30000);
    }

    componentWillUnmount() {
        const {
            taxiStore: {
                taxiResultsStore: { setResetFaresAnimation },
            },
        } = this.props;
        setResetFaresAnimation();
    }

    animate = (duration: number) => {
        const { fillAnimation } = this.state;
        Animated.timing(fillAnimation, {
            toValue: 100,
            easing: Easing.linear,
            duration,
            // useNativeDriver: true,
        }).start();
    };

    reset = () => {
        this.setState({ fillAnimation: new Animated.Value(0) }, () => this.animate(30000));
    };

    onPress = () => {
        const {
            taxiStore: {
                taxiResultsStore: { getTaxiTypes },
            },
        } = this.props;
        getTaxiTypes(true);
    };

    renderImage = () => (
        <Image
            source={Images.icons.refresh}
            style={FareRefreshStyles.refreshImage}
            resizeMode="contain"
        />
    );

    render() {
        const { fillAnimation } = this.state;
        return (
            <TouchableOpacity onPress={this.onPress} style={FareRefreshStyles.faresRefresh}>
                <AnimatedCircularProgress
                    size={42}
                    width={3}
                    prefill={0}
                    fill={fillAnimation}
                    tintColor={Colors.darkerGrayBackgroundColor}
                    backgroundColor={Colors.lightGrayColor}
                    useNativeDriver
                >
                    {Platform.OS === 'web' ? this.renderImage() : () => this.renderImage()}
                </AnimatedCircularProgress>
            </TouchableOpacity>
        );
    }
}

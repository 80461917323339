import React, { Component } from 'react';
import { FlatList, View, LayoutAnimation } from 'react-native';
import { observer, inject } from 'mobx-react';

import { Stores } from 'stores/RootStore';
import { SectionData } from 'stores/dataStores/taxiResultsStore/TaxisComparationStore';
import { BikeStore, TaxiStore } from 'stores';

import BikeRow from '../../row/BikeRow';
import FiltersStore from 'stores/FiltersStore';
import GroupedComparationListStyles from './styles/GroupedComparationListStyles';
import Section from './Section';
import SectionHeader from './SectionHeader';
import SectionStyles from './styles/SectionStyles';
import TaxiRow from '../../row/TaxiRow';
import TaxisEmpty from '../TaxisEmpty';

interface Props {
    bikeStore: BikeStore;
    filtersStore: FiltersStore;
    taxiStore: TaxiStore;
}

@inject(({ filtersStore, taxiStore, bikeStore }: Stores) => ({
    bikeStore,
    filtersStore,
    taxiStore,
}))
@observer
export default class GroupedComparationList extends Component<Props> {
    renderSection = ({ item }: { item: SectionData }) => <Section item={item} />;

    renderSectionSeparator = () => <View style={SectionStyles.separator} />;

    renderSectionHeader = (section: SectionData) => {
        const {
            taxiStore: {
                taxiResultsStore: {
                    taxisComparationStore: { handleSectionHeaderClick },
                },
            },
        } = this.props;
        return (
            <SectionHeader
                section={section}
                onPress={() => {
                    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
                    handleSectionHeaderClick(section);
                }}
            />
        );
    };

    renderRow = ({ item, index }: { item: any; index: number }) => {
        return !item ? null : item.bike ? (
            <BikeRow item={item} index={index} testID={`item_bike_${index}`} />
        ) : (
            <TaxiRow index={index} item={item} testID={`item_taxi_${index}`} />
        );
    };

    keyExtractor = (item: any, index: number) => {
        return `${item}${index}${item.search_id}`;
    };

    render() {
        const {
            taxiStore: {
                taxiResultsStore: { taxisComparationStore, typesLoading },
            },
        } = this.props;
        return taxisComparationStore.sectionsEmpty ? (
            <TaxisEmpty />
        ) : (
            <FlatList
                data={taxisComparationStore.sections}
                keyExtractor={this.keyExtractor}
                ref={taxisComparationStore.flatListRef}
                renderItem={this.renderSection}
                style={GroupedComparationListStyles.taxisComparationList}
                testID="list_compare"
            />
        );
    }
}

import React, { Component } from 'react';
import { View } from 'react-native';
import { observer, inject } from 'mobx-react';

import TitleNavigationBar from 'components/navigationBars/TitleNavigationBar';

import AppStore from '../../stores/AppStore';
import AuthStore from '../../stores/AuthStore';
import GroupedListView from '../../components/profile/GroupedListView';
import NavigationStore from 'stores/navigationStores/NavigationStore';
import SettingsStyles from './styles/SettingsStyles';
import { Analytics, Localizable } from '../../lib';
import { Images } from '../../themes';
import { Stores } from '../../stores/RootStore';

const sections = [
    {
        title: Localizable.t('settings.general'),
        data: [
            {
                icon: Images.settings.globe,
                title: Localizable.t('settings.country'),
                onPress: () => {},
                value: 'US',
            },
            {
                icon: Images.settings.currency,
                title: Localizable.t('settings.currency'),
                onPress: () => {},
                value: 'USD',
            },
            {
                icon: Images.settings.language,
                title: Localizable.t('settings.language'),
                onPress: () => {},
                value: 'English',
            },
        ],
    },
    {
        title: Localizable.t('settings.profiles'),
        data: [
            {
                title: Localizable.t('settings.businessAdd'),
                description: Localizable.t('settings.businessDescription'),
                onPress: () => {},
            },
        ],
    },
    {
        title: Localizable.t('settings.privacy'),
        data: [
            {
                icon: Images.settings.privacy,
                title: Localizable.t('settings.privacySettings'),
                onPress: () => {},
                value: 'US',
            },
        ],
    },
];

interface Props {
    authStore: AuthStore;
    appStore: AppStore;
    navigationStore: NavigationStore;
}

@inject(({ appStore, authStore, navigationStore }: Stores) => ({
    appStore,
    authStore,
    navigationStore,
}))
@observer
export default class Settings extends Component<Props> {
    render() {
        const {
            appStore,
            authStore,
            navigationStore: { goBack },
        } = this.props;

        return (
            <View style={SettingsStyles.container}>
                <TitleNavigationBar
                    authStore={authStore}
                    appStore={appStore}
                    title={Localizable.t('settings.title')}
                    handleBackClick={() => goBack()}
                />
                <GroupedListView sections={sections} />
            </View>
        );
    }
}

import { StyleSheet } from 'react-native';

export default StyleSheet.create({
    container: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 100,
        alignItems: 'center',
        justifyContent: 'center',
    },
    image: {
        height: 35,
        width: 18,
    },
    confirmView: {
        left: 0,
        right: 0,
        position: 'absolute',
        height: 100,
        backgroundColor: 'white',
        padding: 20,
        borderTopLeftRadius: 16,
        borderTopRightRadius: 16,
        shadowColor: '#000000',
        shadowOffset: { width: 0, height: 0 },
        shadowRadius: 4,
        shadowOpacity: 0.2,
        elevation: 20,
    },
});

/* eslint-disable camelcase */

// import firebase from 'react-native-firebase';
import { persist } from 'mobx-persist';
import { observable, flow, action } from 'mobx';
import { Platform } from 'react-native';

import firebase from 'modules/firebase/firebase';

import { FirebaseClient, RestClient } from 'services';
import { BaseStore } from '.';
import { AppLifecycle, PersistDataStore } from './RootStore';

export default class ReferralStore extends BaseStore implements PersistDataStore, AppLifecycle {
    hydrateStore = (hydrate: Function) => hydrate('referralStore', this)    

    onAppStarted = () => {
        this.getInviteCounter();
    };

    inviteFriendsCounterInterval?: NodeJS.Timer;

    setInviteFriendsCounterInterval = () => {
        this.getInviteCounter();
        this.inviteFriendsCounterInterval = setInterval(() => this.getInviteCounter(), 5000);
    };

    clearInviteFriendsCounterInterval = () => {
        if (!!this.inviteFriendsCounterInterval) {
            clearInterval(this.inviteFriendsCounterInterval);
        }
    };

    @persist
    @observable
    referrerId?: string;

    @observable
    inviteCounter?: number;

    @observable
    dynamicLink = '';

    @observable
    shouldShowReferralDrawer = false;

    @action
    toggleReferralDrawer = () => {
        this.shouldShowReferralDrawer = !this.shouldShowReferralDrawer;
    };

    getInviteCounter = flow(function*(this: ReferralStore) {
        try {
            const {
                data: { invites_count },
            } = yield RestClient.getReferral();
            this.inviteCounter = invites_count;
        } catch (err) {
            console.log('getInviteCounter error', { ...err });
        }
    }).bind(this);

    generateDynamicLink = flow(function*(this: ReferralStore) {
        const {
            stores: { authStore },
        } = this.rootStore;

        const encodedUserId = encodeURIComponent(authStore.user?.user_id);

        let link: any = '';
        if (Platform.OS !== 'web') {
            link = new firebase.links.DynamicLink(
                `https://bellhop.app/?referrerId=${encodedUserId}`,
                'bellhop.page.link'
            ).android
                .setPackageName('com.bellhop.app')
                .ios.setBundleId('com.bellhop.app')
                .ios.setAppStoreId('1081008679');
        }

        try {
            const dynamicLink = yield firebase.links().createDynamicLink(link);
            try {
                const {
                    data: { shortLink },
                } = yield FirebaseClient.getShortDynamicLink(`${dynamicLink}`);
                this.dynamicLink = shortLink;
            } catch (err) {
                this.dynamicLink = dynamicLink || 'https://bellhop.page.link/app';
                console.log('getShortDynamicLink error', { ...err });
            }
        } catch (err) {
            this.dynamicLink = 'https://bellhop.page.link/app';
            console.log('createDynamicLink error', { ...err });
        }

        console.log('DYNAMIC LINK', this.dynamicLink);
    }).bind(this);

    @action
    setReferrerId(referrerId: string) {
        this.referrerId = referrerId;
    }
}

import EstimatedDirectionQueryParams from 'domain/api/EstimatedDirectionQueryParams';
import GoogleClient, { GoogleTravelMode } from './GoogleClient';
import TomTomClient, { TomTomTravelMode } from './TomTomClient';
import Units from '../Units';
import { DirectionFormatted } from 'domain/api/DirectionFormatted';
import { LatLng } from 'modules/maps/maps';
import { distanceBetweenCoords, calculateCurvedRoute } from '../Utils';

class GeolocationService {
    getDirectionFormatted = async (
        pickupLocation: LatLng,
        destinationLocation: LatLng,
        travelMode: TomTomTravelMode = 'car'
    ): Promise<DirectionFormatted> => {
        try {
            const {
                data: { routes },
            } = await TomTomClient.getDirection(pickupLocation, destinationLocation, travelMode);
            const {
                summary: { lengthInMeters, travelTimeInSeconds },
                legs,
            } = routes[0];
            const { points } = legs[0];
            return { points, lengthInMeters, travelTimeInSeconds };
        } catch (error) {
            console.log('getDirectionFormatted tomtom error', error);
            try {
                let googleTravelMode: GoogleTravelMode = 'driving';
                switch (travelMode) {
                    case 'pedestrian':
                        googleTravelMode = 'walking';
                        break;
                    case 'car':
                        googleTravelMode = 'driving';
                        break;
                    default:
                        googleTravelMode = 'bicycling';
                        break;
                }
                const {
                    data: { routes },
                } = await GoogleClient.getDirection(
                    pickupLocation,
                    destinationLocation,
                    googleTravelMode
                );
                const { legs } = routes[0];
                const {
                    distance: { value: lengthInMeters },
                    duration: { value: travelTimeInSeconds },
                    steps,
                } = legs[0];
                const points = steps.map((step: any) => step.start_location);
                return { points, lengthInMeters, travelTimeInSeconds };
            } catch (error) {
                console.log('getDirectionFormatted google error', error);
                const points = calculateCurvedRoute(pickupLocation, destinationLocation, 5, 2);
                return {
                    points,
                    lengthInMeters: null,
                    travelTimeInSeconds: null,
                };
            }
        }
    };
    getEstimatedDirections = ({
        travelMode,
        destination,
        pickup,
    }: EstimatedDirectionQueryParams) => {
        let travelTimeInSeconds = 0;
        const distance = distanceBetweenCoords({
            startCoord: pickup,
            endCoord: destination,
            unit: 'METERS',
        });
        switch (travelMode) {
            case 'car':
                travelTimeInSeconds = Units.getTimeDrivingDistanceInMinutes(distance) * 60;
                break;
            case 'bicycle':
                travelTimeInSeconds = Units.getTimeBikingDistanceInMinutes(distance) * 60;
                break;
            case 'pedestrian':
                travelTimeInSeconds = Units.getTimeWalkingDistanceInMinutes(distance) * 60;
                break;
            default:
                break;
        }
        return {
            lengthInMeters: distance,
            travelTimeInSeconds,
        };
    };
}

export default new GeolocationService();

/* eslint import/no-extraneous-dependencies: ["error", {"peerDependencies": true}] */
/* eslint-disable camelcase */
import { Platform } from 'react-native';
import axios, { AxiosPromise } from 'axios';

import Alert from 'modules/alert/alert';
import DeviceInfo from 'modules/deviceInfo/deviceInfo';
import getEnv from 'modules/config/config';

import ArroResponse from 'domain/services/Arro/ArroResponse';
import AuthStore from 'stores/AuthStore';
import BoltResponse from 'domain/services/Bolt/BoltResponse';
import CancelRideReponse from 'domain/api/restClient/CancelRideResponse';
import GetPickupTimeResponse from 'domain/api/restClient/GetPickupTimeResponse';
import GetProfileResponse from 'domain/api/restClient/GetProfileResponse';
import GetProvidersResponse from 'domain/api/restClient/GetProvidersResponse';
import GoogleClient from './geolocation/GoogleClient';
import LatLng from 'domain/app/LatLng';
import PostEstimateResponse from 'domain/api/restClient/PostEstimateResponse';
import ViaResponse from 'domain/services/Via/ViaResponse';
import { getEndpointFromConfig } from './Utils';
import { localhostProxy } from './Proxy';
import {
    HandleDeeplinkHailResponse,
    HailTaxiResponse,
    GetCurrentRideResponse,
    GetRideStatusResponse,
} from 'domain/api';

interface HailsRequestParams {
    offerId: string;
    startLocation: any;
    endLocation: any;
    surge: any;
    startNick: string;
    endNick: string;
    numPassengers: number;
    pickupDate: string;
    onDemand: boolean;
    fareId: string | null;
    cardId: string;
    fareValue: number | null;
    service: string;
}

const url = `${localhostProxy}${getEnv('API_URL')}`;

const instance = axios.create({
    baseURL: url,
    timeout: 40000,
});

function makeRequestCreator(url: string) {
    let call;
    return function(params: any) {
        if (call) {
            call.cancel();
        }
        call = axios.CancelToken.source();
        return instance
            .get(url, {
                cancelToken: call.token,
                headers: this.getAuthHeader(),
                params: { ...params },
            })
            .catch(function(thrown) {
                // if (axios.isCancel(thrown)) {
                // } else {
                // }
                throw thrown;
            });
    };
}

const profileHeader = { Accept: 'application/vnd.me.bellhop-app.profile+json; v=2' };

const userAgent =
    Platform.OS === 'web'
        ? {}
        : {
              'User-Agent': `Bellhop/${DeviceInfo.getVersion()} ${
                  Platform.OS === 'ios' ? 'iOS' : 'Android'
              }/${DeviceInfo.getSystemVersion()}`,
          };

class RestClient {
    authStore?: AuthStore;

    token: string | null = null;

    isRefreshing: boolean = false;

    refreshSubscribers: Array<any> = [];

    constructor() {
        if (__DEV__) {
            instance.interceptors.request.use(request => {
                console.log(
                    `[RestClient] Start Request: ${getEndpointFromConfig(request)}`,
                    request
                );
                return request;
            });
        }
        instance.interceptors.response.use(
            response => {
                if (__DEV__) {
                    const { config, status, data } = response;
                    console.log(
                        `[RestClient] Response success: ${getEndpointFromConfig(config)}`,
                        status,
                        data
                    );
                }
                return response;
            },
            error => {
                const { config, response } = error;
                const originalRequest = config;
                console.log(
                    `[RestClient] Response error: ${getEndpointFromConfig(config)}`,
                    status,
                    response
                );
                if (!response || response.status !== 401) {
                    return Promise.reject(error);
                }
                if (!this.isRefreshing) {
                    this.isRefreshing = true;
                    if (this.authStore) {
                        this.refreshToken(this.authStore.refreshToken)
                            .then(refreshTokenResponse => {
                                console.log('RefreshToken response', refreshTokenResponse);
                                this.isRefreshing = false;
                                this.onRefreshed(refreshTokenResponse.data.access_token);
                            })
                            .catch(refreshTokenError => {
                                console.log('RefreshToken error', this.isRefreshing, {
                                    ...refreshTokenError,
                                });
                                this.registerUser()
                                    .then(registerUserResponse => {
                                        if (this.authStore) {
                                            this.authStore.login(
                                                registerUserResponse.data.access_token,
                                                registerUserResponse.data.refresh_token
                                            );
                                        }
                                    })
                                    .catch(() => {
                                        Alert.alert(
                                            'Alert',
                                            "We couldn't connect to server. Please check internet connection and try again",
                                            [{ text: 'Ok', onPress: () => {} }]
                                        );
                                    });
                            });
                    }
                }
                const retryOrigReq = new Promise((resolve, reject) => {
                    this.subscribeTokenRefresh((token: string) => {
                        if (this.authStore) {
                            this.authStore.handleTokenRefresh(token);
                        }
                        originalRequest.headers.Authorization = `Bearer ${this.getToken()}`;
                        resolve(axios(originalRequest));
                    });
                });
                return retryOrigReq;
            }
        );
    }

    subscribeTokenRefresh(cb) {
        this.refreshSubscribers.push(cb);
    }

    onRefreshed(token: string) {
        this.refreshSubscribers.map(cb => cb(token));
    }

    setAuthStore(authStore: AuthStore) {
        this.authStore = authStore;
    }

    getToken() {
        return this.token;
    }

    setToken(token: string | null) {
        this.token = token;
    }

    getAuthHeader() {
        if (this.token) {
            return this.createAuthHeader(this.token);
        }
        return userAgent;
    }

    createAuthHeader(token: string) {
        if (token) {
            return {
                Authorization: `Bearer ${token}`,
                // TODO: change to use real loaction
                'User-Location': '40.754363;-73.9889317',
                'App-Version': '2.9',
                ...userAgent,
            };
        }
        return userAgent;
    }

    getTaxiHeader() {
        const apiVersion = getEnv('API_VERSION');
        if (this.getAuthHeader()) {
            return {
                ...this.getAuthHeader(),
                Accept: `application/vnd.me.bellhop-app.taxi+json; v=${apiVersion}`,
            };
        }
        return null;
    }

    getTaxis({
        startLat,
        startLong,
        endLat,
        endLong,
        numPassengers,
        pickupDate,
        onDemand,
        startNickname,
        endNickname,
        distance,
        duration,
    }) {
        return instance.get('/taxis', {
            params: {
                end_latitude: endLat,
                end_longitude: endLong,
                end_nickname: endNickname,
                num_passengers: numPassengers,
                on_demand: onDemand,
                pickup_date_time: pickupDate,
                start_latitude: startLat,
                start_longitude: startLong,
                start_nickname: startNickname,
                distance,
                duration,
            },
            headers: this.getTaxiHeader(),
            responseType: 'stream',
        });
    }

    getTaxiStream({
        startLat,
        startLong,
        endLat,
        endLong,
        numPassengers,
        pickupDate,
        onDemand,
        startNickname,
        endNickname,
        distance,
        countryCode,
        duration,
    }) {
        return instance.get('/taxis/stream', {
            params: {
                start_latitude: startLat,
                start_longitude: startLong,
                start_nickname: startNickname,
                end_latitude: endLat,
                end_longitude: endLong,
                end_nickname: endNickname,
                num_passengers: numPassengers,
                on_demand: onDemand,
                pickup_date_time: pickupDate,
                distance,
                country_code: countryCode,
                duration,
            },
            headers: this.getTaxiHeader(),
        });
    }

    hailTaxi(props: HailsRequestParams): AxiosPromise<HailTaxiResponse> {
        const {
            offerId,
            startLocation,
            endLocation,
            surge,
            startNick,
            endNick,
            numPassengers,
            pickupDate,
            onDemand,
            fareId,
            cardId,
            fareValue,
        } = props;
        return instance.post(
            '/taxis/hails',
            {
                card_id: cardId || undefined,
                end_location: endLocation,
                end_nickname: endNick,
                fare_id: fareId || undefined,
                fare_value: fareValue || undefined,
                offer_id: offerId,
                on_demand: onDemand,
                pickup_date_time: pickupDate,
                seat_count: numPassengers,
                start_location: startLocation,
                start_nickname: startNick,
                surge_confirmation_id: surge || undefined,
            },
            {
                headers: this.getTaxiHeader(),
            }
        );
    }

    changeDestination(requestId: string | number, endLocation: LatLng, endNick: LatLng) {
        return instance.put(
            `/taxis/hails/${requestId}`,
            {
                end_location: endLocation,
                end_nickname: endNick,
            },
            {
                headers: this.getTaxiHeader(),
            }
        );
    }

    handleDeeplinkHail(searchId, provider, productName): AxiosPromise<HandleDeeplinkHailResponse> {
        return instance.post(
            '/taxis/deeplink_hail',
            {
                product_name: productName,
                search_id: searchId,
                service_provider: provider,
                session_id: GoogleClient.sessionId,
            },
            {
                headers: this.getTaxiHeader(),
            }
        );
    }

    getPickupTime(startLat: number, startLong: number): AxiosPromise<GetPickupTimeResponse> {
        return instance.get('/taxis/pickup/shortest', {
            params: {
                start_latitude: startLat,
                start_longitude: startLong,
            },
            headers: this.getAuthHeader(),
        });
    }

    getCurrentRide(): AxiosPromise<GetCurrentRideResponse> {
        return instance.get('/requests/taxis/current', {
            headers: this.getAuthHeader(),
        });
    }

    getProfileFeatures(): AxiosPromise<any> {
        return instance.get('/profile/features', {
            headers: this.getAuthHeader(),
        });
    }

    getRideStatus(requestId: string): AxiosPromise<GetRideStatusResponse> {
        return instance.get(`/taxis/${requestId}/status`, {
            headers: this.getAuthHeader(),
        });
    }

    cancelRide(requestId: string, confirmationToken?: string): AxiosPromise<CancelRideReponse> {
        return instance.delete(`/taxis/hails/${requestId}`, {
            params: { confirmationToken },
            headers: this.getAuthHeader(),
        });
    }

    getTaxiVenues(lat: number, lng: number) {
        return instance.get('/taxis/venues', {
            params: { lat, lng },
            headers: this.getAuthHeader(),
        });
    }

    connectProviderCurb(email: string, password: string) {
        return instance.post(
            `/curb/login`,
            {
                email,
                password,
            },
            {
                headers: this.getAuthHeader(),
            }
        );
    }

    getProviders(): AxiosPromise<GetProvidersResponse> {
        return instance.get('/provider_connections', {
            headers: this.getAuthHeader(),
        });
    }

    revokeProvider(provider: string) {
        return instance.delete(`/provider/${provider}/revoke`, {
            headers: this.getAuthHeader(),
        });
    }

    sandboxChangeStatus(requestId, status) {
        return instance.patch(
            `/sandbox/ride/${requestId}/status?status=${status}`,
            { status },
            {
                headers: this.getAuthHeader(),
            }
        );
    }

    addCard(stripeToken) {
        const headers = { ...this.getAuthHeader(), ...profileHeader };
        return instance.post('/profile/card_info', { token: stripeToken }, { headers });
    }

    removeCard(cardId) {
        const headers = { ...this.getAuthHeader(), ...profileHeader };
        return instance.delete(`/profile/card_info/${cardId}`, { headers });
    }

    changeCardType(cardId, business) {
        const headers = { ...this.getAuthHeader(), ...profileHeader };
        return instance.post(
            `/profile/card_info/${cardId}/default/${business ? 'BUSINESS' : 'PERSONAL'}`,
            {},
            { headers }
        );
    }

    registerUser(registered?: boolean, oldToken?: string, referrerId?: string) {
        const headers =
            !registered && oldToken && oldToken.length
                ? {
                      Accept: 'application/vnd.me.bellhop-app.sign_up+json; v=2',
                      ...this.createAuthHeader(oldToken),
                  }
                : {
                      Accept: 'application/vnd.me.bellhop-app.sign_up+json; v=2',
                      ...userAgent,
                  };
        if (referrerId) {
            return instance.post(
                '/sign_up',
                {
                    referrer_id: referrerId,
                },
                {
                    headers,
                }
            );
        }
        return instance.post(
            '/sign_up',
            {},
            {
                headers,
            }
        );
    }

    refreshToken(refreshToken: string) {
        const formData = new FormData();

        formData.append('grant_type', 'refresh_token');
        formData.append('client_id', getEnv('OAUTH_CLIENT_ID'));
        formData.append('refresh_token', refreshToken);

        return instance.post('/oauth/access_token', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                ...userAgent,
            },
        });
    }

    sentInfo(token: string) {
        return instance.post(
            '/sign_up',
            { jwt: token },
            {
                headers: this.createAuthHeader(token),
            }
        );
    }

    sendMetadata(
        countryCode,
        firstName: string,
        surname: string,
        phoneNumber,
        infoSent: boolean = false
    ) {
        const parameters = {
            country_code: countryCode,
            first_name: firstName,
            surname,
            phone_number: phoneNumber,
        };

        if (infoSent) {
            if (__DEV__) {
                parameters.info_sent_test = infoSent;
            } else {
                parameters.info_sent = infoSent;
            }
        }

        return instance.post(
            '/sign_up/metadata',
            { jwt: this.getToken(), metadata: parameters },
            {
                headers: this.getAuthHeader(),
            }
        );
    }

    getRequests() {
        return instance.get('/requests', {
            params: { service_types: 'TAXI_RESERVATION_ONDEMAND' },
            headers: this.getAuthHeader(),
        });
    }

    getPaymentMethods(provider: string) {
        return instance.get(`/provider/${provider}/payment_methods`, {
            params: {},
            headers: this.getAuthHeader(),
        });
    }

    getUberPaymentMethods() {
        return instance.get('/provider/uber/payment_methods', {
            headers: this.getAuthHeader(),
        });
    }

    getLyftPaymentMethods() {
        return instance.get('/provider/lyft/payment_methods', {
            headers: this.getAuthHeader(),
        });
    }

    getCurbPaymentMethods() {
        return instance.get('/provider/curb/payment_methods', {
            headers: this.getAuthHeader(),
        });
    }

    postEstimate(params: HailsRequestParams): AxiosPromise<PostEstimateResponse> {
        const {
            service,
            offerId,
            startLocation,
            endLocation,
            startNick,
            endNick,
            numPassengers,
            pickupDate,
            onDemand,
            fareId,
        } = params;
        return instance.post(
            `/provider/${service}/estimate`,
            {
                offer_id: offerId,
                start_location: startLocation,
                end_location: endLocation,
                start_nickname: startNick,
                end_nickname: endNick,
                pickup_date_time: pickupDate,
                on_demand: onDemand,
                seat_count: numPassengers,
                fare_id: fareId,
            },
            {
                headers: this.getAuthHeader(),
            }
        );
    }

    setPhoneNumber(phoneNumber) {
        return instance.post(
            '/profile/phone_number',
            {
                phone_number: phoneNumber,
            },
            {
                headers: this.getAuthHeader(),
            }
        );
    }

    verifyCode(code) {
        return instance.post(
            '/profile/phone_verification',
            {
                code,
            },
            {
                headers: this.getAuthHeader(),
            }
        );
    }

    resendCode() {
        return instance.get('/profile/phone_verification', {
            headers: this.getAuthHeader(),
        });
    }

    postDeviceToken(token: string) {
        const headers = { ...this.getAuthHeader(), ...profileHeader };
        return instance.post(
            '/profile/device_token',
            { token, provider: Platform.OS === 'ios' ? 'apple' : 'android' },
            { headers }
        );
    }

    getDestinations(): AxiosPromise<GetDestinationResponse> {
        return instance.get('/taxis/destinations', {
            headers: this.getAuthHeader(),
        });
    }

    setDestination(
        name: string,
        locationName: string,
        locationLatitude: number,
        locationLongitude: number
    ) {
        return instance.post(
            '/taxis/destinations',
            {
                name,
                type: name.toUpperCase(),
                location: {
                    name: locationName,
                    latitude: locationLatitude,
                    longitude: locationLongitude,
                },
            },
            {
                headers: this.getAuthHeader(),
            }
        );
    }

    getProfile(): AxiosPromise<GetProfileResponse> {
        return instance.get('/profile', {
            headers: this.getAuthHeader(),
        });
    }

    updateProfile(name: string | null, surname: string, email: string) {
        return instance.patch(
            '/profile',
            {
                first_name: name || undefined,
                surname: surname || undefined,
                email: email || undefined,
            },
            {
                headers: this.getAuthHeader(),
            }
        );
    }

    getSavings = (): AxiosPromise<GetSavingsResponse> =>
        instance.get('/taxis/savings', {
            headers: this.getAuthHeader(),
        });

    getBikes = (
        lat: number,
        lng: number,
        radius: number,
        cancelPrevReq?: boolean
    ): AxiosPromise<Array<GetBikesResponse>> => {
        if (cancelPrevReq) {
            return this.getBikesCancelPrevRequest({ lat, lng, radius_km: radius });
        }
        return instance.get('/bikes/nearby', {
            headers: this.getAuthHeader(),
            params: { lat, lng, radius_km: radius },
        });
    };

    getBikesCancelPrevRequest = makeRequestCreator('/bikes/nearby');

    getBikeZones = (lat: number, lng: number) =>
        instance.get('/bikes/zones', {
            headers: this.getAuthHeader(),
            params: { lat, lng },
        });

    getRide = (requestId: string | number): AxiosPromise<GetRideResponse> =>
        instance.get(`/requests/${requestId}`, {
            headers: this.getAuthHeader(),
        });

    getAvailableProviders = (region: LatLng): AxiosPromise<GetAvailableProvidersResponse> =>
        instance.get(`/taxis/availability`, {
            headers: this.getAuthHeader(),
            params: { lat: region.latitude, lng: region.longitude },
        });

    rateRide = ({ request_id, rating, tip_amount }) =>
        instance.put(
            `/taxis/${request_id}/rate`,
            { rating, tip_amount },
            { headers: this.getAuthHeader() }
        );

    loginUber = ({ userUUID, apiToken }) => {
        return instance.post(
            '/provider/uber/login',
            {
                user_uuid: userUUID,
                api_token: apiToken,
                device_id: DeviceInfo.getUniqueId().replace(/-/g, ''),
            },
            {
                headers: this.getAuthHeader(),
            }
        );
    };

    loginLyft = ({
        access_token,
        expires_in,
        refresh_token,
        session_id,
        session,
    }: {
        access_token: string;
        expires_in: string;
        refresh_token: string;
        session_id: string;
        session: { f: string; a: string; h: boolean };
    }) => {
        return instance.post(
            '/provider/lyft/login',
            {
                access_token,
                expires_in,
                refresh_token,
                session_id,
                session: {
                    f: session.f,
                    a: session.a,
                    h: session.h,
                },
            },
            {
                headers: this.getAuthHeader(),
            }
        );
    };

    loginVia = ({ auth_token, id, acct_type }: ViaResponse) => {
        return instance.post(
            '/provider/via/login',
            {
                identity: {
                    auth_token,
                    id,
                    acct_type,
                },
            },
            {
                headers: this.getAuthHeader(),
            }
        );
    };

    loginArro = ({ access_token, refresh_token, expires_in, token_type }: ArroResponse) => {
        return instance.post(
            'provider/arro/login',
            {
                access_token,
                refresh_token,
                expires_in,
                token_type,
            },
            {
                headers: this.getAuthHeader(),
            }
        );
    };

    loginBolt = ({ phone, phone_uuid }: BoltResponse) => {
        return instance.post(
            'provider/taxify/login',
            {
                phone,
                phone_uuid,
            },
            {
                headers: this.getAuthHeader(),
            }
        );
    };

    getReferral = () => {
        return instance.get('referral/summary', { headers: this.getAuthHeader() });
    };
}

export default new RestClient();

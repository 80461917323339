import { StyleSheet } from 'react-native';
import { Metrics, Colors } from 'themes';

export default StyleSheet.create({
    sectionContainer: {
        width: Metrics.screenWidth - 20,
        marginHorizontal: 10,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'white',
    },
    empty: {
        height: 5,
    },
    separator: {
        backgroundColor: Colors.darkerGrayBackgroundColor,
        height: 1,
        marginHorizontal: 20,
    },
});

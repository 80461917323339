import { observable, action } from 'mobx';
import { persist, create } from 'mobx-persist';
import BaseStore from './BaseStore';
import RootStore, { PersistDataStore } from './RootStore';

export default class TooltipStore extends BaseStore implements PersistDataStore {
    hydrateStore = (hydrate: Function) => hydrate('faresChanging', this);

    @persist
    @observable
    faresChanging: boolean = true;

    @observable
    informationScreenShow = false;

    @action
    setFaresChangingTooltipState = (show: boolean) => {
        this.faresChanging = show;
    };

    @action
    showInfoScreen = (show: boolean) => {
        this.informationScreenShow = show;
    };
}

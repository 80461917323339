import { StyleSheet } from 'react-native';
import { Fonts, Metrics } from '../../../../../themes';

export default StyleSheet.create({
    sortTypeButton: {
        flex: 1,
    },
    sortTypeInner: {
        height: 42,
        padding: 10,
        borderRadius: 21,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    sortTypeInnerMobile: {
        shadowColor: '#000000',
        shadowOffset: { width: 0, height: 0 },
        shadowRadius: 2,
        shadowOpacity: 0.1,
    },
    textView: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    typeTitleText: {
        fontFamily: Fonts.type.base,
        fontWeight: '500',
        fontSize: Metrics.normalize(14),
        backgroundColor: 'transparent',
    },
    imageView: {
        flex: 0.3,
        alignItems: 'center',
        justifyContent: 'center',
    },
    image: {
        width: 10,
        height: 20,
    },
});

import { observable, action } from 'mobx';
import BaseStore from './BaseStore';
import RootStore from './RootStore';

const modalElements = {
    title: {
        type: 'title',
        text: 'Lorem ipsum',
    },
    text: {
        type: 'text',
        text:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed venenatis tincidunt libero vitae fringilla. Aenean aliquam scelerisque ex vel iaculis. Pellentesque sollicitudin at felis ac tristique. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque augue purus, rutrum non aliquam et, pretium id mauris. Suspendisse faucibus consectetur risus. Nam sed pretium nisi. Fusce maximus convallis eros, vitae auctor risus tincidunt a. Mauris molestie congue gravida. In dictum pharetra mi a.',
        links: [{}],
    },
    button: {
        type: 'button',
        text: 'Button',
        state: 'primary|secondary|cancel',
        action: () => {
            console.log('This is button action');
        },
    },
};

const defaultModalSections = [
    {
        type: 'title',
        text: 'Lorem ipsum',
    },
    {
        type: 'text',
        text:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed venenatis tincidunt libero vitae fringilla. Aenean aliquam scelerisque ex vel iaculis. Pellentesque sollicitudin at felis ac tristique. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque augue purus, rutrum non aliquam et, pretium id mauris. Suspendisse faucibus consectetur risus. Nam sed pretium nisi. Fusce maximus convallis eros, vitae auctor risus tincidunt a. Mauris molestie congue gravida. In dictum pharetra mi a.',
    },
    {
        type: 'button',
        text: 'Button',
        state: 'primary',
        action: () => {
            console.log('This is button action');
        },
    },
];

interface ModalSection {
    type: string;
    text: string;
    state?: string;
    action?: () => void;
}
interface ModalType {
    title: string;
    message: string;
    label: string;
    action: () => void;
}

export default class ModalStore extends BaseStore {
    @observable modalVisible: boolean = false;

    @observable modalSections: Array<ModalSection> = [];

    constructor(rootStore: RootStore) {
        super(rootStore);
        this.modalSections = defaultModalSections;
    }

    @action
    showModal = () => {
        this.modalVisible = true;
    };

    @action
    hideModal = () => {
        this.modalVisible = false;
    };

    @action
    mapNotificationToModal = ({ title, message, label, action: buttonAction }: ModalType) => {
        this.modalSections = [];
        this.modalSections.push({ type: 'title', text: title });
        this.modalSections.push({ type: 'text', text: message });
        this.modalSections.push({
            action: buttonAction,
            text: label,
            type: 'button',
            state: 'primary',
        });
        this.modalSections.push({
            action: () => this.hideModal(),
            text: 'Cancel',
            type: 'button',
            state: 'secondary',
        });
    };
}

import React, { Component, ClassicComponent } from 'react';
import { View, TouchableOpacity, Text, ViewStyle, ViewProps } from 'react-native';
import URL from 'url-parse';

import Animatable from 'modules/animatable/animatable';
import SurgeConfirmationStyles from './styles/SurgeConfirmationStyles';
import { Localizable } from '../../lib';

interface Props {
    url?: string;
    handleSurge: Function;
    cancel: () => void;
}

export default class SurgeConfirmation extends Component<Props> {
    main?: ClassicComponent<Animatable.AnimatableProperties<ViewStyle> & ViewProps, any> | null;

    static defaultProps = {
        handleSurge: () => {},
        cancel: () => {},
    };

    handleNavigationChange = state => {
        const url = new URL(state.url, true, true);
        const { handleSurge } = this.props;
        if (url.query && url.query.surge_confirmation_id) {
            this.cancel();
            handleSurge(url.query.surge_confirmation_id);
        }
    };

    cancel = () => {
        this.main.fadeOutDownBig(300).then(() => {
            this.props.cancel();
        });
    };

    render() {
        const { url } = this.props;
        return (
            <Animatable.View
                ref={ref => {
                    this.main = ref;
                }}
                animation="fadeInUpBig"
                duration={300}
                style={SurgeConfirmationStyles.container}
                useNativeDriver
            >
                <View style={SurgeConfirmationStyles.topBar}>
                    <View style={SurgeConfirmationStyles.content}>
                        <TouchableOpacity
                            style={SurgeConfirmationStyles.cancelButton}
                            onPress={this.cancel}
                        >
                            <Text style={SurgeConfirmationStyles.cancelText}>
                                {Localizable.t('taxis.surgeConfirmation.cancel')}
                            </Text>
                        </TouchableOpacity>
                        <Text style={SurgeConfirmationStyles.titleText} />
                        <View style={SurgeConfirmationStyles.separator} />
                    </View>
                </View>
            </Animatable.View>
        );
    }
}

import React, { Component, ClassicComponent } from 'react';
import {
    View,
    TouchableOpacity,
    Text,
    Image,
    ImageBackground,
    ImageSourcePropType,
    ViewProps,
    ViewStyle,
} from 'react-native';
import Animatable from 'modules/animatable/animatable';
import { observer } from 'mobx-react';

import DefaultPopupStyles from './styles/DefaultPopupStyles';
import GradientButton from '../GradientButton';
import { Images } from '../../themes';
import { Localizable } from '../../lib';

interface Props {
    hidePopup: Function;
    accepted: Function;
    icon: ImageSourcePropType;
    title: string;
    description: string;
    skipable: boolean;
    buttonTitle: string;
    step: number;
    maxSteps: number;
}

@observer
export default class DefaultPopup extends Component<Props, {}> {
    frame?: ClassicComponent<Animatable.AnimatableProperties<ViewStyle> & ViewProps, any> | null;

    main?: ClassicComponent<Animatable.AnimatableProperties<ViewStyle> & ViewProps, any> | null;

    static defaultProps = {
        hidePopup: () => {},
        accepted: () => {},
        skipable: false,
        step: 1,
        maxSteps: 1,
    };

    render() {
        const {
            hidePopup,
            accepted,
            icon,
            title,
            description,
            skipable,
            buttonTitle,
            step,
            maxSteps,
        } = this.props;
        return (
            <Animatable.View
                ref={ref => {
                    this.main = ref;
                }}
                animation="fadeIn"
                duration={300}
                style={DefaultPopupStyles.container}
                useNativeDriver
            >
                <Animatable.View
                    style={DefaultPopupStyles.innerContainer}
                    ref={ref => {
                        this.frame = ref;
                    }}
                    useNativeDriver
                >
                    {skipable && (
                        <View style={DefaultPopupStyles.topView}>
                            <View style={DefaultPopupStyles.empty} />
                            <TouchableOpacity onPress={() => hidePopup()}>
                                <View style={DefaultPopupStyles.skipContainer}>
                                    <Text style={DefaultPopupStyles.skipText}>
                                        {Localizable.t('taxis.defaultPopup.skip')}
                                    </Text>
                                    <Image
                                        style={DefaultPopupStyles.skipArrow}
                                        source={Images.popups.skip}
                                    />
                                </View>
                            </TouchableOpacity>
                        </View>
                    )}
                    <ImageBackground
                        source={Images.popups.bubbles}
                        style={DefaultPopupStyles.bubbleContainer}
                    >
                        <Image
                            source={icon}
                            style={DefaultPopupStyles.iconImage}
                            resizeMode="contain"
                        />
                        <Text style={DefaultPopupStyles.stepText}>
                            {Localizable.t('taxis.defaultPopup.step')} {step}{' '}
                            {Localizable.t('taxis.defaultPopup.of')} {maxSteps}
                        </Text>
                    </ImageBackground>
                    <Text style={DefaultPopupStyles.titleText}>{title}</Text>
                    <Text style={DefaultPopupStyles.descriptionText}>{description}</Text>
                    <GradientButton
                        style={DefaultPopupStyles.confirmButton}
                        title={buttonTitle}
                        handlePress={() => accepted()}
                    />
                </Animatable.View>
            </Animatable.View>
        );
    }
}

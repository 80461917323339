import React, { Component } from 'react';
import { View, ActivityIndicator, StyleSheet } from 'react-native';
import { observer, inject } from 'mobx-react';

import WebView from 'modules/webView/webView';
import AdditionalInfoStyles from '../../styles/AdditionalInfoStyles';
import ServicesStore from '../../../../stores/ServicesStore';
import TitleNavigationBar from '../../../navigationBars/TitleNavigationBar';
import { Colors } from '../../../../themes';
import { ErrorMessage } from '../../providerConnectingComponents';
import { Analytics } from 'lib';
import { Stores } from 'stores/RootStore';

interface Props {
    servicesStore: ServicesStore;
}

@inject(({ servicesStore }: Stores) => ({ servicesStore }))
@observer
export default class Captcha extends Component<Props> {
    componentDidMount() {
        Analytics.trackServiceLinking('uber', 'connecting', 'captcha_screen');
    }

    render() {
        const {
            servicesStore: {
                hideProviderPopup,
                uberServiceStore: { onCaptchaNavStateChanged, errorMessage },
            },
        } = this.props;
        return (
            <View style={AdditionalInfoStyles.container}>
                <TitleNavigationBar title="Uber" handleBackClick={() => hideProviderPopup()} />

                {errorMessage.length === 0 ? (
                    <WebView
                        source={{ uri: 'https://auth.uber.com/login/mobile-captcha.html' }}
                        onNavigationStateChange={onCaptchaNavStateChanged}
                        renderLoading={() => (
                            <View
                                style={[
                                    StyleSheet.absoluteFill,
                                    AdditionalInfoStyles.webViewInnerConatiner,
                                ]}
                            >
                                <ActivityIndicator color={Colors.obiTeal} size="large" />
                            </View>
                        )}
                        startInLoadingState
                    />
                ) : (
                    <View>
                        <ErrorMessage message={errorMessage} />
                    </View>
                )}
            </View>
        );
    }
}

import { StyleSheet } from 'react-native';
import { Fonts, Metrics } from '../../themes';

export default StyleSheet.create({
    container: {
        borderRadius: 10,
        overflow: 'hidden',
        height: 48,
    },
    innerContainer: {
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
    },
    titleText: {
        textAlign: 'center',
        backgroundColor: 'transparent',
        fontFamily: Fonts.type.base,
        fontWeight: '600',
        fontSize: Metrics.normalize(17),
    },
});

import React from 'react';
import { View, Text, ActivityIndicator, Linking } from 'react-native';
import { observer, inject } from 'mobx-react';

import Button from '../../../Button';
import FiltersStore from '../../../../stores/FiltersStore';
import TaxiStore from '../../../../stores/TaxiStore';
import TaxisEmptyStyles from './styles/TaxisEmptyStyles';
import { Localizable } from '../../../../lib';

interface Props {
    filtersStore: FiltersStore;
    taxiStore: TaxiStore;
}

export default inject(({ filtersStore, taxiStore }) => ({ filtersStore, taxiStore }))(
    observer(({ filtersStore, taxiStore: { taxiResultsStore } }: Props) => {
        if (taxiResultsStore.typesLoading) {
            return (
                <View style={TaxisEmptyStyles.taxisEmptyRefreshContainer}>
                    <ActivityIndicator size="large" />
                </View>
            );
        }
        return (
            <View style={TaxisEmptyStyles.taxisEmptyRefreshContainer}>
                {filtersStore.availableProviders.length &&
                filtersStore.availableProviders.length > 0 ? (
                    <>
                        <View style={TaxisEmptyStyles.textView} key="text">
                            <Text style={TaxisEmptyStyles.text}>
                                {Localizable.t('taxi.emptyRide.refresh.oops')}
                            </Text>
                            <Text style={TaxisEmptyStyles.text}>
                                {Localizable.t('taxi.emptyRide.refresh.text')}
                            </Text>
                        </View>
                        <Button
                            key="button"
                            title={Localizable.t('taxi.refresh')}
                            handlePress={() => taxiResultsStore.getTaxiTypes(true)}
                            active
                            style={TaxisEmptyStyles.button}
                        />
                    </>
                ) : (
                    <View style={TaxisEmptyStyles.textView}>
                        <Text style={TaxisEmptyStyles.text}>
                            {Localizable.t('taxi.emptyRide.text')}
                            {'\n\n'}
                            <Text
                                style={TaxisEmptyStyles.innerText}
                                onPress={() => Linking.openURL('https://bellhop.app/help/#contact')}
                            >
                                {'https://bellhop.app/help/#contact'}
                            </Text>
                        </Text>
                    </View>
                )}
            </View>
        );
    })
);

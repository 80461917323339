import { StyleSheet } from 'react-native';
import { Fonts, Metrics, Colors } from '../../../themes';

export default StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: Colors.typesBackgroundColor,
    },
    rowView: {
        margin: 10,
        borderRadius: 8,
        backgroundColor: 'white',
        shadowColor: 'black',
        shadowRadius: 3,
        shadowOpacity: 0.1,
        shadowOffset: { width: 0, height: 2 },
        elevation: 2,
    },
    mapView: {
        height: 110,
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        overflow: 'hidden',
    },
    infoView: {
        paddingVertical: 14,
    },
    addressView: {
        flexDirection: 'row',
        alignItems: 'center',
        marginHorizontal: 10,
        marginBottom: 8,
    },
    addressDot: {
        width: 6,
        height: 6,
        borderRadius: 3,
        marginHorizontal: 10,
    },
    pickupText: {
        fontFamily: Fonts.type.base,
        fontWeight: '400',
        fontSize: Metrics.normalize(12),
        color: Colors.grayTextColor,
        flex: 1,
    },
    destinationText: {
        fontFamily: Fonts.type.base,
        fontWeight: '600',
        fontSize: Metrics.normalize(17),
        color: Colors.darkTextColor,
        flex: 1,
    },
    separator: {
        backgroundColor: Colors.grayTextColor,
        height: 1,
        opacity: 0.2,
        marginVertical: 10,
    },
    cellView: {
        flex: 3,
        paddingHorizontal: 20,
        flexDirection: 'row',
        alignItems: 'center',
        paddingVertical: 4,
        justifyContent: 'center',
    },
    cellSeparator: {
        backgroundColor: Colors.grayTextColor,
        width: 1,
        opacity: 0.2,
        top: 2,
        bottom: 2,
        right: 0,
        position: 'absolute',
    },
    cellImage: {
        width: 20,
        height: 25,
        marginRight: 8,
    },
    cellTitleText: {
        fontFamily: Fonts.type.base,
        fontWeight: '400',
        fontSize: Metrics.normalize(7),
        color: Colors.grayTextColor,
        marginBottom: 2,
    },
    cellValueText: {
        fontFamily: Fonts.type.base,
        fontWeight: '700',
        fontSize: Metrics.normalize(14),
        color: Colors.darkTextColor,
    },
    renderEmpty: {
        fontFamily: Fonts.type.base,
        color: Colors.darkGreyTextColor,
        fontSize: Metrics.normalize(18),
        fontWeight: '300',
        margin: 20,
        textAlign: 'justify',
    },
    flexOne: { flex: 1 },
    flexRow: { flexDirection: 'row' },
});

import React from 'react';
import { observer } from 'mobx-react';
import {
    View,
    Text,
    FlatList,
    ScrollView,
    TouchableOpacity,
    StyleSheet,
    Platform,
    Animated,
    TouchableWithoutFeedback,
    Image,
} from 'react-native';

import Avatar from '../profile/Avatar';
import DrawerItem from 'domain/app/DrawerItem';
import DrawerListItem from './DrawerListItem';
import DrawerStyles from './styles/DrawerWebStyles';
import { Images } from 'themes';
import { Localizable, Analytics } from 'lib';
import { useStores } from 'containers/App';

interface FlatListDrawerItem {
    item: DrawerItem;
}

interface GetItemProps {
    navigate: (routeName: string) => void;
    closeDrawer: () => void;
    toggleReferralDrawer: () => void;
}

const renderRow = ({ item }: FlatListDrawerItem) => <DrawerListItem item={item} />;
const getItems = ({ navigate, closeDrawer, toggleReferralDrawer }: GetItemProps) => {
    return [
        {
            name: 'Providers',
            image: Images.menu.providers,
            onPress: () => navigate('Services'),
        },
        {
            name: 'Trip History',
            image: Images.menu.tripHistory,
            onPress: () => navigate('RideHistory'),
        },
        {
            name: Localizable.t('profile.invite'),
            image: Images.menu.inviteFriends,
            onPress: () => {
                closeDrawer();
                toggleReferralDrawer();
                Analytics.trackShare();
            },
        },
        // {
        //     name: 'Settings',
        //     image: Images.menu.settings,
        //     onPress: () => navigate('Settings'),
        // },
    ];
};

export default observer(() => {
    const {
        stores: {
            authStore,
            navigationStore,
            navigationStore: { navigate, closeDrawer, drawerOpen },
            referralStore: { toggleReferralDrawer },
        },
    } = useStores();
    const items = getItems({ navigate, closeDrawer, toggleReferralDrawer });
    const onProfileClick = () => {
        closeDrawer();
        navigate('Profile');
    };
    return Platform.OS === 'web' ? (
        <View style={[StyleSheet.absoluteFill]} pointerEvents="box-none">
            <Animated.View
                pointerEvents="box-none"
                style={[
                    StyleSheet.absoluteFill,
                    {
                        backgroundColor: 'black',
                        opacity: navigationStore.drawerOffset.interpolate({
                            inputRange: [0, 1],
                            outputRange: [0, 0.6],
                        }),
                    },
                ]}
            >
                {drawerOpen && (
                    <TouchableWithoutFeedback
                        style={{ flex: 1, alignSelf: 'stretch' }}
                        onPress={() => {
                            closeDrawer();
                        }}
                    >
                        <View style={{ flex: 1, alignSelf: 'stretch' }} />
                    </TouchableWithoutFeedback>
                )}
            </Animated.View>
            <Animated.ScrollView
                style={[
                    DrawerStyles.container,
                    {
                        width: navigationStore.drawerWidth,
                        transform: [
                            {
                                translateX: navigationStore.drawerOffset.interpolate({
                                    inputRange: [0, 1],
                                    outputRange: [-navigationStore.drawerWidth, 0],
                                }),
                            },
                        ],
                    },
                ]}
                bounces={false}
            >
                <View style={DrawerStyles.outerContainer}>
                    <View style={DrawerStyles.container}>
                        <ScrollView style={DrawerStyles.scrollView} bounces={false}>
                            <TouchableOpacity key="ProfileButton" onPress={onProfileClick}>
                                <Avatar style={DrawerStyles.avatarView} size={50} />
                                <Text style={DrawerStyles.nameText}>
                                    Hi,{' '}
                                    {authStore.user && authStore.user.first_name
                                        ? `${authStore.user.first_name}`
                                        : 'friend'}
                                </Text>
                                <Text style={DrawerStyles.profileText}>
                                    {authStore.user && authStore.user.first_name
                                        ? 'View Profile'
                                        : 'Tap here to get started'}
                                </Text>
                            </TouchableOpacity>
                            <View style={DrawerStyles.horizontalLine} />
                            <FlatList
                                key="ItemsList"
                                keyExtractor={item => item.name}
                                data={items}
                                renderItem={renderRow}
                            />
                            <View style={[DrawerStyles.horizontalLine, { marginBottom: 30 }]} />
                            <TouchableOpacity onPress={() => navigate('Help')}>
                                <Text style={DrawerStyles.helpText}>Need help?</Text>
                            </TouchableOpacity>
                        </ScrollView>
                        {authStore.isLoggedInWithPhoneNumber && (
                            <View style={DrawerStyles.bottomView}>
                                <TouchableOpacity
                                    style={DrawerStyles.logoutButton}
                                    onPress={() => authStore.logout()}
                                >
                                    <Image
                                        source={Images.menu.logout}
                                        style={DrawerStyles.logoutButtonImage}
                                    />
                                    <Text style={DrawerStyles.logoutButtonText}>Logout</Text>
                                </TouchableOpacity>
                            </View>
                        )}
                    </View>
                    <View style={DrawerStyles.drawerIndicatorView}>
                        <View style={DrawerStyles.drawerIndicator} />
                    </View>
                </View>
            </Animated.ScrollView>
        </View>
    ) : null;
});

import React from 'react';
import { View, Text } from 'react-native';

import TripSavingsHeaderStyles from './TripSavingsHeaderStyles';

interface Props {
    section: {
        title: string;
    };
}

const TripSavingsHeader = ({ section: { title } }: Props) => (
    <View style={TripSavingsHeaderStyles.itemContainer}>
        <Text style={TripSavingsHeaderStyles.headerText}>{title}</Text>
    </View>
);

export default TripSavingsHeader;

import { StyleSheet, Platform } from 'react-native';
import { Fonts, Metrics, Colors } from '../../../themes';

export default StyleSheet.create({
    suggestionTitle: {
        fontSize: Metrics.normalize(14),
        color: Colors.suggestionTitleColor,
        fontFamily: Fonts.type.base,
        fontWeight: '500',
        marginBottom: Platform.select({ web: 0, default: 2 }),
    },
    suggestionDescription: {
        fontSize: Metrics.normalize(12),
        color: '#858585',
        fontFamily: Fonts.type.base,
        fontWeight: '300',
    },
    distance: {
        fontSize: Metrics.normalize(14),
        color: Colors.grayTextColor,
        fontFamily: Fonts.type.base,
        fontWeight: '300',
        marginTop: 4,
    },
    suggestionItemContainer: {
        flexDirection: 'row',
        height: 55,
        paddingHorizontal: 5,
        backgroundColor: 'white',
    },
    suggestionItemContainerEmpty: {
        flexDirection: 'row',
        height: 55,
        backgroundColor: 'transparent',
    },
    suggestionsItemLeftContainer: {
        width: 70,
        alignItems: 'center',
        justifyContent: 'center',
    },
    suggestionsPin: {
        width: 16,
        height: 16,
    },
    headerText: {
        color: '#C8C7CC',
        fontFamily: Fonts.type.base,
        fontSize: Metrics.normalize(10),
        fontWeight: '400',
        marginLeft: 16,
    },
    suggestionIconView: {
        width: 32,
        height: 32,
        borderRadius: 16,
        backgroundColor: Colors.darkerGrayBackgroundColor,
        alignItems: 'center',
        justifyContent: 'center',
    },
    suggestionIconWitoutBackground: {
        height: 14,
        width: 14,
    },
    suggestionIcon: {
        height: 24,
        width: 24,
    },
    textView: {
        flex: 1,
        justifyContent: 'center',
    },
});

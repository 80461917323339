import React, { Component } from 'react';
import { View, Text, TouchableOpacity, Image } from 'react-native';
import { observer, inject } from 'mobx-react';
import moment from 'moment';

import RowStyles from './styles/RowStyles';
import { Images, Colors, Metrics } from 'themes';
import { bikeProviderInfo } from 'services/Utils';
import { Stores } from 'stores/RootStore';
import TaxiStore from 'stores/TaxiStore';
import BikeStore from 'stores/BikeStore';

interface InjectedProps {
    bikeStore: BikeStore;
    taxiStore: TaxiStore;
}

interface OwnProps {
    item: any;
    index: number;
}

type Props = InjectedProps & OwnProps;

@inject(({ bikeStore, taxiStore }: Stores) => ({ bikeStore, taxiStore }))
@observer
export default class BikeRow extends Component<Props> {
    onItemClick = () => {
        const {
            item,
            bikeStore: { selectBike },
        } = this.props;
        selectBike(item.provider.company, item.details);
    };

    render() {
        const { item } = this.props;
        return (
            <TouchableOpacity onPress={this.onItemClick} style={RowStyles.outerContainer}>
                <View style={RowStyles.container}>
                    <View style={RowStyles.providerIconView}>
                        <Image
                            resizeMode="contain"
                            source={bikeProviderInfo(item.provider.company).icon}
                            style={RowStyles.providerIcon}
                        />
                    </View>
                    <View style={RowStyles.mainView}>
                        <View style={RowStyles.providerTitleView}>
                            <Text style={RowStyles.rowCompanyText}>{item.provider.company}</Text>
                        </View>
                        <View style={RowStyles.detailsView}>
                            <Image
                                style={RowStyles.seatsImage}
                                source={Images.icons.seats}
                                resizeMode="contain"
                            />
                            <Text style={RowStyles.rowValueText}>
                                Seats: <Text style={RowStyles.boldText}>1</Text>
                                {!!item.details.totalTime && (
                                    <Text style={RowStyles.rowValueText}>
                                        {' • ETA: '}
                                        <Text style={RowStyles.boldText}>
                                            {moment()
                                                .add(item.details.totalTime, 'seconds')
                                                .format('h:mma')}
                                        </Text>
                                    </Text>
                                )}
                            </Text>
                        </View>
                    </View>
                    <View style={RowStyles.valueContainer}>
                        <View style={RowStyles.priceView}>
                            <Text
                                numberOfLines={1}
                                style={[
                                    RowStyles.priceText,
                                    {
                                        color: '#9AA2A8',
                                    },
                                ]}
                            >
                                {'Subscription'}
                            </Text>
                        </View>
                        {/* {!!item.details.pickupTime && (
                                <View style={{ flexDirection: 'row', marginTop: 5 }}>
                                    <Text style={RowStyles.rowValueText}>Pickup:</Text>
                                    <View
                                        style={[
                                            RowStyles.priceView,
                                            {
                                                backgroundColor:
                                                    taxisComparationStore.selectedTypesTab.index ===
                                                    1
                                                        ? Colors.destinationColor
                                                        : '#fff',
                                            },
                                        ]}
                                    >
                                        <Text
                                            style={[
                                                RowStyles.boldText,
                                                {
                                                    color:
                                                        taxisComparationStore.selectedTypesTab
                                                            .index === 1
                                                            ? '#fff'
                                                            : Colors.grayTextColor,
                                                },
                                            ]}
                                        >
                                            {`${(item.details.pickupTime / 60).toFixed()} minutes`}
                                        </Text>
                                    </View>
                                </View>
                            )} */}
                    </View>
                </View>
            </TouchableOpacity>
        );
    }
}

/* eslint-disable camelcase */
import moment from 'moment';

import Intercom from 'modules/intercom/intercom';
import Mixpanel from 'modules/mixpanel/mixpanel';
import analytics, { Options, JsonMap } from 'modules/analytics/analytics';
import getEnv from 'modules/config/config';
import { FirebaseAnalytics } from 'modules/firebase/firebase';

import { Bike } from 'domain/app';
import { BookingType, BookingEventParams } from 'domain/app/Booking';
import { ServiceLinkingType, ServiceLinkingStep } from 'domain/app/ServiceLinking';

export enum SuperProperties {
    SessionStart = 'SP_Session_Start',
    Compare = 'SP_Compare',
    Booking = 'SP_Booking',
    ProviderLinked = 'SP_Provider_Linked',
}

export const excludedUserIds = [
    '4f36f83d-d5f6-4d72-a346-cb86b03d89c0',
    '242fec57-f8b2-42fc-8820-3e4da1654fc7',
    '34a15b53-9090-4932-b0a2-1d6167f4bca7',
    'c127cc82-9ceb-44d2-b58f-6e9decb9d5e6',
];

const Analytics = {
    enabled: true,

    async init(userId: string) {
        if (excludedUserIds.some((id) => id === userId)) {
            this.enabled = false;
        }
        if (this.enabled) {
            await Mixpanel.sharedInstanceWithToken(
                getEnv('TEST') === '1' || __DEV__
                    ? 'ed74aec06645b98b7633253c1bc6b398'
                    : '0000c732a2754bad7d1b87e2e885565d'
            );
            const object: { [key: string]: number } = {};
            object[SuperProperties.SessionStart] = 0;
            object[SuperProperties.Compare] = 0;
            object[SuperProperties.Booking] = 0;
            object[SuperProperties.ProviderLinked] = 0;
            Mixpanel.registerSuperPropertiesOnce(object);
            await analytics.setup(getEnv('SEGMENT_KEY'), {
                recordScreenViews: false,
                trackAppLifecycleEvents: false,
                trackAttributionData: false,
            });
            this.trackSuperProperty(SuperProperties.SessionStart);
        }
    },

    async reset() {
        if (this.enabled) {
            await analytics.reset();
        }
    },

    async identify(userId: string, params?: JsonMap, options?: Options) {
        console.log('[Analytics] identify:', userId, params, options);
        if (this.enabled) {
            await analytics.identify(userId, params, options);
        }
    },

    trackEvent(name: string, params?: JsonMap, options?: Options) {
        console.log('[Analytics] trackEvent', name, params, options);
        if (this.enabled) {
            analytics.track(name, params, options);
            FirebaseAnalytics.logEvent(name, params);
        }
    },

    trackScreenView(screenName: string, params?: JsonMap, options?: Options) {
        console.log('[Analytics] trackScreenView:', screenName, params, options);
        if (this.enabled) {
            analytics.screen(screenName, params, options);
            FirebaseAnalytics.setCurrentScreen(screenName);
        }
    },

    trackSuperProperty(name: SuperProperties) {
        if (this.enabled) {
            Mixpanel.increment(name, 1);
        }
    },

    // EVENTS
    trackBooking(type: BookingType, params: BookingEventParams) {
        Mixpanel.trackChargeWithProperties(
            Number(`${params.fare_estimate}`.split(/[^0-9.]/g).join('')),
            {
                ...params,
                type,
            }
        );
        this.trackEvent(`_Book_${type}`, {
            ...params,
        });
    },

    trackBikeClickGoogleMapsDeepLink(provider: string, pickup: any, destination: any) {
        this.trackEvent('Bike_Click_Deep_Link_Google_Maps', {
            provider,
            pickup,
            destination,
        });
    },

    trackBikeClickUnlock(bike: Bike) {
        const { battery_level, location, num_bikes_available, provider, type } = bike;
        const { latitude, longitude } = location;
        this.trackEvent('Bike_Click_Unlock', {
            battery_level,
            location: {
                latitude,
                longitude,
            },
            num_bikes_available,
            provider,
            type,
        });
    },

    trackBikeMarkerClick({ bike, taxiState }: { bike: Bike; taxiState: string }) {
        const { battery_level, location, num_bikes_available, provider, type } = bike;
        const { latitude, longitude } = location;
        let screenName = '';
        switch (taxiState) {
            case 'start':
                screenName = 'Landing';
                break;
            case 'taxis':
                screenName = 'Compare';
                break;
            default:
                return;
        }
        this.trackEvent(`Bike_Click_Marker_${screenName}_Screen`, {
            battery_level,
            location: {
                latitude,
                longitude,
            },
            num_bikes_available,
            provider,
            type,
        });
    },

    trackDeeplink(
        provider: string,
        estimate: string,
        eta: string,
        serviceType: string,
        offerId: string
    ) {
        this.trackEvent('DeeplinkClick', {
            provider,
            estimate,
            eta,
            offerId,
        });
        this.trackBooking('Indirect', {
            fare_estimate: estimate,
            provider,
            eta,
            search_id: offerId,
            service_type: serviceType,
        });
    },

    trackDirectSavings(userId: string, savingsFromLastRide: any) {
        const normalizedSavings = {
            amount: savingsFromLastRide.offer.savings,
            currency: savingsFromLastRide.offer.currency,
        };
        const rideTime = Math.floor(
            moment(savingsFromLastRide.ask.schedule, "YYYY-MM-DD'T'HH:mm:ss.SSS'Z'").toDate() / 1000
        );

        this.trackEvent(
            'savings',
            { price: normalizedSavings },
            { integrations: { All: false, Intercom: true } }
        );
        Intercom.updateUser({
            custom_attributes: {
                last_direct_savings_at: rideTime,
                last_direct_savings_amount: normalizedSavings.amount,
                last_direct_savings_currency: normalizedSavings.currency,
            },
        });
    },

    trackDriverAccept(result: any, time: number, provider: any) {
        this.trackEvent('timeWaitingForDriver', {
            result,
            time,
            provider,
        });
        // this.trackEvent('_Book_Direct', { real: true });
        this.trackEvent('Ride_Confirmed', {
            result,
            time,
            provider,
            real: true,
        });
        this.trackEvent('Ride_InProgress', { real: true });
    },

    trackEmptyProvidersList() {
        this.trackEvent('Providers_no_results');
    },

    trackLogout() {
        this.trackEvent('logout');
    },

    trackLogin() {
        this.trackEvent('login');
    },

    trackNotificationEnable() {
        this.trackEvent('notifications_enabled');
    },

    trackNotificationDisable() {
        this.trackEvent('notifications_disabled');
    },

    trackPurchase(
        price: string,
        currency: string,
        success: boolean,
        deeplink: boolean,
        provider: string,
        type: string,
        custom = {},
        requestId: string
    ) {
        if (!price || !price.length) {
            return;
        }
        const parsedCurrency = currency.length === 3 ? currency : 'USD';
        const name = deeplink ? 'Deeplink' : 'Ride';
        const priceFloat = parseFloat(price);
        this.trackEvent(
            'purchase',
            {
                price: priceFloat,
                currency: parsedCurrency,
                ...custom,
                event: name,
                provider,
                type,
                requestId,
            },
            { integrations: { All: false, AppEventsLogger: true } }
        );
        this.trackSuperProperty(SuperProperties.Booking);
        if (this.enabled) {
            FirebaseAnalytics.logEvent('ecommerce_purchase', {
                ...custom,
                value: priceFloat,
                currency: parsedCurrency,
                travel_class: provider,
                type,
                provider,
                requestId,
            });
        }
        this.trackEvent(
            'ecommerce_purchase',
            {
                ...custom,
                value: priceFloat,
                currency: parsedCurrency,
                travel_class: provider,
                type,
                provider,
                requestId,
            },
            { integrations: { All: false, Firebase: true } }
        );
    },

    trackSavings(userId: string, savings: any) {
        this.trackEvent(
            'savings',
            { price: savings },
            { integrations: { All: false, Intercom: true } }
        );
        Intercom.updateUser({
            custom_attributes: {
                last_savings_at: Math.floor(Date.now() / 1000),
                last_savings_amount: savings.amount,
                last_savings_currency: savings.currency,
            },
        });
    },

    trackSearch() {
        this.trackEvent('Search', {}, { integrations: { All: true } });
        this.trackSuperProperty(SuperProperties.Compare);
    },

    trackServiceLinking(provider: string, type: ServiceLinkingType, step: ServiceLinkingStep) {
        this.trackEvent(
            'service_linking',
            { provider, type, step },
            {
                integrations: { All: false },
            }
        );
    },

    trackServiceConnect(provider: any) {
        this.trackEvent('service_connected', { provider });
    },

    trackShare() {
        this.trackEvent('share');
    },

    trackSignUp() {
        this.trackEvent('sign_up');
    },

    trackTaxiCancelRide() {
        this.trackEvent('taxi_ride_cancel');
        this.trackEvent('Ride_Cancelled', { real: true });
    },

    trackTaxiCompare() {
        this.trackEvent('Compare_Taxi');
        this.trackEvent('_Compare_Search', { real: true });
    },

    trackTaxiCompleteRide() {
        this.trackEvent('taxi_ride_complete');
    },

    trackTaxiOfferClick({
        searchOrderType,
        provider,
        index,
        rideType,
        offerId,
    }: {
        searchOrderType: 'cheapest' | 'fastest';
        provider: string;
        index: number;
        rideType: string;
        offerId: string;
    }) {
        this.trackEvent('taxi_offer_click', {
            searchOrderType,
            provider,
            index,
            rideType,
            offerId,
        });
    },

    trackTaxiRequestRide() {
        this.trackEvent('taxi_ride_request');
        this.trackEvent('Ride_Requested', { real: true });
    },

    trackTaxiRideDetails(provider: any, rideType: any, offerId: string) {
        this.trackEvent('Click_Throughs', { provider, rideType, offerId });
        this.trackEvent('_Compare_ReviewDetails', { provider, rideType, real: true, offerId });
    },

    trackToggleBikesClick() {
        this.trackEvent('Bikes_Toggle');
    },

    trackCreateProviderAccount(provider: any) {
        this.trackEvent('Create_Provider_Account', { provider });
    },
};

export default Analytics;

import React, { Component } from 'react';
import {
    View,
    Linking,
    Text,
    SectionList,
    Image,
    TouchableOpacity,
    ImageSourcePropType,
    FlatList,
} from 'react-native';
import { observer, inject } from 'mobx-react';

import Intercom from 'modules/intercom/intercom';
import AppStore from '../../stores/AppStore';
import AuthStore from '../../stores/AuthStore';
import HelpStyles from './styles/HelpStyles';
import NavigationStore from 'stores/navigationStores/NavigationStore';
import { Analytics, Localizable } from '../../lib';
import { Images } from '../../themes';
import { Stores } from '../../stores/RootStore';
import EmptyNavigationBar from '../../components/navigationBars/EmptyNavigationBar';

interface Item {
    icon: ImageSourcePropType;
    title: string;
    onPress: () => void;
}

const sections: Array<{ title?: string; data: Array<Item> }> = [
    {
        data: [
            {
                icon: Images.help.helpCenter,
                title: Localizable.t('help.helpCenter'),
                onPress: () => Linking.openURL('https://bellhop.app/help/'),
            },
            {
                icon: Images.help.contact,
                title: Localizable.t('help.contact'),
                onPress: () => Intercom.displayMessageComposer(),
            },
        ],
    },
    {
        title: Localizable.t('help.legal'),
        data: [
            {
                icon: Images.help.termsOfService,
                title: Localizable.t('help.terms'),
                onPress: () =>
                    Linking.openURL(
                        'https://bellhop.app/terms/?utm_source=bellhop-app&utm_medium=app-menu&utm_campaign=terms'
                    ),
            },
            {
                icon: Images.help.privacyPolicy,
                title: Localizable.t('help.privacy'),
                onPress: () =>
                    Linking.openURL(
                        'https://bellhop.app/privacy/?utm_source=bellhop-app&utm_medium=app-menu&utm_campaign=privacy'
                    ),
            },
        ],
    },
];

interface Props {
    navigationStore: NavigationStore;
}

@inject(({ navigationStore }: Stores) => ({
    navigationStore,
}))
@observer
export default class Help extends Component<Props> {
    componentDidMount() {
        Analytics.trackScreenView('04_Help');
    }

    renderSectionHeader = ({ section }: any) => {
        const { title } = section;
        return title ? (
            <>
                <View style={HelpStyles.sectionSeparator} />
                <View style={HelpStyles.sectionHeader}>
                    <Text style={HelpStyles.sectionHeaderText}>{title}</Text>
                </View>
            </>
        ) : (
            <View style={HelpStyles.sectionHeader} />
        );
    };

    renderItemSeparator = () => <View style={HelpStyles.itemSeparator} />;

    renderItem = ({ item }: { item: Item }) => {
        const { onPress, title, icon } = item;
        return (
            <TouchableOpacity onPress={onPress} style={HelpStyles.itemContainer}>
                <View style={HelpStyles.itemInnerContainer}>
                    <Image source={icon} style={HelpStyles.itemImage} />
                    <Text style={HelpStyles.itemTitle}>{title}</Text>
                </View>
                <View style={HelpStyles.itemArrowRightView}>
                    <Image source={Images.help.arrowRight} style={HelpStyles.itemArrowRight} />
                </View>
            </TouchableOpacity>
        );
    };

    render() {
        const {
            navigationStore: { goBack },
        } = this.props;

        return (
            <View style={HelpStyles.container}>
                <EmptyNavigationBar
                    handleBackClick={() => goBack()}
                    style={{ paddingHorizontal: 10 }}
                />
                <View style={HelpStyles.titleView}>
                    <Text style={HelpStyles.title}>{Localizable.t('help.help')}</Text>
                    <View style={HelpStyles.emptyHeight5} />
                    <Text style={HelpStyles.subtitle}>{Localizable.t('help.problem')}</Text>
                </View>
                <SectionList
                    sections={sections}
                    renderItem={this.renderItem}
                    renderSectionHeader={this.renderSectionHeader}
                    ItemSeparatorComponent={this.renderItemSeparator}
                />
            </View>
        );
    }
}

/* eslint-disable camelcase */
import axios from 'axios';

import DeviceInfo from 'modules/deviceInfo/deviceInfo';
import CurbCredentials from 'domain/services/Curb/CurbCredentials';
import proxy from '../Proxy';

const instance = axios.create({
    baseURL: `https://www.ridecharge.com/en_US/services/mobile/tm/5.10.0/android/9/${DeviceInfo.getUniqueId()}/rc/`,
    timeout: 40000,
});

class CurbClient {
    constructor() {
        instance.interceptors.request.use(request => {
            console.log('[CurbClient] Starting Request:', { ...request });
            return proxy(request);
        });
        instance.interceptors.response.use(
            response => {
                console.log('[CurbClient] Response:', { ...response });
                return response;
            },
            error => {
                console.log('[CurbClient] Response error:', { ...error });
                return Promise.reject(error);
            }
        );
    }

    getHeaders = () => ({
        'Content-Type': 'application/json',
    });

    getHeadersForUser = (userCredentials: CurbCredentials) => ({
        'Content-Type': 'application/json',
        user: userCredentials.email,
        'X-Password': userCredentials.password,
        'X-NewRelic-ID': 'VQ4AV1BbGwIIXVFXAwM',
    });

    register = ({ first_name, last_name, phone, email, password }: CurbCredentials) => {
        return instance.post(
            '/register',
            { email, first_name, last_name, latitude: '0', longitude: '0', password, phone },
            { headers: this.getHeaders() }
        );
    };

    acceptTerms = (credentials: CurbCredentials) => {
        return instance.put(
            '/accept_terms',
            {},
            {
                headers: this.getHeadersForUser(credentials),
            }
        );
    };
}

export default new CurbClient();

import React, { Component } from 'react';
import moment from 'moment';
import {
    View,
    Text,
    TouchableOpacity,
    Image,
    ActivityIndicator,
    ImageSourcePropType,
} from 'react-native';
import { observer, inject } from 'mobx-react';
import { computed, observable, action } from 'mobx';

import TaxiStore from 'stores/TaxiStore';
import NewProviderBadge from './badge/NewProviderBadge';
import RowStyles from './styles/RowStyles';
import SurgeBadge from './badge/SurgeBadge';
import { Images, Colors } from 'themes';
import { providerInfo } from 'services/Utils';
import { Stores } from 'stores/RootStore';
import BestValueBadge from './badge/BestValueBadge';

class TaxiRowItemStore {
    taxiStore: TaxiStore;

    @observable
    item: any;

    constructor({ item, taxiStore }: Props) {
        this.taxiStore = taxiStore;
        this.item = item;
    }

    @action
    updateItem = (item: any) => {
        this.item = item;
    };

    @computed
    get price() {
        return this.item.exactPrice && this.item.exactPrice.display
            ? this.item.exactPrice.display
            : this.item.price_info.rate_estimate;
    }

    @computed
    get priceWithoutDiscount() {
        return this.item.price_info.regular_rate_estimate;
    }

    @computed
    get eta() {
        const { taxiRouteStore } = this.taxiStore;
        return (
            this.item.initial_eta + (this.item.ride_duration || taxiRouteStore.rideDuration || 0)
        );
    }

    @computed
    get destinationTime() {
        return moment()
            .add(this.eta, 's')
            .format('h:mma');
    }

    @computed
    get surgeMultiplier() {
        // return this.item.surge_multiplier || 1;
        return this.item.price_info.index || this.item.surge_multiplier || null;
    }

    @computed
    get surgeMultiplierString() {
        if (this.surgeMultiplier === 1) {
            return `${this.surgeMultiplier}`;
        }
        if (this.surgeMultiplier < 1) {
            return Number(this.surgeMultiplier)
                .toFixed(1)
                .substring(1);
        }
        return Number(this.surgeMultiplier).toFixed(1);
    }

    @computed
    get surge() {
        // return this.surgeMultiplier > 1;
        return !!this.surgeMultiplier;
    }

    @computed
    get surgeColorIndex(): number {
        // return this.surgeMultiplier < 4 ? Math.floor(this.surgeMultiplier - 1) : 3;
        if (this.surgeMultiplier < 1) {
            return 0;
        }
        if (this.surgeMultiplier < 3 && this.surgeMultiplier >= 2) {
            return 2;
        }
        if (this.surgeMultiplier >= 3) {
            return 3;
        }
        return 1;
    }

    @computed
    get surgeColors() {
        return Colors.surge[this.surgeColorIndex];
    }

    @computed
    get surgeImage(): ImageSourcePropType | null {
        if (this.surgeMultiplier < 1) {
            return Images.badges.surgeGreen;
        }
        if (this.surgeMultiplier < 3 && this.surgeMultiplier >= 2) {
            return Images.badges.surgeYellow;
        }
        if (this.surgeMultiplier >= 3) {
            return Images.badges.surgeRed;
        }
        return null;
    }

    @computed
    get isNewProvider(): boolean {
        const {
            taxiResultsStore: {
                newProviderStore: { alreadySeenAsNewProvider },
            },
        } = this.taxiStore;
        return this.item.is_new_provider && !alreadySeenAsNewProvider(this.item.service_provider);
    }

    @computed
    get isBestValue(): boolean {
        const { taxiResultsStore } = this.taxiStore;
        return taxiResultsStore.bestValue && taxiResultsStore.bestValue === this.item.offer_id;
    }

    @computed
    get fareIndex(): number {
        return this.item.price_info.index;
    }
}

interface InjectedProps {
    taxiStore: TaxiStore;
}

interface OwnProps {
    item: any;
    index: number;
    grouped?: boolean;
    testID?: string;
}

type Props = InjectedProps & OwnProps;

@inject(({ taxiStore }: Stores) => ({ taxiStore }))
@observer
export default class TaxiRow extends Component<Props> {
    taxiRowItemStore = new TaxiRowItemStore(this.props);

    onItemClick = () => {
        const {
            taxiStore: {
                selectedTaxiStore: { taxiSelected },
            },
            item,
            index,
        } = this.props;
        taxiSelected(item, index);
    };

    componentDidUpdate() {
        this.taxiRowItemStore.updateItem(this.props.item);
    }

    render() {
        const {
            destinationTime,
            isBestValue,
            isNewProvider,
            item,
            price,
            priceWithoutDiscount,
            surge,
            surgeColors,
            surgeMultiplierString,
            surgeImage,
        } = this.taxiRowItemStore;
        const { grouped, testID } = this.props;
        return (
            <TouchableOpacity
                onPress={this.onItemClick}
                testID={testID}
                style={RowStyles.outerContainer}
            >
                <View style={RowStyles.container}>
                    <Image
                        source={providerInfo(item.service_provider).icon}
                        style={RowStyles.providerIcon}
                    />
                    <View style={RowStyles.mainView}>
                        <View style={RowStyles.providerTitleView}>
                            <Text style={RowStyles.rowCompanyText} numberOfLines={1}>
                                {item.provider.company}
                            </Text>
                            {isNewProvider && <NewProviderBadge />}
                        </View>
                        <View style={RowStyles.rowCenter}>
                            <Image
                                style={RowStyles.seatsImage}
                                source={grouped ? Images.icons.seats : Images.icons.car}
                                resizeMode="contain"
                            />
                            <Text style={RowStyles.rowValueText}>
                                <Text style={RowStyles.boldText}>
                                    {grouped
                                        ? `${item.max_passengers > 1 ? '1-' : ''}${
                                              item.max_passengers
                                          }`
                                        : `${item.service_level}`}
                                </Text>{' '}
                                • ETA:{' '}
                                <Text style={RowStyles.boldText}>
                                    {item.initial_eta ? destinationTime : 'N/A'}
                                </Text>
                            </Text>
                        </View>
                    </View>
                    <View style={RowStyles.badgeView}>
                        {(isBestValue && <BestValueBadge />) ||
                            (surge && (
                                <SurgeBadge
                                    image={surgeImage}
                                    surgeColors={surgeColors}
                                    surgeMultiplierString={surgeMultiplierString}
                                />
                            ))}
                    </View>
                    <View style={RowStyles.valueContainer}>
                        <View style={RowStyles.rowCenter}>
                            {(item.exactPriceLoading && <ActivityIndicator />) || (
                                <View style={RowStyles.rowCenter}>
                                    <View style={RowStyles.priceView}>
                                        <Text
                                            numberOfLines={1}
                                            style={[
                                                RowStyles.priceText,
                                                {
                                                    color:
                                                        // isBestValue
                                                        //     ? Colors.destinationColor
                                                        //     :
                                                        '#9AA2A8',
                                                },
                                            ]}
                                        >
                                            {price}
                                        </Text>
                                    </View>
                                </View>
                            )}
                        </View>
                        {!!priceWithoutDiscount && (
                            <View style={[RowStyles.rowCenter, { marginTop: 2 }]}>
                                <View
                                    style={[
                                        RowStyles.priceView,
                                        {
                                            backgroundColor: '#fff',
                                        },
                                    ]}
                                >
                                    <Text
                                        style={[
                                            RowStyles.priceText,
                                            {
                                                textDecorationLine: 'line-through',
                                                color: '#9AA2A8',
                                            },
                                        ]}
                                    >
                                        {priceWithoutDiscount}
                                    </Text>
                                </View>
                            </View>
                        )}
                    </View>
                </View>
            </TouchableOpacity>
        );
    }
}

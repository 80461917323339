import { StyleSheet } from 'react-native';
import { Metrics, Colors, Fonts } from 'themes';

export default StyleSheet.create({
    container: {
        // backgroundColor: 'green',
        padding: 4,
        alignItems: 'center',
        justifyContent: 'center',
    },
    text: {
        fontFamily: Fonts.type.base,
        fontWeight: '500',
        fontSize: Metrics.normalize(16),
        color: Colors.grayTextColor,
    },
    title: {
        fontFamily: Fonts.type.base,
        fontWeight: '600',
        fontSize: Metrics.normalize(20),
        color: Colors.black,
    },
    buttonContainer: {
        alignItems: 'center',
        justifyContent: 'center',
        height: Metrics.normalize(48),
        borderRadius: Metrics.normalize(12),
        shadowColor: 'black',
        shadowRadius: 10,
        shadowOpacity: 0.1,
        shadowOffset: { width: 0, height: 0 },
        elevation: 2,
        paddingHorizontal: 20,
        marginTop: 8,
    },
    buttonLabel: {
        textAlign: 'center',
        textAlignVertical: 'center',
        backgroundColor: 'transparent',
        fontFamily: Fonts.type.base,
        fontWeight: '500',
        fontSize: Metrics.normalize(16),
    },
});

import { Platform } from 'react-native';
import RestClient from './RestClient';

type WebSocketTaxiResultType = 'CLOSE' | 'SEARCH_RESULTS' | 'PRICE_PREDICTIONS';

export interface WebSocketMessage {
    data: any;
    type: WebSocketTaxiResultType;
}

export const TaxiResultsSocketService = (
    url: string,
    onOpen: Function,
    onResultsReceived: (message: WebSocketMessage) => void,
    onClose: Function
) => {
    const encodedUrl = encodeURI(url);
    const ws = new WebSocket(encodedUrl, '', { headers: RestClient.getTaxiHeader() });
    ws.onopen = () => {
        onOpen();
    };
    ws.onmessage = (response: WebSocketMessage) => {
        const data = JSON.parse(response.data);
        // Workaround for not working onClose on Android
        if (data.length === 0 && Platform.OS === 'android') {
            onClose();
        } else {
            onResultsReceived(data);
        }
    };
    ws.onerror = e => {
        console.log('[ERROR] TaxiResultsSockerService:', e.message);
    };
    ws.onclose = () => {
        onClose();
    };
};

import { persist } from 'mobx-persist';
import { observable, action, flow, when } from 'mobx';

import BaseStore from './BaseStore';
import ExampleRouteStore from './dataStores/ExampleRouteStore';
import { PersistDataStore } from './RootStore';

export default class OnboardingStore extends BaseStore implements PersistDataStore {
    hydrateStore = (hydrate: Function) => {
        return hydrate('onboardingStore', this);
    };

    exampleRouteStore = new ExampleRouteStore(this.rootStore);

    @persist
    @observable
    shouldShowOnboarding = true;

    showOnboarding = () => {
        const {
            rootStore: {
                stores: {
                    addressStore,
                    navigationStore: { navigate },
                },
            },
            exampleRouteStore: { getNearbyAddresses },
        } = this;
        if (this.shouldShowOnboarding) {
            navigate('GetStarted');
            when(
                () => !!addressStore.pickupLocation,
                () => getNearbyAddresses()
            );
        }
    };

    @action
    dismissOnboarding = () => {
        const {
            navigationStore: { navigate },
            suggestionsStore: { updateText },
            taxiStore: { setSuggestionsState },
        } = this.rootStore.stores;
        updateText();
        setSuggestionsState('landing');
        this.shouldShowOnboarding = false;
        navigate('MainView');
    };
}

import React from 'react';
import { Text, TouchableOpacity, View, ViewStyle } from 'react-native';

import { Fonts } from '../themes';
import ProviderButtonStyles from './styles/ProviderButtonStyles';

interface Props {
    handlePress: () => void;
    title: string;
    style?: ViewStyle;
    color: string;
    textColor: string;
    active?: boolean;
}

export default ({
    handlePress = () => {},
    title,
    style = {},
    color,
    textColor,
    active = true,
}: Props) => {
    return (
        <TouchableOpacity style={style} onPress={handlePress} disabled={!active}>
            <View style={ProviderButtonStyles.container}>
                <View style={[ProviderButtonStyles.innerContainer, { backgroundColor: color }]}>
                    <Text style={[ProviderButtonStyles.titleText, { color: textColor }]}>
                        {title}
                    </Text>
                </View>
            </View>
        </TouchableOpacity>
    );
};

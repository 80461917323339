import { observable, action } from 'mobx';

import { Snap } from 'components/drawer/BottomDrawer';
import { BaseStore } from '../..';

export default class MapLandingRefStore extends BaseStore {
    mapLandingBackHandler?: (() => void) | null;

    @observable
    mapLandingCurrentSnap?: Snap | null;

    setMapLandingBackHandler = (backHandler: (() => void) | null) => {
        this.mapLandingBackHandler = backHandler;
    };

    @action
    setMapLandingCurrentSnap = (currentSnap: Snap | null) => {
        this.mapLandingCurrentSnap = currentSnap;
    };
}

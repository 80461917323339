import ShareIntentAndroid from 'react-native-send-intent';
import moment, { Moment } from 'moment';
import { observable, action, flow } from 'mobx';

import Alert from 'modules/alert/alert';

import { BaseStore } from '../..';
import { RestClient } from 'services';
import { Analytics } from 'lib';
import { Linking, Platform } from 'react-native';
import { providerInfo } from 'services/Utils';
import { Metrics } from 'themes';

export default class SelectedTaxiDeeplinkStore extends BaseStore {
    @observable
    deeplinkStart?: Moment;

    @action
    clearDeeplinkStart = () => {
        this.deeplinkStart = undefined;
    };

    handleDeeplink = flow(function*(this: SelectedTaxiDeeplinkStore) {
        try {
            const {
                taxiStore: { selectedTaxiStore },
            } = this.rootStore.stores;
            const deeplink =
                selectedTaxiStore.selectedTaxi.deeplinks[Platform.OS] ||
                selectedTaxiStore.selectedTaxi.deeplinks[
                    Metrics.isWebAndroid() ? 'android' : 'ios'
                ];
            const deeplinkSupported = yield Linking.canOpenURL(deeplink);
            if (deeplink.startsWith('bundle://') && Platform.OS !== 'web') {
                const bundle = deeplink.replace('bundle://', '');
                this.handleAppBundleAndroid(bundle);
            } else if (deeplink.startsWith('fb') || deeplinkSupported) {
                Analytics.trackDeeplink(
                    selectedTaxiStore.selectedTaxi.service_provider,
                    selectedTaxiStore.selectedTaxi.price_info.rate_estimate,
                    selectedTaxiStore.selectedTaxi.initial_eta,
                    selectedTaxiStore.selectedTaxi.service_level,
                    selectedTaxiStore.selectedTaxi.offer_id
                );
                this.sendDeeplinkRequest();
                this.deeplinkStart = moment();
                yield Linking.openURL(deeplink);
            } else {
                this.showMissingAppPopup();
                this.sendDeeplinkRequest();
            }
        } catch (error) {
            this.showMissingAppPopup();
        }
    }).bind(this);

    handleAppBundleAndroid = flow(function*(this: SelectedTaxiDeeplinkStore, appBundle: string) {
        try {
            const {
                taxiStore: { selectedTaxiStore },
            } = this.rootStore.stores;
            Analytics.trackDeeplink(
                selectedTaxiStore.selectedTaxi.service_provider,
                selectedTaxiStore.selectedTaxi.price_info.rate_estimate,
                selectedTaxiStore.selectedTaxi.initial_eta,
                selectedTaxiStore.selectedTaxi.service_level,
                selectedTaxiStore.selectedTaxi.offer_id
            );
            this.sendDeeplinkRequest();
            const appInstalled = yield ShareIntentAndroid.isAppInstalled(appBundle);
            if (appInstalled) {
                ShareIntentAndroid.openApp(appBundle, {});
                this.deeplinkStart = moment();
            } else {
                this.showMissingAppPopup();
            }
        } catch (error) {
            this.showMissingAppPopup();
        }
    }).bind(this);

    sendDeeplinkRequest = flow(function*(this: SelectedTaxiDeeplinkStore) {
        try {
            const {
                authStore: { user },
                taxiStore: { selectedTaxiStore, taxiRouteStore },
            } = this.rootStore.stores;
            const price = selectedTaxiStore.selectedTaxi.price_info.rate_estimate.match(/\d+/);
            const { data } = yield RestClient.handleDeeplinkHail(
                selectedTaxiStore.selectedTaxi.search_id,
                selectedTaxiStore.selectedTaxi.service_provider,
                selectedTaxiStore.selectedTaxi.provider.company
            );
            if (data && data.savings) {
                Analytics.trackSavings(user?.user_id, data.savings);
            }
            if (price && price.length) {
                Analytics.trackTaxiRideDetails(
                    selectedTaxiStore.selectedTaxi.service_provider,
                    selectedTaxiStore.selectedTaxi.service_level,
                    selectedTaxiStore.selectedTaxi.offer_id
                );
                Analytics.trackPurchase(
                    price[0],
                    selectedTaxiStore.selectedTaxi.price_info.currency,
                    true,
                    true,
                    selectedTaxiStore.selectedTaxi.service_provider,
                    selectedTaxiStore.selectedTaxi.provider.company,
                    {
                        estimate: selectedTaxiStore.selectedTaxi.price_info.rate_estimate,
                        eta: selectedTaxiStore.selectedTaxi.initial_eta,
                    },
                    data.request_id
                );
                Analytics.trackBooking('Combined', {
                    fare_estimate: price[0],
                    eta: moment()
                        .add(
                            selectedTaxiStore.selectedTaxi.initial_eta +
                                (selectedTaxiStore.selectedTaxi.ride_duration ||
                                    taxiRouteStore.rideDuration ||
                                    0),
                            's'
                        )
                        .format('h:mma'),
                    provider: selectedTaxiStore.selectedTaxi.service_provider,
                    service_type: selectedTaxiStore.selectedTaxi.service_level,
                    search_id: selectedTaxiStore.selectedTaxi.offer_id,
                });
            }
        } catch (error) {
            console.log('sendDeeplinkRequest error', error);
        }
    }).bind(this);

    showMissingAppPopup = () => {
        const {
            taxiStore: { selectedTaxiStore },
        } = this.rootStore.stores;
        Alert.alert(
            'Alert',
            `Please install ${
                providerInfo(selectedTaxiStore.selectedTaxi.service_provider).name
            } by continuing to the store to complete the booking.`,
            [
                {
                    text: 'Cancel',
                    onPress: () => {},
                    style: 'cancel',
                },
                {
                    text: 'Continue',
                    onPress: () => {
                        if (
                            selectedTaxiStore.selectedTaxi.store &&
                            selectedTaxiStore.selectedTaxi.store[Platform.OS]
                        ) {
                            Linking.openURL(selectedTaxiStore.selectedTaxi.store[Platform.OS]);
                        }
                    },
                },
            ]
        );
    };
}

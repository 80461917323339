import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { View, TouchableOpacity, Text, Image } from 'react-native';
import Animatable from 'modules/animatable/animatable';
import { observer, inject } from 'mobx-react';

import RideAddressPickerStyles from './styles/RideAddressPickerStyles';
import { Images, Colors } from '../../themes';
import { trimAddress } from '../../services/Utils';
import { Localizable } from '../../lib';
import { Stores } from '../../stores/RootStore';
import { AddressStore, TaxiStore } from 'stores';

interface Props {
    addressStore: AddressStore;
    taxiStore: TaxiStore;
}

export default inject(({ addressStore, taxiStore }: Stores) => ({ addressStore, taxiStore }))(
    observer(({ addressStore, taxiStore, taxiStore: { taxiRideStore } }: Props) => {
        if (
            !taxiRideStore.currentRide ||
            !taxiRideStore.currentRide.ask ||
            !taxiRideStore.currentRideStatus
        ) {
            return null;
        }
        const isDestination = taxiRideStore.currentRideStatus.status !== 'PENDING';
        const canChangeDestination = false;
        let address = isDestination
            ? taxiRideStore.currentRide.ask.destination_address
            : taxiRideStore.currentRide.ask.pickup_address;
        address = trimAddress(address);
        return (
            <Animatable.View
                animation="fadeIn"
                pointerEvents="box-none"
                duration={300}
                style={RideAddressPickerStyles.container}
                useNativeDriver
            >
                <View style={[RideAddressPickerStyles.listContainer]}>
                    <View style={RideAddressPickerStyles.addressContainer}>
                        <View style={RideAddressPickerStyles.addressLeftContainer}>
                            <View style={RideAddressPickerStyles.addressLeftInnerContainer}>
                                <View
                                    style={[
                                        RideAddressPickerStyles.addressDot,
                                        {
                                            backgroundColor: Colors.obiTeal,
                                        },
                                    ]}
                                />
                            </View>
                        </View>
                        <View style={RideAddressPickerStyles.locationOuterContainer}>
                            <View style={RideAddressPickerStyles.locationContainer}>
                                <Text
                                    style={[
                                        RideAddressPickerStyles.labelText,
                                        {
                                            color:
                                                addressStore.focusedInput === 'pickup'
                                                    ? Colors.obiTeal
                                                    : Colors.grayTextColor,
                                        },
                                    ]}
                                >
                                    {isDestination
                                        ? Localizable.t('taxis.rideAddressPicker.destination')
                                        : Localizable.t('taxis.rideAddressPicker.pickup')}{' '}
                                    {Localizable.t('taxis.rideAddressPicker.location')}
                                </Text>
                                {(!!taxiStore.newDestinationAddressFocused && (
                                    <View style={RideAddressPickerStyles.flexDirectionRow}>
                                        <TextInput
                                            textAlignVertical="top"
                                            style={RideAddressPickerStyles.locationText}
                                            value={taxiStore.newDestinationAddress}
                                            placeholder={Localizable.t(
                                                'taxis.rideAddressPicker.enterPickupAddress'
                                            )}
                                            placeholderTextColor={Colors.grayTextColor}
                                            onChangeText={taxiStore.updateNewDestinationText}
                                            underlineColorAndroid="transparent"
                                            onSubmitEditing={() => {
                                                taxiStore.handleBackClick();
                                            }}
                                            autoFocus
                                            autoCorrect={false}
                                        />
                                        <TouchableOpacity
                                            onPress={() => taxiStore.updateNewDestinationText('')}
                                        >
                                            <View style={RideAddressPickerStyles.cancelView}>
                                                <Image
                                                    style={RideAddressPickerStyles.cancelIcon}
                                                    source={Images.settings.close}
                                                />
                                            </View>
                                        </TouchableOpacity>
                                    </View>
                                )) || (
                                    <View style={RideAddressPickerStyles.flexDirectionRow}>
                                        <Text
                                            numberOfLines={1}
                                            style={RideAddressPickerStyles.locationText}
                                        >
                                            {address}
                                        </Text>
                                        {!!isDestination && !!canChangeDestination && (
                                            <TouchableOpacity
                                                onPress={() => {
                                                    taxiStore.updateNewDestinationText(address);
                                                    taxiStore.focusNewDestination(true);
                                                }}
                                            >
                                                <Text
                                                    style={
                                                        RideAddressPickerStyles.addressChangeText
                                                    }
                                                >
                                                    {Localizable.t(
                                                        'taxis.rideAddressPicker.change'
                                                    )}
                                                </Text>
                                            </TouchableOpacity>
                                        )}
                                    </View>
                                )}
                            </View>
                        </View>
                    </View>
                </View>
            </Animatable.View>
        );
    })
);

import React from 'react';
import { View, Text, Image, TouchableOpacity, Linking, ScrollView, StyleSheet } from 'react-native';
import { observer, inject } from 'mobx-react';
import { when } from 'mobx';

import Alert from 'modules/alert/alert';
import NetInfo from 'modules/netInfo/netInfo';
import Geolocation from 'modules/geolocation/geolocation';

import AppStore from '../../stores/AppStore';
import GetStartedStyles from './styles/GetStartedStyles';
import OnboardingStore from '../../stores/OnboardingStore';
import { Images } from '../../themes';
import { Localizable } from '../../lib';
import { Stores } from '../../stores/RootStore';

const openTermsOfService = () => {
    Linking.openURL(
        'https://bellhop.app/terms/?utm_source=bellhop-app&utm_medium=app-menu&utm_campaign=terms'
    );
};

const openPrivacyPolicy = () => {
    Linking.openURL(
        'https://bellhop.app/privacy/?utm_source=bellhop-app&utm_medium=app-menu&utm_campaign=privacy'
    );
};

interface Props {
    appStore: AppStore;
    onboardingStore: OnboardingStore;
}

export default inject(({ appStore, onboardingStore }: Stores) => ({
    appStore,
    onboardingStore,
}))(
    observer(
        ({
            appStore: { showLoading, hideLoading },
            onboardingStore: {
                dismissOnboarding,
                exampleRouteStore,
                exampleRouteStore: {
                    setDestination,
                    setForcedNearbyLocation,
                    setForcedPickupLocation,
                },
            },
        }: Props) => {
            const onRandomExampleClick = async () => {
                const { isConnected } = await NetInfo.fetch();
                if (!isConnected) {
                    Alert.alert('No internet connection', 'Turn on data transfer to continue.', [
                        { text: 'Cancel', onPress: dismissOnboarding },
                        { text: 'OK', onPress: () => {} },
                    ]);
                } else {
                    await Geolocation.getCurrentPosition(
                        () => {
                            showLoading();
                            when(
                                () => !!exampleRouteStore.nearbyAddressesFetched,
                                () => {
                                    hideLoading();
                                    setDestination();
                                }
                            );
                        },
                        () => {
                            setForcedNearbyLocation();
                            setForcedPickupLocation();
                            hideLoading();
                            setDestination();
                        }
                    );
                }
            };
            const onTermsOfServiceClick = () => {
                dismissOnboarding();
                openTermsOfService();
            };
            const onPrivacyPolicyCLick = () => {
                dismissOnboarding();
                openPrivacyPolicy();
            };
            return (
                <View style={StyleSheet.absoluteFill}>
                    <ScrollView
                        bounces={false}
                        showsVerticalScrollIndicator={false}
                        style={GetStartedStyles.scrollView}
                        contentContainerStyle={GetStartedStyles.container}
                    >
                        <View style={GetStartedStyles.topView}>
                            <Text style={GetStartedStyles.title} numberOfLines={2}>
                                {Localizable.t('onboarding.title')}
                            </Text>
                            <Image
                                source={Images.onboarding.taxisComparation}
                                resizeMode="contain"
                                style={GetStartedStyles.image}
                            />
                        </View>
                        <View style={GetStartedStyles.buttonsView}>
                            <TouchableOpacity
                                style={GetStartedStyles.exampleButton}
                                onPress={onRandomExampleClick}
                            >
                                <Text style={GetStartedStyles.exampleButtonText}>
                                    {Localizable.t('onboarding.example')}
                                </Text>
                            </TouchableOpacity>
                        </View>
                    </ScrollView>
                    <View style={GetStartedStyles.bottomView}>
                        <View style={GetStartedStyles.horizontalLine} />
                        <View style={GetStartedStyles.termsView}>
                            <Text style={GetStartedStyles.termsText} numberOfLines={2}>
                                {Localizable.t('onboarding.disclaimer')}{' '}
                                <Text
                                    style={GetStartedStyles.termsUnderlinedText}
                                    onPress={onTermsOfServiceClick}
                                >
                                    {Localizable.t('onboarding.termsOfService')}
                                </Text>
                                {' & '}
                                <Text
                                    style={GetStartedStyles.termsUnderlinedText}
                                    onPress={onPrivacyPolicyCLick}
                                >
                                    {Localizable.t('onboarding.privacyPolicy')}
                                </Text>
                            </Text>
                        </View>
                    </View>
                </View>
            );
        }
    )
);

import { StyleSheet, Platform } from 'react-native';
import { Fonts, Metrics, Colors } from '../../../themes';

export default StyleSheet.create({
    container: {
        position: 'absolute',
        top: Metrics.paddingTop + 50,
        left: 0,
        right: 0,
        bottom: 0,
    },
    listContainer: {
        marginHorizontal: 20,
        borderRadius: 6,
        backgroundColor: 'white',
        shadowColor: 'black',
        shadowRadius: 5,
        shadowOpacity: 0.1,
        shadowOffset: { width: 0, height: 0 },
        elevation: 2,
    },
    locationOuterContainer: {
        flex: 1,
        paddingRight: 10,
    },
    locationContainer: {
        flex: 1,
        justifyContent: 'center',
        paddingVertical: 5,
    },
    labelText: {
        marginTop: Platform.OS === 'ios' ? 0 : 5,
        fontSize: 10,
        color: Colors.grayTextColor,
        fontFamily: Fonts.type.base,
        fontWeight: '500',
    },
    locationText: {
        fontSize: 15,
        color: Colors.black,
        fontFamily: Fonts.type.base,
        fontWeight: '500',
        flex: 1,
    },
    addressContainer: {
        flexDirection: 'row',
        height: 60,
    },
    addressLeftContainer: {
        width: 60,
        alignItems: 'center',
        justifyContent: 'center',
    },
    addressLeftInnerContainer: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    addressDot: {
        width: 8,
        height: 8,
        borderRadius: 4,
        backgroundColor: Colors.grayTextColor,
    },
    cancelView: {
        width: 30,
        height: 30,
        alignItems: 'center',
        justifyContent: 'center',
    },
    cancelIcon: {
        width: 12,
        height: 12,
        opacity: 0.5,
    },
    addressChangeText: {
        color: Colors.obiTeal,
        fontFamily: Fonts.type.base,
        fontSize: 16,
        backgroundColor: 'transparent',
    },
    flexDirectionRow: {
        flexDirection: 'row',
    },
});

import React from 'react';
import { createNavigator, SceneView, StackRouter } from '@react-navigation/core';
import { createBrowserApp } from '@react-navigation/web';

import BoltConnectingRouter from './providerConnecting/providers/bolt/BoltConnectingRouter.web';
import ConnectingInfo from 'components/providerConnecting/ConnectingInfo';
import CurbRegisterRouter from './providerConnecting/providers/curb/CurbRegisterRouter.web';
import LyftConnectingRouter from './providerConnecting/providers/lyft/LyftConnectingRouter.web';
import LyftRegisterRouter from './providerConnecting/providers/lyft/LyftRegisterRouter.web';
import OnboardingRouter from './onboarding/OnboardingRotuer.web';
import ProfileRouter from './profile/ProfileRouter.web';
import ProviderConnecting from 'components/providerConnecting/ProviderConnecting';
import ProviderConnectingRouter from './providerConnecting/ProviderConnectingRouter.web';
import ServicesRouter from './services/ServicesRouter.web';
import UberConnectingRouter from './providerConnecting/providers/uber/UberConnectingRouter.web';
import UberRegisterRouter from './providerConnecting/providers/uber/UberRegisterRouter.web';
import { MainView } from 'containers/taxis';
import { AdditionalInfo, CountryPicker } from 'containers/setup';
import {
    AddCreditCard,
    FavoriteAddressPicker,
    Help,
    PaymentMethods,
    RideHistory,
    RideReceipt,
    Services,
    Settings,
} from 'containers/profile';
import {
    ProviderConnectingArro,
    ProviderConnectingCurb,
    ProviderConnectingVia,
} from 'components/providerConnecting/providers';

interface NavigatorViewProps {
    navigation: any;
    descriptors: any;
}

const NavigatorView = ({ descriptors, navigation: { state, screenProps } }: NavigatorViewProps) => {
    const { getComponent, navigation } = descriptors[state.routes[state.index].key];
    return (
        <SceneView
            style={{ flex: 1 }}
            component={getComponent()}
            navigation={navigation}
            screenProps={screenProps}
        />
    );
};

const Navigator = createNavigator(
    NavigatorView,
    StackRouter(
        {
            MainView,
            OnboardingRouter,
            RideHistory,
            Help,
            RideReceipt,
            CountryPicker,
            PaymentMethods,
            AddCreditCard,
            Settings,
            FavoriteAddressPicker,
            AdditionalInfo,
            ProfileRouter,
            Services,
            ConnectingInfo,
            ProviderConnectingArro,
            BoltConnectingRouter,
            ProviderConnectingCurb,
            CurbRegisterRouter,
            LyftConnectingRouter,
            LyftRegisterRouter,
            UberConnectingRouter,
            UberRegisterRouter,
            ProviderConnectingVia,
        },
        {}
    ),
    { navigationOptions: {} }
);

const Router = createBrowserApp(Navigator, { history: 'memory' });

export default Router;

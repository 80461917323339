import React from 'react';
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';

import { Metrics, Fonts, Colors } from 'themes';

type AlertOptions = {
    cancellable?: boolean;
    onDismiss?: () => void;
};

type AlertButtonStyle = 'cancel' | 'default';

interface AlertButtonProps {
    text: string;
    onPress: () => void;
    style: AlertButtonStyle;
}

const AlertStyles = StyleSheet.create({
    container: {
        zIndex: 2000,
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        alignItems: 'center',
        justifyContent: 'center',
    },
    background: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: '#000',
        opacity: 0.5,
    },
    alertContainer: {
        borderRadius: 14,
        marginHorizontal: 30,
        backgroundColor: Colors.grayBackgroundColor,
        overflow: 'hidden',
    },
    textView: {
        paddingHorizontal: 30,
        paddingVertical: 20,
        alignItems: 'center',
        justifyConent: 'center',
    },
    title: {
        fontSize: Metrics.normalize(12),
        fontWeight: '600',
        color: Colors.darkTextColor,
        fontFamily: Fonts.type.base,
        marginBottom: 10,
        textAlign: 'center',
    },
    subtitle: {
        fontSize: Metrics.normalize(10),
        fontWeight: '400',
        color: Colors.grayTextColor,
        fontFamily: Fonts.type.base,
        textAlign: 'center',
    },
    buttonView: {
        borderBottomLeftRadius: 14,
        borderBottomRightRadius: 14,
        flexDirection: 'row',
        borderTopWidth: 0.5,
        borderTopColor: '#000',
    },
    buttonDefault: {
        flex: 1,
        paddingVertical: 10,
        alignItems: 'center',
        justifyContent: 'center',
    },
    buttonCancel: {
        flex: 1,
        paddingVertical: 10,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'red',
    },
    buttonSeparator: {
        width: 0.5,
        alignSelf: 'stretch',
        backgroundColor: '#000',
    },
});

class AlertStore {
    @observable
    isVisible = false;

    @action
    setVisible = () => {
        this.isVisible = true;
    };

    @action
    dismiss = () => {
        this.isVisible = false;
    };

    @observable
    title = '';

    @observable
    subtitle = '';

    @observable
    buttons = [];

    @observable
    options: AlertOptions = {
        cancellable: true,
        onDismiss: () => {},
    };

    @action
    setProps = (
        title?: string,
        subtitle?: string,
        buttons?: Array<JSX.Element>,
        options?: AlertOptions
    ) => {
        this.title = title || '';
        this.subtitle = subtitle || '';
        this.buttons = buttons || [];
        this.options = options || {
            cancellable: true,
            onDismiss: () => {},
        };
    };

    @action
    clearProps = () => {
        this.title = '';
        this.subtitle = '';
        this.buttons = [];
        this.options = {
            cancellable: true,
            onDismiss: () => {},
        };
    };
}

const AlertButton = ({ text, onPress, style }: AlertButtonProps) => (
    <TouchableOpacity
        // style={style === 'cancel' ? AlertStyles.buttonCancel : AlertStyles.buttonDefault}
        style={AlertStyles.buttonDefault}
        onPress={onPress}
    >
        <Text>{text}</Text>
    </TouchableOpacity>
);

const alertStore = new AlertStore();

const Alert = observer(() => {
    return alertStore.isVisible ? (
        <View style={AlertStyles.container}>
            <View style={AlertStyles.background} />
            <View style={AlertStyles.alertContainer}>
                <View style={AlertStyles.textView}>
                    <Text style={AlertStyles.title}>{alertStore.title}</Text>
                    <Text style={AlertStyles.subtitle}>{alertStore.subtitle}</Text>
                </View>
                <View style={AlertStyles.buttonView}>
                    {alertStore.buttons.map((button: AlertButtonProps, index) => (
                        <React.Fragment key={`alertButton${index}_${button.text}`}>
                            <AlertButton
                                {...button}
                                onPress={() => {
                                    alertStore.dismiss();
                                    button.onPress();
                                }}
                            />
                            {index !== alertStore.buttons.length - 1 && (
                                <View style={AlertStyles.buttonSeparator} />
                            )}
                        </React.Fragment>
                    ))}
                </View>
            </View>
        </View>
    ) : null;
});

Alert.alert = (
    title?: string,
    subtitle?: string,
    buttons?: Array<JSX.Element>,
    options?: AlertOptions
) => {
    alertStore.setProps(title, subtitle, buttons, options);
    alertStore.setVisible();
};

export default Alert;

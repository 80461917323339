import React, { forwardRef, Ref } from 'react';
import { View, TouchableOpacity, Text, Image, TextInput, ViewStyle } from 'react-native';
import { observer } from 'mobx-react';

import AddressPickerStyles from './styles/MainAddressPickerStyles';
import { Analytics } from 'lib';
import { Images } from 'themes';
import { useStores } from 'containers/App';

interface Props {
    style: ViewStyle;
    pointerEvents: any;
    handleCloseClick: () => void;
}

export default observer(
    forwardRef(({ style, pointerEvents, handleCloseClick }: Props, ref: Ref<View>) => {
        const {
            stores: {
                addressStore,
                addressStore: {
                    onFocusStart,
                    onFocusEnd,
                    updatePickupText,
                    updateDestinationText,
                    switchPickupWithLocation,
                },
                suggestionsStore: { updateText },
            },
        } = useStores();
        const onPickupFocusStart = () => {
            Analytics.trackScreenView('02-02a_EnterPickup');
            onFocusStart('pickup');
            updateText();
        };
        const onPickupChangeText = (text: string) => {
            updatePickupText(text);
            updateText();
        };
        const onDestinationFocusStart = () => {
            Analytics.trackScreenView('02-02a_EnterDestination');
            onFocusStart('destination');
            updateText();
        };
        const onDestinationChangeText = (text: string) => {
            updateDestinationText(text);
            updateText();
        };
        const onSwitchPress = () => {
            switchPickupWithLocation();
            updateText();
        };
        return (
            <View
                key="AddressPicker"
                pointerEvents={pointerEvents}
                style={[style, AddressPickerStyles.container]}
                ref={ref}
            >
                <View key="listContainer" style={AddressPickerStyles.listContainer}>
                    <View key="addressContainer" style={AddressPickerStyles.addressContainer}>
                        <TouchableOpacity
                            style={AddressPickerStyles.closeButtonView}
                            onPress={handleCloseClick}
                        >
                            <Image
                                source={Images.addressPicker.close}
                                style={AddressPickerStyles.closeButtonImage}
                                resizeMode="contain"
                            />
                        </TouchableOpacity>
                        <View key="LeftContainer" style={AddressPickerStyles.addressLeftContainer}>
                            <Image
                                key="PickupDestinationIcon"
                                style={AddressPickerStyles.addressImage}
                                source={
                                    addressStore.focusedInput === 'pickup'
                                        ? Images.addressPicker.pickupFocused
                                        : Images.addressPicker.destinationFocused
                                }
                                resizeMode="contain"
                            />
                        </View>
                        <View style={AddressPickerStyles.inputContainer} key="InputContainer">
                            <View
                                style={AddressPickerStyles.inputContainerRow}
                                key="PickupInputContainer"
                            >
                                <TextInput
                                    testID="input_pickup"
                                    accessible
                                    key="Pickup"
                                    ref={addressStore.pickupInputRef}
                                    textAlignVertical="top"
                                    style={AddressPickerStyles.locationText}
                                    value={addressStore.pickup || ''}
                                    placeholder="Pickup location"
                                    placeholderTextColor="#C1C3C1"
                                    onChangeText={onPickupChangeText}
                                    onFocus={onPickupFocusStart}
                                    onEndEditing={() => onFocusEnd('pickup')}
                                    underlineColorAndroid="transparent"
                                    autoCorrect={false}
                                    returnKeyType="search"
                                />
                                {addressStore.showPickupDeleteButton && (
                                    <TouchableOpacity
                                        hitSlop={{ bottom: 5, top: 5, left: 5, right: 5 }}
                                        style={AddressPickerStyles.deleteInputButton}
                                        onPress={() => onPickupChangeText('')}
                                    >
                                        <Image
                                            source={Images.addressPicker.close}
                                            style={AddressPickerStyles.deleteInputIcon}
                                            resizeMode="contain"
                                        />
                                    </TouchableOpacity>
                                )}
                            </View>
                            <View
                                style={AddressPickerStyles.inputContainerRow}
                                key="DestinationInputContainer"
                            >
                                <TextInput
                                    testID="input_destination"
                                    accessible
                                    key="Destination"
                                    ref={addressStore.destinationInputRef}
                                    textAlignVertical="top"
                                    style={AddressPickerStyles.locationText}
                                    value={addressStore.destination || ''}
                                    placeholder="Where to?"
                                    placeholderTextColor="#C1C3C1"
                                    onChangeText={onDestinationChangeText}
                                    onFocus={onDestinationFocusStart}
                                    onEndEditing={() => onFocusEnd('destination')}
                                    underlineColorAndroid="transparent"
                                    autoCorrect={false}
                                    returnKeyType="search"
                                />
                                {addressStore.showDestinationDeleteButton && (
                                    <TouchableOpacity
                                        hitSlop={{ bottom: 5, top: 5, left: 5, right: 5 }}
                                        style={AddressPickerStyles.deleteInputButton}
                                        onPress={() => onDestinationChangeText('')}
                                    >
                                        <Image
                                            source={Images.addressPicker.close}
                                            style={AddressPickerStyles.deleteInputIcon}
                                            resizeMode="contain"
                                        />
                                    </TouchableOpacity>
                                )}
                            </View>
                        </View>
                        <TouchableOpacity
                            style={AddressPickerStyles.rightContainer}
                            onPress={onSwitchPress}
                        >
                            <Image
                                source={Images.addressPicker.switch}
                                style={AddressPickerStyles.switchButtonImage}
                                resizeMode="contain"
                            />
                        </TouchableOpacity>
                    </View>
                </View>
            </View>
        );
    })
);

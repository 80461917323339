import React from 'react';
import { Text, TouchableOpacity, ImageBackground, View, ViewStyle } from 'react-native';

import GradientButtonStyles from './styles/GradientButtonStyles';
import { Images } from '../themes';

interface Props {
    active?: boolean;
    handlePress: () => void;
    title: string;
    style?: ViewStyle;
}

export default ({ handlePress = () => {}, title, style = {} }: Props) => (
    <TouchableOpacity style={style} onPress={handlePress}>
        <View style={GradientButtonStyles.innerContainer}>
            <ImageBackground
                style={GradientButtonStyles.backgroundImage}
                source={Images.gradientButton}
            >
                <Text style={GradientButtonStyles.titleText}>{title}</Text>
            </ImageBackground>
        </View>
    </TouchableOpacity>
);

import { observable, action, flow } from 'mobx';
import { Platform, Linking } from 'react-native';

import ServicesStore from '../ServicesStore';
import ViaClient from 'services/providers/ViaClient';
import ViaResponse from 'domain/services/Via/ViaResponse';
import { Analytics } from 'lib';

export default class ViaServiceStore {
    servicesRootStore: ServicesStore;

    viaClient: ViaClient;

    constructor(servicesStore: ServicesStore) {
        this.servicesRootStore = servicesStore;
        this.viaClient = new ViaClient();
    }

    @observable loading: boolean = false;

    @observable email: string = '';

    @observable password = '';

    @observable errorMessage = '';

    @observable myIdentity?: ViaResponse;

    @action
    clearStore = () => {
        const { setRefreshing } = this.servicesRootStore;

        this.email = '';
        this.password = '';
        this.errorMessage = '';
        this.myIdentity = undefined;
        setRefreshing(false);
    };

    @action
    clearError = () => {
        this.errorMessage = '';
    };

    @action
    setViaEmail = (text: string) => {
        this.email = text;
        this.clearError();
    };

    @action
    setViaPassword = (text: string) => {
        this.password = text;
        this.clearError();
    };

    onViaCredentialsSubmit = flow(function*(this: ViaServiceStore) {
        const { handleViaLogin, setRefreshing } = this.servicesRootStore;
        setRefreshing(true);
        yield this.getViaAuthTokens();
        if (this.myIdentity) {
            yield handleViaLogin(this.myIdentity);
        }
        setRefreshing(false);
    }).bind(this);

    getViaAuthTokens = flow(function*(this: ViaServiceStore) {
        try {
            const {
                data: { my_identity },
            } = yield this.viaClient.login({
                email: this.email,
                password: this.password,
            });

            // eslint-disable-next-line camelcase
            this.myIdentity = my_identity;
        } catch (err) {
            console.log('VIA login error\n', { ...err });

            const { response: { data: { message } } = { data: { message: '' } } } = err;

            if (message) {
                this.errorMessage = message;
            } else {
                this.errorMessage = 'Unable to login';
            }
        }
    }).bind(this);

    downloadApp = () => {
        Linking.openURL(
            Platform.OS === 'android'
                ? 'https://play.google.com/store/apps/details?id=via.rider'
                : 'https://apps.apple.com/app/via-low-cost-ride-sharing/id657777015'
        )
            .then(() => {
                Analytics.trackServiceLinking('via', 'connecting', 'download_app');
            })
            .catch(() => {});
    };
}

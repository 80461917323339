import { StyleSheet, Platform } from 'react-native';
import { Fonts, Metrics, Colors } from '../../../themes';

export default StyleSheet.create({
    container: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: '#000',
    },
    navigationContainer: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        alignItems: 'center',
        backgroundColor: 'transparent',
        flexDirection: 'row',
        paddingTop: Platform.OS === 'ios' ? Metrics.paddingTop + 10 : 10,
        paddingHorizontal: 10,
    },
    navigationMiddleView: {
        flex: 1,
    },
    navigationIconView: {
        height: 60,
        flex: 0.2,
        alignItems: 'center',
        justifyContent: 'center',
    },
    navigationIconBackground: {
        width: 40,
        height: 40,
        borderRadius: 20,
        backgroundColor: Colors.darkerGrayBackgroundColor,
        alignItems: 'center',
        justifyContent: 'center',
    },
    navigationIconInnerBackground: {
        width: 35,
        height: 35,
        borderRadius: 17.5,
        backgroundColor: '#fff',
        alignItems: 'center',
        justifyContent: 'center',
    },
    navigationIcon: {
        width: 14,
        height: 14,
    },
    interactableView: {
        backgroundColor: Colors.transparent,
        alignItems: 'center',
    },
    interactableInnerView: {
        backgroundColor: '#fff',
        borderBottomLeftRadius: 16,
        borderBottomRightRadius: 16,
        shadowColor: '#000000',
        shadowOffset: { width: 0, height: 3 },
        shadowRadius: 4,
        shadowOpacity: 0.2,
        elevation: 10,
    },
    tabHeaderView: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        marginTop: Metrics.paddingTop + 60,
        paddingHorizontal: 25,
    },
    fakeBorder: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        height: 1,
        backgroundColor: Colors.darkerGrayBackgroundColor,
    },
    tabView: {
        paddingVertical: 10,
    },
    selectedTabView: {
        borderBottomColor: Colors.obiTeal,
        borderBottomWidth: 1,
    },
    tabSeparator: {
        width: 30,
    },
    tabText: {
        fontFamily: Fonts.type.base,
        fontWeight: '400',
        fontSize: Metrics.normalize(16),
    },
    contentContainer: {
        paddingHorizontal: 25,
        paddingTop: Platform.OS === 'ios' ? 10 : 15,
    },
    drawerIndicator: {
        backgroundColor: Colors.darkerGrayBackgroundColor,
        width: 45,
        height: 5,
        borderRadius: 3,
        marginVertical: 7,
    },
    modesSection: {
        height: 170,
        paddingTop: 20,
        paddingHorizontal: 20,
    },
    providersSection: {
        flex: 1,
        paddingHorizontal: 20,
    },
    doneButton: {
        paddingVertical: 15,
        alignItems: 'center',
        justifyContent: 'center',
        borderTopWidth: 1,
        borderTopColor: Colors.darkerGrayBackgroundColor,
        backgroundColor: 'white',
        borderBottomRightRadius: 10,
        borderBottomLeftRadius: 10,
    },
    doneButtonText: {
        fontFamily: Fonts.type.base,
        fontWeight: '600',
        fontSize: Metrics.normalize(16),
        color: Colors.black,
    },
    sectionTitle: {
        fontFamily: Fonts.type.base,
        fontWeight: '500',
        fontSize: Metrics.normalize(20),
        color: Colors.darkPurple,
        marginBottom: 10,
    },
    toggleAllVehiclesButton: {
        position: 'absolute',
        top: 20,
        right: 20,
        paddingVertical: 5,
        paddingHorizontal: 15,
        backgroundColor: Colors.darkerGrayBackgroundColor,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 15,
    },
});

import React from 'react';
import { View, Image } from 'react-native';

import BestValueBadgeStyles from './styles/BestValueBadgeStyles';
import { Images } from 'themes';

export default () => (
    <View style={BestValueBadgeStyles.bestValueView}>
        <Image
            source={Images.badges.bestValue}
            style={BestValueBadgeStyles.bestValueIcon}
            resizeMode="contain"
        />
    </View>
);

import React from 'react';
import { View, Text, Image, ImageSourcePropType } from 'react-native';
import { observer } from 'mobx-react';

import styles from './styles';

interface Props {
    icon: ImageSourcePropType;
    text?: string;
    time?: string;
    primary?: boolean;
}

const RouteRow = ({ icon, text, time, primary }: Props) => {
    return (
        <View style={styles.addressContainer}>
            <View style={styles.routeImage}>
                <Image source={icon} resizeMode="contain" />
            </View>
            {text && (
                <Text style={primary ? styles.textPrimary : styles.text} numberOfLines={1}>
                    {text}
                </Text>
            )}
            <View style={styles.separator} />
            {time && (
                <Text style={styles.timeText} numberOfLines={1}>
                    {time}
                </Text>
            )}
        </View>
    );
};

export default observer(RouteRow);

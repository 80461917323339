import React from 'react';
import { View, Text, Image, Platform } from 'react-native';
import { observer } from 'mobx-react';

import { Images, Colors } from '../../../../themes';
import styles from './styles';
import { bikeProviderInfo } from '../../../../services/Utils';

interface Props {
    bikesAvailable: number;
    provider: string;
    docksAvailable: number;
}

export default observer(({ bikesAvailable, provider, docksAvailable }: Props) => {
    // let imageSource = Images.pickupGradient;
    const imageSource = bikeProviderInfo(provider).icon;
    return (
        <View style={styles.container}>
            <View style={styles.innerContainer}>
                {Platform.OS === 'ios' ? (
                    <Image
                        source={imageSource}
                        style={[styles.destinationView, { marginLeft: 6, height: 40, width: 40 }]}
                        resizeMode="contain"
                    />
                ) : (
                    <View
                        style={[
                            styles.destinationView,
                            { alignSelf: 'stretch', width: 4, backgroundColor: Colors.city },
                        ]}
                    />
                )}
                <View style={styles.availableTextsView}>
                    <Text numberOfLines={1} style={styles.titleText}>
                        {`${bikesAvailable} bikes available`}
                    </Text>

                    <Text
                        numberOfLines={1}
                        style={[
                            styles.descriptionText,
                            { fontWeight: '700', color: Colors.obiTeal },
                        ]}
                    >
                        {`${docksAvailable} docks available`}
                    </Text>
                </View>

                <Image
                    source={Images.pickupArrow}
                    resizeMode="contain"
                    style={styles.pickupArrowImage}
                />
            </View>
        </View>
    );
});

/* eslint-disable react/no-array-index-key */
import React, { useRef, useEffect } from 'react';
import Voca from 'voca';
import { observer } from 'mobx-react';
import {
    View,
    TouchableOpacity,
    Image,
    Animated,
    Text,
    Keyboard,
    FlatList,
    Platform,
} from 'react-native';
import { useLocalStore, useComputed, useObserver } from 'mobx-react-lite';

import Animatable from 'modules/animatable/animatable';
import BottomDrawer, { Snap, YID } from '../drawer/BottomDrawer';
import CenterMapButton from '../map/centerMapButton/CenterMapButton';
import KeyboardStore from 'stores/subsidiaryStores/KeyboardStore';
import MainAddressPicker from '../addressPickers/MainAddressPicker';
import MapLandingPickupMarker from '../taxis/ridePopup/MapLandingPickupMarker';
import MapLandingStyles from './styles/MapLandingStyles';
import MapLandingSuggestion from '../suggestions/MapLandingSuggestion';
import { Images, Metrics } from 'themes';
import { trimAddress } from 'services/Utils';
import { useStores } from 'containers/App';

const AnimatedAddressPicker = Animated.createAnimatedComponent(MainAddressPicker);

export default observer(() => {
    const {
        stores: {
            addressStore,
            addressStore: { setPickup, setCurrentType },
            layoutStore,
            mapStore,
            navigationStore: {
                mapLandingRefStore: { setMapLandingBackHandler, setMapLandingCurrentSnap },
            },
            suggestionsStore,
            taxiStore,
            taxiStore: { centerMap, updateCurrentUserLocation },
        },
    } = useStores();
    useEffect(() => {
        setMapLandingBackHandler(handleBackClick);
        return () => {
            setMapLandingBackHandler(null);
        };
    }, []);
    const bottomDrawerRef = useRef(null);
    const downPoint = useComputed(() => {
        switch (suggestionsStore.mapLandingMinimizedSuggestions.length) {
            case 0:
                return (
                    layoutStore.screenHeight -
                    Platform.select({ web: Metrics.isMobileDevice ? 200 : 220, default: 220 })
                );
            case 1:
                return (
                    layoutStore.screenHeight -
                    Platform.select({ web: Metrics.isMobileDevice ? 250 : 270, default: 270 })
                );
            default:
                return (
                    layoutStore.screenHeight -
                    Platform.select({ web: Metrics.isMobileDevice ? 310 : 330, default: 330 })
                );
        }
    });
    const points = [
        { y: Platform.select({ web: -60, default: -30 }), id: 'up' },
        { y: downPoint, id: 'down' },
    ];
    const initPosition = addressStore.selectedField ? -30 : downPoint;
    const drawerStore = useLocalStore(() => ({
        deltaY: new Animated.Value(addressStore.selectedField ? -30 : downPoint),
        currentSnap: 'down',
        updateSnap(index: Snap) {
            this.currentSnap = index;
            if (index === 'down') {
                Keyboard.dismiss();
                taxiStore.setSuggestionsState('landing');
                setTimeout(() => {
                    addressStore.setSelectedField(undefined);
                }, 500);
            }
        },
    }));
    useEffect(() => {
        setMapLandingCurrentSnap(drawerStore.currentSnap as Snap);
        if (drawerStore.currentSnap === 'down') {
            drawerStore;
            setTimeout(() => {
                setSnap('down');
            }, 200);
        }
    }, [downPoint, drawerStore.currentSnap]);
    const keyboardStore = useLocalStore(() => new KeyboardStore());
    const setSnap = (snap: Snap) => {
        if (bottomDrawerRef.current) {
            drawerStore.updateSnap(snap);
            snapTo(snap === 'down' ? 1 : 0);
        }
    };
    const snapTo = (index: number) => {
        bottomDrawerRef.current?.snapTo(index);
    };
    const onSnapStart = ({ nativeEvent: { index } }) => {
        if (
            index === 0 &&
            taxiStore.suggestionsState !== 'destination' &&
            !addressStore.selectedField &&
            addressStore.focusedInput !== 'pickup'
        ) {
            addressStore.showDestination();
        }
    };
    const onDrag = event => {
        if (event.nativeEvent.state && event.nativeEvent.state === 'end') {
            drawerStore.updateSnap(event.nativeEvent.targetSnapPointId);
        }
    };
    const handleAddressPickerBackClick = () => {
        if (!!addressStore.pickupLocation) {
            setPickup(trimAddress(addressStore.pickupLocation.address));
        }
        setSnap('down');
    };
    const handleBackClick = () => {
        const { toggleBlockLocationOnMap } = mapStore;
        setSnap('down');
        if (addressStore.currentType === 'pickup') {
            setCurrentType('destination');
        }
        toggleBlockLocationOnMap(false);
        updateCurrentUserLocation(
            {
                coordinate: {
                    latitude: mapStore.lastUserLatitude,
                    longitude: mapStore.lastUserLongitude,
                },
            },
            false,
            'MapLanding back handler'
        );
        centerMap();
    };
    const renderListHeader = () => (
        <>
            <View style={MapLandingStyles.favoriteLocationView}>
                {suggestionsStore.favorites.map((favorite, index) => (
                    <React.Fragment key={favorite.name}>
                        <TouchableOpacity
                            onPress={() => suggestionsStore.selectSuggestion(favorite)}
                            style={MapLandingStyles.favoriteAddressButton}
                        >
                            <Image
                                source={Images.mapLanding.favorite}
                                style={MapLandingStyles.favoriteAddressButtonIcon}
                                resizeMode="contain"
                            />
                            <View style={MapLandingStyles.favoriteLocationTextView}>
                                <Text style={MapLandingStyles.favoriteLocationTitle}>
                                    {Voca.capitalize(favorite.name)}
                                </Text>
                                <Text
                                    style={MapLandingStyles.favoriteLocationText}
                                    numberOfLines={1}
                                >
                                    {favorite.empty ? 'Set location' : favorite.location.name}
                                </Text>
                            </View>
                        </TouchableOpacity>
                        {index !== suggestionsStore.favorites.length - 1 && (
                            <View style={MapLandingStyles.favoriteAddressButtonSeparator} />
                        )}
                    </React.Fragment>
                ))}
            </View>
            <Text
                style={[
                    MapLandingStyles.suggestionListTitle,
                    {
                        marginBottom: drawerStore.currentSnap === 'down' ? 0 : 8,
                    },
                ]}
            >
                SUGGESTIONS
            </Text>
        </>
    );
    const renderSuggestionItem = ({ item, index }: { item: any; index: number }) => (
        <MapLandingSuggestion
            item={item}
            index={index}
            suggestionSelected={suggestionsStore.selectSuggestion}
        />
    );
    return useObserver(() => (
        <View pointerEvents="box-none" style={MapLandingStyles.container}>
            <Animatable.View
                animation="bounceIn"
                duration={500}
                pointerEvents="box-none"
                useNativeDriver
                style={[
                    MapLandingStyles.ridePopupContainer,
                    { top: mapStore.padding.top, bottom: mapStore.padding.bottom },
                ]}
            >
                <MapLandingPickupMarker onPress={() => setSnap('up')} />
            </Animatable.View>
            <CenterMapButton
                inputRange={[0, downPoint]}
                outputRange={[-downPoint, 0]}
                bottom={layoutStore.screenHeight - downPoint}
                deltaY={drawerStore.deltaY}
                opacityInputRange={[
                    0,
                    layoutStore.screenHeight - downPoint,
                    layoutStore.screenHeight,
                ]}
                opacityOutputRange={[0, 1, 1]}
            />
            <Animatable.View
                pointerEvents="box-none"
                animation="slideInUp"
                duration={300}
                useNativeDriver
            >
                <BottomDrawer
                    ref={bottomDrawerRef}
                    snapPoints={points}
                    onDrag={onDrag}
                    onSnapStart={onSnapStart}
                    boundaries={{ bottom: layoutStore.screenHeight - 50, top: -50 }}
                    deltaY={drawerStore.deltaY}
                    initialPoint={initPosition}
                >
                    <View
                        style={[
                            MapLandingStyles.interactableOuterContainer,
                            {
                                height:
                                    layoutStore.screenHeight +
                                    Platform.select({ web: 19, default: 39 }),
                            },
                        ]}
                    >
                        <View style={MapLandingStyles.drawerIndicator} />
                        <View
                            style={[
                                MapLandingStyles.interactableContainer,
                                {
                                    height:
                                        layoutStore.screenHeight +
                                        Platform.select({ web: 0, default: 20 }),
                                },
                            ]}
                        >
                            <Animated.View
                                useNativeDriver
                                pointerEvents={drawerStore.currentSnap === 'down' ? 'auto' : 'none'}
                                style={[
                                    MapLandingStyles.destinationAnimatedView,
                                    {
                                        opacity: drawerStore.deltaY.interpolate({
                                            inputRange: [
                                                points[0].y,
                                                (points[0].y + points[1].y) / 2,
                                                points[1].y,
                                            ],
                                            outputRange: [0, 0.2, 1],
                                            extrapolateRight: 'clamp',
                                        }),
                                        transform: [
                                            {
                                                translateY: drawerStore.deltaY.interpolate({
                                                    inputRange: [
                                                        points[0].y,
                                                        points[0].y + 10,
                                                        points[1].y,
                                                    ],
                                                    outputRange: [-60, 10, 0],
                                                    extrapolateRight: 'clamp',
                                                }),
                                            },
                                        ],
                                    },
                                ]}
                            >
                                <TouchableOpacity
                                    onPress={() => setSnap('up')}
                                    testID="button_search"
                                    style={MapLandingStyles.destinationButton}
                                >
                                    <Image
                                        source={Images.mapLanding.search}
                                        style={MapLandingStyles.destinationButtonIcon}
                                        resizeMode="contain"
                                    />
                                    <Text
                                        allowFontScaling={false}
                                        style={MapLandingStyles.destinationButtonText}
                                    >
                                        Where do you want to go?
                                    </Text>
                                </TouchableOpacity>
                            </Animated.View>
                            <Animated.View
                                useNativeDriver
                                style={{
                                    transform: [
                                        {
                                            translateY: drawerStore.deltaY.interpolate({
                                                inputRange: [points[0].y, points[1].y],
                                                outputRange: [
                                                    Platform.OS === 'android' ? 50 : 95,
                                                    0,
                                                ],
                                                extrapolate: 'extend',
                                            }),
                                        },
                                    ],
                                    justifyContent: 'flex-start',
                                    paddingVertical: drawerStore.currentSnap === 'down' ? 10 : 23,
                                }}
                            >
                                <FlatList
                                    ListHeaderComponent={renderListHeader}
                                    bounces={false}
                                    showsVerticalScrollIndicator={false}
                                    keyboardShouldPersistTaps="handled"
                                    data={
                                        drawerStore.currentSnap === 'down'
                                            ? suggestionsStore.mapLandingMinimizedSuggestions
                                            : suggestionsStore.mapLandingMaximizedSuggestions
                                    }
                                    renderItem={renderSuggestionItem}
                                    style={{
                                        height:
                                            layoutStore.screenHeight -
                                            (Platform.OS === 'android' ? 205 : 240) -
                                            keyboardStore.keyboardHeight,
                                    }}
                                />
                            </Animated.View>
                            <AnimatedAddressPicker
                                style={[
                                    {
                                        opacity: drawerStore.deltaY.interpolate({
                                            inputRange: [points[0].y, points[0].y + 20],
                                            outputRange: [1, 0],
                                            extrapolateRight: 'clamp',
                                        }),
                                        transform: [
                                            {
                                                translateY: drawerStore.deltaY.interpolate({
                                                    inputRange: [points[0].y, points[1].y + 100],
                                                    outputRange: [0, 500],
                                                    extrapolateRight: 'clamp',
                                                }),
                                            },
                                        ],
                                        height: 120,
                                    },
                                    MapLandingStyles.animatedAddressPicker,
                                ]}
                                useNativeDriver
                                handleCloseClick={handleAddressPickerBackClick}
                            />
                        </View>
                    </View>
                </BottomDrawer>
            </Animatable.View>
            {drawerStore.currentSnap === 'up' && suggestionsStore.current.slice().length > 0 && (
                <View
                    style={[
                        MapLandingStyles.keyboardStickyView,
                        {
                            bottom: keyboardStore.keyboardHeight,
                            paddingBottom: 10,
                        },
                    ]}
                >
                    {suggestionsStore.current.map((suggestion: any, index: number) => (
                        <TouchableOpacity
                            key={`${suggestion.title}${index}`}
                            style={MapLandingStyles.keyboardStickyViewButton}
                            onPress={() => suggestionsStore.selectSuggestion(suggestion)}
                        >
                            <Image
                                source={suggestion.image}
                                style={MapLandingStyles.keyboardStickyViewButtonIcon}
                            />
                            <Text style={MapLandingStyles.keyboardStickyViewButtonText}>
                                {suggestion.title}
                            </Text>
                        </TouchableOpacity>
                    ))}
                </View>
            )}
        </View>
    ));
});

import React, { Component } from 'react';
import { TouchableOpacity, View, Image, Text, Platform } from 'react-native';
import Voca from 'voca';

import AddressPickerStyles from './styles/AddressPickerStyles';
import { Localizable } from '../../lib';
import { Images } from '../../themes';

interface Props {
    item: any;
    index: number;
    suggestionSelected: Function;
}

class SuggestionsListItemSetFavorite extends Component<Props> {
    onPress = () => {
        const { item, suggestionSelected } = this.props;
        suggestionSelected(item);
    };

    render() {
        const { item, index } = this.props;
        const title = Voca.capitalize(item.name);
        const subTitle = `${Localizable.t('suggestions.setAddressTap1')} ${
            item.name
        } ${Localizable.t('suggestions.setAddressTap2')}`;
        const icon = Images.mapLanding.home;

        return (
            <TouchableOpacity
                style={AddressPickerStyles.suggestionItemContainerEmpty}
                {...(Platform.OS === 'web'
                    ? {
                          // When scrolling the document body, the touchables might be triggered
                          // see  https://github.com/necolas/react-native-web/issues/1219
                          onClick: this.onPress,
                      }
                    : {
                          onPress: this.onPress,
                      })}
                testID={`item_suggestion_${index}`}
            >
                <View
                    key={`${title} iconView ${index}`}
                    style={AddressPickerStyles.suggestionsItemLeftContainer}
                >
                    <Image
                        key={`${title} image ${index}`}
                        source={icon}
                        style={AddressPickerStyles.suggestionIcon}
                    />
                </View>
                <View style={AddressPickerStyles.textView} key={`${title} innerView ${index}`}>
                    <Text
                        key={`${title} title ${index}`}
                        allowFontScaling={false}
                        style={AddressPickerStyles.suggestionTitle}
                    >
                        {title}
                    </Text>
                    <Text
                        key={`${title} subtitle ${index}`}
                        allowFontScaling={false}
                        style={AddressPickerStyles.suggestionDescription}
                    >
                        {subTitle}
                    </Text>
                </View>
            </TouchableOpacity>
        );
    }
}

export default SuggestionsListItemSetFavorite;

import React from 'react';
import { TouchableOpacity, Text, Platform } from 'react-native';
import { inject, observer } from 'mobx-react';

import Alert from 'modules/alert/alert';
import getEnv from 'modules/config/config';

import ChangeRideStatusButtonStyles from './styles/ChangeRideStatusButtonStyles';
import TaxiStore from '../../stores/TaxiStore';
import { Localizable } from '../../lib';
import { Stores } from '../../stores/RootStore';

interface Props {
    taxiStore: TaxiStore;
}

export default inject(({ taxiStore }: Stores) => ({ taxiStore }))(
    observer(
        ({
            taxiStore,
            taxiStore: {
                taxiRideStore: { changeCurrentRideStatus },
            },
        }: Props) => {
            const showStatusPopup = () => {
                Alert.alert('', 'Choose status', [
                    {
                        text: 'Accepted',
                        onPress: () => changeCurrentRideStatus('booked'),
                    },
                    {
                        text: 'Picked up',
                        onPress: () => changeCurrentRideStatus('AVAILABLE_CANCELLATION'),
                    },
                    Platform.select({
                        ios: {
                            text: 'Completed',
                            onPress: () => changeCurrentRideStatus('completed'),
                        },
                        android: {
                            text: 'OTHER',
                            onPress: () => showStatusPopupOther(),
                        },
                    }),
                    {
                        text: 'Cancel ride',
                        onPress: () => changeCurrentRideStatus('canceled'),
                    },
                    { text: 'Cancel' },
                ]);
            };
            const showStatusPopupOther = () => {
                Alert.alert('', 'Choose status', [
                    {
                        text: 'Completed',
                        onPress: () => changeCurrentRideStatus('completed'),
                    },
                    {
                        text: 'Cancel ride',
                        onPress: () => changeCurrentRideStatus('canceled'),
                    },
                    {
                        text: 'OTHER',
                        onPress: () => showStatusPopup(),
                    },
                    { text: 'Cancel' },
                ]);
            };
            return taxiStore.taxiState === 'ride' && __DEV__ ? (
                <TouchableOpacity
                    style={ChangeRideStatusButtonStyles.container}
                    onPress={showStatusPopup}
                >
                    <Text style={ChangeRideStatusButtonStyles.statusText}>
                        {Localizable.t('taxi.changeRideStatus')}
                    </Text>
                </TouchableOpacity>
            ) : null;
        }
    )
);

import React from 'react';
import { Text } from 'react-native';
import { observer } from 'mobx-react';
import ErrorMessageStyles from './ErrorMessageStyles';

interface Props {
    message: string;
    options?: any;
    onPress?: () => void;
}

const ErrorMessage = ({ message, options, onPress }: Props) => {
    if (options && options.start !== options.end) {
        return (
            <Text style={ErrorMessageStyles.errorMessage}>
                {message.slice(0, options.start)}
                <Text style={ErrorMessageStyles.errorMessageInner} onPress={onPress}>
                    {message.slice(options.start, options.end)}
                </Text>
                {message.slice(options.end)}
            </Text>
        );
    }
    return <Text style={ErrorMessageStyles.errorMessage}>{message}</Text>;
};

export default observer(ErrorMessage);

import { StyleSheet } from 'react-native';
import { Fonts, Metrics, Colors } from '../../../themes';

export default StyleSheet.create({
    container: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#000000cc',
    },
    innerContainer: {
        backgroundColor: Colors.white,
        padding: 16,
        width: Metrics.screenWidth - 80,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 6,
    },
    topView: {
        flexDirection: 'row',
    },
    titleText: {
        fontFamily: Fonts.type.base,
        fontWeight: '700',
        fontSize: Metrics.normalize(20),
        color: Colors.darkTextColor,
        flex: 1,
        marginBottom: 14,
    },
    descriptionText: {
        fontFamily: Fonts.type.base,
        fontWeight: '400',
        fontSize: Metrics.normalize(13),
        color: Colors.darkGreyTextColor,
        marginVertical: 16,
    },
    closeView: {
        backgroundColor: Colors.closeButtonBackgroundColor,
        width: 30,
        height: 30,
        borderRadius: 15,
        alignItems: 'center',
        justifyContent: 'center',
    },
    closeButton: {
        width: 15,
        height: 15,
    },
    separator: {
        position: 'absolute',
        bottom: 0,
        right: 0,
        left: 0,
        backgroundColor: Colors.grayTextColor,
        height: 1,
        opacity: 0.1,
    },
    seatsView: {
        borderRadius: 4,
        backgroundColor: Colors.white,
        shadowColor: 'black',
        shadowRadius: 3,
        shadowOpacity: 0.1,
        shadowOffset: { width: 0, height: 2 },
        elevation: 2,
        padding: 10,
        alignItems: 'center',
        width: Metrics.screenWidth - 120,
    },
    seatsTitleText: {
        fontFamily: Fonts.type.base,
        fontWeight: '600',
        fontSize: Metrics.normalize(13),
        color: Colors.darkGreyTextColor,
    },
    seatsValueView: {
        marginVertical: 8,
        flexDirection: 'row',
    },
    seatsValueText: {
        fontFamily: Fonts.type.base,
        fontWeight: '600',
        fontSize: Metrics.normalize(33),
        width: 80,
        textAlign: 'center',
        color: Colors.lightGrayColor,
    },
    feesText: {
        fontFamily: Fonts.type.base,
        fontWeight: '500',
        fontSize: Metrics.normalize(13),
        marginTop: 40,
        marginBottom: 5,
        color: Colors.grayTextColor,
    },
    feesValueText: {
        fontFamily: Fonts.type.base,
        fontWeight: '700',
        fontSize: Metrics.normalize(26),
        color: Colors.destinationColor,
    },
    confirmButton: {
        marginTop: 30,
        width: Metrics.screenWidth - 120,
    },
});

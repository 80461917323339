import React, { Component } from 'react';
import { View, StyleSheet, ActivityIndicator, Text, TextInput } from 'react-native';
import { inject, observer } from 'mobx-react';
import KeyboardAwareScrollView from 'modules/keyboardAwareScrollView/keyboardAwareScrollView';

import { Colors } from 'themes';
import { ServicesStore } from 'stores';
import { Stores } from 'stores/RootStore';
import { Localizable, Analytics } from 'lib';

import AdditionalInfoStyles from '../../styles/AdditionalInfoStyles';
import TitleNavigationBar from '../../../navigationBars/TitleNavigationBar';
import {
    NextButton,
    ErrorMessage,
    InfoLabel,
    ClickableInfoLabel,
} from '../../providerConnectingComponents';

interface Props {
    servicesStore: ServicesStore;
}

@inject(({ servicesStore }: Stores) => ({
    servicesStore,
}))
@observer
export default class ProviderConnectingVia extends Component<Props> {
    passwordInputRef: React.RefObject<TextInput> = React.createRef();

    focusPasswordInput = () => {
        if (this.passwordInputRef && this.passwordInputRef.current) {
            this.passwordInputRef.current.focus();
        }
    };

    componentDidMount() {
        Analytics.trackServiceLinking('via', 'connecting', 'credentials_screen');
    }

    render() {
        const {
            servicesStore: {
                hideProviderPopup,
                viaServiceStore: {
                    loading,
                    onViaCredentialsSubmit,
                    setViaEmail,
                    setViaPassword,
                    email,
                    errorMessage,
                    password,
                    downloadApp,
                },
            },
        } = this.props;

        return (
            <View
                style={[
                    StyleSheet.absoluteFill,
                    {
                        backgroundColor: Colors.typesBackgroundColor,
                    },
                ]}
            >
                <TitleNavigationBar title="Via" handleBackClick={() => hideProviderPopup()} />
                <KeyboardAwareScrollView
                    contentContainerStyle={{ paddingHorizontal: 20 }}
                    keyboardShouldPersistTaps="handled"
                >
                    <Text style={AdditionalInfoStyles.title}>
                        {Localizable.t('additionalInfo.enterCredentials')}
                    </Text>

                    <Text style={AdditionalInfoStyles.description}>
                        {Localizable.t('additionalInfo.placeholderEmail')}
                    </Text>
                    <View style={AdditionalInfoStyles.inputBorder}>
                        <TextInput
                            style={[
                                AdditionalInfoStyles.textInput,
                                {
                                    fontWeight: email.length ? '300' : '100',
                                },
                            ]}
                            keyboardType="email-address"
                            placeholder={Localizable.t('additionalInfo.placeholderEmail')}
                            placeholderTextColor={Colors.lightGrayColor}
                            value={email}
                            onChangeText={setViaEmail}
                            autoFocus
                            autoCorrect={false}
                            autoCapitalize="none"
                            selectionColor={Colors.obiTeal}
                            onSubmitEditing={this.focusPasswordInput}
                            returnKeyType="next"
                        />
                    </View>

                    <Text style={AdditionalInfoStyles.description}>
                        {Localizable.t('additionalInfo.placeholderPassword')}
                    </Text>
                    <View style={AdditionalInfoStyles.inputBorder}>
                        <TextInput
                            style={[
                                AdditionalInfoStyles.textInput,
                                {
                                    fontWeight: password.length ? '300' : '100',
                                },
                            ]}
                            ref={this.passwordInputRef}
                            keyboardType="default"
                            placeholder={Localizable.t('additionalInfo.placeholderPassword')}
                            placeholderTextColor={Colors.lightGrayColor}
                            value={password}
                            secureTextEntry
                            onChangeText={setViaPassword}
                            autoCorrect={false}
                            autoCapitalize="none"
                            selectionColor={Colors.obiTeal}
                            onSubmitEditing={onViaCredentialsSubmit}
                            returnKeyType="done"
                        />
                    </View>

                    {errorMessage.length !== 0 && <ErrorMessage message={errorMessage} />}
                    <ClickableInfoLabel
                        childrenProps={[
                            {
                                text: '',
                                clickableText: 'No account? Click here to continue.',
                                clickableTextStyle: {
                                    fontWeight: '500',
                                    color: '#3bbce3',
                                },
                                handleClickableTextClick: downloadApp,
                            },
                        ]}
                    />

                    <InfoLabel info={Localizable.t('additionalInfo.via.permissionClick')} />
                    <InfoLabel info={Localizable.t('additionalInfo.via.info')} />
                    <NextButton onPress={onViaCredentialsSubmit} />
                </KeyboardAwareScrollView>
                {loading && (
                    <View
                        style={[
                            StyleSheet.absoluteFill,
                            {
                                backgroundColor: Colors.transporentBackground,
                                alignItems: 'center',
                                justifyContent: 'center',
                            },
                        ]}
                    >
                        <ActivityIndicator color={Colors.obiTeal} size="large" />
                    </View>
                )}
            </View>
        );
    }
}

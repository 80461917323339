/* eslint-disable no-plusplus */
import { Platform } from 'react-native';
import { observable, action, computed } from 'mobx';
import { persist } from 'mobx-persist';
import Rate, { AndroidMarket } from 'react-native-rate';

import Alert from 'modules/alert/alert';

import BaseStore from './BaseStore';
import { PersistDataStore } from './RootStore';

const rateOptions = {
    AppleAppID: '1081008679',
    GooglePackageName: 'com.bellhop.app',
    preferredAndroidMarket: AndroidMarket.Google,
    preferInApp: true,
    inAppDelay: 0.5,
    openAppStoreIfInAppFails: false,
};

export default class RateAppStore extends BaseStore implements PersistDataStore {
    hydrateStore = (hydrate: Function) => hydrate('rateAppStore', this);

    @persist
    @observable
    searchCounter: number = 0;

    @persist
    @observable
    appRated: boolean = false;

    @action
    increaseSearchCounter = () => {
        this.searchCounter++;
    };

    @action
    rateApp = () => {
        if (this.appRated || this.searchCounter < 5) {
            return;
        }
        if (Platform.OS === 'ios') {
            Rate.rate(rateOptions, () => {
                this.appRated = true;
            });
        } else {
            Alert.alert('Would you like to rate our app?', '', [
                {
                    text: 'Rate Bellhop',
                    onPress: () =>
                        Rate.rate(rateOptions, () => {
                            this.appRated = true;
                        }),
                },
                {
                    text: 'Cancel',
                    onPress: () => {
                        this.appRated = true;
                    },
                    style: 'cancel',
                },
            ]);
        }
    };
}

import React from 'react';
import { FlatList } from 'react-native';
import { observer, inject } from 'mobx-react';

import ProviderConnectingItem from './ProviderConnectingItem';
import { ServiceType } from '../../../stores/ServicesStore';
import { Stores } from '../../../stores/RootStore';

function renderProviderItem({ item }: { item: ServiceType }) {
    return <ProviderConnectingItem item={item} />;
}

export default inject(({ servicesStore }: Stores) => ({ servicesStore }))(
    observer(({ servicesStore }) => (
        <FlatList
            data={servicesStore.services}
            onRefresh={servicesStore.getServices}
            refreshing={servicesStore.refreshing}
            renderItem={renderProviderItem}
            showsVerticalScrollIndicator={false}
        />
    ))
);

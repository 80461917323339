import { LayoutAnimation, Platform } from 'react-native';
import { observable, action, computed, flow } from 'mobx';

import { Images } from 'themes';
import { Analytics } from 'lib';
import { BaseStore } from '.';
import { GoogleClient } from 'services';
import KeyTitleData from 'domain/KeyTitleData';
import { handleError, distanceBetweenCoords } from '../services/Utils';

export default class SuggestionsStore extends BaseStore {
    autocompleteThrottling: any;

    @observable
    suggestions: Array<any> = [];

    updateText = () => {
        this.handleSuggestionType(this.address);
    };

    handleSuggestionType = (address: any) => {
        if (this.autocompleteThrottling) {
            clearTimeout(this.autocompleteThrottling);
        }
        this.autocompleteThrottling = setTimeout(() => {
            this.getSuggestions(address);
        }, 500);
    };

    getSuggestions = flow(function*(this: SuggestionsStore, address: string) {
        try {
            const {
                taxiStore: { userLocation },
            } = this.rootStore.stores;
            this.cachedAddress = address;
            const {
                data: { predictions },
            } = yield GoogleClient.getSuggestions(address, userLocation);
            this.parseSuggestions(predictions);
        } catch (error) {
            handleError(error);
        }
    }).bind(this);

    @action
    parseSuggestions = (predictions: any) => {
        this.suggestions = predictions;
    };

    favoriteFits = (favorite: any, address: any, type: any) => {
        const {
            taxiStore: { suggestionsState },
        } = this.rootStore.stores;

        if (!favorite.location && suggestionsState === 'landing') {
            return { name: type, empty: true };
        }

        return favorite.location &&
            (favorite.location.name.toLowerCase().indexOf(address.toLowerCase()) >= 0 ||
                type.toLowerCase().indexOf(address.toLowerCase()) >= 0)
            ? favorite
            : null;
    };

    @computed
    get address() {
        const {
            taxiStore: { suggestionsState },
            addressStore: { pickup, destination },
        } = this.rootStore.stores;

        if (suggestionsState === 'pickup') {
            return pickup || '';
        }
        if (suggestionsState === 'destination') {
            return destination || '';
        }
        return '';
    }

    @computed.struct
    get current() {
        const {
            taxiStore: { suggestionsState },
        } = this.rootStore.stores;

        if (suggestionsState === 'pickup') {
            return [
                {
                    current: true,
                    id: 'current',
                    title: 'Use current location',
                    image: Images.addressPicker.currentLocation,
                },
                {
                    select: true,
                    id: 'select',
                    title: 'Set on map',
                    image: Images.addressPicker.centerMap,
                },
            ];
        }
        if (suggestionsState === 'destination') {
            return [
                {
                    select: true,
                    destination: true,
                    id: 'select',
                    title: 'Set on map',
                    image: Images.addressPicker.centerMap,
                },
            ];
        }
        return [];
    }

    @computed
    get addressSuggestions() {
        const { taxiStore } = this.rootStore.stores;
        return taxiStore.suggestionsState === 'landing' ? [] : this.suggestions;
    }

    @computed.struct
    get favorites() {
        const {
            locationsStore: {
                home,
                work,
                // other
            },
        } = this.rootStore.stores;
        return [
            home,
            work,
            // other
        ];
    }

    @computed.struct
    get recent(): Array<any> {
        const {
            locationsStore,
            addressStore: { pickupLocation },
        } = this.rootStore.stores;

        if (locationsStore.list.length !== 0) {
            return locationsStore.list
                .filter(
                    e =>
                        e.structured_formatting.main_text
                            .toLowerCase()
                            .indexOf(this.address.toLowerCase()) >= 0
                )
                .filter(
                    e =>
                        pickupLocation &&
                        e.location &&
                        distanceBetweenCoords({
                            startCoord: e.location,
                            endCoord: pickupLocation,
                            unit: 'METERS',
                            caller: 'computed recent filter',
                        }) > 200 &&
                        distanceBetweenCoords({
                            startCoord: e.location,
                            endCoord: pickupLocation,
                            unit: 'METERS',
                            caller: 'computed recent filter',
                        }) < 50000
                )
                .sort((recent1, recent2) => {
                    return (
                        distanceBetweenCoords({
                            startCoord: recent1.location,
                            endCoord: pickupLocation,
                            unit: 'METERS',
                            caller: 'computed recent sort',
                        }) -
                        distanceBetweenCoords({
                            startCoord: recent2.location,
                            endCoord: pickupLocation,
                            unit: 'METERS',
                            caller: 'computed recent sort',
                        })
                    );
                });
        }
        return [];
    }

    @computed.struct
    get mapLandingMinimizedSuggestions(): KeyTitleData[] {
        // TODO; ta animacja powinna sie wywolywac w innym miejscu
        if (Platform.OS === 'ios') {
            LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
        }

        switch (this.recent.slice().length) {
            case 0:
                return [];
            case 1:
                return [this.recent.slice()[0]];
            default:
                return [this.recent.slice()[0], this.recent.slice()[1]];
        }
    }

    @computed.struct
    get mapLandingMaximizedSuggestions() {
        // TODO; ta animacja powinna sie wywolywac w innym miejscu
        if (Platform.OS === 'ios') {
            LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
        }
        return [
            ...this.recent.slice(),
            ...this.addressSuggestions
                .slice()
                .filter(suggestion => !this.recent.slice().find(item => item.id === suggestion.id)),
        ];
    }

    @computed.struct
    get sections(): KeyTitleData[] {
        // TODO; ta animacja powinna sie wywolywac w innym miejscu
        if (Platform.OS === 'ios') {
            LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
        }

        return [
            {
                // title: '',
                key: 'empty',
                data: this.current.slice(),
            },
            {
                title: 'Favorites',
                key: 'Favorites',
                data: this.favorites.slice(),
            },
            {
                title: 'Recent',
                key: 'Recent',
                data: this.recent.slice(),
            },
            {
                title: 'Search',
                key: 'Search',
                data: this.addressSuggestions.slice(),
            },
        ];
    }

    selectSuggestion = flow(function*(this: SuggestionsStore, suggestion) {
        const {
            stores: {
                addressStore,
                navigationStore: { navigate },
                mapStore,
                taxiStore,
            },
        } = this.rootStore;
        const { showDestination } = addressStore;
        const { animateCamera, toggleBlockLocationOnMap } = mapStore;
        const {
            getSuggestions,
            getUserLocation,
            parseSuggestion,
            selectDestination,
            selectPickup,
            setTaxiState,
            updateCurrentUserLocation,
        } = taxiStore;

        if (suggestion.current) {
            toggleBlockLocationOnMap(false);
            updateCurrentUserLocation(
                {
                    coordinate: {
                        latitude: mapStore.lastUserLatitude,
                        longitude: mapStore.lastUserLongitude,
                    },
                },
                false,
                'select suggestion - set current location'
            );
            getUserLocation(true, false, 'select suggestion - set current location');
            setTaxiState('start');
            showDestination();
        }

        if (suggestion.empty) {
            navigate('FavoriteAddressPicker', {
                type: suggestion.name,
                onClose: () => getSuggestions(taxiStore.cachedAddress, false),
            });
        } else if (suggestion.select) {
            if (suggestion.destination) {
                getUserLocation(false, false, 'select suggestion - destination');
                selectDestination();
                if (addressStore.destinationLocation) {
                    const { latitude, longitude } = addressStore.destinationLocation;
                    setTimeout(() => {
                        animateCamera({ latitude, longitude });
                    }, 0);
                } else {
                    const { latitude, longitude } = addressStore.pickupLocation;
                    setTimeout(() => {
                        animateCamera({ latitude, longitude });
                    }, 0);
                }
            } else {
                getUserLocation(true, false, 'select suggestion - pickup');
                selectPickup();
                if (addressStore.pickupLocation) {
                    const { latitude, longitude } = addressStore.pickupLocation;
                    setTimeout(() => {
                        animateCamera({ latitude, longitude });
                    }, 0);
                }
            }
        } else if (suggestion.name && suggestion.location) {
            parseSuggestion(false, null, suggestion);
        } else if (suggestion.place_id) {
            try {
                const { data } = yield GoogleClient.getSuggestionDetails(suggestion.place_id);
                parseSuggestion(true, suggestion, data);
            } catch (error) {
                handleError(error);
            }
        }
    }).bind(this);
}

import { action, observable, flow } from 'mobx';

import NetInfo from 'modules/netInfo/netInfo';

import { AppLifecycle } from './RootStore';
import { BaseStore } from '.';
import { RestClient } from 'services';

export default class ConnectionStore extends BaseStore implements AppLifecycle {
    @observable
    netConnectionAvailable = true;

    @observable
    showConnectionModal = true;

    @observable
    connectionRetrying = false;

    onAppStarted = () => {
        this.addEventsListeners();
    };

    addEventsListeners = () => {
        NetInfo.addEventListener(this.handleNetConnectionChange);
    };

    @action
    handleNetConnectionChange = ({ isConnected }: { isConnected: boolean }) => {
        if (!isConnected) {
            this.showConnectionModal = true;
        }
        this.netConnectionAvailable = isConnected;
    };

    @action
    hideConnectionModal = () => {
        this.showConnectionModal = false;
    };

    tryToReconnect = flow(function*(this: ConnectionStore) {
        this.connectionRetrying = true;
        try {
            const { status } = yield RestClient.getProfile();
            if (status) {
                this.handleNetConnectionChange({ isConnected: true });
            } else {
                this.handleNetConnectionChange({ isConnected: true });
            }
        } catch (error) {
            this.handleNetConnectionChange({ isConnected: true });
        }
        this.connectionRetrying = false;
    }).bind(this);
}

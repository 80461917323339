import React from 'react';
import { View, TouchableOpacity, Image } from 'react-native';
import NextStyles from './NextButtonStyles';
import { Images } from '../../../../themes';

interface Props {
    onPress: () => void;
    active?: boolean;
}

const NextButton = ({ onPress, active = true }: Props) => (
    <View style={NextStyles.nextView}>
        <View style={NextStyles.nextViewSeparator} />
        <TouchableOpacity onPress={onPress}>
            <Image
                style={NextStyles.nextButton}
                source={active ? Images.landing.arrowActive : Images.landing.arrowInactive}
            />
        </TouchableOpacity>
    </View>
);

export default NextButton;

import React from 'react';
import { View, Text, Image } from 'react-native';
import { observer } from 'mobx-react';

import RouteViewStyles from './RouteViewStyles';
import { Images } from '../../../../themes';
import { Localizable } from '../../../../lib';

interface Props {
    pickup: string;
    destination: string;
}

const RouteView = ({ pickup, destination }: Props) => {
    return (
        <View style={RouteViewStyles.addressContainer}>
            <Image
                source={Images.icons.route}
                style={RouteViewStyles.routeImage}
                resizeMode="contain"
            />
            <View style={RouteViewStyles.innerContainer}>
                <View style={RouteViewStyles.innerContainerView}>
                    <Text style={RouteViewStyles.addressValueText}>
                        {Localizable.t('rideDetails.pickup').toUpperCase()}
                    </Text>
                    <Text style={RouteViewStyles.addressText} numberOfLines={1}>
                        {pickup}
                    </Text>
                </View>
                <View style={RouteViewStyles.innerContainerView}>
                    <Text style={RouteViewStyles.addressValueText}>
                        {Localizable.t('rideDetails.destination').toUpperCase()}
                    </Text>
                    <Text style={RouteViewStyles.addressText} numberOfLines={1}>
                        {destination}
                    </Text>
                </View>
            </View>
        </View>
    );
};

export default observer(RouteView);

import { StyleSheet } from 'react-native';
import { Fonts, Metrics, Colors } from '../../../../themes';

export default StyleSheet.create({
    container: {
        marginTop: 10,
        height: 50,
        shadowColor: 'black',
        shadowRadius: 4,
        shadowOpacity: 0.2,
        shadowOffset: { width: 0, height: 0 },
        elevation: 10,
    },
    innerContainer: {
        shadowColor: 'black',
        shadowRadius: 3,
        shadowOpacity: 0.2,
        shadowOffset: { width: 0, height: 0 },
        borderRadius: 8,
        backgroundColor: 'white',
        width: 180,
        margin: 4,
        marginVertical: 6,
        flexDirection: 'row',
        overflow: 'hidden',
    },
    titleText: {
        fontFamily: Fonts.type.base,
        fontSize: Metrics.normalize(12),
        fontWeight: '700',
        color: Colors.black,
        marginBottom: 2,
        maxWidth: 140,
    },
    descriptionText: {
        fontFamily: Fonts.type.base,
        fontSize: Metrics.normalize(9),
        fontWeight: '400',
        color: Colors.grayTextColor,
        maxWidth: 200,
    },
    destinationView: {
        padding: 10,
        alignItems: 'center',
        justifyContent: 'center',
        borderTopLeftRadius: 4,
        borderBottomLeftRadius: 4,
    },
    destinationValueText: {
        fontFamily: Fonts.type.base,
        fontSize: Metrics.normalize(13),
        fontWeight: '800',
        color: 'white',
        backgroundColor: Colors.transparent,
    },
    destinationDescriptionText: {
        fontFamily: Fonts.type.base,
        fontSize: Metrics.normalize(11),
        fontWeight: '400',
        color: 'white',
        backgroundColor: Colors.transparent,
    },
    triangle: {
        width: 0,
        height: 0,
        backgroundColor: 'transparent',
        borderStyle: 'solid',
        borderLeftWidth: 10,
        borderRightWidth: 10,
        borderBottomWidth: 15,
        borderLeftColor: 'transparent',
        borderRightColor: 'transparent',
        borderBottomColor: 'white',
        position: 'absolute',
        bottom: -5,
        left: '50%',
        transform: [{ translateX: -10 }, { rotate: '180deg' }],
    },
    upTriangle: {
        width: 0,
        height: 0,
        backgroundColor: 'transparent',
        borderStyle: 'solid',
        borderLeftWidth: 10,
        borderRightWidth: 10,
        borderBottomWidth: 15,
        borderLeftColor: 'transparent',
        borderRightColor: 'transparent',
        borderBottomColor: 'white',
        position: 'absolute',
        top: 0,
        left: '50%',
        transform: [{ translateX: -10 }],
    },
    availablePin: { width: Metrics.normalize(40), height: Metrics.normalize(40) },
    unavailablePinView: {
        position: 'absolute',
        top: Metrics.normalize(2),
        left: Metrics.normalize(3),
        right: Metrics.normalize(3),
        overflow: 'hidden',
    },
    unavailablePin: {
        width: Metrics.normalize(34),
        height: Metrics.normalize(34),
    },
    overIconView: {
        position: 'absolute',
        top: 0,
        right: 0,
        left: 0,
        overflow: 'hidden',
    },
    availableTextsView: {
        paddingVertical: 6,
        paddingHorizontal: 8,
        flexGrow: 1,
        alignSelf: 'center',
    },
    pickupArrowImage: {
        alignSelf: 'center',
        width: 10,
        height: 15,
        marginRight: 10,
    },
    bikeMarkerEndBikingImage: { width: Metrics.normalize(20), height: Metrics.normalize(20) },
    bikeMarkerEndImage: { width: Metrics.normalize(16), height: Metrics.normalize(16) },
    bikeMarkerRideImage: { width: Metrics.normalize(30), height: Metrics.normalize(30) },
    bikeMarkerStartImage: { width: Metrics.normalize(16), height: Metrics.normalize(16) },
    bikeMarkerStartWalkingImage: { width: Metrics.normalize(16), height: Metrics.normalize(16) },
    driverMarker: { width: 19, height: 33 },
});

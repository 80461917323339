import { StyleSheet } from 'react-native';
import { Fonts, Metrics, Colors } from '../../../themes';

export default StyleSheet.create({
    avatarView: {
        // shadowColor: 'black',
        // shadowRadius: 3,
        // shadowOpacity: 0.5,
        // shadowOffset: { width: 0, height: 0 },
        // elevation: 2,
        width: 30,
        height: 30,
        borderRadius: 15,
        backgroundColor: Colors.darkPurple,
        alignItems: 'center',
        justifyContent: 'center',
    },
    avatarImage: {
        width: 30,
        height: 30,
    },
    avatarText: {
        fontFamily: Fonts.type.base,
        fontSize: Metrics.normalize(21.43),
        fontWeight: '600',
        color: Colors.obiTeal,
        backgroundColor: 'transparent',
    },
});

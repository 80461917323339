import React, { Component } from 'react';
import { View, StyleSheet, Text, TextInput, TouchableOpacity, Image } from 'react-native';
import { inject, observer } from 'mobx-react';

import KeyboardAwareScrollView from 'modules/keyboardAwareScrollView/keyboardAwareScrollView';
import AdditionalInfoStyles from '../../styles/AdditionalInfoStyles';
import ServicesStore from '../../../../stores/ServicesStore';
import TitleNavigationBar from '../../../navigationBars/TitleNavigationBar';
import { Colors, Images } from '../../../../themes';
import { Localizable, Analytics } from '../../../../lib';
import {
    NextButton,
    ErrorMessage,
    InfoLabel,
    ClickableInfoLabel,
} from '../../providerConnectingComponents';
import { Stores } from '../../../../stores/RootStore';
import NavigationStore from '../../../../stores/NavigationStore';

interface Props {
    servicesStore: ServicesStore;
    navigationStore: NavigationStore;
}

@inject(({ navigationStore, servicesStore }: Stores) => ({
    navigationStore,
    servicesStore,
}))
@observer
export default class PhoneNumber extends Component<Props> {
    componentDidMount() {
        Analytics.trackScreenView('04-02-01a_linkPhone');
        Analytics.trackServiceLinking('bolt', 'connecting', 'phone_screen');
    }

    render() {
        const {
            navigationStore: { navigate },
            servicesStore: {
                hideProviderPopup,
                boltServiceStore: {
                    countryCode,
                    countryName,
                    downloadApp,
                    errorMessage,
                    onPhoneNumberSubmit,
                    phoneNumber,
                    selectCountry,
                    setPhoneNumber,
                },
            },
        } = this.props;

        return (
            <View
                style={[
                    StyleSheet.absoluteFill,
                    {
                        backgroundColor: Colors.typesBackgroundColor,
                    },
                ]}
            >
                <TitleNavigationBar title="Bolt" handleBackClick={() => hideProviderPopup()} />
                <KeyboardAwareScrollView
                    contentContainerStyle={{ paddingHorizontal: 20 }}
                    keyboardShouldPersistTaps="handled"
                >
                    <Text style={AdditionalInfoStyles.title}>
                        {Localizable.t('additionalInfo.enterCredentials')}
                    </Text>

                    <Text style={AdditionalInfoStyles.description}>
                        {Localizable.t('additionalInfo.placeholderPhone')}
                    </Text>
                    <View style={AdditionalInfoStyles.inputBorder}>
                        <TouchableOpacity
                            onPress={() =>
                                navigate('CountryPicker', {
                                    onClose: country => {
                                        selectCountry(country);
                                    },
                                })
                            }
                        >
                            <View style={AdditionalInfoStyles.countryContainer}>
                                <Image
                                    source={Images.flags[countryName]}
                                    style={AdditionalInfoStyles.countryIcon}
                                    resizeMode="contain"
                                />
                                <Image
                                    source={Images.icons.countryArrow}
                                    style={AdditionalInfoStyles.countryArrow}
                                    resizeMode="contain"
                                />
                                <Text style={AdditionalInfoStyles.countryText}>+{countryCode}</Text>
                            </View>
                        </TouchableOpacity>
                        <TextInput
                            style={[
                                AdditionalInfoStyles.textInput,
                                {
                                    fontWeight: phoneNumber.length ? '300' : '100',
                                },
                            ]}
                            keyboardType="phone-pad"
                            placeholder={Localizable.t('additionalInfo.placeholderPhone')}
                            placeholderTextColor={Colors.lightGrayColor}
                            value={phoneNumber}
                            secureTextEntry={false}
                            onChangeText={setPhoneNumber}
                            autoFocus
                            autoCorrect={false}
                            selectionColor={Colors.obiTeal}
                            onSubmitEditing={onPhoneNumberSubmit}
                            returnKeyType="done"
                        />
                    </View>
                    {errorMessage.length !== 0 && <ErrorMessage message={errorMessage} />}
                    <ClickableInfoLabel
                        childrenProps={[
                            {
                                text: '',
                                clickableText: 'No account? Click here to continue.',
                                clickableTextStyle: {
                                    fontWeight: '500',
                                    color: '#37b777',
                                },
                                handleClickableTextClick: downloadApp,
                            },
                        ]}
                    />
                    <InfoLabel info={Localizable.t('additionalInfo.bolt.permissionClick')} />
                    <InfoLabel info={Localizable.t('additionalInfo.bolt.info')} />
                    <NextButton onPress={onPhoneNumberSubmit} />
                </KeyboardAwareScrollView>
            </View>
        );
    }
}

import { StyleSheet } from 'react-native';
import { Colors, Metrics, Fonts } from '../../../themes';

export default StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: Colors.typesBackgroundColor,
    },
    savingsContainer: {
        flex: 1,
        // flexDirection: 'row',
        padding: 10,
    },
    savingSectionMoney: {
        alignSelf: 'stretch',
        backgroundColor: '#50C96A',
        borderRadius: 10,
        padding: 20,
    },
    savingSectionSmall: {
        borderRadius: 10,
        marginTop: 10,
        padding: 20,
        flex: 1,
        borderColor: 'red',
        borderWidth: 1,
    },
    textSmall: {
        fontSize: Metrics.normalize(18),
        fontFamily: Fonts.type.base,
        textAlignVertical: 'bottom',
        fontWeight: '600',
    },
    textBig: {
        fontSize: Metrics.normalize(28),
        fontFamily: Fonts.type.base,
        textAlignVertical: 'center',
        fontWeight: '800',
    },
    savingsCards: { flexDirection: 'row', paddingHorizontal: 5, alignSelf: 'stretch' },
});

import { StyleSheet } from 'react-native';
import { Metrics, Colors, Fonts } from '../../../../themes';

export default StyleSheet.create({
    container: {
        padding: 16,
        shadowColor: 'black',
        shadowRadius: 4,
        shadowOpacity: 0.1,
        shadowOffset: { width: 0, height: 0 },
        backgroundColor: 'white',
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        paddingBottom: Metrics.paddingBottom + 20,
        position: 'absolute',
        elevation: 20,
        bottom: 0,
        left: 0,
        right: 0,
    },
    innerContainer: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    etaView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    etaValue: {
        fontSize: Metrics.normalize(15),
        color: Colors.black,
        fontFamily: Fonts.type.base,
        fontWeight: '500',
    },
    driverImageView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    fareView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    fareValueText: {
        color: Colors.priceColor,
        fontFamily: Fonts.type.base,
        fontSize: Metrics.normalize(15),
        fontWeight: '500',
    },
    subText: {
        fontSize: Metrics.normalize(13),
        fontFamily: Fonts.type.base,
        color: Colors.grayTextColor,
        fontWeight: '400',
    },
    cancelRideButton: {
        alignSelf: 'stretch',
        marginVertical: 20,
    },
});

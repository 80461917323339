/* eslint-disable camelcase */
import React from 'react';
import { View, Text } from 'react-native';
import { observer } from 'mobx-react';
import moment from 'moment';

import BottomRideInProgressStyles from './styles/BottomRideInProgressStyles';
import DriverAvatar from '../driverAvatar/DriverAvatar';
import { Localizable } from '../../../lib';

interface Props {
    currentRideStatus: any;
    currentRide: any;
}

export default observer(
    ({
        currentRideStatus: { driver: { picture_url } = {}, eta },
        currentRide: {
            offer: { rate_estimate },
        },
    }: Props) => {
        const etaValue = moment()
            .add(eta, 'm')
            .format('h:mma');
        return (
            <View style={BottomRideInProgressStyles.container}>
                <View style={BottomRideInProgressStyles.innerContainer}>
                    <View style={BottomRideInProgressStyles.etaView}>
                        <Text style={BottomRideInProgressStyles.etaValue}>{etaValue}</Text>
                        <Text style={BottomRideInProgressStyles.subText}>
                            {Localizable.t('taxis.bottomRideView.dropoffEta')}
                        </Text>
                    </View>
                    <View style={BottomRideInProgressStyles.driverImageView}>
                        <DriverAvatar imageSource={{ uri: picture_url }} />
                    </View>
                    <View style={BottomRideInProgressStyles.fareView}>
                        <Text style={BottomRideInProgressStyles.fareValueText}>
                            {rate_estimate}
                        </Text>
                        <Text style={BottomRideInProgressStyles.subText}>
                            {Localizable.t('taxis.bottomRideView.fare')}
                        </Text>
                    </View>
                </View>
            </View>
        );
    }
);

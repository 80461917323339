import { StyleSheet, Platform } from 'react-native';
import { Fonts, Metrics, Colors } from 'themes';

export default StyleSheet.create({
    container: {
        alignSelf: 'stretch',
        paddingTop: Platform.OS === 'ios' ? Metrics.paddingTop + 10 : 10,
        paddingHorizontal: 10,
    },
    button: {
        height: 34,
        justifyContent: 'center',
        marginVertical: 10,
        marginLeft: 5,
    },
    image: {
        width: 18,
        height: 18,
    },
});

import { observable, action } from 'mobx';
import { persist, create } from 'mobx-persist';

import AsyncStorage from 'modules/asyncStorage/asyncStorage';
import RootStore from '../../RootStore';
import NewProvider from 'domain/app/NewProvider';

const hydrate = create({ storage: AsyncStorage });

export default class NewProviderStore {
    rootStore: RootStore;

    @persist('list')
    @observable
    alreadySeenNewProviders: Array<string> = [];

    @observable
    newProviderPopupShown: boolean = false;

    @observable
    newProviderName: string = '';

    @observable
    newProviderDesc: string = '';

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        hydrate('alreadySeenNewProviders', this);
    }

    markNewProviderAsAlreadySeen = (): void => {
        if (!this.alreadySeenAsNewProvider(this.newProviderName)) {
            this.alreadySeenNewProviders.push(this.newProviderName);
        }
        this.dismissNewProviderPopup();
    };

    @action
    showNewProviderPopup = ({ name, description }: NewProvider) => {
        this.newProviderPopupShown = true;
        this.newProviderName = name;
        if (description) {
            this.newProviderDesc = description;
        }
    };

    alreadySeenAsNewProvider = (providerName: string) =>
        !!this.alreadySeenNewProviders.find(seenProvider => seenProvider === providerName);

    @action
    dismissNewProviderPopup = () => {
        this.newProviderPopupShown = false;
        this.newProviderName = '';
        this.newProviderDesc = '';
    };
}

import { StyleSheet, Platform } from 'react-native';
import { Colors, Fonts, Metrics } from '../../../themes';

export default StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: Colors.typesBackgroundColor,
    },
    listView: {
        marginHorizontal: 15,
    },
    locationContainer: {
        height: Platform.OS === 'android' ? 65 : 50,
        backgroundColor: 'white',
        padding: 10,
        marginTop: 10,
        borderBottomColor: Colors.typesBackgroundColor,
        borderBottomWidth: 1,
    },
    locationText: {
        fontSize: Metrics.normalize(18),
        color: Colors.darkTextColor,
        fontFamily: Fonts.type.base,
        fontWeight: '400',
        flex: 1,
    },
    suggestionTitle: {
        fontSize: Metrics.normalize(16),
        color: Colors.suggestionTitleColor,
        fontFamily: Fonts.type.base,
        fontWeight: '400',
        marginBottom: 2,
    },
    suggestionDescription: {
        fontSize: Metrics.normalize(12),
        color: Colors.grayTextColor,
        fontFamily: Fonts.type.base,
        fontWeight: '400',
    },
    suggestionItemContainer: {
        flexDirection: 'row',
        height: 55,
    },
    suggestionsItemLeftContainer: {
        width: 60,
        padding: 30,
        alignItems: 'center',
        justifyContent: 'center',
    },
    suggestionsPin: {
        width: 16,
        height: 16,
    },
    separator: {
        position: 'absolute',
        bottom: 0,
        right: 0,
        left: 0,
        backgroundColor: Colors.grayTextColor,
        height: 1,
        opacity: 0.1,
    },
    addressDot: {
        width: 8,
        height: 8,
        borderRadius: 4,
        backgroundColor: Colors.grayTextColor,
    },
});

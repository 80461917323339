import { StyleSheet } from 'react-native';
import { Fonts, Metrics, Colors } from '../../../themes';

export default StyleSheet.create({
    container: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: 'white',
    },
    closeImage: {
        width: 14,
        height: 14,
    },
    dollarImage: {
        height: 44,
        width: 44,
        marginLeft: 40,
        alignSelf: 'flex-start',
    },
    titleText: {
        color: Colors.black,
        fontSize: Metrics.normalize(19),
        fontFamily: Fonts.type.base,
        fontWeight: '600',
        marginHorizontal: 40,
        marginVertical: 25,
    },
    smallTitleText: {
        color: Colors.black,
        fontSize: Metrics.normalize(17),
        fontWeight: '600',
        fontFamily: Fonts.type.base,
        marginBottom: 10,
    },
    contentText: {
        marginHorizontal: 40,
        color: Colors.grayTextColor,
        fontSize: Metrics.normalize(14),
        fontFamily: Fonts.type.base,
        fontWeight: '300',
    },
    infoContentText: {
        color: Colors.grayTextColor,
        fontSize: Metrics.normalize(14),
        fontWeight: '300',
        fontFamily: Fonts.type.base,
    },
    infoContainer: {
        marginTop: 20,
        backgroundColor: 'white',
        marginHorizontal: 20,
        shadowColor: '#E5E5ED',
        shadowOpacity: 1,
        borderRadius: 18,
        padding: 15,
        shadowOffset: { width: 0, height: 0 },
    },
    infoInnerContainer: {
        flexDirection: 'row',
    },
    textView: {
        marginLeft: 10,
        flex: 1,
    },
    separator: {
        height: 1,
        alignSelf: 'stretch',
        marginHorizontal: 10,
        marginVertical: 25,
        backgroundColor: '#ECEEF1',
    },
    showInfoScreenButton: {
        marginHorizontal: 20,
        marginVertical: 30,
    },
    closeScreenButtonImage: {
        height: 20,
        width: 20,
        marginTop: 50,
        marginRight: 30,
        alignSelf: 'flex-end',
    },
});

import React, { Component } from 'react';
import { View, StyleSheet, ActivityIndicator, Text, TextInput } from 'react-native';
import { inject, observer } from 'mobx-react';
import KeyboardAwareScrollView from 'modules/keyboardAwareScrollView/keyboardAwareScrollView';

import AdditionalInfoStyles from '../../styles/AdditionalInfoStyles';
import ServicesStore from '../../../../stores/ServicesStore';

import { Colors } from '../../../../themes';
import { Localizable } from '../../../../lib';
import { NextButton, ErrorMessage, InfoLabel } from '../../providerConnectingComponents';
import { Stores } from '../../../../stores/RootStore';
import TitleNavigationBar from '../../../navigationBars/TitleNavigationBar';

interface Props {
    servicesStore: ServicesStore;
}

@inject(({ servicesStore }: Stores) => ({
    servicesStore,
}))
@observer
export default class ProviderConnectingVia extends Component<Props> {
    passwordInputRef: React.RefObject<TextInput> = React.createRef();

    focusPasswordInput = () => {
        if (this.passwordInputRef && this.passwordInputRef.current) {
            this.passwordInputRef.current.focus();
        }
    };

    componentDidMount() {
        Analytics.trackScreenView('04-02-01b_linkPhoneVerify');
        Analytics.trackServiceLinking('bolt', 'connecting', 'credentials_screen');
    }

    render() {
        const {
            servicesStore: {
                hideProviderPopup,
                arroServiceStore: {
                    loading,
                    onCredentialsSubmit,
                    setEmail,
                    setPassword,
                    email,
                    errorMessage,
                    password,
                },
            },
        } = this.props;

        return (
            <View
                style={[
                    StyleSheet.absoluteFill,
                    {
                        backgroundColor: Colors.typesBackgroundColor,
                    },
                ]}
            >
                <TitleNavigationBar title="Arro" handleBackClick={() => hideProviderPopup()} />
                <KeyboardAwareScrollView
                    contentContainerStyle={{ paddingHorizontal: 20 }}
                    keyboardShouldPersistTaps="handled"
                >
                    <Text style={AdditionalInfoStyles.title}>
                        {Localizable.t('additionalInfo.enterCredentials')}
                    </Text>

                    <Text style={AdditionalInfoStyles.description}>
                        {Localizable.t('additionalInfo.placeholderEmail')}
                    </Text>
                    <View style={AdditionalInfoStyles.inputBorder}>
                        <TextInput
                            style={[
                                AdditionalInfoStyles.textInput,
                                {
                                    fontWeight: email.length ? '300' : '100',
                                },
                            ]}
                            keyboardType="email-address"
                            placeholder={Localizable.t('additionalInfo.placeholderEmail')}
                            placeholderTextColor={Colors.lightGrayColor}
                            value={email}
                            onChangeText={setEmail}
                            autoFocus
                            autoCorrect={false}
                            autoCapitalize="none"
                            selectionColor={Colors.obiTeal}
                            onSubmitEditing={this.focusPasswordInput}
                            returnKeyType="next"
                        />
                    </View>

                    <Text style={AdditionalInfoStyles.description}>
                        {Localizable.t('additionalInfo.placeholderPassword')}
                    </Text>
                    <View style={AdditionalInfoStyles.inputBorder}>
                        <TextInput
                            style={[
                                AdditionalInfoStyles.textInput,
                                {
                                    fontWeight: password.length ? '300' : '100',
                                },
                            ]}
                            ref={this.passwordInputRef}
                            keyboardType="default"
                            placeholder={Localizable.t('additionalInfo.placeholderPassword')}
                            placeholderTextColor={Colors.lightGrayColor}
                            value={password}
                            secureTextEntry
                            onChangeText={setPassword}
                            autoCorrect={false}
                            autoCapitalize="none"
                            selectionColor={Colors.obiTeal}
                            onSubmitEditing={onCredentialsSubmit}
                            returnKeyType="done"
                        />
                    </View>
                    {errorMessage.length !== 0 && <ErrorMessage message={errorMessage} />}
                    <InfoLabel info={Localizable.t('additionalInfo.arro.permissionClick')} />
                    <InfoLabel info={Localizable.t('additionalInfo.arro.info')} />
                    <NextButton onPress={onCredentialsSubmit} />
                </KeyboardAwareScrollView>
                {loading && (
                    <View
                        style={[
                            StyleSheet.absoluteFill,
                            {
                                backgroundColor: Colors.transporentBackground,
                                alignItems: 'center',
                                justifyContent: 'center',
                            },
                        ]}
                    >
                        <ActivityIndicator color={Colors.obiTeal} size="large" />
                    </View>
                )}
            </View>
        );
    }
}

import { StyleSheet, Platform } from 'react-native';
import { Fonts, Metrics, Colors } from '../../../themes';

export default StyleSheet.create({
    container: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        borderBottomColor: Colors.darkerGrayBackgroundColor,
        borderBottomWidth: 1,
        alignSelf: 'stretch',
    },
    listContainer: {
        flex: 1,
        backgroundColor: 'white',
        flexDirection: 'row',
    },
    locationContainer: {
        flex: 1,
        justifyContent: 'center',
        paddingVertical: 5,
    },
    labelText: {
        marginTop: Platform.OS === 'android' ? 15 : 5,
        marginBottom: Platform.OS === 'android' ? -10 : -5,
        fontSize: 10,
        color: Colors.grayTextColor,
        fontFamily: Fonts.type.base,
        fontWeight: '500',
    },
    locationText: {
        fontSize: Metrics.normalize(15),
        color: Colors.black,
        fontFamily: Fonts.type.base,
        fontWeight: '500',
        flex: 1,
        marginVertical: 0,
        paddingVertical: 0,
        textAlignVertical: 'center',
    },
    androidAddressOverlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'white',
        justifyContent: 'center',
        borderRadius: 5,
        paddingHorizontal: 10,
    },
    deleteInputButton: {
        opacity: 0.5,
        paddingLeft: 8,
    },
    deleteInputIcon: {
        width: 10,
        height: 10,
    },
    suggestionTitle: {
        fontSize: 14,
        color: Colors.suggestionTitleColor,
        fontFamily: Fonts.type.base,
        fontWeight: '500',
        marginBottom: 2,
    },
    suggestionDescription: {
        fontSize: 12,
        color: Colors.suggestionTitleColor,
        fontFamily: Fonts.type.base,
        fontWeight: '300',
    },
    distance: {
        fontSize: 14,
        color: Colors.grayTextColor,
        fontFamily: Fonts.type.base,
        fontWeight: '300',
        marginTop: 4,
    },
    suggestionItemContainer: {
        flexDirection: 'row',
        height: 55,
    },
    suggestionsItemLeftContainer: {
        width: 70,
        alignItems: 'center',
        justifyContent: 'center',
    },
    suggestionsPin: {
        width: 16,
        height: 16,
    },
    separator: {
        position: 'absolute',
        bottom: 0,
        right: 0,
        left: 0,
        backgroundColor: Colors.grayTextColor,
        height: 1,
        opacity: 0.1,
    },
    closeButtonView: {
        flex: 0.15,
        alignSelf: 'stretch',
        alignItems: 'center',
        justifyContent: 'flex-start',
        paddingTop: 25,
    },
    closeButtonImage: {
        width: 15,
        height: 15,
    },
    addressContainer: {
        flex: 1,
        flexDirection: 'row',
    },
    addressLeftContainer: {
        flex: 0.15,
        alignSelf: 'stretch',
        alignItems: 'center',
        justifyContent: 'flex-start',
        paddingTop: 25,
    },
    addressRightSeparators: {
        backgroundColor: Colors.grayTextColor,
        height: 1,
        marginRight: 40,
        opacity: 0.1,
        marginBottom: -5,
    },
    addressDot: {
        width: 8,
        height: 8,
        borderRadius: 4,
        backgroundColor: Colors.grayTextColor,
    },
    currentDot: {
        width: 8,
        height: 8,
        borderRadius: 4,
        backgroundColor: Colors.centerColor,
    },
    cancelView: {
        width: 30,
        height: 30,
        alignItems: 'center',
        justifyContent: 'center',
    },
    cancelIcon: {
        width: 12,
        height: 12,
        opacity: 0.5,
    },
    addressChangeText: {
        color: Colors.obiTeal,
        fontFamily: Fonts.type.base,
        fontSize: 16,
        backgroundColor: 'transparent',
    },
    addressImage: {
        width: 24,
        height: 70,
    },
    inputContainer: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'space-evenly',
        marginRight: 5,
    },
    inputContainerRow: {
        alignSelf: 'stretch',
        flexDirection: 'row',
        alignItems: 'center',
        borderWidth: 1,
        borderColor: Colors.darkerGrayBackgroundColor,
        borderRadius: 5,
        padding: 10,
    },
    inputContainerText: {
        fontSize: Metrics.normalize(15),
        color: Colors.grayTextColor,
        fontFamily: Fonts.type.base,
        fontWeight: '400',
        marginRight: 5,
    },
    rightContainer: {
        flex: 0.17,
        alignSelf: 'stretch',
        alignItems: 'center',
        justifyContent: 'center',
        marginVertical: 10,
    },
    switchButtonImage: {
        height: 22,
        width: 22,
    },
});

import React from 'react';
import { View, Text, Image, TouchableOpacity } from 'react-native';
import { observer } from 'mobx-react';

import ArrowDown from './ArrowDown';
import styles from './styles/MapLandingPickupMarkerStyles';
import { Images, Colors } from '../../../themes';
import { useStores } from 'containers/App';

interface Props {
    onPress: () => void;
}

export default observer(({ onPress }: Props) => {
    const {
        stores: { addressStore },
    } = useStores();
    return (
        <TouchableOpacity onPress={onPress} style={styles.container}>
            {!!addressStore.pickupTime && (
                <View style={styles.etaView}>
                    <Text style={styles.etaText}>{addressStore.pickupTime} min</Text>
                </View>
            )}
            <View style={styles.innerContainer}>
                <Text style={styles.title}>
                    <Text style={{ color: Colors.obiTeal }}>Pickup </Text>
                    {addressStore.pickup}
                </Text>
                <Image source={Images.pickupArrowGrey} style={styles.image} />
            </View>
            <ArrowDown />
            <Image source={Images.icons.locationDot} resizeMode="contain" style={styles.dot} />
        </TouchableOpacity>
    );
});

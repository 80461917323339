import { Linking } from 'react-native';
import { observable, action, flow, reaction, computed } from 'mobx';
import { persist } from 'mobx-persist';
import moment from 'moment';

import Alert from 'modules/alert/alert';
import hydrate from 'modules/hydrate/hydrate';

import { Analytics } from 'lib';
import { RestClient } from 'services';
import {
    handleError,
    showErrorAlert,
    providerNotConnected,
    errorCode,
    showAlert,
} from 'services/Utils';
import { RootStore, BaseStore } from 'stores';

export default class TaxiRideStore extends BaseStore {
    constructor(rootStore: RootStore) {
        super(rootStore);
        hydrate('taxiRideStore', this);
        reaction(
            () => [
                this.currentRide && this.currentRide.service_provider,
                this.currentRideStatus && this.currentRideStatus.status,
                this.currentRideStatus && this.currentRideStatus.driver,
                !!this.waitingTimeStart,
            ],
            ([serviceProvider, status, driver, timetrackingInProgress]) => {
                if (status === 'PENDING' && !driver && !timetrackingInProgress) {
                    this.startTrackingWaitingForDriverTime();
                } else if (driver && timetrackingInProgress) {
                    this.stopTrackingWaitingForDriverTime('SUCCESS', serviceProvider);
                } else if (status === 'CANCELED' && timetrackingInProgress) {
                    this.stopTrackingWaitingForDriverTime('CANCELED', serviceProvider);
                }
            }
        );
    }

    @observable
    showSurgePopup = false;

    @observable
    surgeUrl = null;

    @observable
    surgeId: number | null = null;

    @observable
    rideRequestInProgress = false;

    @observable
    currentRide: any = null;

    @observable
    lastRideStatus: any = null;

    @observable
    currentRideStatus: any = null;

    @persist
    @observable
    activeRideRequestId = '';

    @observable
    rideFinished = false;

    @observable
    waitingTimeStart: Moment | null = null;

    requestRide = flow(function*(this: TaxiRideStore) {
        const {
            taxiStore,
            taxiStore: { selectedTaxiStore, taxiRouteStore },
            navigationStore: { navigate },
            addressStore,
            filtersStore,
            servicesStore: { showProviderPopup },
        } = this.rootStore.stores;

        if (taxiStore.exactPriceLoading && !taxiStore.exactPrice) return;
        if (!addressStore.pickupLocation || !addressStore.destinationLocation) return;

        this.setRideRequestInProgress(true);

        const {
            paymentMethodsStore: { selectedPaymentMethodId, clearSelectedCard },
        } = this.rootStore.stores;

        try {
            const { data } = yield RestClient.hailTaxi({
                service: selectedTaxiStore.selectedTaxi.service_provider.toLowerCase(),
                offerId: selectedTaxiStore.selectedTaxi.offer_id,
                startLocation: addressStore.pickupLocation,
                endLocation: addressStore.destinationLocation,
                startNick: addressStore.pickupAddressSocketString,
                endNick: addressStore.destinationAddressSocketString,
                surge: this.surgeId ? this.surgeId : null,
                numPassengers: filtersStore.seats,
                pickupDate: moment().format('YYYY-MM-DDTHH:mm:ss.SSS'),
                onDemand: true,
                fareId: taxiStore.exactPrice ? taxiStore.exactPrice.fare_id : null,
                cardId: selectedPaymentMethodId,
                fareValue: taxiStore.exactPrice ? taxiStore.exactPrice.value : null,
            });

            Analytics.trackTaxiRequestRide();

            if (
                selectedTaxiStore.selectedTaxi.provider &&
                selectedTaxiStore.selectedTaxi.price_info &&
                selectedTaxiStore.selectedTaxi.price_info.currency
            ) {
                const price = selectedTaxiStore.selectedTaxi.price_info.rate_estimate.match(/\d+/);

                Analytics.trackTaxiRideDetails(
                    selectedTaxiStore.selectedTaxi.service_provider,
                    selectedTaxiStore.selectedTaxi.service_level,
                    selectedTaxiStore.selectedTaxi.offer_id
                );
                if (!!data) {
                    Analytics.trackPurchase(
                        price[0],
                        selectedTaxiStore.selectedTaxi.price_info.currency,
                        true,
                        false,
                        selectedTaxiStore.selectedTaxi.service_provider,
                        selectedTaxiStore.selectedTaxi.provider.company,
                        {},
                        data.request_id
                    );
                    Analytics.trackBooking('Combined', {
                        fare_estimate: price[0],
                        eta: moment()
                            .add(
                                selectedTaxiStore.selectedTaxi.initial_eta +
                                    (selectedTaxiStore.selectedTaxi.ride_duration ||
                                        taxiRouteStore.rideDuration ||
                                        0),
                                's'
                            )
                            .format('h:mma'),
                        provider: selectedTaxiStore.selectedTaxi.service_provider,
                        service_type: selectedTaxiStore.selectedTaxi.service_level,
                        search_id: selectedTaxiStore.selectedTaxi.offer_id,
                    });
                }
            }
            this.getActiveRide();
        } catch (error) {
            if (providerNotConnected(error)) {
                // TODO: change, to access service stor more directly
                showProviderPopup(selectedTaxiStore.selectedTaxi.service_provider);
            } else {
                if (errorCode(error) === 402) {
                    // this.showPaymentNeededPopup();
                } else if (errorCode(error) === 422) {
                    this.surgeId = error.response.data.surge_confirmation_id;
                    if (error.response.data.href) {
                        this.surgeUrl = error.response.data.href;
                        this.showSurgePopup = true;
                    } else {
                        this.requestRide();
                    }
                } else if (errorCode(error) === 417) {
                    navigate('AdditionalInfo');
                } else if (error.response && error.response.data && error.response.data.error_uri) {
                    Alert.alert('', error.response.data.message, [
                        {
                            text: 'Cancel',
                            onPress: () => {},
                            style: 'cancel',
                        },
                        {
                            text: 'Update info',
                            onPress: () => Linking.openURL(error.response.data.error_uri),
                        },
                    ]);
                } else {
                    showErrorAlert(error);
                }
                // setRideRequestInProgress(false);
            }
        }
        clearSelectedCard();
    }).bind(this);

    handleCancelRide = () => {
        const inProgress =
            this.currentRideStatus && this.currentRideStatus.status === 'IN_PROGRESS';

        Alert.alert(
            '',
            inProgress
                ? 'Are you sure you want to cancel this ride while in progress? If you cancel, the driver will pull over immediately.'
                : 'Do you want to cancel a ride?',
            [
                {
                    text: 'No',
                    onPress: () => console.log('Cancel Pressed'),
                    style: 'cancel',
                },
                { text: 'Yes', onPress: () => this.cancelRide(undefined) },
            ]
        );
    };

    cancelRide = flow(function*(this: TaxiRideStore, confirmationToken?: string) {
        const {
            appStore: { showLoading, hideLoading },
        } = this.rootStore.stores;

        if (!this.currentRide || !this.currentRide.request_id) return;
        showLoading();
        try {
            const { data } = yield RestClient.cancelRide(
                this.currentRide.request_id,
                confirmationToken
            );
            Analytics.trackTaxiCancelRide();
            this.resetRide();
            hideLoading();
        } catch (error) {
            hideLoading();
            if (error.response && error.response.data && error.response.data.token) {
                Alert.alert('', error.response.data.message, [
                    {
                        text: 'Cancel',
                        onPress: () => {},
                        style: 'cancel',
                    },
                    {
                        text: 'Confirm',
                        onPress: () => this.cancelRide(error.response.data.token),
                    },
                ]);
            } else {
                showErrorAlert(error);
            }
        }
    }).bind(this);

    getActiveRide = flow(function*(this: TaxiRideStore) {
        const {
            stores: {
                appStore: { hideLoading },
            },
        } = this.rootStore;
        try {
            const { data } = yield RestClient.getCurrentRide();
            this.setCurrentRide(data);
            this.getCurrentRideStatus(true);
        } catch {
            hideLoading();
        }
    }).bind(this);

    getCurrentRideStatus = flow(function*(this: TaxiRideStore, firstFetch: boolean) {
        const {
            taxiStore: {
                setTaxiState,
                addRideStatusListener,
                blockMap,
                setBlockMap,
                taxiRouteStore: { updateRoute },
            },
            appStore: { hideLoading },
            splashScreenStore: { setForceFadeOutMapLoader },
        } = this.rootStore.stores;

        setForceFadeOutMapLoader();

        if (!this.currentRide || !this.currentRide.request_id) return;

        try {
            const { data } = yield RestClient.getRideStatus(this.currentRide.request_id);

            if (
                !this.currentRideStatus &&
                data &&
                (data.status === 'COMPLETED' || data.status === 'CANCELED')
            ) {
                return;
            }

            this.setCurrentRideStatus(data);
            this.setRideRequestInProgress(false);

            if (
                this.currentRide.service_type.includes('ONDEMAND') &&
                data.status === 'IN_PROGRESS'
            ) {
                this.setActiveRideRequestId(currentRide.request_id);
            } else {
                this.setActiveRideRequestId('');
            }

            hideLoading();
            if (firstFetch) {
                setTaxiState('ride');
                addRideStatusListener();
            }

            if (
                this.lastRideStatus &&
                this.currentRideStatus.status !== this.lastRideStatus.status
            ) {
                setBlockMap(false);
            }

            updateRoute(!blockMap, false);

            if (this.currentRideStatus.status === 'COMPLETED') {
                showAlert('Ride is completed, thank you for a ride');
                Analytics.trackTaxiCompleteRide();
                this.setRideFinished(true, this.currentRide.request_id);
                this.resetRide();
            } else if (this.currentRideStatus.status === 'CANCELED') {
                Alert.alert('Ride was canceled', '', [{ text: 'Ok' }]);
                Analytics.trackTaxiCancelRide();
                this.resetRide();
            } else if (firstFetch) {
                setTaxiState('ride');
                addRideStatusListener();
            }

            if (
                this.lastRideStatus &&
                this.currentRideStatus.status !== this.lastRideStatus.status
            ) {
                setBlockMap(false);
            }
            updateRoute(!blockMap);

            this.setLastRideStatus(data);
        } catch (error) {
            if (error.response && error.response.status === 404 && this.currentRide) {
                this.resetRide();
            }
            handleError(error);
            hideLoading();
        }
    }).bind(this);

    @action
    handleSurge = (surgeId: number) => {
        this.surgeId = surgeId;
        this.requestRide();
    };

    @action
    hideSurgePopup = () => {
        this.showSurgePopup = false;
    };

    @action
    resetSurge = () => {
        this.surgeId = null;
    };

    @action
    setActiveRideRequestId = (activeRideRequestId: string) => {
        this.activeRideRequestId = activeRideRequestId;
    };

    @action
    setCurrentRide = (currentRide: any) => {
        this.currentRide = currentRide;
    };

    @action
    setCurrentRideStatus = (currentRideStatus: any) => {
        this.currentRideStatus = currentRideStatus;
    };

    @action
    setRideFinished = (show: boolean, requestId: string = '') => {
        const {
            taxiStore: {
                centerMap,
                taxiRouteStore: { clearRoute },
            },
        } = this.rootStore.stores;
        clearRoute();
        this.rideFinished = show;
        this.activeRideRequestId = requestId;
        centerMap();
    };

    @action
    callDriver = () => {
        Linking.openURL(`tel:${this.currentRideStatus.driver.phone_number}`);
    };

    @action
    messageDriver = () => {
        Linking.openURL(`sms:${this.currentRideStatus.driver.phone_number}`);
    };

    @action
    resetRide = () => {
        const {
            taxiStore,
            addressStore: { setDestination, setDestinationLocation, setVenueSelected },
            taxiStore: {
                centerMap,
                clearSuggestions,
                getUserLocation,
                removeListeners,
                setBlockMap,
                setSuggestionsState,
                setTaxiState,
                selectedTaxiStore: { setExactPrice },
                taxiRouteStore: { clearRoute, hideRoute },
            },
        } = this.rootStore.stores;
        this.setCurrentRide(null);
        this.setCurrentRideStatus(null);
        setTaxiState('start');
        removeListeners();
        hideRoute();
        clearRoute();
        setDestination(null);
        setDestinationLocation(null);
        setExactPrice(null);
        this.setLastRideStatus(null);
        getUserLocation(true, true, 'TaxiStore resetRide');
        setBlockMap(false);
        this.resetSurge();
        taxiStore.newDestinationAddressFocused = false;
        clearSuggestions();
        setVenueSelected(false);
        setSuggestionsState('landing');
        centerMap();
    };

    changeCurrentRideStatus = flow(function*(this: TaxiRideStore, status: any) {
        const {
            stores: {
                appStore: { hideLoading },
            },
        } = this.rootStore;
        try {
            yield RestClient.sandboxChangeStatus(this.currentRide.request_id, status);
            this.getCurrentRideStatus();
        } catch (error) {
            showErrorAlert(error);
        } finally {
            hideLoading();
        }
    }).bind(this);

    @action
    setRideRequestInProgress = (inProgress: boolean) => {
        this.rideRequestInProgress = inProgress;
    };

    @action
    startTrackingWaitingForDriverTime = () => {
        this.waitingTimeStart = moment();
    };

    @action
    stopTrackingWaitingForDriverTime = (result: string, serviceProvider: any) => {
        const {
            taxiStore: { selectedTaxiStore, taxiRouteStore },
        } = this.rootStore.stores;
        const timeTaken = moment().diff(this.waitingTimeStart, 'seconds');
        Analytics.trackDriverAccept(result, timeTaken, serviceProvider);
        Analytics.trackBooking('Direct', {
            fare_estimate: selectedTaxiStore.selectedTaxiPrice,
            provider: serviceProvider,
            eta: moment()
                .add(
                    selectedTaxiStore.selectedTaxi.initial_eta +
                        (selectedTaxiStore.selectedTaxi.ride_duration ||
                            taxiRouteStore.rideDuration ||
                            0),
                    's'
                )
                .format('h:mma'),
            search_id: selectedTaxiStore.selectedTaxi.offer_id,
            service_type: selectedTaxiStore.selectedTaxi.service_level,
        });
        this.waitingTimeStart = null;
    };

    @action
    setLastRideStatus = (lastRideStatus: any) => {
        this.lastRideStatus = lastRideStatus;
    };

    @computed
    get etaText() {
        if (this.currentRideStatus.status_v2 === 'ARRIVED') {
            return Localizable.t('taxis.bottomRideView.driverArrived');
        }
        return this.currentRideStatus.eta
            ? `${Localizable.t('taxis.bottomRideView.arrivesIn')} ${this.currentRideStatus.eta} MIN`
            : Localizable.t('taxi.driverEnRoute');
    }

    @computed
    get driverAvatarSource() {
        if (this.currentRideStatus.picture_url) {
            return { uri: this.currentRideStatus.picture_url };
        }
        return this.currentRideStatus.service_provider === 'CURB' ? Images.stockAvatar : null;
    }
}

import React, { useRef } from 'react';
import {
    Animated,
    View,
    Image,
    TouchableOpacity,
    Text,
    FlatList,
    ScrollView,
    Platform,
    StyleSheet,
} from 'react-native';
import { observer } from 'mobx-react';
import { useLocalStore } from 'mobx-react-lite';

import BottomDrawer, { YID } from 'components/drawer/BottomDrawer';
import ModeItem from '../../components/options/ModeItem';
import OptionsStyles from './styles/OptionsStyles';
import ServicesList from 'components/services/newServiceList/ServicesList';
import { Analytics } from '../../lib';
import { Images, Colors } from '../../themes';
import { ModeType } from '../../stores/OptionsStore';
import { useStores } from '../App';

const renderModeItem = ({ item }: { item: ModeType }) => <ModeItem item={item} />;
const bottomDrawerBoundaries: any = { bottom: 0 };

export default observer(() => {
    const {
        stores: {
            addressStore,
            taxiStore,
            optionsStore,
            optionsStore: { selectOptionTab, toggleOptionsOpened },
            layoutStore,
        },
    } = useStores();
    const bottomDrawerRef = useRef<BottomDrawer>(null);
    const drawerStore = useLocalStore(() => ({
        bottomDrawerSnapPoints: [
            {
                y: (-layoutStore.screenHeight * 2) / 3,
                id: 'closed',
            },
            { y: 0, id: 'down' },
        ],
        deltaY: new Animated.Value((-layoutStore.screenHeight * 2) / 3),
    }));
    const onOptionsClose = () => {
        toggleOptionsOpened();
        selectOptionTab('providers');
    };
    const onTabContentChange = ({
        nativeEvent: {
            contentOffset: { x },
            contentSize: { width },
        },
    }) => {
        selectOptionTab(Math.round(x / (width / 2)) == 0 ? 'providers' : 'options');
    };
    const dismissOptions = () => {
        if (bottomDrawerRef.current) {
            bottomDrawerRef.current.setSnap('closed');
            if (taxiStore.taxiState === 'taxis') {
                Analytics.trackScreenView(
                    `02-03_Compare${addressStore.isNewYork ? '_Grouped' : ''}`
                );
            } else {
                Analytics.trackScreenView('02_Map');
            }
        }
    };
    return optionsStore.optionsOpened ? (
        <View style={StyleSheet.absoluteFill} pointerEvents="box-none">
            <BottomDrawer
                backgroundOpacityInterpolation={{
                    inputRange: drawerStore.bottomDrawerSnapPoints.map(point => point.y),
                    outputRange: [0, 0.3],
                }}
                boundaries={bottomDrawerBoundaries}
                deltaY={drawerStore.deltaY}
                initialPoint={drawerStore.bottomDrawerSnapPoints[0].y}
                onClose={onOptionsClose}
                openAnimated={{ toIndex: 1 }}
                ref={bottomDrawerRef}
                snapPoints={drawerStore.bottomDrawerSnapPoints.slice() as Array<YID>}
            >
                <View
                    style={[
                        OptionsStyles.interactableView,
                        {
                            height: (layoutStore.screenHeight * 2) / 3,
                            width: layoutStore.screenWidth,
                        },
                    ]}
                >
                    <View
                        style={[
                            OptionsStyles.interactableInnerView,
                            {
                                height: (layoutStore.screenHeight * 2) / 3 - 19,
                                width: layoutStore.screenWidth,
                            },
                        ]}
                    >
                        <View style={OptionsStyles.navigationContainer}>
                            <View style={OptionsStyles.navigationIconView} />
                            <View style={OptionsStyles.navigationMiddleView} />
                            <View style={OptionsStyles.navigationIconView}>
                                <TouchableOpacity
                                    onPress={dismissOptions}
                                    style={OptionsStyles.navigationIconBackground}
                                >
                                    <View style={OptionsStyles.navigationIconInnerBackground}>
                                        <Image
                                            source={Images.options.closeOrange}
                                            style={OptionsStyles.navigationIcon}
                                            resizeMode="contain"
                                        />
                                    </View>
                                </TouchableOpacity>
                            </View>
                        </View>
                        <View style={OptionsStyles.tabHeaderView}>
                            <View style={OptionsStyles.fakeBorder} />
                            <TouchableOpacity
                                style={[
                                    OptionsStyles.tabView,
                                    optionsStore.selectedTab === 'providers' &&
                                        OptionsStyles.selectedTabView,
                                ]}
                                onPress={() => optionsStore.onOptionTabClick('providers')}
                            >
                                <Text
                                    style={[
                                        OptionsStyles.tabText,
                                        {
                                            color:
                                                optionsStore.selectedTab === 'providers'
                                                    ? Colors.obiTeal
                                                    : Colors.darkPurple,
                                        },
                                    ]}
                                >
                                    Providers
                                </Text>
                            </TouchableOpacity>
                            <View style={OptionsStyles.tabSeparator} />
                            <TouchableOpacity
                                style={[
                                    OptionsStyles.tabView,
                                    optionsStore.selectedTab === 'options' &&
                                        OptionsStyles.selectedTabView,
                                ]}
                                onPress={() => optionsStore.onOptionTabClick('options')}
                            >
                                <Text
                                    style={[
                                        OptionsStyles.tabText,
                                        {
                                            color:
                                                optionsStore.selectedTab === 'options'
                                                    ? Colors.obiTeal
                                                    : Colors.darkPurple,
                                        },
                                    ]}
                                >
                                    Options
                                </Text>
                            </TouchableOpacity>
                        </View>
                        <ScrollView
                            bounces={false}
                            horizontal
                            onScroll={onTabContentChange}
                            pagingEnabled
                            ref={optionsStore.tabScroll}
                            showsHorizontalScrollIndicator={false}
                            scrollEventThrottle={4}
                        >
                            <View
                                style={[
                                    OptionsStyles.contentContainer,
                                    {
                                        width: layoutStore.screenWidth,
                                    },
                                ]}
                            >
                                <ServicesList />
                            </View>
                            <View
                                style={[
                                    OptionsStyles.contentContainer,
                                    {
                                        width: layoutStore.screenWidth,
                                    },
                                ]}
                            >
                                <FlatList
                                    bounces={false}
                                    data={optionsStore.modes}
                                    horizontal
                                    renderItem={renderModeItem}
                                    showsHorizontalScrollIndicator={false}
                                />
                            </View>
                        </ScrollView>
                        <TouchableOpacity style={OptionsStyles.doneButton} onPress={dismissOptions}>
                            <Text style={OptionsStyles.doneButtonText}>DONE</Text>
                        </TouchableOpacity>
                    </View>
                    <View style={OptionsStyles.drawerIndicator} />
                </View>
            </BottomDrawer>
        </View>
    ) : null;
});

import platform from 'platform';
import { Platform } from 'react-native';
import { AxiosRequestConfig } from 'axios';

const protocol = Platform.select({
    web: window?.origin?.split(':')[0] || 'http',
    default: 'https'
})

const proxyServerAddress = `${protocol}://localhost:8080/`;

const getUrlFromAxiosRequest = (request: AxiosRequestConfig) => {
    let url = `${request.baseURL}${!!request.baseURL && !!request.url ? '/' : ''}${request.url}`;
    Object.entries(request.params).forEach(([key, value], index) => {
        url += `${index === 0 ? '?' : '&'}${key}=${encodeURIComponent(`${value}`)}`;
    });
    return url;
};

const proxy = Platform.select({
    web: __DEV__
        ? (request: AxiosRequestConfig) => {
              if (platform.os.family === 'iOS' || platform.os.family === 'Android') {
                  request.baseURL = `${proxyServerAddress}${request.baseURL}`;
              } else {
                  request.baseURL = `${protocol}://localhost:8080/${request.baseURL}`;
              }
              return request;
          }
        : (request: AxiosRequestConfig) => {
              const url = getUrlFromAxiosRequest(request);
              request.baseURL = `/proxy?url=${encodeURIComponent(url)}`;
              request.url = '';
              request.params = {};
              return request;
          },
    default: (request: AxiosRequestConfig) => request,
});

const webLocalhostProxy = () => {
    if (__DEV__) {
        if (platform.os.family === 'iOS' || platform.os.family === 'Android') {
            return proxyServerAddress;
        } else {
            return `${protocol}://localhost:8080/`;
        }
    } else {
        return '';
    }
};

const localhostProxy = Platform.select({
    web: webLocalhostProxy(),
    default: '',
});

export { localhostProxy };
export default proxy;

import { StyleSheet } from 'react-native';
import { Colors, Metrics, Fonts } from '../../../themes';

export default StyleSheet.create({
    savingSectionSmall: {
        borderRadius: 10,
        marginHorizontal: 5,
        padding: 16,
        flex: 1,
        // borderColor: 'red',
        // borderWidth: 1,
        shadowColor: Colors.defaultTextColor,
        shadowRadius: 8,
        shadowOffset: {
            height: 4,
            width: 0,
        },
        shadowOpacity: 0.2,
        backgroundColor: 'white',
    },
    container: {
        flexDirection: 'row',
        alignContent: 'center',
    },
    image: {
        height: Metrics.normalize(18),
        width: Metrics.normalize(18),
        marginRight: 8,
    },
    separator: {
        height: 20,
    },
    textTitle: {
        fontSize: Metrics.normalize(16),
        fontFamily: Fonts.type.base,
        textAlignVertical: 'center',
        fontWeight: '400',
    },
    textValue: {
        fontFamily: Fonts.type.base,
        fontSize: Metrics.normalize(26),
        textAlignVertical: 'center',
        fontWeight: '600',
    },
});

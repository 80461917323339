import { StyleSheet } from 'react-native';
import { Metrics, Fonts, Colors } from '../../../../../themes';

export default StyleSheet.create({
    tooltipContainer: {
        paddingTop: 10,
    },
    arrow: {
        position: 'absolute',
        top: 5,
        alignSelf: 'center',
        backgroundColor: 'white',
        width: 15,
        height: 15,
        borderRadius: 3,
        transform: [{ rotateZ: '45deg' }],
        shadowColor: 'black',
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.05,
        shadowRadius: 5,
        elevation: 5,
    },
    tooltipInnerContainer: {
        alignSelf: 'center',
        backgroundColor: 'white',
        flexDirection: 'row',
        paddingRight: 10,
        paddingLeft: 6,
        paddingVertical: 5,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 8,
        shadowColor: 'black',
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.05,
        shadowRadius: 5,
        elevation: 5,
    },
    tooltipIcon: {
        marginRight: 4,
        width: 20,
        height: 20,
    },
    tooltipText: {
        fontSize: Metrics.normalize(12),
        color: Colors.darkTextColor,
        fontWeight: '700',
        fontFamily: Fonts.type.base,
    },
});

import React from 'react';
import { Image, Platform } from 'react-native';
import { observer } from 'mobx-react';

import Marker from '../../../taxis/Marker';
import { Metrics } from '../../../../themes';
import { bikeProviderInfo } from '../../../../services/Utils';

interface Props {
    station: any;
    selectBikeOnMap: (station: any) => void;
    selectedBikeOnMap: any;
    calloutActive: boolean;
}

const normalStyle = { width: Metrics.normalize(36), height: Metrics.normalize(41) };
const selectedStyle = { width: Metrics.normalize(40), height: Metrics.normalize(46) };
const notSelectedStyle = {
    width: Metrics.normalize(30),
    height: Metrics.normalize(35),
    opacity: 0.2,
};

export default observer(({ station, selectBikeOnMap, selectedBikeOnMap, calloutActive }: Props) => {
    const {
        location: { latitude, longitude, name },
        provider,
    } = station;
    const bikeIcon = bikeProviderInfo(provider).iconMapFull;
    const style =
        selectedBikeOnMap === null || calloutActive
            ? normalStyle
            : selectedBikeOnMap.location.name === name &&
              selectedBikeOnMap.location.latitude === latitude
            ? selectedStyle
            : notSelectedStyle;
    return (
        <Marker
            anchor={{ x: 0.501, y: 1 }}
            key={`${name}${Platform.OS === 'android' ? style.width : ''}`}
            zIndex={11}
            coordinate={{ latitude, longitude }}
            name={name}
            onPress={() => {
                selectBikeOnMap(station);
            }}
            additionalProps={style}
        >
            <Image source={bikeIcon} style={style} resizeMode="contain" />
            {/* {calloutActive && (
                <Callout tooltip>
                    <BikeTooltip
                        // bikesAvailable={bikesAvailable}
                        provider={provider}
                        // docksAvailable={docksAvailable}
                    />
                </Callout>
            )} */}
        </Marker>
    );
});

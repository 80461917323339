import { StyleSheet } from 'react-native';
import { Fonts, Colors, Metrics } from 'themes';

export default StyleSheet.create({
    taxisComparationList: {
        borderTopColor: Colors.darkerGrayBackgroundColor,
        borderTopWidth: 1,
        // flex: 1,
    },
    taxisComparationListContainer: {
        paddingBottom: Metrics.paddingBottom,
        paddingTop: 20,
    },
    sectionContainer: {
        width: Metrics.screenWidth - 20,
        marginHorizontal: 10,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#fff',
    },
    sectionFooter: {
        width: Metrics.screenWidth - 60,
        marginHorizontal: 30,
        height: 1,
        backgroundColor: Colors.darkerGrayBackgroundColor,
    },
    separator: {
        backgroundColor: Colors.darkerGrayBackgroundColor,
        height: 1,
        marginHorizontal: 20,
    },
});

import { RefObject, createRef } from 'react';
import Animatable from 'modules/animatable/animatable';
import SplashScreen from 'modules/splashScreen/splashScreen';
import { action, when, observable, computed } from 'mobx';

import { View, Platform } from 'react-native';
import RootStore from './RootStore';
import BaseStore from './BaseStore';

export default class SplashScreenStore extends BaseStore {
    @observable
    nativeSplashShown: boolean = true;

    @observable
    reactSplashShown: boolean = true;

    @observable
    mapLoaderShown: boolean = true;

    @observable.ref
    reactSplashRef: RefObject<any> = createRef();

    @observable.ref
    mapLoaderRef?: Animatable.View;

    @observable.ref
    mainViewRef?: View;

    @observable
    savingsValue = 100000;

    @observable
    showMapLanding: boolean = false;

    @observable
    forceFadeOutMapLoader: boolean = false;

    constructor(rootStore: RootStore) {
        super(rootStore);
        when(
            () => this.shouldFadeOutSplash,
            () => {
                this.fadeOutSplash();
            }
        );
        when(
            () => this.shouldFadeOutMapLoader,
            () => {
                const {
                    onboardingStore: { showOnboarding },
                } = this.rootStore.stores;
                showOnboarding();
                this.fadeOutMapLoader();
            }
        );
    }

    @computed
    get shouldFadeOutSplash() {
        return (
            !this.nativeSplashShown &&
            this.rootStore &&
            this.rootStore.stores &&
            this.rootStore.stores.authStore.ready
        );
    }

    @computed
    get shouldFadeOutMapLoader() {
        return !this.reactSplashShown || this.forceFadeOutMapLoader;
        // && this.rootStore.stores.mapStore.userLocationLoaded;
    }

    @action
    setForceFadeOutMapLoader = () => {
        this.forceFadeOutMapLoader = true;
    };

    @action
    hideNativeSplash = () => {
        SplashScreen.hide();
        this.nativeSplashShown = false;
        this.savingsValue = 150680;
    };

    @action
    hideReactSplash = () => {
        this.reactSplashShown = false;
    };

    @action
    hideMapLoader = () => {
        this.mapLoaderShown = false;
    };

    fadeOutSplash = () => {
        if (this.reactSplashRef.current?.fadeOut) {
            this.reactSplashRef.current.fadeOut(100).then(() => {
                this.hideReactSplash();
            });
        } else {
            this.hideReactSplash();
        }
    };

    fadeOutMapLoader = () => {
        const {
            stores: {
                rateAppStore: { rateApp },
            },
        } = this.rootStore;
        if (this.mapLoaderRef && this.mapLoaderRef.fadeOut) {
            this.mapLoaderRef.fadeOut(200).then(() => {
                this.hideMapLoader();
                rateApp();
            });
        } else {
            this.hideMapLoader();
            rateApp();
        }
    };

    @action
    setReactSplashReady = () => {
        this.hideNativeSplash();
    };

    @action
    setMapLoaderRef = (ref: Animatable.View) => {
        this.mapLoaderRef = ref;
    };

    @action
    setMainViewRef = (ref: View) => {
        this.mainViewRef = ref;
    };
}

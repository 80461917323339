import { StyleSheet, Platform } from 'react-native';
import { Colors, Metrics, Fonts } from '../../../themes';

export default StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: Colors.typesBackgroundColor,
    },
    topView: {
        alignSelf: 'stretch',
        alignItems: 'center',
        paddingTop: 40,
        backgroundColor: 'white',
    },
    avatarView: {
        shadowColor: 'black',
        shadowRadius: 3,
        shadowOpacity: 0.1,
        shadowOffset: { width: 0, height: 0 },
        elevation: 2,
        width: 60,
        height: 60,
        borderRadius: 30,
    },
    avatarImage: {
        width: 60,
        height: 60,
        borderRadius: 30,
    },
    nameText: {
        fontFamily: Fonts.type.base,
        fontWeight: '600',
        fontSize: Metrics.normalize(24),
        color: Colors.darkTextColor,
        marginTop: 8,
        marginBottom: 16,
    },
    empty: {
        height: Platform.OS === 'ios' ? 16 : 24,
    },
    closeView: {
        position: 'absolute',
        top: 30,
        right: 6,
    },
    closeImage: {
        height: 18,
        width: 18,
        margin: 10,
    },
    separator: {
        height: 1,
        backgroundColor: Colors.grayTextColor,
        opacity: 0.1,
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
    },
});

import React from 'react';
import { TouchableOpacity, View, Image, Text } from 'react-native';
import { inject } from 'mobx-react';

import ServiceItemReviewScreenStyles from './ServiceItemReviewScreenStyles';
import ServicesStore, { ServiceType } from '../../../stores/ServicesStore';
import { Images } from '../../../themes';
import { Localizable } from '../../../lib';
import { Stores } from '../../../stores/RootStore';
import { providerInfo } from 'services/Utils';

interface Props {
    item: ServiceType;
    servicesStore: ServicesStore;
}

const ServiceItemReviewScreen = ({
    item: { name, logoLinked: logo },
    servicesStore: { showProviderPopup },
}: Props) => (
    <TouchableOpacity
        onPress={() => {
            showProviderPopup(name, true);
        }}
    >
        <View style={ServiceItemReviewScreenStyles.container}>
            <View style={ServiceItemReviewScreenStyles.topContainer}>
                <Image
                    resizeMode="contain"
                    style={ServiceItemReviewScreenStyles.topContainerImage}
                    source={logo}
                />
            </View>
            <View style={ServiceItemReviewScreenStyles.innerContainer}>
                <Text style={ServiceItemReviewScreenStyles.innerContainerTopText}>
                    {Localizable.t('services.serviceItemReviewScreen.connectAccount1')}
                    {providerInfo(name).name}
                    {Localizable.t('services.serviceItemReviewScreen.connectAccount2')}
                </Text>
                <Text style={ServiceItemReviewScreenStyles.innerContainerBottomText}>
                    {Localizable.t('services.serviceItemReviewScreen.discounts')}
                </Text>
            </View>
            <View style={ServiceItemReviewScreenStyles.bottomContainer}>
                <Image
                    resizeMode="contain"
                    source={Images.settings.rightArrow}
                    style={ServiceItemReviewScreenStyles.bottomContainerImage}
                />
            </View>
        </View>
    </TouchableOpacity>
);

export default inject(({ servicesStore }: Stores) => ({ servicesStore }))(ServiceItemReviewScreen);

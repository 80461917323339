// import AsyncStorage from '@react-native-community/async-storage';
import Intercom from 'modules/intercom/intercom';
import Mixpanel from 'modules/mixpanel/mixpanel';
import firebase from 'modules/firebase/firebase';
import { observable, action, computed, flow } from 'mobx';
import { persist } from 'mobx-persist';

import Alert from 'modules/alert/alert';
import AsyncStorage from 'modules/asyncStorage/asyncStorage';
import getEnv from 'modules/config/config';

import BaseStore from './BaseStore';
import RestClient from '../services/RestClient';
import RootStore, { PersistDataStore } from './RootStore';
import { Analytics } from '../lib';
import { getAllUrlParams, showErrorAlertWithText, showAlert } from '../services/Utils';

export default class AuthStore extends BaseStore implements PersistDataStore {
    hydrateStore = (hydrate: Function) => hydrate('authStore', this);

    @observable
    loggedIn = false;

    @observable
    loggedInWithPhone = false;

    @observable
    ready = false;

    @observable
    authPopupVisible = false;

    @observable
    logoutPopupVisible = false;

    @persist
    @observable
    registered = false;

    @persist
    @observable
    accessToken = '';

    // @persist
    @observable
    refreshToken = '';

    @persist('object')
    @observable
    user: any = null;

    @persist
    @observable
    notificationsEnabled = true;

    @persist
    @observable
    updatesNotificationsEnabled = true;

    constructor(rootStore: RootStore) {
        super(rootStore);
        RestClient.setAuthStore(this);
    }

    initAuth = async () => {
        if (!this.accessToken) {
            await this.registerFirstTime();
        } else {
            RestClient.setToken(this.accessToken);
            this.checkUser();
        }
    };

    registerFirstTime = async () => {
        let referrerId;
        try {
            const url = await firebase.links().getInitialLink();
            if (url) {
                const decodedUrl = decodeURIComponent(url);
                const { referrerid } = getAllUrlParams(decodedUrl) as any;
                referrerId = referrerid;
            }
        } catch (error) {
            console.log('getInitialLink error', error);
        }
        try {
            const {
                data: { access_token, refresh_token },
            } = await RestClient.registerUser(false, undefined, referrerId);
            this.login(access_token, refresh_token);
        } catch (error) {
            console.log('registerFirstTime error', { ...error });
        }
    };

    checkUser = () => {
        if (!this.user) {
            this.fetchUser();
        } else {
            const { fetchUserData } = this.rootStore;
            this.setReady();
            this.fetchUser();
            fetchUserData();
        }
    };

    @action
    setReady = () => {
        this.loggedInWithPhone = !!this.user.phone_number;
        this.ready = true;
    };

    fetchUser = flow(function*(this: AuthStore) {
        try {
            const { data } = yield RestClient.getProfile();
            this.loggedIn = true;
            this.setUser(data);
        } catch (error) {
            if (!this.user) {
                Alert.alert(
                    'Alert',
                    "We couldn't connect to server. Please check internet connection and try again",
                    [{ text: 'Ok', onPress: () => this.fetchUser() }]
                );
            } else {
                this.loggedIn = true;
                this.setUser(this.user);
            }
        }
    }).bind(this);

    checkLoggedInAndSetReady = flow(function*(this: AuthStore) {
        if (!this.loggedIn) {
            try {
                const oldToken = yield AsyncStorage.getItem(getEnv('JWT_TOKEN_KEY'));
                const {
                    data: { access_token, refresh_token },
                } = yield RestClient.registerUser(this.registered, oldToken);
                this.login(access_token, refresh_token);
            } catch (error) {
                Alert.alert(
                    'Alert',
                    "We couldn't connect to server. Please check internet connection and try again",
                    [{ text: 'Ok', onPress: () => this.setReady() }]
                );
            }
        } else {
            this.setReady();
        }
    }).bind(this);

    setUser = flow(function*(this: AuthStore, user: User) {
        const { country_code, phone_number, first_name, surname, nickname, user_id } = user;

        this.user = user;
        this.loggedInWithPhone = !!phone_number ? true : false;

        yield Intercom.logout();
        yield Intercom.registerIdentifiedUser({ userId: user_id });

        const intercomParams = {};
        if (country_code && phone_number) {
            intercomParams.phone = `${country_code}${phone_number}`;
        }
        if (first_name && surname) {
            intercomParams.name = `${first_name} ${surname}`;
        } else if (nickname) {
            intercomParams.name = nickname;
        }
        if (!!intercomParams.phone || !!intercomParams.name) {
            Intercom.updateUser(intercomParams);
        }

        yield Mixpanel.identify(user_id);
        if (intercomParams.phone) {
            Mixpanel.set({ $phone: intercomParams.phone });
        }
        if (user.first_name && user.surname) {
            Mixpanel.set({ $first_name: user.first_name, $last_name: user.surname });
        }

        Analytics.identify(
            user_id,
            {
                country_code,
                phone_number,
                first_name,
                surname,
                nickname,
            },
            { integrations: { All: false } }
        );

        yield this.checkLoggedInAndSetReady();
    }).bind(this);

    login = flow(function*(this: AuthStore, accessToken: string, refreshToken: string) {
        this.registered = true;
        this.loggedIn = true;
        this.accessToken = accessToken;
        RestClient.setToken(accessToken);
        this.refreshToken = refreshToken;
        Analytics.trackSignUp();
        yield this.fetchUser();
    }).bind(this);

    @action
    showLogoutPopup = () => {
        this.logoutPopupVisible = true;
    };

    @action
    dismissLogoutPopup = () => {
        this.logoutPopupVisible = false;
    };

    logout = flow(function*(this: AuthStore) {
        const {
            appStore: { showLoading, hideLoading },
        } = this.rootStore.stores;
        try {
            this.dismissLogoutPopup();
            showLoading();
            this.accessToken = '';
            this.refreshToken = '';
            this.user = null;
            this.loggedIn = false;
            this.registered = false;
            this.ready = false;
            RestClient.setToken(null);
            Analytics.trackLogout();
            yield this.initAuth();
            showAlert('Logged out successfully.');
            this.loggedInWithPhone = false;
        } catch (err) {
            showErrorAlertWithText('Something went wrong.');
        } finally {
            hideLoading();
        }
    }).bind(this);

    isLoggedIn(): boolean {
        return !!(!!this.accessToken && this.accessToken.length);
    }

    @action
    setLoggedInWithPhone = (loggedIn: boolean) => {
        this.loggedInWithPhone = loggedIn;
    };

    @computed
    get isLoggedInWithPhoneNumber(): boolean {
        return this.isLoggedIn && this.user && this.user.phone_number;
    }

    @action
    handleTokenRefresh = (token: string): void => {
        this.accessToken = token;
        RestClient.setToken(token);
    };

    @action
    notificationValueChanged = (value: boolean): void => {
        this.notificationsEnabled = value;
        Intercom.updateUser({
            custom_attributes: {
                notifications_disabled: !value,
            },
        });
    };

    @action
    updatesNotificationValueChanged = (value: boolean): void => {
        this.updatesNotificationsEnabled = value;
        Intercom.updateUser({
            custom_attributes: {
                updates_notifications_disabled: !value,
            },
        });
    };

    @action
    showAuthPopup = () => {
        this.authPopupVisible = true;
    };

    @action
    dismissAuthPopup = () => {
        this.authPopupVisible = false;
    };
}

import React, { Component } from 'react';
import KeyboardAwareScrollView from 'modules/keyboardAwareScrollView/keyboardAwareScrollView';
import { View, Text, TextInput } from 'react-native';
import { observer, inject } from 'mobx-react';

import AdditionalInfoStyles from '../../styles/AdditionalInfoStyles';
import NavigationStore from 'stores/navigationStores/NavigationStore';
import ServicesStore from '../../../../stores/ServicesStore';
import TitleNavigationBar from '../../../navigationBars/TitleNavigationBar';
import { Colors } from '../../../../themes';
import { ErrorMessage, NextButton } from '../../providerConnectingComponents';
import { Localizable, Analytics } from '../../../../lib';
import { Stores } from '../../../../stores/RootStore';

interface Props {
    servicesStore: ServicesStore;
    navigationStore: NavigationStore;
}

@inject(({ servicesStore, navigationStore }: Stores) => ({
    servicesStore,
    navigationStore,
}))
@observer
export default class Password extends Component<Props> {
    componentDidMount() {
        Analytics.trackScreenView('04-02-02_linkPassword');
        Analytics.trackServiceLinking('uber', 'connecting', 'password_screen');
    }

    render() {
        const {
            servicesStore: {
                hideProviderPopup,
                uberServiceStore: {
                    onUberPasswordSubmit,
                    passRecoveryPossible,
                    setUberPassword,
                    errorMessage,
                    password,
                },
            },
            navigationStore: { navigate },
        } = this.props;
        return (
            <View style={AdditionalInfoStyles.container}>
                <TitleNavigationBar title="Uber" handleBackClick={() => hideProviderPopup()} />

                <KeyboardAwareScrollView
                    contentContainerStyle={{ paddingHorizontal: 20 }}
                    keyboardShouldPersistTaps="handled"
                >
                    <Text style={AdditionalInfoStyles.title}>
                        {Localizable.t('additionalInfo.uber.enterUberPassword')}
                    </Text>

                    <View style={AdditionalInfoStyles.inputBorder}>
                        <TextInput
                            style={[AdditionalInfoStyles.textInput]}
                            keyboardType="default"
                            placeholder={Localizable.t('additionalInfo.placeholderPassword')}
                            placeholderTextColor={Colors.lightGrayColor}
                            value={password}
                            secureTextEntry
                            onChangeText={setUberPassword}
                            autoFocus
                            autoCorrect={false}
                            autoCapitalize="none"
                            selectionColor={Colors.obiTeal}
                            onSubmitEditing={onUberPasswordSubmit}
                            returnKeyType="done"
                        />
                    </View>

                    {passRecoveryPossible && (
                        <Text
                            onPress={() => {
                                navigate('PasswordRecovery');
                            }}
                            style={AdditionalInfoStyles.passwordRecoveryPossible}
                        >
                            {Localizable.t('additionalInfo.resetPassword')}
                        </Text>
                    )}
                    {errorMessage.length !== 0 && <ErrorMessage message={errorMessage} />}
                    <NextButton onPress={onUberPasswordSubmit} />
                </KeyboardAwareScrollView>
            </View>
        );
    }
}

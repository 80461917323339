import React, { Component } from 'react';
import KeyboardAwareScrollView from 'modules/keyboardAwareScrollView/keyboardAwareScrollView';
import { NavigationParams } from 'react-navigation';
import { View, Text, Image, TextInput } from 'react-native';
import { observer, inject } from 'mobx-react';

import ServicesStore from '../../../../stores/ServicesStore';
import TitleNavigationBar from '../../../navigationBars/TitleNavigationBar';
import { Colors } from '../../../../themes';
import { Localizable, Analytics } from '../../../../lib';
import { ErrorMessage, NextButton } from '../../providerConnectingComponents';
import AdditionalInfoStyles from '../../styles/AdditionalInfoStyles';
import { Stores } from 'stores/RootStore';

interface Props {
    navigation: NavigationParams;
    servicesStore: ServicesStore;
}

@inject(({ servicesStore }: Stores) => ({ servicesStore }))
@observer
export default class TOTP extends Component<Props, {}> {
    componentDidMount() {
        Analytics.trackServiceLinking('uber', 'connecting', 'totp_screen');
    }

    render() {
        const {
            servicesStore: {
                hideProviderPopup,
                uberServiceStore: { TOTP, setUberTOTP, onUberTOTPSubmit, errorMessage },
            },
        } = this.props;
        return (
            <View style={AdditionalInfoStyles.container}>
                <TitleNavigationBar title="Uber" handleBackClick={() => hideProviderPopup()} />

                <KeyboardAwareScrollView
                    contentContainerStyle={{ paddingHorizontal: 20 }}
                    keyboardShouldPersistTaps="handled"
                >
                    <Text style={AdditionalInfoStyles.title}>
                        {Localizable.t('additionalInfo.titleValidateNumberTOTP')}
                    </Text>

                    <View style={[AdditionalInfoStyles.inputBorder, { marginTop: 10 }]}>
                        <TextInput
                            style={[
                                AdditionalInfoStyles.textInput,
                                { fontWeight: TOTP.length ? '300' : '100' },
                            ]}
                            keyboardType="numeric"
                            placeholder={Localizable.t('additionalInfo.descValidateNumberTOTP')}
                            placeholderTextColor={Colors.lightGrayColor}
                            value={TOTP}
                            onChangeText={setUberTOTP}
                            autoFocus
                            autoCorrect={false}
                            selectionColor={Colors.obiTeal}
                            onSubmitEditing={onUberTOTPSubmit}
                            returnKeyType="done"
                        />
                    </View>

                    {errorMessage.length !== 0 && <ErrorMessage message={errorMessage} />}
                    <NextButton onPress={onUberTOTPSubmit} active={TOTP.length > 0} />
                </KeyboardAwareScrollView>
            </View>
        );
    }
}

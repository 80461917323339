/* eslint-disable prefer-destructuring */
/* eslint-disable no-plusplus */
import { observable, action, flow, computed } from 'mobx';

import { LatLng } from 'modules/maps/maps';

import { GeolocationService } from 'services';
import { RootStore } from '..';
import { Images } from 'themes';
import { distance, regionContainingPoints, calculateCurvedRoute } from 'services/Utils';
import { Localizable } from 'lib';

export default class TaxiRouteStore {
    rootStore: RootStore;

    @observable
    routeDistance?: number | null;

    @observable
    rideDuration?: number | null;

    @observable
    showRoute: boolean = false;

    @observable
    showMarkers: boolean = false;

    @observable
    plainRoute: Array<LatLng> = [];

    @observable
    plainMarkers: Array<any> = [];

    @observable
    routeEdgePoints: Array<LatLng> = [];

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
    }

    @action
    updateRouteDistance = (routeDistance: number | null) => {
        this.routeDistance = routeDistance;
    };

    @action
    updateRideDuration = (rideDuration: number | null) => {
        this.rideDuration = rideDuration;
    };

    @action
    updateRouteMarkers = (plainMarkers: Array<any>) => {
        this.plainMarkers = plainMarkers;
    };

    @action
    updateRouteEdgePoints = (routeEdgePoints: Array<LatLng>) => {
        this.routeEdgePoints = routeEdgePoints;
    };

    @action
    updatePlainRoute = (plainRoute: Array<LatLng>) => {
        this.plainRoute = plainRoute;
    };

    @action
    clearRoute = () => {
        this.plainRoute = [];
        this.plainMarkers = [];
    };

    @action
    hideRoute = () => {
        this.showRoute = false;
        this.showMarkers = false;
    };

    updateRoute = flow(function*(
        this: TaxiRouteStore,
        changeRegion: boolean,
        shouldGetTaxiTypes: boolean
    ) {
        const { addressStore, taxiStore } = this.rootStore.stores;
        const {
            taxiResultsStore: { getTaxiTypes },
            taxiRideStore,
            fitMapToCoordinates,
            centerMap,
            setFittingWorkaround,
        } = taxiStore;

        if (taxiStore.taxiState === 'start') return;

        const isRideStatusPending =
            taxiRideStore.currentRideStatus && taxiRideStore.currentRideStatus.status === 'PENDING';
        const isRideStatusInProgress =
            taxiRideStore.currentRideStatus &&
            taxiRideStore.currentRideStatus.status === 'IN_PROGRESS';

        let updatedRoutePickupLocation;
        let updatedRouteDestinationLocation;

        if (taxiStore.taxiState === 'ride') {
            updatedRouteDestinationLocation = taxiRideStore.currentRide.destination;
            if (taxiRideStore.currentRideStatus.location) {
                if (taxiRideStore.currentRideStatus.status !== 'IN_PROGRESS') {
                    updatedRoutePickupLocation = taxiRideStore.currentRideStatus.location;
                    updatedRouteDestinationLocation = taxiRideStore.currentRide.pickup;
                } else {
                    updatedRoutePickupLocation = taxiRideStore.currentRideStatus.location;
                }
            } else {
                updatedRoutePickupLocation = taxiRideStore.currentRide.pickup;
            }
        } else {
            updatedRoutePickupLocation = addressStore.pickupLocation;
            updatedRouteDestinationLocation = addressStore.destinationLocation;
        }

        this.updateRideDuration(null);
        this.updateRouteDistance(null);

        const route: Array<LatLng> = [];
        const pickupDestinationMarkers: Array<any> = [];
        let routeDistance: number;
        let travelTime: number | null;

        const {
            points,
            travelTimeInSeconds,
            lengthInMeters,
        } = yield GeolocationService.getDirectionFormatted(
            updatedRoutePickupLocation,
            updatedRouteDestinationLocation
        );

        if (taxiStore.taxiState !== 'taxis' && taxiStore.taxiState !== 'confirm') {
            route.push(...points);
        } else {
            route.push(
                ...calculateCurvedRoute(
                    updatedRoutePickupLocation,
                    updatedRouteDestinationLocation,
                    5,
                    2
                )
            );
        }
        routeDistance = lengthInMeters;
        travelTime = travelTimeInSeconds;

        let pickupLocationMarkerCoords;
        if (
            taxiRideStore.currentRideStatus &&
            taxiRideStore.currentRideStatus.status === 'IN_PROGRESS'
        ) {
            pickupLocationMarkerCoords = addressStore.pickupLocation;
        } else if (isRideStatusPending && taxiRideStore.currentRideStatus.location) {
            pickupLocationMarkerCoords = updatedRouteDestinationLocation;
        } else {
            pickupLocationMarkerCoords = route[0];
        }
        pickupDestinationMarkers.push({
            coords: pickupLocationMarkerCoords,
            title: 'Pickup',
            image: Images.icons.markerPickup,
            centerOffset: { x: 0.52, y: 0.4 },
        });
        if (!isRideStatusPending) {
            pickupDestinationMarkers.push({
                coords: route[route.length - 1],
                title: 'Destination',
                image: Images.icons.markerDrop,
                centerOffset: { x: 0.52, y: 0.4 },
            });
        }
        this.updateRouteMarkers(pickupDestinationMarkers);
        this.showMarkers = true;

        if (distance(updatedRoutePickupLocation, updatedRouteDestinationLocation) < 0.1) {
            this.showRoute = false;
            this.updatePlainRoute(route);
        } else if (isRideStatusPending && !taxiRideStore.currentRideStatus.location) {
            this.showRoute = false;
        } else {
            this.updatePlainRoute(route);
            this.showRoute = true;
        }

        this.updateRouteDistance(!!routeDistance ? Math.round(routeDistance) : routeDistance);
        this.updateRideDuration(travelTime);

        const edgePoints: Array<LatLng> = [];
        for (let i = 0; i < 4; i++) {
            edgePoints.push(route[0]);
        }
        route.forEach((routePoint: LatLng) => {
            if (routePoint.latitude > edgePoints[0].latitude) {
                edgePoints[0] = routePoint;
            }
            if (routePoint.latitude < edgePoints[1].latitude) {
                edgePoints[1] = routePoint;
            }
            if (routePoint.longitude > edgePoints[2].longitude) {
                edgePoints[2] = routePoint;
            }
            if (routePoint.longitude < edgePoints[3].longitude) {
                edgePoints[3] = routePoint;
            }
        });
        this.updateRouteEdgePoints(edgePoints);

        if (shouldGetTaxiTypes) {
            getTaxiTypes(false);
        }

        if (changeRegion) {
            if (isRideStatusInProgress && taxiRideStore.currentRideStatus.location) {
                fitMapToCoordinates(this.routeEdgePoints);
            } else if (isRideStatusPending && updatedRoutePickupLocation) {
                if (!this.plainMarkers.length) {
                    const newRegion = regionContainingPoints([updatedRoutePickupLocation], 0.01);

                    if (newRegion) {
                        const {
                            mapStore: { updateRegion },
                        } = this.rootStore.stores;

                        setFittingWorkaround();
                        updateRegion(newRegion);
                    }
                } else {
                    fitMapToCoordinates(this.routeEdgePoints);
                }
            } else if (this.plainMarkers.length) {
                fitMapToCoordinates(this.routeEdgePoints);
            }
        }
    }).bind(this);

    @computed
    get markersOrderLat(): boolean {
        return (
            (this.plainMarkers &&
                this.plainMarkers.length > 1 &&
                this.plainMarkers.slice()[0].coords &&
                this.plainMarkers.slice()[1].coords &&
                !(
                    this.plainMarkers.slice()[0].coords.latitude >
                    this.plainMarkers.slice()[1].coords.latitude
                )) ||
            true
        );
    }

    @computed
    get markersOrderLng(): boolean {
        return (
            (this.plainMarkers &&
                this.plainMarkers.length > 1 &&
                !(
                    this.plainMarkers.slice()[0].coords.longitude >
                    this.plainMarkers.slice()[1].coords.longitude
                )) ||
            true
        );
    }

    @computed
    get driverMarkerOrderPickup(): boolean {
        const {
            addressStore,
            taxiStore: { taxiRideStore },
        } = this.rootStore.stores;
        if (taxiRideStore.currentRideStatus && taxiRideStore.currentRideStatus.location) {
            const { latitude: driverLocation } = taxiRideStore.currentRideStatus.location;
            if (addressStore.pickupLocation) {
                const { latitude: pickupLocationLatitude } = addressStore.pickupLocation;
                if (pickupLocationLatitude < driverLocation) {
                    return false;
                }
            }
        }
        return true;
    }

    @computed
    get driverMarkerOrderDestination(): boolean {
        const {
            addressStore,
            taxiStore: { taxiRideStore },
        } = this.rootStore.stores;
        if (taxiRideStore.currentRideStatus && taxiRideStore.currentRideStatus.location) {
            const { latitude: driverLocation } = taxiRideStore.currentRideStatus.location;
            if (addressStore.destinationLocation) {
                const { latitude: destinationLocationLatitude } = addressStore.destinationLocation;
                if (destinationLocationLatitude < driverLocation) {
                    return false;
                }
            }
        }
        return true;
    }

    @computed
    get showDestinationLocationMarker(): boolean {
        const { taxiStore } = this.rootStore.stores;
        return (
            (taxiStore.taxiState === 'confirm' || taxiStore.taxiState === 'taxis') &&
            !!this.plainMarkers &&
            !!this.plainMarkers.length &&
            !!this.plainMarkers[1] &&
            !!this.plainMarkers[1].coords &&
            !!this.plainMarkers[1].coords.latitude
        );
    }

    @computed
    get showPickupLocationMarker(): boolean {
        const { taxiStore } = this.rootStore.stores;
        return (
            (taxiStore.taxiState === 'confirm' || taxiStore.taxiState === 'taxis') &&
            !!this.plainMarkers &&
            !!this.plainMarkers.length &&
            !!this.plainMarkers[0] &&
            !!this.plainMarkers[0].coords &&
            !!this.plainMarkers[0].coords.latitude
        );
    }

    @computed
    get showDestinationMarker(): boolean {
        const {
            taxiStore: { taxiRideStore },
        } = this.rootStore.stores;
        return (
            taxiRideStore.currentRide &&
            taxiRideStore.currentRide.destination &&
            !!taxiRideStore.currentRide.destination.latitude &&
            !!taxiRideStore.currentRideStatus &&
            taxiRideStore.currentRideStatus.status === 'IN_PROGRESS'
        );
    }

    @computed
    get showPickupMarker(): boolean {
        const {
            taxiStore: { taxiRideStore },
        } = this.rootStore.stores;
        return (
            taxiRideStore.currentRide &&
            taxiRideStore.currentRide.pickup &&
            !!taxiRideStore.currentRide.pickup.latitude &&
            !!taxiRideStore.currentRideStatus &&
            !(
                !!taxiRideStore.currentRideStatus &&
                taxiRideStore.currentRideStatus.status === 'IN_PROGRESS'
            ) &&
            !(
                !!taxiRideStore.currentRideStatus &&
                taxiRideStore.currentRideStatus.status === 'PENDING' &&
                !taxiRideStore.currentRideStatus.driver
            )
        );
    }

    @computed
    get pickupMarkerTitle(): string {
        const {
            taxiStore,
            taxiStore: { taxiRideStore },
        } = this.rootStore.stores;
        if (taxiStore.taxiState === 'start') {
            return Localizable.t('taxi.setPickupLocation');
        }
        if (taxiRideStore.currentRideStatus && taxiRideStore.currentRideStatus.location) {
            return Localizable.t('taxi.pickupBold');
        }
        if (taxiRideStore.currentRideStatus) {
            return Localizable.t('taxi.waitingForDriver');
        }
        return '';
    }

    @computed
    get pickupMarkerDescription(): string {
        const {
            taxiStore,
            taxiStore: { taxiRideStore },
        } = this.rootStore.stores;
        if (taxiStore.taxiState === 'start') {
            return '  ';
        }
        if (taxiRideStore.currentRide) {
            return taxiRideStore.currentRide.ask.pickup_address;
        }
        return Localizable.t('taxi.stayDescription');
    }

    @computed
    get showDriverMarker(): boolean {
        const {
            taxiStore: { taxiRideStore },
        } = this.rootStore.stores;
        return (
            !!taxiRideStore.currentRideStatus &&
            !!taxiRideStore.currentRideStatus.driver &&
            !!taxiRideStore.currentRideStatus.location &&
            !!taxiRideStore.currentRideStatus.location.latitude
        );
    }

    @computed
    get showPolylineRoute() {
        const { taxiStore } = this.rootStore.stores;
        return (
            this.showRoute && taxiStore.taxiState !== 'start' && this.plainRoute.slice().length > 0
        );
    }

    @computed
    get destinationMarkerDescription(): string {
        const { addressStore } = this.rootStore.stores;
        if (addressStore.destination) {
            return addressStore.destination;
        }
        return 'Destination';
    }
}

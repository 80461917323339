/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import Axios from 'axios';
import { GoogleMap, LoadScript } from '@react-google-maps/api';
import { LatLng } from 'react-native-maps';
import { View, StyleSheet } from 'react-native';
import getEnv from '../../../config/config';
import Marker from './Marker';

const options = {
    enableHighAccuracy: false,
    timeout: 5000,
    maximumAge: 0,
};

interface Event {
    nativeEvent: {
        coordinate: LatLng;
    };
}

interface Props {
    initialRegion: LatLng;
    lastRegion: LatLng;
    mapPadding: {
        top: number;
        bottom: number;
    };
    onMapReady: () => void;
    onPanDrag?: () => void;
    onRegionChangeComplete: (location: LatLng) => void;
    onUserLocationChange: (event: Event) => void;
}

const styles = StyleSheet.create({
    userPositionMarker: {
        width: 15,
        height: 15,
        backgroundColor: 'white',
        borderRadius: 7.5,
        alignItems: 'center',
        justifyContent: 'center',
        shadowColor: 'black',
        shadowRadius: 10,
        shadowOpacity: 0.4,
        shadowOffset: { width: 0, height: 0 },
        elevation: 5,
    },
    userPositionMarkerInner: {
        width: 12,
        height: 12,
        backgroundColor: 'blue',
        borderRadius: 6,
    },
});

class MapView extends Component<Props> {
    map?: google.maps.Map;

    positionWatchId?: number;

    userPosition?: LatLng;

    firstUserPosistionCentered: boolean = false;

    handleMapMounted = (map: google.maps.Map) => {
        const { onMapReady } = this.props;
        this.map = map;
        if (onMapReady) {
            onMapReady();
        }
        this.onDragEnd();
    };

    getPositionSuccess = ({ coords }: Position) => {
        const { onUserLocationChange } = this.props;
        this.userPosition = coords;
        onUserLocationChange({ nativeEvent: { coordinate: coords } });
        if (!this.firstUserPosistionCentered) {
            this.firstUserPosistionCentered = true;
            this.animateToRegion(coords);
        }
    };

    getPositionError = () => {
        this.geolocateWithAPI();
    };

    geolocateWithAPI = async () => {
        try {
            const {
                data: {
                    location: { lat, lng },
                    accuracy,
                },
            } = await Axios.post(
                `https://www.googleapis.com/geolocation/v1/geolocate?key=${getEnv(
                    'GOOGLE_API_KEY'
                )}`
            );
            this.getPositionSuccess({
                timestamp: Date.now(),
                coords: {
                    latitude: lat,
                    longitude: lng,
                    accuracy,
                    altitude: 0,
                    altitudeAccuracy: 0,
                    heading: 0,
                    speed: 0,
                },
            });
        } catch (error) {
            console.warn('getPositionError', error);
        }
    };

    componentDidMount() {
        this.positionWatchId = navigator.geolocation.watchPosition(
            this.getPositionSuccess,
            this.getPositionError,
            options
        );
    }

    componentWillUnmount() {
        if (this.positionWatchId) {
            navigator.geolocation.clearWatch(this.positionWatchId);
        }
    }

    animateToRegion(coordinates: LatLng) {
        if (this.map) {
            this.map.setCenter({ lat: coordinates.latitude, lng: coordinates.longitude });
        }
    }

    fitToCoordinates = (points: Array<LatLng>) => {
        const newPoints = points.map(({ latitude, longitude }: LatLng) => ({
            lat: latitude,
            lng: longitude,
        }));
        const bounds = new google.maps.LatLngBounds(newPoints[0], newPoints[0]);
        newPoints.forEach(element => {
            bounds.extend(element);
        });
        if (this.map) {
            this.map.fitBounds(bounds, { bottom: 130, top: 130, left: 0, right: 0 });
        }
    };

    onDragEnd = () => {
        const { onRegionChangeComplete } = this.props;
        if (onRegionChangeComplete && this.map) {
            const { center } = this.map;
            const latitude = center.lat();
            const longitude = center.lng();
            onRegionChangeComplete({
                latitude,
                longitude,
            });
        }
    };

    render() {
        const {
            children,
            initialRegion,
            lastRegion,
            mapPadding: { top, bottom },
            onPanDrag,
        } = this.props;
        const { latitude, longitude } = lastRegion.latitude === 0 ? initialRegion : lastRegion;

        return (
            <LoadScript
                googleMapsApiKey={getEnv('GOOGLE_API_KEY')}
                id="script-loader"
                key="loader"
                loadingElement={<></>}
            >
                <GoogleMap
                    key="map"
                    {...this.props}
                    id="example-map"
                    mapContainerStyle={{
                        height: '100%',
                        width: '100%',
                        marginBottom: bottom - top - 100,
                        marginTop: -100,
                    }}
                    zoom={15}
                    center={{
                        lat: latitude,
                        lng: longitude,
                    }}
                    onDrag={onPanDrag}
                    clickableIcons={false}
                    onLoad={this.handleMapMounted}
                    // onZoomChanged={this.onDragEnd}
                    // onDragEnd={this.onDragEnd}
                    onIdle={this.onDragEnd}
                >
                    {children}
                    {this.map && this.userPosition && (
                        <Marker anchor={{ x: 1, y: 0.5 }} coordinate={this.userPosition}>
                            <View style={styles.userPositionMarker}>
                                <View style={styles.userPositionMarkerInner} />
                            </View>
                        </Marker>
                    )}
                </GoogleMap>
            </LoadScript>
        );
    }
}

export default MapView;

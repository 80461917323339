import { StyleSheet, Platform } from 'react-native';
import { Fonts, Metrics, Colors } from '../../../themes';

export default StyleSheet.create({
    container: {
        position: 'absolute',
        top: 40,
        left: 0,
        right: 0,
        bottom: 0,
        shadowColor: 'black',
        shadowRadius: 3,
        shadowOpacity: 0.2,
        shadowOffset: { width: 0, height: 0 },
        elevation: 2,
    },
    topBar: {
        flex: 1,
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        backgroundColor: 'white',
    },
    content: {
        flexDirection: 'row',
        backgroundColor: 'transparent',
        paddingHorizontal: 10,
        marginVertical: 5,
        alignItems: 'center',
        height: 35,
    },
    cancelButton: {
        width: 80,
    },
    titleText: {
        fontSize: Metrics.normalize(15),
        fontWeight: '700',
        fontFamily: Fonts.type.base,
        color: 'black',
        flex: 1,
        textAlign: 'center',
    },
    cancelText: {
        fontSize: Metrics.normalize(16),
        fontWeight: '400',
        fontFamily: Fonts.type.base,
        color: Colors.blueButtonColor,
        width: 80,
    },
    rowView: {
        marginTop: 16,
        marginVertical: 8,
        borderRadius: 6,
        borderWidth: 1,
        borderColor: Colors.lightGrayColor,
        shadowColor: 'black',
        shadowRadius: 2,
        shadowOpacity: 0.2,
        shadowOffset: { width: 0, height: 0 },
        elevation: 2,
        padding: 10,
        alignSelf: 'stretch',
        // height: 40,
        alignItems: 'center',
        justifyContent: 'flex-start',
        flexDirection: 'row',
    },
    separator: {
        width: 80,
    },
});

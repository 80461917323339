import React from 'react';
import { View, Text, Image, TouchableOpacity, Linking, StyleSheet, Platform } from 'react-native';
import { observer, inject } from 'mobx-react';

import BikeStore from '../../../../stores/BikeStore';
import BikeInfoBottomViewStyles from './BikeInfoBottomViewStyles';
import CenterMapButton from '../../../map/centerMapButton/CenterMapButton';
import TaxiStore from '../../../../stores/TaxiStore';
import { Analytics, Localizable } from '../../../../lib';
import { Metrics, Images, Colors } from '../../../../themes';
import { bikeProviderInfo } from '../../../../services/Utils';
import { Stores } from '../../../../stores/RootStore';

interface Props {
    bikeStore: BikeStore;
    taxiStore: TaxiStore;
}

const BikeInfoBottomView = ({
    bikeStore: { selectedBikeOnMap, clearBikeOnMap },
    taxiStore: { centerMap },
}: Props) => {
    const {
        provider,
        deeplinks: { ios: iosDeep, android: androidDeep } = {},
        store: { ios: iosStore, android: androidStore } = {},
        distance,
        time,
    } = selectedBikeOnMap;

    const { price, priceWebLink, icon } = bikeProviderInfo(provider);

    const [appDeepLink, storeDeepLink] = Platform.select({
        android: [androidDeep, androidStore],
        ios: [iosDeep, iosStore],
        web: [iosDeep, iosStore],
    });

    const hasAnyLinks = appDeepLink || storeDeepLink;

    return (
        <View
            style={[StyleSheet.absoluteFill, BikeInfoBottomViewStyles.container]}
            pointerEvents="box-none"
        >
            <TouchableOpacity
                style={BikeInfoBottomViewStyles.clearBikeOnMapButton}
                onPress={() => {
                    clearBikeOnMap();
                    centerMap();
                }}
            >
                <Image
                    style={BikeInfoBottomViewStyles.clearBikeOnMapButtonImage}
                    source={Images.icons.closeBikeView}
                />
            </TouchableOpacity>
            <View style={BikeInfoBottomViewStyles.innerContainer}>
                {!!time && [
                    <Text style={BikeInfoBottomViewStyles.walkView} key="first">
                        {`${time} ${Localizable.t(
                            'taxis.bikes.bikeInfoBottomView.xMinuteWalk'
                        )} (${distance} ${Localizable.t('taxis.bikes.bikeInfoBottomView.miles')})`}
                    </Text>,
                    <View key="second" style={BikeInfoBottomViewStyles.walkViewEmpty} />,
                ]}
                <View style={BikeInfoBottomViewStyles.bikeInfoView}>
                    <View style={BikeInfoBottomViewStyles.bikeInfoImageView}>
                        <Image
                            resizeMode="contain"
                            source={icon}
                            style={BikeInfoBottomViewStyles.bikeInfoImage}
                        />
                    </View>
                    {selectedBikeOnMap && selectedBikeOnMap.battery_level !== undefined && (
                        <View>
                            <View style={BikeInfoBottomViewStyles.batteryLevelView}>
                                <Image
                                    source={Images.battery}
                                    style={BikeInfoBottomViewStyles.batteryLevelImage}
                                />
                                <Text style={BikeInfoBottomViewStyles.batteryLevelValueText}>
                                    {` ${selectedBikeOnMap.battery_level}`}
                                </Text>
                            </View>
                            <Text style={BikeInfoBottomViewStyles.batteryLevelText}>
                                {Localizable.t('taxis.bikes.bikeInfoBottomView.batteryLevel')}
                            </Text>
                        </View>
                    )}
                    {selectedBikeOnMap.num_docks_available !== undefined && (
                        <View>
                            <View style={BikeInfoBottomViewStyles.docksNumberView}>
                                <Image
                                    source={Images.check}
                                    style={BikeInfoBottomViewStyles.docksNumberImage}
                                />
                                <Text style={BikeInfoBottomViewStyles.docksNumberValueText}>
                                    {` ${selectedBikeOnMap.num_bikes_available}`}
                                    <Text
                                        style={BikeInfoBottomViewStyles.docksNumberInnerValueText}
                                    >
                                        {`/${selectedBikeOnMap.num_bikes_available +
                                            selectedBikeOnMap.num_docks_available}`}
                                    </Text>
                                </Text>
                            </View>
                            <Text style={BikeInfoBottomViewStyles.docksNumberText}>
                                {Localizable.t('taxis.bikes.bikeInfoBottomView.bikesAvailable')}
                            </Text>
                        </View>
                    )}
                </View>
                <View style={BikeInfoBottomViewStyles.innerBottomContainer}>
                    <View style={BikeInfoBottomViewStyles.innerBottomContainerView}>
                        <Image
                            source={Images.dollar}
                            style={BikeInfoBottomViewStyles.innerBottomContainerImage}
                            resizeMode="contain"
                        />
                        <TouchableOpacity
                            onPress={() => {
                                if (priceWebLink) {
                                    Linking.openURL(priceWebLink);
                                }
                            }}
                        >
                            <Text style={BikeInfoBottomViewStyles.detailsButtonValueText}>
                                {price}
                            </Text>
                            <Text style={BikeInfoBottomViewStyles.detailsButtonText}>
                                {Localizable.t('taxis.bikes.bikeInfoBottomView.details')}
                            </Text>
                        </TouchableOpacity>
                    </View>
                    {hasAnyLinks && (
                        <TouchableOpacity
                            style={BikeInfoBottomViewStyles.bottomButton}
                            onPress={() => {
                                Analytics.trackBikeClickUnlock(selectedBikeOnMap);
                                if (appDeepLink) {
                                    Linking.openURL(appDeepLink)
                                        .then(result => {})
                                        .catch(err => {
                                            if (storeDeepLink) {
                                                Linking.openURL(storeDeepLink)
                                                    .then(result => {})
                                                    .catch(err2 => {});
                                            }
                                        });
                                } else if (storeDeepLink) {
                                    Linking.openURL(storeDeepLink)
                                        .then(result => {})
                                        .catch(err => {});
                                }
                            }}
                        >
                            <Text style={BikeInfoBottomViewStyles.bottomButtonText}>
                                {Localizable.t('taxis.bikes.bikeInfoBottomView.unlock')}
                            </Text>
                        </TouchableOpacity>
                    )}
                </View>
            </View>
        </View>
    );
};

export default inject(({ bikeStore, taxiStore }: Stores) => ({ bikeStore, taxiStore }))(
    observer(BikeInfoBottomView)
);

import * as serviceWorker from './serviceWorker';
import App from 'containers/App';
import dotenv from 'dotenv';
import { AppRegistry } from 'react-native';

dotenv.config({ path: `.env${__DEV__ ? '' : '.production'}` });

AppRegistry.registerComponent('bellhop', () => App);
AppRegistry.runApplication('bellhop', {
    rootTag: document.getElementById('root'),
});

serviceWorker.register();

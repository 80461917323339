/* eslint-disable camelcase */
import { observable, action, computed } from 'mobx';
import { persist } from 'mobx-persist';
import { Linking } from 'react-native';

import BaseStore from './BaseStore';
import Bike from 'domain/app/Bike';
import BikeStation from 'domain/app/BikeStation';
import BikesResult from 'domain/app/BikesResult';
import { GeolocationService } from 'services';
import LatLng from 'domain/app/LatLng';
import ProviderDetails from 'domain/app/ProviderDetails';
import RestClient from '../services/RestClient';
import RideDetails from 'domain/app/RideDetails';
import SelectedBikeDetails from 'domain/app/SelectedBikeDetails';
import SelectedBikeRes from 'domain/app/SelectedBikeRes';
import SelectedBikeResult from 'domain/app/SelectedBikesResult';
import Units from '../services/Units';
import { Analytics } from '../lib';
import { GetBikesResponse } from 'domain/APIresponses/GetBikesResponse';
import { Images } from '../themes';
import { PersistDataStore } from './RootStore';
import { distanceBetweenCoords, bikeProviderInfo } from '../services/Utils';

export enum BikeStates {
    disabled = 'disabled',
    on = 'on',
    off = 'off',
}

export default class BikeStore extends BaseStore implements PersistDataStore {
    hydrateStore = (hydrate: Function) => hydrate('bikeState', this);

    @observable
    bikesAvailableInRegion: boolean = false;

    @observable
    scootersAvailableInRegion: boolean = false;

    @observable
    bikesLoading: boolean = false;

    @observable
    userLocation?: LatLng;

    @observable
    currentUserLocation?: LatLng;

    @persist
    @observable
    bikeState: BikeStates = BikeStates.off;

    @persist
    @observable
    scooterState: BikeStates = BikeStates.off;

    @persist
    @observable
    prevBikeState: BikeStates = BikeStates.off;

    @observable
    bikeStationsMapCenter: Array<Bike> = [];

    @observable
    bikeStationsPickup: Array<Bike> = [];

    @observable
    bikeStationsPickupAll: Array<Bike> = [];

    @observable
    bikeStationsDestination: Array<Bike> = [];

    @observable
    bikeStationsDestinationAll: Array<Bike> = [];

    @observable
    availableProviders: Map<string, ProviderDetails> = new Map();

    @observable
    selectedBikeRes: SelectedBikeRes | null = null;

    @observable
    selectedBikeOnMap: Bike | null = null;

    @observable
    selectedBikeDetails: SelectedBikeDetails | null = null;

    @action
    selectBikeOnMap = station => {
        const {
            stores: {
                taxiStore: { taxiState },
            },
        } = this.rootStore;
        Analytics.trackBikeMarkerClick({ bike: station, taxiState });
        if (taxiState === 'taxis') {
            return;
        }
        const distance = distanceBetweenCoords({
            startCoord: station.location,
            endCoord: this.currentUserLocation ? this.currentUserLocation : this.userLocation,
            unit: 'MILES',
        });
        const time = Units.getTimeWalkingDistanceInMinutes(Units.getMetersFromMiles(distance));
        this.selectedBikeOnMap = {
            ...station,
            distance,
            time,
        };
    };

    @action
    clearBikeOnMap = () => {
        const {
            stores: {
                taxiStore: { centerMap },
            },
        } = this.rootStore;
        this.selectedBikeOnMap = null;
    };

    @computed
    get selectedBike() {
        if (this.bikeState === BikeStates.on) {
            return this.selectedBikeRes;
        }
        return null;
    }

    @action
    selectBike = (provider: string, details: RideDetails) => {
        this.selectedBikeRes = { provider, details };
        this.getProvidersDetails(provider, details);
    };

    @action
    clearBikeSelection = () => {
        this.selectedBikeRes = null;
        this.selectedBikeDetails = null;
    };

    @action
    clearBikesPickupDestination = () => {
        this.bikeStationsPickup = [];

        this.bikeStationsPickupAll = [];

        this.bikeStationsDestination = [];

        this.bikeStationsDestinationAll = [];

        this.availableProviders = new Map();
    };

    @action
    confirmBike = () => {
        const {
            routeDetails: {
                biking: { route: [{ latitude: orgLat, longitude: orgLon } = {}] = [] } = {},
                walkFromStation: {
                    route: [{ latitude: destLat, longitude: destLon } = {}] = [],
                } = {},
            },
        } = this.selectedBikeDetails;
        Linking.openURL(
            `https://www.google.com/maps/dir/?api=1&origin=${orgLat},${orgLon}&destination=${destLat},${destLon}&travelmode=bicycling`
        )
            .then(res => {
                const {
                    provider,
                    details: { destination, pickup },
                } = this.selectedBike;
                Analytics.trackBikeClickGoogleMapsDeepLink(provider, pickup, destination);
            })
            .catch(err => {});
    };

    @computed
    get bikeTypes() {
        const providers: Array<any> = [];
        this.availableProviders.forEach((details, providerKey) => {
            providers.push({ details, provider: { company: providerKey }, bike: true });
        });
        if (this.bikeState !== BikeStates.on && this.scooterState !== BikeStates.on) {
            return [];
        }
        if (this.bikeState !== BikeStates.on && this.scooterState === BikeStates.on) {
            return providers.filter(provider => provider.details.type === 'Scooter');
        }
        if (this.bikeState === BikeStates.on && this.scooterState !== BikeStates.on) {
            return providers.filter(provider => provider.details.type !== 'Scooter');
        }
        return providers;
    }

    @computed
    get bikeStations() {
        const {
            taxiStore: { taxiState },
        } = this.rootStore.stores;
        let result: any[] = [];
        const distinctResult: any[] = [];
        const map = new Map();
        if (taxiState === 'start' || taxiState === 'pickup' || taxiState === 'destination') {
            result = [...this.bikeStationsMapCenter];
        } else if (taxiState === 'taxis') {
            result = [...this.bikeStationsDestination, ...this.bikeStationsPickup];
        }
        result.forEach(item => {
            if (!map.has(JSON.stringify(item.location))) {
                map.set(item.location, true);
                distinctResult.push(item);
            }
        });
        return distinctResult;
    }

    @action
    setUserLocation = (location: any) => {
        this.userLocation = location;
    };

    @action
    setCurrentUserLocation = (location: any) => {
        this.currentUserLocation = location;
    };

    @action
    toggleBikesEnabled = () => {
        const {
            bikeStore,
            taxiStore: { taxiState },
            addressStore: { pickupLocation, destinationLocation },
        } = this.rootStore.stores;
        if (this.bikeState === BikeStates.on) {
            this.bikeState = BikeStates.off;
            this.clearBikeSelection();
        } else {
            this.bikeState = BikeStates.on;
            Analytics.trackToggleBikesClick();
            if (taxiState === 'start' || taxiState === 'pickup' || taxiState === 'destination') {
                if (this.userLocation) {
                    this.rootStore.stores.mapStore.updateRegion({
                        ...this.userLocation,
                        longitudeDelta: 0.02,
                        latitudeDelta: 0.02,
                    });
                    this.getBikesForLocation();
                }
            } else if (taxiState === 'taxis') {
                this.getBikesForPickupDestiantion({
                    pickup: pickupLocation,
                    destination: destinationLocation,
                });
            }
        }
    };

    @action
    toggleScootersEnabled = () => {
        const {
            taxiStore: { taxiState },
            addressStore: { pickupLocation, destinationLocation },
        } = this.rootStore.stores;
        if (this.scooterState === BikeStates.on) {
            this.scooterState = BikeStates.off;
            this.clearBikeSelection();
        } else {
            this.scooterState = BikeStates.on;
            if (taxiState === 'start' || taxiState === 'pickup' || taxiState === 'destination') {
                if (this.userLocation) {
                    this.rootStore.stores.mapStore.updateRegion({
                        ...this.userLocation,
                        longitudeDelta: 0.02,
                        latitudeDelta: 0.02,
                    });
                    this.getBikesForLocation();
                }
            } else if (taxiState === 'taxis') {
                this.getBikesForPickupDestiantion({
                    pickup: pickupLocation,
                    destination: destinationLocation,
                });
            }
        }
    };

    @action
    getBikesForLocation = ({ latitude, longitude, longitudeDelta }: any = {}) => {
        if (!latitude && !this.userLocation) {
            return;
        }

        const [lat, lng, delta] = [
            latitude || (this.userLocation && this.userLocation.latitude),
            longitude || (this.userLocation && this.userLocation.longitude),
            longitudeDelta || (this.userLocation && this.userLocation.longitudeDelta),
        ];

        if (delta > 0.5 && this.bikeState !== BikeStates.disabled) {
            this.prevBikeState = this.bikeState;
            this.bikeState = BikeStates.disabled;
        } else if (delta <= 0.5 && this.bikeState === BikeStates.disabled) {
            this.bikeState = this.prevBikeState;
        }

        this.bikesLoading = true;
        RestClient.getBikes(lat, lng, 1.2, true)
            .then(result => {
                this.bikesLoading = false;
                const { data } = result;

                this.bikesAvailableInRegion =
                    data.filter(item => item.type !== 'Scooter').length > 0;

                this.scootersAvailableInRegion =
                    data.filter(item => item.type === 'Scooter').length > 0;

                const filteredData =
                    this.scooterState === BikeStates.on
                        ? data
                        : data.filter(item => item.type !== 'Scooter');

                const providersPickup = new Set(
                    filteredData.map((pickupObject: any) => pickupObject.provider)
                );
                this.bikeStationsMapCenter = this.findNearest(
                    filteredData,
                    10,
                    {
                        latitude: lat,
                        longitude: lng,
                    },
                    [...providersPickup]
                );
            })
            .catch(err => {
                this.bikesLoading = false;
            });
    };

    @action
    getBikesForPickupDestiantion = ({ pickup, destination }: any) => {
        this.bikesLoading = true;

        const { latitude: pickupLatitude, longitude: pickupLongitude } = pickup;
        const { latitude: destinationLatitude, longitude: destinationLongitude } = destination;
        const distance = distanceBetweenCoords({
            startCoord: pickup,
            endCoord: destination,
            unit: 'MILES',
        });

        let radius = 1.2;
        if (distance < radius) {
            radius = distance / 2;
        }

        Promise.all([
            RestClient.getBikes(pickupLatitude, pickupLongitude, radius),
            RestClient.getBikes(destinationLatitude, destinationLongitude, radius),
            RestClient.getBikeZones(pickupLatitude, pickupLongitude),
            RestClient.getBikeZones(destinationLatitude, destinationLongitude),
        ])
            .then(result => {
                this.bikesLoading = false;
                const [
                    { data: pickupData },
                    { data: destinationData },
                    { data: pickupZones },
                    { data: destinationZones },
                ] = result;

                this.bikesAvailableInRegion =
                    pickupData.filter(item => item.type !== 'Scooter').length > 0 ||
                    destinationData.length > 0;
                this.scootersAvailableInRegion =
                    pickupData.filter(item => item.type === 'Scooter').length > 0;

                const providersPickup = new Set<string>(
                    pickupData.map((pickupObject: any) => pickupObject.provider)
                );
                const providersDestination = new Set<string>(
                    destinationData.map((destinationObject: any) => destinationObject.provider)
                );

                const destinationNearBikesResult = this.findNearest(
                    destinationData.filter(
                        (bike: BikeStation) =>
                            bikeProviderInfo(bike.provider).type === 'station' &&
                            bike.num_docks_available > 0
                    ),
                    10,
                    {
                        latitude: destinationLatitude,
                        longitude: destinationLongitude,
                    },
                    [...providersDestination]
                );

                const pickupNearBikesResult = this.findNearest(
                    pickupData
                        .filter((bike: GetBikesResponse) =>
                            bikeProviderInfo(bike.provider).type === 'station'
                                ? destinationNearBikesResult.some(
                                      (destinationBike: BikeStation) =>
                                          destinationBike.provider === bike.provider
                                  )
                                : true
                        )
                        .filter((bike: GetBikesResponse) =>
                            this.scooterState === BikeStates.on ? true : bike.type !== 'Scooter'
                        ),
                    10,
                    {
                        latitude: pickupLatitude,
                        longitude: pickupLongitude,
                    },
                    [...providersPickup]
                );

                this.setBikesResult({
                    pickupAll: pickupData,
                    destinationAll: destinationData,
                    pickupNear: pickupNearBikesResult,
                    destinationNear: destinationNearBikesResult,
                    pickup: { latitude: pickupLatitude, longitude: pickupLongitude },
                    destination: {
                        latitude: destinationLatitude,
                        longitude: destinationLongitude,
                    },
                    pickupZones,
                    destinationZones,
                });
            })
            .catch(err => {
                this.bikesLoading = false;
            });
    };

    @action
    setBikesResult = ({
        pickupNear,
        pickupAll,
        destinationNear,
        destinationAll,
        pickup,
        destination,
        pickupZones,
        destinationZones,
    }: BikesResult) => {
        this.bikeStationsDestination = destinationNear;
        this.bikeStationsDestinationAll = destinationAll;
        this.bikeStationsPickup = pickupNear;
        this.bikeStationsPickupAll = pickupAll;
        this.pickupZones = pickupZones;
        this.destinationZones = destinationZones;

        // const providersPickup = new Set(
        //     pickupAll.map((pickupObject: any) => pickupObject.provider)
        // );
        interface ProviderType {
            provider: string;
            type: string;
        }
        const providersPickup = pickupAll.reduce(
            (prev: Array<ProviderType>, curr: GetBikesResponse) => {
                if (!prev.some(item => curr.provider === item.provider)) {
                    prev.push({
                        provider: curr.provider,
                        type: curr.type,
                    });
                }
                return prev;
            },
            []
        );

        const providersDestination = new Set(
            destinationAll.map((destiantionObject: any) => destiantionObject.provider)
        );

        const bikeRideProviders = [...providersPickup].filter(
            providerPickup =>
                providersDestination.has(providerPickup.provider) ||
                bikeProviderInfo(providerPickup.provider).type === 'single' ||
                providerPickup.type === 'Scooter'
        );

        const providersMap = bikeRideProviders.reduce(
            (map: Map<string, ProviderDetails>, currentProvider: ProviderType) => {
                let pickupStations;
                let destinationStations;
                if (bikeProviderInfo(currentProvider.provider).type === 'station') {
                    pickupStations = this.findNearest(
                        pickupAll.filter(station => station.provider === currentProvider.provider),
                        10,
                        pickup,
                        [currentProvider.provider]
                    );
                    destinationStations = this.findNearest(
                        destinationAll.filter(
                            station => station.provider === currentProvider.provider
                        ),
                        10,
                        destination,
                        [currentProvider.provider]
                    );
                    if (pickupStations.length > 0 && destinationStations.length > 0) {
                        map.set(currentProvider.provider, {
                            pickupStations,
                            destinationStations,
                            distanceWalking: 'To estimate',
                            distanceBiking: 'To estimate',
                            pickup,
                            destination,
                            price: '0.99',
                            type: currentProvider.type,
                        });
                    }
                } else if (bikeProviderInfo(currentProvider.provider).type === 'single') {
                    pickupStations = this.findNearest(
                        pickupAll.filter(station => station.provider === currentProvider.provider),
                        10,
                        pickup,
                        [currentProvider.provider]
                    );

                    if (destinationZones[currentProvider.provider]) {
                        destinationStations = [{ location: { ...destination } }];
                    } else if (
                        pickupZones[currentProvider.provider] &&
                        pickupZones[currentProvider.provider].geometry &&
                        pickupZones[currentProvider.provider].geometry.coordinates
                    ) {
                        destinationStations = this.findNearest(
                            pickupZones[currentProvider.provider].geometry.coordinates[0].map(
                                ([longitude, latitude]) => ({
                                    location: { latitude, longitude },
                                })
                            ),
                            10,
                            destination,
                            [currentProvider.provider]
                        );
                    }
                    if (
                        destinationStations &&
                        destinationStations[0] &&
                        distanceBetweenCoords({
                            startCoord: destinationStations[0].location,
                            endCoord: destination,
                            unit: 'MILES',
                        }) < 0.5
                    ) {
                        map.set(currentProvider.provider, {
                            pickupStations,
                            destinationStations,
                            distanceWalking: 'To estimate',
                            distanceBiking: 'To estimate',
                            pickup,
                            destination,
                            price: '0.99',
                            type: currentProvider.type,
                        });
                    }
                }
                return map;
            },
            new Map()
        );

        this.availableProviders = providersMap;
        providersMap.forEach((details: ProviderDetails, providerKey: string) => {
            this.getProvidersDetailsEstimate(providerKey, details);
        });
    };

    getProvidersDetailsEstimate = (provider: string, details: ProviderDetails) => {
        const {
            pickup,
            pickupStations: [{ location: startLocation }],
            destination,
            destinationStations: [{ location: endLocation }],
        } = details;

        Promise.all([
            GeolocationService.getEstimatedDirections({
                pickup,
                destination: startLocation,
                travelMode: 'pedestrian',
            }),
            GeolocationService.getEstimatedDirections({
                pickup: startLocation,
                destination: endLocation,
                travelMode: 'bicycle',
            }),
            GeolocationService.getEstimatedDirections({
                pickup: endLocation,
                destination,
                travelMode: 'pedestrian',
            }),
        ])
            .then(result => {
                this.setProviderDetails(provider, result);
            })
            .catch(err => {
                this.setProviderDetails(provider, null);
            });
    };

    getProvidersDetails = (provider: string, details: ProviderDetails) => {
        const {
            pickup,
            pickupStations: [{ location: startLocation }],
            destination,
            destinationStations: [{ location: endLocation }],
        } = details;

        Promise.all([
            GeolocationService.getDirectionFormatted(pickup, startLocation, 'pedestrian'),
            GeolocationService.getDirectionFormatted(startLocation, endLocation, 'bicycle'),
            GeolocationService.getDirectionFormatted(endLocation, destination, 'pedestrian'),
        ])
            .then(result => {
                this.setSelectedBikeRes(result);
            })
            .catch(err => {
                // this.setProviderDetails(provider, null);
            });
    };

    @action
    setSelectedBikeRes = (result: Array<SelectedBikeResult>) => {
        const [
            {
                lengthInMeters: walkToStationLength,
                points: routeWalkingToStation,
                travelTimeInSeconds: timeInSecondsWalkToStation,
            },
            {
                lengthInMeters: bikingLength,
                points: routeBiking,
                travelTimeInSeconds: timeInSecondsBiking,
            },
            {
                lengthInMeters: walkFromStationLength,
                points: routeWalkingFromStation,
                travelTimeInSeconds: timeInSecondsWalkFromStation,
            },
        ] = result;
        const totalTime =
            timeInSecondsBiking + timeInSecondsWalkFromStation + timeInSecondsWalkToStation;
        const pickupTime = timeInSecondsWalkToStation;
        this.selectedBikeDetails = {
            ...this.selectedBike,
            totalTime,
            pickupTime,
            routeDetails: {
                walkToStation: {
                    distance: Units.getMilesFromMeters(walkToStationLength),
                    time: (timeInSecondsWalkToStation / 60).toFixed(),
                    route: routeWalkingToStation,
                },
                biking: {
                    distance: Units.getMilesFromMeters(bikingLength),
                    time: (timeInSecondsBiking / 60).toFixed(),
                    route: routeBiking,
                },
                walkFromStation: {
                    distance: Units.getMilesFromMeters(walkFromStationLength),
                    time: (timeInSecondsWalkFromStation / 60).toFixed(),
                    route: routeWalkingFromStation,
                },
            },
        };
    };

    @action
    setProviderDetails = (provider: string, result: Array<SelectedBikeResult> | null) => {
        const newProviders = new Map(this.availableProviders);
        if (result === null) {
            newProviders.delete(provider);
            this.availableProviders = newProviders;
            return;
        }
        const [
            {
                lengthInMeters: walkToStationLength,
                points: routeWalkingToStation,
                travelTimeInSeconds: timeInSecondsWalkToStation,
            },
            {
                lengthInMeters: bikingLength,
                points: routeBiking,
                travelTimeInSeconds: timeInSecondsBiking,
            },
            {
                lengthInMeters: walkFromStationLength,
                points: routeWalkingFromStation,
                travelTimeInSeconds: timeInSecondsWalkFromStation,
            },
        ] = result;
        const totalTime =
            timeInSecondsBiking + timeInSecondsWalkFromStation + timeInSecondsWalkToStation;
        const pickupTime = timeInSecondsWalkToStation;

        newProviders.set(provider, {
            ...this.availableProviders.get(provider),
            totalTime,
            pickupTime,
            routeDetails: {
                walkToStation: {
                    distance: Units.getMilesFromMeters(walkToStationLength),
                    time: (timeInSecondsWalkToStation / 60).toFixed(),
                    route: routeWalkingToStation,
                },
                biking: {
                    distance: Units.getMilesFromMeters(bikingLength),
                    time: (timeInSecondsBiking / 60).toFixed(),
                    route: routeBiking,
                },
                walkFromStation: {
                    distance: Units.getMilesFromMeters(walkFromStationLength),
                    time: (timeInSecondsWalkFromStation / 60).toFixed(),
                    route: routeWalkingFromStation,
                },
            },
        });

        this.availableProviders = newProviders;
    };

    findNearest = (
        tab: Array<BikeStation>,
        count: number,
        myLocation: any,
        providers: Array<string>
    ) => {
        const finalRes: Array<Array<BikeStation>> = [];
        providers.forEach(provider => {
            const res = tab
                .filter((bikeStation: BikeStation) => bikeStation.provider === provider)
                .reduce((prev: any, curr: any) => {
                    const distance = distanceBetweenCoords({
                        startCoord: curr.location,
                        endCoord: myLocation,
                        unit: 'MILES',
                    });

                    if (prev.length < count && distance < 2) {
                        prev.push({
                            distance,
                            obj: curr,
                        });
                    } else if (prev && prev[count - 1] && prev[count - 1].distance > distance) {
                        prev.pop();
                        prev.push({
                            distance,
                            obj: curr,
                        });
                    }
                    prev.sort((a: any, b: any) => a.distance - b.distance);
                    return prev;
                }, []);
            finalRes.push(...res);
        });
        return finalRes.reduce((prev: any, curr: any) => {
            prev.push(curr.obj);
            return prev;
        }, []);
    };

    @computed
    get bikesActive(): boolean {
        return this.bikeState === BikeStates.on && this.bikesAvailableInRegion;
    }

    @computed
    get scootersActive(): boolean {
        return this.scooterState === BikeStates.on && this.scootersAvailableInRegion;
    }

    @computed
    get shouldShowBikesAndBikesButton(): boolean {
        const {
            stores: { taxiStore },
        } = this.rootStore;
        return this.bikesAvailableInRegion && taxiStore.taxiState !== 'confirm';
    }

    @computed
    get bikeIconForState() {
        if (this.bikeState === BikeStates.disabled) {
            return Images.bikes.icon.disabled;
        }

        if (this.bikeState === BikeStates.on) {
            return Images.bikes.icon.active;
        }

        if (this.bikeState === BikeStates.off) {
            return Images.bikes.icon.inactive;
        }

        return null;
    }

    @computed
    get showBikeRouteMarkers(): boolean {
        return (
            !!this.selectedBike.walkToStation &&
            !!this.selectedBike.walkToStation.route &&
            !!this.selectedBike.walkToStation.route[0] &&
            !!this.selectedBike.walkToStation.route[0].latitude
        );
    }
}

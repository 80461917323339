import React from 'react';
import moment from 'moment';
import { View } from 'react-native';
import { observer, inject } from 'mobx-react';

import BikeStore from '../../../../stores/BikeStore';
import RouteRow from './RouteRow';
import { Images } from '../../../../themes';
import { Stores } from '../../../../stores/RootStore';
import { Localizable } from '../../../../lib';
import styles from './styles';

interface Props {
    bikeStore: BikeStore;
}

const RouteView = ({ bikeStore: { selectedBikeDetails, selectedBike }}: Props) => {
    const {
        details: {
            pickupStations: [{ location: { name: startStationName } = {} } = {}] = [],
            destinationStations: [{ location: { name: endStationName } = {} } = {}] = [],
        } = {},
    } = selectedBike;

    const {
        routeDetails: {
            walkToStation: { distance: toStationDistance, time: toStationTime } = {},
            biking: { distance: bikeDistance, time: bikeTime } = {},
            walkFromStation: { distance: fromStationDistance, time: fromStationTime } = {},
        } = {},
    } = selectedBikeDetails || {};

    return (
        <View style={styles.routeViewContainer}>
            <RouteRow
                icon={Images.bikes.route.location}
                text={Localizable.t('taxis.bikes.routeView.startLocation')}
                time={`${moment().format('h:mm a')}`}
                primary
            />
            <RouteRow icon={Images.bikes.route.dots} />
            <RouteRow
                icon={Images.bikes.route.walking}
                // text={`Walk ${toStationTime} min (${toStationDistance} mi) to ${startStationName}`}
                text={`${Localizable.t('taxis.bikes.routeView.walk')} ${
                    toStationTime > 0 ? `${toStationTime} min (${toStationDistance} mi)` : ``
                } ${
                    startStationName
                        ? `${Localizable.t('taxis.bikes.routeView.to')} ${startStationName}`
                        : ''
                }`}
            />
            <RouteRow icon={Images.bikes.route.dots} />
            <RouteRow
                primary
                icon={Images.bikes.route.biking}
                text={`${Localizable.t('taxis.bikes.routeView.arriveAt')} ${
                    startStationName
                        ? `${startStationName}`
                        : Localizable.t('taxis.bikes.routeView.bikeLocation')
                }`}
                time={`${moment()
                    .add(toStationTime, 'minutes')
                    .format('h:mm a')}`}
            />
            <RouteRow
                icon={Images.bikes.route.line}
                text={`${Localizable.t('taxis.bikes.routeView.biking')} ${
                    bikeTime > 0 ? `${bikeTime} min (${bikeDistance} mi)` : ``
                } ${
                    endStationName
                        ? `${Localizable.t('taxis.bikes.routeView.to')} ${endStationName}`
                        : ''
                }`}
            />
            {endStationName && [
                <RouteRow
                    icon={Images.bikes.route.pointEnd}
                    primary
                    text={`${Localizable.t('taxis.bikes.routeView.arriveAt')} ${endStationName}`}
                    time={`${moment()
                        .add(toStationTime, 'minutes')
                        .add(bikeTime, 'minutes')
                        .format('h:mm a')}`}
                />,
                <RouteRow icon={Images.bikes.route.dots} />,
                <RouteRow
                    icon={Images.bikes.route.walking}
                    text={`${Localizable.t('taxis.bikes.routeView.walk')} ${
                        fromStationTime > 0
                            ? `${fromStationTime} min (${fromStationDistance} mi)`
                            : ``
                    } to ${endStationName}`}
                />,
                <RouteRow icon={Images.bikes.route.dots} />,
            ]}
            <RouteRow
                icon={Images.bikes.route.pin}
                primary
                text={Localizable.t('taxis.bikes.routeView.destination')}
                time={`${moment()
                    .add(toStationTime, 'minutes')
                    .add(bikeTime, 'minutes')
                    .add(fromStationTime, 'minutes')
                    .format('h:mm a')}`}
            />
        </View>
    );
};

export default inject(({ bikeStore }: Stores) => ({ bikeStore }))(observer(RouteView));

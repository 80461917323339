import { StyleSheet } from 'react-native';
import { Fonts, Colors, Metrics } from 'themes';

export default StyleSheet.create({
    taxisEmptyRefreshContainer: {
        flex: 1,
        alignSelf: 'stretch',
        alignItems: 'center',
        justifyContent: 'center',
    },
    textView: {
        marginHorizontal: 20,
        marginBottom: 20,
        alignItems: 'center',
        justifyContent: 'center',
    },
    text: {
        fontFamily: Fonts.type.base,
        color: Colors.darkGreyTextColor,
        fontSize: Metrics.normalize(18),
        fontWeight: '300',
        textAlign: 'center',
    },
    innerText: {
        textDecorationLine: 'underline',
        textDecorationStyle: 'solid',
    },
    button: {
        alignSelf: 'stretch',
        marginVertical: 16,
        marginHorizontal: 50,
    },
});

import { StyleSheet } from 'react-native';
import { Metrics, Fonts } from 'themes';

export default StyleSheet.create({
    surgeView: {
        paddingHorizontal: 8,
        paddingVertical: 3,
        borderRadius: 10,
        alignItems: 'center',
        justifyContent: 'center',
    },
    surgeInnerView: {
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
    },
    surgeText: {
        fontWeight: '500',
        fontSize: Metrics.normalize(11),
        fontFamily: Fonts.type.base,
    },
    surgeIcon: {
        width: 11,
        height: 11,
        marginRight: 4,
    },
});

/* eslint-disable camelcase */
import axios from 'axios';

import ArroCredentials from 'domain/services/Arro/ArroCredentials';
import proxy from '../Proxy';

const instance = axios.create({
    baseURL: '',
    timeout: 40000,
});

class ArroClient {
    constructor() {
        instance.interceptors.request.use(request => {
            console.log('[ArroClient] Starting Request:', { ...request });
            return proxy(request);
        });
        instance.interceptors.response.use(
            response => {
                console.log('[ArroClient] Response:', { ...response });
                return response;
            },
            error => {
                console.log('[ArroClient] Response error:', { ...error });
                return Promise.reject(error);
            }
        );
    }

    getHeaders = () => ({
        'X-Client-Name': 'Arro',
        'X-Client-Version': '6.2.13',
        'X-Client-Platform': 'Android',
        'X-Client-Locale': 'en-US',
        'X-Client-Timezone': 'GMT',
        'X-Client-Device': 'Google Android SDK built for x86',
        'X-Client-OS_Version': '9',
    });

    getToken = ({ username, password }: ArroCredentials) => {
        return instance.post(
            `https://auth.goarro.com/token?grant_type=password&client_id=U5x_IQl4SzKvCVayOcsdqQ&client_secret=CcoBgaSxR3Cz6g4on88Atg&username=${encodeURIComponent(
                username
            )}&password=${password}`,
            {},
            {
                headers: this.getHeaders(),
            }
        );
    };
}

export default ArroClient;

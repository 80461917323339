import hydrate from 'modules/hydrate/hydrate';
import { IHydrateResult } from 'mobx-persist';
import { observable } from 'mobx';

import {
    AdditionalInfoStore,
    AddressStore,
    AppStore,
    AuthStore,
    BikeStore,
    ConnectionStore,
    FiltersStore,
    LayoutStore,
    LinkingStore,
    LocationsStore,
    MapStore,
    ModalStore,
    NavigationStore,
    OnboardingStore,
    OptionsStore,
    PaymentMethodsStore,
    PredictionStore,
    RateAppStore,
    ReferralStore,
    SavingsStore,
    ServicesStore,
    SplashScreenStore,
    SuggestionsStore,
    TaxiStore,
    TooltipStore,
} from '.';

export interface Stores {
    additionalInfoStore: AdditionalInfoStore;
    addressStore: AddressStore;
    appStore: AppStore;
    authStore: AuthStore;
    bikeStore: BikeStore;
    connectionStore: ConnectionStore;
    filtersStore: FiltersStore;
    layoutStore: LayoutStore;
    linkingStore: LinkingStore;
    locationsStore: LocationsStore;
    mapStore: MapStore;
    modalStore: ModalStore;
    navigationStore: NavigationStore;
    onboardingStore: OnboardingStore;
    optionsStore: OptionsStore;
    paymentMethodsStore: PaymentMethodsStore;
    predictionStore: PredictionStore;
    rateAppStore: RateAppStore;
    referralStore: ReferralStore;
    savingsStore: SavingsStore;
    servicesStore: ServicesStore;
    splashScreenStore: SplashScreenStore;
    suggestionsStore: SuggestionsStore;
    taxiStore: TaxiStore;
    tooltipStore: TooltipStore;
}

export interface PersistDataStore {
    hydrateStore: (
        hydrate: <T extends Object>(key: string, store: T, initialState?: any) => IHydrateResult<T>
    ) => Promise<Object>;
}

function implementsPersistDataStore(store: any): store is PersistDataStore {
    return 'hydrateStore' in store;
}

function persistDataStores(stores: Stores) {
    return Object.values(stores).filter(implementsPersistDataStore);
}
export interface AppLifecycle {
    onAppStarted?: () => void;
    onAppFinished?: () => void;
    onAppWillGoToBackground?: () => void;
    onAppWilGoToForeground?: () => void;
}

function implementsAppLifecycle(store: any, func: string): store is AppLifecycle {
    return func in store;
}

function appLifecycleStores(stores: Stores, func: string) {
    return Object.values(stores).filter(store => implementsAppLifecycle(store, func));
}

export interface UserLifecycle {
    onUserReady: () => void;
}

function implementsUserLifecycle(Store: any): Store is UserLifecycle {
    return 'onUserReady' in Store;
}
function userLifecycleStores(stores: Stores) {
    return Object.values(stores).filter(implementsUserLifecycle);
}

const stores = (store: RootStore): Stores => ({
    additionalInfoStore: new AdditionalInfoStore(store),
    addressStore: new AddressStore(store),
    appStore: new AppStore(store),
    authStore: new AuthStore(store),
    bikeStore: new BikeStore(store),
    connectionStore: new ConnectionStore(store),
    filtersStore: new FiltersStore(store),
    layoutStore: new LayoutStore(store),
    linkingStore: new LinkingStore(store),
    locationsStore: new LocationsStore(store),
    mapStore: new MapStore(store),
    modalStore: new ModalStore(store),
    navigationStore: new NavigationStore(store),
    onboardingStore: new OnboardingStore(store),
    optionsStore: new OptionsStore(store),
    paymentMethodsStore: new PaymentMethodsStore(store),
    predictionStore: new PredictionStore(store),
    rateAppStore: new RateAppStore(store),
    referralStore: new ReferralStore(store),
    savingsStore: new SavingsStore(store),
    servicesStore: new ServicesStore(store),
    splashScreenStore: new SplashScreenStore(store),
    suggestionsStore: new SuggestionsStore(store),
    taxiStore: new TaxiStore(store),
    tooltipStore: new TooltipStore(store),
});

export default class RootStore {
    stores: Stores = stores(this);

    @observable
    appStarted: boolean = false;

    hydrateStores = () => {
        return Promise.all(
            persistDataStores(this.stores).map(store => store.hydrateStore(hydrate))
        );
    };

    fetchUserData = () => {
        userLifecycleStores(this.stores).forEach(store => {
            store.onUserReady();
        });
        return true;
    };

    onAppStarted = () => {
        appLifecycleStores(this.stores, 'onAppStarted').forEach(store => {
            store.onAppStarted();
        });
        this.appStarted = true;
    };

    onAppFinished = () => {
        appLifecycleStores(this.stores, 'onAppFinished').forEach(store => {
            store.onAppFinished();
        });
    };

    onAppWillGoToBackground = () => {
        appLifecycleStores(this.stores, 'onAppWillGoToBackground').forEach(store => {
            store.onAppWillGoToBackground();
        });
    };

    onAppWilGoToForeground = () => {
        appLifecycleStores(this.stores, 'onAppWilGoToForeground').forEach(store => {
            store.onAppWilGoToForeground();
        });
    };
}

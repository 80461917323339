import { StyleSheet } from 'react-native';
import { Fonts, Metrics, Colors } from '../../../themes';

export default StyleSheet.create({
    container: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
    },
    backgroundContainer: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'black',
        opacity: 0.3,
    },
    filterContainer: {
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        paddingTop: 16,
        paddingBottom: 20,
        position: 'absolute',
        backgroundColor: 'white',
        left: 0,
        right: 0,
        bottom: 0,
    },
    innerContainer: {
        backgroundColor: Colors.white,
        padding: 16,
        width: Metrics.screenWidth - 80,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 6,
    },
    topView: {
        flexDirection: 'row',
        marginBottom: 10,
        alignItems: 'center',
        justifyContent: 'center',
    },
    titleText: {
        fontFamily: Fonts.type.base,
        fontWeight: '800',
        fontSize: Metrics.normalize(20),
        color: Colors.black,
        marginBottom: 4,
    },
    headerText: {
        fontFamily: Fonts.type.base,
        fontWeight: '700',
        fontSize: Metrics.normalize(14),
        color: Colors.darkTextColor,
        marginVertical: 14,
        opacity: 0.9,
        marginHorizontal: 24,
    },
    descriptionText: {
        fontFamily: Fonts.type.base,
        fontWeight: '400',
        fontSize: Metrics.normalize(13),
        color: Colors.darkGreyTextColor,
        marginVertical: 16,
    },
    closeView: {
        backgroundColor: Colors.closeButtonBackgroundColor,
        width: 30,
        height: 30,
        borderRadius: 15,
        alignItems: 'center',
        justifyContent: 'center',
    },
    closeButton: {
        position: 'absolute',
        top: 2,
        right: 16,
    },
    closeText: {
        fontFamily: Fonts.type.base,
        fontWeight: '600',
        fontSize: Metrics.normalize(17),
        color: Colors.obiTeal,
    },
    separator: {
        backgroundColor: Colors.grayTextColor,
        height: 1,
        opacity: 0.1,
    },
    thumbStyle: {
        width: 26,
        height: 26,
        borderRadius: 26 / 2,
        borderWidth: 3,
        borderColor: 'white',
        backgroundColor: Colors.obiTeal,
        shadowColor: 'black',
        shadowOffset: { width: 0, height: 2 },
        shadowRadius: 2,
        shadowOpacity: 0.35,
    },
    sliderValues: {
        flexDirection: 'row',
    },
    sliderText: {
        fontFamily: Fonts.type.base,
        fontWeight: '700',
        fontSize: Metrics.normalize(18),
        color: Colors.darkTextColor,
    },
    ridesGrid: {
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    ridesView: {
        height: 40,
        paddingHorizontal: 14,
        marginBottom: 12,
        alignItems: 'center',
        justifyContent: 'center',
        borderWidth: 1,
        borderRadius: 6,
        marginRight: 5,
    },
    ridesCheckbox: {
        width: 22,
        height: 22,
        marginRight: 10,
    },
    ridesText: {
        fontFamily: Fonts.type.base,
        fontWeight: '500',
        fontSize: Metrics.normalize(13),
    },
    passengerContainer: {
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    addButton: {
        width: 40,
        height: 40,
    },
    addButtonContainer: {
        borderRadius: 20,
        height: 40,
        width: 40,
        borderWidth: 1,
        borderColor: '#DDDFE4',
        alignItems: 'center',
        justifyContent: 'center',
    },
    addButtonText: {
        color: Colors.black,
        fontFamily: Fonts.type.base,
        fontWeight: '500',
        fontSize: Metrics.normalize(16),
    },
    passengerText: {
        color: Colors.black,
        fontFamily: Fonts.type.base,
        fontWeight: '600',
        fontSize: Metrics.normalize(25),
        marginHorizontal: 16,
        width: 20,
    },
    scrollView: {
        paddingHorizontal: 24,
    },
    applyButton: {
        marginHorizontal: 24,
    },
    bottomSeparator: {
        flex: 1,
        marginBottom: 20,
    },
});

/* eslint-disable camelcase */
import { observable, action, flow } from 'mobx';
import ServicesStore from '../ServicesStore';
import ArroClient from 'services/providers/ArroClient';
import ArroResponse from 'domain/services/Arro/ArroResponse';

export default class ArroServiceStore {
    servicesRootStore: ServicesStore;

    arroClient: ArroClient;

    constructor(servicesStore: ServicesStore) {
        this.servicesRootStore = servicesStore;
        this.arroClient = new ArroClient();
    }

    @observable
    loading: boolean = false;

    @observable
    email: string = '';

    @observable
    password = '';

    @observable
    errorMessage = '';

    @observable
    tokens?: ArroResponse;

    @action
    clearStore = () => {
        const { setRefreshing } = this.servicesRootStore;

        this.email = '';
        this.password = '';
        this.errorMessage = '';
        this.tokens = undefined;
        setRefreshing(false);
    };

    @action
    clearError = () => {
        this.errorMessage = '';
    };

    @action
    setEmail = (text: string) => {
        this.email = text;
        this.clearError();
    };

    @action
    setPassword = (text: string) => {
        this.password = text;
        this.clearError();
    };

    onCredentialsSubmit = flow(function*(this: ArroServiceStore) {
        const { handleArroLogin, setRefreshing } = this.servicesRootStore;
        setRefreshing(true);
        yield this.getAuthToken();
        if (this.tokens) {
            yield handleArroLogin({
                access_token: this.tokens.access_token,
                refresh_token: this.tokens.refresh_token,
                expires_in: this.tokens.expires_in,
                token_type: this.tokens.token_type,
            });
        }
        setRefreshing(false);
    }).bind(this);

    getAuthToken = flow(function*(this: ArroServiceStore) {
        try {
            const {
                data: { access_token, refresh_token, expires_in, token_type },
            } = yield this.arroClient.getToken({
                username: this.email,
                password: this.password,
            });
            this.tokens = {
                access_token,
                refresh_token,
                expires_in,
                token_type,
            };
        } catch (err) {
            console.log('Arro login error\n', { ...err });
            const {
                response: {
                    data: { responseMessage },
                },
            } = err;
            if (responseMessage) {
                this.errorMessage = responseMessage;
            } else {
                this.errorMessage = 'Unable to login';
            }
        }
    }).bind(this);
}

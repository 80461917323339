import { Linking, Platform } from 'react-native';
import { observable, action } from 'mobx';
import { persist } from 'mobx-persist';

import Mixpanel from 'modules/mixpanel/mixpanel';
import Intercom from 'modules/intercom/intercom';
import firebase from 'modules/firebase/firebase';

import { BaseStore } from '.';
import { RestClient } from 'services';
import { Analytics } from '../lib';
import { AppLifecycle, PersistDataStore } from './RootStore';
import { getAllUrlParams } from '../services/Utils';

export default class LinkingStore extends BaseStore implements AppLifecycle, PersistDataStore {
    hydrateStore = (hydrate: Function) => {
        return hydrate('linkingStore', this);
    };

    onAppStarted = () => {
        this.initLinking();
    };

    notificationDisplayedListener: any;

    notificationOpenedListener: any;

    notificationListener: any;

    @observable
    pushNeededPopupShown: boolean = false;

    @persist
    @observable
    shouldShowRegisterForPush = false;

    initLinking = () => {
        // firebase
        //     .notifications()
        //     .getInitialNotification()
        //     .then(notificationOpen => {
        //         if (notificationOpen) {
        //             this.handleNotification(notificationOpen, true);
        //         }
        //     })
        //     .catch(err => {});
        // this.notificationDisplayedListener = firebase
        //     .notifications()
        //     .onNotificationDisplayed(notification => {
        //         // Process your notification as required
        //         // ANDROID: Remote notifications do not contain the channel ID. You will have to specify this manually if you'd like to re-display the notification.
        //     });
        // this.notificationOpenedListener = firebase
        //     .notifications()
        //     .onNotificationOpened(({ notification }) => {
        //         this.handleNotification(notification, true);
        //     });
        // this.notificationListener = firebase.notifications().onNotification(notification => {
        //     this.handleNotification(notification, true);
        //     firebase
        //         .notifications()
        //         .displayNotification(notification)
        //         .then(res => {})
        //         .catch(err => {});
        // });
    };

    setShouldShowRegisterForPush = () => {
        this.shouldShowRegisterForPush = true;
    };

    registerForPush = async () => {
        try {
            await firebase.messaging().requestPermission();
            this.sendPushToken();
            Analytics.trackNotificationEnable();
        } catch (error) {
            Analytics.trackNotificationDisable();
        }
    };

    sendPushToken = () => {
        firebase
            .messaging()
            .getToken()
            .then(token => {
                if (Platform.OS === 'android') {
                    Mixpanel.setPushRegistrationId(token);
                }
                Intercom.sendTokenToIntercom(token)
                    .then(result => {})
                    .catch(err => {});
                RestClient.postDeviceToken(token)
                    .then(() => {})
                    .catch(() => {});
            });
    };

    @action
    showPushNeededPopup = () => {
        this.pushNeededPopupShown = true;
    };

    @action
    hidePushNeededPopup = () => {
        this.pushNeededPopupShown = false;
    };

    handleNotification = async (notification: any, isInitMessage: boolean) => {
        const { title, body: message, data: { uri } = { uri } } = notification;

        try {
            const canOpen = Platform.select({
                web: true,
                default: await Linking.canOpenURL(uri),
            });
            if (canOpen) {
                Linking.openURL(uri);
            }
        } catch (error) {
            console.log('handleNotification error', error);
        }

        if (!notification) {
            return;
        }

        if (!uri) {
            return;
        }
        const temp = getAllUrlParams(uri);
        const { link, label, savings } = temp;

        if (savings) {
            // this.rootStore.stores.appStore.showProfile(true);
            const {
                stores: {
                    navigationStore: { navigate },
                },
            } = this.rootStore;
            navigate('ProfileRouter');
            Analytics.trackScreenView('04-01_Profile');
        }

        if (!(title && message && link && label)) {
            return;
        }

        const capLabel = `${label.slice(0, 1).toUpperCase()}${label.slice(1)}`;

        const { mapNotificationToModal, showModal } = this.rootStore.stores.modalStore;
        mapNotificationToModal({
            title,
            message,
            label: capLabel,
            action: async () => {
                try {
                    const canOpen = Platform.select({
                        web: true,
                        default: await Linking.canOpenURL(link),
                    });
                    if (!canOpen) {
                        return;
                    }
                    Linking.openURL(link);
                } catch (error) {
                    console.log('mapNotificationToModal error', error);
                }
            },
        });
        showModal();
    };

    clearStore = () => {
        this.notificationDisplayedListener();
        this.notificationListener();
        this.notificationOpenedListener();
    };
}

import React, { Component } from 'react';
import Animatable from 'modules/animatable/animatable';
import getEnv from 'modules/config/config';
import { ImageBackground, View, Image, Text } from 'react-native';
import { observer, inject } from 'mobx-react';

import LandingStyles from './styles/LandingStyles';
import SavingsCounter from '../../components/animations/SavingsCounter';
import SplashScreenStore from '../../stores/SplashScreenStore';
import { Images } from '../../themes';
import { Localizable, Analytics } from '../../lib';
import { Stores } from '../../stores/RootStore';

interface Props {
    splashScreenStore: SplashScreenStore;
}

const renderContent = () => {
    if (getEnv('FAREWELL') && getEnv('FAREWELL') === '1') {
        return (
            <View>
                <Image
                    style={LandingStyles.farewellIcon}
                    source={Images.landing.farewellIcon}
                    resizeMode="contain"
                />
                <Image
                    style={LandingStyles.poweredByImage}
                    source={Images.landing.poweredBy}
                    resizeMode="contain"
                />
            </View>
        );
    }

    return (
        <View style={LandingStyles.contentContainer}>
            <Image style={LandingStyles.icon} source={Images.landing.logo} resizeMode="contain" />
            {/* <Text style={LandingStyles.contentText}>{Localizable.t('bellhopUsersSaved')}</Text>
            <ImageBackground
                source={Images.landing.savingsBackground}
                style={LandingStyles.contentBackgroundImage}
            >
                <SavingsCounter />
            </ImageBackground> */}
        </View>
    );
};

export default inject(({ splashScreenStore }: Stores) => ({ splashScreenStore }))(
    observer(({ splashScreenStore, splashScreenStore: { setReactSplashReady } }: Props) => {
        return splashScreenStore.reactSplashShown ? (
            <Animatable.View
                onLayout={setReactSplashReady}
                ref={splashScreenStore.reactSplashRef}
                style={LandingStyles.container}
                useNativeDriver
            >
                {renderContent()}
            </Animatable.View>
        ) : null;
    })
);

import axios from 'axios';
import Config from 'react-native-config';

const instance = axios.create({
    baseURL: `https://firebasedynamiclinks.googleapis.com/v1`,
    timeout: 40000,
});

class FirebaseClient {
    constructor() {
        instance.interceptors.request.use(request => {
            console.log(`[FirebaseClient] Starting Request:`, { ...request });
            return request;
        });
        instance.interceptors.response.use(
            response => {
                console.log(`[FirebaseClient] Response :  `, { ...response });
                return response;
            },
            error => {
                console.log(`[FirebaseClient] Response error:`, { ...error });
                return Promise.reject(error);
            }
        );
    }

    getHeaders = () => ({
        'Content-Type': 'application/json',
    });

    getShortDynamicLink = (longDynamicLink: string) => {
        return instance.post(
            `shortLinks?key=${Config.FIREBASE_API_KEY}`,
            { longDynamicLink },
            { headers: this.getHeaders() }
        );
    };
}

export default new FirebaseClient();

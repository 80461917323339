import React from 'react';
import { View, Image, Text, TouchableOpacity, ScrollView } from 'react-native';
import { inject } from 'mobx-react';

import Button from '../../components/Button';
import TooltipStore from '../../stores/TooltipStore';
import FeatureInformationPopupStyles from './styles/FeatureInformationStyles';
import { Images } from '../../themes';
import { Localizable } from '../../lib';
import { Stores } from 'stores/RootStore';

export default inject(({ tooltipStore }: Stores) => ({ tooltipStore }))(
    ({ tooltipStore }: { tooltipStore: TooltipStore }) => {
        return (
            <View style={FeatureInformationPopupStyles.container}>
                <ScrollView>
                    <TouchableOpacity
                        onPress={() => {
                            tooltipStore.showInfoScreen(false);
                        }}
                    >
                        <Image
                            source={Images.tooltip.closeScreen}
                            style={FeatureInformationPopupStyles.closeScreenButtonImage}
                        />
                    </TouchableOpacity>
                    <Image
                        source={Images.tooltip.dollar}
                        style={FeatureInformationPopupStyles.dollarImage}
                    />
                    <Text style={FeatureInformationPopupStyles.titleText}>
                        {Localizable.t('taxis.featureInformationPopup.faresShown')}
                    </Text>
                    <Text style={FeatureInformationPopupStyles.contentText}>
                        {Localizable.t('taxis.featureInformationPopup.faresDescription')}
                    </Text>
                    <View style={FeatureInformationPopupStyles.infoContainer}>
                        <View style={FeatureInformationPopupStyles.infoInnerContainer}>
                            <Image source={Images.tooltip.circle} />
                            <View style={FeatureInformationPopupStyles.textView}>
                                <Text style={FeatureInformationPopupStyles.smallTitleText}>
                                    {Localizable.t('taxis.featureInformationPopup.useFareRefresh')}
                                </Text>
                                <Text style={FeatureInformationPopupStyles.infoContentText}>
                                    {Localizable.t(
                                        'taxis.featureInformationPopup.fareRefreshDescription'
                                    )}
                                </Text>
                            </View>
                        </View>
                        <View style={FeatureInformationPopupStyles.separator} />
                        <View style={FeatureInformationPopupStyles.infoInnerContainer}>
                            <Image source={Images.tooltip.circle} />
                            <View style={FeatureInformationPopupStyles.textView}>
                                <Text style={FeatureInformationPopupStyles.smallTitleText}>
                                    {Localizable.t(
                                        'taxis.featureInformationPopup.checkBeforeBooking'
                                    )}
                                </Text>
                                <Text style={FeatureInformationPopupStyles.infoContentText}>
                                    {Localizable.t(
                                        'taxis.featureInformationPopup.checkBeforeBookingDescription'
                                    )}
                                </Text>
                            </View>
                        </View>
                    </View>
                    <Button
                        handlePress={() => {
                            tooltipStore.showInfoScreen(false);
                        }}
                        style={FeatureInformationPopupStyles.showInfoScreenButton}
                        title={Localizable.t('taxis.featureInformationPopup.understand')}
                        active
                    />
                </ScrollView>
            </View>
        );
    }
);

const DeviceInfo = {
    getApiLevel: () => '',
    getBuildNumber: () => '',
    getDeviceId: () => '',
    getDeviceName: () => '',
    getDeviceType: () => '',
    getManufacturer: () => '',
    getSerialNumber: () => '',
    getSystemVersion: () => '',
    getUniqueId: () => '',
    getVersion: () => '',
};
export default DeviceInfo;

import React, { Component } from 'react';
import { View, Animated, Easing, Image, Platform } from 'react-native';
import { observer } from 'mobx-react';

import CircularProgress from 'modules/circularProgress/circularProgress';
import FareRefreshStyles from './styles/FareRefreshStyles';
import { Colors, Images } from '../../../../themes';

const AnimatedCircularProgress = Animated.createAnimatedComponent(CircularProgress);

const colors = [Colors.darkerGrayBackgroundColor, Colors.lightGrayColor];

interface Props {}

interface State {
    fillAnimation: Animated.Value;
    counter: number;
    tintColor: string;
    backgroundColor: string;
}

@observer
export default class FaresRefreshing extends Component<Props, State> {
    state = {
        fillAnimation: new Animated.Value(0),
        counter: 0,
        tintColor: colors[0],
        backgroundColor: colors[1],
    };

    componentDidMount() {
        this.animate(1200);
    }

    animate = (duration: number) => {
        const { fillAnimation } = this.state;
        Animated.timing(fillAnimation, {
            toValue: 100,
            easing: Easing.linear,
            duration,
        }).start(this.reset);
    };

    reset = () => {
        this.setState(
            ({ counter }) => ({
                fillAnimation: new Animated.Value(0),
                counter: counter + 1,
                tintColor: colors[(counter + 1) % 2],
                backgroundColor: colors[counter % 2],
            }),
            () => this.animate(1200)
        );
    };

    renderAnimatedImage = (fill: Animated.Value) => (
        <Animated.View
            style={{
                transform: [
                    {
                        rotate: fill.interpolate({
                            inputRange: [0, 100],
                            outputRange: ['0 deg', '360 deg'],
                        }),
                    },
                ],
            }}
        >
            <Image
                source={Images.icons.refresh}
                style={FareRefreshStyles.refreshImage}
                resizeMode="contain"
            />
        </Animated.View>
    );

    render() {
        const { fillAnimation, tintColor, backgroundColor } = this.state;

        return (
            <View style={FareRefreshStyles.faresRefresh}>
                <AnimatedCircularProgress
                    size={42}
                    width={3}
                    prefill={0}
                    fill={fillAnimation}
                    tintColor={tintColor}
                    backgroundColor={backgroundColor}
                    useNativeDriver
                >
                    {Platform.OS === 'web'
                        ? this.renderAnimatedImage(fillAnimation)
                        : () => this.renderAnimatedImage(fillAnimation)}
                </AnimatedCircularProgress>
            </View>
        );
    }
}

import React, { Component } from 'react';
import { TouchableOpacity, View, Image, Text, Platform } from 'react-native';
import Voca from 'voca';
import AddressPickerStyles from './styles/AddressPickerStyles';
import { Images } from '../../themes';

interface Props {
    item: any;
    index: number;
    suggestionSelected: Function;
}

class SuggestionsListItem extends Component<Props> {
    onPress = () => {
        const { item, suggestionSelected } = this.props;
        suggestionSelected(item);
    };

    render() {
        const { item, index } = this.props;

        let title;
        let subTitle = '';
        let icon;

        if (item.id && item.name && item.location.name) {
            title = Voca.capitalize(item.name);
            subTitle = item.location.name;
            icon = Images.mapLanding.home;
        } else {
            title = item.structured_formatting.main_text;
            subTitle = item.structured_formatting.secondary_text;
            icon = Images.mapLanding.recent;
        }

        return (
            <TouchableOpacity
                style={AddressPickerStyles.suggestionItemContainer}
                testID={`item_suggestion_${index}`}
                {...(Platform.OS === 'web'
                    ? {
                          // When scrolling the document body, the touchables might be triggered
                          // see  https://github.com/necolas/react-native-web/issues/1219
                          onClick: this.onPress,
                      }
                    : {
                          onPress: this.onPress,
                      })}
            >
                <View
                    key={`${title} iconView ${index}`}
                    style={AddressPickerStyles.suggestionsItemLeftContainer}
                >
                    <View style={AddressPickerStyles.suggestionIconView}>
                        <Image
                            key={`${title} image ${index}`}
                            source={icon}
                            style={AddressPickerStyles.suggestionIcon}
                        />
                    </View>
                </View>
                <View style={AddressPickerStyles.textView} key={`${title} innerView ${index}`}>
                    <Text
                        key={`${title} title ${index}`}
                        allowFontScaling={false}
                        style={AddressPickerStyles.suggestionTitle}
                    >
                        {title}
                    </Text>
                    <Text
                        key={`${title} subtitle ${index}`}
                        allowFontScaling={false}
                        style={AddressPickerStyles.suggestionDescription}
                    >
                        {subTitle}
                    </Text>
                </View>
            </TouchableOpacity>
        );
    }
}

export default SuggestionsListItem;

import React, { Component } from 'react';
import Animatable from 'modules/animatable/animatable';
import { ImageBackground, View } from 'react-native';
import { observer, inject } from 'mobx-react';

import { TaxiStore } from 'stores';

import LandingStyles from './styles/LandingStyles';
import MapLoadingPopup from '../../components/taxis/ridePopup/MapLoadingPopup';
import MapStore from '../../stores/MapStore';
import SplashScreenStore from '../../stores/SplashScreenStore';
import { Images } from '../../themes';
import { Stores } from '../../stores/RootStore';

interface Props {
    splashScreenStore: SplashScreenStore;
    mapStore: MapStore;
    taxiStore: TaxiStore;
}

@inject(({ splashScreenStore, mapStore, taxiStore }: Stores) => ({
    splashScreenStore,
    mapStore,
    taxiStore,
}))
@observer
export default class MapLoader extends Component<Props> {
    render() {
        const {
            splashScreenStore: { setMapLoaderRef, mapLoaderShown },
            mapStore: {
                padding: { bottom, top },
            },
        } = this.props;
        if (!mapLoaderShown) {
            return null;
        }
        return (
            <Animatable.View
                style={LandingStyles.mapLoadingContainer}
                useNativeDriver
                ref={setMapLoaderRef}
            >
                <ImageBackground source={Images.mapBackground} style={LandingStyles.imageContainer}>
                    <View style={[LandingStyles.mapLoadingPopupImageContainer, { top, bottom }]}>
                        <MapLoadingPopup />
                    </View>
                </ImageBackground>
            </Animatable.View>
        );
    }
}

import { StyleSheet } from 'react-native';
import { Fonts, Metrics, Colors } from 'themes';

export default StyleSheet.create({
    container: {
        paddingVertical: 15,
        paddingHorizontal: 5,
        alignItems: 'center',
        marginRight: 20,
    },
    image: {
        height: 58,
        width: 58,
        marginBottom: 5,
    },
    text: {
        fontFamily: Fonts.type.base,
        fontWeight: '300',
        fontSize: Metrics.normalize(12),
        color: Colors.black,
    },
    isEnabledIcon: {
        position: 'absolute',
        top: 14,
        left: 0,
        width: 16,
        height: 16,
    },
});

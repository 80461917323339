import { StyleSheet } from 'react-native';
import { Colors, Metrics, Fonts } from 'themes';

export default StyleSheet.create({
    contentContainer: {
        backgroundColor: 'white',
        borderRadius: Metrics.normalize(16),
        paddingTop: Metrics.normalize(16),
        overflow: 'hidden',
    },
    topContainer: {
        flexDirection: 'row',
        height: 70,
        alignItems: 'center',
        marginBottom: 10,
        paddingHorizontal: 16,
        backgroundColor: 'white',
    },
    bottomContainer: {
        paddingTop: 10,
        backgroundColor: Colors.grayBackgroundColor,
        alignItems: 'center',
    },
    rowContainer: {
        alignSelf: 'stretch',
        backgroundColor: 'white',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        paddingVertical: 10,
    },
    itemContainer: {
        flexDirection: 'row',
        padding: 10,
        paddingVertical: Metrics.normalize(15),
        alignItems: 'center',
    },
    separator: {
        width: 2,
        backgroundColor: Colors.grayBackgroundColor,
        marginVertical: 4,
        alignSelf: 'stretch',
    },
    providerIcon: {
        width: Metrics.normalize(50),
        height: Metrics.normalize(50),
        borderRadius: Metrics.normalize(25),
        marginRight: Metrics.normalize(8),
    },
    detailsView: {
        marginHorizontal: 10,
    },
    detailText: {
        fontFamily: Fonts.type.base,
        fontSize: Metrics.normalize(15),
        fontWeight: '400',
        color: Colors.detailsGrayColor,
        marginBottom: 4,
    },
    nameText: {
        fontFamily: Fonts.type.base,
        fontSize: Metrics.normalize(18),
        fontWeight: '500',
        color: Colors.black,
        marginBottom: 4,
    },
    distanceIcon: {
        width: Metrics.normalize(15),
        height: Metrics.normalize(15),
        marginRight: 4,
    },
    providerText: {
        fontFamily: Fonts.type.base,
        fontSize: Metrics.normalize(18),
        fontWeight: '400',
        color: Colors.black,
    },
    destinationText: {
        fontFamily: Fonts.type.base,
        fontSize: Metrics.normalize(16),
        fontWeight: '400',
        color: Colors.black,
        marginBottom: 4,
    },
    distanceText: {
        fontFamily: Fonts.type.base,
        fontSize: Metrics.normalize(13),
        fontWeight: '400',
        color: Colors.grayTextColor,
    },
    savedValueText: {
        fontFamily: Fonts.type.base,
        fontSize: Metrics.normalize(18),
        fontWeight: '600',
        color: Colors.black,
    },
    savedText: {
        fontFamily: Fonts.type.base,
        fontSize: Metrics.normalize(14),
        fontWeight: '400',
        color: Colors.priceColor,
    },
    distanceContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    savedContainer: {
        justifyContent: 'center',
        alignItems: 'flex-end',
        flex: 1,
    },
    totalSavingsLabel: {
        marginTop: 22,
        fontFamily: Fonts.type.base,
        fontWeight: '400',
        fontSize: Metrics.normalize(16),
        color: Colors.darkTextColor,
    },
    totalSavingsValue: {
        marginTop: 6,
        fontFamily: Fonts.type.base,
        fontWeight: '600',
        fontSize: Metrics.normalize(24),
        color: Colors.priceColor,
        marginBottom: 22,
    },
    flexOne: {
        flex: 1,
    },
    hideModalButton: {
        height: 20,
        width: 20,
        alignSelf: 'flex-start',
    },
    closeImage: {
        height: 20,
        width: 20,
    },
    tripSavingsMap: { alignSelf: 'stretch', height: 120, backgroundColor: 'grey' },
});

export async function register() {
    if ('serviceWorker' in navigator) {
        try {
            const { scope } = await navigator.serviceWorker.register(
                `${process.env.PUBLIC_URL}/service-worker.js`
            );
            console.log('ServiceWorker registration successful, scope is:', scope);
        } catch (error) {
            console.log('ServiceWorker registration failed, error:', error);
        }
    } else {
        console.log('ServiceWorkers are not supported in this browser.');
    }
}

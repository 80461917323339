import React from 'react';
import { View, Image, Text, TouchableOpacity } from 'react-native';
import { observer, inject } from 'mobx-react';

import ProviderConnectingItemStyles from './styles/ProviderConnectingItemStyles';
import ServicesStore, { ServiceType } from '../../../stores/ServicesStore';
import { Images } from '../../../themes';
import { Stores } from '../../../stores/RootStore';

interface InjectedProps {
    servicesStore: ServicesStore;
}

interface OwnProps {
    item: ServiceType;
}

type Props = InjectedProps & OwnProps;

const ProviderConnectingItem = ({ item, servicesStore }: Props) => {
    const {
        connected,
        backgroundColor: backgroundColorNotLinked,
        backgroundColorLinked,
        logo,
        logoLinked,
        name,
    } = item;
    return (
        <TouchableOpacity
            style={ProviderConnectingItemStyles.container}
            onPress={() => servicesStore.handleOptionsServiceItemClick(item)}
        >
            <View style={ProviderConnectingItemStyles.iconView}>
                <View
                    style={[
                        ProviderConnectingItemStyles.iconInnerView,
                        {
                            backgroundColor: connected
                                ? backgroundColorLinked
                                : backgroundColorNotLinked,
                        },
                    ]}
                >
                    <Image
                        resizeMode="contain"
                        style={ProviderConnectingItemStyles.icon}
                        source={connected ? logoLinked : logo}
                    />
                </View>
            </View>
            <View style={ProviderConnectingItemStyles.titleView}>
                <Text style={ProviderConnectingItemStyles.title}>{name}</Text>
                <View style={ProviderConnectingItemStyles.availabilityTextView}>
                    <Text style={ProviderConnectingItemStyles.availabilityText}>Available</Text>
                    <Image
                        style={ProviderConnectingItemStyles.availabilityIcon}
                        source={Images.services.available}
                    />
                </View>
            </View>
            <View style={ProviderConnectingItemStyles.buttonView}>
                <View
                    style={[
                        ProviderConnectingItemStyles.linkButton,
                        { backgroundColor: connected ? '#5bac44' : '#eef8ed' },
                    ]}
                >
                    <Text
                        style={[
                            ProviderConnectingItemStyles.linkButtonText,
                            { color: connected ? '#eef8ed' : '#408dfa' },
                        ]}
                    >
                        {connected ? 'LINKED' : 'LINK'}
                    </Text>
                </View>
            </View>
        </TouchableOpacity>
    );
};

export default inject(({ servicesStore }: Stores) => ({ servicesStore }))(
    observer(ProviderConnectingItem)
);

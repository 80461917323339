import React from 'react';
import { observer, inject } from 'mobx-react';

import FaresRefreshButton from './FaresRefreshButton';
import FaresRefreshing from './FaresRefreshing';
import TaxiStore from '../../../../stores/TaxiStore';
import { Stores } from '../../../../stores/RootStore';

interface Props {
    taxiStore: TaxiStore;
}

export default inject(({ taxiStore }: Stores) => ({
    taxiStore,
}))(
    observer(({ taxiStore }: Props) => {
        const {
            taxiResultsStore: { typesLoading, intervalLoading },
        } = taxiStore;
        const loading = typesLoading && !intervalLoading;

        if (loading) {
            return <FaresRefreshing />;
        }
        return <FaresRefreshButton taxiStore={taxiStore} />;
    })
);

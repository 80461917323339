import { StyleSheet } from 'react-native';
import { Colors, Fonts, Metrics } from 'themes';

export default StyleSheet.create({
    container: {
        flexDirection: 'row',
        alignItems: 'center',
        alignSelf: 'stretch',
        paddingHorizontal: 35,
        paddingVertical: 15,
    },
    text: {
        color: Colors.darkTextColor,
        fontFamily: Fonts.type.base,
        fontSize: Metrics.normalize(16),
    },
    image: {
        width: 40,
        height: 40,
    },
    innerContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'flex-start',
        alignSelf: 'stretch',
        paddingLeft: 15,
    },
    selectImage: {
        width: 10,
        height: 20,
    },
    itemSeparator: {
        height: 1,
        alignSelf: 'stretch',
        backgroundColor: Colors.grayBackgroundColor,
        marginLeft: 90,
        marginRight: 30,
    },
});

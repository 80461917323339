import { StyleSheet } from 'react-native';
import { Colors, Fonts, Metrics } from '../../../themes';

export default StyleSheet.create({
    container: {
        backgroundColor: 'white',
        borderRadius: 8,
        flexDirection: 'row',
        padding: 6,
        marginBottom: 10,
        // flex: 1,
        // alignSelf: 'stretch',
    },
    topContainer: {
        alignItems: 'center',
        justifyContent: 'center',
        // backgroundColor,
        padding: 10,
        borderRadius: 6,
    },
    topContainerImage: {
        width: 30,
        height: 30,
    },
    innerContainer: {
        marginLeft: 10,
        flex: 1,
    },
    innerContainerTopText: {
        marginTop: 4,
        fontWeight: '700',
        fontSize: Metrics.normalize(16),
        color: Colors.black,
        fontFamily: Fonts.type.base,
    },
    innerContainerBottomText: {
        marginTop: 6,
        fontWeight: '400',
        fontSize: Metrics.normalize(12),
        color: Colors.grayTextColor,
        fontFamily: Fonts.type.base,
    },
    bottomContainer: {
        alignItems: 'center',
        justifyContent: 'center',
        opacity: 0.5,
    },
    bottomContainerImage: { width: 16 },
});

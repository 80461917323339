import { StyleSheet } from 'react-native';
import { Fonts, Metrics, Colors } from '../../../themes';

export default StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: Colors.typesBackgroundColor,
    },
    rowView: {
        margin: 10,
        borderRadius: 8,
        backgroundColor: 'white',
        shadowColor: 'black',
        shadowRadius: 3,
        shadowOpacity: 0.1,
        shadowOffset: { width: 0, height: 2 },
        elevation: 2,
        padding: 20,
    },
    topView: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 20,
    },
    brand: {
        height: 30,
        marginRight: 20,
        maxWidth: Metrics.screenWidth / 3,
    },
    typeView: {
        borderRadius: 3,
        paddingVertical: 2,
        paddingHorizontal: 8,
        justifyContent: 'center',
        alignItems: 'center',
    },
    typeText: {
        fontFamily: Fonts.type.base,
        fontWeight: '500',
        fontSize: Metrics.normalize(11),
        color: 'white',
    },
    titleText: {
        fontFamily: Fonts.type.base,
        fontWeight: '400',
        fontSize: Metrics.normalize(9.5),
        color: Colors.grayTextColor,
        marginBottom: 2,
        letterSpacing: 1.5,
    },
    cardText: {
        fontFamily: Fonts.type.base,
        fontWeight: '400',
        fontSize: Metrics.normalize(26),
        color: Colors.darkTextColor,
        flex: 1,
    },
    moreText: {
        fontFamily: Fonts.type.base,
        fontWeight: '400',
        fontSize: Metrics.normalize(18),
        color: Colors.darkTextColor,
        flex: 1,
    },
    moreView: {
        marginTop: 10,
        flexDirection: 'row',
    },
    nameView: {
        flex: 2,
    },
    expirationView: {
        flex: 1,
    },
    addButton: {
        borderStyle: 'dashed',
        borderColor: Colors.lightGrayColor,
        borderRadius: 4,
        borderWidth: 1,
        alignItems: 'center',
        justifyContent: 'center',
        margin: 10,
        padding: 12,
    },
    addText: {
        fontFamily: Fonts.type.base,
        fontWeight: '500',
        fontSize: Metrics.normalize(16),
        color: Colors.grayTextColor,
    },
    moreImage: {
        width: 20,
        height: 10,
    },
    flexOne: { flex: 1 },
    deleteButton: { padding: 5 },
});

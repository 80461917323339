import React from 'react';
import { Text } from 'react-native';
import InfoLabelStyles from './InfoLabelStyles';

interface Props {
    info: string;
}

const InfoLabel = ({ info }: Props) => <Text style={InfoLabelStyles.label}>{info}</Text>;

export default InfoLabel;

import React, { Component, ReactElement, RefObject } from 'react';
import Interactable from 'modules/interactable/interactable';
import { StyleSheet, Animated, StyleProp, View, TouchableOpacity, Image } from 'react-native';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';

import FiltersStore from '../../stores/FiltersStore';
import LayoutStore from '../../stores/LayoutStore';
import TaxiStore from '../../stores/TaxiStore';
import { Images } from '../../themes';
import { Stores } from '../../stores/RootStore';
import { OptionsNavigationBar } from '..';

export type Snap = 'down' | 'up' | 'closed';

export interface YID {
    y: number;
    id: Snap;
}

interface InjectedProps {
    taxiStore: TaxiStore;
    layoutStore: LayoutStore;
    filtersStore: FiltersStore;
}

interface UserProps {
    boundaries?: any;
    children: ReactElement;
    currentSnap: Snap;
    deltaY: Animated.Value;
    initialPoint: number;
    setCurrentSnap?: (newCurrentSnap: Snap) => void;
    snapPoints: Array<YID>;
    containerStyle?: StyleProp<View>;
    handleBackClick?: () => void;
    onClose?: () => void;
    onDrag?: (event: any) => void;
    onSnap?: (event: any) => void;
    onSnapStart?: (event: any) => void;
    withBackgroundOpacity?: boolean;
    backgroundOpacityInterpolation?: {
        inputRange: Array<number>;
        outputRange: Array<number>;
    };
    openAnimated?: {
        delay?: number;
        toIndex: number;
    };
}

type Props = InjectedProps & UserProps;

@inject(({ filtersStore, taxiStore }: Stores) => ({
    filtersStore,
    taxiStore,
}))
@observer
export default class BottomDrawer extends Component<Props> {
    snapView: RefObject<any> = React.createRef();

    snapPoints: Array<YID>;

    deltaY: Animated.Value;

    initialPoint: number;

    @observable currentSnap: Snap;

    constructor(props: Props) {
        super(props);

        this.snapPoints = props.snapPoints;
        this.initialPoint = props.initialPoint;
        this.deltaY = new Animated.Value(this.initialPoint);

        const currSnap = props.snapPoints.find(point => point.y === props.initialPoint);
        this.currentSnap = currSnap ? currSnap.id : 'up';

        if (props.openAnimated) {
            const { toIndex, delay } = props.openAnimated;
            setTimeout(() => {
                if (this.snapView.current) {
                    this.snapView.current.snapTo({ index: toIndex });
                    this.currentSnap = this.snapPoints[toIndex].id;
                }
            }, delay || 0);
        }
    }

    // Fix for lib issue
    onDrag = (event: Interactable.IDragEvent) => {
        const { onDrag } = this.props;
        const {
            nativeEvent: { y, state },
        } = event;
        if (!!onDrag) {
            onDrag(event);
        }
        if (state && state === 'end') {
            const snapPoint = this.snapPoints.find(point => point.y === y);
            if (!!snapPoint) {
                this.updateSnap(snapPoint.id);
            } else {
                this.updateSnap(this.snapPoints[0].id);
            }
        }
    };

    updateSnap = (updatedSnap: Snap) => {
        const { setCurrentSnap } = this.props;
        if (setCurrentSnap) {
            setCurrentSnap(updatedSnap);
        }
    };

    onSnapStart = (event: Interactable.ISnapEvent) => {
        const { onSnapStart, onClose } = this.props;
        const {
            nativeEvent: { id },
        } = event;
        if (onSnapStart) {
            onSnapStart(event);
        }
        if (id === 'closed' && !!onClose) {
            setTimeout(() => {
                onClose();
            }, 100);
        }
    };

    setSnap = (index: Snap) => {
        if (this.snapView.current) {
            const point = this.snapPoints.find(snapPoint => snapPoint.id === index);
            if (point) {
                this.snapView.current.snapTo({ index: this.snapPoints.indexOf(point) });
                this.updateSnap(index);
            }
        }
    };

    snapTo = (index: number) => {
        this.snapView.current?.snapTo({ index });
    };

    render() {
        const {
            backgroundOpacityInterpolation,
            boundaries,
            children,
            containerStyle,
            deltaY,
            handleBackClick,
            initialPoint,
            onSnap,
            snapPoints,
            withBackgroundOpacity = false,
        } = this.props;
        return (
            <>
                {withBackgroundOpacity && (
                    <Animated.View
                        style={[
                            StyleSheet.absoluteFill,
                            containerStyle,
                            {
                                backgroundColor: '#000',
                                opacity: deltaY.interpolate({
                                    inputRange: (backgroundOpacityInterpolation &&
                                        backgroundOpacityInterpolation.inputRange) || [
                                        snapPoints[0].y,
                                        snapPoints[1].y,
                                    ],
                                    outputRange: (backgroundOpacityInterpolation &&
                                        backgroundOpacityInterpolation.outputRange) || [0.5, 0],
                                    extrapolateRight: 'clamp',
                                }),
                            },
                        ]}
                    />
                )}
                {!!handleBackClick && (
                    <OptionsNavigationBar
                        backImage={Images.icons.back}
                        handleBackClick={handleBackClick}
                    />
                )}
                <Interactable.View
                    animatedNativeDriver
                    animatedValueY={deltaY}
                    boundaries={boundaries}
                    initialPosition={{ y: initialPoint }}
                    onDrag={this.onDrag}
                    onSnap={onSnap}
                    onSnapStart={this.onSnapStart}
                    ref={this.snapView}
                    snapPoints={snapPoints}
                    verticalOnly
                >
                    {children}
                </Interactable.View>
            </>
        );
    }
}

/* eslint-disable camelcase */
import React, { Component } from 'react';
import { View, Text, TouchableOpacity, Image, ScrollView } from 'react-native';
import { observable, action, flow } from 'mobx';
import { observer } from 'mobx-react';
import moment from 'moment';
import Button from '../../Button';
import DriverAvatar from '../driverAvatar/DriverAvatar';
import NavigationBar from '../../navigationBars/NavigationBar';
import RestClient from '../../../services/RestClient';
import RouteView from './routeView/RouteView';
import RideFinishedViewStyles from './RideFinishedViewStyles';
import { Metrics, Colors, Images } from '../../../themes';
import Ride from 'domain/app/Ride';
import { Localizable } from '../../../lib';

interface Driver {
    name?: string;
    star_rating?: number;
    picture_url?: string;
}

class RideFinishedStore {
    @observable
    cost?: string;

    @observable
    destination?: string;

    @observable
    driver: Driver = {};

    @observable
    pickup?: string;

    @observable
    rate: number = 0;

    @observable
    ratingIsZero: boolean = false;

    @observable
    requestId?: string;

    @observable
    tip: number = 0;

    @observable
    date = moment();

    getFinishedRide = flow(function*(this: RideFinishedStore, requestId: string) {
        try {
            this.requestId = requestId;
            const {
                data: {
                    ask: { destination_address: destination, pickup_address: pickup, schedule },
                    fee,
                    offer: {
                        currency,
                        provider: { driver: { name, star_rating, picture_url } = {} },
                    },
                },
            } = yield RestClient.getRide(requestId);
            this.setRideDetails({
                destination,
                pickup,
                cost: `${currency}${fee}`,
                driver: { name, star_rating, picture_url },
                date: moment(schedule, "YYYY-MM-DD'T'HH:mm:ss.SSS'Z'"),
            });
        } catch (error) {
            console.log('getFinishedRide error:', error);
        }
    }).bind(this);

    @action
    setRideDetails = ({ driver, pickup, destination, cost, date }: Ride) => {
        this.driver = driver;
        this.pickup = pickup;
        this.destination = destination;
        this.cost = cost;
        this.date = date;
    };

    @action
    setRating = (rating: number) => {
        this.rate = rating;
        this.ratingIsZero = false;
    };

    @action
    setTip = (tip: number) => {
        this.tip = tip;
    };

    rateRide = (closeView: () => void) => {
        if (this.rate === 0) {
            if (this.tip !== 0) {
                this.ratingIsZero = true;
            } else {
                closeView();
            }
        } else {
            closeView();
            RestClient.rateRide({
                request_id: this.requestId,
                rating: this.rate,
                tip_amount: this.tip,
            })
                .then()
                .catch(err => {
                    console.log('Rate ride err', { ...err });
                });
        }
    };
}

interface Props {
    requestId: string;
    closeView: () => void;
}

@observer
export default class RideFinishedView extends Component<Props, {}> {
    rideFinishedStore = new RideFinishedStore();

    componentDidMount() {
        const { requestId } = this.props;
        const { getFinishedRide } = this.rideFinishedStore;
        getFinishedRide(requestId);
    }

    render() {
        const {
            pickup,
            destination,
            cost,
            tip: activeTip,
            rate: activeRate,
            setRating,
            setTip,
            rateRide,
            driver: { picture_url, name, star_rating },
            date,
            ratingIsZero,
        } = this.rideFinishedStore;

        const { closeView } = this.props;
        const diff = moment().diff(moment(date), 'hours');

        return (
            <View style={RideFinishedViewStyles.container}>
                <View style={RideFinishedViewStyles.navigationView}>
                    <NavigationBar
                        handleBackClick={closeView}
                        title={Localizable.t('taxis.rideFinishedView.arrived')}
                    />
                </View>
                <ScrollView
                    style={RideFinishedViewStyles.scrollView}
                    contentContainerStyle={RideFinishedViewStyles.scrollViewContentContainer}
                >
                    {!!picture_url && (
                        <DriverAvatar imageSource={{ uri: picture_url }} rating={star_rating} />
                    )}
                    {!!name && <Text style={RideFinishedViewStyles.driver}>{name}</Text>}
                    <View style={RideFinishedViewStyles.roundContainer}>
                        <RouteView pickup={pickup || ''} destination={destination || ''} />
                        <View style={RideFinishedViewStyles.separator} />
                        <Text style={RideFinishedViewStyles.costLabel}>
                            {Localizable.t('taxis.rideFinishedView.tripCost')}
                        </Text>
                        <Text style={RideFinishedViewStyles.costValue}>{cost || ''}</Text>
                    </View>
                    {diff < 24 ? (
                        [
                            <Text key="text" style={RideFinishedViewStyles.costLabel}>
                                {Localizable.t('taxis.rideFinishedView.addTip')}
                            </Text>,
                            <View key="list" style={RideFinishedViewStyles.chooseContainerTip}>
                                <TouchableOpacity
                                    onPress={() => {
                                        setTip(activeTip > 1 ? activeTip - 1 : 0);
                                    }}
                                >
                                    <View style={RideFinishedViewStyles.plusButtonView}>
                                        <Text style={RideFinishedViewStyles.plusButtonText}>-</Text>
                                    </View>
                                </TouchableOpacity>

                                <Text style={RideFinishedViewStyles.activeTipText}>
                                    {'$'}
                                    {activeTip}
                                </Text>
                                <TouchableOpacity
                                    onPress={() => {
                                        setTip(activeTip + 1);
                                    }}
                                >
                                    <View style={RideFinishedViewStyles.minusButtonView}>
                                        <Text style={RideFinishedViewStyles.minusButtonText}>
                                            +
                                        </Text>
                                    </View>
                                </TouchableOpacity>
                            </View>,
                            <Text key="rateTrip" style={RideFinishedViewStyles.costLabel}>
                                {Localizable.t('taxis.rideFinishedView.rateTrip')}
                            </Text>,
                            <View key="rateList" style={RideFinishedViewStyles.chooseContainer}>
                                {[1, 2, 3, 4, 5].map(rate => (
                                    <TouchableOpacity
                                        onPress={() => {
                                            setRating(rate);
                                        }}
                                        key={`${rate}`}
                                    >
                                        <Image
                                            style={RideFinishedViewStyles.starImage}
                                            resizeMode="cover"
                                            source={
                                                Images.driverRating[
                                                    rate <= activeRate ? 'starGold' : 'starGray'
                                                ]
                                            }
                                        />
                                    </TouchableOpacity>
                                ))}
                            </View>,
                        ]
                    ) : (
                        <Text style={RideFinishedViewStyles.costLabel}>
                            {Localizable.t('taxis.rideFinishedView.tooLateToRate')}
                        </Text>
                    )}
                    {ratingIsZero && (
                        <Text style={RideFinishedViewStyles.costLabel}>
                            {Localizable.t('taxis.rideFinishedView.rateTripToAddTip')}
                        </Text>
                    )}
                </ScrollView>
                <Button
                    title="Done"
                    active
                    style={RideFinishedViewStyles.doneButton}
                    handlePress={() => rateRide(closeView)}
                />
            </View>
        );
    }
}

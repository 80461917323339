import React from 'react';
import { Text, View } from 'react-native';

import NewProviderBadgeStyles from './styles/NewProviderBadgeStyles';

export default () => (
    <View style={NewProviderBadgeStyles.newProviderView}>
        <Text style={NewProviderBadgeStyles.newProviderText} numberOfLines={1}>
            NEW
        </Text>
    </View>
);

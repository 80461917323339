import React, { Component } from 'react';
import { View, Text, TextInput, Linking } from 'react-native';
import { observer, inject } from 'mobx-react';
import KeyboardAwareScrollView from 'modules/keyboardAwareScrollView/keyboardAwareScrollView';

import AdditionalInfoStyles from '../../styles/AdditionalInfoStyles';
import ServicesStore from '../../../../stores/ServicesStore';
import TitleNavigationBar from '../../../navigationBars/TitleNavigationBar';
import { Colors, Fonts } from '../../../../themes';
import { Localizable, Analytics } from '../../../../lib';
import { Stores } from '../../../../stores/RootStore';
import { NextButton, ErrorMessage, ClickableInfoLabel } from '../../providerConnectingComponents';
import { ClickableInfoChildProps } from '../../providerConnectingComponents/clickableInfoLabel/ClickableInfoLabel';

const clickableInfoLabelContent: Array<ClickableInfoChildProps> = [
    {
        text: Localizable.t('additionalInfo.uber.accept'),
        clickableText: Localizable.t('additionalInfo.uber.termsOfService'),
        clickableTextStyle: {
            fontWeight: '500',
            color: '#1C162E',
            fontFamily: Fonts.type.base,
        },
        handleClickableTextClick: () => Linking.openURL('https://www.uber.com/legal/terms'),
    },
    {
        text: 'and',
        clickableText: Localizable.t('additionalInfo.uber.privacyPolicy'),
        clickableTextStyle: {
            fontWeight: '500',
            color: '#1C162E',
            fontFamily: Fonts.type.base,
        },
        handleClickableTextClick: () => Linking.openURL('https://www.uber.com/legal/privacy/users'),
    },
];

interface Props {
    servicesStore: ServicesStore;
}

@inject(({ servicesStore }: Stores) => ({ servicesStore }))
@observer
export default class Credentials extends Component<Props> {
    lastNameInputRef: React.RefObject<TextInput> = React.createRef();

    passwordInputRef: React.RefObject<TextInput> = React.createRef();

    focusLastNameInput = () => {
        if (this.lastNameInputRef && this.lastNameInputRef.current) {
            this.lastNameInputRef.current.focus();
        }
    };

    focusPasswordInput = () => {
        if (this.passwordInputRef && this.passwordInputRef.current) {
            this.passwordInputRef.current.focus();
        }
    };

    componentDidMount() {
        Analytics.trackServiceLinking('uber', 'registration', 'credentials_screen');
    }

    render() {
        const {
            servicesStore: {
                hideProviderPopup,
                uberServiceStore: {
                    onUberCredentialsSubmit,
                    setUberFirstName,
                    setUberLastName,
                    setUberPassword,
                    errorMessage,
                    firstName,
                    lastName,
                    password,
                },
            },
        } = this.props;
        return (
            <View style={AdditionalInfoStyles.container}>
                <TitleNavigationBar title="Uber" handleBackClick={() => hideProviderPopup()} />

                <KeyboardAwareScrollView
                    contentContainerStyle={{ paddingHorizontal: 20 }}
                    keyboardShouldPersistTaps="handled"
                >
                    <Text style={AdditionalInfoStyles.title}>
                        {Localizable.t('additionalInfo.enterCredentials')}
                    </Text>

                    <Text style={AdditionalInfoStyles.description}>
                        {Localizable.t('additionalInfo.placeholderFirstname')}
                    </Text>
                    <View style={AdditionalInfoStyles.inputBorder}>
                        <TextInput
                            autoCapitalize="words"
                            autoCorrect={false}
                            autoFocus
                            keyboardType="default"
                            onChangeText={setUberFirstName}
                            onSubmitEditing={this.focusLastNameInput}
                            placeholder={Localizable.t('additionalInfo.placeholderFirstname')}
                            placeholderTextColor={Colors.lightGrayColor}
                            returnKeyType="next"
                            selectionColor={Colors.obiTeal}
                            style={[
                                AdditionalInfoStyles.textInput,
                                {
                                    fontWeight: firstName.length ? '300' : '100',
                                },
                            ]}
                            value={firstName}
                        />
                    </View>

                    <Text style={AdditionalInfoStyles.description}>
                        {Localizable.t('additionalInfo.placeholderLastname')}
                    </Text>
                    <View style={AdditionalInfoStyles.inputBorder}>
                        <TextInput
                            autoCapitalize="words"
                            autoCorrect={false}
                            keyboardType="default"
                            onChangeText={setUberLastName}
                            onSubmitEditing={this.focusPasswordInput}
                            placeholder={Localizable.t('additionalInfo.placeholderLastname')}
                            placeholderTextColor={Colors.lightGrayColor}
                            ref={this.lastNameInputRef}
                            returnKeyType="next"
                            selectionColor={Colors.obiTeal}
                            style={[
                                AdditionalInfoStyles.textInput,
                                {
                                    fontWeight: lastName.length ? '300' : '100',
                                },
                            ]}
                            value={lastName}
                        />
                    </View>

                    <Text style={AdditionalInfoStyles.description}>
                        {Localizable.t('additionalInfo.placeholderPassword')}
                    </Text>
                    <View style={AdditionalInfoStyles.inputBorder}>
                        <TextInput
                            autoCapitalize="none"
                            autoCorrect={false}
                            keyboardType="default"
                            onChangeText={setUberPassword}
                            placeholder={Localizable.t('additionalInfo.placeholderPassword')}
                            placeholderTextColor={Colors.lightGrayColor}
                            ref={this.passwordInputRef}
                            returnKeyType="done"
                            secureTextEntry
                            selectionColor={Colors.obiTeal}
                            style={[
                                AdditionalInfoStyles.textInput,
                                {
                                    fontWeight: password.length ? '300' : '100',
                                },
                            ]}
                            value={password}
                        />
                    </View>

                    <ClickableInfoLabel childrenProps={clickableInfoLabelContent} />
                    {errorMessage.length !== 0 && <ErrorMessage message={errorMessage} />}
                    <NextButton onPress={onUberCredentialsSubmit} />
                </KeyboardAwareScrollView>
            </View>
        );
    }
}

import React, { Component } from 'react';
import { LayoutAnimation, FlatList, View, Animated, Easing, Text } from 'react-native';
import { observer, inject } from 'mobx-react';
import { observable, action } from 'mobx';

import BikeRow from '../../row/BikeRow';
import SectionStyles from './styles/SectionStyles';
import InfoPopup from './InfoPopup';
import SectionHeader from './SectionHeader';
import TaxiRow from '../../row/TaxiRow';
import TaxiStore from 'stores/TaxiStore';
import { SectionData } from 'stores/dataStores/taxiResultsStore/TaxisComparationStore';
import { Stores } from 'stores/RootStore';

class SectionInfoStore {
    animatedValue = new Animated.Value(0);

    @observable
    popupOpened: boolean = false;

    @action
    toggleInfoPopup = () => {
        if (!this.popupOpened) {
            this.popupOpened = true;
            Animated.timing(this.animatedValue, {
                toValue: 100,
                easing: Easing.linear,
                duration: 200,
                // useNativeDriver: true,
            }).start();
        } else {
            this.closeInfoPopup();
        }
    };

    @action
    closeInfoPopup = () => {
        Animated.timing(this.animatedValue, {
            toValue: 0,
            easing: Easing.linear,
            duration: 200,
            // useNativeDriver: true,
        }).start(() => {
            this.popupOpened = false;
        });
    };
}

interface InjectedProps {
    taxiStore: TaxiStore;
}

interface OwnProps {
    item: SectionData;
}

type Props = InjectedProps & OwnProps;

@inject(({ taxiStore }: Stores) => ({ taxiStore }))
@observer
export default class Section extends Component<Props> {
    sectionInfoStore = new SectionInfoStore();

    renderSectionHeader = (section: SectionData) => {
        const {
            taxiStore: {
                taxiResultsStore: {
                    taxisComparationStore: { handleSectionHeaderClick },
                },
            },
        } = this.props;
        return (
            <SectionHeader
                section={section}
                onInfoPress={this.sectionInfoStore.toggleInfoPopup}
                onPress={() => {
                    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
                    this.sectionInfoStore.closeInfoPopup();
                    handleSectionHeaderClick(section);
                }}
            />
        );
    };

    renderSectionSeparator = () => <View style={SectionStyles.separator} />;

    renderRow = ({ item, index }: { item: any; index: number }) => {
        return !item ? null : item.bike ? (
            <BikeRow item={item} index={index} testID={`item_bike_${index}`} />
        ) : (
            <TaxiRow index={index} item={item} testID={`item_taxi_${index}`} grouped />
        );
    };

    render() {
        const { item } = this.props;
        return (
            <>
                {this.renderSectionHeader(item)}
                {this.sectionInfoStore.popupOpened && (
                    <InfoPopup
                        text={item.description}
                        animatedValue={this.sectionInfoStore.animatedValue}
                        onPress={this.sectionInfoStore.closeInfoPopup}
                    />
                )}
                {(item.data.length === 0 ||
                    (!this.sectionInfoStore.popupOpened && item.state === 'hidden')) && (
                    <View style={SectionStyles.empty} />
                )}
                <FlatList
                    contentContainerStyle={SectionStyles.sectionContainer}
                    renderItem={this.renderRow}
                    data={item.data}
                    ItemSeparatorComponent={this.renderSectionSeparator}
                />
            </>
        );
    }
}

import { StyleSheet, Platform } from 'react-native';
import { Fonts, Colors, Metrics } from 'themes';

export default StyleSheet.create({
    outerContainer: {
        flex: 1,
        backgroundColor: Colors.transparent,
        flexDirection: 'row',
        alignSelf: 'stretch',
    },
    drawerIndicatorView: {
        flex: 0.08,
        backgroundColor: Colors.transparent,
        alignSelf: 'stretch',
        alignItems: 'center',
        justifyContent: 'center',
    },
    drawerIndicator: {
        height: 40,
        width: 4,
        borderRadius: 3,
        backgroundColor: 'white',
    },
    container: {
        flex: 1,
        alignSelf: 'stretch',
        backgroundColor: 'white',
        paddingHorizontal: 20,
        paddingVertical: 30,
    },
    scrollView: {
        alignSelf: 'stretch',
        paddingHorizontal: 0,
        paddingVertical: 20,
    },
    avatarView: {
        // shadowColor: 'black',
        // shadowRadius: 4,
        // shadowOpacity: 0.4,
        // shadowOffset: { width: 0, height: 0 },
        // elevation: 2,
        width: 50,
        height: 50,
        borderRadius: 25,
        marginVertical: 10,
    },
    avatarImage: {
        width: 50,
        height: 50,
        borderRadius: 25,
    },
    nameText: {
        fontFamily: Fonts.type.base,
        fontWeight: '700',
        fontSize: Metrics.normalize(20.16),
        color: Colors.darkTextColor,
        marginTop: 20,
    },
    profileText: {
        fontFamily: Fonts.type.base,
        fontWeight: '300',
        fontSize: 14.5,
        color: Colors.grayTextColor,
        marginTop: 8,
        marginBottom: 30,
    },
    horizontalLine: {
        alignSelf: 'stretch',
        height: 1,
        backgroundColor: Colors.graySeparatorColor,
        marginVertical: 15,
    },
    helpText: {
        fontFamily: Fonts.type.base,
        fontWeight: '400',
        fontSize: Metrics.normalize(19.12),
        color: Colors.grayTextColor2,
    },
    bottomView: {
        position: 'absolute',
        bottom: -50,
        left: 0,
        alignSelf: 'stretch',
        alignItems: 'flex-start',
    },
    logoutButton: {
        alignSelf: 'flex-start',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: 20,
        marginBottom: 15,
        paddingHorizontal: 25,
        paddingVertical: 12,
        flexDirection: 'row',
        backgroundColor: Colors.darkerGrayBackgroundColor,
        borderRadius: 30,
    },
    logoutButtonImage: {
        width: 18,
        height: 18,
        marginRight: 10,
    },
    logoutButtonText: {
        fontFamily: Fonts.type.base,
        fontWeight: '500',
        fontSize: Metrics.normalize(14),
        color: Colors.logoutButtonText,
    },
    versionText: {
        fontFamily: Fonts.type.base,
        fontWeight: '400',
        fontSize: 10,
        color: Colors.darkGreyTextColor,
        marginTop: 4,
    },
});

import { StyleSheet } from 'react-native';
import { Metrics, Fonts } from 'themes';

export default StyleSheet.create({
    container: {
        alignContent: 'flex-end',
        justifyContent: 'center',
        flexDirection: 'row',
        marginHorizontal: 65,
        alignSelf: 'stretch',
    },
    innerContainer: {
        flex: 1,
        flexBasis: 1,
        justifyContent: 'flex-end',
        alignItems: 'center',
        alignSelf: 'stretch',
    },
    predictionView: {
        margin: 8,
        alignItems: 'center',
        justifyContent: 'flex-end',
        paddingHorizontal: 16,
        paddingBottom: 10,
        borderRadius: 12,
    },
    predictionText: {
        fontSize: Metrics.normalize(11),
        fontFamily: Fonts.type.base,
        fontWeight: '600',
    },
    predictionPeriod: {
        fontSize: Metrics.normalize(12),
        fontFamily: Fonts.type.base,
        fontWeight: '500',
    },
});

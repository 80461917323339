import React from 'react';
import { observer, inject } from 'mobx-react';

import AddressStore from '../../../../stores/AddressStore';
import Marker from '../../../taxis/Marker';
import TaxiStore from '../../../../stores/TaxiStore';
import { Localizable } from '../../../../lib';
import { RidePopup } from '../../../taxis';
import { Stores } from '../../../../stores/RootStore';

interface Props {
    addressStore: AddressStore;
    taxiStore: TaxiStore;
}

export default inject(({ addressStore, taxiStore }: Stores) => ({ addressStore, taxiStore }))(
    observer(
        ({
            addressStore,
            addressStore: { showPickup },
            taxiStore: { taxiRouteStore, selectedTaxiStore },
        }: Props) =>
            taxiRouteStore.showPickupLocationMarker ? (
                <Marker
                    anchor={{ x: 0, y: taxiRouteStore.markersOrderLat ? 1 : 0 }}
                    key="pickupLocation"
                    zIndex={12}
                    coordinate={taxiRouteStore.plainMarkers[0].coords}
                    onPress={showPickup}
                >
                    <RidePopup
                        title={addressStore.pickup || ''}
                        duration={selectedTaxiStore.nearbyEta}
                    />
                </Marker>
            ) : null
    )
);

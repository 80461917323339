import React from 'react';
import { View, Text } from 'react-native';
import styles from './styles';

interface Props {
    text?: string;
    links?: any;
}

const ModalText = ({ text, links }: Props) => (
    <View style={styles.container}>
        <Text style={styles.text}>{text}</Text>
    </View>
);

export default ModalText;

import { StyleSheet } from 'react-native';
import { Fonts, Metrics, Colors } from '../../../themes';

export default StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: 'white',
    },
    topContainer: {
        height: 180,
    },
    mapContainer: {
        flex: 5,
    },
    infoContainer: {
        flex: 5,
        backgroundColor: '#F9FAFC',
        flexDirection: 'row',
        paddingBottom: 15,
        paddingHorizontal: 20,
        alignItems: 'flex-end',
    },
    infoItem: {
        flexDirection: 'row',
        flex: 1,
        height: 45,
        alignItems: 'center',
        justifyContent: 'center',
    },
    infoIcon: {
        width: 30,
        height: 25,
        marginRight: 10,
    },
    infoTitleText: {
        fontFamily: Fonts.type.base,
        fontWeight: '300',
        fontSize: Metrics.normalize(12),
        color: Colors.darkGreyTextColor,
        marginBottom: 3,
    },
    infoDescriptionText: {
        fontFamily: Fonts.type.base,
        fontWeight: '700',
        fontSize: Metrics.normalize(14),
        color: Colors.darkGreyTextColor,
    },
    bottomContainer: {
        shadowColor: 'black',
        shadowRadius: 4,
        shadowOpacity: 0.1,
        shadowOffset: { width: 0, height: 0 },
        elevation: 2,
        flex: 1,
        backgroundColor: 'white',
    },
    providerContainer: {
        position: 'absolute',
        top: 50,
        height: 60,
        left: 20,
        right: 20,
        backgroundColor: 'white',
        shadowRadius: 4,
        shadowOpacity: 0.1,
        shadowOffset: { width: 0, height: 0 },
        elevation: 2,
        borderRadius: 5,
        padding: 10,
        flexDirection: 'row',
        alignItems: 'center',
    },
    providerIcon: {
        height: 40,
        width: 40,
        borderRadius: 5,
        marginRight: 10,
    },
    providerTitleText: {
        fontFamily: Fonts.type.base,
        fontWeight: '700',
        fontSize: Metrics.normalize(15),
        color: Colors.darkGreyTextColor,
        marginBottom: 3,
    },
    providerSeatsText: {
        fontFamily: Fonts.type.base,
        fontWeight: '400',
        fontSize: Metrics.normalize(10),
        color: Colors.grayTextColor,
    },
    priceText: {
        fontFamily: Fonts.type.base,
        fontWeight: '700',
        fontSize: Metrics.normalize(17),
        color: Colors.priceColor,
        marginBottom: 3,
        textAlign: 'right',
    },
    priceValueText: {
        fontFamily: Fonts.type.base,
        fontWeight: '400',
        fontSize: Metrics.normalize(11),
        color: Colors.grayTextColor,
        textAlign: 'right',
    },
    addressContainer: {
        padding: 30,
        flexDirection: 'row',
        alignItems: 'center',
    },
    dateText: {
        fontFamily: Fonts.type.base,
        fontWeight: '300',
        fontSize: Metrics.normalize(13),
        color: Colors.grayTextColor,
        marginVertical: 10,
    },
    routeImage: {
        flex: 1,
        height: 70,
        width: 30,
        marginHorizontal: 10,
        marginVertical: 12,
    },
    addressText: {
        fontFamily: Fonts.type.base,
        fontWeight: '700',
        fontSize: Metrics.normalize(13),
        color: Colors.darkTextColor,
        marginBottom: 3,
        width: Metrics.screenWidth / 2,
    },
    addressValueText: {
        fontFamily: Fonts.type.base,
        fontWeight: '300',
        fontSize: Metrics.normalize(11),
        color: Colors.grayTextColor,
    },
    separator: {
        backgroundColor: Colors.lightGrayColor,
        height: 1,
        alignSelf: 'stretch',
    },
    additionContainer: {
        height: 40,
        flexDirection: 'row',
        alignItems: 'center',
        paddingHorizontal: 30,
    },
    additionText: {
        fontFamily: Fonts.type.base,
        fontWeight: '400',
        fontSize: Metrics.normalize(12),
        color: Colors.grayTextColor,
        flex: 1,
    },
    additionValueText: {
        fontFamily: Fonts.type.base,
        fontWeight: '500',
        fontSize: Metrics.normalize(12),
        color: Colors.darkTextColor,
        flex: 1,
    },
    flexOne: { flex: 1 },
    addressTextView: { flex: 1, justifyContent: 'center' },
});

import React from 'react';
import { View, Text, Image, TouchableOpacity, ScrollView, PanResponder } from 'react-native';
import { observer, inject } from 'mobx-react';
import Voca from 'voca';

import ConnectingInfoStyles from './styles/ConnectingInfoStyles';
import { Images } from 'themes';
import { Localizable, Analytics } from 'lib';
import { providerInfo } from 'services/Utils';
import { useStores } from 'containers/App';

export default observer(() => {
    const {
        stores: {
            servicesStore,
            servicesStore: { navigateToItem, providerPopup },
            navigationStore: { navigate, goBack },
            taxiStore,
        },
    } = useStores();
    const navigateToMainView = () => {
        navigate('MainView');
        Analytics.trackScreenView(`02${taxiStore.taxiState === 'taxis' ? '-03' : ''}_Options`);
    };
    const navigateToServices = () => {
        navigate('Services');
        Analytics.trackScreenView('04-02_Accounts');
    };
    const handleCloseClick = () => {
        Analytics.trackServiceLinking(
            !!providerPopup ? providerPopup.toLowerCase() : 'unknown',
            'connecting',
            'cancel'
        );
        return servicesStore.navigateFromOptions ? navigateToMainView() : navigateToServices();
    };
    return (
        <View style={ConnectingInfoStyles.container}>
            <View style={ConnectingInfoStyles.topView}>
                <TouchableOpacity
                    onPress={handleCloseClick}
                    style={ConnectingInfoStyles.closeButton}
                >
                    <Image
                        source={Images.settings.close}
                        style={ConnectingInfoStyles.closeImage}
                        resizeMode="contain"
                    />
                </TouchableOpacity>
            </View>
            <ScrollView style={ConnectingInfoStyles.scrollView} bounces={false}>
                <View style={ConnectingInfoStyles.top}>
                    {servicesStore.providerPopup && (
                        <Image
                            source={providerInfo(servicesStore.providerPopup).icon}
                            style={ConnectingInfoStyles.providerImage}
                        />
                    )}
                    <Text style={ConnectingInfoStyles.providerText}>
                        {Localizable.t('services.connectingInfo.title.1')}{' '}
                        {servicesStore.providerPopup &&
                            `${Voca.capitalize(servicesStore.providerPopup.toLowerCase())} `}
                        {Localizable.t('services.connectingInfo.title.2')}
                    </Text>
                    <Text style={ConnectingInfoStyles.grayText}>
                        {Localizable.t('services.connectingInfo.subtitle')}
                    </Text>
                </View>
                <View style={ConnectingInfoStyles.bottom}>
                    <View style={ConnectingInfoStyles.point}>
                        <Image
                            resizeMode="contain"
                            source={Images.icons.fancyDot}
                            style={ConnectingInfoStyles.pointImage}
                        />
                        <View style={ConnectingInfoStyles.pointTextView}>
                            <Text style={ConnectingInfoStyles.pointTitle}>
                                {Localizable.t('services.connectingInfo.points.1.title')}
                            </Text>
                            <Text style={ConnectingInfoStyles.pointText}>
                                {Localizable.t('services.connectingInfo.points.1.subtitle')}
                            </Text>
                        </View>
                    </View>
                    <View style={ConnectingInfoStyles.separator} />
                    {servicesStore.providerPopup !== 'curb' && (
                        <View style={ConnectingInfoStyles.point}>
                            <Image
                                resizeMode="contain"
                                source={Images.icons.fancyDot}
                                style={ConnectingInfoStyles.pointImage}
                            />
                            <View style={ConnectingInfoStyles.pointTextView}>
                                <Text style={ConnectingInfoStyles.pointTitle}>
                                    {Localizable.t('services.connectingInfo.points.2.title')}
                                </Text>
                                <Text style={ConnectingInfoStyles.pointText}>
                                    {Localizable.t('services.connectingInfo.points.2.subtitle.1')}{' '}
                                    <Text style={ConnectingInfoStyles.boldText}>
                                        {Localizable.t(
                                            'services.connectingInfo.points.2.subtitle.2'
                                        )}
                                    </Text>{' '}
                                    {Localizable.t('services.connectingInfo.points.2.subtitle.3')}
                                </Text>
                            </View>
                        </View>
                    )}
                </View>
            </ScrollView>
            <View style={ConnectingInfoStyles.bottomContainer}>
                <TouchableOpacity
                    style={ConnectingInfoStyles.confirmButton}
                    onPress={navigateToItem}
                >
                    <Text style={ConnectingInfoStyles.confirmButtonText}>
                        {Localizable.t('services.connectingInfo.understand')}
                    </Text>
                </TouchableOpacity>
            </View>
        </View>
    );
});

import { StyleSheet } from 'react-native';
import { Colors, Metrics, Fonts } from '../../../themes';

export default StyleSheet.create({
    header: {
        marginLeft: 20,
        color: Colors.darkTextColor,
        fontSize: Metrics.normalize(20),
        fontWeight: '400',
        marginTop: 8,
        fontFamily: Fonts.type.base,
    },
    list: {
        alignSelf: 'stretch',
    },
});

import React, { PureComponent } from 'react';
import { SceneView, createNavigator, StackRouter } from '@react-navigation/core';

import {
    Credentials,
    DriverLicense,
    Email,
    Name,
    OTP,
    PhoneNumber,
} from 'components/providerConnecting/providers/lyft';
import { CountryPicker } from 'containers/setup';

interface NavigatorViewProps {
    navigation: any;
    descriptors: any;
}

class NavigatorView extends PureComponent<NavigatorViewProps> {
    render() {
        const {
            descriptors,
            navigation: { state, screenProps },
        } = this.props;
        const { getComponent, navigation } = descriptors[state.routes[state.index].key];

        return (
            <SceneView
                style={{ flex: 1 }}
                component={getComponent()}
                navigation={navigation}
                screenProps={screenProps}
            />
        );
    }
}

const LyftConnectingRouter = createNavigator(
    NavigatorView,
    StackRouter(
        {
            PhoneNumber,
            OTP,
            Name,
            Email,
            DriverLicense,
            Credentials,
            CountryPicker,
        },
        {
            initialRouteName: 'PhoneNumber',
            headerMode: 'none',
        },
        {}
    ),
    { navigationOptions: {} }
);

export default LyftConnectingRouter;

import { StyleSheet } from 'react-native';
import { Colors, Fonts, Metrics } from 'themes';

export default StyleSheet.create({
    interactableContainer: {
        marginHorizontal: 15,
        backgroundColor: Colors.transparent,
        borderRadius: 16,
        shadowColor: '#000000',
        shadowOffset: { width: 0, height: 0 },
        shadowRadius: 4,
        shadowOpacity: 0.2,
        elevation: 20,
    },
    indicatorView: {
        alignSelf: 'stretch',
        alignItems: 'center',
        justifyContent: 'center',
        paddingVertical: 5,
        backgroundColor: Colors.transparent,
    },
    indicator: {
        backgroundColor: Colors.grayBackgroundColor,
        width: 40,
        height: 4,
        borderRadius: 6,
    },
    modalContainer: {
        backgroundColor: Colors.grayBackgroundColor,
        alignSelf: 'stretch',
        paddingTop: 10,
        paddingBottom: 20,
        paddingHorizontal: 10,
        borderRadius: 16,
        alignItems: 'center',
        justifyContent: 'center',
    },
    skipView: {
        alignSelf: 'stretch',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    skipButton: {
        paddingHorizontal: 10,
        paddingVertical: 2,
        borderRadius: 10,
        backgroundColor: Colors.graySeparatorColor,
    },
    skipButtonText: {
        fontFamily: Fonts.type.base,
        fontWeight: '500',
        color: Colors.grayTextColor2,
        fontSize: Metrics.normalize(10),
    },
    title: {
        marginTop: 20,
        marginBottom: 8,
        fontFamily: Fonts.type.base,
        fontWeight: '700',
        color: Colors.darkTextColor,
        fontSize: Metrics.normalize(18),
    },
    subtitle: {
        textAlign: 'center',
        marginTop: 8,
        marginBottom: 15,
        marginHorizontal: 15,
        fontFamily: Fonts.type.base,
        fontWeight: '400',
        color: Colors.grayTextColor,
        fontSize: Metrics.normalize(14),
    },
    signupButton: {
        alignSelf: 'stretch',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
        marginHorizontal: 35,
        marginVertical: 20,
        paddingVertical: 12,
        backgroundColor: Colors.obiTeal,
        borderRadius: 6,
    },
    signupButtonIcon: {
        width: 15,
        height: 15,
        marginRight: 10,
    },
    signupButtonText: {
        fontFamily: Fonts.type.base,
        fontWeight: '400',
        color: 'white',
        fontSize: Metrics.normalize(15),
    },
    bottomText: {
        marginHorizontal: 25,
        textAlign: 'center',
        marginBottom: 10,
        fontFamily: Fonts.type.base,
        fontWeight: '400',
        color: Colors.grayTextColor,
        fontSize: Metrics.normalize(10),
    },
    bottomTextUnderlined: {
        textAlign: 'center',
        marginBottom: 10,
        fontFamily: Fonts.type.base,
        fontSize: Metrics.normalize(10),
        textDecorationLine: 'underline',
        fontWeight: '500',
        color: Colors.grayTextColor2,
    },
});

import React from 'react';
import { View, Text, Image, ActivityIndicator, TouchableOpacity } from 'react-native';
import { observer } from 'mobx-react';

import Modal from 'modules/modal/modal';

import Button from '../Button';
import NetConnectionModalStyles from './styles/NetConnectionModalStyles';
import { Images, Colors } from 'themes';
import { Localizable } from 'lib';
import { useStores } from 'containers/App';

export default observer(() => {
    const {
        stores: {
            appStore,
            connectionStore,
            connectionStore: { tryToReconnect, hideConnectionModal },
        },
    } = useStores();
    return (
        <Modal
            animationIn="zoomIn"
            animationOut="zoomOut"
            isVisible={
                appStore.appHydrated &&
                !connectionStore.netConnectionAvailable &&
                connectionStore.showConnectionModal
            }
            avoidKeyboard
        >
            <View style={NetConnectionModalStyles.container}>
                <View style={NetConnectionModalStyles.modalContainer}>
                    <TouchableOpacity
                        style={NetConnectionModalStyles.closeButton}
                        onPress={hideConnectionModal}
                        hitSlop={{ top: 10, right: 10, left: 10, bottom: 10 }}
                    >
                        <Image
                            source={Images.services.close}
                            style={NetConnectionModalStyles.closeButtonImage}
                        />
                    </TouchableOpacity>
                    <Image
                        source={Images.icons.connection}
                        style={NetConnectionModalStyles.topImage}
                    />
                    <Text style={NetConnectionModalStyles.modalTitle}>
                        {Localizable.t('noConnectionModal.title')}
                    </Text>
                    <Text style={NetConnectionModalStyles.modalInfo} numberOfLines={2}>
                        {Localizable.t('noConnectionModal.info')}
                    </Text>
                    {connectionStore.connectionRetrying ? (
                        <View style={NetConnectionModalStyles.activityIndicatorView}>
                            <ActivityIndicator color={Colors.obiTeal} size="large" />
                        </View>
                    ) : (
                        <Button
                            title={Localizable.t('noConnectionModal.button')}
                            handlePress={tryToReconnect}
                            active
                            style={NetConnectionModalStyles.modalButton}
                        />
                    )}
                </View>
            </View>
        </Modal>
    );
});

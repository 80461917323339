import React, { Component } from 'react';
import { View, TouchableOpacity, Image, Text } from 'react-native';
import { observable, action } from 'mobx';
import { observer, inject } from 'mobx-react';

import Button from '../Button';
import PredictionList from './PredictionList';
import PredictionStore from '../../stores/dataStores/PredictionStore';
import PredictionViewStyles from './styles/PrefictionsViewStyles';
import { Images, Colors, Metrics } from '../../themes';
import { Localizable } from '../../lib';
import { Stores } from '../../stores/RootStore';

interface YID {
    y: number;
    id: string;
}

interface Props {
    predictionStore: PredictionStore;
}

export default inject(({ predictionStore }: Stores) => ({ predictionStore }))(
    observer(
        ({
            predictionStore: { togglePredictionsScreen, predictions, togglePredictionsTooltip },
        }) => {
            return (
                <View style={PredictionViewStyles.container} pointerEvents="box-none">
                    <View style={PredictionViewStyles.innerContainer}>
                        <View style={PredictionViewStyles.changePredictionPossibleView}>
                            <Image source={Images.predictions.alert} />
                            <Text style={PredictionViewStyles.changePredictionPossibleText}>
                                {Localizable.t('predictions.priceChangePossible')}
                            </Text>
                        </View>
                        <Text style={PredictionViewStyles.changePredictionText}>
                            {Localizable.t('predictions.changePrediction')}
                        </Text>
                        <PredictionList predictions={predictions} />
                        <Button
                            style={PredictionViewStyles.togglePredictionButton}
                            active
                            handlePress={() => {
                                togglePredictionsScreen(false);
                                togglePredictionsTooltip(true);
                            }}
                            title={Localizable.t('predictions.gotIt')}
                        />
                        <TouchableOpacity
                            onPress={() => {
                                togglePredictionsScreen(false);
                                togglePredictionsTooltip(false);
                            }}
                        >
                            <Text
                                style={PredictionViewStyles.hidePredictionsText}
                                // active={false}
                            >
                                {Localizable.t('predictions.hidePredictions')}
                            </Text>
                        </TouchableOpacity>
                    </View>
                </View>
            );
        }
    )
);

import React from 'react';
import { View, Text, Image } from 'react-native';
import { observer } from 'mobx-react';

import styles from './styles';
import { Images } from 'themes';
import { Localizable } from 'lib';

interface Props {
    pickup: any;
    destination: any;
    date: string;
    arrivedAt: any;
    arrivedTime: any;
}

export default observer(({ pickup, destination, date, arrivedAt, arrivedTime }: Props) => {
    return (
        <View style={styles.addressContainer}>
            <Image source={Images.icons.route} style={styles.routeImage} resizeMode="contain" />
            <View style={styles.innerContainer}>
                <View style={styles.addressView}>
                    <Text style={styles.addressValueText}>
                        {Localizable.t('rideDetails.pickup').toUpperCase()}
                    </Text>
                    <Text style={styles.addressText} numberOfLines={1}>
                        {pickup}
                    </Text>
                </View>
                <View style={styles.addressView}>
                    <Text style={styles.addressValueText}>
                        {Localizable.t('rideDetails.destination').toUpperCase()}
                    </Text>
                    <Text style={styles.addressText} numberOfLines={1}>
                        {destination}
                    </Text>
                </View>
            </View>
            <View style={styles.flexOne}>
                <View style={styles.inner}>
                    <Text style={styles.timeText} numberOfLines={1}>
                        {'PICKUP TIME'}
                    </Text>
                    <Text style={styles.timeValueText}>{date}</Text>
                </View>
                <View style={styles.inner}>
                    <Text style={styles.timeText} numberOfLines={1}>
                        {arrivedTime ? Localizable.t('deeplinkPopup.arriveBy').toUpperCase() : ''}
                    </Text>
                    <Text style={styles.timeValueText}>{arrivedTime ? arrivedAt : ''}</Text>
                </View>
            </View>
        </View>
    );
});

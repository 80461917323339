import React, { Component } from 'react';
import { View, Text, TextInput, TouchableOpacity, Image } from 'react-native';
import { observer, inject } from 'mobx-react';
import KeyboardAwareScrollView from 'modules/keyboardAwareScrollView/keyboardAwareScrollView';

import { Colors, Images } from 'themes';
import { NavigationStore, ServicesStore, Stores } from 'stores';
import { Localizable, Analytics } from 'lib';

import AdditionalInfoStyles from '../../styles/AdditionalInfoStyles';
import TitleNavigationBar from '../../../navigationBars/TitleNavigationBar';
import {
    ErrorMessage,
    NextButton,
    InfoLabel,
    ClickableInfoLabel,
} from '../../providerConnectingComponents';

interface Props {
    navigationStore: NavigationStore;
    servicesStore: ServicesStore;
}

@inject(({ servicesStore, navigationStore }: Stores) => ({
    servicesStore,
    navigationStore,
}))
@observer
export default class PhoneNumber extends Component<Props, {}> {
    componentDidMount() {
        Analytics.trackScreenView('04-02-01a_linkPhone');
        Analytics.trackServiceLinking('lyft', 'connecting', 'phone_screen');
    }

    render() {
        const {
            servicesStore: {
                hideProviderPopup,
                lyftServiceStore: {
                    countryCode,
                    countryName,
                    errorMessage,
                    phoneNumber,
                    onLyftPhoneNumberSubmit,
                    selectCountry,
                    setLyftPhoneNumber,
                    validPhoneNumber,
                    clearStore,
                    downloadApp,
                },
            },
            navigationStore: { navigate },
        } = this.props;

        return (
            <View style={AdditionalInfoStyles.container}>
                <TitleNavigationBar title="Lyft" handleBackClick={() => hideProviderPopup()} />
                <KeyboardAwareScrollView
                    contentContainerStyle={{ paddingHorizontal: 20 }}
                    keyboardShouldPersistTaps="handled"
                >
                    <Text style={AdditionalInfoStyles.title}>
                        {Localizable.t('additionalInfo.titlePhone')}
                    </Text>
                    <Text style={AdditionalInfoStyles.description}>
                        {'You will receive a 6-digit verification code'}
                    </Text>

                    <View style={AdditionalInfoStyles.inputBorder}>
                        <TouchableOpacity
                            onPress={() =>
                                navigate('CountryPicker', {
                                    onClose: country => {
                                        selectCountry(country);
                                    },
                                })
                            }
                        >
                            <View style={AdditionalInfoStyles.countryContainer}>
                                <Image
                                    source={Images.flags[countryName]}
                                    style={AdditionalInfoStyles.countryIcon}
                                    resizeMode="contain"
                                />
                                <Image
                                    source={Images.icons.countryArrow}
                                    style={AdditionalInfoStyles.countryArrow}
                                    resizeMode="contain"
                                />
                                <Text style={AdditionalInfoStyles.countryText}>+{countryCode}</Text>
                            </View>
                        </TouchableOpacity>
                        <TextInput
                            style={[
                                AdditionalInfoStyles.textInput,
                                {
                                    fontWeight: phoneNumber.length ? '300' : '100',
                                },
                            ]}
                            keyboardType="phone-pad"
                            placeholder={Localizable.t('additionalInfo.placeholderPhone')}
                            placeholderTextColor={Colors.lightGrayColor}
                            value={phoneNumber}
                            secureTextEntry={false}
                            onChangeText={setLyftPhoneNumber}
                            autoFocus
                            autoCorrect={false}
                            selectionColor={Colors.obiTeal}
                            onSubmitEditing={onLyftPhoneNumberSubmit}
                            returnKeyType="done"
                        />
                    </View>
                    {errorMessage.length !== 0 && <ErrorMessage message={errorMessage} />}
                    {/* {navigation.getParam('flow', 'login') !== 'register' && (
                        <ClickableInfoLabel
                            childrenProps={[
                                {
                                    text: '',
                                    clickableText: 'No account? Click here to continue.',
                                    clickableTextStyle: {
                                        fontFamily: Fonts.type.base,
                                        fontWeight: '500',
                                        color: '#FF01BF',
                                    },
                                    handleClickableTextClick: () => {
                                        clearStore();
                                        navigate('ProviderRegisterLyft');
                                        navigate('PhoneNumber', { flow: 'register' });
                                    },
                                },
                            ]}
                        />
                    )} */}
                    <ClickableInfoLabel
                        childrenProps={[
                            {
                                text: '',
                                clickableText: 'No account? Click here to continue.',
                                clickableTextStyle: {
                                    fontWeight: '500',
                                    color: '#FF01BF',
                                },
                                handleClickableTextClick: downloadApp,
                            },
                        ]}
                    />
                    <InfoLabel info={Localizable.t('additionalInfo.lyft.permissionClick')} />
                    <InfoLabel info={Localizable.t('additionalInfo.lyft.info')} />
                    <NextButton onPress={onLyftPhoneNumberSubmit} active={validPhoneNumber} />
                </KeyboardAwareScrollView>
            </View>
        );
    }
}

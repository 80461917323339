import { StyleSheet, Platform } from 'react-native';
import { Metrics, Fonts, Colors } from '../../../themes';

export default StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'flex-start',
        paddingTop: Platform.OS === 'ios' ? Metrics.paddingTop : 10,
        paddingBottom: Metrics.paddingBottom === 0 ? 10 : Metrics.paddingBottom + 5,
    },
    innerContainer: {
        flex: 1,
        alignSelf: 'stretch',
    },
    topView: {
        alignSelf: 'stretch',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
    },
    scrollView: {
        flex: 1,
        alignSelf: 'stretch',
        marginBottom: 20,
        paddingHorizontal: 30,
    },
    top: {
        alignSelf: 'stretch',
        paddingTop: 30,
        paddingBottom: 30,
    },
    closeButton: {
        padding: 30,
        paddingTop: 40,
        paddingLeft: 40,
    },
    closeImage: {
        width: 16,
        height: 16,
    },
    bottom: {
        alignSelf: 'stretch',
        paddingTop: 10,
        paddingBottom: 20,
    },
    stretch: {
        alignSelf: 'stretch',
    },
    bottomContainer: {
        flex: 0.1,
        alignSelf: 'stretch',
        justifyContent: 'flex-end',
        paddingHorizontal: 30,
    },
    providerImage: {
        alignSelf: 'flex-start',
        width: 40,
        height: 40,
        borderRadius: 20,
        marginBottom: 30,
    },
    providerText: {
        alignSelf: 'flex-start',
        fontSize: Metrics.normalize(22),
        lineHeight: Metrics.normalize(28),
        fontFamily: Fonts.type.base,
        fontWeight: '500',
        color: Colors.black,
        marginBottom: 15,
    },
    grayText: {
        alignSelf: 'flex-start',
        fontSize: Metrics.normalize(15),
        lineHeight: Metrics.normalize(24),
        fontFamily: Fonts.type.base,
        fontWeight: '400',
        color: Colors.grayTextColor,
        textAlign: 'left',
    },
    pointsView: {
        paddingVertical: 60,
    },
    point: {
        flexDirection: 'row',
        alignSelf: 'flex-start',
    },
    pointImage: {
        width: 30,
        height: 30,
        borderRadius: 10,
    },
    pointTextView: {
        flex: 1,
        paddingHorizontal: 15,
    },
    pointTitle: {
        fontSize: Metrics.normalize(17),
        lineHeight: Metrics.normalize(24),
        fontFamily: Fonts.type.base,
        fontWeight: '500',
        color: Colors.black,
        marginBottom: 10,
    },
    pointText: {
        alignSelf: 'flex-start',
        fontSize: Metrics.normalize(14),
        lineHeight: Metrics.normalize(21),
        fontFamily: Fonts.type.base,
        fontWeight: '400',
        color: Colors.grayTextColor,
        textAlign: 'left',
    },
    boldText: {
        fontWeight: '800',
    },
    separator: {
        alignSelf: 'stretch',
        marginVertical: 30,
        height: 1,
        backgroundColor: Colors.lightGrayColor,
    },
    confirmButton: {
        backgroundColor: Colors.obiTeal,
        paddingVertical: 15,
        alignSelf: 'stretch',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 10,
    },
    confirmButtonText: {
        fontSize: Metrics.normalize(16),
        lineHeight: Metrics.normalize(20),
        fontFamily: Fonts.type.base,
        fontWeight: '700',
        color: '#fff',
    },
});

import { StyleSheet } from 'react-native';
import { Fonts, Metrics, Colors } from '../../../themes';

export default StyleSheet.create({
    rowView: {
        paddingHorizontal: 16,
        paddingVertical: 10,
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: 'white',
    },
    icon: {
        height: 30,
        width: 30,
        marginRight: 10,
    },
    rowText: {
        fontFamily: Fonts.type.base,
        fontWeight: '500',
        fontSize: Metrics.normalize(16),
        color: Colors.darkTextColor,
        backgroundColor: 'transparent',
        marginRight: 10,
        flex: 1,
    },
    descriptionView: { flex: 1 },
    descriptionText: {
        fontFamily: Fonts.type.base,
        fontWeight: '400',
        fontSize: Metrics.normalize(12),
        color: Colors.grayTextColor,
        backgroundColor: 'transparent',
    },
    valueText: {
        fontFamily: Fonts.type.base,
        fontWeight: '400',
        fontSize: Metrics.normalize(16),
        color: Colors.grayTextColor,
        backgroundColor: 'transparent',
        marginRight: 10,
    },
    arrow: {
        height: 10,
        width: 10,
        marginLeft: 10,
        opacity: 0.4,
    },
    sectionText: {
        fontFamily: Fonts.type.base,
        fontWeight: '500',
        fontSize: Metrics.normalize(12),
        color: Colors.grayTextColor,
        marginHorizontal: 16,
        marginBottom: 10,
        marginTop: 16,
        backgroundColor: 'transparent',
    },
    separator: {
        height: 1,
        backgroundColor: Colors.grayTextColor,
        opacity: 0.1,
    },
    headerContainer: {
        backgroundColor: Colors.grayBackgroundColor,
    },
    separatorContainer: {
        backgroundColor: 'white',
    },
});

/* eslint-disable camelcase */
import Config from 'react-native-config';
import moment from 'moment';
import { observable, action, computed } from 'mobx';
import { persist, create } from 'mobx-persist';
import { Platform } from 'react-native';

import NewProvider from 'domain/app/NewProvider';
import NewProviderStore from '../dataStores/taxiResultsStore/NewProviderStore';
import RestClient from 'services/RestClient';
import RootStore from '../RootStore';
import TaxisComparationStore from '../dataStores/taxiResultsStore/TaxisComparationStore';
import WebSocketMessage from 'domain/api/WebSocketMessage';
import { Analytics } from 'lib';
import { TaxiResultsSocketService } from 'services/WebSocketServices';
import { providerInfo, distanceBetweenCoords } from 'services/Utils';
import { isStandard } from 'domain/serviceProviders/TaxiServices';
import { BaseStore } from '..';

const TYPES_REFRESH_INTERVAL = 30;

export default class TaxiResultsStore extends BaseStore {
    taxisComparationStore = new TaxisComparationStore(this.rootStore);

    newProviderStore = new NewProviderStore(this.rootStore);

    searchEventTracked: boolean = false;

    @observable
    taxiTypes = [];

    @observable
    taxiTypesFiltered = [];

    @observable
    typesLoading: boolean = false;

    @observable
    intervalLoading: boolean = false;

    @observable
    lastTaxiUpdate = null;

    removeListeners = () => {
        this.removeTaxiTypesInterval();
    };

    addTaxiTypesInterval = () => {
        if (!this.taxiTypesInterval) {
            this.taxiTypesInterval = setInterval(
                () => this.getTaxiTypes(true, true),
                TYPES_REFRESH_INTERVAL * 1000
            );
        }
    };

    resetInterval = () => {
        this.removeTaxiTypesInterval();
        this.getTaxiTypes(false);
    };

    removeTaxiTypesInterval = () => {
        if (!this.taxiTypesInterval) {
            clearInterval(this.taxiTypesInterval);
        }
    };

    setResetFaresAnimation = (func?: () => void) => {
        this.resetFaresAnimation = func;
    };

    @computed
    get filteredTypes() {
        const {
            filtersStore: { excludedProviders, excludedTypes, seats },
            taxiStore,
        } = this.rootStore.stores;
        return taxiStore.ridesEnabled
            ? this.taxiTypes.filter(
                  item =>
                      item.max_passengers >= seats &&
                      excludedTypes.indexOf(item.service_level) === -1 &&
                      excludedProviders.indexOf(item.service_provider) === -1 &&
                      !!providerInfo(item.service_provider).icon &&
                      item.max_passengers >= seats
              )
            : [];
    }

    @computed
    get bestValue() {
        const normalRides = this.rideTypesOrdered.filter(ride => !ride.bike);
        if (normalRides.length > 0) {
            return this.taxisComparationStore.selectedTypesTab.type === 'ascending'
                ? normalRides[0].offer_id
                : normalRides[normalRides.length - 1].offer_id;
        }
        return null;
    }

    @computed
    get rideTypesOrdered() {
        const {
            stores: {
                taxiStore: { taxiRouteStore },
                bikeStore: { bikeTypes },
            },
        } = this.rootStore;

        let filteredRides;
        if (this.taxisComparationStore.selectedTypesTab.sort === 'fastest') {
            filteredRides = [...this.filteredTypes, ...bikeTypes].sort((a, b) => {
                const etaA = a.bike
                    ? Number.MAX_VALUE
                    : a.initial_eta
                    ? a.initial_eta + (a.ride_duration || taxiRouteStore.rideDuration || 0)
                    : Number.MAX_VALUE;
                const etaB = b.bike
                    ? Number.MAX_VALUE
                    : b.initial_eta
                    ? b.initial_eta + (b.ride_duration || taxiRouteStore.rideDuration || 0)
                    : Number.MAX_VALUE;

                const isStandardRideA = isStandard(a) ? 1 : 0;
                const isStandardRideB = isStandard(b) ? 1 : 0;

                let priceA = a.price_info
                    ? a.price_info.rate_estimate.replace(/^[^_]*-/, '').replace(/[^\d.-]/g, '')
                    : '';
                priceA = priceA.length ? parseFloat(priceA) : Number.MAX_VALUE;
                let priceB = b.price_info
                    ? b.price_info.rate_estimate.replace(/^[^_]*-/, '').replace(/[^\d.-]/g, '')
                    : '';
                priceB = priceB.length ? parseFloat(priceB) : Number.MAX_VALUE;

                return this.taxisComparationStore.selectedTypesTab.type === 'ascending'
                    ? etaA - etaB || isStandardRideB - isStandardRideA || priceA - priceB
                    : etaB - etaA || isStandardRideA - isStandardRideB || priceB - priceA;
            });
        } else {
            filteredRides = [
                ...bikeTypes,
                ...this.filteredTypes.sort((a, b) => {
                    let priceA = a.price_info.rate_estimate
                        .replace(/^[^_]*-/, '')
                        .replace(/[^\d.-]/g, '');
                    priceA = priceA.length ? parseFloat(priceA) : Number.MAX_VALUE;
                    let priceB = b.price_info.rate_estimate
                        .replace(/^[^_]*-/, '')
                        .replace(/[^\d.-]/g, '');
                    priceB = priceB.length ? parseFloat(priceB) : Number.MAX_VALUE;
                    return this.taxisComparationStore.selectedTypesTab.type === 'ascending'
                        ? priceA - priceB
                        : priceB - priceA;
                }),
            ];
        }
        return filteredRides;
    }

    @action
    getTaxiTypes = (reload: boolean, interval?: any) => {
        const {
            addressStore,
            bikeStore: { getBikesForPickupDestiantion },
            taxiStore: {
                selectedTaxiStore: { setExactPrice },
                setTaxiState,
                taxiRouteStore,
            },
            taxiStore,
            linkingStore,
            linkingStore: { registerForPush, setShouldShowRegisterForPush },
        } = this.rootStore.stores;

        if (!addressStore.pickupLocation || !addressStore.destinationLocation) {
            return;
        }
        if (this.resetFaresAnimation) {
            this.resetFaresAnimation();
        }
        if (reload && !interval && this.intervalLoading && !this.typesLoading) {
            this.typesLoading = false;
            return;
        }
        if ((interval || reload) && (this.typesLoading || this.intervalLoading)) {
            return;
        }
        if (interval && taxiStore.taxiState !== 'taxis' && taxiStore.taxiState !== 'confirm') {
            this.removeTaxiTypesInterval();
            return;
        }
        if (interval) {
            this.intervalLoading = true;
        } else {
            this.intervalLoading = false;
        }

        if (!reload) {
            this.taxiTypes = [];
            this.rootStore.stores.predictionStore.setPredictions([]);
            this.taxisComparationStore.typesTabSelected(
                this.taxisComparationStore.selectedTypesTab
            );
            if (linkingStore.shouldShowRegisterForPush) {
                registerForPush();
            } else {
                setShouldShowRegisterForPush();
            }
            registerForPush();
            Analytics.trackTaxiCompare();
        }

        this.typesLoading = true;
        this.tempTaxiTypes = [];

        getBikesForPickupDestiantion({
            pickup: addressStore.pickupLocation,
            destination: addressStore.destinationLocation,
        });

        if (Platform.OS === 'web') {
            RestClient.getTaxis({
                startLat: addressStore.pickupLocation.latitude,
                startLong: addressStore.pickupLocation.longitude,
                endLat: addressStore.destinationLocation.latitude,
                endLong: addressStore.destinationLocation.longitude,
                numPassengers: 1,
                pickupDate: moment().format('YYYY-MM-DDTHH:mm:ss.SSS'),
                onDemand: true,
                startNickname: addressStore.pickupAddressSocketString,
                endNickname: addressStore.destinationAddressSocketString,
                distance: taxiRouteStore.routeDistance,
                duration: taxiRouteStore.rideDuration,
            })
                .then(result => {
                    console.log('GET TAXI', result);
                    this.taxiTypes = [];
                    this.processTaxiWebSocketResults(
                        { type: 'SEARCH_RESULTS', data: result.data },
                        false
                    );
                    this.typesLoading = false;
                    this.intervalLoading = false;
                })
                .catch(err => {
                    console.log('GET TAXIS ERROR', { ...err });
                    console.log('GET TAXIS ERROR', err);
                    this.typesLoading = false;
                });
        } else {
            const url = `${Config.WEBSOCKET_URL}/taxis/ws?start_latitude=${
                addressStore.pickupLocation.latitude
            }&start_longitude=${addressStore.pickupLocation.longitude}&end_latitude=${
                addressStore.destinationLocation.latitude
            }&end_longitude=${
                addressStore.destinationLocation.longitude
            }&pickup_date_time=${moment().format(
                'YYYY-MM-DDTHH:mm:ss.SSS'
            )}&on_demand=${true}&num_passengers=${1}&start_nickname=${
                addressStore.pickupAddressSocketString
            }&end_nickname=${addressStore.destinationAddressSocketString}${
                !!taxiRouteStore.routeDistance || taxiRouteStore.routeDistance === 0
                    ? `&distance=${taxiRouteStore.routeDistance}`
                    : ''
            }${this.currentCountryCode ? `&country_code=${this.currentCountryCode}` : ''}${
                !!taxiRouteStore.rideDuration || taxiRouteStore.rideDuration === 0
                    ? `&duration=${taxiRouteStore.rideDuration}`
                    : ''
            }`;
            TaxiResultsSocketService(
                url,
                () => {
                    if (!reload) {
                        this.taxiTypes = [];
                        setTaxiState('taxis');
                        Analytics.trackEvent('Search_Results');
                    }
                },
                results => {
                    this.processTaxiWebSocketResults(results, reload);
                },
                () => {
                    if (reload) {
                        if (this.tempTaxiTypes.length || !this.taxiTypes.length) {
                            this.taxiTypes = this.tempTaxiTypes.slice();
                            this.taxisComparationStore.typesTabSelected(
                                this.taxisComparationStore.selectedTypesTab
                            );
                        }
                    }
                    this.typesLoading = false;
                    this.intervalLoading = false;
                }
            );
        }
    };

    setSearchEventTracked = () => {
        this.searchEventTracked = true;
    };

    resetSearchEventTracked = () => {
        this.searchEventTracked = false;
    };

    @action
    processTaxiWebSocketResults = (results: WebSocketMessage, reload: boolean) => {
        const {
            taxiStore: {
                selectedTaxiStore: { updateSelectedTaxi },
                taxiState,
            },
            predictionStore: { setPredictions },
            mapStore: { lastUserLatitude, lastUserLongitude },
        } = this.rootStore.stores;

        const { data } = results;
        if (results.type === 'SEARCH_RESULTS') {
            if (!this.searchEventTracked) {
                this.setSearchEventTracked();
                Analytics.trackEvent('Search_Results', {
                    search_id: data[0] && data[0].search_id,
                    user_location: { latitude: lastUserLatitude, longitude: lastUserLongitude },
                });
            }
            if (reload) {
                this.tempTaxiTypes.push(...data);
                updateSelectedTaxi(data);
            } else {
                if (taxiState !== 'taxis') return;
                this.taxiTypes.push(...data);
                this.taxisComparationStore.typesTabSelected(
                    this.taxisComparationStore.selectedTypesTab
                );
            }
            this.addTaxiTypesInterval();
            this.lastTaxiUpdate = moment();
        } else if (results.type === 'PRICE_PREDICTIONS') {
            setPredictions(data.predictions);
        } else if (results.type === 'CLOSE') {
            this.resetSearchEventTracked();
            if (this.rideTypesOrdered.length === 0) {
                Analytics.trackEmptyProvidersList();
            }
        }
    };

    @computed.struct
    get taxiTypesSortedETA() {
        return this.taxiTypes
            .filter(type => !!type.initial_eta)
            .sort((a, b) => a.initial_eta - b.initial_eta);
    }
}

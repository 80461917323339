import { StyleSheet } from 'react-native';
import { Fonts, Metrics, Colors } from '../../../themes';

export default StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: Colors.typesBackgroundColor,
    },
    keyboardAwareScrollView: { flex: 1 },
    inputBorder: {
        borderColor: '#F2F2F2',
        borderWidth: 1,
        marginTop: 2,
        marginBottom: 12,
        paddingHorizontal: 10,
        flexDirection: 'row',
        alignItems: 'center',
    },
    title: {
        fontSize: Metrics.normalize(34),
        fontWeight: '500',
        fontFamily: Fonts.type.base,
        backgroundColor: 'transparent',
        color: Colors.darkTextColor,
        marginTop: 30,
    },
    phoneNumber: {
        color: 'black',
    },
    description: {
        fontSize: Metrics.normalize(16),
        fontWeight: '300',
        fontFamily: Fonts.type.base,
        backgroundColor: 'transparent',
        color: '#9B99A9',
        marginVertical: 10,
        lineHeight: 28,
    },
    resendText: {
        fontSize: Metrics.normalize(16),
        fontWeight: '300',
        fontFamily: Fonts.type.base,
        backgroundColor: 'transparent',
        color: 'black',
        marginTop: 20,
        textDecorationLine: 'underline',
        textDecorationStyle: 'solid',
    },
    socialButton: {
        width: Metrics.screenWidth - 60,
        height: (Metrics.screenWidth - 60) * 0.19,
    },
    textInput: {
        height: 60,
        fontSize: Metrics.normalize(20),
        color: Colors.defaultTextColor,
        fontWeight: '400',
        fontFamily: Fonts.type.base,
        flex: 1,
    },
    hintText: {
        fontSize: Metrics.normalize(12),
        fontWeight: '300',
        fontFamily: Fonts.type.base,
        backgroundColor: 'transparent',
        color: Colors.defaultTextColor,
        marginRight: 10,
    },
    countryContainer: {
        height: 60,
        flexDirection: 'row',
        padding: 8,
        alignItems: 'center',
        justifyContent: 'center',
    },
    countryIcon: {
        width: 20,
        height: 20,
    },
    countryArrow: {
        width: 6,
        height: 6,
        marginHorizontal: 6,
    },
    countryText: {
        marginBottom: Metrics.normalize(1),
        fontSize: Metrics.normalize(20),
        color: Colors.defaultTextColor,
        fontWeight: '400',
        fontFamily: Fonts.type.base,
    },
    correctInputImage: {
        width: 22,
        height: 22,
    },
    passwordRecoveryPossible: {
        color: Colors.darkGreyTextColor,
        marginTop: 10,
        fontSize: Metrics.normalize(16),
    },
    createAccountButton: {
        marginVertical: 5,
        fontSize: Metrics.normalize(14),
        fontWeight: '500',
        fontFamily: Fonts.type.base,
        color: Colors.black,
    },
    curbRegisterContainer: { flex: 1, paddingHorizontal: 20 },
});

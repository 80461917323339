import { StyleSheet, Platform } from 'react-native';
import { Fonts, Metrics, Colors } from '../../../themes';

export default StyleSheet.create({
    container: {
        position: 'absolute',
        top: Metrics.paddingTop + (Platform.OS === 'ios' ? 65 : 40),
        left: 0,
        right: 0,
        bottom: 0,
    },
    listContainer: {
        marginHorizontal: 20,
        borderRadius: 6,
        backgroundColor: 'white',
        shadowColor: 'black',
        shadowRadius: 5,
        shadowOpacity: 0.1,
        shadowOffset: { width: 0, height: 0 },
        elevation: 2,
    },
    addressLocationContainer: {
        flex: 1,
        flexDirection: 'row',
        paddingRight: 10,
    },
    locationContainer: {
        flex: 1,
        justifyContent: 'center',
    },
    addressInputView: {
        flexDirection: 'row',
        paddingRight: 10,
    },
    cancelContainer: {
        justifyContent: 'center',
        alignItems: 'center',
    },
    labelText: {
        fontSize: 10,
        color: Colors.grayTextColor,
        fontFamily: Fonts.type.base,
        fontWeight: '500',
    },
    locationText: {
        fontSize: 15,
        color: Colors.black,
        fontFamily: Fonts.type.base,
        fontWeight: '500',
        paddingVertical: 0,
        marginVertical: 0,
    },
    addressContainer: {
        flexDirection: 'row',
        height: 60,
    },
    addressLeftContainer: {
        width: 60,
        alignItems: 'center',
        justifyContent: 'center',
    },
    addressDot: {
        width: 8,
        height: 8,
        borderRadius: 4,
        backgroundColor: Colors.grayTextColor,
    },
    cancelIcon: {
        width: 12,
        height: 12,
        opacity: 0.5,
    },
    addressDotOuter: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
});

import { StyleSheet } from 'react-native';
import { Fonts, Metrics, Colors } from '../../../themes';

export default StyleSheet.create({
    container: {
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
        position: 'absolute',
    },
    rideHeaderText: {
        fontFamily: Fonts.type.base,
        fontWeight: '800',
        fontSize: Metrics.normalize(25),
        color: '#2D2D2D',
    },
    separator: {
        position: 'absolute',
        bottom: 0,
        right: 15,
        left: 15,
        backgroundColor: Colors.grayTextColor,
        height: 1,
        opacity: 0.2,
    },
    statusHeader: {
        paddingHorizontal: 20,
        backgroundColor: Colors.white,
        paddingBottom: 15,
    },
    mapView: {
        flex: 1,
    },
    smallMapView: {
        height: Metrics.screenHeight - 230,
    },
    landingMapView: {
        height: Metrics.screenHeight - 180,
    },
    marker: {
        width: 12,
        height: 12,
        marginTop: 6,
        marginLeft: 6,
        borderRadius: 6,
        backgroundColor: Colors.obiTeal,
    },
    centerMapContainer: {
        position: 'absolute',
        bottom: 4,
        right: 8,
        shadowColor: 'black',
        shadowRadius: 5,
        shadowOpacity: 0.2,
        shadowOffset: { width: 0, height: 0 },
    },
    centerMapIcon: {
        width: 40,
        height: 40,
    },
    whiteGradient: {
        position: 'absolute',
        bottom: 0,
        right: 0,
        left: 0,
        height: Metrics.screenHeight / 3,
        opacity: 0.8,
    },
    rideOptionsButton: {
        position: 'absolute',
        bottom: 20,
        left: 40,
        right: 40,
    },
    cancelRequestView: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: 'white',
        borderTopLeftRadius: 16,
        borderTopRightRadius: 16,
        shadowColor: '#000000',
        shadowOffset: { width: 0, height: 0 },
        shadowRadius: 4,
        shadowOpacity: 0.2,
        paddingTop: 20,
        alignSelf: 'stretch',
        paddingHorizontal: 20,
        paddingBottom: Metrics.paddingBottom + 20,
    },
    animationContainer: {
        ...StyleSheet.absoluteFillObject,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'transparent',
    },
    lottieViewContainer: {
        width: Metrics.screenWidth - 20,
        height: Metrics.screenWidth - 20,
        // flex: 1,
    },
    lottieView: {
        width: Metrics.screenWidth - 20,
        height: Metrics.screenWidth - 20,
    },
    navigationBar: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
    },
});

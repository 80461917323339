/* eslint-disable no-empty */
/* eslint-disable camelcase */
import * as Localize from 'react-native-localize';
import { observable, action } from 'mobx';
import { Platform, Linking } from 'react-native';
import { AsYouType, isValidNumber, parse, getPhoneCode } from 'libphonenumber-js';

import DeviceInfo from 'modules/deviceInfo/deviceInfo';
import LyftClient from 'services/providers/LyftClient';
import ServicesStore from '../ServicesStore';
import countryCodes from '../../lib/flags';
import { uuidv4 } from '../../services/Utils';
import { Analytics } from '../../lib';

type LyftView = 'PHONE' | 'PHONE_OTP' | 'CREDENTIALS' | 'NAME' | 'EMAIL' | 'DRIVER_LICENSE';

export default class LyftServiceStore {
    servicesRootStore: ServicesStore;

    lyftClient: LyftClient;

    constructor(servicesStore: ServicesStore) {
        this.servicesRootStore = servicesStore;
        this.lyftClient = new LyftClient();
        this.getCarrierInfo();
    }

    @observable activeView: LyftView = 'PHONE';

    @observable phoneNumber: string = '';

    @observable countryCode: any = '1';

    @observable countryName: any = 'us';

    @observable asYouType: AsYouType = new AsYouType(this.countryName.toUpperCase());

    @observable validPhoneNumber: boolean = false;

    @observable OTP = '';

    @observable firstName: string = '';

    @observable lastName: string = '';

    @observable email: string = '';

    @observable driverLicense: string = '';

    @observable termsAccepted: boolean = false;

    @observable errorMessage = '';

    phoneId = DeviceInfo.getUniqueId();

    userId = '';

    sessionUUID = '';

    @action
    clearStore = () => {
        const { setRefreshing } = this.servicesRootStore;
        this.getCarrierInfo();
        this.activeView = 'PHONE';
        this.phoneNumber = '';
        this.validPhoneNumber = false;
        this.OTP = '';
        this.firstName = '';
        this.lastName = '';
        this.email = '';
        this.driverLicense = '';
        this.termsAccepted = false;
        this.errorMessage = '';
        setRefreshing(false);
    };

    @action
    clearError = () => {
        this.errorMessage = '';
    };

    @action
    getCarrierInfo = () => {
        let countryCode = 'us';
        try {
            countryCode = Localize.getCountry();
        } catch (error) {}
        const codes = countryCodes.filter(
            item => item.code.toLowerCase() === countryCode.toLowerCase()
        );
        if (codes.length > 0) {
            this.countryName = codes[0].code.toLowerCase();
            this.countryCode = codes[0].dial_code.replace(/\D/g, '');

            this.asYouType = new AsYouType(this.countryName.toUpperCase());
        }
    };

    @action
    initializeLyftClient = () => {
        this.userId = this.servicesRootStore.rootStore.stores.authStore.user.user_id;
        this.sessionUUID = uuidv4();
        this.lyftClient
            .initializeLyftConnection({
                userId: this.userId,
                phoneId: DeviceInfo.getUniqueId(),
                sessionId: this.sessionUUID,
            })
            .then(this.processLyftResponse('init'))
            .catch(this.processLyftError('init'));
    };

    @action
    selectCountry = country => {
        this.countryCode = country.dial_code.replace(/\D/g, '');
        this.countryName = country.code.toLowerCase();
        this.asYouType = new AsYouType(this.countryName.toUpperCase());
        this.clearError();
    };

    @action
    setLyftPhoneNumber = (text: string) => {
        this.asYouType.reset();
        if (this.phoneNumber.length - text.length === 1 && this.phoneNumber.slice(-1) === ')') {
            this.phoneNumber = text.replace('(', '');
        } else {
            this.phoneNumber = this.asYouType.input(text);
        }
        this.validPhoneNumber = isValidNumber(text, this.countryName.toUpperCase());
        this.clearError();
    };

    lyftRawPhoneNumber = () => {
        if (this.phoneNumber.indexOf('+') >= 0) {
            const numberInfo = parse(this.phoneNumber);
            if (numberInfo && numberInfo.country) {
                const countryCode = getPhoneCode(numberInfo.country);
                this.countryCode = countryCode;
                return this.phoneNumber.replace(`+${countryCode}`, '').replace(/\D/g, '');
            }
        }
        return this.phoneNumber.replace(/\D/g, '');
    };

    @action
    onLyftPhoneNumberSubmit = () => {
        const { setRefreshing } = this.servicesRootStore;
        setRefreshing(true);
        this.lyftClient
            .sendPhone({
                phone: `+${this.countryCode}${this.lyftRawPhoneNumber()}`,
            })
            .then(this.processLyftResponse('PHONE_NUMBER'))
            .catch(this.processLyftError('PHONE_NUMBER'));
    };

    @action
    setLyftOTP = (text: string) => {
        this.OTP = text;
        this.clearError();
    };

    @action
    onLyftOTPSubmit = () => {
        const { setRefreshing } = this.servicesRootStore;
        setRefreshing(true);
        this.lyftClient
            .sendOTP({
                otp: this.OTP,
                phone: `+${this.countryCode}${this.lyftRawPhoneNumber()}`,
            })
            .then(this.processLyftResponse('PHONE_SMS_OTP'))
            .catch(this.processLyftError('PHONE_SMS_OTP'));
    };

    @action
    retryLyftOTPSubmitWithAcceptedUpdatedTerms = () => {
        const { setRefreshing } = this.servicesRootStore;
        setRefreshing(true);
        this.lyftClient
            .sendOTPWithUpdatedTerms({
                otp: this.OTP,
                phone: `+${this.countryCode}${this.lyftRawPhoneNumber()}`,
            })
            .then(this.processLyftResponse('PHONE_SMS_OTP'))
            .catch(this.processLyftError('PHONE_SMS_OTP'));
    };

    @action
    setLyftEmail = (text: string) => {
        this.email = text;
        this.clearError();
    };

    @action
    onLyftEmailSubmit = () => {
        const { setRefreshing } = this.servicesRootStore;
        setRefreshing(true);

        this.lyftClient
            .sendEmail({
                email: this.email,
                phone: `+${this.countryCode}${this.lyftRawPhoneNumber()}`,
                otp: this.OTP,
            })
            .then(this.processLyftResponse('EMAIL'))
            .catch(this.processLyftError('EMAIL'));
    };

    @action
    setLyftFirstName = (text: string) => {
        this.firstName = text;
        this.clearError();
    };

    @action
    setLyftLastName = (text: string) => {
        this.lastName = text;
        this.clearError();
    };

    @action
    setLyftTermsAccepted = () => {
        this.termsAccepted = true;
    };

    @action
    onLyftCredentialsSubmit = () => {
        const { setRefreshing } = this.servicesRootStore;
        setRefreshing(true);

        this.lyftClient
            .initializeLyftConnection({
                userId: this.userId,
                phoneId: DeviceInfo.getUniqueId(),
                sessionId: this.sessionUUID,
            })
            .then(() => {
                this.lyftClient
                    .sendCredentials({
                        email: this.email,
                        firstName: this.firstName,
                        lastName: this.lastName,
                        phone: `+${this.countryCode}${this.lyftRawPhoneNumber()}`,
                        otp: this.OTP,
                    })
                    .then(this.processLyftResponse('CREDENTIALS'))
                    .catch(this.processLyftError('CREDENTIALS'));
            })
            .catch(this.processLyftError('init'));
    };

    @action
    onLyftNameSubmit = () => {
        const { setRefreshing } = this.servicesRootStore;
        setRefreshing(true);

        this.lyftClient
            .sendName({
                otp: this.OTP,
                phone: `+${this.countryCode}${this.lyftRawPhoneNumber()}`,
                name: this.firstName,
                lastName: this.lastName,
            })
            .then(this.processLyftResponse('NAME'))
            .catch(this.processLyftError('NAME'));
    };

    @action
    setLyftDriverLicense = (text: string) => {
        this.driverLicense = text;
        this.clearError();
    };

    @action
    onLyftDriverLicenseSubmit = () => {
        const { setRefreshing } = this.servicesRootStore;
        setRefreshing(true);

        this.lyftClient
            .sendDriversLicense({
                otp: this.OTP,
                phone: `+${this.countryCode}${this.lyftRawPhoneNumber()}`,
                name: this.firstName,
                lastName: this.lastName,
                driversLiceseNumber: this.driverLicense,
                email: this.email,
            })
            .then(this.processLyftResponse('DRIVER_LICENSE'))
            .catch(this.processLyftError('DRIVER_LICENSE'));
    };

    @action
    processLyftResponse = (fieldName: string) => res => {
        const { setRefreshing } = this.servicesRootStore;
        setRefreshing(false);

        if (fieldName === 'PHONE_NUMBER') {
            this.setNextLyftView('PHONE_OTP');
        }

        const { data } = res;
        if (data) {
            const { user_id, access_token, expires_in, refresh_token } = data;
            if (user_id && access_token && refresh_token) {
                this.servicesRootStore.handleLyftLogin({
                    access_token,
                    expires_in,
                    refresh_token,
                    session_id: this.sessionUUID,
                    session: {
                        f: user_id,
                        a: this.phoneId,
                        h: true,
                    },
                });

                if (fieldName === 'CREDENTIALS') {
                    Analytics.trackCreateProviderAccount('Lyft');
                }
            }
        }
    };

    @action
    processLyftError = (fieldName: string) => errorResponse => {
        const { setRefreshing } = this.servicesRootStore;
        setRefreshing(false);
        const {
            response: {
                data: { error_description, error, challenges },
                status,
            },
        } = errorResponse;
        console.log('err', { ...errorResponse });
        if (status === 401 && error === 'challenge_required') {
            if (challenges.length) {
                const challengeType = challenges[0].identifier;
                if (challengeType === 'enter_name') {
                    this.setNextLyftView('NAME');
                    this.errorMessage = error_description;
                } else if (challengeType === 'email_match') {
                    this.setNextLyftView('EMAIL');
                    this.errorMessage = error_description;
                } else if (challengeType === 'drivers_license_number') {
                    this.setNextLyftView('DRIVER_LICENSE');
                    this.errorMessage = error_description;
                } else {
                    this.errorMessage = error_description;
                }
            } else {
                this.errorMessage = error_description;
            }
        } else if (error === 'terms_consent_required' && fieldName === 'PHONE_SMS_OTP') {
            this.retryLyftOTPSubmitWithAcceptedUpdatedTerms();
        } else if (status === 422) {
            this.setNextLyftView('CREDENTIALS');
        } else {
            this.errorMessage = error_description;
        }
    };

    @action
    setNextLyftView = (lyftNextView: LyftView) => {
        const {
            rootStore: {
                stores: {
                    navigationStore: { navigate },
                },
            },
        } = this.servicesRootStore;
        this.clearError();
        if (this.activeView === lyftNextView) {
            return;
        }
        this.activeView = lyftNextView;
        switch (lyftNextView) {
            case 'PHONE_OTP':
                navigate('OTP');
                break;
            case 'NAME':
                navigate('Name');
                break;
            case 'EMAIL':
                navigate('Email');
                break;
            case 'DRIVER_LICENSE':
                navigate('DriverLicense');
                break;
            case 'CREDENTIALS':
                navigate('Credentials');
                break;
            default:
                break;
        }
    };

    downloadApp = () => {
        Linking.openURL(
            Platform.OS === 'android'
                ? 'https://play.google.com/store/apps/details?id=me.lyft.android'
                : 'https://apps.apple.com/app/lyft/id529379082'
        )
            .then(() => {
                Analytics.trackServiceLinking('lyft', 'connecting', 'download_app');
            })
            .catch(() => {});
    };
}

import React from 'react';
import { TouchableOpacity, View, Image, Text } from 'react-native';
import { Images } from '../../../../themes';

import ServiceStyles from './ServiceStyles';
import { Localizable } from '../../../../lib';
import { ServiceType } from '../../../../stores/ServicesStore';
import { providerInfo } from 'services/Utils';

interface Props {
    item: ServiceType;
    index: number;
    handleClick: (item: ServiceType) => void;
}

const ServiceItem = ({ item, index, handleClick }: Props) => {
    const {
        connected,
        backgroundColor: backgroundColorNotLinked,
        backgroundColorLinked,
        logo,
        logoLinked,
        name,
    } = item;
    const backgroundColor = connected ? backgroundColorLinked : backgroundColorNotLinked;
    return (
        <TouchableOpacity
            key={`${item.name}${item.connected}${index}`}
            onPress={() => handleClick(item)}
        >
            <View style={[ServiceStyles.container, { backgroundColor }]}>
                <View style={ServiceStyles.innerContainer}>
                    <View style={[ServiceStyles.imageView, { backgroundColor }]}>
                        <Image
                            resizeMode="contain"
                            style={ServiceStyles.image}
                            source={connected ? logoLinked : logo}
                        />
                    </View>
                    <View style={ServiceStyles.textView}>
                        <Text style={ServiceStyles.nameText}>{providerInfo(name).name}</Text>
                        <Text style={ServiceStyles.serviceText}>
                            {Localizable.t('services.privateRidesharingService')}
                        </Text>
                    </View>
                </View>
                <View style={ServiceStyles.rightContainer}>
                    <Image
                        resizeMode="contain"
                        source={connected ? Images.services.check : Images.services.plus}
                        style={ServiceStyles.rightContainerImage}
                    />
                </View>
            </View>
        </TouchableOpacity>
    );
};
export default ServiceItem;

import { StyleSheet } from 'react-native';
import { Fonts, Metrics } from '../../themes';

export default StyleSheet.create({
    innerContainer: {
        alignItems: 'center',
        justifyContent: 'center',
        height: 48,
        borderRadius: 8,
        shadowColor: 'black',
        shadowRadius: 10,
        shadowOpacity: 0.2,
        shadowOffset: { width: 0, height: 0 },
        elevation: 2,
        paddingHorizontal: 20,
    },
    titleText: {
        textAlign: 'center',
        backgroundColor: 'transparent',
        fontFamily: Fonts.type.base,
        fontWeight: '500',
        fontSize: Metrics.normalize(16),
    },
});

/* eslint-disable no-param-reassign */
import { RefObject, createRef } from 'react';
import { Easing, Animated, ImageSourcePropType, ScrollView, LayoutAnimation } from 'react-native';
import { observable, action } from 'mobx';

import BaseStore from './BaseStore';
import { Images } from '../themes';
import { BikeStates } from './BikeStore';
import { Analytics } from 'lib';

export type Vehicle = 'Bikes' | 'Scooters' | 'Rides';

export interface ModeType {
    name: Vehicle;
    enabled: boolean;
    image: ImageSourcePropType;
}

export type OptionTab = 'providers' | 'options';

export default class OptionsStore extends BaseStore {
    @observable.ref
    tabScroll: RefObject<ScrollView> = createRef();

    @observable
    optionsOpened: boolean = false;

    @observable
    modes: Array<ModeType> = [
        {
            name: 'Bikes',
            image: Images.options.modeBikes,
            enabled: this.rootStore.stores.bikeStore.bikeState === BikeStates.on,
        },
        {
            name: 'Scooters',
            image: Images.options.modeScooters,
            enabled: this.rootStore.stores.bikeStore.scooterState === BikeStates.on,
        },
        {
            name: 'Rides',
            image: Images.options.modeRides,
            enabled: this.rootStore.stores.taxiStore.ridesEnabled,
        },
    ];

    @observable
    selectedTab: OptionTab = 'providers';

    @action
    toggleOptionsOpened = () => {
        const { taxiStore } = this.rootStore.stores;
        this.optionsOpened = !this.optionsOpened;
        if (this.optionsOpened) {
            Analytics.trackScreenView(
                taxiStore.taxiState === 'taxis' ? '02-03_Options' : '02_Options'
            );
        }
    };

    @action
    toggleModeEnabled = (vehicles: Vehicle) => {
        const {
            stores: { bikeStore, taxiStore },
        } = this.rootStore;
        const modeListIndex = this.modes.findIndex(mode => mode.name === vehicles);
        this.modes[modeListIndex].enabled = !this.modes[modeListIndex].enabled;
        switch (vehicles) {
            case 'Bikes':
                bikeStore.toggleBikesEnabled();
                break;
            case 'Rides':
                taxiStore.toggleRidesEnabled();
                break;
            case 'Scooters':
                bikeStore.toggleScootersEnabled();
                break;
            default:
                break;
        }
    };

    @action
    toggleAllModesEnabled = () => {
        const {
            stores: { bikeStore },
        } = this.rootStore;
        this.modes.forEach(mode => {
            mode.enabled = true;
        });
        bikeStore.toggleBikesEnabled();
    };

    @action
    onOptionTabClick = (tab: OptionTab) => {
        const { layoutStore } = this.rootStore.stores;
        if (this.tabScroll.current) {
            switch (tab) {
                case 'providers':
                    this.tabScroll.current.scrollTo({ x: 0, animated: true });
                    break;
                case 'options':
                    this.tabScroll.current.scrollTo({ x: layoutStore.screenWidth, animated: true });
                    break;
                default:
                    break;
            }
        }
    };

    @action
    selectOptionTab = (tab: OptionTab) => {
        LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
        this.selectedTab = tab;
    };
}

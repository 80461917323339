/* eslint-disable global-require */
import Localizable from 'react-native-i18n';

Localizable.fallbacks = true;
Localizable.defaultLocale = 'en';

Localizable.translations = {
    en: require('./languages/en.json'),
};

export default Localizable;

import { StyleSheet } from 'react-native';
import { Colors, Fonts, Metrics } from '../../../themes';

export default StyleSheet.create({
    container: {
        position: 'absolute',
        right: Metrics.screenWidth / 3,
        top: Metrics.paddingTop + 15,
    },
    statusText: {
        color: Colors.obiTeal,
        fontFamily: Fonts.type.base,
        fontSize: 18,
        backgroundColor: 'transparent',
    },
});

import { StyleSheet, Platform } from 'react-native';
import { Fonts, Metrics, Colors } from '../../../themes';

export default StyleSheet.create({
    container: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
    },
    ridePopupContainer: {
        position: 'absolute',
        left: 0,
        right: 0,
        alignItems: 'center',
        justifyContent: 'center',
    },
    interactableOuterContainer: {
        backgroundColor: Colors.transparent,
    },
    interactableContainer: {
        backgroundColor: 'white',
        borderTopLeftRadius: 16,
        borderTopRightRadius: 16,
        shadowColor: '#000000',
        shadowOffset: { width: 0, height: 0 },
        shadowRadius: 4,
        shadowOpacity: 0.2,
        elevation: 20,
    },
    innerContainer: {
        width: Metrics.screenWidth,
    },
    drawerIndicator: {
        marginHorizontal: 16,
        marginVertical: 7,
        alignSelf: 'center',
        backgroundColor: 'white',
        height: 5,
        width: 45,
        borderRadius: 5,
        shadowColor: '#000000',
        shadowOffset: { width: 0, height: 0 },
        shadowRadius: 4,
        shadowOpacity: 0.2,
        elevation: 20,
    },
    centerMapButton: {
        alignItems: 'flex-end',
        position: 'absolute',
        right: 10,
    },
    destinationAnimatedView: {
        height: 75,
        flexDirection: 'row',
        alignItems: 'center',
        alignSelf: 'stretch',
        justifyContent: 'center',
        padding: 10,
    },
    destinationButton: {
        flex: 1,
        alignSelf: 'stretch',
        backgroundColor: Colors.darkerGrayBackgroundColor,
        borderRadius: 10,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        paddingLeft: 25,
    },
    destinationButtonIcon: {
        width: 16,
        height: 16,
    },
    destinationButtonText: {
        fontFamily: Fonts.type.base,
        fontSize: Metrics.normalize(18),
        fontWeight: '400',
        color: Colors.darkGreyTextColor,
        marginLeft: 30,
    },
    favoriteAddressButtonSeparator: {
        width: 2,
        alignSelf: 'stretch',
        marginVertical: 8,
        backgroundColor: Colors.graySeparatorColor,
    },
    favoriteLocationView: {
        flex: 1,
        alignSelf: 'stretch',
        height: 65,
        marginHorizontal: 10,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'white',
        shadowColor: '#000000',
        shadowOffset: { width: 0, height: 0 },
        shadowRadius: 4,
        shadowOpacity: 0.1,
        elevation: 5,
        marginBottom: 15,
    },
    favoriteAddressButton: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        paddingVertical: 10,
        paddingHorizontal: 15,
        overflow: 'hidden',
        marginRight: 5,
    },
    favoriteAddressButtonIcon: {
        width: 36,
        height: 36,
        marginRight: 15,
    },
    favoriteLocationTextView: {
        alignItems: 'flex-start',
        justifyContent: 'center',
        backgroundColor: Colors.transparent,
    },
    favoriteLocationTitle: {
        fontFamily: Fonts.type.base,
        fontSize: Metrics.normalize(14),
        fontWeight: '400',
        color: Colors.black,
    },
    favoriteLocationText: {
        fontFamily: Fonts.type.base,
        fontSize: Metrics.normalize(14),
        fontWeight: '400',
        color: Colors.grayTextColor,
    },
    suggestionListTitle: {
        fontFamily: Fonts.type.base,
        fontSize: Metrics.normalize(13),
        fontWeight: '400',
        color: Colors.grayTextColor,
        paddingHorizontal: Platform.select({ web: 25, default: 12.5 }),
    },
    animatedAddressPicker: {
        marginTop: Platform.OS === 'android' ? 15 : 60,
        backgroundColor: 'white',
        alignItems: 'stretch',
        paddingHorizontal: 10,
    },
    keyboardStickyView: {
        backgroundColor: 'white',
        position: 'absolute',
        left: 0,
        right: 0,
        alignSelf: 'stretch',
        flex: 1,
        flexDirection: 'row',
        paddingTop: 10,
        alignItems: 'center',
        justifyContent: 'space-evenly',
        borderTopColor: Colors.darkerGrayBackgroundColor,
        borderTopWidth: 1,
    },
    keyboardStickyViewButton: {
        flexDirection: 'row',
        borderWidth: 1,
        borderColor: Colors.darkerGrayBackgroundColor,
        borderRadius: 40,
        paddingVertical: 10,
        paddingHorizontal: 20,
        alignItems: 'center',
        justifyContent: 'center',
    },
    keyboardStickyViewButtonIcon: {
        width: 16,
        height: 16,
        marginRight: 10,
    },
    keyboardStickyViewButtonText: {
        fontFamily: Fonts.type.base,
        fontSize: Metrics.normalize(14),
        fontWeight: '500',
        color: Colors.black,
    },
});

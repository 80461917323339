import React, { Component } from 'react';
import { View, Text, TextInput, Linking, TouchableOpacity } from 'react-native';
import { observer, inject } from 'mobx-react';
import KeyboardAwareScrollView from 'modules/keyboardAwareScrollView/keyboardAwareScrollView';

import AdditionalInfoStyles from '../../styles/AdditionalInfoStyles';
import ServicesStore from '../../../../stores/ServicesStore';
import TitleNavigationBar from '../../../navigationBars/TitleNavigationBar';
import { Colors, Fonts } from '../../../../themes';
import { Localizable, Analytics } from '../../../../lib';
import { NextButton, ErrorMessage, ClickableInfoLabel } from '../../providerConnectingComponents';
import { Stores } from '../../../../stores/RootStore';
import { ClickableInfoChildProps } from '../../providerConnectingComponents/clickableInfoLabel/ClickableInfoLabel';

const clickableInfoLabelContent: Array<ClickableInfoChildProps> = [
    {
        text: Localizable.t('additionalInfo.lyft.accept'),
        clickableText: Localizable.t('additionalInfo.lyft.termsOfService'),
        clickableTextStyle: {
            fontWeight: '500',
            color: '#FE00BF',
            fontFamily: Fonts.type.base,
        },
        handleClickableTextClick: () =>
            Linking.openURL('https://s3.amazonaws.com/api.lyft.com/static/terms.html'),
    },
];

interface Props {
    servicesStore: ServicesStore;
}

@inject(({ servicesStore }: Stores) => ({ servicesStore }))
@observer
export default class Credentials extends Component<Props> {
    lastNameInputRef: React.RefObject<TextInput> = React.createRef();

    emailInputRef: React.RefObject<TextInput> = React.createRef();

    lastNameInputFocus = () => {
        if (this.lastNameInputRef && this.lastNameInputRef.current) {
            this.lastNameInputRef.current.focus();
        }
    };

    focusEmailPasswordRef = () => {
        if (this.emailInputRef && this.emailInputRef.current) {
            this.emailInputRef.current.focus();
        }
    };

    componentDidMount() {
        Analytics.trackServiceLinking('lyft', 'registration', 'credentials_screen');
    }

    render() {
        const {
            servicesStore: {
                hideProviderPopup,
                lyftServiceStore: {
                    errorMessage,
                    email,
                    firstName,
                    lastName,
                    setLyftEmail,
                    setLyftFirstName,
                    setLyftLastName,
                    onLyftCredentialsSubmit,
                },
            },
        } = this.props;
        return (
            <View style={AdditionalInfoStyles.container}>
                <TitleNavigationBar title="Lyft" handleBackClick={() => hideProviderPopup()} />
                <KeyboardAwareScrollView
                    contentContainerStyle={{ paddingHorizontal: 20 }}
                    keyboardShouldPersistTaps="handled"
                >
                    <Text style={AdditionalInfoStyles.title}>
                        {Localizable.t('additionalInfo.enterCredentials')}
                    </Text>

                    <Text style={AdditionalInfoStyles.description}>
                        {Localizable.t('additionalInfo.placeholderFirstname')}
                    </Text>
                    <View style={AdditionalInfoStyles.inputBorder}>
                        <TextInput
                            autoCapitalize="words"
                            autoCorrect={false}
                            autoFocus
                            keyboardType="email-address"
                            onChangeText={setLyftFirstName}
                            onSubmitEditing={this.lastNameInputFocus}
                            placeholder={Localizable.t('additionalInfo.placeholderFirstname')}
                            placeholderTextColor={Colors.lightGrayColor}
                            returnKeyType="next"
                            selectionColor={Colors.obiTeal}
                            style={[
                                AdditionalInfoStyles.textInput,
                                {
                                    fontWeight: firstName.length ? '300' : '100',
                                },
                            ]}
                            value={firstName}
                        />
                    </View>
                    <Text style={AdditionalInfoStyles.description}>
                        {Localizable.t('additionalInfo.placeholderLastname')}
                    </Text>
                    <View style={AdditionalInfoStyles.inputBorder}>
                        <TextInput
                            autoCapitalize="words"
                            autoCorrect={false}
                            keyboardType="email-address"
                            onChangeText={setLyftLastName}
                            onSubmitEditing={this.focusEmailPasswordRef}
                            placeholder={Localizable.t('additionalInfo.placeholderLastname')}
                            placeholderTextColor={Colors.lightGrayColor}
                            ref={this.lastNameInputRef}
                            returnKeyType="next"
                            selectionColor={Colors.obiTeal}
                            style={[
                                AdditionalInfoStyles.textInput,
                                {
                                    fontWeight: lastName.length ? '300' : '100',
                                },
                            ]}
                            value={lastName}
                        />
                    </View>
                    <Text style={AdditionalInfoStyles.description}>
                        {Localizable.t('additionalInfo.placeholderEmail')}
                    </Text>
                    <View style={AdditionalInfoStyles.inputBorder}>
                        <TextInput
                            autoCapitalize="none"
                            autoCorrect={false}
                            keyboardType="email-address"
                            onChangeText={setLyftEmail}
                            onSubmitEditing={onLyftCredentialsSubmit}
                            placeholder={Localizable.t('additionalInfo.placeholderEmail')}
                            placeholderTextColor={Colors.lightGrayColor}
                            ref={this.emailInputRef}
                            returnKeyType="done"
                            selectionColor={Colors.obiTeal}
                            style={[
                                AdditionalInfoStyles.textInput,
                                {
                                    fontWeight: email.length ? '300' : '100',
                                },
                            ]}
                            value={email}
                        />
                    </View>

                    <ClickableInfoLabel childrenProps={clickableInfoLabelContent} />
                    {errorMessage.length !== 0 && <ErrorMessage message={errorMessage} />}
                    <NextButton onPress={onLyftCredentialsSubmit} />
                </KeyboardAwareScrollView>
            </View>
        );
    }
}

/* eslint-disable no-throw-literal */
/* eslint-disable camelcase */
import * as RNLocalize from 'react-native-localize';
import axios from 'axios';
import base64 from 'react-native-base64';
import { Platform } from 'react-native';

import DeviceInfo from 'modules/deviceInfo/deviceInfo';
import LyftConnectionCredentials from 'domain/services/Lyft/LyftConnectionCredentials';
import LyftCredentials from 'domain/services/Lyft/LyftCredentials';
import proxy from '../Proxy';

const instance = axios.create({
    baseURL: `https://api.lyft.com/`,
    timeout: 40000,
});

const identifiers = base64.encode(
    JSON.stringify([
        {
            type: 'android_backup_token',
            source: 'lyft_android_app',
            name: 'ccb9fbed-50d7-41f6-8796-95c8e1e0f193',
        },
    ])
);

const getParamsFromObject = paramsObj => {
    return Object.entries(paramsObj)
        .map(([key, value]) => {
            const encodedKey = encodeURIComponent(key);
            const encodedValue = encodeURIComponent(value);
            return `${encodedKey}=${encodedValue}`;
        })
        .join('&');
};

class LyftClient {
    access_token = '';

    userId = '';

    phoneId = '';

    xSession = '';

    sessionUUID = '';

    constructor() {
        instance.interceptors.request.use(request => {
            console.log('[LyftClient] Starting Request:', { ...request });
            return proxy(request);
        });
        instance.interceptors.response.use(
            response => {
                console.log('[LyftClient] Response:', { ...response });
                return response;
            },
            error => {
                console.log('[LyftClient] Response error:', { ...error });
                return Promise.reject(error);
            }
        );
    }

    userDevice = async () => {
        const str = await DeviceInfo.getManufacturer();
        const str2 = DeviceInfo.getDeviceId();
        if (str2.toLowerCase().startsWith(str.toLowerCase())) {
            return str2;
        }
        return `${str} ${str2}`;
    };

    getCommonHeaders = () => ({
        'User-Device': this.userDevice(),
        Accept: 'application/json',
        'X-Session': this.xSession,
        'X-Locale-Region': RNLocalize.getCountry(),
        'X-Device-Density': '560',
        'X-Design-ID': 'X',
        'Accept-Language': RNLocalize.getLocales()[0].languageCode,
        'X-Locale-Language': RNLocalize.getLocales()[0].languageCode,
        'X-Client-Session-ID': this.sessionUUID,
        'User-Agent': 'lyft:android:8.1.0:6.3.3.1572623106',
        'X-Carrier': Platform.OS === 'android' ? 'Android' : 'iOS',
    });

    getHeadersInit = () => {
        return {
            Authorization: 'Basic ZVNhdDctaXU5ZG9NOlp0dkxEejBuMS1rSlZ3a0l2eEM0aVNKMHlNdkp5ZFBx',
            'Content-Type': 'application/x-www-form-urlencoded',
            ...this.getCommonHeaders(),
        };
    };

    getHeadersBearer = () => {
        return {
            Authorization: `Bearer ${this.access_token}`,
            'Content-Type': 'application/json; charset=utf-8',
            ...this.getCommonHeaders(),
        };
    };

    initializeLyftConnection = ({ userId, phoneId, sessionId }: LyftConnectionCredentials) => {
        this.userId = userId;
        this.phoneId = phoneId;
        this.sessionUUID = sessionId;
        this.xSession = base64.encode(
            JSON.stringify({
                f: userId,
                a: phoneId,
                h: true,
            })
        );
        return instance
            .post('/oauth2/access_token', 'grant_type=client_credentials', {
                headers: this.getHeadersInit(),
            })
            .then(res => {
                if (res && res.data && res.data.access_token) {
                    this.access_token = res.data.access_token;
                    return res;
                }
                throw 'No access token';
            });
    };

    sendPhone = ({ phone }: LyftCredentials) => {
        return instance.post(
            '/v1/phoneauth',
            {
                phone_number: phone,
                voice_verification: false,
                message_format: 'sms_basic',
                client_configuration: 'release',
            },
            {
                headers: this.getHeadersBearer(),
            }
        );
    };

    sendOTP = ({ otp, phone }: LyftCredentials) => {
        const paramsObj = {
            grant_type: 'urn:lyft:oauth2:grant_type:phone',
            phone_number: phone,
            phone_code: otp,
            identifiers,
        };
        const params = getParamsFromObject(paramsObj);
        return instance.post('/oauth2/access_token', params, {
            headers: this.getHeadersInit(),
        });
    };

    sendOTPWithUpdatedTerms = ({ otp, phone }: LyftCredentials) => {
        const paramsObj = {
            grant_type: 'urn:lyft:oauth2:grant_type:phone',
            phone_number: phone,
            phone_code: otp,
            identifiers,
            terms_url: 'https://s3.amazonaws.com/api.lyft.com/static/terms.html',
            terms_context_description: 'Acceptance of Lyft TOS with reconsent from Lyft Rider App',
            terms_ui_style: 'SCROLLWRAP',
        };
        const params = getParamsFromObject(paramsObj);
        return instance.post('/oauth2/access_token', params, {
            headers: this.getHeadersInit(),
        });
    };

    sendName = ({ name, lastName, phone, otp }: LyftCredentials) => {
        const paramsObj = {
            grant_type: 'urn:lyft:oauth2:grant_type:phone',
            phone_number: phone,
            phone_code: otp,
            username: `${name} ${lastName}`,
            identifiers,
        };
        const params = getParamsFromObject(paramsObj);
        return instance.post('/oauth2/access_token', params, {
            headers: this.getHeadersInit(),
        });
    };

    sendEmail = ({ email, phone, otp }: LyftCredentials) => {
        const paramsObj = {
            grant_type: 'urn:lyft:oauth2:grant_type:phone',
            phone_number: phone,
            phone_code: otp,
            email: `${email}`,
            identifiers,
        };
        const params = getParamsFromObject(paramsObj);
        return instance.post('/oauth2/access_token', params, {
            headers: this.getHeadersInit(),
        });
    };

    sendDriversLicense = ({
        driversLiceseNumber,
        phone,
        otp,
        email,
        name,
        lastName,
    }: LyftCredentials) => {
        let paramsObj = {};
        paramsObj = {
            grant_type: 'urn:lyft:oauth2:grant_type:phone',
            phone_number: phone,
            phone_code: otp,
            identifiers,
            drivers_license_number: driversLiceseNumber,
        };
        if (email && email.length > 0) {
            paramsObj = { ...paramsObj, email: `${email}` };
        }
        if (name && lastName && name.length > 0) {
            paramsObj = { ...paramsObj, username: `${name} ${lastName}` };
        }
        const params = getParamsFromObject(paramsObj);
        return instance.post('/oauth2/access_token', params, {
            headers: this.getHeadersInit(),
        });
    };

    sendCredentials = ({ phone, otp, email, firstName, lastName }: LyftCredentials) => {
        const paramsObj = {
            phone_number: phone,
            phone_code: otp,
            email,
            first_name: firstName,
            last_name: lastName,
            terms_url: 'https://s3.amazonaws.com/api.lyft.com/static/terms.html',
            force_new_account: false,
            mat_id: 'ccb9fbed-50d7-41f6-8796-95c8e1e0f193',
            app_store_source: 'google',
            identifiers,
            terms_ui_style: 'SCROLLWRAP',
        };
        const params = getParamsFromObject(paramsObj);
        return instance.post(
            '/v1/users',
            { ...paramsObj },
            {
                headers: this.getHeadersBearer(),
            }
        );
    };
}

export default LyftClient;

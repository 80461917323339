import React from 'react';
import { Platform } from 'react-native';
import { Polyline } from 'modules/maps/maps';
import { observer, inject } from 'mobx-react';

import BikeStore from '../../../stores/BikeStore';
import { Colors } from '../../../themes';
import { Stores } from '../../../stores/RootStore';

interface Props {
    bikeStore: BikeStore;
}

const platformParams =
    Platform.OS === 'ios'
        ? {
              strokeWidth: 4,
              lineDashPattern: [2, 4],
          }
        : { strokeWidth: 2 };

const PolylineRouteBikes = ({ bikeStore }: Props) => {
    if (!bikeStore.selectedBikeDetails) {
        return null;
    }
    const {
        routeDetails: { walkToStation, biking, walkFromStation } = {},
    } = bikeStore.selectedBikeDetails;
    if (walkToStation) {
        return null;
    }
    return (
        <>
            <Polyline
                key="walk1"
                coordinates={walkToStation.route ? walkToStation.route.slice() : []}
                strokeColor={Colors.walkingRouteColor}
                lineCap="round"
                {...platformParams}
            />
            <Polyline
                key="bike"
                coordinates={biking.route ? biking.route.slice() : []}
                strokeWidth={4}
                strokeColor={Colors.obiTeal}
                lineCap="round"
            />
            <Polyline
                key="walk2"
                coordinates={walkFromStation.route ? walkFromStation.route.slice() : []}
                strokeColor={Colors.walkingRouteColor}
                lineCap="round"
                {...platformParams}
            />
        </>
    );
};

export default inject(({ bikeStore }: Stores) => ({ bikeStore }))(observer(PolylineRouteBikes));

import React, { useEffect } from 'react';
import { ImageSourcePropType, FlatList, View, Share, Platform } from 'react-native';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { useLocalStore } from 'mobx-react-lite';

import InviteFriendsListItem from './InviteFriendsListItem';
import InviteFriendsListItemStyles from './styles/InviteFriendsListItemStyles';
import ReferralStore from '../../stores/ReferralStore';
import shareMessage from '../../services/ShareUtils';
import { Images, Colors } from '../../themes';
import { Localizable, Analytics } from '../../lib';
import { Utils } from '../../services/Utils';
import { useStores } from 'containers/App';

interface ReferralItem {
    text: string;
    icon: ImageSourcePropType;
    onPress: () => void;
}

class ReferralListStore {
    referralStore: ReferralStore;

    constructor(referralStore: ReferralStore) {
        this.referralStore = referralStore;
    }

    @observable
    message = Localizable.t('profile.inviteMessage');

    @observable
    title = Localizable.t('profile.inviteTitle');

    get referrals(): Array<ReferralItem> {
        return Platform.select({
            web: [
                {
                    text: Localizable.t('inviteFriends.sendViaEmail'),
                    icon: Images.inviteFriends.email,
                    onPress: () => this.share('email'),
                },
                {
                    text: Localizable.t('inviteFriends.copyLink'),
                    icon: Images.inviteFriends.link,
                    onPress: () => {
                        this.share('copy');
                        Utils.showAlert('', 'Message copied');
                    },
                },
            ],
            default: [
                {
                    text: Localizable.t('inviteFriends.sendViaEmail'),
                    icon: Images.inviteFriends.email,
                    onPress: () => this.share('email'),
                },
                {
                    text: Localizable.t('inviteFriends.sendViaSms'),
                    icon: Images.inviteFriends.sms,
                    onPress: () => this.share('sms'),
                },
                {
                    text: Localizable.t('inviteFriends.copyLink'),
                    icon: Images.inviteFriends.link,
                    onPress: () => {
                        this.share('copy');
                        Utils.showAlert('', 'Message copied');
                    },
                },
                {
                    text: Localizable.t('inviteFriends.more'),
                    icon: Images.inviteFriends.more,
                    onPress: () => this.systemShare(),
                },
            ],
        });
    }

    share = channel => {
        shareMessage({
            channel,
            deeplink: this.referralStore.dynamicLink,
            message: this.message,
            title: this.title,
        });
    };

    systemShare = () => {
        Share.share({
            title: this.title,
            message: `${this.message}${this.referralStore.dynamicLink}`,
        });
    };
}

const renderReferral = ({ item }: { item: ReferralItem }) => (
    <InviteFriendsListItem {...item} key={item.text} />
);

const renderSeparator = () => <View style={InviteFriendsListItemStyles.itemSeparator} />;

export default observer(() => {
    useEffect(() => {
        Analytics.trackScreenView('04_Referrals_InviteFriend');
    }, []);
    const {
        stores: { referralStore },
    } = useStores();
    const referralListStore = useLocalStore(() => new ReferralListStore(referralStore));
    return (
        <FlatList
            ItemSeparatorComponent={renderSeparator}
            bounces={false}
            data={referralListStore.referrals}
            renderItem={renderReferral}
            showsVerticalScrollIndicator={false}
        />
    );
});

import { Polyline } from '@react-google-maps/api';
import React from 'react';

const MyPolyline = ({ coordinates, strokeWidth, strokeColor }) => {
    return (
        <Polyline
            path={coordinates.map(route => {
                return { lat: route.latitude, lng: route.longitude };
            })}
            options={{
                strokeColor,
                strokeWeight: strokeWidth,
            }}
        />
    );
};

export default MyPolyline;

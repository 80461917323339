import React, { Component } from 'react';
import { TextInput, View, Text, StyleSheet, Image, TouchableOpacity, Linking } from 'react-native';
import KeyboardAwareScrollView from 'modules/keyboardAwareScrollView/keyboardAwareScrollView';
import { observer, inject } from 'mobx-react';

import { Localizable } from 'lib';
import { NavigationStore, ServicesStore } from 'stores';
import { Stores } from 'stores/RootStore';

import AdditionalInfoStyles from '../../styles/AdditionalInfoStyles';
import TitleNavigationBar from '../../../navigationBars/TitleNavigationBar';
import { Colors, Images, Fonts } from '../../../../themes';
import { ClickableInfoChildProps } from '../../providerConnectingComponents/clickableInfoLabel/ClickableInfoLabel';
import {
    InfoLabel,
    NextButton,
    ErrorMessage,
    ClickableInfoLabel,
} from '../../providerConnectingComponents';

const clickableInfoLabelContent: Array<ClickableInfoChildProps> = [
    {
        text: Localizable.t('additionalInfo.curb.accept'),
        clickableText: Localizable.t('additionalInfo.curb.termsOfService'),
        clickableTextStyle: {
            fontFamily: Fonts.type.base,
            fontWeight: '500',
            color: '#1FB484',
        },
        handleClickableTextClick: () => Linking.openURL('http://gocurb.com/terms'),
    },
    {
        text: 'and',
        clickableText: Localizable.t('additionalInfo.curb.privacyPolicy'),
        clickableTextStyle: {
            fontFamily: Fonts.type.base,
            fontWeight: '500',
            color: '#1FB484',
        },
        handleClickableTextClick: () => Linking.openURL('http://gocurb.com/privacy'),
    },
];

interface Props {
    servicesStore: ServicesStore;
    navigationStore: NavigationStore;
}

@inject(({ servicesStore, navigationStore }: Stores) => ({
    servicesStore,
    navigationStore,
}))
@observer
export default class ProviderRegisterCurb extends Component<Props> {
    lastNameInputRef: React.RefObject<TextInput> = React.createRef();

    phoneNumberInputRef: React.RefObject<TextInput> = React.createRef();

    emailInputRef: React.RefObject<TextInput> = React.createRef();

    passwordInputRef: React.RefObject<TextInput> = React.createRef();

    focusLastNameInput = () => {
        if (this.lastNameInputRef.current) {
            this.lastNameInputRef.current.focus();
        }
    };

    focusPhoneNumberInput = () => {
        if (this.phoneNumberInputRef.current) {
            this.phoneNumberInputRef.current.focus();
        }
    };

    focusEmailInput = () => {
        if (this.emailInputRef.current) {
            this.emailInputRef.current.focus();
        }
    };

    focusPasswordInput = () => {
        if (this.passwordInputRef.current) {
            this.passwordInputRef.current.focus();
        }
    };

    handleBackClick = () => {
        const {
            servicesStore: {
                curbServiceStore: { clearStore },
            },
            navigationStore: { navigate },
        } = this.props;
        clearStore();
        navigate('ProviderConnectingCurb');
        Analytics.trackServiceLinking('curb', 'registration', 'cancel');
    };

    componentDidMount() {
        Analytics.trackServiceLinking('curb', 'registration', 'credentials_screen');
    }

    render() {
        const {
            servicesStore: {
                curbServiceStore: {
                    clearStore,
                    countryCode,
                    countryName,
                    email,
                    errorMessage,
                    firstName,
                    lastName,
                    password,
                    phoneNumber,
                    onCurbRegisterCredentialsSubmit,
                    selectCountry,
                    setCurbEmail,
                    setCurbFirstName,
                    setCurbLastName,
                    setCurbPassword,
                    setCurbPhoneNumber,
                },
            },
            navigationStore: { navigate, goBack },
        } = this.props;

        return (
            <View
                style={[
                    StyleSheet.absoluteFill,
                    {
                        backgroundColor: Colors.typesBackgroundColor,
                    },
                ]}
            >
                <TitleNavigationBar title="Curb" handleBackClick={this.handleBackClick} />
                <View style={AdditionalInfoStyles.curbRegisterContainer}>
                    <KeyboardAwareScrollView
                        keyboardShouldPersistTaps="handled"
                        showsVerticalScrollIndicator={false}
                        style={AdditionalInfoStyles.keyboardAwareScrollView}
                    >
                        <Text style={AdditionalInfoStyles.title}>
                            {Localizable.t('additionalInfo.curb.createAccount')}
                        </Text>

                        <Text style={AdditionalInfoStyles.description}>
                            {Localizable.t('additionalInfo.placeholderFirstname')}
                        </Text>
                        <View style={AdditionalInfoStyles.inputBorder}>
                            <TextInput
                                style={[
                                    AdditionalInfoStyles.textInput,
                                    {
                                        fontWeight: firstName.length ? '300' : '100',
                                    },
                                ]}
                                keyboardType="default"
                                placeholder={Localizable.t('additionalInfo.placeholderFirstname')}
                                placeholderTextColor={Colors.lightGrayColor}
                                value={firstName}
                                secureTextEntry={false}
                                onChangeText={setCurbFirstName}
                                autoCapitalize="none"
                                autoFocus
                                autoCorrect={false}
                                selectionColor={Colors.obiTeal}
                                onSubmitEditing={this.focusLastNameInput}
                                returnKeyType="next"
                            />
                        </View>

                        <Text style={AdditionalInfoStyles.description}>
                            {Localizable.t('additionalInfo.placeholderLastname')}
                        </Text>
                        <View style={AdditionalInfoStyles.inputBorder}>
                            <TextInput
                                style={[
                                    AdditionalInfoStyles.textInput,
                                    {
                                        fontWeight: lastName.length ? '300' : '100',
                                    },
                                ]}
                                ref={this.lastNameInputRef}
                                keyboardType="default"
                                placeholder={Localizable.t('additionalInfo.placeholderLastname')}
                                placeholderTextColor={Colors.lightGrayColor}
                                value={lastName}
                                secureTextEntry={false}
                                onChangeText={setCurbLastName}
                                autoCapitalize="none"
                                autoCorrect={false}
                                selectionColor={Colors.obiTeal}
                                onSubmitEditing={this.focusPhoneNumberInput}
                                returnKeyType="next"
                            />
                        </View>

                        <Text style={AdditionalInfoStyles.description}>
                            {Localizable.t('additionalInfo.placeholderPhone')}
                        </Text>
                        <View style={AdditionalInfoStyles.inputBorder}>
                            <TouchableOpacity
                                onPress={() =>
                                    navigate('CountryPicker', {
                                        onClose: country => {
                                            selectCountry(country);
                                        },
                                    })
                                }
                            >
                                <View style={AdditionalInfoStyles.countryContainer}>
                                    <Image
                                        source={Images.flags[countryName]}
                                        style={AdditionalInfoStyles.countryIcon}
                                        resizeMode="contain"
                                    />
                                    <Image
                                        source={Images.icons.countryArrow}
                                        style={AdditionalInfoStyles.countryArrow}
                                        resizeMode="contain"
                                    />
                                    <Text style={AdditionalInfoStyles.countryText}>
                                        +{countryCode}
                                    </Text>
                                </View>
                            </TouchableOpacity>
                            <TextInput
                                style={[
                                    AdditionalInfoStyles.textInput,
                                    {
                                        fontWeight: phoneNumber.length ? '300' : '100',
                                    },
                                ]}
                                ref={this.phoneNumberInputRef}
                                keyboardType="phone-pad"
                                placeholder={Localizable.t('additionalInfo.placeholderPhone')}
                                placeholderTextColor={Colors.lightGrayColor}
                                value={phoneNumber}
                                secureTextEntry={false}
                                onChangeText={setCurbPhoneNumber}
                                autoCorrect={false}
                                selectionColor={Colors.obiTeal}
                                onSubmitEditing={this.focusEmailInput}
                                returnKeyType="done"
                            />
                        </View>

                        <Text style={AdditionalInfoStyles.description}>
                            {Localizable.t('additionalInfo.placeholderEmail')}
                        </Text>
                        <View style={AdditionalInfoStyles.inputBorder}>
                            <TextInput
                                style={[
                                    AdditionalInfoStyles.textInput,
                                    {
                                        fontWeight: email.length ? '300' : '100',
                                    },
                                ]}
                                ref={this.emailInputRef}
                                keyboardType="email-address"
                                placeholder={Localizable.t('additionalInfo.placeholderEmail')}
                                placeholderTextColor={Colors.lightGrayColor}
                                value={email}
                                secureTextEntry={false}
                                onChangeText={setCurbEmail}
                                autoCapitalize="none"
                                autoCorrect={false}
                                selectionColor={Colors.obiTeal}
                                onSubmitEditing={this.focusPasswordInput}
                                returnKeyType="next"
                            />
                        </View>

                        <Text style={AdditionalInfoStyles.description}>
                            {Localizable.t('additionalInfo.placeholderPassword')}
                        </Text>
                        <View style={AdditionalInfoStyles.inputBorder}>
                            <TextInput
                                style={[
                                    AdditionalInfoStyles.textInput,
                                    {
                                        fontWeight: password.length ? '300' : '100',
                                    },
                                ]}
                                ref={this.passwordInputRef}
                                keyboardType="default"
                                placeholder={Localizable.t('additionalInfo.placeholderPassword')}
                                placeholderTextColor={Colors.lightGrayColor}
                                value={password}
                                secureTextEntry
                                onChangeText={setCurbPassword}
                                autoCorrect={false}
                                selectionColor={Colors.obiTeal}
                                onSubmitEditing={onCurbRegisterCredentialsSubmit}
                                returnKeyType="done"
                            />
                        </View>
                    </KeyboardAwareScrollView>

                    <View>
                        {!!errorMessage.length && <ErrorMessage message={errorMessage} />}
                        <ClickableInfoLabel childrenProps={clickableInfoLabelContent} />
                        <InfoLabel info={Localizable.t('additionalInfo.curb.info')} />
                        <NextButton
                            onPress={onCurbRegisterCredentialsSubmit}
                            active={!errorMessage.length}
                        />
                    </View>
                </View>
            </View>
        );
    }
}

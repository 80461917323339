import { StyleSheet } from 'react-native';
import { Fonts, Metrics, Colors } from '../../../../themes';

export default StyleSheet.create({
    container: {
        justifyContent: 'flex-end',
    },
    clearBikeOnMapButton: {
        alignSelf: 'flex-end',
    },
    clearBikeOnMapButtonImage: {
        width: 60,
        height: 60,
    },
    innerContainer: {
        padding: 16,
        shadowColor: 'black',
        shadowRadius: 4,
        shadowOpacity: 0.1,
        shadowOffset: { width: 0, height: 0 },
        backgroundColor: 'white',
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        paddingBottom: Metrics.paddingBottom + 10,
        // position: 'absolute',
        // bottom: 0,
        // left: 0,
        // right: 0,
        elevation: 20,
    },
    walkView: {
        textAlign: 'center',
        fontSize: Metrics.normalize(14),
        color: Colors.black,
        fontWeight: '600',
        fontFamily: Fonts.type.base,
    },
    walkViewEmpty: {
        margin: 12,
        height: 1,
        alignSelf: 'stretch',
        backgroundColor: '#E9EAEB',
    },
    bikeInfoView: {
        flexDirection: 'row',
        padding: 10,
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    bikeInfoImageView: {
        borderRadius: Metrics.normalize(36),
        height: Metrics.normalize(72),
        width: Metrics.normalize(72),
        backgroundColor: 'white',
        shadowColor: 'grey',
        shadowRadius: 5,
        shadowOpacity: 0.3,
        shadowOffset: {
            width: 0,
            height: 2,
        },
        justifyContent: 'center',
        alignItems: 'center',
    },
    bikeInfoImage: {
        height: Metrics.normalize(68),
        width: Metrics.normalize(68),
    },
    batteryLevelView: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    batteryLevelImage: {
        width: 19,
        height: 12,
    },
    batteryLevelValueText: {
        fontSize: Metrics.normalize(17),
        fontFamily: Fonts.type.base,
        color: Colors.black,
    },
    batteryLevelText: {
        fontSize: Metrics.normalize(13),
        fontFamily: Fonts.type.base,
        color: Colors.grayTextColor,
    },
    docksNumberView: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    docksNumberImage: {
        width: 15,
        height: 16,
    },
    docksNumberValueText: {
        fontSize: Metrics.normalize(17),
        fontFamily: Fonts.type.base,
        color: Colors.black,
    },
    docksNumberInnerValueText: {
        fontSize: Metrics.normalize(17),
        fontFamily: Fonts.type.base,
        color: Colors.grayTextColor,
    },
    docksNumberText: {
        fontSize: Metrics.normalize(13),
        fontFamily: Fonts.type.base,
        color: Colors.grayTextColor,
    },
    innerBottomContainer: {
        flexDirection: 'row',
        padding: 10,
        justifyContent: 'space-between',
        alignItems: 'center',
        alignSelf: 'stretch',
    },
    innerBottomContainerView: {
        flexDirection: 'row',
        alignItems: 'center',
        flex: 1,
    },
    innerBottomContainerImage: {
        width: 24,
        height: 24,
        marginRight: 8,
    },
    detailsButtonValueText: {
        fontWeight: '700',
        color: Colors.black,
        fontSize: Metrics.normalize(16),
        fontFamily: Fonts.type.base,
    },
    detailsButtonText: {
        fontWeight: '400',
        color: Colors.grayTextColor,
        fontSize: Metrics.normalize(13),
        fontFamily: Fonts.type.base,
    },
    bottomButton: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#263571',
        borderRadius: 7,
        padding: 12,
        shadowColor: 'grey',
        shadowRadius: 5,
        shadowOpacity: 0.3,
        shadowOffset: {
            width: 0,
            height: 2,
        },
    },
    bottomButtonText: {
        fontSize: Metrics.normalize(16),
        color: 'white',
        fontWeight: '600',
        fontFamily: Fonts.type.base,
    },
});

import React, { Component } from 'react';
import Modal from 'modules/modal/modal';
import { View, Text, Image } from 'react-native';
import { inject, observer } from 'mobx-react';

import Button from '../Button';
import NewProviderModalStyles from './styles/NewProviderModalStyles';
import TaxiStore from 'stores/TaxiStore';
import { Stores } from 'stores/RootStore';
import { providerInfo } from 'services/Utils';

interface Props {
    taxiStore: TaxiStore;
}

@inject(({ taxiStore }: Stores) => ({ taxiStore }))
@observer
export default class NewProviderModal extends Component<Props> {
    render() {
        const {
            taxiStore: {
                taxiResultsStore: {
                    newProviderStore: {
                        newProviderPopupShown,
                        dismissNewProviderPopup,
                        markNewProviderAsAlreadySeen,
                        newProviderDesc,
                        newProviderName,
                    },
                },
            },
        } = this.props;
        return (
            <Modal
                animationIn="zoomIn"
                animationOut="zoomOut"
                isVisible={newProviderPopupShown}
                avoidKeyboard
                onBackdropPress={dismissNewProviderPopup}
            >
                <View style={NewProviderModalStyles.modalContainer}>
                    <Text style={NewProviderModalStyles.modalTitle}>New Provider</Text>
                    <Image
                        source={providerInfo(newProviderName).icon}
                        style={NewProviderModalStyles.providerImage}
                    />
                    {newProviderDesc.length > 0 && (
                        <Text style={NewProviderModalStyles.description}>{newProviderDesc}</Text>
                    )}
                    <Button
                        title="Dismiss"
                        handlePress={markNewProviderAsAlreadySeen}
                        active
                        style={NewProviderModalStyles.modalButton}
                    />
                </View>
            </Modal>
        );
    }
}

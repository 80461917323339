import React from 'react';
import { observer, inject } from 'mobx-react';
import { View } from 'react-native';
import BikeMarkerSingle from './BikeMarkerSingle';
import BikeMarkerStation from './BikeMarkerStation';
import BikeStore, { BikeStates } from '../../../../stores/BikeStore';
import { bikeProviderInfo } from '../../../../services/Utils';
import TaxiStore from '../../../../stores/TaxiStore';
import { Stores } from '../../../../stores/RootStore';

interface Props {
    bikeStore: BikeStore;
    taxiStore: TaxiStore;
}

export default inject(({ bikeStore, taxiStore }: Stores) => ({ bikeStore, taxiStore }))(
    observer(({ bikeStore, taxiStore }: Props) => {
        return bikeStore.bikeState !== BikeStates.on && bikeStore.scooterState !== BikeStates.on ? (
            <></>
        ) : (
            <>
                {bikeStore.bikeState === BikeStates.on &&
                    bikeStore.bikeStations
                        .filter(bike => bike.type !== 'Scooter')
                        .map(station =>
                            bikeProviderInfo(station.provider).type === 'single' ? (
                                <BikeMarkerSingle
                                    station={station}
                                    selectBikeOnMap={bikeStore.selectBikeOnMap}
                                    selectedBikeOnMap={bikeStore.selectedBikeOnMap}
                                    key={JSON.stringify(station.location)}
                                    calloutActive={taxiStore.taxiState === 'taxis'}
                                />
                            ) : bikeProviderInfo(station.provider).type === 'station' ? (
                                <BikeMarkerStation
                                    station={station}
                                    selectBikeOnMap={bikeStore.selectBikeOnMap}
                                    selectedBikeOnMap={bikeStore.selectedBikeOnMap}
                                    key={JSON.stringify(station.location)}
                                    calloutActive={taxiStore.taxiState === 'taxis'}
                                />
                            ) : (
                                <></>
                            )
                        )}
                {bikeStore.scooterState === BikeStates.on &&
                    bikeStore.bikeStations
                        .filter(bike => bike.type === 'Scooter')
                        .map(station =>
                            bikeProviderInfo(station.provider).type === 'single' ? (
                                <BikeMarkerSingle
                                    station={station}
                                    selectBikeOnMap={bikeStore.selectBikeOnMap}
                                    selectedBikeOnMap={bikeStore.selectedBikeOnMap}
                                    key={JSON.stringify(station.location)}
                                    calloutActive={taxiStore.taxiState === 'taxis'}
                                />
                            ) : bikeProviderInfo(station.provider).type === 'station' ? (
                                <BikeMarkerStation
                                    station={station}
                                    selectBikeOnMap={bikeStore.selectBikeOnMap}
                                    selectedBikeOnMap={bikeStore.selectedBikeOnMap}
                                    key={JSON.stringify(station.location)}
                                    calloutActive={taxiStore.taxiState === 'taxis'}
                                />
                            ) : (
                                <></>
                            )
                        )}
            </>
        );
    })
);

import { StyleSheet } from 'react-native';
import { Fonts, Metrics, Colors } from '../../../../themes';

export default StyleSheet.create({
    container: {
        padding: 16,
        shadowColor: 'black',
        shadowRadius: 4,
        shadowOpacity: 0.1,
        shadowOffset: { width: 0, height: 0 },
        backgroundColor: 'white',
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        paddingBottom: Metrics.paddingBottom,
        position: 'absolute',
        elevation: 20,
        bottom: 0,
        left: 0,
        right: 0,
    },
    etaText: {
        alignSelf: 'center',
        fontWeight: '500',
        fontSize: Metrics.normalize(14),
    },
    separator: {
        height: 1,
        alignSelf: 'stretch',
        backgroundColor: '#E9EAEB',
        marginVertical: 10,
    },
    vehicleView: {
        alignContent: 'center',
        marginLeft: 10,
    },
    empty: {
        flex: 1,
    },
    rowContainer: {
        // padding: 16,
        // alignItems: 'center',
        // justifyContent: 'center',
        flexDirection: 'row',
        marginBottom: 10,
        justifyContent: 'center',
        alignItems: 'center',
    },
    nameText: {
        fontFamily: Fonts.type.base,
        color: Colors.darkTextColor,
        fontWeight: '500',
        fontSize: Metrics.normalize(18),
    },
    carText: {
        fontFamily: Fonts.type.base,
        color: Colors.rideIconsColor,
        fontSize: Metrics.normalize(12),
    },
    cancelText: {
        fontFamily: Fonts.type.base,
        color: Colors.obiTeal,
        fontWeight: '400',
        fontSize: Metrics.normalize(14),
    },
    callDriverButton: {
        flex: 1,
        margin: 5,
    },
    messageDriverButton: {
        flex: 1,
        margin: 5,
    },
});

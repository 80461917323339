import React, { Component } from 'react';
import moment from 'moment';
import { LatLng } from 'react-native-maps';
import { NavigationParams } from 'react-navigation';
import { View } from 'react-native';
import { observable, action, flow } from 'mobx';
import { observer, inject } from 'mobx-react';

import AppStore from 'stores/AppStore';
import AuthStore from 'stores/AuthStore';
import GeolocationService from 'services/geolocation/GeolocationService';
import NavigationBar from 'components/navigationBars/NavigationBar';
import SavingsItem from 'domain/SavingsItem';
import SavingsStore from 'stores/SavingsStore';
import SavingsStyles from './styles/SavingsStyles';
import { Analytics, Localizable } from 'lib';
import { DirectionFormatted } from 'domain/APIresponses/DirectionFormatted';
import { Images } from 'themes';
import { NavigationStore } from 'stores';
import { SavingsCardSmall, TripSavingsList, TripSavingsModal } from 'components/savings';
import { Stores } from 'stores/RootStore';

class TripSavingsModalStore {
    @observable provider;

    @observable pickup;

    @observable pickupLocation;

    @observable destination;

    @observable destinationLocation;

    @observable pickupTime;

    @observable arrivedTime;

    @observable distance;

    @observable duration;

    @observable totalSavings;

    @observable modalVisible?: boolean;

    @observable seats;

    @observable currency;

    @observable route = [];

    @action
    setSavingsData = ({
        provider,
        pickup,
        pickupLocation,
        destination,
        destinationLocation,
        pickupTime,
        arrivedTime,
        distance,
        duration,
        totalSavings,
        seats,
        currency,
    }) => {
        this.provider = provider;
        this.pickup = pickup;
        this.pickupLocation = pickupLocation;
        this.destination = destination;
        this.destinationLocation = destinationLocation;
        this.pickupTime = pickupTime;
        this.arrivedTime = arrivedTime;
        this.distance = distance;
        this.duration = duration;
        this.totalSavings = totalSavings;
        this.seats = seats;
        this.currency = currency;
        this.modalVisible = true;
    };

    @action
    hideModal = () => {
        this.modalVisible = false;
        Analytics.trackScreenView('04-03_History');
    };

    getRoute = flow(function*(
        this: TripSavingsModalStore,
        {
            pickupLocation,
            destinationLocation,
        }: { pickupLocation: LatLng; destinationLocation: LatLng }
    ) {
        try {
            this.setRoute([]);
            const { points }: DirectionFormatted = yield GeolocationService.getDirectionFormatted(
                pickupLocation,
                destinationLocation
            );
            this.setRoute(points);
        } catch (error) {
            console.log({ ...error });
        }
    }).bind(this);

    @action
    setRoute = route => {
        this.route = route;
    };
}

interface Props {
    authStore: AuthStore;
    appStore: AppStore;
    savingsStore: SavingsStore;
    navigationStore: NavigationStore;
    navigation: NavigationParams;
}

@inject(({ appStore, authStore, navigationStore, savingsStore }: Stores) => ({
    appStore,
    authStore,
    navigationStore,
    savingsStore,
}))
@observer
export default class SavingsDashboard extends Component<Props, {}> {
    modalStore: TripSavingsModalStore;

    constructor(props: Props) {
        super(props);
        this.modalStore = new TripSavingsModalStore();
    }

    componentDidMount() {
        const {
            savingsStore: { getSavings },
            navigation: {
                state: { params },
            },
        } = this.props;
        Analytics.trackScreenView('04-03_History');
        getSavings().then(tripList => {
            if (params && (params.showModal || (params.showTrip && params.showTrip === 'recent'))) {
                if (tripList.length > 0) {
                    this.onItemClick(tripList[0]);
                }
            }
        });
    }

    onItemClick = (item: SavingsItem) => {
        const {
            date,
            destination,
            destinationLocation,
            distance,
            dropOffTime,
            pickup,
            pickupLocation,
            provider,
            savings,
            seats,
            currency,
        } = item;
        this.modalStore.getRoute({
            pickupLocation,
            destinationLocation,
        });
        this.modalStore.setSavingsData({
            provider,
            arrivedTime: dropOffTime,
            destination,
            distance,
            duration: moment(dropOffTime).diff(moment(date), 'minute'),
            pickup,
            pickupTime: date,
            totalSavings: savings,
            pickupLocation,
            destinationLocation,
            seats,
            currency,
        });
        Analytics.trackScreenView('04-03_Details');
    };

    render() {
        const {
            savingsStore: { tripCount, tripList, totalDistance },
            navigationStore: { goBack },
        } = this.props;
        return (
            <View style={SavingsStyles.container}>
                <NavigationBar
                    handleBackClick={() => goBack()}
                    title={Localizable.t('savings.savingsTrackerTitle')}
                />
                <View style={SavingsStyles.savingsCards}>
                    <SavingsCardSmall
                        icon={Images.savings.time}
                        title={Localizable.t('savings.tripsTaken')}
                        value={tripCount}
                    />
                    <SavingsCardSmall
                        icon={Images.savings.distance}
                        title={Localizable.t('savings.totalDistance')}
                        value={`${totalDistance.toFixed(1)} mi`}
                    />
                </View>
                <TripSavingsList tripList={tripList.slice()} onItemClick={this.onItemClick} />
                <TripSavingsModal modalStore={this.modalStore} />
            </View>
        );
    }
}

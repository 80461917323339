/* eslint-disable camelcase */
import axios from 'axios';
import ViaCredentials from 'domain/services/Via/ViaCredentials';
import proxy from '../Proxy';

const instance = axios.create({
    baseURL: `https://server.live.ridewithvia.com/account/rider/`,
    timeout: 40000,
});

class ViaClient {
    constructor() {
        instance.interceptors.request.use(request => {
            console.log('[ViaClient] Starting Request:', { ...request });
            return proxy(request);
        });
        instance.interceptors.response.use(
            response => {
                console.log('[ViaClient] Response:', { ...response });
                return response;
            },
            error => {
                console.log('[ViaClient] Response error:', { ...error });
                return Promise.reject(error);
            }
        );
    }

    getHeaders = () => ({
        'Content-Type': 'application/json',
        'Accept-Language': 'en-US',
    });

    login = ({ email, password }: ViaCredentials) => {
        return instance.post(
            '/login',
            { email, password, city_id: -1, rider_service_flag: 0 },
            { headers: this.getHeaders() }
        );
    };
}

export default ViaClient;

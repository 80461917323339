import React from 'react';
import { View, Text, Image, ImageSourcePropType } from 'react-native';

import SurgeBadgeStyles from './styles/SurgeBadgeStyles';

interface Props {
    surgeColors: {
        base: string;
        text: string;
    };
    surgeMultiplierString: string;
    image: ImageSourcePropType | null;
}

export default ({ surgeColors, surgeMultiplierString, image }: Props) => {
    return (
        <View style={[SurgeBadgeStyles.surgeView, { backgroundColor: surgeColors.base }]}>
            <View style={SurgeBadgeStyles.surgeInnerView}>
                {!!image && (
                    <Image source={image} style={SurgeBadgeStyles.surgeIcon} resizeMode="contain" />
                )}
                <Text style={[SurgeBadgeStyles.surgeText, { color: surgeColors.text }]}>
                    {`${surgeMultiplierString}x`}
                </Text>
            </View>
        </View>
    );
};

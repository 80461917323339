import { StyleSheet } from 'react-native';
import { Fonts, Colors, Metrics } from '../../../../themes';

export default StyleSheet.create({
    interactable: {
        backgroundColor: 'white',
        borderTopLeftRadius: 16,
        borderTopRightRadius: 16,
        shadowColor: '#000000',
        shadowOffset: { width: 0, height: 0 },
        shadowRadius: 4,
        shadowOpacity: 0.2,
        elevation: 20,
    },
    interactableInner: {
        backgroundColor: 'white',
        flex: 1,
    },
    top: {
        paddingHorizontal: 16,
        paddingVertical: 10,
        alignItems: 'center',
        justifyContent: 'center',
    },
    indicator: {
        height: 10,
        width: Metrics.screenWidth,
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    indicatorInner: {
        backgroundColor: '#000',
        opacity: 0.2,
        height: 5,
        width: 40,
        borderRadius: 2.5,
    },
    sideButton: {
        width: 42,
        height: 42,
        borderRadius: 21,
        alignItems: 'center',
        justifyContent: 'center',
    },
    sideImage: {
        width: 22,
        height: 22,
    },
    buttonsContainer: {
        flex: 1,
        flexDirection: 'row',
        borderRadius: 25,
        backgroundColor: Colors.darkerGrayBackgroundColor,
        padding: 5,
    },
    taxisComparationList: {
        borderTopColor: Colors.darkerGrayBackgroundColor,
        borderTopWidth: 1,
    },
    sectionContainer: {
        width: Metrics.screenWidth - 20,
        marginHorizontal: 10,
        alignItems: 'center',
        justifyContent: 'center',
        shadowColor: '#000000',
        shadowOffset: { width: 0, height: 0 },
        shadowRadius: 4,
        shadowOpacity: 0.2,
        elevation: 10,
    },
    flexRow: { flexDirection: 'row', alignItems: 'center', justifyContent: 'center' },
    comparationOptions: {
        alignSelf: 'stretch',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    filterButton: {
        alignSelf: 'stretch',
        alignItems: 'center',
        justifyContent: 'center',
        shadowColor: '#000000',
        shadowOffset: { width: 0, height: 0 },
        shadowRadius: 4,
        shadowOpacity: 0.2,
        elevation: 10,
    },
});

/* eslint-disable camelcase */
import * as Localize from 'react-native-localize';
import { AsYouType, isValidNumber, parse, getPhoneCode } from 'libphonenumber-js';
import { observable, action, flow } from 'mobx';

import Alert from 'modules/alert/alert';

import BaseStore from './BaseStore';
import RootStore from './RootStore';
import countryCodes from 'lib/flags';
import { Analytics, Localizable } from 'lib';
import { showAlert, showErrorAlert } from 'services/Utils';
import { RestClient } from 'services';

export enum AdditionaInfoType {
    EMAIL,
    PASSWORD,
    FIRSTNAME,
    LASTNAME,
    PHONENUMBER,
    VALIDATENUMBER,
    FORGOT,
}

export default class AdditionalInfoStore extends BaseStore {
    updateProfile: boolean = false;

    @observable type: AdditionaInfoType | null = null;

    @observable loading = null;

    @observable email: string = '';

    @observable password: string = '';

    @observable firstName: string = '';

    @observable lastName: string = '';

    @observable phoneNumber: string = '';

    @observable validationCode: string = '';

    @observable newPassword: string = '';

    @observable validPhoneNumber: boolean = false;

    @observable validEmail: boolean = false;

    @observable countryCode: any = 1;

    @observable countryName: any = 'us';

    constructor(rootStore: RootStore) {
        super(rootStore);
        this.getCarrierInfo();
    }

    @action
    setInitialType = (navigation: NavigationParams) => {
        const {
            stores: {
                authStore: { loggedIn },
            },
        } = this.rootStore;

        if (
            navigation &&
            navigation.state &&
            navigation.state.params &&
            navigation.state.params.type
        ) {
            this.updateProfile = true;

            switch (navigation.state.params.type) {
                case 'email':
                    this.type = AdditionaInfoType.EMAIL;
                    break;
                case 'name':
                    this.type = AdditionaInfoType.FIRSTNAME;
                    break;
                default:
                    this.type = AdditionaInfoType.PHONENUMBER;
                    this.validationCode = '';
                    Analytics.trackScreenView('01-02_EnterPhoneNumber');
                    break;
            }
        } else if (loggedIn) {
            this.type = AdditionaInfoType.FIRSTNAME;
        } else {
            this.type = AdditionaInfoType.PHONENUMBER;
            Analytics.trackScreenView('01-02_EnterPhoneNumber');
        }
    };

    @action
    getCarrierInfo = () => {
        let countryCode = 'us';
        try {
            countryCode = Localize.getCountry();
        } catch (error) {}
        const codes = countryCodes.filter(
            item => item.code.toLowerCase() === countryCode.toLowerCase()
        );
        if (codes.length > 0) {
            this.countryName = codes[0].code.toLowerCase();
            this.countryCode = codes[0].dial_code.replace(/\D/g, '');
        }
    };

    valueChanged = (value: string) => {
        switch (this.type) {
            case AdditionaInfoType.EMAIL:
                this.email = value;
                this.validEmail = this.validateEmail(value);
                break;
            case AdditionaInfoType.PASSWORD:
                this.password = value;
                break;
            case AdditionaInfoType.FIRSTNAME:
                this.firstName = value;
                break;
            case AdditionaInfoType.LASTNAME:
                this.lastName = value;
                break;
            case AdditionaInfoType.PHONENUMBER:
                this.phoneNumber = value;
                break;
            case AdditionaInfoType.VALIDATENUMBER:
                this.validationCode = value;
                if (value.length > 3) {
                    this.submitClicked();
                }
                break;
            case AdditionaInfoType.FORGOT:
                this.newPassword = value;
                break;
            default:
                break;
        }
    };

    submitClicked = () => {
        if (this.isValid()) {
            this.nextClicked();
        }
    };

    validateEmail = (email: string) => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    };

    isValid = () => {
        switch (this.type) {
            case AdditionaInfoType.EMAIL:
                return this.validEmail;
            case AdditionaInfoType.PASSWORD:
                return this.password.length > 5;
            case AdditionaInfoType.FIRSTNAME:
                return this.firstName.length > 1;
            case AdditionaInfoType.LASTNAME:
                return this.lastName.length > 1;
            case AdditionaInfoType.PHONENUMBER:
                return this.validPhoneNumber;
            case AdditionaInfoType.VALIDATENUMBER:
                return this.validationCode.length > 3;
            case AdditionaInfoType.FORGOT:
                return this.newPassword.length > 5;
            default:
                return false;
        }
    };

    rawPhoneNumber = () => {
        if (this.phoneNumber.indexOf('+') >= 0) {
            const numberInfo = parse(this.phoneNumber);
            if (numberInfo && numberInfo.country) {
                const countryCode = getPhoneCode(numberInfo.country);
                this.countryCode = countryCode;
                return this.phoneNumber.replace(`+${countryCode}`, '').replace(/\D/g, '');
            }
        }
        return this.phoneNumber.replace(/\D/g, '');
    };

    parseNumber = (text: any) => {
        if (text.length < this.phoneNumber.length) {
            this.phoneNumber = text;
            this.validPhoneNumber = isValidNumber(text);
        } else {
            this.phoneNumber = new AsYouType(this.countryName.toUpperCase()).input(text);
            this.validPhoneNumber = isValidNumber(text, this.countryName.toUpperCase());
        }
    };

    countrySelected = (country: any) => {
        this.countryCode = country.dial_code.replace(/\D/g, '');
        this.countryName = country.code.toLowerCase();
    };

    handleBackClick = () => {
        const {
            stores: {
                navigationStore: { goBack },
            },
        } = this.rootStore;
        switch (this.type) {
            case AdditionaInfoType.EMAIL:
                if (this.updateProfile) {
                    goBack();
                } else {
                    this.type = AdditionaInfoType.LASTNAME;
                }
                break;
            case AdditionaInfoType.FIRSTNAME:
                goBack();
                break;
            case AdditionaInfoType.PASSWORD:
                this.type = AdditionaInfoType.EMAIL;
                break;
            case AdditionaInfoType.LASTNAME:
                this.type = AdditionaInfoType.FIRSTNAME;
                break;
            case AdditionaInfoType.PHONENUMBER:
                goBack();
                break;
            case AdditionaInfoType.VALIDATENUMBER:
                this.type = AdditionaInfoType.PHONENUMBER;
                this.validationCode = '';
                Analytics.trackScreenView('01-02_EnterPhoneNumber');
                break;
            case AdditionaInfoType.FORGOT:
                this.type = AdditionaInfoType.PASSWORD;
                break;
            default:
                goBack();
                break;
        }
    };

    phoneLogin = flow(function*(this: AdditionalInfoStore) {
        const {
            stores: {
                appStore: { showLoading, hideLoading },
            },
        } = this.rootStore;
        try {
            showLoading();
            yield RestClient.setPhoneNumber(`+${this.countryCode}${this.rawPhoneNumber()}`);
            this.type = AdditionaInfoType.VALIDATENUMBER;
            Analytics.trackScreenView('01-02_VerifyPhoneNumber');
        } catch (error) {
            showErrorAlert(error);
        } finally {
            hideLoading();
        }
    }).bind(this);

    additionalFilled = flow(function*(this: AdditionalInfoStore) {
        const {
            stores: {
                appStore: { showLoading, hideLoading },
                navigationStore: { goBack },
            },
        } = this.rootStore;
        try {
            showLoading();
            yield RestClient.updateProfile(this.firstName, this.lastName, this.email);
            goBack();
        } catch (error) {
            showErrorAlert(error);
        } finally {
            hideLoading();
        }
    }).bind(this);

    saveName = () => {
        const {
            stores: {
                appStore: { showLoading, hideLoading },
                authStore: { fetchUser },
                navigationStore: { goBack },
            },
        } = this.rootStore;

        showLoading();

        RestClient.updateProfile(this.firstName, this.lastName, this.email)
            .then(() => {
                hideLoading();
                fetchUser();
                goBack();
            })
            .catch((error: Error) => {
                hideLoading();
                showErrorAlert(error);
            });
    };

    saveEmail = flow(function*(this: AdditionalInfoStore) {
        const {
            stores: {
                appStore: { showLoading, hideLoading },
                authStore: { fetchUser },
                navigationStore: { goBack },
            },
        } = this.rootStore;
        try {
            showLoading();
            yield RestClient.updateProfile(null, null, this.email);
            hideLoading();
            fetchUser();
            goBack();
        } catch (error) {
            hideLoading();
            showErrorAlert(error);
        }
    }).bind(this);

    @action
    validate = flow(function*(this: AdditionalInfoStore) {
        const {
            stores: {
                appStore: { showLoading, hideLoading },
                locationsStore: { fetchFavorites },
                authStore: { fetchUser, setLoggedInWithPhone },
                navigationStore: { goBack },
            },
        } = this.rootStore;
        try {
            showLoading();
            yield RestClient.verifyCode(this.validationCode);
            setLoggedInWithPhone(true);
            hideLoading();
            fetchUser();
            fetchFavorites();
            goBack();
            this.validationCode = '';
        } catch (error) {
            hideLoading();
            showErrorAlert(error);
        }
    }).bind(this);

    resend = flow(function*(this: AdditionalInfoStore) {
        const {
            stores: {
                appStore: { showLoading, hideLoading },
            },
        } = this.rootStore;
        try {
            showLoading();
            yield RestClient.resendCode();
        } catch (error) {
            showErrorAlert(error);
        } finally {
            hideLoading();
        }
    }).bind(this);

    register = () => {
        const {
            appStore: { showLoading, hideLoading },
            // auth0Store: { signup },
        } = this.rootStore.stores;
        // showLoading();
        // signup(this.email, this.password)
        // 	.then(() => {
        // 		this.login();
        // 	})
        // 	.catch(error => {
        // 		if (
        // 			error.response &&
        // 			error.response.data &&
        // 			error.response.data.code &&
        // 			error.response.data.code === 'user_exists'
        // 		) {
        // 			this.login();
        // 		} else {
        // 			showErrorAlert(error);
        // 			hideLoading();
        // 		}
        // 	});
    };

    // login = () => {
    // 	const {
    // 		appStore: { showLoading, hideLoading },
    // 		locationsStore: { fetchFavorites },
    // 		auth0Store: { login: loginOAuth, tokenInfo },
    // 		authStore: { setUser, login },
    // 	} = this.rootStore.stores;
    // 	loginOAuth(this.email, this.password)
    // 		.then(response => {
    // 			login(response.data.id_token, response.data.refresh_token);
    // 			tokenInfo()
    // 				.then(userResponse => {
    // 					setUser(userResponse.data);
    // 					hideLoading();
    // 					this.type = AdditionaInfoType.FIRSTNAME;
    // 				})
    // 				.catch(error => showErrorAlert(error));
    // 		})
    // 		.catch(error => {
    // 			showErrorAlert(error);
    // 			hideLoading();
    // 		});
    // };

    resetPassword = () => {
        const {
            appStore: { showLoading, hideLoading },
            locationsStore: { fetchFavorites },
            // auth0Store: { reset, tokenInfo },
            authStore: { setUser, login },
        } = this.rootStore.stores;
        // showLoading();
        // reset(this.email)
        // 	.then(() => {
        // 		hideLoading();
        // 		alert(Localizable.t('additionalInfo.resetSend'));
        // 	})
        // 	.catch(error => {
        // 		showErrorAlert(error);
        // 		hideLoading();
        // 	});
    };

    forgot = () => {
        Alert.alert('', Localizable.t('additionalInfo.resetMessage'), [
            {
                text: Localizable.t('no'),
                onPress: () => console.log('Cancel Pressed'),
                style: 'cancel',
            },
            { text: Localizable.t('yes'), onPress: () => this.resetPassword() },
        ]);
    };

    @action
    nextClicked = () => {
        switch (this.type) {
            case AdditionaInfoType.EMAIL:
                if (!this.validateEmail(this.email)) {
                    showAlert(Localizable.t('additionalInfo.wrongEmail'));
                    return;
                }
                if (this.updateProfile) {
                    this.saveEmail();
                } else {
                    this.additionalFilled();
                }
                break;
            case AdditionaInfoType.PASSWORD:
                this.register();
                break;
            case AdditionaInfoType.FIRSTNAME:
                this.type = AdditionaInfoType.LASTNAME;
                break;
            case AdditionaInfoType.LASTNAME:
                if (this.updateProfile) {
                    this.saveName();
                } else {
                    this.type = AdditionaInfoType.EMAIL;
                }
                break;
            case AdditionaInfoType.PHONENUMBER:
                this.phoneLogin();
                break;
            case AdditionaInfoType.VALIDATENUMBER:
                this.validate();
                break;
            case AdditionaInfoType.FORGOT:
                this.resetPassword();
                break;
            default:
                break;
        }
    };
}

/* eslint-disable no-underscore-dangle */
import React, { Component, RefObject } from 'react';
import { Text, View, TouchableOpacity, Image, Animated, StyleSheet, Platform } from 'react-native';
import { observer, inject } from 'mobx-react';
import { observable, action, computed } from 'mobx';

import BottomDrawer, { YID, Snap } from '../../components/drawer/BottomDrawer';
import RideDetailsSummary from '../../components/taxis/rideDetailsSummary/RideDetailsSummary';
import RideDetailsSummaryBottomView from 'components/taxis/rideDetailsSummary/RideDetailsSummaryBottomView';
import RideDetailsSummaryScrollView from 'components/taxis/rideDetailsSummary/RideDetailsSummaryScrollView';
import RideDetailsViewStyles from './styles/RideDetailsViewStyles';
import { AddressStore, LayoutStore, NavigationStore, TaxiStore } from 'stores';
import { Images, Metrics } from 'themes';
import { Localizable, Analytics } from 'lib';
import { Stores } from 'stores/RootStore';
import { providerInfo } from 'services/Utils';

export class ScrollViewStore {
    @observable
    parentHeight: number = 0;

    @observable
    contentHeight: number = 0;

    @action
    setParentHeight = (height: number) => {
        this.parentHeight = height;
    };

    @action
    setContentHeight = (height: number) => {
        this.contentHeight = height;
    };

    @computed
    get contentFitInParent(): boolean {
        return this.contentHeight <= this.parentHeight;
    }
}

interface Props {
    addressStore: AddressStore;
    filtersStore: FiltersStore;
    layoutStore: LayoutStore;
    navigationStore: NavigationStore;
    taxiStore: TaxiStore;
}

@inject(({ addressStore, filtersStore, layoutStore, navigationStore, taxiStore }: Stores) => ({
    addressStore,
    filtersStore,
    layoutStore,
    navigationStore,
    taxiStore,
}))
@observer
export default class RideDetailsView extends Component<Props, {}> {
    scrollViewStore = new ScrollViewStore();

    @observable.ref
    bottomDrawerRef: RefObject<BottomDrawer> = React.createRef();

    @observable
    currentSnap: Snap = 'up';

    bottomDrawerSnapPoints: Array<YID> = [
        {
            y: this.props.layoutStore.screenHeight / 8,
            id: 'up',
        },
        { y: Metrics.screenHeight, id: 'closed' },
    ];

    bottomDrawerInitialPoint: number = this.bottomDrawerSnapPoints[1].y;

    deltaY: Animated.Value = new Animated.Value(Metrics.screenHeight - 350);

    bottomDrawerInterpolation: any = {
        inputRange: this.bottomDrawerSnapPoints.map(point => point.y),
        outputRange: [0.6, 0.2],
    };

    bottomDrawerBoundaries: any = {
        bottom: Metrics.screenHeight,
        top: this.bottomDrawerSnapPoints[0].y,
    };

    componentDidMount() {
        const {
            navigationStore: { setRideDetailsViewRef },
            taxiStore: {
                selectedTaxiStore: {
                    selectedTaxi: {
                        provider: { company },
                        service_provider,
                    },
                },
            },
        } = this.props;
        setRideDetailsViewRef(this);
        Analytics.trackScreenView(`02-04_Review-TripDetails_${service_provider}_${company}`);
    }

    @action
    setCurrentSnap = (newSnap: Snap) => {
        this.currentSnap = newSnap;
    };

    handleBackClick = () => {
        const { addressStore } = this.props;
        if (this.bottomDrawerRef.current && this.bottomDrawerRef.current.currentSnap === 'up') {
            this.bottomDrawerRef.current.setSnap('closed');
            Analytics.trackScreenView(`02-03_Compare${addressStore.isNewYork ? '_Grouped' : ''}`);
        }
    };

    render() {
        const {
            taxiStore,
            taxiStore: { selectedTaxiStore, setTaxiState },
            filtersStore,
            layoutStore,
        } = this.props;
        return (
            <View style={StyleSheet.absoluteFill} pointerEvents="box-none">
                <BottomDrawer
                    backgroundOpacityInterpolation={this.bottomDrawerInterpolation}
                    boundaries={this.bottomDrawerBoundaries}
                    currentSnap={this.currentSnap}
                    deltaY={this.deltaY}
                    handleBackClick={this.handleBackClick}
                    initialPoint={this.bottomDrawerInitialPoint}
                    onClose={() => setTaxiState('taxis')}
                    openAnimated={{ toIndex: 0 }}
                    ref={this.bottomDrawerRef}
                    setCurrentSnap={this.setCurrentSnap}
                    snapPoints={this.bottomDrawerSnapPoints}
                >
                    <View
                        style={[
                            RideDetailsViewStyles.interactableContainer,
                            { height: (layoutStore.screenHeight * 7) / 8 },
                        ]}
                    >
                        <View style={RideDetailsViewStyles.interactableButtonContainer}>
                            <TouchableOpacity
                                style={RideDetailsViewStyles.interactableButton}
                                onPress={this.handleBackClick}
                            >
                                <Image
                                    style={RideDetailsViewStyles.interactableButtonImage}
                                    source={Images.icons.drawerArrow}
                                    resizeMode="contain"
                                />
                            </TouchableOpacity>
                        </View>
                        <Animated.View
                            style={{
                                flex: 1,
                                alignSelf: 'stretch',
                                opacity: this.deltaY.interpolate({
                                    inputRange: [
                                        this.bottomDrawerSnapPoints[0].y,
                                        this.bottomDrawerSnapPoints[1].y,
                                    ],
                                    outputRange: [1, 0],
                                    extrapolate: 'clamp',
                                }),
                            }}
                        >
                            <View style={RideDetailsViewStyles.topView}>
                                <Image
                                    source={
                                        providerInfo(
                                            selectedTaxiStore.selectedTaxi.service_provider
                                        ).icon
                                    }
                                    style={RideDetailsViewStyles.logo}
                                />
                                <View style={RideDetailsViewStyles.detailsView}>
                                    <Text style={RideDetailsViewStyles.nameText}>
                                        {selectedTaxiStore.selectedTaxi.provider.company}
                                    </Text>
                                    <Text style={RideDetailsViewStyles.detailText}>
                                        {selectedTaxiStore.selectedTaxi.service_level}
                                        {', '}
                                        {selectedTaxiStore.selectedTaxi.shared
                                            ? [
                                                  filtersStore.seats,
                                                  ' ',
                                                  Localizable.t('taxis.rideDetailsSummary.seats'),
                                              ]
                                            : [
                                                  selectedTaxiStore.selectedTaxi.max_passengers >
                                                      1 && '1-',
                                                  `${
                                                      selectedTaxiStore.selectedTaxi.max_passengers
                                                  } ${Localizable.t(
                                                      'taxis.rideDetailsSummary.seats'
                                                  )}`,
                                              ]}
                                    </Text>
                                </View>
                            </View>
                            <View
                                style={RideDetailsViewStyles.scrollView}
                                onLayout={({
                                    nativeEvent: {
                                        layout: { height },
                                    },
                                }) => {
                                    this.scrollViewStore.setParentHeight(height);
                                }}
                            >
                                <RideDetailsSummaryScrollView
                                    scrollViewStore={this.scrollViewStore}
                                />
                            </View>
                            <RideDetailsSummaryBottomView />
                        </Animated.View>
                    </View>
                </BottomDrawer>
            </View>
        );
    }
}

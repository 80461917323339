import { StyleSheet } from 'react-native';
import { Fonts, Metrics, Colors } from '../../../../../themes';

export default StyleSheet.create({
    outerContainer: {
        flex: 1,
        alignSelf: 'stretch',
        backgroundColor: 'white',
        marginHorizontal: 15,
    },
    container: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
        paddingVertical: 15,
        paddingHorizontal: 5,
        alignItems: 'center',
        backgroundColor: 'white',
    },
    providerIcon: {
        width: 35,
        height: 35,
        borderRadius: 17.5,
        marginRight: 10,
    },
    rowCompanyText: {
        flexShrink: 1,
        marginRight: 5,
        fontFamily: Fonts.type.base,
        fontSize: Metrics.normalize(16),
        fontWeight: '500',
        color: Colors.darkTextColor,
    },
    rowValueText: {
        fontFamily: Fonts.type.base,
        fontSize: Metrics.normalize(13),
        fontWeight: '300',
        color: '#BBC3C9',
    },
    providerTitleView: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        marginBottom: 4,
    },
    badgeView: {
        alignSelf: 'stretch',
        justifyContent: 'space-evenly',
        alignItems: 'flex-end',
        marginHorizontal: 5,
    },
    typeTitleText: {
        fontFamily: Fonts.type.base,
        fontWeight: '500',
        fontSize: Metrics.normalize(14),
        backgroundColor: 'transparent',
    },
    priceView: {
        paddingHorizontal: 3,
        paddingVertical: 1,
        borderRadius: 3,
        alignItems: 'center',
        justifyContent: 'center',
    },
    priceText: {
        fontFamily: Fonts.type.base,
        fontSize: Metrics.normalize(16),
        fontWeight: '500',
    },
    rowEtaDestinationText: {
        fontFamily: Fonts.type.base,
        fontSize: Metrics.normalize(16),
        fontWeight: '700',
        color: Colors.darkGreyTextColor,
        textAlign: 'right',
    },
    valueContainer: {
        justifyContent: 'center',
        alignItems: 'flex-end',
    },
    seatsImage: {
        height: 10,
        marginRight: 2,
    },
    boldText: {
        color: '#9AA2A8',
        fontFamily: Fonts.type.base,
        fontSize: Metrics.normalize(13),
        fontWeight: '700',
    },
    newProviderHeader: {
        backgroundColor: Colors.newProvider,
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        height: 15,
    },
    bikeProviderImage: {
        width: 35,
    },
    mainView: {
        flex: 1,
    },
    rowView: {
        flex: 1,
        flexDirection: 'row',
    },
    detailsView: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    separator: {
        backgroundColor: Colors.darkerGrayBackgroundColor,
        height: 1,
        marginHorizontal: 20,
    },
    textView: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    imageView: {
        flex: 0.3,
        alignItems: 'center',
        justifyContent: 'center',
    },
    image: {
        width: 10,
        height: 20,
    },
    flexOne: {
        flex: 1,
    },
    rowCenter: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    optimizedPriceText: {
        fontFamily: Fonts.type.base,
        fontSize: Metrics.normalize(16),
        fontWeight: '500',
        color: Colors.obiTeal,
    },
    optimizedPriceIcon: {
        width: 14,
        height: 14,
    },
});

import React from 'react';
import { observer, inject } from 'mobx-react';

import { Polyline } from 'modules/maps/maps';

import BikeStore from '../../../stores/BikeStore';
import TaxiStore from '../../../stores/TaxiStore';
import { Colors } from '../../../themes';
import { Stores } from '../../../stores/RootStore';

interface Props {
    bikeStore: BikeStore;
    taxiStore: TaxiStore;
}

export default inject(({ bikeStore, taxiStore }: Stores) => ({ bikeStore, taxiStore }))(
    observer(({ taxiStore: { taxiRouteStore }, bikeStore }: Props) => {
        return !bikeStore.selectedBike && taxiRouteStore.showPolylineRoute ? (
            <Polyline
                coordinates={taxiRouteStore.plainRoute.slice()}
                strokeWidth={3}
                strokeColor={Colors.newProvider}
                lineCap="round"
            />
        ) : null;
    })
);

import React, { Component } from 'react';
import { PixelRatio, Platform } from 'react-native';
import { observer } from 'mobx-react';
import MapView, { PROVIDER_GOOGLE, Polyline } from 'modules/maps/maps';
import MapStyle from '../../../map/map/mapJsonStyle/MapStyle.json';
import { Colors, Images } from 'themes';
import PlainMarker from '../../../map/marker/plainMarkers/PlainMarker';

interface Props {
    pickupLocation: any;
    destinationLocation: any;
    route: any;
}

interface State {
    initialState: boolean;
    mapHack: boolean;
}

@observer
export default class TripSavingsMap extends Component<Props, State, {}> {
    state = {
        initialState: true,
        mapHack: false,
    };

    componentDidUpdate(prevProps: Props, prevState: State) {
        const { pickupLocation, destinationLocation, route } = this.props;
        const {
            pickupLocation: pickupLocationPrev,
            destinationLocation: destinationLocationPrev,
            route: routePrev,
        } = prevProps;
        if (route.length > 0 && route.length !== routePrev.length) {
            this.fitToCoordinates(route);
        }
    }

    fitToCoordinates = points => {
        if (!points || !points.length || !this.mapView) {
            return;
        }
        const parsedPoints = points.map(point => point.coords || point);

        let padding;
        if (Platform.OS === 'android') {
            // return;
            padding = {
                top: 10 * PixelRatio.get(),
                left: 10 * PixelRatio.get(),
                right: 10 * PixelRatio.get(),
                bottom: 10 * PixelRatio.get(),
            };
        } else {
            padding = {
                top: 10,
                left: 10,
                right: 10,
                bottom: 10,
            };
        }

        this.mapView.fitToCoordinates(parsedPoints, {
            edgePadding: padding,
            animated: true,
        });
    };

    setMapRef = ref => {
        this.mapView = ref;
    };

    onMapLayout = () => {
        const { pickupLocation, destinationLocation, route } = this.props;

        const { initialState } = this.state;
        if (initialState) {
            this.setState({
                initialState: false,
            });
        }
        this.fitToCoordinates([pickupLocation, destinationLocation]);
    };

    render() {
        const { route, pickupLocation, destinationLocation } = this.props;

        return (
            <MapView
                customMapStyle={MapStyle}
                pitchEnabled={false}
                zoomEnabled={false}
                scrollEnabled={false}
                provider={PROVIDER_GOOGLE}
                ref={this.setMapRef}
                showsCompass={false}
                showsIndoors={false}
                showsMyLocationButton={false}
                showsUserLocation={false}
                style={{ flex: 1 }}
                cacheEnabled
                rotateEnabled={false}
                onLayout={this.onMapLayout}
            >
                <Polyline
                    coordinates={route}
                    strokeWidth={4}
                    strokeColor={Colors.obiTeal}
                    lineCap="round"
                />
                <PlainMarker
                    marker={{
                        coords: pickupLocation,
                        title: 'Pickup',
                        image: Images.icons.markerPickup,
                        centerOffset: { x: 0.52, y: 0.4 },
                    }}
                />
                <PlainMarker
                    marker={{
                        coords: destinationLocation,
                        title: 'Destination',
                        image: Images.icons.markerDrop,
                        centerOffset: { x: 0.52, y: 0.4 },
                    }}
                />
            </MapView>
        );
    }
}

import { StyleSheet, Platform } from 'react-native';
import { Fonts, Metrics, Colors } from '../../../../themes';

export default StyleSheet.create({
    container: {
        marginBottom: Platform.OS === 'ios' ? Metrics.normalize(55) : Metrics.normalize(75),
        padding: 10,
        paddingBottom: 0,
        alignItems: 'center',
        justifyContent: 'center',
    },
    etaView: {
        position: 'absolute',
        top: 0,
        left: 28,
        paddingHorizontal: 10,
        paddingVertical: 5,
        borderRadius: 15,
        backgroundColor: '#37454e',
        shadowColor: 'black',
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.05,
        shadowRadius: 5,
        elevation: 5,
        zIndex: 4,
    },
    etaText: {
        color: '#fff',
        fontSize: Metrics.normalize(9),
        fontFamily: Fonts.type.base,
        fontWeight: '400',
    },
    innerContainer: {
        alignItems: 'center',
        backgroundColor: 'white',
        borderRadius: 10,
        elevation: 5,
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: 5,
        padding: 10,
        shadowColor: 'black',
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.1,
        shadowRadius: 5,
        zIndex: 3,
    },
    title: {
        minWidth: 50,
        maxWidth: (Metrics.screenWidth * 2) / 3,
        fontSize: Metrics.normalize(12),
        color: Colors.darkTextColor,
        fontWeight: '700',
        fontFamily: Fonts.type.base,
        textAlign: 'center',
    },
    image: {
        marginLeft: 10,
        height: 12,
        width: 6,
    },
    // innerContainer: {
    //     backgroundColor: 'white',
    //     borderRadius: 10,
    //     flexDirection: 'row',
    //     height: Metrics.normalize(42),
    //     zIndex: 3,
    //     shadowColor: 'black',
    //     shadowOffset: { width: 0, height: 0 },
    //     shadowOpacity: 0.4,
    //     shadowRadius: 5,
    //     elevation: 7,
    // },
    // destinationView: {
    //     flexDirection: 'row',
    //     borderRadius: Metrics.normalize(18),
    //     height: Metrics.normalize(36),
    //     width: Metrics.normalize(36),
    //     margin: Metrics.normalize(3),
    //     overflow: 'hidden',
    //     alignItems: 'center',
    //     justifyContent: 'space-evenly',
    // },
    // etaContainer: {
    //     justifyContent: 'center',
    //     alignItems: 'center',
    //     flex: 1,
    // },
    // destinationValueText: {
    //     fontFamily: Fonts.type.base,
    //     fontSize: 14,
    //     fontWeight: '600',
    //     color: 'white',
    //     textAlign: 'center',
    // },
    // destinationDescriptionText: {
    //     textAlign: 'center',
    //     fontFamily: Fonts.type.base,
    //     fontSize: 8,
    //     fontWeight: '400',
    //     color: 'white',
    // },
    // bigPopupView: {
    //     alignSelf: 'center',
    //     marginHorizontal: 6,
    // },
    // titleText: {
    //     textAlign: 'center',
    //     fontFamily: Fonts.type.base,
    //     fontSize: 11,
    //     fontWeight: '600',
    //     color: Colors.black,
    //     maxWidth: 140,
    // },
    // descriptionText: {
    //     fontFamily: Fonts.type.base,
    //     fontSize: 9,
    //     fontWeight: '400',
    //     color: Colors.grayTextColor,
    //     maxWidth: 140,
    // },
    // arrowButton: {
    //     alignSelf: 'center',
    //     width: 6,
    //     height: 14,
    //     marginRight: 8,
    // },
    // smallArrowView: {
    //     justifyContent: 'center',
    //     alignItems: 'flex-start',
    //     width: 8,
    // },
    // smallArrowButton: {
    //     width: 6,
    //     height: 8,
    // },
    dot: {
        width: Metrics.normalize(11),
        height: Metrics.normalize(11),
        alignSelf: 'center',
        marginTop: -6,
        marginLeft: 1,
    },
});

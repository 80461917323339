/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import { OverlayView } from '@react-google-maps/api';
import { View } from 'react-native';
import { LatLng } from 'react-native-maps';

interface Props {
    anchor: {
        x: number;
        y: number;
    };
    coordinate: LatLng;
}

class Marker extends Component<Props> {
    render() {
        const {
            anchor,
            coordinate: { latitude, longitude },
            children,
        } = this.props;
        let translateX = '-50%';
        let translateY = '-50%';
        if (anchor) {
            const { x, y } = anchor;
            translateX = `${x * -100}%`;
            translateY = `${y * -100}%`;
        }
        return (
            <OverlayView
                position={{
                    lat: latitude,
                    lng: longitude,
                }}
                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            >
                <View
                    style={{
                        transform: [{ translateX }, { translateY }],
                    }}
                >
                    {children}
                </View>
            </OverlayView>
        );
    }
}

export default Marker;

import { StyleSheet } from 'react-native';
import { Metrics } from 'themes';

export default StyleSheet.create({
    container: {
        backgroundColor: 'white',
    },
    avatarImage: {
        height: 50,
        width: 50,
        borderRadius: 25,
        marginBottom: 8,
    },
    ratingView: {
        backgroundColor: 'white',
        position: 'absolute',
        bottom: 0,
        // right: 0,
        // left: 0,
        flexDirection: 'row',
        borderRadius: 8,
        shadowColor: 'black',
        shadowRadius: 4,
        shadowOpacity: 0.1,
        shadowOffset: { width: 0, height: 0 },
        elevation: 2,
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: 4,
        paddingVertical: 2,
        alignSelf: 'center',
    },
    ratingImage: {
        height: 10,
        width: 10,
    },
    ratingText: {
        fontSize: Metrics.normalize(10),
        marginLeft: 2,
    },
});

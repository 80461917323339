import React from 'react';
import { Text, View, TouchableOpacity, Image } from 'react-native';
import { inject, observer } from 'mobx-react';

import ExampleRouteTooltipStyles from './styles/ExampleRouteTooltipStyles';
import OnboardingStore from '../../../../stores/OnboardingStore';
import { Stores } from '../../../../stores/RootStore';
import { Images } from '../../../../themes';

interface Props {
    onboardingStore: OnboardingStore;
}

export default inject(({ onboardingStore }: Stores) => ({
    onboardingStore,
}))(
    observer(({ onboardingStore: { exampleRouteStore } }: Props) => {
        return exampleRouteStore.exampleDestinationTooltipVisible ? (
            <TouchableOpacity style={ExampleRouteTooltipStyles.tooltipContainer}>
                <View style={ExampleRouteTooltipStyles.arrow} />
                <View style={ExampleRouteTooltipStyles.tooltipInnerContainer}>
                    <Image
                        source={Images.icons.fancyDot}
                        style={ExampleRouteTooltipStyles.tooltipIcon}
                        resizeMode="contain"
                    />
                    <Text style={ExampleRouteTooltipStyles.tooltipText}>Tap to edit</Text>
                </View>
            </TouchableOpacity>
        ) : null;
    })
);

import React, { Component } from 'react';
import { View, Text, TextInput } from 'react-native';
import { observer, inject } from 'mobx-react';
import KeyboardAwareScrollView from 'modules/keyboardAwareScrollView/keyboardAwareScrollView';

import AdditionalInfoStyles from '../../styles/AdditionalInfoStyles';
import NavigationStore from 'stores/navigationStores/NavigationStore';
import ServicesStore from '../../../../stores/ServicesStore';
import TitleNavigationBar from '../../../navigationBars/TitleNavigationBar';
import { Colors } from '../../../../themes';
import { Localizable, Analytics } from '../../../../lib';
import { NextButton, ErrorMessage } from '../../providerConnectingComponents';
import { Stores } from '../../../../stores/RootStore';

interface Props {
    servicesStore: ServicesStore;
}

@inject(({ servicesStore }: Stores) => ({ servicesStore }))
@observer
export default class Name extends Component<Props> {
    lastNameInputRef: React.RefObject<TextInput> = React.createRef();

    focusLastNameInput = () => {
        if (this.lastNameInputRef && this.lastNameInputRef.current) {
            this.lastNameInputRef.current.focus();
        }
    };

    componentDidMount() {
        Analytics.trackServiceLinking('lyft', 'connecting', 'name_screen');
    }

    render() {
        const {
            servicesStore: {
                hideProviderPopup,
                lyftServiceStore: {
                    errorMessage,
                    firstName,
                    lastName,
                    onLyftNameSubmit,
                    setLyftFirstName,
                    setLyftLastName,
                },
            },
        } = this.props;
        return (
            <View style={AdditionalInfoStyles.container}>
                <TitleNavigationBar title="Lyft" handleBackClick={() => hideProviderPopup()} />

                <KeyboardAwareScrollView
                    contentContainerStyle={{ paddingHorizontal: 20 }}
                    keyboardShouldPersistTaps="handled"
                >
                    <Text style={AdditionalInfoStyles.title}>
                        {Localizable.t('additionalInfo.enterName')}
                    </Text>

                    <Text style={AdditionalInfoStyles.description}>
                        {Localizable.t('additionalInfo.placeholderFirstname')}
                    </Text>
                    <View style={AdditionalInfoStyles.inputBorder}>
                        <TextInput
                            autoCapitalize="words"
                            autoCorrect={false}
                            autoFocus
                            keyboardType="default"
                            onChangeText={setLyftFirstName}
                            onSubmitEditing={this.focusLastNameInput}
                            placeholder={Localizable.t('additionalInfo.placeholderFirstname')}
                            placeholderTextColor={Colors.lightGrayColor}
                            returnKeyType="done"
                            selectionColor={Colors.obiTeal}
                            style={[AdditionalInfoStyles.textInput]}
                            value={firstName}
                        />
                    </View>

                    <Text style={AdditionalInfoStyles.description}>
                        {Localizable.t('additionalInfo.placeholderLastname')}
                    </Text>
                    <View style={AdditionalInfoStyles.inputBorder}>
                        <TextInput
                            autoCapitalize="words"
                            autoCorrect={false}
                            keyboardType="default"
                            onChangeText={setLyftLastName}
                            onSubmitEditing={onLyftNameSubmit}
                            placeholder={Localizable.t('additionalInfo.placeholderLastname')}
                            placeholderTextColor={Colors.lightGrayColor}
                            ref={this.lastNameInputRef}
                            returnKeyType="done"
                            selectionColor={Colors.obiTeal}
                            style={[AdditionalInfoStyles.textInput]}
                            value={lastName}
                        />
                    </View>

                    {errorMessage.length !== 0 && <ErrorMessage message={errorMessage} />}
                    <NextButton onPress={onLyftNameSubmit} />
                </KeyboardAwareScrollView>
            </View>
        );
    }
}

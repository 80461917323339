import React from 'react';
import { TouchableOpacity, Image, View, Animated } from 'react-native';
import { inject, observer } from 'mobx-react';

import CenterMapButtonStyles from './CenterMapButtonStyles';
import TaxiStore from '../../../stores/TaxiStore';
import { Images } from '../../../themes';
import { Stores } from '../../../stores/RootStore';

interface InjectedProps {
    taxiStore: TaxiStore;
}

interface OwnProps {
    bottom: number;
    deltaY: Animated.Value;
    inputRange: number[];
    outputRange: number[];
    opacityInputRange?: number[];
    opacityOutputRange?: number[];
}

type Props = InjectedProps & OwnProps;

const CenterMapButton = ({
    taxiStore: { centerMap },
    bottom,
    deltaY,
    inputRange,
    outputRange,
    opacityInputRange,
    opacityOutputRange,
}: Props) => {
    return (
        <Animated.View
            pointerEvents="box-none"
            style={[
                CenterMapButtonStyles.animatedContainer,
                {
                    bottom,
                    transform: [
                        {
                            translateY:
                                inputRange && outputRange
                                    ? deltaY.interpolate({
                                          inputRange,
                                          outputRange,
                                          // extrapolate: 'clamp',
                                      })
                                    : 0,
                        },
                    ],
                    opacity:
                        opacityInputRange && opacityOutputRange
                            ? deltaY.interpolate({
                                  inputRange: opacityInputRange,
                                  outputRange: opacityOutputRange,
                              })
                            : 1,
                },
            ]}
        >
            <TouchableOpacity onPress={centerMap}>
                <View style={CenterMapButtonStyles.container}>
                    <Image style={CenterMapButtonStyles.image} source={Images.icons.centerMap} />
                </View>
            </TouchableOpacity>
        </Animated.View>
    );
};

export default inject(({ taxiStore }: Stores) => ({ taxiStore }))(observer(CenterMapButton));

import React, { Component } from 'react';
import { NavigationParams } from 'react-navigation';
import { View, Text, ScrollView, StyleSheet } from 'react-native';
import { observer, inject } from 'mobx-react';

import TitleNavigationBar from 'components/navigationBars/TitleNavigationBar';

import AppStore from '../../stores/AppStore';
import AuthStore from '../../stores/AuthStore';
import NavigationStore from '../../stores/NavigationStore';
import ServicesList from '../../components/services/servicesList/ServicesList';
import ServicesStore from '../../stores/ServicesStore';
import ServicesStyles from './styles/ServicesStyles';
import { Analytics, Localizable } from '../../lib';
import { Stores } from '../../stores/RootStore';

interface Props {
    authStore: AuthStore;
    appStore: AppStore;
    servicesStore: ServicesStore;
    navigationStore: NavigationStore;
}

@inject(({ appStore, authStore, servicesStore, navigationStore }: Stores) => ({
    appStore,
    authStore,
    servicesStore,
    navigationStore,
}))
@observer
export default class Services extends Component<Props, {}> {
    componentDidMount() {
        const {
            servicesStore: { getServices },
        } = this.props;
        getServices();
    }

    render() {
        const {
            authStore,
            appStore,
            navigationStore: { goBack },
            servicesStore: { services },
        } = this.props;
        return (
            <View style={StyleSheet.absoluteFill}>
                <View style={ServicesStyles.container}>
                    <TitleNavigationBar
                        authStore={authStore}
                        appStore={appStore}
                        title={Localizable.t('services.title')}
                        handleBackClick={() => {
                            goBack();
                        }}
                    />
                    <ScrollView bounces={false}>
                        {services.every(service => service.connected) ? (
                            <Text style={ServicesStyles.descriptionText}>
                                {Localizable.t('services.descriptionConnected')}
                            </Text>
                        ) : (
                            <>
                                <Text key="text1" style={ServicesStyles.descriptionText}>
                                    {Localizable.t('services.description')}
                                </Text>
                                <Text key="text2" style={ServicesStyles.descriptionBoldText}>
                                    {Localizable.t('services.descriptionBold')}
                                </Text>
                            </>
                        )}
                        <ServicesList keepModal={false} />
                        <Text style={ServicesStyles.descriptionText2}>
                            {Localizable.t('services.disclaimer')}
                        </Text>
                    </ScrollView>
                </View>
            </View>
        );
    }
}

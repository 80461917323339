import React from 'react';
import { View, Text } from 'react-native';
import { observer } from 'mobx-react';

import Avatar from '../profile/Avatar';
import EmptyNavigationBar from './EmptyNavigationBar';
import NavigationStore from 'stores/navigationStores/NavigationStore';
import TitleNavigationBarStyles from './styles/TitleNavigationBarStyles';
import { Analytics } from 'lib';
import { AuthStore } from 'stores';
import { useStores } from 'containers/App';

interface Props {
    handleBackClick: () => void;
    title: string;
}

export default observer(({ handleBackClick, title }: Props) => {
    const {
        stores: {
            navigationStore: { navigate },
        },
    } = useStores();
    return (
        <View style={TitleNavigationBarStyles.container}>
            <EmptyNavigationBar handleBackClick={handleBackClick} />
            <View style={TitleNavigationBarStyles.titleView}>
                <Text style={TitleNavigationBarStyles.titleText}>{title}</Text>
                <Avatar
                    style={TitleNavigationBarStyles.avatarView}
                    size={30}
                    onPress={() => {
                        navigate('ProfileRouter');
                        Analytics.trackScreenView('04-01_Profile');
                    }}
                />
            </View>
            <View style={TitleNavigationBarStyles.separator} />
        </View>
    );
});

/* eslint-disable no-underscore-dangle */
import React, { Component } from 'react';
import { View, Animated, Keyboard, StyleSheet, TouchableOpacity } from 'react-native';
import { Colors } from 'themes';
import { observable, action } from 'mobx';
import { throwStatement } from '@babel/types';
import { observer } from 'mobx-react';

type AnimationIn = 'zoomIn';
type AnimationOut = 'zoomOut';

interface Props {
    animationIn: AnimationIn;
    animationOut: AnimationOut;
    avoidKeyboard: boolean;
    isVisible: boolean;
    onBackdropPress: () => void;
}

const ModalStyles = StyleSheet.create({
    container: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        padding: 20,
    },
    background: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: '#000',
    },
    backgroundClickable: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: Colors.transparent,
    },
});

@observer
export default class Modal extends Component<Props> {
    animatedValue = new Animated.Value(0);

    @observable
    isVisible?: boolean = false;

    componentDidMount() {
        const { avoidKeyboard, isVisible } = this.props;
        if (avoidKeyboard) {
            Keyboard.dismiss();
        }
        if (isVisible) {
            this.setVisible();
            Animated.timing(this.animatedValue, {
                toValue: 0.5,
                duration: 500,
            }).start();
        }
    }

    componentDidUpdate(prevProps: Props) {
        const { isVisible } = this.props;
        const { isVisible: prevIsVisible } = prevProps;
        if (!prevIsVisible && isVisible) {
            this.setVisible();
            Animated.timing(this.animatedValue, {
                toValue: 0.5,
                duration: 500,
            }).start();
        }
        if (prevIsVisible && !isVisible) {
            Animated.timing(this.animatedValue, {
                toValue: 0,
                duration: 500,
            }).start(this.setInvisible);
        }
    }

    @action
    setInvisible = () => {
        this.isVisible = false;
    };

    @action
    setVisible = () => {
        this.isVisible = true;
    };

    render() {
        const { children, isVisible, onBackdropPress } = this.props;
        return isVisible || this.isVisible ? (
            <View style={ModalStyles.container}>
                <Animated.View
                    style={[
                        ModalStyles.background,
                        {
                            opacity: this.animatedValue.interpolate({
                                inputRange: [0, 0.5],
                                outputRange: [0, 0.5],
                            }),
                        },
                    ]}
                />
                {onBackdropPress && (
                    <TouchableOpacity
                        style={ModalStyles.backgroundClickable}
                        onPress={onBackdropPress}
                    />
                )}
                <Animated.View
                    pointerEvents="box-none"
                    style={{
                        flex: 1,
                        opacity: this.animatedValue.interpolate({
                            inputRange: [0, 0.5],
                            outputRange: [0, 1],
                        }),
                        transform: [
                            {
                                scaleX: this.animatedValue.interpolate({
                                    inputRange: [0, 0.5],
                                    outputRange: [0, 1],
                                }),
                            },
                            {
                                scaleY: this.animatedValue.interpolate({
                                    inputRange: [0, 0.5],
                                    outputRange: [0, 1],
                                }),
                            },
                        ],
                    }}
                >
                    {children}
                </Animated.View>
            </View>
        ) : null;
    }
}

import moment from 'moment';
import { observable, action, flow, computed } from 'mobx';

import { Analytics, Localizable } from 'lib';
import { BaseStore, SelectedTaxiDeeplinkStore } from 'stores';
import { ExactPrice } from 'domain';
import { RestClient } from 'services';
import { providerInfo } from 'services/Utils';

export default class SelectedTaxiStore extends BaseStore {
    selectedTaxiDeeplinkStore = new SelectedTaxiDeeplinkStore(this.rootStore);
    exactTimer?: NodeJS.Timer;

    @observable
    selectedTaxi: any = null;

    @observable
    exactPrice: ExactPrice | null = null;

    @observable
    exactPriceLoading: boolean = false;

    @observable
    showSeatsPopup: boolean = false;

    @action
    setSelectedTaxi = (taxi: any) => {
        this.selectedTaxi = taxi;
    };

    @action
    taxiSelected = (item, index) => {
        const {
            taxiStore: {
                zoomToRoute,
                setTaxiState,
                taxiResultsStore: {
                    taxisComparationStore: { selectedTypesTab },
                    newProviderStore: { alreadySeenAsNewProvider, showNewProviderPopup },
                },
            },
        } = this.rootStore.stores;
        const {
            service_provider,
            service_level,
            offer_id,
            is_new_provider,
            new_provider_description,
            exact_price,
            estimate_available,
            shared,
        } = item;

        this.setSelectedTaxi(item);
        this.exactPrice = exact_price || null;
        if (is_new_provider && !alreadySeenAsNewProvider(service_provider)) {
            showNewProviderPopup({
                name: service_provider,
                description: new_provider_description,
            });
        }
        zoomToRoute();

        if (shared && estimate_available) {
            this.showSeatsPopup = true;
        } else {
            setTaxiState('confirm');
        }

        this.getExactPrice();

        // if (item.show_payment) {
        //     const {
        //         paymentMethodsStore: { getPaymentsForProvider, setActivePaymentsProvider },
        //     } = this.rootStore.stores;
        //     setActivePaymentsProvider(item.service_provider.toLowerCase());
        //     getPaymentsForProvider(item.service_provider.toLowerCase());
        // }

        Analytics.trackTaxiOfferClick({
            searchOrderType: selectedTypesTab.sort,
            provider: service_provider,
            index,
            rideType: service_level,
            offerId: offer_id,
        });
    };

    @action
    seatsChanged = (seats: number) => {
        this.rootStore.stores.filtersStore.seatsChanged(seats);
        this.getExactPrice();
    };

    @action
    seatsConfirmed = () => {
        const {
            taxiStore: { setTaxiState },
        } = this.rootStore.stores;
        this.hideSeatsPopup();
        setTaxiState('confirm');
    };

    @action
    hideSeatsPopup = () => {
        this.showSeatsPopup = false;
    };

    getExactPrice = flow(function*(this: SelectedTaxiStore) {
        try {
            const {
                addressStore: { pickupLocation, destinationLocation },
            } = this.rootStore.stores;
            if (
                !this.selectedTaxi ||
                (!this.selectedTaxi.upfront_fare_enabled && !this.selectedTaxi.fare_id)
            ) {
                this.exactPrice = null;
                this.clearExactPriceTimer();
                return;
            }
            if (!pickupLocation || !destinationLocation) return;
            this.exactPriceLoading = true;
            const { data } = yield RestClient.postEstimate({
                service: this.selectedTaxi.service_provider.toLowerCase(),
                offerId: this.selectedTaxi.offer_id,
                startLocation: pickupLocation,
                endLocation: destinationLocation,
                startNick: pickupLocation.address,
                endNick: destinationLocation.address,
                numPassengers: this.rootStore.stores.filtersStore.seats,
                pickupDate: moment().format('YYYY-MM-DDTHH:mm:ss.SSS'),
                onDemand: true,
                fareId: this.selectedTaxi.fare_id,
            });
            this.exactPrice = data;
            this.exactPriceLoading = false;
            this.exactTimer = setTimeout(() => this.getExactPrice(), 1000 * 50);
        } catch (error) {
            this.exactPriceLoading = false;
            this.exactPrice = null;
            this.clearExactPriceTimer();
        }
    }).bind(this);

    @action
    clearExactPriceTimer = () => {
        if (this.exactTimer) {
            clearTimeout(this.exactTimer);
        }
    };

    @action
    updateSelectedTaxi = results => {
        if (!this.selectedTaxi || !this.selectedTaxi.offer_id) {
            return;
        }
        const filteredResults = results.filter(
            result => result.offer_id === this.selectedTaxi.offer_id
        );
        if (filteredResults.length > 0) {
            this.setSelectedTaxi(filteredResults[0]);
        }
    };

    @action
    setExactPrice = (exactPrice: ExactPrice | null) => {
        this.exactPrice = exactPrice;
    };

    @action
    handleRideRequest = () => {
        // if (!this.selectedTaxi.booking_enabled) {
        this.selectedTaxiDeeplinkStore.handleDeeplink();
        //     return;
        // }
        // if (this.selectedTaxi.show_payment) {
        //     const {
        //         paymentMethodsStore: { showPaymentsPopup },
        //     } = this.rootStore.stores;
        //     showPaymentsPopup(this.selectedTaxi.service_provider.toLowerCase());
        // }
        // const {
        //     addressStore: { getTaxiVenues },
        //     paymentMethodsStore: { lastUsedPaymentId, selectCard, selectedPaymentMethodId },
        // } = this.rootStore.stores;
        // if (selectedPaymentMethodId === '' && lastUsedPaymentId !== '') {
        //     selectCard(lastUsedPaymentId);
        // }
        // getTaxiVenues();
    };

    @computed
    get arrivalTime() {
        const {
            taxiStore: { taxiResultsStore },
        } = this.rootStore.stores;
        let eta;
        const typesDuration = taxiResultsStore.taxiTypes.filter(type => !!type.initial_eta);
        if (this.selectedTaxi) {
            const duration = this.selectedTaxi.ride_duration
                ? this.selectedTaxi.ride_duration
                : this.taxiRouteStore.rideDuration;
            eta = this.selectedTaxi.initial_eta + duration;
        } else if (typesDuration.length) {
            this.taxis = typesDuration.sort((a, b) => {
                const etaA = a.initial_eta;
                const etaB = b.initial_eta;
                return etaA - etaB;
            });
            const duration = this.taxis[0].ride_duration
                ? this.taxis[0].ride_duration
                : taxiRouteStore.rideDuration;
            eta = this.taxis[0].initial_eta + duration;
        }
        if (eta) {
            const destinationTime = moment()
                .add(eta, 's')
                .format('h:mma');
            return destinationTime;
        }
        return null;
    }

    @computed
    get nearbyEta() {
        const {
            taxiStore: { taxiResultsStore },
        } = this.rootStore.stores;
        if (this.selectedTaxi) {
            if (this.selectedTaxi.initial_eta) {
                return Math.max(1, Math.round(this.selectedTaxi.initial_eta / 60));
            }
            return 'N/A';
        }
        if (taxiResultsStore.taxiTypesSortedETA.length > 0) {
            return Math.max(1, Math.round(taxiResultsStore.taxiTypesSortedETA[0].initial_eta / 60));
        }
        return null;
    }

    @computed
    get selectedTaxiPrice() {
        if (this.selectedTaxi.shared && this.exactPrice) {
            return `${this.exactPrice.display}`;
        }
        if (this.selectedTaxi.exactPrice && this.selectedTaxi.exactPrice.display) {
            return this.selectedTaxi.exactPrice.display;
        }
        if (this.selectedTaxi.price_info.max_price_cents) {
            if (
                this.selectedTaxi.price_info.max_price_cents !==
                this.selectedTaxi.price_info.min_price_cents
            ) {
                return `${this.selectedTaxi.price_info.rate}${(
                    this.selectedTaxi.price_info.min_price_cents / 100
                ).toFixed(2)} - ${(this.selectedTaxi.price_info.max_price_cents / 100).toFixed(2)}`;
            }
            return this.selectedTaxi.price_info.rate_estimate;
        }
        return this.selectedTaxi.price_info.rate_estimate;
    }

    @computed
    get isSelectedTaxiLinkable() {
        return (
            providerInfo(this.selectedTaxi.service_provider).linkable &&
            this.rootStore.stores.servicesStore.services.find(
                service =>
                    this.selectedTaxi.service_provider.toLowerCase() ===
                        service.name.toLowerCase() && !service.connected
            )
        );
    }

    @computed
    get fareWarning() {
        return (
            providerInfo(this.selectedTaxi.service_provider).fareWarning ||
            (this.selectedTaxi.booking_enabled
                ? Localizable.t('taxis.rideDetailsSummary.disclaimerBooking')
                : Localizable.t('taxis.rideDetailsSummary.disclaimerDeeplink'))
        );
    }

    @computed
    get deeplinksPresent() {
        return this.selectedTaxi.booking_enabled && this.selectedTaxi.deeplinks;
    }
}

import React from 'react';
import { View, TouchableOpacity, Text, Image } from 'react-native';
import { inject, observer } from 'mobx-react';

import ProviderButton from '../../ProviderButton';
import RideDetailsSummaryBottomViewStyles from './styles/RideDetailsSummaryBottomViewStyles';
import ServiceItemReviewScreen from '../../services/serviceInReview/ServiceItemReviewScreen';
import { Images } from '../../../themes';
import { Localizable } from '../../../lib';
import { linkableServices } from '../../../stores/ServicesStore';
import { providerInfo } from '../../../services/Utils';
import { useStores } from 'containers/App';

export default observer(() => {
    const {
        stores: {
            taxiStore,
            taxiStore: { selectedTaxiStore },
        },
    } = useStores();
    const title = selectedTaxiStore.selectedTaxi.booking_enabled
        ? Localizable.t('taxis.rideDetailsSummary.bookRide')
        : `${Localizable.t('taxis.rideDetailsSummary.continueToBookIn')} ${
              providerInfo(selectedTaxiStore.selectedTaxi.service_provider).name
          }`;
    return (
        <View style={RideDetailsSummaryBottomViewStyles.bottomView}>
            {selectedTaxiStore.isSelectedTaxiLinkable ? (
                <ServiceItemReviewScreen
                    item={linkableServices.find(
                        linkableService =>
                            linkableService.name.toLowerCase() ===
                            selectedTaxiStore.selectedTaxi.service_provider.toLowerCase()
                    )}
                />
            ) : null}
            <ProviderButton
                title={title}
                handlePress={selectedTaxiStore.handleRideRequest}
                color={providerInfo(selectedTaxiStore.selectedTaxi.service_provider).color}
                textColor={providerInfo(selectedTaxiStore.selectedTaxi.service_provider).textColor}
            />
        </View>
    );
});

import React from 'react';
import { View, Image, Text, TouchableOpacity } from 'react-native';
import { inject, observer } from 'mobx-react';

import { Images } from 'themes';
import { Stores } from 'stores';
import OptionsStore, { ModeType } from '../../stores/OptionsStore';

import ModeItemStyles from './styles/ModeItemStyles';

interface InjectedProps {
    optionsStore: OptionsStore;
}

interface OwnProps {
    item: ModeType;
}

type Props = InjectedProps & OwnProps;

const ModeItem = ({ item, optionsStore }: Props) => {
    const { name, image, enabled } = item;
    return (
        <TouchableOpacity onPress={() => optionsStore.toggleModeEnabled(name)}>
            <View style={ModeItemStyles.container}>
                <Image source={image} style={ModeItemStyles.image} />
                <Text style={ModeItemStyles.text}>{name}</Text>
                <Image
                    source={enabled ? Images.services.available : Images.services.unavailable}
                    style={ModeItemStyles.isEnabledIcon}
                />
            </View>
        </TouchableOpacity>
    );
};

export default inject(({ optionsStore }: Stores) => ({ optionsStore }))(observer(ModeItem));

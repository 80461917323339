/* eslint-disable no-bitwise */
/* eslint-disable no-plusplus */
import { AxiosRequestConfig } from 'axios';
import { LatLng } from 'modules/maps/maps';
import { Images } from '../themes';
import Location from 'domain/app/Location';
import Localizable from '../lib/Localizable';

const Utils = {
    setDropdown(dropdown) {
        if (dropdown) {
            this.dropdown = dropdown;
        }
    },

    showErrorAlert(error) {
        if (!error && !error.length) {
            return;
        }

        if (this.dropdown && this.dropdown.alertWithType) {
            this.dropdown.alertWithType('error', 'Error', error);
        } else {
            alert(error);
        }
    },

    showAlert(title: string, message: string) {
        if (this.dropdown && this.dropdown.alertWithType) {
            this.dropdown.alertWithType('info', title || 'Bellhop', message);
        } else {
            alert(message);
        }
    },
};

type PhoneNumberSearchResult = {
    /**
     * formattedNumber: number as it was formatted in input string
     */
    formattedNumber: string;
    /**
     * phoneNumber: number formatted for use with external tools
     */
    phoneNumber: string;
    /**
     * start: start index of number in input
     */
    start: number;
    /**
     * end: end index of number in input
     */
    end: number;
};

/**
 * Returns information about phone number in input string, or null if no number found
 * @param input string to search for possible numbers
 */
const findNumberInString = (input: string): PhoneNumberSearchResult | null => {
    const possibleNumbers = input.match(/\+?(\s*-*\(*(\d+)\)*)*/g);
    if (possibleNumbers) {
        const formattedNumber = possibleNumbers.sort((a, b) => b.length - a.length)[0].trim();
        const phoneNumber = formattedNumber
            .replace(/\s/g, '')
            .replace(/\(/g, '')
            .replace(/\)/g, '')
            .replace(/-/g, '');
        const start = input.indexOf(formattedNumber);
        const end = input.indexOf(formattedNumber) + formattedNumber.length;
        return {
            formattedNumber,
            phoneNumber,
            start,
            end,
        };
    }
    return null;
};

const providerNotConnected = error => {
    if (error.response && error.response.data && error.response.data.code) {
        return (
            error.response.data.code === 'UBER_NOT_CONNECTED' ||
            error.response.data.code === 'CURB_NOT_CONNECTED' ||
            error.response.data.code === 'LYFT_NOT_CONNECTED'
        );
    }
    return false;
};
const errorCode = error => {
    if (error.response && error.response.status) {
        return error.response.status;
    }
    return null;
};
const handleError = error => {
    if (error.response && error.response.data) {
        if (error.response.status && error.response.status === 401) {
            return '';
        }
        if (error.response.data.message) {
            return error.response.data.message;
        }

        if (error.response.data.error_description) {
            return error.response.data.error_description;
        }
    }
    if (error.response) {
        console.log(error.response);
    } else {
        console.log(error);
    }

    if (error.error && error.error.message) {
        return error.error.message;
    }
    return 'Check your internet connection';
};

const showAlert = (alert: string) => {
    Utils.showAlert('', alert);
};

const showErrorAlert = (error: string) => {
    // Utils.showErrorAlert(handleError(error));
};

const showErrorAlertWithText = (errorText: string) => {
    Utils.showErrorAlert(errorText);
};

function deg2rad(deg: number) {
    return deg * (Math.PI / 180);
}

function rad2deg(rad: number) {
    return rad * (180 / Math.PI);
}

const regionContainingPoints = (points, inset) => {
    if (!points || !points.length) {
        return null;
    }
    if (points && points.length && points[0].coords) {
        points = points.map(marker => marker.coords);
    }
    let minX: number;
    let maxX: number;
    let minY: number;
    let maxY: number;

    (point => {
        minX = point.latitude;
        maxX = point.latitude;
        minY = point.longitude;
        maxY = point.longitude;
    })(points[0]);

    points.map((point: Location) => {
        minX = Math.min(minX, point.latitude);
        maxX = Math.max(maxX, point.latitude);
        minY = Math.min(minY, point.longitude);
        maxY = Math.max(maxY, point.longitude);
    });

    const midX = (minX + maxX) / 2;
    const midY = (minY + maxY) / 2;
    const midPoint = [midX, midY];

    const deltaX = maxX - minX + inset;
    const deltaY = maxY - minY + inset;

    return {
        latitude: midX,
        longitude: midY,
        latitudeDelta: deltaX,
        longitudeDelta: deltaY,
    };
};

const distance = (coordA: Location, coordB: Location) => {
    if (!coordA || !coordB) return 0;
    const p = 0.017453292519943295;
    const c = Math.cos;
    const a =
        0.5 -
        c((coordB.latitude - coordA.latitude) * p) / 2 +
        (c(coordA.latitude * p) *
            c(coordB.latitude * p) *
            (1 - c((coordB.longitude - coordA.longitude) * p))) /
            2;
    return 7918 * Math.asin(Math.sqrt(a));
};

export enum EarthRadiusUnits {
    MILES = 3959,
    KILOMETERS = 6371,
    METERS = 6371000,
}

type DistanceUnit = 'METERS' | 'KILOMETERS' | 'MILES';

const distanceBetweenCoords = ({
    startCoord,
    endCoord,
    unit = 'MILES',
    caller,
}: {
    startCoord: Location;
    endCoord: Location;
    unit?: DistanceUnit;
    caller?: string;
}) => {
    const dLat = deg2rad(endCoord.latitude - startCoord.latitude);
    const dLon = deg2rad(endCoord.longitude - startCoord.longitude);

    const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(deg2rad(startCoord.latitude)) *
            Math.cos(deg2rad(endCoord.latitude)) *
            Math.sin(dLon / 2) *
            Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = EarthRadiusUnits[unit] * c;

    return Number(d.toFixed(1));
};

const creditCardBrandIcon = (brand: string) => {
    const brandLowerCase = brand.toLowerCase();
    if (brandLowerCase.includes('visa')) {
        return Images.cards.visa;
    }
    if (brandLowerCase.includes('mastercard')) {
        return Images.cards.mastercard;
    }
    switch (brandLowerCase) {
        case 'diners club':
            return Images.cards.diners;
        case 'american express':
            return Images.cards.amex;
        case 'discover':
            return Images.cards.discover;
        case 'jcb':
            return Images.cards.jcb;
        default:
            return null;
    }
};

const trimAddress = (address: string) => {
    if (!address) return '';
    const splitedAddress = address.split(',');
    const showMore = splitedAddress.length && /^\d+$/.test(splitedAddress[0]);
    splitedAddress.length = Math.min(splitedAddress.length, showMore ? 3 : 2);
    return splitedAddress.join(',');
};

const getCityFromTrimmedAddress = (trimmedAddress: string) => {
    const splitedAddress = trimmedAddress.split(',');
    return splitedAddress[1];
};

interface ProviderInfo {
    name: string;
    icon?: any;
    color?: string;
    textColor?: string;
    linkable?: boolean;
    fareWarning?: string;
}

const providerInfo = (provider: string): ProviderInfo => {
    switch (provider.toLowerCase()) {
        case 'uber':
            return {
                name: 'Uber',
                icon: Images.providers.uber,
                color: '#000000',
                textColor: '#FFFFFF',
                linkable: true,
                fareWarning: Localizable.t('fareWarning.uber'),
            };
        case 'lyft':
            return {
                name: 'Lyft',
                icon: Images.providers.lyft,
                color: '#FF01BF',
                textColor: '#FFFFFF',
                linkable: true,
                fareWarning: Localizable.t('fareWarning.lyft'),
            };
        case 'arro':
            return {
                name: 'Arro',
                icon: Images.providers.arro,
                color: '#5DC9DD',
                textColor: '#FFFFFF',
                linkable: true,
                fareWarning: Localizable.t('fareWarning.arro'),
            };
        case 'curb':
            return {
                name: 'Curb',
                icon: Images.providers.curb,
                color: '#1FB484',
                textColor: '#FFFFFF',
                linkable: true,
                fareWarning: Localizable.t('fareWarning.curb'),
            };
        case 'bolt':
            return {
                name: 'Bolt',
                icon: Images.providers.bolt,
                color: '#37b777',
                textColor: '#fff',
                linkable: true,
                fareWarning: Localizable.t('fareWarning.bolt'),
            };
        case 'taxify':
            return {
                name: 'Bolt',
                icon: Images.providers.bolt,
                color: '#37b777',
                textColor: '#fff',
                linkable: true,
                fareWarning: Localizable.t('fareWarning.bolt'),
            };
        case 'juno':
            return {
                name: 'Juno',
                icon: Images.providers.juno,
                color: '#0A2C4A',
                textColor: '#FFFFFF',
                fareWarning: Localizable.t('fareWarning.juno'),
            };
        case 'flywheel':
            return {
                name: 'Flywheel',
                icon: Images.providers.flywheel,
                color: '#F2252E',
                textColor: '#000000',
            };
        case 'mytaxi':
            return {
                name: 'Free Now',
                icon: Images.providers.freeNow,
                color: '#01112b',
                textColor: '#fff',
            };
        case 'free_now':
            return {
                name: 'Free Now',
                icon: Images.providers.freeNow,
                color: '#01112b',
                textColor: '#fff',
            };
        case 'easy_taxi':
            return {
                name: 'Easy',
                icon: Images.providers.easytaxi,
                color: '#FFB629',
                textColor: '#FFFFFF',
            };
        case 'cabify':
            return {
                name: 'Cabify',
                icon: Images.providers.cabify,
                color: '#865BF3',
                textColor: '#FFFFFF',
            };
        case 'ola':
            return {
                name: 'Ola',
                icon: Images.providers.ola,
                color: '#54A628',
                textColor: '#FFFFFF',
            };
        case 'lecab':
            return {
                name: 'LeCab',
                icon: Images.providers.lecab,
                color: '#f67a38',
                textColor: '#FFFFFF',
            };
        case 'waave':
            return {
                name: 'Waave',
                icon: Images.providers.waave,
                color: '#F5EE20',
                textColor: '#000000',
            };
        case 'addison_lee':
            return {
                name: 'Addison Lee',
                icon: Images.providers.addisonLee,
                color: '#fcd931',
                textColor: '#000000',
            };
        case 'carmel':
            return {
                name: 'Carmel',
                icon: Images.providers.carmel,
                color: '#2d86eb',
                textColor: '#FFFFFF',
            };
        case 'via':
            return {
                name: 'Via',
                icon: Images.providers.via,
                color: '#3bbce3',
                textColor: '#FFFFFF',
                linkable: false,
                fareWarning: Localizable.t('fareWarning.via'),
            };
        case 'kabbee':
            return {
                name: 'Kabbee',
                icon: Images.providers.kabbee,
                color: '#c49220',
                textColor: '#000000',
                linkable: false,
            };
        case 'kapten':
            return {
                name: 'Kapten',
                icon: Images.providers.kapten,
                color: '#174870',
                textColor: '#FFFFFF',
                linkable: false,
            };
        case 'gett':
            return {
                name: 'Gett',
                icon: Images.providers.gett,
                color: '#f4ac27',
                textColor: '#FFFFFF',
                linkable: false,
            };
        case 'ride_austin':
            return {
                name: 'Ride Austin',
                icon: Images.providers.rideAustin,
                color: '#e6303c',
                textColor: '#fff',
                linkable: false,
            };
        case 'sandbox':
            return {
                name: 'SANDBOX',
                icon: Images.stockAvatar,
                color: '#c49220',
                textColor: '#000000',
                linkable: false,
            };
        case 'grab':
            return {
                name: 'Grab',
                icon: Images.providers.grab,
                color: '#4fba3c',
                textColor: '#fff',
                linkable: false,
            };
        case 'gojek':
            return {
                name: 'Gojek',
                icon: Images.providers.gojek,
                color: '#45a114',
                textColor: '#fff',
                linkable: false,
            };
        case 'didi':
            return {
                name: 'DiDi',
                icon: Images.providers.didi,
                color: '#fd804a',
                textColor: '#FFFFFF',
                linkable: false,
            };
        case 'yandex':
            return {
                name: 'Yandex',
                icon: Images.providers.yandex,
                color: '#fdc12d',
                textColor: '#fff',
                linkable: false,
            };
        case 'taxis99':
            return {
                name: 'Taxis99',
                icon: Images.providers.taxis99,
                color: '#fedc32',
                textColor: '#fff',
                linkable: false,
            };
        case 'beat':
            return {
                name: 'Beat',
                icon: Images.providers.beat,
                color: '#4eddb9',
                textColor: '#fff',
                linkable: false,
            };
        case 'arecibo':
            return {
                name: 'Arecibo',
                icon: Images.providers.arecibo,
                color: '#bd2d2f',
                textColor: '#fff',
                linkable: false,
            };

        default:
            return { name: 'Unknown' };
    }
};

type BikeType = 'single' | 'station';
interface BikeProviderInfo {
    name: string;
    icon?: any;
    iconMapFull?: any;
    iconMapEmpty?: any;
    color?: string;
    textColor?: string;
    priceWebLink?: string;
    price?: string;
    type?: BikeType;
}

const bikeProviderInfo = (provider: string): BikeProviderInfo => {
    switch (provider.toLowerCase()) {
        case 'citibike':
            return {
                name: 'CitiBike',
                icon: Images.bikes.citi.provider,
                iconMapFull: Images.bikes.citi.pin.full,
                iconMapEmpty: Images.bikes.citi.pin.empty,
                color: '#865BF3',
                textColor: '#FFFFFF',
                priceWebLink: 'https://www.citibikenyc.com/pricing',
                price: '$3/trip',
                type: 'station',
            };
        case 'pace':
            return {
                name: 'Pace',
                icon: Images.bikes.pace.provider,
                iconMapFull: Images.bikes.pace.pin.full,
                iconMapEmpty: Images.bikes.pace.pin.empty,
                color: '#2209f4',
                textColor: '#FFFFFF',
                priceWebLink: 'https://ridepace.com/',
                price: '$10/month',
                type: 'station',
            };
        case 'jump':
            return {
                name: 'JUMP!',
                icon: Images.bikes.jump.provider,
                iconMapFull: Images.bikes.jump.pin.full,
                // iconMapEmpty: Images.bikes.citi.pin.empty,
                color: '#865BF3',
                priceWebLink:
                    'https://help.jumpbikes.com/hc/en-us/articles/115005974306-Cost-of-rental',
                price: '$3 / 30 min',
                type: 'single',
            };
        case 'fordgobike':
            return {
                name: 'Ford Go Bike',
                icon: Images.bikes.fordgobike.provider,
                iconMapFull: Images.bikes.fordgobike.pin.full,
                iconMapEmpty: Images.bikes.fordgobike.pin.empty,
                color: '#865BF3',
                priceWebLink: 'https://www.fordgobike.com/pricing',
                price: '$2/trip',
                type: 'station',
            };
        case 'bluebikes':
            return {
                name: 'Blue Bikes',
                icon: Images.bikes.bluebikes.provider,
                iconMapFull: Images.bikes.bluebikes.pin.full,
                iconMapEmpty: Images.bikes.bluebikes.pin.empty,
                color: '#865BF3',
                priceWebLink: 'https://www.bluebikes.com/pricing',
                price: '$2.50/trip',
                type: 'station',
            };
        case 'niceride':
            return {
                name: 'Nice Ride',
                icon: Images.bikes.niceride.provider,
                iconMapFull: Images.bikes.niceride.pin.full,
                iconMapEmpty: Images.bikes.niceride.pin.empty,
                color: '#865BF3',
                priceWebLink: 'https://www.niceridemn.com/pricing',
                price: '$2/trip',
                type: 'station',
            };
        case 'capitalbikeshare':
            return {
                name: 'Capital Bikeshare',
                icon: Images.bikes.capitalbikeshare.provider,
                iconMapFull: Images.bikes.capitalbikeshare.pin.full,
                iconMapEmpty: Images.bikes.capitalbikeshare.pin.empty,
                color: '#865BF3',
                priceWebLink: 'https://www.capitalbikeshare.com/pricing',
                price: '$2/trip',
                type: 'station',
            };
        case 'cogo':
            return {
                name: 'CoGo',
                icon: Images.bikes.cogo.provider,
                iconMapFull: Images.bikes.cogo.pin.full,
                iconMapEmpty: Images.bikes.cogo.pin.empty,
                color: '#865BF3',
                priceWebLink: 'https://www.cogobikeshare.com/pricing',
                price: '$8/day',
                type: 'station',
            };
        case 'divvy':
            return {
                name: 'Divvy',
                icon: Images.bikes.divvy.provider,
                iconMapFull: Images.bikes.divvy.pin.full,
                iconMapEmpty: Images.bikes.divvy.pin.empty,
                color: '#865BF3',
                priceWebLink: 'https://www.divvybikes.com/pricing',
                price: '$3/trip',
                type: 'station',
            };
        case 'biketown':
            return {
                name: 'Bike Town',
                icon: Images.bikes.biketown.provider,
                iconMapFull: Images.bikes.biketown.pin.full,
                iconMapEmpty: Images.bikes.biketown.pin.empty,
                color: '#865BF3',
                priceWebLink: 'https://www.biketownpdx.com/pricing',
                price: '$0.08/min',
                type: 'station',
            };
        case 'metrobikeshare':
            return {
                name: 'Metro Bike Share',
                icon: Images.bikes.metrobikeshare.provider,
                iconMapFull: Images.bikes.metrobikeshare.pin.full,
                iconMapEmpty: Images.bikes.metrobikeshare.pin.empty,
                color: '#865BF3',
                priceWebLink: 'https://bikeshare.metro.net/',
                price: '$5/day',
                type: 'station',
            };
        case 'bird':
            return {
                name: 'Bird',
                icon: Images.bikes.bird.provider,
                iconMapFull: Images.bikes.bird.pin.full,
                iconMapEmpty: Images.bikes.bird.pin.empty,
                color: '#865BF3',
                priceWebLink: 'https://www.bird.co/',
                price: '$1 / unlock',
                type: 'single',
            };
        case 'lime':
            return {
                name: 'Lime',
                icon: Images.bikes.lime.provider,
                iconMapFull: Images.bikes.lime.pin.full,
                iconMapEmpty: Images.bikes.lime.pin.empty,
                color: '#865BF3',
                priceWebLink: 'https://www.li.me/',
                price: '$1 / unlock',
                type: 'single',
            };
        case 'scoot':
            return {
                name: 'Scoot',
                icon: Images.bikes.scoot.provider,
                iconMapFull: Images.bikes.scoot.pin.full,
                iconMapEmpty: Images.bikes.scoot.pin.empty,
                color: '#865BF3',
                priceWebLink: 'https://scoot.co/prices/',
                price: '$4 / ride',
                type: 'single',
            };
        default:
            return { name: 'Unknown' };
    }
};

const generateUUID = (): string => {
    // // return uuid of form xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx
    let uuid = '';
    let ii;
    for (ii = 0; ii < 32; ii += 1) {
        switch (ii) {
            case 8:
            case 20:
                uuid += '-';
                uuid += ((Math.random() * 16) | 0).toString(16);
                break;
            case 12:
                uuid += '-';
                uuid += '4';
                break;
            case 16:
                uuid += '-';
                uuid += ((Math.random() * 4) | 8).toString(16);
                break;
            default:
                uuid += ((Math.random() * 16) | 0).toString(16);
        }
    }
    return uuid;
};

const getAllUrlParams = (url: string) => {
    // get query string from url (optional) or window
    let queryString = url ? url.split('?')[1] : window.location.search.slice(1);

    // we'll store the parameters here
    const obj = {};

    // if query string exists
    if (queryString) {
        // stuff after # is not part of query string, so get rid of it
        queryString = queryString.split('#')[0];

        // split our query string into its component parts
        const arr = queryString.split('&');

        for (let i = 0; i < arr.length; i++) {
            // separate the keys and the values
            const a = arr[i].split('=');

            // in case params look like: list[]=thing1&list[]=thing2
            let paramNum;
            let paramName = a[0].replace(/\[\d*\]/, v => {
                paramNum = v.slice(1, -1);
                return '';
            });

            // set parameter value (use 'true' if empty)
            let paramValue = typeof a[1] === 'undefined' ? true : a[1];

            // (optional) keep case consistent
            paramName = paramName.toLowerCase();
            paramValue = paramValue.toLowerCase();

            // if parameter name already exists
            if (obj[paramName]) {
                // convert value to array (if still string)
                if (typeof obj[paramName] === 'string') {
                    obj[paramName] = [obj[paramName]];
                }
                // if no array index number specified...
                if (typeof paramNum === 'undefined') {
                    // put the value on the end of the array
                    obj[paramName].push(paramValue);
                }
                // if array index number specified...
                else {
                    // put the value at that index number
                    obj[paramName][paramNum] = paramValue;
                }
            }
            // if param name doesn't exist yet, set it
            else {
                obj[paramName] = paramValue;
            }
        }
    }

    return obj;
};

const uuidv4 = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        const r = (Math.random() * 16) | 0;
        const v = c == 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
};

const calculateMiddlePoint = (
    startPoint: LatLng,
    endPoint: LatLng,
    curveFactor: number
): LatLng => {
    const startLat = deg2rad(startPoint.latitude);
    const startLon = deg2rad(startPoint.longitude);
    const endLat = deg2rad(endPoint.latitude);
    const endLon = deg2rad(endPoint.longitude);
    const sign = startLon > endLon ? 1 : -1;

    return {
        latitude: rad2deg(
            (startLat + endLat + (Math.sqrt(3) * (startLon - endLon) * curveFactor * sign) / 10) / 2
        ),
        longitude: rad2deg(
            (startLon + endLon + (Math.sqrt(3) * (startLat - endLat) * curveFactor) / 10) / 2
        ),
    };
};

const calculateCurvedRoute = (
    startPoint: LatLng,
    endPoint: LatLng,
    recursionCounter: number,
    curveFactor: number
): Array<LatLng> => {
    const curvedRoute: Array<LatLng> = [];
    curvedRoute.push(startPoint);

    const midPoint = calculateMiddlePoint(startPoint, endPoint, curveFactor);

    if (recursionCounter !== 0) {
        curvedRoute.push(
            ...calculateCurvedRoute(startPoint, midPoint, recursionCounter - 1, curveFactor / 2)
        );
        curvedRoute.push(midPoint);
        curvedRoute.push(
            ...calculateCurvedRoute(midPoint, endPoint, recursionCounter - 1, curveFactor / 2)
        );
    }

    curvedRoute.push(endPoint);
    return curvedRoute;
};

const getEndpointFromConfig: (value: AxiosRequestConfig) => string = ({ url, baseURL }) =>
    (url && baseURL && url.replace(baseURL, '')) || '';

export default Utils;

export {
    Utils,
    bikeProviderInfo,
    creditCardBrandIcon,
    calculateCurvedRoute,
    distance,
    distanceBetweenCoords,
    errorCode,
    findNumberInString,
    generateUUID,
    getAllUrlParams,
    getCityFromTrimmedAddress,
    handleError,
    providerInfo,
    providerNotConnected,
    regionContainingPoints,
    showAlert,
    showErrorAlert,
    showErrorAlertWithText,
    trimAddress,
    uuidv4,
    getEndpointFromConfig,
};

import { StyleSheet } from 'react-native';
import { Colors, Fonts, Metrics } from '../../../themes';

export default StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
    },
    innerContainer: {
        backgroundColor: 'white',
        paddingTop: 50,
        paddingBottom: 20,
        paddingHorizontal: 16,
        borderRadius: 16,
        alignItems: 'center',
    },
    logoutText: {
        textAlign: 'center',
        color: Colors.grayTextColor,
        fontSize: Metrics.normalize(18),
        fontFamily: Fonts.type.base,
        fontWeight: '600',
    },
    separator: {
        height: 20,
    },
    yesButton: {
        alignSelf: 'stretch',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: Colors.darkerGrayBackgroundColor,
        borderRadius: 13,
        paddingVertical: 13,
    },
    yesText: {
        color: Colors.darkTextColor,
        fontSize: Metrics.normalize(18),
        fontFamily: Fonts.type.base,
        fontWeight: '600',
    },
    noButton: {
        alignSelf: 'stretch',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: Colors.obiTeal,
        borderRadius: 13,
        paddingVertical: 13,
    },
    noText: {
        color: '#fff',
        fontSize: Metrics.normalize(18),
        fontFamily: Fonts.type.base,
        fontWeight: '600',
    },
});

import React, { Component } from 'react';
import { TouchableOpacity, View, Image, Text, Platform } from 'react-native';
import AddressPickerStyles from './styles/AddressPickerStyles';
import { Localizable } from '../../lib';
import { Images } from '../../themes';

interface Props {
    item: any;
    index: number;
    suggestionSelected: Function;
}

class SuggestionsListItemUseLocation extends Component<Props> {
    onPress = () => {
        const { item, suggestionSelected } = this.props;
        suggestionSelected(item);
    };

    render() {
        const { item, index } = this.props;
        let title;
        let icon;

        if (item.current) {
            title = Localizable.t('suggestions.useCurrentLocation');
            icon = Images.mapLanding.current;
        } else if (item.select) {
            title = Localizable.t('suggestions.useMapToSelectLocation');
            icon = Images.mapLanding.select;
        }

        return (
            <TouchableOpacity
                style={AddressPickerStyles.suggestionItemContainer}
                {...(Platform.OS === 'web'
                    ? {
                          // When scrolling the document body, the touchables might be triggered
                          // see  https://github.com/necolas/react-native-web/issues/1219
                          onClick: this.onPress,
                      }
                    : {
                          onPress: this.onPress,
                      })}
            >
                <View
                    key={`${title} iconView ${index}`}
                    style={AddressPickerStyles.suggestionsItemLeftContainer}
                >
                    <Image
                        key={`${title} image ${index}`}
                        source={icon}
                        style={AddressPickerStyles.suggestionIcon}
                    />
                </View>
                <View style={AddressPickerStyles.textView} key={`${title} innerView ${index}`}>
                    <Text
                        key={`${title} title ${index}`}
                        allowFontScaling={false}
                        style={[
                            AddressPickerStyles.suggestionTitle,
                            item.current && { color: '#007AFF' },
                        ]}
                    >
                        {title}
                    </Text>
                </View>
            </TouchableOpacity>
        );
    }
}
export default SuggestionsListItemUseLocation;

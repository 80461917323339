import React, { PureComponent } from 'react';
import { SceneView, createNavigator, StackRouter } from '@react-navigation/core';

import {
    Captcha,
    Credentials,
    Email,
    TOTP,
    PhoneNumber,
    PasswordRecovery,
    Password,
    OTP,
} from 'components/providerConnecting/providers/uber';
import { CountryPicker } from 'containers/setup';

interface NavigatorViewProps {
    navigation: any;
    descriptors: any;
}

class NavigatorView extends PureComponent<NavigatorViewProps> {
    render() {
        const {
            descriptors,
            navigation: { state, screenProps },
        } = this.props;
        const { getComponent, navigation } = descriptors[state.routes[state.index].key];

        return (
            <SceneView
                style={{ flex: 1 }}
                component={getComponent()}
                navigation={navigation}
                screenProps={screenProps}
            />
        );
    }
}

const UberRegisterRouter = createNavigator(
    NavigatorView,
    StackRouter(
        {
            PhoneNumber,
            Credentials,
            OTP,
            Email,
            Captcha,
            TOTP,
            Password,
            PasswordRecovery,
            CountryPicker,
        },
        {
            initialRouteName: 'PhoneNumber',
            headerMode: 'none',
        },
        {}
    ),
    { navigationOptions: {} }
);

export default UberRegisterRouter;

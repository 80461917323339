import { StyleSheet } from 'react-native';
import { Fonts, Metrics, Colors } from '../../../themes';

export default StyleSheet.create({
    container: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        justifyContent: 'flex-end',
    },
    innerContainer: {
        backgroundColor: 'white',
        borderTopLeftRadius: 16,
        borderTopRightRadius: 16,
        shadowColor: '#000000',
        shadowOffset: { width: 0, height: 0 },
        shadowRadius: 4,
        shadowOpacity: 0.2,
        width: Metrics.screenWidth,
        paddingVertical: 16,
    },
    rowContainer: {
        flexDirection: 'row',
        height: 70,
        alignItems: 'center',
        marginBottom: 10,
        paddingHorizontal: 16,
        backgroundColor: 'white',
    },
    detailsView: {
        marginHorizontal: 10,
    },
    nameText: {
        fontFamily: Fonts.type.base,
        fontSize: Metrics.normalize(18),
        fontWeight: '500',
        color: Colors.black,
        marginBottom: 4,
    },
    confirmButton: {
        marginHorizontal: 16,
        marginVertical: 16,
    },
    bikeImage: { width: 50 },
});

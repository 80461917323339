/* eslint-disable camelcase */
/* eslint-disable no-empty */
import * as Localize from 'react-native-localize';
import { observable, action, flow } from 'mobx';
import { isValidNumber, AsYouType, getPhoneCode, parse } from 'libphonenumber-js';
import { Linking, Platform } from 'react-native';
import { persist, create } from 'mobx-persist';

import DeviceInfo from 'modules/deviceInfo/deviceInfo';
import AsyncStorage from 'modules/asyncStorage/asyncStorage';
import countryCodes from 'lib/flags';
import { Analytics } from 'lib';
import { BoltClient } from 'services/providers';
import { ServicesStore } from '..';
import { generateUUID, uuidv4 } from 'services/Utils';

const hydrate = create({ storage: AsyncStorage });

export default class BoltServiceStore {
    servicesRootStore: ServicesStore;

    constructor(servicesStore: ServicesStore) {
        this.servicesRootStore = servicesStore;
        this.getCarrierInfo();

        hydrate('boltServiceStore', this);
    }

    @persist
    @observable
    phoneUUID: string = generateUUID();

    @observable sessionId: string = uuidv4();

    @observable phoneNumber: string = '';

    @observable countryCode: any = '1';

    @observable countryName: any = 'us';

    @observable asYouType: AsYouType = new AsYouType(this.countryName.toUpperCase());

    @observable validPhoneNumber: boolean = false;

    @observable OTP: string = '';

    @observable errorMessage: string = '';

    clearStore = () => {
        const { setRefreshing } = this.servicesRootStore;
        this.getCarrierInfo();
        this.errorMessage = '';
        this.phoneNumber = '';
        this.asYouType = new AsYouType(this.countryName.toUpperCase());
        this.validPhoneNumber = false;
        this.OTP = '';
        this.errorMessage = '';
        setRefreshing(false);
    };

    @action
    clearError = () => {
        this.errorMessage = '';
    };

    @action
    getCarrierInfo = () => {
        let countryCode = 'us';
        try {
            countryCode = Localize.getCountry();
        } catch (error) {}
        const codes = countryCodes.filter(
            item => item.code.toLowerCase() === countryCode.toLowerCase()
        );
        if (codes.length > 0) {
            this.countryName = codes[0].code.toLowerCase();
            this.countryCode = codes[0].dial_code.replace(/\D/g, '');
            this.asYouType = new AsYouType(this.countryName.toUpperCase());
        }
    };

    @action
    selectCountry = country => {
        this.countryCode = country.dial_code.replace(/\D/g, '');
        this.countryName = country.code.toLowerCase();
        this.asYouType = new AsYouType(this.countryName.toUpperCase());
        this.clearError();
    };

    @action
    setPhoneNumber = (text: string) => {
        this.asYouType.reset();
        if (this.phoneNumber.length - text.length === 1 && this.phoneNumber.slice(-1) === ')') {
            this.phoneNumber = text.replace('(', '');
        } else {
            this.phoneNumber = this.asYouType.input(text);
        }
        this.validPhoneNumber = isValidNumber(text, this.countryName.toUpperCase());
        this.clearError();
    };

    rawPhoneNumber = () => {
        if (this.phoneNumber.indexOf('+') >= 0) {
            const numberInfo = parse(this.phoneNumber);
            if (numberInfo && numberInfo.country) {
                const countryCode = getPhoneCode(numberInfo.country);
                this.countryCode = countryCode;
                return this.phoneNumber.replace(`+${countryCode}`, '').replace(/\D/g, '');
            }
        }
        return this.phoneNumber.replace(/\D/g, '');
    };

    @action
    setOTP = (text: string) => {
        this.OTP = text;
        this.clearError();
    };

    onPhoneNumberSubmit = flow(function*(this: BoltServiceStore) {
        const {
            rootStore: {
                stores: {
                    mapStore: { lastUserLatitude, lastUserLongitude },
                    navigationStore: { navigate },
                },
            },
            setRefreshing,
        } = this.servicesRootStore;
        try {
            setRefreshing(true);
            const { data: { message = null } = {} } = yield BoltClient.sendVerificationCode({
                location: {
                    latitude: lastUserLatitude,
                    longitude: lastUserLongitude,
                },
                phone: `+${this.countryCode}${this.rawPhoneNumber()}`,
                phone_uuid: this.phoneUUID,
                session_id: this.sessionId,
            });

            switch (message) {
                case 'OK':
                    setRefreshing(false);
                    navigate('OTP');
                    break;
                case 'INVALID_PHONE':
                    throw {
                        message: 'Invalid phone number.',
                    };
                case 'SMS_CONFIRM_CODE_RESEND_LIMIT_REACHED':
                    throw {
                        message: 'SMS confirm code resend limit reached.',
                    };
                default:
                    throw {
                        message: 'Unable to verify phone number.',
                    };
            }
        } catch (err) {
            console.log('Bolt phone number error\n', { ...err });
            setRefreshing(false);
            const { message } = err;
            if (message) {
                this.errorMessage = message;
            } else {
                this.errorMessage = 'Unable to login.';
            }
        }
    }).bind(this);

    onOTPSubmit = flow(function*(this: BoltServiceStore) {
        const {
            setRefreshing,
            rootStore: {
                stores: {
                    mapStore: { lastUserLatitude, lastUserLongitude },
                },
            },
            handleBoltLogin,
        } = this.servicesRootStore;
        try {
            setRefreshing(true);
            const { data: { message = null } = {} } = yield BoltClient.sendOTP({
                code: this.OTP,
                location: {
                    latitude: lastUserLatitude,
                    longitude: lastUserLongitude,
                },
                phone: `+${this.countryCode}${this.rawPhoneNumber()}`,
                phone_uuid: this.phoneUUID,
                session_id: this.sessionId,
            });

            switch (message) {
                case 'OK':
                    yield handleBoltLogin({
                        phone: `+${this.countryCode}${this.rawPhoneNumber()}`,
                        phone_uuid: this.phoneUUID,
                    });
                    setRefreshing(false);
                    break;
                case 'USER_NOT_FOUND':
                    throw {
                        message: 'User not found.',
                    };
                case 'REGISTRATION_CODE_NOT_FOUND':
                    throw {
                        message: 'Verification code not found.',
                    };
                default:
                    throw {
                        message: 'Unable to verify code.',
                    };
            }
        } catch (err) {
            console.log('Bolt OTP error\n', { ...err });
            setRefreshing(false);
            const { message } = err;
            if (message) {
                this.errorMessage = message;
            } else {
                this.errorMessage = 'Unable to verify code.';
            }
        }
    }).bind(this);

    downloadApp = () => {
        Linking.openURL(
            Platform.OS === 'android'
                ? 'https://play.google.com/store/apps/details?id=ee.mtakso.client'
                : 'https://apps.apple.com/pl/app/bolt-taxify/id675033630'
        )
            .then(() => {
                Analytics.trackServiceLinking('bolt', 'connecting', 'download_app');
            })
            .catch(() => {});
    };
}

import React, { Component } from 'react';
import { View, Text, TextInput } from 'react-native';
import { observer, inject } from 'mobx-react';
import KeyboardAwareScrollView from 'modules/keyboardAwareScrollView/keyboardAwareScrollView';

import AdditionalInfoStyles from '../../styles/AdditionalInfoStyles';
import ServicesStore from 'stores/ServicesStore';
import TitleNavigationBar from '../../../navigationBars/TitleNavigationBar';
import { Colors } from 'themes';
import { Localizable, Analytics } from 'lib';
import { NextButton, ErrorMessage } from '../../providerConnectingComponents';
import { Stores } from 'stores/RootStore';

interface Props {
    servicesStore: ServicesStore;
}

@inject(({ servicesStore }: Stores) => ({ servicesStore }))
@observer
export default class Email extends Component<Props, {}> {
    componentDidMount() {
        Analytics.trackServiceLinking('lyft', 'connecting', 'email_screen');
    }

    render() {
        const {
            servicesStore: {
                hideProviderPopup,
                lyftServiceStore: { email, setLyftEmail, onLyftEmailSubmit, errorMessage },
            },
        } = this.props;
        return (
            <View style={AdditionalInfoStyles.container}>
                <TitleNavigationBar title="Lyft" handleBackClick={() => hideProviderPopup()} />
                <KeyboardAwareScrollView
                    contentContainerStyle={{ paddingHorizontal: 20 }}
                    keyboardShouldPersistTaps="handled"
                >
                    <Text style={AdditionalInfoStyles.title}>
                        {Localizable.t('additionalInfo.enterEmail')}
                    </Text>

                    <Text style={AdditionalInfoStyles.description}>
                        {Localizable.t('additionalInfo.placeholderEmail')}
                    </Text>
                    <View style={AdditionalInfoStyles.inputBorder}>
                        <TextInput
                            style={[AdditionalInfoStyles.textInput]}
                            keyboardType="email-address"
                            placeholder={Localizable.t('additionalInfo.placeholderEmail')}
                            placeholderTextColor={Colors.lightGrayColor}
                            value={email}
                            onChangeText={setLyftEmail}
                            autoFocus
                            autoCorrect={false}
                            autoCapitalize="none"
                            selectionColor={Colors.obiTeal}
                            onSubmitEditing={onLyftEmailSubmit}
                            returnKeyType="done"
                        />
                    </View>

                    {errorMessage.length !== 0 && <ErrorMessage message={errorMessage} />}
                    <NextButton onPress={onLyftEmailSubmit} />
                </KeyboardAwareScrollView>
            </View>
        );
    }
}

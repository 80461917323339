import getEnv from 'modules/config/config';
import React, { Component } from 'react';
import creditCardType, { types as CardType } from 'credit-card-type';
import moment from 'moment';
import KeyboardAwareScrollView from 'modules/keyboardAwareScrollView/keyboardAwareScrollView';
import { NavigationParams } from 'react-navigation';
import { TextInputMask } from 'react-native-masked-text';
import { View, Text, TextInput, TouchableOpacity } from 'react-native';
import { observer, inject } from 'mobx-react';

import TitleNavigationBar from 'components/navigationBars/TitleNavigationBar';

import AddCreditCardStyles from './styles/AddCreditCardStyles';
import AppStore from '../../stores/AppStore';
import AuthStore from '../../stores/AuthStore';
import GradientButton from '../../components/GradientButton';
import NavigationStore from 'stores/navigationStores/NavigationStore';
import { Analytics, Localizable } from '../../lib';
import { Colors } from '../../themes';
import { Stores } from '../../stores/RootStore';
import { showErrorAlert, showAlert } from '../../services/Utils';

// const stripe = require('../../lib/Stripe.js')(Config.STRIPE_URL, Config.STRIPE_KEY);

interface Props {
    appStore: AppStore;
    authStore: AuthStore;
    navigation: NavigationParams;
    navigationStore: NavigationStore;
}

interface State {
    number: string;
    expDate: string;
    cvv: string;
    valid: boolean;
    zip: string;
    name: string;
    numberValid: boolean;
    business: boolean;
}

// TODO: Migrate to store
@inject(({ appStore, authStore, navigationStore }: Stores) => ({
    appStore,
    authStore,
    navigationStore,
}))
@observer
export default class AddCreditCard extends Component<Props, State> {
    expDate?: View;

    number?: TextInput | null;

    name?: TextInput | null;

    cvv?: View;

    zip?: TextInput | null;

    constructor(props: Props) {
        super(props);
        this.state = {
            number: '',
            expDate: '',
            cvv: '',
            valid: false,
            zip: '',
            name: '',
            numberValid: false,
            business: false,
        };
    }

    handleBackClick = () => {};

    validFields = () => {
        const { numberValid, name, zip } = this.state;

        const isValid =
            numberValid &&
            this.expDate.isValid() &&
            this.cvv.isValid() &&
            name.length > 4 &&
            zip.length > 3;
        this.setState({ valid: isValid });
    };

    handleSave = () => {
        const { valid, expDate, cvv, zip, name, number, business } = this.state;
        const {
            appStore: { hideLoading, showLoading },
            navigation: {
                state: {
                    params: { onClose },
                },
            },
            navigationStore: { goBack },
        } = this.props;

        if (!valid) {
            showAlert(Localizable.t('profile.creditCardFillInputs'));
            return;
        }
        const expiryDate = moment(`1/${expDate}`, 'DD/MM/YY');
        showLoading();
        // stripe.createCardToken(
        //     number,
        //     expiryDate.format('M'),
        //     expiryDate.format('YY'),
        //     cvv,
        //     zip,
        //     name,
        //     resp => {
        //         if (!resp.ok) {
        //             hideLoading();
        //             showErrorAlert(JSON.parse(resp.text));
        //         } else {
        //             RestClient.addCard(resp.body.id)
        //                 .then(response => {
        //                     RestClient.changeCardType(response.data.id, business)
        //                         .then(() => {
        //                             showAlert(Localizable.t('profile.creditCardAdded'));
        //                             hideLoading();
        //                             goBack();
        //                             onClose();
        //                         })
        //                         .catch(error => {
        //                             showErrorAlert(error);
        //                             hideLoading();
        //                         });
        //                 })
        //                 .catch(error => {
        //                     showErrorAlert(error);
        //                     hideLoading();
        //                 });
        //         }
        //     }
        // );
    };

    updateCard = (text: string) => {
        const rawCardNumber = this.rawCardNumber(text);
        const cardTypes = creditCardType(rawCardNumber);
        if (cardTypes.length === 1) {
            const cardType = cardTypes[0];
            if (cardType.lengths.length > 0) {
                const maxValue = cardType.lengths.reduce((a, b) => Math.max(a, b));
                if (rawCardNumber.length > maxValue) {
                    this.name.focus();
                    return;
                }
            }
            this.setState(
                {
                    number: this.prettyCardNumber(this.rawCardNumber(text), cardType),
                    numberValid: cardType.lengths.includes(this.rawCardNumber(text).length),
                },
                () => this.validFields()
            );
        } else {
            this.setState({ number: text }, () => this.validFields());
        }
    };

    prettyCardNumber = (cardNumber: string, card) => {
        if (card) {
            const offsets = [0].concat(card.gaps).concat([cardNumber.length]);
            const components = [];

            for (let i = 0; offsets[i] < cardNumber.length; i += 1) {
                const start = offsets[i];
                const end = Math.min(offsets[i + 1], cardNumber.length);
                components.push(cardNumber.substring(start, end));
            }
            return components.join(' ');
        }

        return cardNumber;
    };

    rawCardNumber = (number: string) => number.replace(/\D/g, '');

    render() {
        const {
            authStore,
            appStore,
            navigationStore: { goBack },
        } = this.props;
        const { business, cvv, expDate, name, number, valid, zip } = this.state;
        return (
            <View style={AddCreditCardStyles.outerContainer}>
                <TitleNavigationBar
                    authStore={authStore}
                    appStore={appStore}
                    title={Localizable.t('profile.creditCardTitle')}
                    handleBackClick={() => goBack()}
                />
                <KeyboardAwareScrollView
                    style={AddCreditCardStyles.contentView}
                    contentContainerStyle={{ paddingBottom: 20 }}
                    keyboardShouldPersistTaps="handled"
                >
                    <Text style={AddCreditCardStyles.headerText}>
                        {Localizable.t('profile.creditCardNumber')}
                    </Text>
                    <TextInput
                        ref={ref => {
                            this.number = ref;
                        }}
                        autoFocus
                        keyboardType="numeric"
                        placeholder="**** **** **** ****"
                        placeholderTextColor="black"
                        value={number}
                        onChangeText={text => {
                            this.updateCard(text);
                            // if (this.state.number.length && this.number.isValid()) {
                            //   this.name.focus();
                            // }
                        }}
                    />
                    <View style={AddCreditCardStyles.separator} />
                    <Text style={AddCreditCardStyles.headerText}>
                        {Localizable.t('profile.creditCardName')}
                    </Text>
                    <TextInput
                        ref={ref => {
                            this.name = ref;
                        }}
                        autoCapitalize="words"
                        placeholder={Localizable.t('profile.creditCardName')}
                        placeholderTextColor="black"
                        value={name}
                        onChangeText={text => {
                            this.setState({ name: text }, () => this.validFields());
                        }}
                    />
                    <View style={AddCreditCardStyles.separator} />
                    <View style={AddCreditCardStyles.flexRow}>
                        <View style={AddCreditCardStyles.flexOne}>
                            <Text style={AddCreditCardStyles.headerText}>
                                {Localizable.t('profile.creditCardExp')}
                            </Text>
                            <TextInputMask
                                ref={(ref: View) => {
                                    this.expDate = ref;
                                }}
                                type="datetime"
                                placeholder="**/**"
                                placeholderTextColor="black"
                                options={{
                                    format: 'MM/YY',
                                }}
                                value={expDate}
                                onChangeText={(text: string) => {
                                    this.setState({ expDate: text }, () => this.validFields());
                                    if (expDate.length && this.expDate.isValid()) {
                                        this.cvv.getElement().focus();
                                    }
                                }}
                            />
                        </View>
                        <View style={AddCreditCardStyles.flexOne}>
                            <Text style={AddCreditCardStyles.headerText}>
                                {Localizable.t('profile.creditCardCVV')}
                            </Text>
                            <TextInputMask
                                ref={(ref: View) => {
                                    this.cvv = ref;
                                }}
                                keyboardType="numeric"
                                type="custom"
                                placeholder="***"
                                placeholderTextColor="black"
                                options={{
                                    mask: '9999',
                                    validator(value: string) {
                                        return value.length === 3 || value.length === 4;
                                    },
                                }}
                                secureTextEntry
                                value={cvv}
                                onChangeText={(text: string) => {
                                    this.setState({ cvv: text }, () => this.validFields());
                                }}
                            />
                        </View>
                        <View style={AddCreditCardStyles.flexOne}>
                            <Text style={AddCreditCardStyles.headerText}>
                                {Localizable.t('profile.creditCardZIP')}
                            </Text>
                            <TextInput
                                ref={ref => {
                                    this.zip = ref;
                                }}
                                placeholder="******"
                                placeholderTextColor="black"
                                value={zip}
                                onChangeText={text => {
                                    this.setState({ zip: text }, () => this.validFields());
                                }}
                            />
                        </View>
                    </View>
                    <View style={AddCreditCardStyles.separator} />
                    <Text style={AddCreditCardStyles.headerText}>
                        {Localizable.t('profile.creditCardType')}
                    </Text>
                    <View style={AddCreditCardStyles.flexRow}>
                        <TouchableOpacity
                            style={AddCreditCardStyles.flexOne}
                            onPress={() => this.setState({ business: false })}
                        >
                            <View
                                style={[
                                    AddCreditCardStyles.typeView,
                                    {
                                        backgroundColor: business
                                            ? Colors.transparent
                                            : Colors.obiTeal,
                                        borderWidth: business ? 1 : 0,
                                        borderColor: Colors.lightGrayColor,
                                        marginRight: 20,
                                    },
                                ]}
                            >
                                <Text
                                    style={[
                                        AddCreditCardStyles.typeText,
                                        {
                                            color: business ? Colors.lightGrayColor : Colors.white,
                                        },
                                    ]}
                                >
                                    {Localizable.t('profile.personal')}
                                </Text>
                            </View>
                        </TouchableOpacity>
                        <TouchableOpacity
                            style={AddCreditCardStyles.flexOne}
                            onPress={() => this.setState({ business: true })}
                        >
                            <View
                                style={[
                                    AddCreditCardStyles.typeView,
                                    {
                                        backgroundColor: business
                                            ? Colors.businessCardColor
                                            : Colors.transparent,
                                        borderWidth: business ? 0 : 1,
                                        borderColor: Colors.lightGrayColor,
                                    },
                                ]}
                            >
                                <Text
                                    style={[
                                        AddCreditCardStyles.typeText,
                                        {
                                            color: business ? Colors.white : Colors.lightGrayColor,
                                        },
                                    ]}
                                >
                                    {Localizable.t('profile.business')}
                                </Text>
                            </View>
                        </TouchableOpacity>
                    </View>
                    <GradientButton
                        style={AddCreditCardStyles.gradientButton}
                        title={Localizable.t('profile.creditCardAdd')}
                        active={valid}
                        handlePress={this.handleSave}
                    />
                </KeyboardAwareScrollView>
            </View>
        );
    }
}

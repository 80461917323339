import React from 'react';
import { observer, inject } from 'mobx-react';

import CustomMarker from '../../../taxis/Marker';
import ExampleRouteTooltip from './ExampleRouteTooltip';
import { AddressStore, TaxiStore, OnboardingStore } from 'stores';
import { RidePopup } from '../../../taxis';
import { Stores } from 'stores/RootStore';

interface Props {
    addressStore: AddressStore;
    onboardingStore: OnboardingStore;
    taxiStore: TaxiStore;
}

export default inject(({ addressStore, onboardingStore, taxiStore }: Stores) => ({
    addressStore,
    onboardingStore,
    taxiStore,
}))(
    observer(
        ({
            addressStore,
            onboardingStore: { exampleRouteStore },
            taxiStore: { taxiRouteStore },
        }: Props) =>
            taxiRouteStore.showDestinationLocationMarker ? (
                <CustomMarker
                    anchor={{
                        x: 0,
                        y: exampleRouteStore.exampleDestinationTooltipVisible ? 0.5 : 1,
                    }}
                    key="destinationLocation"
                    zIndex={12}
                    coordinate={taxiRouteStore.plainMarkers[1].coords}
                    onPress={addressStore.showDestination}
                >
                    <RidePopup title={addressStore.destination || ''} />
                    <ExampleRouteTooltip />
                </CustomMarker>
            ) : null
    )
);

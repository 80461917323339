/* eslint-disable camelcase */
import axios from 'axios';
import { Platform } from 'react-native';

import DeviceInfo from 'modules/deviceInfo/deviceInfo';
import { LatLng } from 'modules/maps/maps';
import proxy from '../Proxy';

const instance = axios.create({
    baseURL: `https://user.taxify.eu/user`,
    timeout: 40000,
});

class BoltClient {
    constructor() {
        instance.interceptors.request.use(request => {
            console.log('[BoltClient] Starting Request:', { ...request });
            return proxy(request);
        });
        instance.interceptors.response.use(
            response => {
                console.log('[BoltClient] Response:', { ...response });
                return response;
            },
            error => {
                console.log('[BoltClient] Response error:', { ...error });
                return Promise.reject(error);
            }
        );
    }

    getHeaders = () => ({
        authority: 'user.taxify.eu',
        'content-type': 'application/json',
        'accept-encoding': 'gzip, deflate, br',
        'user-agent': 'Taxify/2 CFNetwork/1107.1 Darwin/19.0.0',
        'accept-language': 'en-en',
        accept: '*/*',
    });

    sendVerificationCode = ({
        phone,
        phone_uuid,
        location,
        session_id,
    }: {
        phone: string;
        phone_uuid: string;
        location: LatLng;
        session_id: string;
    }) => {
        return instance.post(
            '/register/phone',
            {
                deviceName: DeviceInfo.getDeviceName(),
                version: 'CI.4.13',
                preferred_verification_method: 'sms',
                lat: location.latitude,
                lng: location.longitude,
                gps_lat: location.latitude,
                gps_lng: location.longitude,
                session_id,
                deviceType: DeviceInfo.getDeviceType(),
                country: 'en',
                language: 'en',
                phone_uuid,
                device_os_version: Platform.Version,
                phone,
                facebook_access_token_expires: 0,
            },
            { headers: this.getHeaders() }
        );
    };

    sendOTP = ({
        phone,
        phone_uuid,
        location,
        code,
        session_id,
    }: {
        phone: string;
        phone_uuid: string;
        location: LatLng;
        code: string;
        session_id: string;
    }) => {
        return instance.post('/confirmVerification', {
            deviceName: DeviceInfo.getDeviceName(),
            version: 'CI.4.13',
            preferred_verification_method: 'sms',
            lat: location.latitude,
            lng: location.longitude,
            gps_lat: location.latitude,
            gps_lng: location.longitude,
            session_id,
            deviceType: DeviceInfo.getDeviceType(),
            country: 'en',
            language: 'en',
            phone_uuid,
            device_os_version: Platform.Version,
            phone,
            facebook_access_token_expires: 0,
            verification: {
                confirmation_data: {
                    code,
                },
            },
        });
    };
}

export default new BoltClient();

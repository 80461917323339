import React, { StatelessComponent } from 'react';
import { observer, inject } from 'mobx-react';

import PlainMarker from './PlainMarker';
import TaxiStore from '../../../../stores/TaxiStore';
import { Stores } from '../../../../stores/RootStore';

interface Props {
    taxiStore: TaxiStore;
}

const PlainMarkers = ({ taxiStore }: Props) => {
    const { taxiRouteStore } = taxiStore;

    if (!taxiRouteStore.showMarkers || !taxiRouteStore.plainMarkers.length) {
        return null;
    }

    return (
        <>
            {taxiRouteStore.plainMarkers.map((marker, i) => {
                return <PlainMarker key={marker.title} marker={marker} index={i} />;
            })}
        </>
    );
};

export default inject(({ taxiStore }: Stores) => ({ taxiStore }))(observer(PlainMarkers));

import React, { useEffect } from 'react';
import { View, Platform } from 'react-native';
import { observer } from 'mobx-react';

import Alert from 'modules/alert/alert';
import DropdownAlert from 'modules/dropDownAlert/dropDownAlert';
import Reactotron from 'modules/reactotron/reactotron';
import codePush from 'modules/codePush/codePush';
import withDevMenuTrigger from 'modules/devMenuTrigger/devMenuTrigger';

import DrawerWeb from 'components/drawer/DrawerWeb';
import Landing from 'containers/setup/Landing';
import MapLoader from 'containers/setup/MapLoader';
import Router from 'routers/Router';
import { Analytics } from 'lib';
import { RootStore } from 'stores';
import { Colors, ApplicationStyles } from 'themes';
import { Modals, Loading } from 'components';
import { Utils } from 'services';
import { withStoreProvider } from 'enhancers';
import getEnv from 'modules/config/config';

const rootStore = new RootStore();
const storesContext = React.createContext(rootStore);
const useStores = () => React.useContext(storesContext);
const App = withDevMenuTrigger(
    codePush(
        withStoreProvider(rootStore)(
            observer(() => {
                const {
                    stores: {
                        appStore: { appDidMount, appWillUnmount },
                        authStore: { ready },
                        layoutStore: { onAppLayout, layoutReady },
                        navigationStore: { setNavigator, onNavigationStateChange },
                    },
                } = useStores();
                const onAppMount = async () => {
                    console.disableYellowBox = true;
                    if (!__DEV__) {
                        console.log = () => {};
                    }
                    await appDidMount();
                    Analytics.trackScreenView('00_Launch Screen');
                };
                useEffect(() => {
                    onAppMount();
                    return () => appWillUnmount();
                }, []);
                return (
                    <View style={ApplicationStyles.app} onLayout={onAppLayout}>
                        {ready && layoutReady && (
                            <Router
                                ref={setNavigator}
                                onNavigationStateChange={onNavigationStateChange}
                                uriPrefix="bellhop://"
                                key="router"
                            />
                        )}
                        <Loading />
                        <DropdownAlert
                            imageStyle={{ opacity: 0, width: 1 }}
                            infoColor={Colors.obiTeal}
                            ref={ref => Utils.setDropdown(ref)}
                        />
                        <Modals />
                        <DrawerWeb />
                        <MapLoader />
                        <Landing />
                        {Platform.select({ web: <Alert />, default: null })}
                    </View>
                );
            })
        )
    )
);

const AppWithOverlay = __DEV__ ? Reactotron.overlay(App) : App;

export default AppWithOverlay;
export { useStores };

import React from 'react';
import { Image, Text, View } from 'react-native';

import ArrowDown from './ArrowDown';
import LoadingView from './LoadingView';
import styles from './styles/MapLandingPickupMarkerStyles';
import { Images } from '../../../themes';

export default () => (
    <View style={styles.container}>
        <View style={styles.innerContainer}>
            <Text style={styles.title}>Locating you</Text>
            <LoadingView />
        </View>
        <ArrowDown />
        <Image source={Images.icons.locationDot} resizeMode="contain" style={styles.dot} />
    </View>
);

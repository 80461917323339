import { StyleSheet } from 'react-native';
import { Fonts, Metrics, Colors } from '../../../themes';

export default StyleSheet.create({
    container: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        top: 0,
        alignItems: 'center',
        backgroundColor: Colors.grayBackgroundColor,
    },
    navigationView: {
        alignSelf: 'stretch',
        backgroundColor: 'white',
        marginBottom: 10,
    },
    scrollView: {
        flex: 1,
        alignSelf: 'stretch',
    },
    scrollViewContentContainer: {
        alignItems: 'center',
        paddingBottom: 10,
    },
    driver: {
        fontFamily: Fonts.type.base,
        fontWeight: '500',
        fontSize: Metrics.normalize(15),
        color: Colors.darkTextColor,
        margin: 10,
    },
    roundContainer: {
        borderRadius: 10,
        backgroundColor: 'white',
        margin: 10,
        padding: 10,
        alignItems: 'center',
        alignSelf: 'stretch',
        justifyContent: 'center',
    },
    separator: {
        margin: 10,
        height: 2,
        backgroundColor: Colors.grayBackgroundColor,
        alignSelf: 'stretch',
    },
    costLabel: {
        fontFamily: Fonts.type.base,
        fontWeight: '500',
        fontSize: Metrics.normalize(13),
        color: Colors.darkTextColor,
        marginTop: 10,
        marginBottom: 4,
    },
    costValue: {
        fontFamily: Fonts.type.base,
        fontWeight: '500',
        fontSize: Metrics.normalize(24),
        color: Colors.obiTeal,
        marginBottom: 10,
    },
    chooseContainerTip: {
        marginHorizontal: 100,
        marginVertical: 15,
        alignSelf: 'stretch',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    chooseContainer: {
        marginHorizontal: 40,
        alignSelf: 'stretch',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    plusButtonView: {
        width: 50,
        height: 50,
        borderRadius: 25,
        backgroundColor: 'white',
        alignContent: 'center',
        justifyContent: 'center',
        shadowColor: Colors.grayTextColor,
        shadowOpacity: 0.3,
        shadowRadius: 1,
        shadowOffset: {
            height: 0,
            width: 0,
        },
    },
    plusButtonText: {
        fontSize: Metrics.normalize(17),
        color: Colors.darkTextColor,
        fontWeight: '300',
        textAlign: 'center',
        fontFamily: Fonts.type.base,
    },
    activeTipText: {
        fontSize: Metrics.normalize(20),
        fontFamily: Fonts.type.base,
        color: Colors.darkTextColor,
        fontWeight: '600',
    },
    minusButtonView: {
        width: 50,
        height: 50,
        borderRadius: 25,
        backgroundColor: 'white',
        alignContent: 'center',
        justifyContent: 'center',
        shadowColor: Colors.grayTextColor,
        shadowOpacity: 0.3,
        shadowRadius: 1,
        shadowOffset: {
            height: 0,
            width: 0,
        },
    },
    minusButtonText: {
        fontSize: Metrics.normalize(17),
        fontFamily: Fonts.type.base,
        color: Colors.darkTextColor,
        fontWeight: '300',
        textAlign: 'center',
    },
    starImage: {
        flex: 1,
        margin: 3,
    },
    doneButton: {
        alignSelf: 'stretch',
        margin: 10,
        marginBottom: Metrics.paddingBottom + 20,
    },
});

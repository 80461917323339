import { TomTomClient } from 'services';

const Geocoder = {
    geocodePosition: async ({ lat, lng }) => {
        try {
            const response = await TomTomClient.getLocationNameForCoordinates({
                latitude: lat,
                longitude: lng,
            });
            if (response?.data?.addresses?.length) {
                const data = response.data.addresses[0];
                const result = {
                    formattedAddress: data.address.freeformAddress,
                    position: {
                        lat: Number(data.position.split(',')[0]),
                        lng: Number(data.position.split(',')[1]),
                    },
                };
                return [result];
            }
        } catch (error) {
            throw error;
        }
    },
};

export default Geocoder;

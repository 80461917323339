import React from 'react';
import { Text, TouchableOpacity, View, ViewStyle } from 'react-native';

import ButtonStyles from './styles/ButtonStyles';
import { Colors } from '../themes';

interface Props {
    active: boolean;
    handlePress: () => void;
    style?: ViewStyle;
    title: string;
}

export default ({ active, handlePress, style, title }: Props) => (
    <TouchableOpacity style={style} onPress={handlePress}>
        <View
            style={[
                ButtonStyles.innerContainer,
                { backgroundColor: active ? Colors.obiTeal : Colors.white },
            ]}
        >
            <Text
                style={[
                    ButtonStyles.titleText,
                    { color: active ? Colors.darkPurple : Colors.black },
                ]}
            >
                {title}
            </Text>
        </View>
    </TouchableOpacity>
);

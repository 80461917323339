import React, { Component } from 'react';
import KeyboardAwareScrollView from 'modules/keyboardAwareScrollView/keyboardAwareScrollView';
import { View, Text, TextInput } from 'react-native';
import { observer, inject } from 'mobx-react';

import AdditionalInfoStyles from '../../styles/AdditionalInfoStyles';
import ServicesStore from 'stores/ServicesStore';
import TitleNavigationBar from '../../../navigationBars/TitleNavigationBar';
import { Colors } from 'themes';
import { Localizable, Analytics } from 'lib';
import { ErrorMessage, NextButton } from '../../providerConnectingComponents';
import { NavigationStore } from 'stores';

interface Props {
    servicesStore: ServicesStore;
    navigationStore: NavigationStore;
}

@inject(({ navigationStore, servicesStore }) => ({ navigationStore, servicesStore }))
@observer
export default class PasswordRecovery extends Component<Props> {
    componentDidMount() {
        Analytics.trackServiceLinking('uber', 'connecting', 'password_recovery_screen');
    }

    render() {
        const {
            servicesStore: {
                uberServiceStore: {
                    onUberPasswordRecoverySubmit,
                    passRecoveryEmail,
                    passRecoveryEmailHint,
                    passRecoverySuccess,
                    setUberEmailRecovery,
                    errorMessage,
                },
            },
            navigationStore: { goBack },
        } = this.props;
        return (
            <View style={AdditionalInfoStyles.container}>
                <TitleNavigationBar title="Uber" handleBackClick={() => goBack()} />

                <KeyboardAwareScrollView
                    contentContainerStyle={{ paddingHorizontal: 20 }}
                    keyboardShouldPersistTaps="handled"
                >
                    {passRecoverySuccess
                        ? [
                              <Text key="text1" style={AdditionalInfoStyles.title}>
                                  {Localizable.t('additionalInfo.recoverySuccessful')}
                              </Text>,
                              <Text key="text2" style={AdditionalInfoStyles.textInput}>
                                  {Localizable.t('additionalInfo.checkEmail')}
                              </Text>,
                          ]
                        : [
                              <Text key="text3" style={AdditionalInfoStyles.title}>
                                  {Localizable.t('additionalInfo.uber.enterUberEmail')}
                              </Text>,
                              <View key="View1" style={AdditionalInfoStyles.inputBorder}>
                                  <TextInput
                                      style={[AdditionalInfoStyles.textInput]}
                                      keyboardType="email-address"
                                      placeholder={passRecoveryEmailHint}
                                      placeholderTextColor={Colors.lightGrayColor}
                                      value={passRecoveryEmail}
                                      onChangeText={setUberEmailRecovery}
                                      autoFocus
                                      autoCorrect={false}
                                      autoCapitalize="none"
                                      selectionColor={Colors.obiTeal}
                                      onSubmitEditing={onUberPasswordRecoverySubmit}
                                      returnKeyType="done"
                                  />
                              </View>,

                              errorMessage.length !== 0 && <ErrorMessage message={errorMessage} />,
                          ]}
                    <NextButton
                        onPress={() => {
                            if (passRecoverySuccess) {
                                hideProviderPopup();
                            } else {
                                onUberPasswordRecoverySubmit();
                            }
                        }}
                    />
                </KeyboardAwareScrollView>
            </View>
        );
    }
}

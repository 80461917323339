import React from 'react';
import { observer } from 'mobx-react';
import { Image } from 'react-native';
import MapView from 'modules/maps/maps';
import { Metrics } from '../../../../themes';
import CustomMarker from '../../../taxis/Marker';

interface Props {
    marker: any;
    index: number;
}

export default observer(({ marker, index }: Props) => {
    const showPlainMarker = !!marker.coords && !!marker.coords.latitude;
    if (!showPlainMarker) {
        return null;
    }
    const commonProps = {
        anchor: marker.centerOffset || { x: 0.5, y: 1 },
        key: marker.title + marker.coords.latitude,
        coordinate: marker.coords,
        title: marker.title,
        zIndex: index,
        rotation: marker.bearing || 0,
    };
    return (
        <CustomMarker {...commonProps} image={marker.image}>
            <Image source={marker.image} style={{ width: 10, height: 10 }} />
        </CustomMarker>
    );
});

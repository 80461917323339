import React, { useEffect } from 'react';
import { NavigationParams } from 'react-navigation';
import { View, TextInput } from 'react-native';
import { observer } from 'mobx-react';
import { useLocalStore } from 'mobx-react-lite';

import FavoriteAddressPickerStyles from './styles/FavoriteAddressPickerStyles';
import FavoriteAddressStore from '../../stores/FavoriteAddressStore';
import SuggestionsListView from '../../components/suggestions/SuggestionsListView';
import TitleNavigationBar from 'components/navigationBars/TitleNavigationBar';
import { Analytics, Localizable } from '../../lib';
import { useStores } from '../App';

interface Props {
    navigation: NavigationParams;
}

export default observer(({ navigation }: Props) => {
    const {
        stores: {
            navigationStore,
            navigationStore: { goBack },
            locationsStore,
        },
    } = useStores();
    useEffect(() => {
        Analytics.trackScreenView('04-04-addLocation');
    }, []);
    const suggestionSelected = () => {
        if (navigation.state?.params?.onClose) {
            navigation.state?.params?.onClose();
        }
        navigationStore.goBack();
    };
    const favoriteAddressStore = useLocalStore(
        () =>
            new FavoriteAddressStore(
                suggestionSelected,
                navigation.state?.params?.type,
                locationsStore
            )
    );
    return (
        <View style={FavoriteAddressPickerStyles.container}>
            <TitleNavigationBar
                title={`${Localizable.t('addressPicker.add')} ${navigation.state?.params?.type}`}
                handleBackClick={() => goBack()}
            />
            <View style={FavoriteAddressPickerStyles.locationContainer}>
                <TextInput
                    style={FavoriteAddressPickerStyles.locationText}
                    value={favoriteAddressStore.address}
                    onChangeText={favoriteAddressStore.updateAddress}
                    placeholder={Localizable.t('addressPicker.enterAddress')}
                    autoFocus
                    returnKeyType="search"
                />
            </View>
            <SuggestionsListView
                suggestionSelected={item => favoriteAddressStore.selectSuggestion(item)}
                sections={[
                    {
                        key: 'suggestionListKey',
                        data: favoriteAddressStore.suggestions,
                    },
                ]}
            />
        </View>
    );
});

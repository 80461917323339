/* eslint-disable consistent-return */
/* eslint-disable camelcase */
import Geolocation from '@react-native-community/geolocation';
import moment from 'moment';
import voca from 'voca';
import { Linking, Platform } from 'react-native';
import { observable, action, computed, reaction, flow } from 'mobx';
import { persist } from 'mobx-persist';

import Alert from 'modules/alert/alert';
import Geocoder from 'modules/geocoder/geocoder';
import Permissions from 'modules/permissions/permissions';
import { LatLng, Region } from 'modules/maps/maps';

import { GoogleClient, RestClient } from 'services';
import { PersistDataStore, AppLifecycle, UserLifecycle } from './RootStore';
import { Analytics } from '../lib';
import { ExactPrice, TaxiState } from 'domain';
import { distance, handleError, showErrorAlert, trimAddress } from '../services/Utils';
import {
    SelectedTaxiStore,
    BaseStore,
    RootStore,
    TaxiResultsStore,
    TaxiRideStore,
    TaxiRouteStore,
} from 'stores';

export default class TaxiStore extends BaseStore
    implements PersistDataStore, AppLifecycle, UserLifecycle {
    hydrateStore = (hydrate: Function) => {
        return hydrate('activeRideRequestId', this);
    };

    onUserReady = () => {
        const { getActiveRide } = this.taxiRideStore;
        getActiveRide();
    };

    @action
    onAppWilGoToForeground = () => {
        const { getActiveRide } = this.taxiRideStore;
        if (this.taxiResultsStore.shouldReloadInterval) {
            this.taxiResultsStore.addTaxiTypesInterval();
            this.taxiResultsStore.shouldReloadInterval = false;
        }
        getActiveRide();
    };

    @action
    onAppWillGoToBackground = () => {
        if (this.taxiResultsStore.taxiTypesInterval) {
            this.taxiResultsStore.removeTaxiTypesInterval();
            this.taxiResultsStore.shouldReloadInterval = true;
        }
    };

    onAppFinished = () => {
        const {
            selectedTaxiStore: { clearExactPriceTimer },
        } = this;
        this.removeListeners();
        clearExactPriceTimer();
    };

    taxiResultsStore = new TaxiResultsStore(this.rootStore);
    taxiRouteStore = new TaxiRouteStore(this.rootStore);
    taxiRideStore = new TaxiRideStore(this.rootStore);
    selectedTaxiStore = new SelectedTaxiStore(this.rootStore);
    blockRegion = true;

    @observable
    testStatusPopupShown: boolean = false;

    @observable
    updated: boolean = false;

    @observable
    fittingCoordinates: boolean = false;

    @observable
    lastKnownRegion?: Region;

    @observable
    currentUserLocation: any;

    @observable
    blockMap: boolean = false;

    @observable
    exactPrice: ExactPrice | null = null;

    @observable
    exactPriceLoading: boolean = false;

    @observable
    leavePickupAsIs: boolean = false;

    @observable
    locationGranted: boolean = false;

    @observable
    newDestinationAddress: string = '';

    @observable
    newDestinationAddressFocused: boolean = false;

    @observable
    suggestions: Array<any> = [];

    @observable
    suggestionsState: string = 'landing';

    @observable
    taxiState: TaxiState = 'start';

    @observable
    taxiStateHelperArray = ['start'];

    @observable
    userLocation: LatLng | null = null;

    @persist
    @observable
    ridesEnabled: boolean = true;

    constructor(rootStore: RootStore) {
        super(rootStore);
        reaction(
            () => this.taxiState,
            state => {
                if (state !== 'start') {
                    this.updated = true;
                } else {
                    const {
                        bikeStore: {
                            clearBikeOnMap,
                            clearBikeSelection,
                            clearBikesPickupDestination,
                        },
                    } = this.rootStore.stores;
                    clearBikeOnMap();
                    clearBikeSelection();
                    clearBikesPickupDestination();
                }
                this.taxiStateHelperArray.push(state);
            }
        );
    }

    @action
    setTaxiState = (taxiState: TaxiState) => {
        this.taxiState = taxiState;
    };

    @action
    selectPickup = () => {
        this.setTaxiState('selectPickup');
    };

    @action
    selectDestination = () => {
        this.setTaxiState('selectDestination');
    };

    @action
    setSuggestionsState = suggestionsState => {
        this.suggestionsState = suggestionsState;
    };

    @action
    setBlockMap = (blockMap: boolean) => {
        this.blockMap = blockMap;
    };

    @computed
    get prevTaxiState() {
        if (this.taxiStateHelperArray.length > 1) {
            return this.taxiStateHelperArray[this.taxiStateHelperArray.length - 2];
        }
        return '';
    }

    removeListeners = () => {
        clearInterval(this.taxiInterval);
        this.taxiResultsStore.removeListeners();
    };

    addRideStatusListener = () => {
        const { getCurrentRideStatus } = this.taxiRideStore;
        this.taxiInterval = setInterval(() => getCurrentRideStatus(false), 5000);
    };

    @action
    userPanMap = () => {
        this.updated = true;
        return false;
    };

    @action
    updateNewDestinationText = newDestination => {
        this.newDestinationAddress = newDestination;
        this.getSuggestions(newDestination, false);
    };

    @action
    focusNewDestination = focus => {
        this.newDestinationAddressFocused = focus;
    };

    location = (address: string, latitude: number, longitude: number) => ({
        address,
        latitude,
        longitude,
    });

    updateCurrentLocation = flow(function*(
        this: TaxiStore,
        pickup: boolean,
        location: any,
        setCurrent: boolean,
        updateRegion: boolean | null,
        userLocation: boolean | null,
        overrdideLocationText?: string | null,
        caller?: string
    ) {
        const {
            addressStore: {
                setPickup,
                setPickupLocation,
                setPickupTime,
                setPickupTimeLoading,
                setDestination,
                setDestinationLocation,
            },
            bikeStore: { setUserLocation },
        } = this.rootStore.stores;

        setUserLocation(location);

        this.leavePickupAsIs = !!overrdideLocationText;

        if (updateRegion) {
            this.setNewRegion(location.latitude, location.longitude);
            this.userLocation = location;
        }
        if (userLocation) {
            this.userLocation = location;
        }
        if (!(location && location.address)) {
            this.blockRegion = false;
        }
        if (setCurrent) {
            this.setPickupTime(location);
            if (!location || !location.address) {
                if (overrdideLocationText) {
                    if (pickup) {
                        setPickupLocation(
                            this.location(
                                
                                overrdideLocationText,
                                location.latitude,
                                location.longitude
                            )
                        );
                        setPickup(overrdideLocationText);
                    }
                    return;
                }

                try {
                    const res = yield Geocoder.geocodePosition({
                        lat: location.latitude,
                        lng: location.longitude,
                    });
                    if (res && res.length) {
                        const address = res[0];
                        const locationAddress = this.location(
                            address.formattedAddress,
                            address.position.lat,
                            address.position.lng
                        );

                        let locationText;
                        if (address.feature && address.locality) {
                            locationText = `${address.feature}, ${address.locality}`;
                        } else {
                            locationText = trimAddress(address.formattedAddress);
                        }

                        if (pickup) {
                            this.lastCenterLocation = location;
                            setPickupLocation(locationAddress);
                            setPickup(locationText);
                        } else {
                            setDestinationLocation(locationAddress);
                            setDestination(locationText);
                        }
                    }
                } catch (error) {
                    console.log('Geocoder error', { ...error });
                }
            }
        }
    }).bind(this);

    setPickupTime = flow(function*(this: TaxiStore, location: any) {
        const {
            addressStore: { setPickupTime, setPickupTimeLoading },
        } = this.rootStore.stores;
        setPickupTimeLoading(true);
        try {
            const { data } = yield RestClient.getPickupTime(location.latitude, location.longitude);
            setPickupTime(Math.round(data.time / 60));
            setPickupTimeLoading(false);
        } catch (error) {
            console.log('Update current location - get pickup time error', error);
            setPickupTimeLoading(false);
            setPickupTime(null);
        }
    }).bind(this);

    readableLatLng = ({ latitude, longitude }) =>
        `lat: ${latitude.toString().substring(0, 3)}>${latitude
            .toString()
            .slice(-2)} | lng: ${longitude.toString().substring(0, 3)}>${longitude
            .toString()
            .slice(-2)}`;

    @action
    updateCurrentUserLocation = (location: any, skipLocationCheck: boolean, caller: string) => {
        const {
            addressStore: { focusedInput },
            bikeStore: { setCurrentUserLocation },
        } = this.rootStore.stores;
        if (location && location.coordinate && location.coordinate.latitude === 0) return;
        setCurrentUserLocation(location.coordinate);
        this.currentUserLocation = location;
        if (
            !this.updated &&
            (!this.lastCenterLocation ||
                distance(location.coordinate, this.lastCenterLocation) > 0.1) &&
            !focusedInput
        ) {
            this.getUserLocation(
                true,
                skipLocationCheck,
                `TaxiStore updateCurrentUserLocation - ${caller} - ${this.readableLatLng(
                    location.coordinate
                )}`
            );
        }
    };

    @action
    getUserLocation = (setPickup: boolean, skipLocationCheck: boolean, caller?: string) => {
        if (this.currentUserLocation) {
            const newCoordinates = this.rootStore.stores.locationsStore.favorites.find(fav => {
                const dist = distance(fav.location, this.currentUserLocation.coordinate);
                return dist < 0.06;
            });

            this.updateCurrentLocation(
                true,
                newCoordinates ? newCoordinates.location : this.currentUserLocation.coordinate,
                setPickup,
                true,
                true,
                newCoordinates && `${trimAddress(newCoordinates.location.name)}`,
                `GetUserLocation - ${caller}`
            );
            this.blockRegion = false;
        }

        if (skipLocationCheck) {
            return;
        }

        const { mapStore } = this.rootStore.stores;

        Permissions.request('location')
            .then(response => {
                if (
                    response === 'undetermined' ||
                    (Platform.OS === 'android' && response === 'denied')
                ) {
                    this.askForLocation();
                } else if (response !== 'authorized' && Platform.OS !== 'web') {
                    Alert.alert(
                        'Alert',
                        'Please turn on location services to view and set current location',
                        Platform.OS === 'ios'
                            ? [
                                  {
                                      text: 'Cancel',
                                      onPress: () => {
                                          if (this.lastKnownRegion) {
                                              this.updateCurrentUserLocation(
                                                  { coordinate: { ...this.lastKnownRegion } },
                                                  true,
                                                  'get user location , permission'
                                              );
                                          }
                                      },
                                      style: 'cancel',
                                  },
                                  {
                                      text: 'Open Settings',
                                      onPress: () => {
                                          Linking.openURL('app-settings:');
                                      },
                                      style: 'cancel',
                                  },
                              ]
                            : [
                                  {
                                      text: 'Ok',
                                      onPress: () => {
                                          if (this.lastKnownRegion) {
                                              this.updateCurrentUserLocation(
                                                  { coordinate: { ...this.lastKnownRegion } },
                                                  true,
                                                  'get user location permission'
                                              );
                                          }
                                      },
                                      style: 'cancel',
                                  },
                              ]
                    );
                } else {
                    this.locationGranted = true;
                }
            })
            .then(() => {
                if (!mapStore.userLocationLoaded) {
                    Geolocation.getCurrentPosition(
                        () => {},
                        error => {
                            if (error.message === 'No location provider available.') {
                                mapStore.setUserLocationLoaded();
                            }
                        }
                    );
                }
            });
    };

    @action
    askForLocation = () => {
        Permissions.request('location').then(() => {
            this.getUserLocation(true, false, 'TaxiStore askForLocation');
        });
    };

    @action
    regionChanged = () => {
        if (!this.fittingCoordinates) {
            if (this.taxiState === 'ride') {
                this.blockMap = true;
            } else {
                this.blockMap = false;
            }
        }
    };

    @action
    regionChangeComplete = (region: Region) => {
        const {
            bikeStore: { getBikesForLocation },
            filtersStore: { getProvidersForRegion },
            mapStore,
        } = this.rootStore.stores;
        this.lastKnownRegion = region;
        if (this.blockRegion && Platform.OS !== 'web') return;
        getProvidersForRegion(region);
        if (this.taxiState === 'selectPickup' || this.taxiState === 'start') {
            getBikesForLocation(region);
            const dist = distance(region, this.userLocation);
            if (this.leavePickupAsIs && dist < 0.04) {
                this.leavePickupAsIs = false;
            } else {
                this.updateCurrentLocation(
                    true,
                    region,
                    true,
                    null,
                    null,
                    null,
                    'Region change complete'
                );
            }
        } else if (this.taxiState === 'selectDestination') {
            getBikesForLocation(region);
            this.updateCurrentLocation(
                false,
                region,
                true,
                null,
                null,
                null,
                'Region change complete'
            );
        }
    };

    setNewRegion = (latitude: number, longitude: number) => {
        this.setFittingWorkaround();
        const {
            mapStore: { updateRegion },
        } = this.rootStore.stores;
        updateRegion({
            latitude,
            longitude,
            latitudeDelta: 0.01,
            longitudeDelta: 0.01,
        });
    };

    setFittingWorkaround = () => {
        this.fittingCoordinates = true;
        setTimeout(() => {
            this.fittingCoordinates = false;
        }, 2000);
    };

    getSuggestions = flow(function*(this: TaxiStore, address: string, pickup: string) {
        this.cachedAddress = address;
        this.rootStore.stores.locationsStore.fetchFavorites();
        if (!address || address.length === 0) {
            this.parseSuggestions([], '', pickup);
            return;
        }
        try {
            const {
                data: { predictions },
            } = yield GoogleClient.getSuggestions(address, this.userLocation);
            this.parseSuggestions(predictions, address, pickup);
        } catch (error) {
            handleError(error);
        }
    }).bind(this);

    @action
    parseSuggestions = (predictions, address, pickup) => {
        this.suggestions = [];
        if (pickup) {
            this.suggestions.push({ current: true, id: 'current' });
        }

        if (this.rootStore.stores.locationsStore.getFilteredFavorite('home', address)) {
            this.suggestions.push(
                this.rootStore.stores.locationsStore.getFilteredFavorite('home', address)
            );
        }
        if (this.rootStore.stores.locationsStore.getFilteredFavorite('work', address)) {
            this.suggestions.push(
                this.rootStore.stores.locationsStore.getFilteredFavorite('work', address)
            );
        }
        const locationsArray = this.rootStore.stores.locationsStore.getLocations(address);
        this.suggestions.push(...locationsArray);
        this.suggestions.push(...predictions);
    };

    @action
    parseSuggestion = (fromGoogle, suggestion, address) => {
        const {
            stores: {
                addressStore,
                appStore: { hideLoading },
                taxiStore: {
                    taxiRideStore: { getActiveRide },
                },
            },
        } = this.rootStore;
        const {
            showDestination,
            setPickup,
            setPickupLocation,
            setDestination,
            setDestinationLocation,
            pickupLocation,
            setFocusedInput,
            currentType,
        } = addressStore;

        let location;
        let formattedAddress;

        if (fromGoogle) {
            location = this.location(
                address.result.formatted_address,
                address.result.geometry.location.lat,
                address.result.geometry.location.lng
            );
            formattedAddress = suggestion.structured_formatting.main_text;
        } else {
            location = this.location(
                address.location.name,
                address.location.latitude,
                address.location.longitude
            );
            formattedAddress = address.location.name;
        }
        if (this.newDestinationAddressFocused) {
            this.rootStore.stores.appStore.showLoading();
            RestClient.changeDestination(
                this.taxiRideStore.currentRide.request_id,
                location,
                formattedAddress
            )
                .then(response => {
                    hideLoading();
                    getActiveRide();
                    this.focusNewDestination(false);
                })
                .catch(error => {
                    showErrorAlert(error);
                    hideLoading();
                });
            return;
        }
        if (currentType === 'pickup') {
            setPickupLocation(location);
            setPickup(formattedAddress);
        } else {
            setDestinationLocation(location);
            setDestination(formattedAddress);
        }
        if (this.suggestionsState === 'pickup') {
            this.setTaxiState('start');
            this.blockRegion = true;
            setTimeout(() => {
                this.blockRegion = false;
            }, 2000);
            this.updateCurrentLocation(true, pickupLocation, true, true, false);
            showDestination();
        } else if (
            addressStore.destinationLocation &&
            addressStore.pickupLocation &&
            (this.suggestionsState === 'destination' || this.suggestionsState === 'landing')
        ) {
            this.setTaxiState('taxis');
            this.taxiRouteStore.updateRoute(true, true);
            setFocusedInput(null);
            this.taxiResultsStore.typesLoading = true;
            Analytics.trackScreenView('TaxiList');
            this.rootStore.stores.rateAppStore.increaseSearchCounter();
            Analytics.trackSearch();
            if (suggestion) {
                this.rootStore.stores.locationsStore.add({
                    ...suggestion,
                    location,
                });
            }
        }
    };

    @action
    fitMapToCoordinates = (points: Array<LatLng>) => {
        this.setFittingWorkaround();
        const {
            mapStore: { fitToCoordinates },
        } = this.rootStore.stores;

        fitToCoordinates(points);
    };

    @action
    centerMap = () => {
        const {
            mapStore: { toggleBlockLocationOnMap },
        } = this.rootStore.stores;
        toggleBlockLocationOnMap(false);
        if (
            this.taxiState === 'ride' ||
            this.taxiState === 'taxis' ||
            this.taxiState === 'confirm'
        ) {
            this.blockMap = false;
            this.taxiRouteStore.updateRoute(true);
            return;
        }
        this.updated = false;
        this.getUserLocation(true, false, 'TaxiStore centerMap');
    };

    @action
    zoomToRoute = () => {
        if (this.taxiRouteStore.routeEdgePoints.length) {
            this.fitMapToCoordinates(this.taxiRouteStore.routeEdgePoints);
        }
    };

    @action
    clearSuggestions = () => {
        this.suggestions = [];
    };

    @computed
    get currentEta() {
        return this.taxiRideStore.currentRideStatus && this.taxiRideStore.currentRideStatus.eta
            ? this.taxiRideStore.currentRideStatus.eta
            : this.taxiRouteStore.rideDuration
            ? Math.round(this.taxiRouteStore.rideDuration / 60)
            : '0';
    }

    @action
    resetPath = () => {
        const {
            addressStore: { setVenueSelected },
        } = this.rootStore.stores;

        setVenueSelected(false);
        this.taxiRouteStore.plainMarkers = [];
        this.taxiRouteStore.plainRoute = [];
        this.taxiRouteStore.showMarkers = false;
        this.taxiRouteStore.showRoute = false;
    };

    @action
    handleBackClick = () => {
        const {
            stores: {
                addressStore,
                addressStore: { setSelectedField },
                mapStore,
                mapStore: { toggleBlockLocationOnMap },
                onboardingStore: {
                    exampleRouteStore: { setExampleDestinationTooltipVisible },
                },
            },
        } = this.rootStore;
        switch (this.taxiState) {
            case 'selectPickup':
                this.setTaxiState('start');
                this.setSuggestionsState('pickup');
                toggleBlockLocationOnMap(false);
                setSelectedField('pickup');
                break;
            case 'selectDestination':
                this.setTaxiState('start');
                this.setSuggestionsState('destination');
                setSelectedField('destination');
                break;
            case 'taxis':
                this.suggestions = [];
                this.setTaxiState('start');
                this.setSuggestionsState('landing');
                toggleBlockLocationOnMap(false);
                this.updateCurrentUserLocation(
                    {
                        coordinate: {
                            latitude: mapStore.lastUserLatitude,
                            longitude: mapStore.lastUserLongitude,
                        },
                    },
                    true,
                    'TaxiStore back handler'
                );
                this.getUserLocation(true, true, 'TaxiStore handleBackClick');
                this.taxiRouteStore.showMarkers = false;
                setExampleDestinationTooltipVisible(false);
                this.centerMap();
                break;
            case 'confirm':
                this.setTaxiState('taxis');
                this.zoomToRoute();
                this.selectedTaxiStore.selectedTaxi = null;
                this.rootStore.stores.paymentMethodsStore.clearSelectedCard();
                this.rootStore.stores.paymentMethodsStore.setActivePaymentsProvider('');
                break;
            case 'ride':
                this.focusNewDestination(false);
                break;
            default:
                break;
        }
    };

    @action
    toggleRidesEnabled = () => {
        this.ridesEnabled = !this.ridesEnabled;
    };
}

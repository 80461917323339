import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { computed } from 'mobx';
import { TouchableOpacity, View, Text, Image, ImageSourcePropType } from 'react-native';

import SectionHeaderStyles from './styles/SectionHeaderStyles';
import TaxiStore from 'stores/TaxiStore';
import { Images } from 'themes';
import { SectionData } from 'stores/dataStores/taxiResultsStore/TaxisComparationStore';
import { Stores } from 'stores/RootStore';

interface InjectedProps {
    taxiStore: TaxiStore;
}

interface OwnProps {
    section: SectionData;
    onInfoPress: () => void;
    onPress: (section: SectionData) => void;
}

type Props = InjectedProps & OwnProps;

@inject(({ taxiStore }: Stores) => ({ taxiStore }))
@observer
export default class SectionHeader extends Component<Props> {
    @computed
    get shouldDisplayHeader(): boolean {
        const {
            section,
            taxiStore: {
                taxiResultsStore: { taxisComparationStore, typesLoading },
            },
        } = this.props;
        return typesLoading || taxisComparationStore[section.key].length !== 0;
    }

    @computed
    get buttonText(): string {
        const {
            section,
            taxiStore: {
                taxiResultsStore: { taxisComparationStore, typesLoading },
            },
        } = this.props;
        return section.state === 'maximized' ||
            (section.state === 'minimized' &&
                taxisComparationStore[section.key].length <= 2 &&
                !typesLoading)
            ? 'Less'
            : 'More';
    }

    @computed
    get shouldDisplayMoreCounter(): boolean {
        const {
            section,
            taxiStore: {
                taxiResultsStore: { taxisComparationStore },
            },
        } = this.props;
        switch (section.state) {
            case 'hidden':
                return taxisComparationStore[section.key].length > 0;
            case 'minimized':
                return taxisComparationStore[section.key].length - 2 > 0;
            default:
                return false;
        }
    }

    @computed
    get moreCounter(): number {
        const {
            section,
            taxiStore: {
                taxiResultsStore: { taxisComparationStore },
            },
        } = this.props;
        switch (section.state) {
            case 'hidden':
                return taxisComparationStore[section.key].length;
            case 'minimized':
                return taxisComparationStore[section.key].length - 2;
            default:
                return 0;
        }
    }

    @computed
    get buttonImage(): ImageSourcePropType {
        const {
            section,
            taxiStore: {
                taxiResultsStore: { taxisComparationStore, typesLoading },
            },
        } = this.props;
        return section.state === 'maximized' ||
            (section.state === 'minimized' &&
                taxisComparationStore[section.key].length <= 2 &&
                !typesLoading)
            ? Images.icons.hideAll
            : Images.icons.showAll;
    }

    render() {
        const { section, onPress, onInfoPress } = this.props;
        return this.shouldDisplayHeader ? (
            <TouchableOpacity
                style={SectionHeaderStyles.container}
                onPress={() => onPress(section)}
            >
                <TouchableOpacity style={SectionHeaderStyles.titleView} onPress={onInfoPress}>
                    <Text style={SectionHeaderStyles.title}>{section.title}</Text>
                    <View style={SectionHeaderStyles.infoButton}>
                        <Image
                            source={Images.icons.information}
                            style={SectionHeaderStyles.infoButtonIcon}
                        />
                    </View>
                </TouchableOpacity>
                <View style={SectionHeaderStyles.buttonView}>
                    <View style={SectionHeaderStyles.button}>
                        <Text style={SectionHeaderStyles.buttonText}>{this.buttonText}</Text>
                        {this.shouldDisplayMoreCounter && (
                            <View style={SectionHeaderStyles.moreView}>
                                <Text style={SectionHeaderStyles.moreText}>{this.moreCounter}</Text>
                            </View>
                        )}
                        <Image
                            source={this.buttonImage}
                            style={SectionHeaderStyles.buttonImage}
                            resizeMode="contain"
                        />
                    </View>
                </View>
            </TouchableOpacity>
        ) : null;
    }
}

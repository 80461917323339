import { StyleSheet } from 'react-native';
import { Fonts, Metrics, Colors } from '../../../themes';

export default StyleSheet.create({
    container: {
        position: 'absolute',
        top: Metrics.paddingTop + 70,
        left: 16,
        right: 16,
        shadowColor: 'black',
        shadowRadius: 5,
        shadowOpacity: 0.1,
        shadowOffset: { width: 0, height: 0 },
        borderRadius: 4,
        backgroundColor: 'white',
        elevation: 2,
        height: 65,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 16,
    },
    companyIcon: {
        width: 70,
        height: 55,
    },
    providerView: {
        justifyContent: 'space-evenly',
        alignItems: 'center',
    },
    providerValueText: {
        fontFamily: Fonts.type.base,
        fontSize: Metrics.normalize(15),
        fontWeight: '500',
        color: Colors.black,
    },
    carColorTewxt: {
        fontFamily: Fonts.type.base,
        fontSize: Metrics.normalize(13),
        fontWeight: '400',
        color: Colors.darkGreyTextColor,
    },
    providerLicenceText: {
        fontFamily: Fonts.type.base,
        fontSize: Metrics.normalize(13),
        fontWeight: '400',
        color: 'white',
    },
    providerLicenseView: {
        backgroundColor: 'black',
        padding: 4,
        paddingHorizontal: 8,
        borderRadius: 4,
        alignContent: 'center',
        justifyContent: 'center',
    },
});

/* eslint-disable camelcase */
import React from 'react';
import { View, Text, Image } from 'react-native';
import { observer, inject } from 'mobx-react';

import TaxiStore from '../../stores/TaxiStore';
import TopRideSummaryStyles from './styles/TopRideSummaryStyles';
import { Images } from '../../themes';
import { Stores } from 'stores/RootStore';

interface Props {
    taxiStore: TaxiStore;
}

export default inject(({ taxiStore }: Stores) => ({ taxiStore }))(
    observer(
        ({
            taxiStore: {
                taxiRideStore: { currentRideStatus, currentRide },
            },
        }: Props) => {
            const { service_provider } = currentRide;
            const {
                vehicle: { color: carColor, vehicle, vehicle_image, vehicle_license_plate } = {},
            } = currentRideStatus;
            const vehicleImageSource = vehicle_image
                ? { uri: vehicle_image }
                : service_provider === 'CURB'
                ? Images.stockCar
                : null;
            const vehicleName = vehicle || (service_provider === 'CURB' ? 'Curb Taxi' : '');
            return (
                <View style={TopRideSummaryStyles.container}>
                    <View style={TopRideSummaryStyles.providerView}>
                        <Text style={TopRideSummaryStyles.providerValueText}>{vehicleName}</Text>
                        {carColor && (
                            <Text style={TopRideSummaryStyles.carColorTewxt}>
                                {carColor.toUpperCase()}
                            </Text>
                        )}
                    </View>
                    <Image
                        source={vehicleImageSource}
                        style={TopRideSummaryStyles.companyIcon}
                        resizeMode="contain"
                    />
                    <View style={TopRideSummaryStyles.providerLicenseView}>
                        <Text style={TopRideSummaryStyles.providerLicenceText}>
                            {vehicle_license_plate}
                        </Text>
                    </View>
                </View>
            );
        }
    )
);

import React, { Component, RefObject } from 'react';
import {
    View,
    TouchableOpacity,
    Text,
    ViewStyle,
    ViewProps,
    ScrollView,
    Platform,
} from 'react-native';
import { observer, inject } from 'mobx-react';

import Animatable from 'modules/animatable/animatable';

import FiltersPopupStyles from './styles/FiltersPopupStyles';
import GradientButton from '../GradientButton';
import { AddressStore, FiltersStore } from 'stores';
import { Colors } from 'themes';
import { Localizable, Analytics } from 'lib';
import { Stores } from 'stores/RootStore';
import { defaultTaxiServiceTypes } from 'domain/serviceProviders/TaxiServices';

interface Props {
    addressStore: AddressStore;
    filtersStore: FiltersStore;
}

@inject(({ addressStore, filtersStore }: Stores) => ({ addressStore, filtersStore }))
@observer
export default class FiltersPopup extends Component<Props, {}> {
    bg: RefObject<any> = React.createRef();

    main: RefObject<any> = React.createRef();

    componentDidMount() {
        const {
            filtersStore: { setTempValues },
        } = this.props;
        setTempValues();
    }

    hidePopUp = () => {
        const {
            addressStore,
            filtersStore: { toggleFiltersPopup },
        } = this.props;
        if (Platform.OS === 'web') {
            toggleFiltersPopup(false);
            Analytics.trackScreenView(`02-03_Compare${addressStore.isNewYork ? '_Grouped' : ''}`);
        } else {
            if (this.bg.current && this.main.current) {
                this.bg.current.fadeOut(300);
                this.main.current.fadeOutDownBig(300).then(() => {
                    toggleFiltersPopup(false);
                    Analytics.trackScreenView(
                        `02-03_Compare${addressStore.isNewYork ? '_Grouped' : ''}`
                    );
                });
            }
        }
    };

    applyFilters = () => {
        const {
            filtersStore: { applyFilters },
        } = this.props;
        applyFilters();
        this.hidePopUp();
    };

    cancel = () => {
        const {
            filtersStore: { resetChanges },
        } = this.props;
        resetChanges();
        this.hidePopUp();
    };

    render() {
        const {
            filtersStore: {
                excludedProviders,
                excludedTypes,
                providerSelectd,
                typeSelected,
                availableProviders,
                seats,
                seatsDecreased,
                seatsIncreased,
                canDecrease,
                canIncrease,
            },
        } = this.props;
        return (
            <Animatable.View
                ref={this.bg}
                animation="fadeIn"
                duration={300}
                style={FiltersPopupStyles.container}
                useNativeDriver
            >
                <View style={FiltersPopupStyles.backgroundContainer} />
                <Animatable.View
                    ref={this.main}
                    animation="fadeInUpBig"
                    duration={300}
                    style={FiltersPopupStyles.filterContainer}
                    useNativeDriver
                >
                    <View style={FiltersPopupStyles.topView}>
                        <Text style={FiltersPopupStyles.titleText}>
                            {Localizable.t('taxis.filtersPopup.filters')}
                        </Text>
                    </View>
                    <View style={FiltersPopupStyles.separator} />
                    <Text style={FiltersPopupStyles.headerText}>
                        {Localizable.t('taxis.filtersPopup.rideType')}
                    </Text>
                    <ScrollView
                        showsHorizontalScrollIndicator={false}
                        horizontal
                        contentContainerStyle={FiltersPopupStyles.scrollView}
                    >
                        {Object.entries(defaultTaxiServiceTypes).map(([key, value]) => {
                            const excluded = excludedTypes.indexOf(key) > -1;
                            return (
                                <TouchableOpacity key={key} onPress={() => typeSelected(key)}>
                                    <View
                                        style={[
                                            FiltersPopupStyles.ridesView,
                                            {
                                                borderColor: excluded ? '#DDDFE4' : Colors.obiTeal,
                                            },
                                        ]}
                                    >
                                        <Text
                                            style={[
                                                FiltersPopupStyles.ridesText,
                                                {
                                                    color: excluded ? 'black' : Colors.obiTeal,
                                                },
                                            ]}
                                        >
                                            {value.name}
                                        </Text>
                                    </View>
                                </TouchableOpacity>
                            );
                        })}
                    </ScrollView>
                    <Text style={FiltersPopupStyles.headerText}>
                        {Localizable.t('taxis.filtersPopup.providers')}
                    </Text>
                    <ScrollView
                        showsHorizontalScrollIndicator={false}
                        horizontal
                        contentContainerStyle={FiltersPopupStyles.scrollView}
                    >
                        {availableProviders.map((item, index) => {
                            const excluded = excludedProviders.indexOf(item.type) > -1;
                            return (
                                <TouchableOpacity
                                    onPress={() => providerSelectd(item)}
                                    key={item.type}
                                >
                                    <View
                                        style={[
                                            FiltersPopupStyles.ridesView,
                                            {
                                                borderColor: excluded ? '#DDDFE4' : Colors.obiTeal,
                                            },
                                        ]}
                                    >
                                        <Text
                                            style={[
                                                FiltersPopupStyles.ridesText,
                                                {
                                                    color: excluded ? 'black' : Colors.obiTeal,
                                                },
                                            ]}
                                        >
                                            {item.name}
                                        </Text>
                                    </View>
                                </TouchableOpacity>
                            );
                        })}
                    </ScrollView>
                    <Text style={FiltersPopupStyles.headerText}>
                        {Localizable.t('taxis.filtersPopup.passengers')}
                    </Text>
                    <View style={FiltersPopupStyles.passengerContainer}>
                        {(canDecrease && (
                            <TouchableOpacity
                                onPress={seatsDecreased}
                                style={FiltersPopupStyles.addButton}
                            >
                                <View style={FiltersPopupStyles.addButtonContainer}>
                                    <Text style={FiltersPopupStyles.addButtonText}>-</Text>
                                </View>
                            </TouchableOpacity>
                        )) || <View style={FiltersPopupStyles.addButton} />}
                        <Text style={FiltersPopupStyles.passengerText}>{seats}</Text>
                        {(canIncrease && (
                            <TouchableOpacity
                                onPress={seatsIncreased}
                                style={FiltersPopupStyles.addButton}
                            >
                                <View style={FiltersPopupStyles.addButtonContainer}>
                                    <Text style={FiltersPopupStyles.addButtonText}>+</Text>
                                </View>
                            </TouchableOpacity>
                        )) || <View style={FiltersPopupStyles.addButton} />}
                    </View>
                    <View style={FiltersPopupStyles.bottomSeparator} />
                    <GradientButton
                        style={FiltersPopupStyles.applyButton}
                        title={Localizable.t('taxis.filtersPopup.apply')}
                        handlePress={this.applyFilters}
                    />
                </Animatable.View>
            </Animatable.View>
        );
    }
}

import platform from 'platform';
import { Dimensions, Platform, PixelRatio } from 'react-native';
import { getStatusBarHeight, getBottomSpace } from 'modules/iPhoneXHelper/iPhoneXHelper';

const { width, height } = Dimensions.get('window');

// Used via Metrics.baseMargin

const screen = {
    screenWidth: width < height ? width : height,
    screenHeight: width < height ? height : width,
};

const scale = screen.screenWidth / 375;

// const scale = size => width / guidelineBaseWidth * size;
// const verticalScale = size => height / guidelineBaseHeight * size;
// const normalize = (size, factor = 0.5) => size + (scale(size) - size) * factor;

const normalize = (size: number) =>
    scale > 1.5
        ? PixelRatio.roundToNearestPixel(size * 1.5)
        : PixelRatio.roundToNearestPixel(size * scale);

const metrics = {
    normalize,
    marginHorizontal: 10,
    marginVertical: 10,
    section: 25,
    baseMargin: 10,
    doubleBaseMargin: 20,
    smallMargin: 5,
    doubleSection: 50,
    horizontalLineHeight: 1,
    searchBarHeight: 30,
    screenWidth: screen.screenWidth,
    screenHeight: screen.screenHeight,
    navBarHeight: Platform.OS === 'ios' ? 64 : 54,
    buttonRadius: 4,
    icons: {
        tiny: 15,
        small: 20,
        medium: 30,
        large: 45,
        xl: 50,
    },
    images: {
        small: 20,
        medium: 40,
        large: 60,
        logo: 200,
    },
    paddingTop: getStatusBarHeight(),
    paddingBottom: getBottomSpace(),
    // isIphoneX: Platform.OS === 'ios' && screen.screenHeight === 812,
    // androidGap: Platform.OS === 'android' ? 10 : 0,
    // iPhoneXGap: Platform.OS === 'ios' && screen.screenHeight === 812 ? 20 : 0,
    isMobileDevice: () => platform.os.family === 'iOS' || platform.os.family === 'Android',
    isWebApplication: () => Platform.OS === 'web',
    isWebAndroid: () => metrics.isWebApplication && platform.os.family === 'Android',
    isWebIOS: () => metrics.isWebApplication && platform.os.family === 'iOS',
    isPinnedOnScreen: () =>
        metrics.isMobileDevice
            ? metrics.isWebApplication
                ? window.matchMedia('(display-mode: standalone)').matches ||
                  window.navigator.standalone ||
                  false
                : true
            : false,
};

export default metrics;

import { StyleSheet } from 'react-native';
import { Fonts, Colors } from '../../../themes';

export default StyleSheet.create({
    container: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    image: {
        width: 20,
        height: 20,
    },
    textView: {
        justifyContent: 'center',
        alignItems: 'center',
        paddingLeft: 20,
    },
    text: {
        fontFamily: Fonts.type.base,
        fontWeight: '500',
        fontSize: 21,
        color: Colors.darkGreyTextColor,
        marginVertical: 18,
    },
});

/* eslint-disable camelcase */
import { observable, action, computed, flow } from 'mobx';
import RestClient from '../services/RestClient';
import BaseStore from './BaseStore';
import { UserLifecycle } from './RootStore';
import { creditCardBrandIcon } from '../services/Utils';

type cardInfo = {
    type: string;
    description: string;
    payment_method_id: string;
};

export default class PaymentMethodsStore extends BaseStore implements UserLifecycle {
    @observable paymentMethodsMap: Map<string, cardInfo[]> = new Map();

    @observable selectedPaymentMethodId: string = '';

    @observable lastUsedPaymentId: string = '';

    @observable paymentsPopupShown = false;

    @observable activePaymentsProvider = '';

    onUserReady = () => {
        // this.getPaymentMethods();
    };

    @computed
    get paymentMethods() {
        return this.paymentMethodsMap.get(this.activePaymentsProvider) || [];
    }

    @action
    showPaymentsPopup = provider => {
        this.activePaymentsProvider = provider;
        this.getPaymentsForProvider(provider);
        if (this.paymentMethods.length === 0) {
            this.activePaymentsProvider = '';
            return false;
        }
        this.paymentsPopupShown = true;
        return true;
    };

    @action
    setActivePaymentsProvider = provider => {
        this.activePaymentsProvider = provider;
    };

    @action
    hidePaymentsPopup = () => {
        this.paymentsPopupShown = false;
        // this.activePaymentsProvider = '';
    };

    getPaymentsForProvider = flow(function*(this: PaymentMethodsStore, provider: string) {
        try {
            const {
                data: { payment_methods, last_used },
            } = yield RestClient.getPaymentMethods(provider);
            this.setPaymentMethodsForProvider(provider, payment_methods, last_used);
        } catch (error) {
            console.log(`getPaymentsForProvider(${provider}) error: `, { ...error });
        }
    }).bind(this);

    @action
    setPaymentMethodsForProvider = (
        provider: string,
        methods: Array<CardInfo>,
        lastUsed: string
    ) => {
        this.paymentMethodsMap.set(provider, methods);
        this.lastUsedPaymentId = lastUsed;
    };

    getPaymentMethods = () => {
        // this.getLyftPaymentMethods();
        // this.getCurbPaymentMethods();
    };

    @action
    selectCard = (id: string) => {
        this.selectedPaymentMethodId = id;
    };

    @action
    clearSelectedCard = () => {
        this.selectedPaymentMethodId = '';
        // this.lastUsedPaymentId = '';
    };

    @computed
    get cardInfo() {
        return (
            this.paymentMethods.find(
                paymentMethod => paymentMethod.payment_method_id === this.selectedPaymentMethodId
            ) ||
            this.paymentMethods.find(
                paymentMethod => paymentMethod.payment_method_id === this.lastUsedPaymentId
            )
        );
    }

    @computed
    get cardIcon() {
        return this.cardInfo ? creditCardBrandIcon(this.cardInfo.type.toLowerCase()) : null;
    }
}

import React from 'react';
import { View, Text, TouchableOpacity, Image, ViewStyle } from 'react-native';
import { observer, inject } from 'mobx-react';

import { Images } from 'themes';
import { TaxiStore, LayoutStore } from 'stores';
import { Stores } from 'stores/RootStore';

import NavigationBarStyles from './styles/NavigationBarStyles';

interface InjectedProps {
    taxiStore: TaxiStore;
    layoutStore: LayoutStore;
}

interface OwnProps {
    backImage?: Object;
    disableIcon?: boolean;
    handleBackClick: () => void;
    style?: ViewStyle;
    title: string;
}

type Props = InjectedProps & OwnProps;

export default inject(({ taxiStore }: Stores) => ({ taxiStore }))(
    observer(
        ({
            taxiStore,
            taxiStore: { taxiRideStore },
            backImage = Images.icons.back,
            handleBackClick,
            style = {},
            title = '',
        }: Props) => {
            return (
                <View style={[NavigationBarStyles.container, style]}>
                    <Image
                        source={Images.topGradient}
                        style={NavigationBarStyles.background}
                        resizeMode="stretch"
                    />
                    <TouchableOpacity
                        disabled={taxiRideStore.rideRequestInProgress}
                        style={NavigationBarStyles.backButton}
                        onPress={handleBackClick}
                    >
                        {!taxiRideStore.rideRequestInProgress && (
                            <Image
                                style={NavigationBarStyles.backButtonImage}
                                source={backImage || Images.icons.back}
                                resizeMode="contain"
                            />
                        )}
                    </TouchableOpacity>
                    <View style={NavigationBarStyles.titleView}>
                        {(taxiStore.taxiState === 'start' && (
                            <Image
                                resizeMode="contain"
                                source={Images.icons.bellhopLogo}
                                style={NavigationBarStyles.titleImage}
                            />
                        )) ||
                            (!!title && (
                                <Text numberOfLines={1} style={NavigationBarStyles.titleText}>
                                    {title}
                                </Text>
                            ))}
                    </View>
                    <View style={NavigationBarStyles.backButton} />
                </View>
            );
        }
    )
);

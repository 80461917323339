import { StyleSheet } from 'react-native';
import { Colors, Metrics, Fonts } from '../../../../themes';

export default StyleSheet.create({
    nextView: {
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: 30,
    },
    nextViewSeparator: {
        flex: 1,
    },
    nextButton: {
        width: 50,
        height: 50,
    },
});

import React, { RefObject } from 'react';
import Voca from 'voca';
import { TextInput, Keyboard } from 'react-native';
import { action, observable, computed, flow } from 'mobx';

import { GoogleClient } from 'services';
import { Localizable } from 'lib';
import { RootStore } from 'stores';
import { handleError } from 'services/Utils';

export default class SmallAddressPickerStore {
    rootStore: RootStore;

    @observable.ref
    textInputRef: RefObject<TextInput> = React.createRef();

    @observable
    showSuggestions: boolean = false;

    @observable
    textChanged: boolean = false;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
    }

    @action
    toggleSmallAddressPickerSuggestions = (showSuggestions?: boolean) => {
        if (showSuggestions) {
            this.showSuggestions = showSuggestions;
        } else {
            this.showSuggestions = !this.showSuggestions;
        }
    };

    @action
    toggleShowSuggestions = (showSuggestions: boolean) => {
        this.showSuggestions = showSuggestions;
    };

    @action
    toggleTextChanged = (changed: boolean) => {
        this.textChanged = changed;
    };

    @action
    onChangeText = (text: string) => {
        const {
            addressStore: { updateDestinationText, updatePickupText },
            suggestionsStore: { updateText },
            taxiStore,
        } = this.rootStore.stores;

        this.toggleTextChanged(true);
        this.toggleShowSuggestions(!Voca.isBlank(text));

        if (taxiStore.taxiState === 'selectPickup') {
            updatePickupText(text);
        } else {
            updateDestinationText(text);
        }
        updateText();
    };

    selectSugestion = flow(function*(this: SmallAddressPickerStore, suggestion) {
        const {
            taxiStore,
            addressStore: { setPickup, setPickupLocation, setDestination, setDestinationLocation },
        } = this.rootStore.stores;
        const { setNewRegion, location } = taxiStore;

        this.toggleShowSuggestions(false);
        this.toggleTextChanged(false);

        try {
            const {
                data: {
                    result: {
                        geometry: {
                            location: { lat, lng },
                        },
                        formatted_address,
                    },
                },
            } = yield GoogleClient.getSuggestionDetails(suggestion.place_id);

            Keyboard.dismiss();
            setNewRegion(lat, lng);

            if (taxiStore.taxiState === 'selectDestination') {
                setDestination(formatted_address);
                setDestinationLocation(location(formatted_address, lat, lng));
            } else {
                setPickup(formatted_address);
                setPickupLocation(location(formatted_address, lat, lng));
            }

            taxiStore.setBlockRegion(true);
            setTimeout(() => {
                taxiStore.setBlockRegion(false);
            }, 2000);
        } catch (error) {
            handleError(error);
        }
    }).bind(this);

    @computed
    get confirmLocationButtonActive(): boolean {
        const { taxiStore, addressStore } = this.rootStore.stores;
        if (this.textChanged || addressStore.pickup === addressStore.destination) {
            return false;
        }
        if (taxiStore.taxiState === 'selectDestination' && addressStore.destination) {
            return true;
        }
        if (taxiStore.taxiState === 'selectPickup' && addressStore.pickup) {
            return true;
        }
        return false;
    }

    @computed
    get confirmLocationButtonAlert(): string {
        const { addressStore, taxiStore } = this.rootStore.stores;
        if (
            taxiStore.taxiState === 'selectPickup' &&
            (addressStore.pickup === '' || addressStore.pickup === null)
        ) {
            return Localizable.t('mapAddressPicker.setAddressFirst');
        }
        if (
            taxiStore.taxiState === 'selectDestination' &&
            (addressStore.pickup === addressStore.destination || addressStore.destination === null)
        ) {
            return Localizable.t('mapAddressPicker.sameAddresses');
        }
        return Localizable.t('mapAddressPicker.setAddressFirst');
    }

    @action
    confirmLocation = () => {
        const {
            taxiStore,
            addressStore,
            mapStore: { toggleBlockLocationOnMap },
        } = this.rootStore.stores;
        const { setSelectedField } = addressStore;
        const {
            setTaxiState,
            taxiRouteStore: { updateRoute },
            updateCurrentUserLocation,
        } = taxiStore;

        if (taxiStore.taxiState === 'selectDestination') {
            setTaxiState('taxis');
            updateRoute(true, true);
        } else if (taxiStore.taxiState === 'selectPickup') {
            toggleBlockLocationOnMap(true);
            updateCurrentUserLocation(
                {
                    coordinate: {
                        latitude: addressStore.pickupLocation.latitude,
                        longitude: addressStore.pickupLocation.longitude,
                    },
                },
                true,
                'SmallAddressPicker confirmLocation'
            );

            setTaxiState('start');
            setSelectedField('destination');
        }

        this.toggleTextChanged(false);
    };

    @computed
    get textInputValue() {
        const { taxiStore, addressStore } = this.rootStore.stores;
        if (taxiStore.taxiState === 'selectPickup') {
            return addressStore.pickup;
        }
        return addressStore.destination === null ? addressStore.pickup : addressStore.destination;
    }

    @computed
    get locationForText() {
        const { taxiStore } = this.rootStore.stores;
        return taxiStore.taxiState === 'selectPickup' ? 'PICKUP' : 'DESTINATION';
    }
}

import React, { Component } from 'react';
import sectionListGetItemLayout from 'react-native-section-list-get-item-layout';
import {
    Image,
    View,
    SectionList,
    Text,
    TouchableOpacity,
    TextInput,
    SectionListData,
    SectionListRenderItem,
} from 'react-native';
import { observer, inject } from 'mobx-react';
import { NavigationParams } from 'react-navigation';
import { observable, computed, action } from 'mobx';

import TitleNavigationBar from 'components/navigationBars/TitleNavigationBar';

import AuthStore from '../../stores/AuthStore';
import CountryPickerStyles from './styles/CountryPickerStyles';
import countryCodes from '../../lib/flags';
import { Images } from '../../themes';
import { Localizable } from '../../lib';
import NavigationStore from 'stores/navigationStores/NavigationStore';
import { Stores } from '../../stores/RootStore';

class CountryListStore {
    @observable filterText = '';

    @computed
    get countriesList() {
        const sortedList = countryCodes
            .sort((a, b) => a.name.localeCompare(b.name))
            .filter(section => {
                if (this.filterText.length) {
                    return (
                        section.name.charAt(0).toUpperCase() ===
                        this.filterText.charAt(0).toUpperCase()
                    );
                }
                return true;
            });

        const filtered = sortedList.reduce((prev, current) => {
            const firstLetter = current.name.substring(0, 1).toUpperCase();
            if (prev.length) {
                if (
                    prev[prev.length - 1].data[0].name.substring(0, 1).toUpperCase() === firstLetter
                ) {
                    const lastSection = prev[prev.length - 1];
                    current.key = lastSection.data.length;
                    lastSection.data.push(current);
                } else {
                    current.key = 0;
                    prev.push({ key: firstLetter, data: [current] });
                }
            } else {
                current.key = 0;
                prev.push({ key: firstLetter, data: [current] });
            }
            return prev;
        }, []);

        if (filtered.length === 1) {
            filtered[0].data = filtered[0].data.filter(country => {
                return country.name.toUpperCase().includes(this.filterText.toUpperCase());
            });
        }

        return filtered;
    }

    @action
    setFilterText = (newFilterText: string) => {
        this.filterText = newFilterText;
    };
}

interface Props {
    authStore: AuthStore;
    navigation: NavigationParams;
    navigationStore: NavigationStore;
}

@inject(({ authStore, navigationStore }: Stores) => ({
    authStore,
    navigationStore,
}))
@observer
export default class CountryPicker extends Component<Props> {
    countriesListStore: CountryListStore;

    getItemLayout: (
        data,
        index: number
    ) => {
        length: number;
        offset: number;
        index: number;
    };

    constructor(props: Props) {
        super(props);

        this.countriesListStore = new CountryListStore();

        this.getItemLayout = sectionListGetItemLayout({
            // The height of the row with rowData at the given sectionIndex and rowIndex
            getItemHeight: (rowData, sectionIndex, rowIndex) => 50,
            getSectionHeaderHeight: () => 50, // The height of your section headers
        });
    }

    renderHeader = ({ section }: { section: SectionListData<any> }) => (
        <View style={CountryPickerStyles.header}>
            <Text style={CountryPickerStyles.headerText}>{section.key}</Text>
        </View>
    );

    renderFooter = () => <View style={CountryPickerStyles.footer} />;

    renderCountry = ({ item }: { item: SectionListRenderItem<any> }) => {
        const {
            navigationStore: { goBack },
            navigation: {
                state: {
                    params: { onClose },
                },
            },
        } = this.props;

        return (
            <TouchableOpacity
                onPress={() => {
                    onClose(item);
                    goBack();
                }}
            >
                <View style={CountryPickerStyles.rowContainer}>
                    <Image
                        style={CountryPickerStyles.icon}
                        source={Images.flags[item.code.toLowerCase()]}
                        resizeMode="contain"
                    />
                    <Text style={CountryPickerStyles.title}>{item.name}</Text>
                </View>
            </TouchableOpacity>
        );
    };

    render() {
        const {
            navigationStore: { goBack },
        } = this.props;
        return (
            <View style={CountryPickerStyles.container}>
                <TitleNavigationBar
                    title={Localizable.t('setup.chooseCountry')}
                    handleBackClick={() => goBack()}
                />
                <View style={CountryPickerStyles.searchBarView}>
                    <TextInput
                        style={CountryPickerStyles.searchBarInput}
                        placeholder={Localizable.t('setup.search')}
                        value={this.countriesListStore.filterText}
                        onChangeText={input => this.countriesListStore.setFilterText(input)}
                    />
                </View>
                <SectionList
                    keyboardShouldPersistTaps="handled"
                    renderSectionHeader={this.renderHeader}
                    // sections={this.state.countriesList}
                    sections={this.countriesListStore.countriesList}
                    renderItem={this.renderCountry}
                    getItemLayout={this.getItemLayout}
                />
            </View>
        );
    }
}

import { StyleSheet } from 'react-native';
import { Fonts, Metrics, Colors } from '../../../themes';

export default StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: 'white',
        alignSelf: 'stretch',
    },
    inputBorder: {
        borderColor: '#F2F2F2',
        borderWidth: 1,
        marginTop: 20,
        paddingHorizontal: 10,
        flexDirection: 'row',
        alignItems: 'center',
        alignSelf: 'stretch',
    },
    inputWithoutBorder: {
        marginTop: 20,
        paddingHorizontal: 10,
        flexDirection: 'row',
        alignItems: 'center',
    },
    title: {
        fontSize: Metrics.normalize(28),
        fontWeight: '400',
        fontFamily: Fonts.type.base,
        backgroundColor: 'transparent',
        color: Colors.darkTextColor,
        marginTop: 80,
        marginBottom: 15,
    },
    description: {
        fontSize: Metrics.normalize(16),
        fontWeight: '300',
        fontFamily: Fonts.type.base,
        backgroundColor: 'transparent',
        color: '#9B99A9',
        marginBottom: 15,
        lineHeight: 28,
        textAlign: 'center',
        marginHorizontal: 30,
    },
    resendView: {
        alignItems: 'center',
        justifyContent: 'center',
        alignSelf: 'flex-end',
        marginBottom: 40,
    },
    resendText: {
        fontSize: Metrics.normalize(16),
        fontWeight: '300',
        fontFamily: Fonts.type.base,
        color: Colors.grayTextColor,
    },
    resendTextRed: {
        color: 'red',
    },
    nextButton: {
        width: 50,
        height: 50,
    },
    socialButton: {
        width: Metrics.screenWidth - 60,
        height: (Metrics.screenWidth - 60) * 0.19,
    },
    textInput: {
        height: 60,
        fontSize: Metrics.normalize(20),
        color: Colors.defaultTextColor,
        fontWeight: '400',
        fontFamily: Fonts.type.base,
        flex: 1,
    },
    hintText: {
        fontSize: Metrics.normalize(12),
        fontWeight: '300',
        fontFamily: Fonts.type.base,
        backgroundColor: 'transparent',
        color: Colors.defaultTextColor,
        marginRight: 10,
    },
    countryContainer: {
        flexDirection: 'row',
        padding: 8,
        alignItems: 'center',
    },
    countryIcon: {
        width: 20,
        height: 20,
    },
    countryArrow: {
        width: 6,
        height: 6,
        marginHorizontal: 6,
    },
    countryText: {
        fontSize: Metrics.normalize(20),
        color: Colors.defaultTextColor,
        fontWeight: '400',
        fontFamily: Fonts.type.base,
    },
    phoneNumberText: {
        color: 'black',
    },
    scrollView: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'flex-start',
        alignSelf: 'stretch',
        paddingHorizontal: 20,
    },
    submitButtonView: {
        position: 'absolute',
        left: 0,
        right: 0,
        alignSelf: 'stretch',
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    submitButton: {
        flexDirection: 'row',
        height: 50,
        width: Metrics.screenWidth - 80,
        backgroundColor: Colors.obiTeal,
        alignItems: 'center',
        borderRadius: 25,
        justifyContent: 'center',
        alignSelf: 'flex-end',
        marginBottom: 50,
    },
    submitButtonImageView: {
        position: 'absolute',
        right: 20,
        alignItems: 'center',
        justifyContent: 'center',
    },
    submitButtonImage: {
        height: Metrics.normalize(18),
    },
    submitButtonTextView: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    submitButtonText: {
        color: '#fff',
        fontSize: Metrics.normalize(18),
        fontWeight: '400',
    },
    hintTextInner: { fontWeight: '700' },
    hintTextInnerPhoneNumber: {
        fontFamily: Fonts.type.base,
        fontWeight: '600',
        textDecorationLine: 'underline',
        textDecorationStyle: 'solid',
    },
    correctImage: { width: 22, height: 22 },
    nextButtonView: { flexDirection: 'row', alignItems: 'center', marginTop: 30 },
    flexOne: { flex: 1 },
});

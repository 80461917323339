import Axios from 'axios';
import proxy from '../Proxy';

const instance = Axios.create({
    baseURL: 'https://secure.geonames.org/',
    timeout: 10000,
});

class GeonamesClient {
    constructor() {
        instance.interceptors.request.use(request => {
            console.log('[GeonamesClient] Starting Request:', { ...request });
            return proxy(request);
        });
        instance.interceptors.response.use(
            response => {
                console.log('[GeonamesClient] Response:', { ...response });
                return response;
            },
            error => {
                console.log('[GeonamesClient] Response error:', { ...error });
                return Promise.reject(error);
            }
        );
    }

    getCountryCodeForCoordinates = (lat: number, lng: number) =>
        instance.get('/countryCode', {
            params: {
                lat,
                lng,
                username: 'sztembus',
            },
        });
}

export default new GeonamesClient();

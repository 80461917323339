import React, { Component } from 'react';
import { Image, View, Text, TouchableOpacity, FlatList } from 'react-native';
import { observer, inject } from 'mobx-react';
import { NavigationParams } from 'react-navigation';

import TitleNavigationBar from 'components/navigationBars/TitleNavigationBar';

import AppStore from '../../stores/AppStore';
import AuthStore from '../../stores/AuthStore';
import NavigationStore from 'stores/navigationStores/NavigationStore';
import PaymentMethodsStore from '../../stores/PaymentMethodsStore';
import PaymentMethodsStyles from './styles/PaymentMethodsStyles';
import { Analytics, Localizable } from '../../lib';
import { Colors, Images } from '../../themes';
import { Stores } from '../../stores/RootStore';
import { creditCardBrandIcon } from '../../services/Utils';

interface Props {
    authStore: AuthStore;
    appStore: AppStore;
    navigationStore: NavigationStore;
    paymentMethodsStore: PaymentMethodsStore;
}

@inject(({ appStore, authStore, navigationStore, paymentMethodsStore }: Stores) => ({
    appStore,
    authStore,
    navigationStore,
    paymentMethodsStore,
}))
@observer
export default class PaymentMethods extends Component<Props> {
    componentDidMount() {
        const {
            paymentMethodsStore: { getPaymentMethods },
        } = this.props;
        getPaymentMethods();
    }

    renderRow = ({ item }) => {
        const brand = item.brand.toLowerCase();
        const business = item.defaults_to && item.defaults_to === 'BUSINESS';
        const card = creditCardBrandIcon(brand);
        const {
            paymentMethodsStore: { getPaymentMethods, handleDelete },
        } = this.props;
        return (
            <View style={PaymentMethodsStyles.rowView}>
                <View style={PaymentMethodsStyles.topView}>
                    <Image resizeMode="contain" source={card} style={PaymentMethodsStyles.brand} />
                    <View
                        style={[
                            PaymentMethodsStyles.typeView,
                            {
                                backgroundColor: business
                                    ? Colors.businessCardColor
                                    : Colors.obiTeal,
                            },
                        ]}
                    >
                        <Text style={PaymentMethodsStyles.typeText}>
                            {business
                                ? Localizable.t('profile.business')
                                : Localizable.t('profile.personal')}
                        </Text>
                    </View>
                    <View style={PaymentMethodsStyles.flexOne} />
                    <TouchableOpacity
                        onPress={() => handleDelete(item)}
                        style={PaymentMethodsStyles.deleteButton}
                    >
                        <Image
                            resizeMode="contain"
                            source={Images.icons.dots}
                            style={PaymentMethodsStyles.moreImage}
                        />
                    </TouchableOpacity>
                </View>
                <Text style={PaymentMethodsStyles.titleText}>
                    {Localizable.t('profile.creditCardNumberBold')}
                </Text>
                <Text style={PaymentMethodsStyles.cardText}>**** **** **** {item.last_four}</Text>
                <View style={PaymentMethodsStyles.moreView}>
                    <View style={PaymentMethodsStyles.nameView}>
                        <Text style={PaymentMethodsStyles.titleText}>
                            {Localizable.t('profile.creditCardNameBold')}
                        </Text>
                        <Text style={PaymentMethodsStyles.moreText}>
                            {item.card_name || '**** ****'}
                        </Text>
                    </View>
                    <View style={PaymentMethodsStyles.expirationView}>
                        <Text style={PaymentMethodsStyles.titleText}>
                            {Localizable.t('profile.creditCardExpBold')}
                        </Text>
                        <Text style={PaymentMethodsStyles.moreText}>
                            {item.expiration_month}/{item.expiration_year}
                        </Text>
                    </View>
                </View>
            </View>
        );
    };

    renderFooter = () => {
        const {
            paymentMethodsStore: { getPaymentMethods },
            navigationStore: { navigate },
        } = this.props;
        return (
            <TouchableOpacity
                onPress={() =>
                    navigate('AddCreditCard', {
                        onClose: getPaymentMethods,
                    })
                }
            >
                <View style={PaymentMethodsStyles.addButton}>
                    <Text style={PaymentMethodsStyles.addText}>
                        {Localizable.t('profile.creditCardAdd')}
                    </Text>
                </View>
            </TouchableOpacity>
        );
    };

    render() {
        const {
            paymentMethodsStore: { getPaymentMethods, paymentMethods, refreshing },
            authStore,
            appStore,
            navigationStore: { goBack },
        } = this.props;
        return (
            <View style={PaymentMethodsStyles.container}>
                <TitleNavigationBar
                    authStore={authStore}
                    appStore={appStore}
                    title={Localizable.t('profile.paymentMethods')}
                    handleBackClick={() => goBack()}
                />
                <FlatList
                    refreshing={refreshing}
                    onRefresh={getPaymentMethods}
                    keyExtractor={item => item.name}
                    data={paymentMethods}
                    renderItem={this.renderRow}
                    ListFooterComponent={this.renderFooter}
                />
            </View>
        );
    }
}

import BaseStore from '../BaseStore';
import { observable, action, flow } from 'mobx';

import GoogleClient from '../../services/geolocation/GoogleClient';
import { Analytics } from '../../lib';

export default class ExampleRouteStore extends BaseStore {
    @observable
    nearbySuggestions: Array<any> = [];

    @observable
    nearbyAddressesFetched = false;

    @observable
    exampleDestinationTooltipVisible = false;

    @action
    setDestinationText = (address: string) => {
        const {
            addressStore: { updateDestinationText },
            suggestionsStore: { updateText },
        } = this.rootStore.stores;
        updateDestinationText(address);
        updateText();
    };

    @action
    setExampleDestinationTooltipVisible = (visible: boolean) => {
        this.exampleDestinationTooltipVisible = visible;
    };

    getNearbyAddresses = flow(function*(this: ExampleRouteStore) {
        const {
            addressStore: { pickupLocation },
        } = this.rootStore.stores;
        try {
            const [
                {
                    data: { results: airports },
                },
                {
                    data: { results: trainStations },
                },
            ] = yield Promise.all([
                GoogleClient.getNearbyPlaces(
                    {
                        latitude: pickupLocation.latitude,
                        longitude: pickupLocation.longitude,
                    },
                    'airport'
                ),
                GoogleClient.getNearbyPlaces(
                    {
                        latitude: pickupLocation.latitude,
                        longitude: pickupLocation.longitude,
                    },
                    'train_station'
                ),
            ]);
            this.nearbySuggestions.push(...airports.slice(0, 3), ...trainStations.slice(0, 3));
            this.nearbyAddressesFetched = true;
        } catch (error) {
            this.setForcedNearbyLocation();
        }
    }).bind(this);

    setForcedPickupLocation = () => {
        const {
            addressStore: { setPickupLocation, setPickup },
        } = this.rootStore.stores;
        setPickup('1 Wall Street, New York');
        setPickupLocation({
            latitude: 40.707611,
            longitude: -74.011403,
            address: '1 Wall Street, New York',
        });
    };

    @action
    setForcedNearbyLocation = () => {
        this.nearbySuggestions = [
            {
                name: 'Grand Central Terminal, New York',
                geometry: {
                    location: {
                        lat: 40.752522,
                        lng: -73.978324,
                    },
                },
            },
        ];
        this.nearbyAddressesFetched = true;
    };

    @action
    setDestination = () => {
        const {
            addressStore: { setDestination, setDestinationLocation, setFocusedInput },
            rateAppStore,
            taxiStore: {
                setTaxiState,
                taxiRouteStore: { updateRoute },
                taxiResultsStore,
            },
            onboardingStore: { dismissOnboarding },
        } = this.rootStore.stores;
        const exampleDestination = this.nearbySuggestions[
            Math.floor(Math.random() * this.nearbySuggestions.slice().length)
        ];
        setDestinationLocation({
            address: exampleDestination.name,
            latitude: exampleDestination.geometry.location.lat,
            longitude: exampleDestination.geometry.location.lng,
        });
        setDestination(exampleDestination.name);
        this.setExampleDestinationTooltipVisible(true);
        dismissOnboarding();
        setTaxiState('taxis');
        updateRoute(true, true);
        setFocusedInput(null);
        taxiResultsStore.typesLoading = true;
        Analytics.trackScreenView('TaxiList');
        rateAppStore.increaseSearchCounter();
    };
}

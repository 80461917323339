import React, { Component } from 'react';
import { FlatList } from 'react-native';
import { observer, inject } from 'mobx-react';

import BikeRow from '../row/BikeRow';
import ComparationListStyles from './styles/ComparationListStyles';
import TaxiRow from '../row/TaxiRow';
import TaxiStore from 'stores/TaxiStore';
import TaxisEmpty from './TaxisEmpty';
import { Stores } from 'stores/RootStore';

interface Props {
    taxiStore: TaxiStore;
}

@inject(({ taxiStore }: Stores) => ({
    taxiStore,
}))
@observer
export default class ComparationList extends Component<Props> {
    renderRow = ({ item, index }: { item: any; index: number }) => {
        return !item ? null : item.bike ? (
            <BikeRow item={item} index={index} testID={`item_bike_${index}`} />
        ) : (
            <TaxiRow index={index} item={item} testID={`item_taxi_${index}`} />
        );
    };

    renderEmpty = () => {
        return <TaxisEmpty />;
    };

    keyExtractor = (item: any, index: number) => {
        return `${item}${index}`;
    };

    render() {
        const {
            taxiStore: { taxiResultsStore },
        } = this.props;
        return (
            <FlatList
                data={taxiResultsStore.rideTypesOrdered}
                keyExtractor={this.keyExtractor}
                ref={taxiResultsStore.taxisComparationStore.flatListRef}
                renderItem={this.renderRow}
                style={ComparationListStyles.taxisComparationList}
                testID="list_compare"
                ListEmptyComponent={this.renderEmpty}
                contentContainerStyle={ComparationListStyles.taxisComparationListContainer}
            />
        );
    }
}

/* eslint-disable no-lonely-if */
import { Animated } from 'react-native';
import { NavigationActions } from '@react-navigation/core';
import { observable, action, computed } from 'mobx';

import MapLandingRefStore from './componentRefs/MapLandingRefStore';
import { BaseStore } from 'stores';
import { MainView } from 'containers/taxis';

function getCurrentState(state: any): any {
    const childRoute = state.routes[state.index];
    if (childRoute.routes) {
        return getCurrentState(childRoute);
    }
    return childRoute;
}

export default class NavigationStore extends BaseStore {
    navigator: any;

    mainViewRef?: MainView;
    rideDetailsViewRef?: RideDetailsView;
    mapLandingRefStore = new MapLandingRefStore(this.rootStore);

    @observable
    drawerOffset: Animated.Value = new Animated.Value(0);

    @observable
    drawerOpen = false;

    @observable
    currentRouteName: string = 'MainView';

    setNavigator = (navigator: any): void => {
        this.navigator = navigator;
    };

    handleHardwareBackClick = () => {
        if (this.currentRouteName === 'MainView' && this.mainViewRef) {
            this.mainViewRef.handleBackClick(true);
        } else {
            this.goBack();
        }
        return true;
    };

    goBack = (key?: string): void => {
        if (!this.navigator) {
            return;
        }
        if (this.drawerOpen) {
            this.closeDrawer();
        }
        if (this.navigator.state.nav.routes.length <= 1) {
            this.navigator.dispatch(NavigationActions.navigate({ routeName: 'MainView' }));
        } else {
            if (key) {
                this.navigator.dispatch(NavigationActions.back({ key }));
            } else {
                this.navigator.dispatch(NavigationActions.back());
            }
        }
    };

    navigate = (routeName: string, params?: object, navAction?: object): void => {
        if (!this.navigator) {
            return;
        }
        if (this.drawerOpen) {
            this.closeDrawer();
        }
        this.navigator.dispatch(
            NavigationActions.navigate({
                routeName,
                params,
            })
        );
    };

    @action
    closeDrawer = () => {
        Animated.timing(this.drawerOffset, {
            toValue: 0,
            duration: 500,
        }).start();
        this.drawerOpen = false;
    };

    @action
    openDrawer = () => {
        Animated.timing(this.drawerOffset, {
            toValue: 1,
            duration: 500,
        }).start();
        this.drawerOpen = true;
    };

    setMainViewRef = (viewRef: any) => {
        this.mainViewRef = viewRef;
    };

    setRideDetailsViewRef = (rideDetailsViewRef: RideDetailsView) => {
        this.rideDetailsViewRef = rideDetailsViewRef;
    };

    onNavigationStateChange = (): void => {
        this.setCurrentRouteName(this.getCurrentRouteName());
    };

    @action
    setCurrentRouteName = (routeName: string): void => {
        this.currentRouteName = routeName;
    };

    getCurrentRouteName = (): string =>
        (this.navigator && getCurrentState(this.navigator.state.nav).routeName) || '';

    @computed
    get drawerWidth(): number {
        const { layoutStore } = this.rootStore.stores;
        return layoutStore.screenHeight > layoutStore.screenWidth
            ? (layoutStore.screenWidth * 4) / 5
            : 450;
    }
}

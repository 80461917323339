/* eslint-disable camelcase */
import React from 'react';
import { Callout } from 'modules/maps/maps';
import { View, Image } from 'react-native';
import { observer } from 'mobx-react';

import BikeTooltip from './BikeTooltip';
import Marker from '../../../taxis/Marker';
import { Metrics } from '../../../../themes';
import { bikeProviderInfo } from '../../../../services/Utils';
import styles from './styles';

interface Props {
    station: any;
    selectBikeOnMap: (station: any) => void;
    selectedBikeOnMap: any;
    calloutActive: boolean;
}

const normalStyle = { width: Metrics.normalize(36), height: Metrics.normalize(41) };
const selectedStyle = { width: Metrics.normalize(40), height: Metrics.normalize(46) };
const notSelectedStyle = { width: Metrics.normalize(30), height: Metrics.normalize(35) };

export default observer(({ station, selectBikeOnMap, selectedBikeOnMap, calloutActive }: Props) => {
    const {
        location: { latitude, longitude, name },
        num_bikes_available,
        num_docks_available,
        provider,
    } = station;
    const [bikesAvailable, docksAvailable] = [num_bikes_available || 0, num_docks_available || 0];
    const [baseIcon, overIcon] = [
        bikeProviderInfo(provider).iconMapFull,
        bikeProviderInfo(provider).iconMapEmpty,
    ];
    const availibilityRatio = bikesAvailable / (bikesAvailable + docksAvailable);
    const style =
        selectedBikeOnMap === null
            ? normalStyle
            : selectedBikeOnMap.location.name === name
            ? selectedStyle
            : notSelectedStyle;

    const blueHeight = Math.round(
        style.height * (isNaN(availibilityRatio) ? 1 : availibilityRatio)
    );
    const key = JSON.stringify(station.location);
    return (
        <Marker
            anchor={{ x: 0.501, y: 1 }}
            key={key}
            zIndex={11}
            coordinate={{ latitude, longitude }}
            name={name}
            onPress={() => {
                selectBikeOnMap(station);
            }}
            additionalProps={selectedBikeOnMap && selectedBikeOnMap.location.name}
        >
            <View style={[style, style.height === Metrics.normalize(35) && { opacity: 0.2 }]}>
                <Image source={baseIcon} style={style} resizeMode="contain" />
                <View style={[styles.overIconView, { bottom: blueHeight }]}>
                    <Image source={overIcon} style={style} resizeMode="contain" />
                </View>
            </View>
            <Callout tooltip>
                {calloutActive ? (
                    <BikeTooltip
                        bikesAvailable={bikesAvailable}
                        provider={provider}
                        docksAvailable={docksAvailable}
                    />
                ) : (
                    <View />
                )}
            </Callout>
        </Marker>
    );
});

import Animatable from 'modules/animatable/animatable';
import React, { Component } from 'react';
import Voca from 'voca';
import { View, TouchableOpacity, Text, TextInput, Image } from 'react-native';
import { observer, inject } from 'mobx-react';

import SmallMapAddressPickerStyles from './styles/SmallMapAddressPickerStyles';
import AddressStore from '../../stores/AddressStore';
import SmallMapAddressPickerSuggestion from './SmallMapAddressPickerSuggestion';
import SuggestionsStore from '../../stores/SuggestionsStore';
import { Colors, Images } from '../../themes';
import { Stores } from '../../stores/RootStore';

interface Props {
    addressStore: AddressStore;
    suggestionsStore: SuggestionsStore;
}

@inject(({ addressStore, suggestionsStore }: Stores) => ({
    addressStore,
    suggestionsStore,
}))
@observer
export default class SmallMapAddressPicker extends Component<Props> {
    focusInput = () => {
        const {
            addressStore: {
                smallAddressPickerStore: { textInputRef },
            },
        } = this.props;
        if (textInputRef.current) {
            textInputRef.current.focus();
        }
    };

    onClearInputPress = () => {
        const {
            addressStore: {
                smallAddressPickerStore: { onChangeText, toggleTextChanged },
            },
        } = this.props;
        toggleTextChanged(true);
        onChangeText('');
        this.focusInput();
    };

    render() {
        const {
            addressStore: {
                smallAddressPickerStore: {
                    textInputRef,
                    selectSugestion,
                    onChangeText,
                    textInputValue,
                    locationForText,
                    showSuggestions,
                },
            },
            suggestionsStore,
        } = this.props;
        const [suggestion1, suggestion2] = suggestionsStore.addressSuggestions;

        return (
            <Animatable.View
                animation="fadeIn"
                pointerEvents="box-none"
                duration={300}
                style={SmallMapAddressPickerStyles.container}
                useNativeDriver
            >
                <TouchableOpacity
                    onPress={this.focusInput}
                    style={SmallMapAddressPickerStyles.listContainer}
                >
                    <View style={SmallMapAddressPickerStyles.addressContainer}>
                        <View style={SmallMapAddressPickerStyles.addressLeftContainer}>
                            <View style={SmallMapAddressPickerStyles.addressDotOuter}>
                                <View
                                    style={[
                                        SmallMapAddressPickerStyles.addressDot,
                                        {
                                            backgroundColor: Colors.obiTeal,
                                        },
                                    ]}
                                />
                            </View>
                        </View>
                        <View style={SmallMapAddressPickerStyles.addressLocationContainer}>
                            <View style={SmallMapAddressPickerStyles.locationContainer}>
                                <Text
                                    style={[
                                        SmallMapAddressPickerStyles.labelText,
                                        {
                                            color: Colors.grayTextColor,
                                        },
                                    ]}
                                >
                                    CURRENT {locationForText} LOCATION
                                </Text>
                                <TextInput
                                    ref={textInputRef}
                                    numberOfLines={1}
                                    style={SmallMapAddressPickerStyles.locationText}
                                    value={textInputValue}
                                    onChangeText={onChangeText}
                                    returnKeyType="search"
                                />
                            </View>
                            {!Voca.isBlank(textInputValue) && (
                                <TouchableOpacity
                                    style={SmallMapAddressPickerStyles.cancelContainer}
                                    onPress={this.onClearInputPress}
                                    key="touchablePickupCancel"
                                >
                                    <Image
                                        source={Images.settings.close}
                                        style={SmallMapAddressPickerStyles.cancelIcon}
                                    />
                                </TouchableOpacity>
                            )}
                        </View>
                    </View>
                </TouchableOpacity>
                {showSuggestions && (
                    <View style={SmallMapAddressPickerStyles.listContainer}>
                        {suggestion1 && (
                            <SmallMapAddressPickerSuggestion
                                item={suggestion1}
                                selectSugestion={selectSugestion}
                            />
                        )}
                        {suggestion2 && (
                            <SmallMapAddressPickerSuggestion
                                item={suggestion2}
                                selectSugestion={selectSugestion}
                            />
                        )}
                    </View>
                )}
            </Animatable.View>
        );
    }
}

import React, { Component } from 'react';
import { Animated, View, StyleSheet } from 'react-native';
import { Colors } from 'themes';
import { observer } from 'mobx-react';

const CircularProgressStyles = StyleSheet.create({
    container: {
        backgroundColor: Colors.transparent,
        justifyContent: 'center',
        alignItems: 'center',
    },
    firstLayer: {
        position: 'absolute',
        borderLeftColor: 'transparent',
        borderBottomColor: 'transparent',
        transform: [{ rotateZ: '-45deg' }],
    },
    secondLayer: {
        position: 'absolute',
        borderLeftColor: 'transparent',
        borderBottomColor: 'transparent',
        transform: [{ rotateZ: '135deg' }],
    },
});

interface Props {
    backgroundColor: string;
    fill: Animated.Value;
    prefill: number;
    size: number;
    tintColor: string;
    width: number;
}

@observer
export default class CircularProgress extends Component<Props> {
    percent: number = 0;

    componentDidMount() {
        const { fill, prefill } = this.props;
        this.percent = Number(`${fill}`) + prefill;
    }

    componentDidUpdate() {
        const { fill, prefill } = this.props;
        this.percent = Number(`${fill}`) + prefill;
    }

    rotateZ = (fill: number, prefill: number) => {
        const rotateBy = prefill + fill * 3.6;
        return {
            transform: [{ rotateZ: `${rotateBy}deg` }],
        };
    };

    render() {
        const { backgroundColor, size, width, children, tintColor } = this.props;
        const style = {
            width: size,
            height: size,
            borderRadius: size / 2,
            borderWidth: width,
        };
        const borderColorTintStyle = {
            borderRightColor: tintColor,
            borderTopColor: tintColor,
        };
        return (
            <View
                style={[
                    {
                        borderColor: backgroundColor,
                    },
                    style,
                    CircularProgressStyles.container,
                ]}
            >
                <View
                    style={[
                        style,
                        borderColorTintStyle,
                        CircularProgressStyles.firstLayer,
                        this.rotateZ(this.percent > 50 ? 50 : this.percent, -45),
                    ]}
                />
                {this.percent > 50 ? (
                    <View
                        style={[
                            style,
                            borderColorTintStyle,
                            CircularProgressStyles.secondLayer,
                            this.rotateZ(this.percent - 50, 135),
                        ]}
                    />
                ) : (
                    <View
                        style={[
                            {
                                borderRightColor: backgroundColor,
                                borderTopColor: backgroundColor,
                            },
                            style,
                            CircularProgressStyles.firstLayer,
                        ]}
                    />
                )}
                {children}
            </View>
        );
    }
}

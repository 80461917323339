import React, { Component } from 'react';
import { View, Text, Image, TouchableOpacity } from 'react-native';
import { inject, observer } from 'mobx-react';

import Modal from 'modules/modal/modal';

import ServicesList from '../services/newServiceList/ServicesList';
import ServicesModalStyles from './styles/ServicesModalStyles';
import ServicesStore from 'stores/ServicesStore';
import { Images } from 'themes';
import { Localizable } from 'lib';
import { useStores } from 'containers/App';

export default observer(() => {
    const {
        stores: {
            servicesStore: { servicesModalVisible, hideServicesModal, dontShowServicesModalAgain },
            layoutStore,
        },
    } = useStores();
    return (
        <Modal
            animationIn="zoomIn"
            animationOut="zoomOut"
            isVisible={servicesModalVisible}
            avoidKeyboard
        >
            <View
                style={[
                    ServicesModalStyles.container,
                    { height: (layoutStore.screenHeight / 5) * 4 },
                ]}
            >
                <TouchableOpacity
                    style={ServicesModalStyles.closeButton}
                    onPress={hideServicesModal}
                    hitSlop={{ top: 10, right: 10, left: 10, bottom: 10 }}
                >
                    <Image
                        source={Images.services.close}
                        style={ServicesModalStyles.closeButtonImage}
                    />
                </TouchableOpacity>
                <Image source={Images.services.userImage} />
                <Text style={ServicesModalStyles.linkAccountsText}>
                    {Localizable.t('services.modal.linkAccounts')}
                </Text>
                <Text style={ServicesModalStyles.nextLevelText}>
                    {Localizable.t('services.modal.takeToNextLevel')}
                </Text>
                <View style={ServicesModalStyles.servicesList}>
                    <ServicesList />
                </View>
                <TouchableOpacity
                    style={ServicesModalStyles.notNowButton}
                    onPress={hideServicesModal}
                >
                    <Text style={ServicesModalStyles.notNowText}>
                        {Localizable.t('services.modal.notNow')}
                    </Text>
                </TouchableOpacity>
                <View style={ServicesModalStyles.separator} />
                <TouchableOpacity
                    style={ServicesModalStyles.dontShowButton}
                    onPress={dontShowServicesModalAgain}
                >
                    <Text style={ServicesModalStyles.dontShowText}>
                        {Localizable.t('services.modal.dontShow')}
                    </Text>
                </TouchableOpacity>
            </View>
        </Modal>
    );
});

import React from 'react';
import { observer, inject } from 'mobx-react';

import BikeMarkerEndBiking from './BikeMarkerEndBiking';
import BikeMarkerEndWalking from './BikeMarkerEndWalking';
import BikeMarkerRide from './BikeMarkerRide';
import BikeMarkerStartBiking from './BikeMarkerStartBiking';
import BikeMarkerStartWalking from './BikeMarkerStartWalking';
import BikeStore from '../../../../../stores/BikeStore';
import { Stores } from '../../../../../stores/RootStore';

interface Props {
    bikeStore: BikeStore;
}

const BikeRouteMarkers = ({ bikeStore }: Props) => {
    if (!bikeStore.selectedBike || !bikeStore.showBikeRouteMarkers) {
        return null;
    }
    const {
        details: { routeDetails: { walkToStation, biking, walkFromStation } = {} } = {},
    } = bikeStore.selectedBike;
    return (
        <>
            {biking.route && (
                <>
                    <BikeMarkerStartWalking key="startWalking" coords={walkToStation.route[0]} />
                    <BikeMarkerStartBiking key="startBiking" coords={biking.route[0]} />
                    {biking.route.length > 0 && (
                        <BikeMarkerRide
                            key="ride"
                            coords={biking.route[Math.floor(biking.route.length / 2)]}
                        />
                    )}
                </>
            )}{' '}
            {walkFromStation.route && (
                <>
                    <BikeMarkerEndBiking key="endBiking" coords={walkFromStation.route[0]} />
                    <BikeMarkerEndWalking
                        key="endWalking"
                        coords={walkFromStation.route[walkFromStation.route.length - 1]}
                    />
                </>
            )}
        </>
    );
};

export default inject(({ bikeStore }: Stores) => ({ bikeStore }))(observer(BikeRouteMarkers));

import { StyleSheet } from 'react-native';
import { Colors, Metrics, Fonts } from 'themes';

export default StyleSheet.create({
    modalContainer: {
        backgroundColor: 'white',
        paddingTop: 20,
        paddingBottom: 10,
        paddingHorizontal: 20,
        borderRadius: 16,
        alignItems: 'center',
    },
    modalTitle: {
        textAlign: 'center',
        color: Colors.black,
        fontSize: Metrics.normalize(19),
        fontWeight: '600',
        fontFamily: Fonts.type.base,
        // marginBottom: 16,
    },
    description: {
        textAlign: 'center',
        color: Colors.black,
        fontSize: Metrics.normalize(16),
        fontFamily: Fonts.type.base,
        fontWeight: '400',
        marginBottom: 16,
    },
    modalButton: {
        alignSelf: 'stretch',
        marginVertical: 16,
    },
    providerImage: {
        width: 60,
        height: 60,
        margin: 30,
    },
});

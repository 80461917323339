import { StyleSheet } from 'react-native';
import { Colors, Metrics, Fonts } from 'themes';

export default StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'flex-end',
    },
    modalContainer: {
        backgroundColor: 'white',
        paddingBottom: 10,
        borderRadius: 16,
        alignItems: 'center',
    },
    closeButton: {
        position: 'absolute',
        top: 0,
        right: 0,
        paddingBottom: 10,
        paddingLeft: 10,
    },
    closeButtonImage: {
        alignSelf: 'flex-end',
        margin: 20,
    },
    topImage: {
        marginBottom: 30,
    },
    modalTitle: {
        textAlign: 'center',
        color: Colors.black,
        fontSize: Metrics.normalize(18),
        fontFamily: Fonts.type.base,
        fontWeight: '600',
        marginBottom: 16,
    },
    modalInfo: {
        textAlign: 'center',
        color: Colors.grayTextColor,
        fontSize: Metrics.normalize(12),
        fontFamily: Fonts.type.base,
        fontWeight: '500',
    },
    modalButton: {
        alignSelf: 'stretch',
        marginVertical: 16,
        marginHorizontal: 20,
    },
    activityIndicatorView: {
        padding: 20,
        alignItems: 'center',
        justifyContent: 'center',
    },
});

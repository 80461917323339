/* eslint-disable camelcase */
import React from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { observer, inject } from 'mobx-react';

import BottomRideInProgress from './BottomRideInProgress';
import Button from '../../Button';
import DriverAvatar from '../driverAvatar/DriverAvatar';
import TaxiStore from '../../../stores/TaxiStore';
import BottomRideViewStyles from './styles/BottomRideViewStyles';
import { Localizable } from '../../../lib';
import { Stores } from '../../../stores/RootStore';

interface Props {
    taxiStore: TaxiStore;
}

export default inject(({ taxiStore }: Stores) => ({ taxiStore }))(
    observer(
        ({
            taxiStore: {
                taxiRideStore: {
                    driverAvatarSource,
                    handleCancelRide,
                    callDriver,
                    messageDriver,
                    currentRide,
                    currentRideStatus,
                    etaText,
                },
            },
        }: Props) => {
            const {
                driver: { star_rating, name } = {},
                vehicle: { vehicle, vehicle_license_plate } = {},
                status,
            } = currentRideStatus;
            if (!name) {
                return null;
            }
            if (status === 'IN_PROGRESS') {
                return (
                    <BottomRideInProgress
                        currentRideStatus={currentRideStatus}
                        currentRide={currentRide}
                    />
                );
            }
            return (
                <View style={BottomRideViewStyles.container}>
                    <Text style={BottomRideViewStyles.etaText}>{etaText}</Text>
                    <View style={BottomRideViewStyles.separator} />
                    <View style={BottomRideViewStyles.rowContainer}>
                        <DriverAvatar imageSource={driverAvatarSource} rating={star_rating} />
                        <View style={BottomRideViewStyles.vehicleView}>
                            <Text style={BottomRideViewStyles.nameText}>{name}</Text>
                            <Text style={BottomRideViewStyles.carText}>
                                {vehicle} • {vehicle_license_plate}
                            </Text>
                        </View>
                        <View style={BottomRideViewStyles.empty} />
                        <TouchableOpacity
                            onPress={handleCancelRide}
                            hitSlop={{ top: 10, left: 10, right: 10, bottom: 10 }}
                        >
                            <Text style={BottomRideViewStyles.cancelText}>
                                {Localizable.t('taxis.bottomRideView.cancelRide')}
                            </Text>
                        </TouchableOpacity>
                    </View>
                    <View style={BottomRideViewStyles.rowContainer}>
                        <Button
                            style={BottomRideViewStyles.callDriverButton}
                            active
                            title={Localizable.t('taxis.bottomRideView.call')}
                            handlePress={callDriver}
                        />
                        <Button
                            style={BottomRideViewStyles.messageDriverButton}
                            active={false}
                            title={Localizable.t('taxis.bottomRideView.chat')}
                            handlePress={messageDriver}
                        />
                    </View>
                </View>
            );
        }
    )
);

import React, { Component } from 'react';
import { Image, View, Text, ScrollView } from 'react-native';
import { observer, inject } from 'mobx-react';
import moment from 'moment';
import getEnv from 'modules/config/config';
import { NavigationParams } from 'react-navigation';

import TitleNavigationBar from 'components/navigationBars/TitleNavigationBar';

import AppStore from '../../stores/AppStore';
import AuthStore from '../../stores/AuthStore';
import NavigationStore from 'stores/navigationStores/NavigationStore';
import RideReceiptStyles from './styles/RideReceiptStyles';
import { Analytics, Localizable } from '../../lib';
import { Colors, Images } from '../../themes';
import { Stores } from '../../stores/RootStore';
import { providerInfo } from '../../services/Utils';

interface Props {
    authStore: AuthStore;
    appStore: AppStore;
    navigation: NavigationParams;
    navigationStore: NavigationStore;
}

@inject(({ appStore, authStore, navigationStore }: Stores) => ({
    appStore,
    authStore,
    navigationStore,
}))
@observer
export default class RideHistory extends Component<Props, {}> {
    render() {
        const {
            appStore,
            authStore,
            navigation: {
                state: {
                    params: { rideItem },
                },
            },
            navigationStore: { goBack },
        } = this.props;

        return (
            <View style={RideReceiptStyles.container}>
                <TitleNavigationBar
                    authStore={authStore}
                    appStore={appStore}
                    title={Localizable.t('history.receiptTitle')}
                    handleBackClick={() => goBack()}
                />
                <ScrollView>
                    <View style={RideReceiptStyles.topContainer}>
                        <Image
                            style={RideReceiptStyles.mapContainer}
                            source={{
                                uri: `https://maps.googleapis.com/maps/api/staticmap?center=${
                                    rideItem.destination.latitude
                                },${
                                    rideItem.destination.longitude
                                }&zoom=13&size=600x300&maptype=roadmap&key=${getEnv(
                                    'GOOGLE_API_KEY'
                                )}`,
                            }}
                        />
                        <View style={RideReceiptStyles.infoContainer}>
                            <View style={RideReceiptStyles.infoItem}>
                                <Image
                                    source={Images.icons.timeGray}
                                    style={RideReceiptStyles.infoIcon}
                                    resizeMode="contain"
                                />
                                <View>
                                    <Text style={RideReceiptStyles.infoTitleText}>ETA</Text>
                                    <Text style={RideReceiptStyles.infoDescriptionText}>2 min</Text>
                                </View>
                            </View>
                            <View style={RideReceiptStyles.infoItem}>
                                <Image
                                    source={Images.icons.distanceGray}
                                    style={RideReceiptStyles.infoIcon}
                                    resizeMode="contain"
                                />
                                <View>
                                    <Text style={RideReceiptStyles.infoTitleText}>Distance</Text>
                                    <Text style={RideReceiptStyles.infoDescriptionText}>
                                        {rideItem.estimated_distance} mi
                                    </Text>
                                </View>
                            </View>
                            <View style={RideReceiptStyles.infoItem}>
                                <Image
                                    source={Images.icons.durationGray}
                                    style={RideReceiptStyles.infoIcon}
                                    resizeMode="contain"
                                />
                                <View>
                                    <Text style={RideReceiptStyles.infoTitleText}>Duration</Text>
                                    <Text style={RideReceiptStyles.infoDescriptionText}>
                                        {Math.round(rideItem.estimated_duration / 60)} min
                                    </Text>
                                </View>
                            </View>
                        </View>
                        <View style={RideReceiptStyles.providerContainer}>
                            <Image
                                style={RideReceiptStyles.providerIcon}
                                source={providerInfo(rideItem.service_provider).icon}
                            />
                            <View style={RideReceiptStyles.flexOne}>
                                <Text style={RideReceiptStyles.providerTitleText}>
                                    {rideItem.offer.provider.company}
                                </Text>
                                <Text style={RideReceiptStyles.providerSeatsText}>
                                    {rideItem.seats} seats
                                </Text>
                            </View>
                            <View>
                                <Text style={RideReceiptStyles.priceText}>
                                    {rideItem.offer.rate}
                                    {rideItem.fee}
                                </Text>
                                <Text style={RideReceiptStyles.priceValueText}>Fee</Text>
                            </View>
                        </View>
                    </View>
                    <View style={RideReceiptStyles.bottomContainer}>
                        <View style={RideReceiptStyles.addressContainer}>
                            <View>
                                <Text style={RideReceiptStyles.dateText}>
                                    {moment(
                                        rideItem.ask.schedule,
                                        "YYYY-MM-DD'T'HH:mm:ss.SSS'Z'"
                                    ).format('MMMM D [\nat] hh:mm')}
                                </Text>
                                <View style={RideReceiptStyles.flexOne} />
                                <Text style={RideReceiptStyles.dateText}>
                                    {moment(rideItem.ask.schedule, "YYYY-MM-DD'T'HH:mm:ss.SSS'Z'")
                                        .add(rideItem.estimated_duration, 's')
                                        .format('MMMM D [\nat] hh:mm')}
                                </Text>
                            </View>
                            <Image
                                source={Images.icons.route}
                                style={RideReceiptStyles.routeImage}
                                resizeMode="contain"
                            />
                            <View>
                                <View style={RideReceiptStyles.addressTextView}>
                                    <Text style={RideReceiptStyles.addressText} numberOfLines={1}>
                                        {rideItem.ask.pickup_address}
                                    </Text>
                                    <Text style={RideReceiptStyles.addressValueText}>Pickup</Text>
                                </View>
                                <View style={RideReceiptStyles.addressTextView}>
                                    <Text style={RideReceiptStyles.addressText} numberOfLines={1}>
                                        {rideItem.ask.destination_address}
                                    </Text>
                                    <Text style={RideReceiptStyles.addressValueText}>
                                        Destination
                                    </Text>
                                </View>
                            </View>
                        </View>
                        <View style={RideReceiptStyles.separator} />
                        <View style={RideReceiptStyles.additionContainer}>
                            <Text style={RideReceiptStyles.additionText}>Trip Date</Text>
                            <Text style={RideReceiptStyles.additionValueText}>
                                {moment(
                                    rideItem.ask.schedule,
                                    "YYYY-MM-DD'T'HH:mm:ss.SSS'Z'"
                                ).format('MMMM D [at] hh:mm A')}
                            </Text>
                        </View>
                        <View style={RideReceiptStyles.separator} />
                        <View style={RideReceiptStyles.additionContainer}>
                            <Text style={RideReceiptStyles.additionText}>Status</Text>
                            <Text
                                style={[
                                    RideReceiptStyles.additionValueText,
                                    {
                                        color:
                                            rideItem.status === 'CANCELED'
                                                ? Colors.obiTeal
                                                : Colors.priceColor,
                                    },
                                ]}
                            >
                                {rideItem.status}
                            </Text>
                        </View>
                        <View style={RideReceiptStyles.separator} />
                        {rideItem.status === 'CANCELED' && (
                            <View>
                                <View style={RideReceiptStyles.additionContainer}>
                                    <Text style={RideReceiptStyles.additionText}>
                                        Cancellation fee
                                    </Text>
                                    <Text style={RideReceiptStyles.additionValueText}>
                                        {rideItem.offer.rate}
                                        {rideItem.fee}
                                    </Text>
                                </View>
                                <View style={RideReceiptStyles.separator} />
                            </View>
                        )}
                    </View>
                </ScrollView>
            </View>
        );
    }
}

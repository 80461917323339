import { Linking, Platform, Clipboard } from 'react-native';
import { ShareOption, MessageOptions } from 'domain/app/ShareUtils';

const shareMessage = ({
    channel,
    deeplink,
    title = '',
    message = '',
    phoneNumber = '',
}: MessageOptions) => {
    const encodedMessageWithDeeplink = encodeURIComponent(`${message}${deeplink}`);
    const encodedTitle = encodeURIComponent(title);
    const messageWithDeeplink = `${message}${deeplink}`;
    switch (channel) {
        case 'email':
            sendEmail({ title: encodedTitle, message: encodedMessageWithDeeplink });
            break;
        case 'sms':
            sendSms({ title: encodedTitle, message: encodedMessageWithDeeplink, phoneNumber });
            break;
        case 'copy':
            setToClipboard({ message: messageWithDeeplink });
            break;
        default:
            break;
    }
};

const setToClipboard = ({ message }: ShareOption) => {
    Clipboard.setString(message);
};

const sendEmail = ({ title, message }: ShareOption) => {
    Linking.openURL(`mailto:?subject=${title}&body=${message}`)
        .then(result => {})
        .catch(err => {});
};

const sendSms = ({ message, phoneNumber = '' }: ShareOption) => {
    const smslink =
        Platform.OS === 'ios'
            ? `sms:${phoneNumber}&body=${message}`
            : `sms:${phoneNumber}?body=${message}`;
    Linking.openURL(smslink)
        .then(result => {})
        .catch(err => {});
};

export default shareMessage;

const colors = {
    aquamarine: '#a0ffeb',
    brightPurple: '#7837c3',
    darkPurple: '#230064',
    darkTeal: '#00415f',
    gray1: '#191e28',
    gray2: '#6e7587',
    gray3: '#d9e0eb',
    gray4: '#f5faff',
    lavender: '#dbc2ff',
    obiTeal: '#14ffeb',
    ultralightPurple: '#f5ebff',
    ultralightTeal: '#dbfffa',
    white: '#ffffff',
    black: '#000000',
    transparent: '#00000000',

    blueButtonColor: '#007AFF',
    businessCardColor: '#394BCC',
    centerColor: '#4285F4',
    city: '#1E214A',
    closeButtonBackgroundColor: '#F3F3F3',
    darkGreyTextColor: '#666666',
    darkText: '#2D3142',
    darkTextColor: '#1a1b24',
    darkerGrayBackgroundColor: '#eff1f5',
    graySeparatorColor: '#F1F3F8',
    grayTextColor: '#8A8A8F',
    grayTextColor2: '#8D92A3',
    separatorColor: '#FBFBFC',
    sugestionsColor: '#FAFAFA',
    suggestionTitleColor: '#000000',
    walkingRouteColor: '#60636C',
    routeDisabledColor: '#ACB4BF',
    rideIconsColor: '#ADB5CA',
    defaultTextColor: '#6f6f6f',
    destinationColor: '#00CD5D',
    detailsGrayColor: '#B8B8B8',
    drawerIndicator: '#E4E5EE',
    grayBackgroundColor: '#F9F9F9',
    lightGrayText: '#9C9EB9',
    lightGrayColor: '#D9DADB',
    lighterGrayColor: '#EFEFEF',
    newProvider: '#39365B',
    priceColor: '#00CD5D',
    serviceNameColor: '#b1b1b1',
    transporentBackground: '#ADB5CA44',
    typesBackgroundColor: '#F8F8F8',
    avatarBackground: '#FFDBC3',
    logoutButtonText: '#9C9AA8',
    logoutButtonBorder: '#979797',
    surge: [
        { base: '#E1FFE1', text: '#22A45D' },
        { base: '#EFEFF4', text: '#9C9EB9' },
        { base: '#FFFADF', text: '#FACB00' },
        { base: '#FFEAE5', text: '#FF5733' },
    ],
    predictions: {
        up: { backgroundColor: 'rgb(255,223,244)', color: '#F54848', shadowColor: '' },
        down: { backgroundColor: 'rgb(229,247,233)', color: '#50C96A', shadowColor: '' },
        same: { backgroundColor: 'rgb(239,241,240)', color: '#C5C5C5', shadowColor: '' },
    },
};

export default colors;

import React from 'react';

import AuthModal from './AuthModal';
import LogoutModal from './LogoutModal';
import Modal from './modal/Modal';
import NetConnectionModal from './NetConnectionModal';
import NewProviderModal from './NewProviderModal';
import ServicesModal from './ServicesModal';

export default () => (
    <>
        <Modal />
        <ServicesModal />
        <NetConnectionModal />
        <NewProviderModal />
        <AuthModal />
        <LogoutModal />
    </>
);

import { StyleSheet } from 'react-native';
import { Fonts, Colors, Metrics } from 'themes';

export default StyleSheet.create({
    taxisComparationList: {
        borderTopColor: Colors.darkerGrayBackgroundColor,
        borderTopWidth: 1,
        flex: 1,
    },
    taxisComparationListContainer: { paddingBottom: 80, paddingTop: 10 },
    sectionFooter: {
        width: Metrics.screenWidth - 60,
        marginHorizontal: 30,
        height: 1,
        backgroundColor: Colors.darkerGrayBackgroundColor,
    },
});

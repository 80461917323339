import { View } from 'react-native';
import MapView from './webMaps/react-google-maps-api/MapView';
import Marker from './webMaps/react-google-maps-api/Marker';
import Polyline from './webMaps/react-google-maps-api/Polyline';

export default MapView;

const Callout = View;
const PROVIDER_GOOGLE = View;
export { Callout, Marker, PROVIDER_GOOGLE, Polyline };

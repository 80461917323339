/* eslint-disable react/no-array-index-key */
import React, { Component, ClassicComponent } from 'react';
import {
    View,
    TouchableOpacity,
    Text,
    Image,
    ScrollView,
    ViewStyle,
    ViewProps,
} from 'react-native';
import Animatable from 'modules/animatable/animatable';
import { observer } from 'mobx-react';
import { observable, action } from 'mobx';
import { Images } from '../../themes';
import GradientButton from '../GradientButton';
import VenuesPopupStyles from './styles/VenuesPopupStyles';
import Venue from 'domain/app/Venue';
import { Localizable } from '../../lib';

class VenuesPopupStore {
    @observable selectedVenue: number = 0;

    @action
    selectVenue = (index: number) => {
        this.selectedVenue = index;
    };
}

interface Props {
    venues: Array<Venue>;
    venueSelected: (index: number) => void;
    hidePopup: Function;
}

@observer
export default class VenuesPopup extends Component<Props, {}> {
    main?: ClassicComponent<Animatable.AnimatableProperties<ViewStyle> & ViewProps, any> | null;

    frame?: ClassicComponent<Animatable.AnimatableProperties<ViewStyle> & ViewProps, any> | null;

    venuesStore: VenuesPopupStore;

    constructor(props: Props) {
        super(props);
        this.venuesStore = new VenuesPopupStore();
    }

    componentDidMount() {}

    render() {
        const { venues, venueSelected, hidePopup } = this.props;

        return (
            <Animatable.View
                ref={ref => {
                    this.main = ref;
                }}
                animation="fadeIn"
                duration={300}
                style={VenuesPopupStyles.container}
                useNativeDriver
            >
                <Animatable.View
                    style={VenuesPopupStyles.innerContainer}
                    ref={ref => {
                        this.frame = ref;
                    }}
                    useNativeDriver
                >
                    <View style={VenuesPopupStyles.topView}>
                        <Text style={VenuesPopupStyles.titleText}>
                            {Localizable.t('taxis.venuesPopup.selectTerminal')}
                        </Text>
                        <TouchableOpacity onPress={() => hidePopup()}>
                            <View style={VenuesPopupStyles.closeView}>
                                <Image
                                    style={VenuesPopupStyles.closeButton}
                                    resizeMode="contain"
                                    source={Images.settings.close}
                                />
                            </View>
                        </TouchableOpacity>
                        <View style={VenuesPopupStyles.separator} />
                    </View>
                    <ScrollView contentContainerStyle={{ paddingHorizontal: 16 }}>
                        {venues.map((venue, index) => (
                            <TouchableOpacity
                                key={index}
                                onPress={() => this.venuesStore.selectVenue(index)}
                            >
                                <View
                                    style={
                                        this.venuesStore.selectedVenue === index
                                            ? VenuesPopupStyles.activePaymentView
                                            : VenuesPopupStyles.paymentView
                                    }
                                >
                                    {/* <Image
                                        source={card}
                                        style={VenuesPopupStyles.paymentCardImage}
                                        resizeMode="contain"
                                    /> */}
                                    <Text style={VenuesPopupStyles.paymentCardText}>
                                        {venue.name}
                                    </Text>
                                    <Text style={VenuesPopupStyles.descText}>
                                        {venue.description}
                                    </Text>
                                    <View style={VenuesPopupStyles.empty} />
                                </View>
                            </TouchableOpacity>
                        ))}
                    </ScrollView>
                    <GradientButton
                        style={VenuesPopupStyles.confirmButton}
                        title={Localizable.t('taxis.venuesPopup.confirm')}
                        handlePress={() => {
                            venueSelected(this.venuesStore.selectedVenue);
                            hidePopup();
                        }}
                    />
                </Animatable.View>
            </Animatable.View>
        );
    }
}

import { observable, action } from 'mobx';
import moment from 'moment';
import RestClient from '../services/RestClient';
import { distanceBetweenCoords } from '../services/Utils';
import { Analytics } from '../lib';
import BaseStore from './BaseStore';
import { UserLifecycle, AppLifecycle } from './RootStore';

interface Trip {
    provider: string;
    destination: string;
    distance: number;
    savings: number;
    date: string;
}

export default class SavingsStore extends BaseStore implements UserLifecycle, AppLifecycle {
    @observable
    tripCount: number = 0;

    @observable
    totalSavings: number = 0;

    @observable
    totalSavingsPerTrip: number = 0;

    @observable
    totalDistance: number = 0;

    @observable
    tripList: Array<Trip> = [];

    onUserReady = () => {
        this.getSavingsForAnalitics();
    };

    onAppWilGoToForeground = () => {
        this.getSavingsForAnalitics();
    };

    @action
    getSavings = () => {
        const {
            appStore: { showLoading, hideLoading },
        } = this.rootStore.stores;
        showLoading();
        return RestClient.getSavings()
            .then(response => {
                const {
                    data: {
                        number_of_trips: tripCount,
                        total_savings: totalSavings,
                        total_savings_per_trip: totalSavingsPerTrip,
                        recent_trips: recentTrips,
                    } = {},
                } = response;
                hideLoading();
                this.tripCount = tripCount;
                this.totalSavings = totalSavings;
                this.totalSavingsPerTrip = totalSavingsPerTrip;
                this.totalDistance = 0;
                const tripList = recentTrips.map(item => {
                    const distance = distanceBetweenCoords({
                        startCoord: item.pickup,
                        endCoord: item.destination,
                        unit: 'MILES',
                    });
                    this.totalDistance = this.totalDistance + distance;
                    return {
                        provider: item.service_provider.toLowerCase(),
                        destination: item.ask.destination_address,
                        pickup: item.ask.pickup_address,
                        pickupLocation: item.pickup,
                        destinationLocation: item.destination,
                        savings: item.offer.savings,
                        date: moment(
                            item.ask.schedule,
                            "YYYY-MM-DD'T'HH:mm:ss.SSS'Z'"
                        ).toISOString(),
                        dropOffTime: item.dropoff_time
                            ? moment(item.dropoff_time).toISOString()
                            : null,
                        distance,
                        orgItem: item,
                        seats: item.seats,
                        currency: item.offer.rate,
                    };
                });
                this.tripList = tripList;
                return tripList;
            })
            .catch(err => {
                console.log('get savings err', { ...err });
                hideLoading();
            });
    };

    @action
    getSavingsForAnalitics = () => {
        const {
            authStore: { user },
        } = this.rootStore.stores;
        RestClient.getSavings()
            .then(res => {
                const { data: { recent_trips: recentTrips = [] } = {} } = res;
                const lastDirectTrip = recentTrips.find((item, index: number) => {
                    return item.service_type && item.service_type.includes('ONDEMAND');
                });
                if (lastDirectTrip) {
                    Analytics.trackDirectSavings(user?.user_id, lastDirectTrip);
                }
            })
            .catch(err => {
                console.log('get savings err', { ...err });
            });
    };
}

import React, { Component } from 'react';
import { View, Image, Text, TouchableOpacity, StyleSheet } from 'react-native';
import { observer, inject } from 'mobx-react';
import Animatable from 'modules/animatable/animatable';
import { NavigationParams } from 'react-navigation';
import { Images } from '../../themes';
import { Analytics, Localizable } from '../../lib';
import ProfileStyles from './styles/ProfileStyles';
import GroupedListView from '../../components/profile/GroupedListView';
import Avatar from '../../components/profile/Avatar';
import LocationsStore from '../../stores/LocationsStore';
import AuthStore from '../../stores/AuthStore';
import AppStore from '../../stores/AppStore';
import { Stores } from '../../stores/RootStore';
import NavigationStore from 'stores/navigationStores/NavigationStore';
import { ReferralStore } from 'stores';

interface Props {
    authStore: AuthStore;
    appStore: AppStore;
    navigation: NavigationParams;
    navigationStore: NavigationStore;
    locationsStore: LocationsStore;
    referralStore: ReferralStore;
}

@inject(({ appStore, authStore, locationsStore, navigationStore, referralStore }: Stores) => ({
    appStore,
    authStore,
    locationsStore,
    navigationStore,
    referralStore,
}))
@observer
export default class Profile extends Component<Props, {}> {
    sections = () => {
        const {
            appStore,
            authStore,
            locationsStore,
            navigationStore: { navigate },
        } = this.props;
        return [
            {
                title: Localizable.t('profile.profile'),
                data: [
                    {
                        icon: Images.settings.name,
                        title: Localizable.t('profile.name'),
                        onPress: () => {
                            navigate('AdditionalInfo', { type: 'name' });
                        },
                        value:
                            authStore.user.first_name && authStore.user.surname
                                ? `${authStore.user.first_name} ${authStore.user.surname}`
                                : 'Set name',
                    },
                    {
                        icon: Images.settings.phone,
                        title: Localizable.t('profile.phone'),
                        onPress: () => {
                            navigate('AdditionalInfo', { type: 'phone' });
                        },
                        value:
                            authStore.user && authStore.user.country_code
                                ? `${authStore.user.country_code} ${authStore.user.phone_number}`
                                : authStore.user.phone_number || 'Set phone number',
                    },
                ],
            },
            {
                title: Localizable.t('profile.social'),
                data: [
                    {
                        icon: Images.settings.email,
                        title: Localizable.t('profile.invite'),
                        onPress: () => {
                            const {
                                referralStore: { toggleReferralDrawer },
                                navigation: { navigate },
                            } = this.props;
                            navigate('MainView');
                            toggleReferralDrawer();
                            Analytics.trackShare();
                        },
                    },
                ],
            },
            {
                title: Localizable.t('profile.favorites'),
                data: [
                    {
                        icon: Images.settings.home,
                        title: !locationsStore.getFavorites.home.empty
                            ? `${Localizable.t('profile.home')} - ${
                                  locationsStore.getFavorites.home.location.name
                              }`
                            : Localizable.t('profile.homeAdd'),
                        onPress: () => {
                            navigate('FavoriteAddressPicker', { type: 'home' });
                        },
                    },
                    {
                        icon: Images.settings.work,
                        title: !locationsStore.getFavorites.work.empty
                            ? `${Localizable.t('profile.work')} - ${
                                  locationsStore.getFavorites.work.location.name
                              }`
                            : Localizable.t('profile.workAdd'),
                        onPress: () => {
                            navigate('FavoriteAddressPicker', { type: 'work' });
                        },
                    },
                ],
            },
            {
                title: Localizable.t('profile.preferences'),
                data: [
                    {
                        icon: Images.settings.car,
                        title: Localizable.t('profile.updatesNotifications'),
                        switch: true,
                        enabled: authStore.updatesNotificationsEnabled,
                        onValueChanged: authStore.updatesNotificationValueChanged,
                    },
                    {
                        icon: Images.settings.notifications,
                        title: Localizable.t('profile.notifications'),
                        switch: true,
                        enabled: authStore.notificationsEnabled,
                        onValueChanged: authStore.notificationValueChanged,
                    },
                ],
            },
        ];
    };

    render() {
        const {
            appStore,
            authStore,
            navigationStore: { goBack },
        } = this.props;

        if (!authStore || !authStore.user) {
            return <View />;
        }

        return (
            <View style={ProfileStyles.container}>
                <View style={ProfileStyles.topView}>
                    <Avatar style={ProfileStyles.avatarView} size={60} />
                    {authStore.user && authStore.user.first_name && authStore.user.surname ? (
                        <Text style={ProfileStyles.nameText}>
                            {authStore.user.first_name} {authStore.user.surname}
                        </Text>
                    ) : (
                        <View style={ProfileStyles.empty} />
                    )}
                    <TouchableOpacity onPress={() => goBack()} style={ProfileStyles.closeView}>
                        <Image style={ProfileStyles.closeImage} source={Images.settings.close} />
                    </TouchableOpacity>
                    <View style={ProfileStyles.separator} />
                </View>
                <GroupedListView sections={this.sections()} />
            </View>
        );
    }
}

import React from 'react';
import { View, Text, TouchableOpacity, Image, ViewStyle, ImageStyle } from 'react-native';
import { observer, inject } from 'mobx-react';

import AuthStore from '../../stores/AuthStore';
import AvatarStyles from './styles/AvatarStyles';
import { Images } from '../../themes';
import { Stores } from '../../stores/RootStore';

interface InjectedProps {
    authStore: AuthStore;
}

interface OwnProps {
    iconStyle?: ImageStyle;
    onPress?: () => void;
    size: number;
    style: ViewStyle;
}

type Props = InjectedProps & OwnProps;

const Avatar = ({ authStore, size, onPress, style, iconStyle }: Props) => (
    <TouchableOpacity disabled onPress={onPress}>
        {authStore && authStore.user && authStore.user.first_name && authStore.user.surname ? (
            <View
                style={[
                    AvatarStyles.avatarView,
                    style,
                    { width: size, height: size, borderRadius: size / 2 },
                ]}
            >
                <Text style={[AvatarStyles.avatarText, { fontSize: size / 2 }]}>
                    {authStore.user.first_name.charAt(0)}
                    {authStore.user.surname.charAt(0)}
                </Text>
            </View>
        ) : (
            <Image
                style={[
                    AvatarStyles.avatarImage,
                    { width: size + 10, height: size + 10 },
                    iconStyle,
                ]}
                source={Images.menu.userImage}
                resizeMode="contain"
            />
        )}
    </TouchableOpacity>
);

Avatar.defaultProps = {
    size: 30,
    onPress: () => {},
    style: {},
    iconStyle: {},
};

export default inject(({ authStore }: Stores) => ({ authStore }))(observer(Avatar));

import React from 'react';
import { View, Text, Image, ImageSourcePropType } from 'react-native';
import SavingsCardSmallStyles from './SavingsCardSmallStyles';

interface Props {
    icon: ImageSourcePropType;
    title: string;
    value: string | number;
}

const SavingsCardSmall = ({ icon, title, value }: Props) => (
    <View style={[SavingsCardSmallStyles.savingSectionSmall]}>
        <View style={SavingsCardSmallStyles.container}>
            <Image source={icon} style={SavingsCardSmallStyles.image} resizeMode="contain" />
            <Text style={SavingsCardSmallStyles.textTitle}>{title}</Text>
        </View>
        <View style={SavingsCardSmallStyles.separator} />
        <Text style={SavingsCardSmallStyles.textValue}>{value}</Text>
    </View>
);

export default SavingsCardSmall;

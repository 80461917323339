import React from 'react';
import { View, Text } from 'react-native';

import { observer } from 'mobx-react';
import styles from './styles';

interface Props {
    label: string;
    data: string;
}

const DataInfo = ({ label, data }: Props) => {
    return (
        <View style={styles.container}>
            <Text style={styles.label} numberOfLines={1}>
                {label}
            </Text>
            <Text style={styles.value}>{data}</Text>
        </View>
    );
};

export default observer(DataInfo);

import { Fonts, Metrics, Colors } from '.';

const ApplicationStyles = {
    app: {
        flex: 1,
    },
    dropdownImage: {
        opacity: 0,
        width: 1,
    },
};

export default ApplicationStyles;

const constants = {
    metersToMiles: 0.0006213,
    walkingSpeedMetersPerSecond: 1.4,
    bikingSpeedMetersPerSecond: 4.1,
    drivingSpeedMetersPerSecond: 9,
};

const getMilesFromMeters = (valueMeters: number) => {
    return Number((valueMeters * constants.metersToMiles).toFixed(1));
};

const getMetersFromMiles = (valueMiles: number) => {
    return Number((valueMiles / constants.metersToMiles).toFixed(1));
};
const getTimeWalkingDistanceInMinutes = (valueDistanceInMeters: number) => {
    return Number((valueDistanceInMeters / constants.walkingSpeedMetersPerSecond / 60).toFixed());
};
const getTimeBikingDistanceInMinutes = (valueDistanceInMeters: number) => {
    return Number((valueDistanceInMeters / constants.bikingSpeedMetersPerSecond / 60).toFixed());
};

const getTimeDrivingDistanceInMinutes = (valueDistanceInMeters: number) => {
    return Number((valueDistanceInMeters / constants.drivingSpeedMetersPerSecond / 60).toFixed());
};

export default {
    constants,
    getMilesFromMeters,
    getMetersFromMiles,
    getTimeWalkingDistanceInMinutes,
    getTimeBikingDistanceInMinutes,
    getTimeDrivingDistanceInMinutes,
};

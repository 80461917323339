import { StyleSheet } from 'react-native';
import { Fonts, Metrics, Colors } from '../../../themes';

export default StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: Colors.sugestionsColor,
    },
    rowContainer: {
        flexDirection: 'row',
        paddingHorizontal: 16,
        paddingVertical: 10,
        height: 50,
        backgroundColor: 'white',
        alignItems: 'center',
    },
    header: {
        paddingHorizontal: 8,
        height: 50,
        justifyContent: 'center',
        backgroundColor: Colors.sugestionsColor,
    },
    headerText: {
        fontSize: Metrics.normalize(18),
        fontWeight: '400',
        fontFamily: Fonts.type.base,
        backgroundColor: 'transparent',
        color: 'black',
        marginLeft: 10,
    },
    icon: {
        width: 20,
        height: 20,
    },
    title: {
        fontSize: Metrics.normalize(18),
        fontWeight: '400',
        fontFamily: Fonts.type.base,
        backgroundColor: 'transparent',
        color: 'black',
        marginLeft: 16,
        marginRight: 16,
    },
    searchBarView: {
        height: 50,
        borderBottomWidth: StyleSheet.hairlineWidth,
        borderBottomColor: Colors.grayTextColor,
    },
    searchBarInput: {
        height: 50,
        width: Metrics.screenWidth,
        paddingHorizontal: 10,
        paddingVertical: 5,
    },
    footer: { height: 2 },
});

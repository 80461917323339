import { observable, action, computed, flow } from 'mobx';
import { persist } from 'mobx-persist';

import { Analytics } from 'lib';
import { BaseStore } from 'stores';
import { PersistDataStore } from 'stores/RootStore';
import { RestClient } from 'services';
import { defaultProviders } from 'domain/serviceProviders/TaxiServices';

export default class FiltersStore extends BaseStore implements PersistDataStore {
    hydrateStore = (hydrate: any) => {
        return Promise.all([
            hydrate('seats', this),
            hydrate('excludedTypes', this),
            hydrate('excludedProviders', this),
        ]);
    };

    @action
    toggleFiltersPopup = (show: boolean) => {
        if (show) {
            Analytics.trackScreenView('02-03_Filter');
        }
        this.filtersPopupShown = show;
    };

    @observable
    filtersPopupShown: boolean = false;

    @persist
    @observable
    seats: number = 1;

    @observable
    seatsTemp: number = 1;

    @persist('list')
    @observable
    excludedTypes: string[] = ['WAV'];

    @observable
    excludedTypesTemp: string[] = [];

    @persist('list')
    @observable
    excludedProviders: string[] = [];

    @observable
    excludedProvidersTemp: string[] = [];

    @observable
    regionProviders: string[] = [];

    @computed
    get availableProviders() {
        return defaultProviders.filter(
            provider => this.regionProviders.indexOf(provider.type) !== -1
        );
    }

    getProvidersForRegion = flow(function*(this: FiltersStore, region: Region) {
        try {
            const { data } = yield RestClient.getAvailableProviders(region);
            this.regionProviders = data;
        } catch (error) {
            console.log('getProvidersForRegion error', { ...error });
        }
    }).bind(this);

    @action
    setTempValues = () => {
        this.seatsTemp = this.seats;
        this.excludedProvidersTemp = this.excludedProviders;
        this.excludedTypesTemp = this.excludedTypes;
    };

    @action
    applyFilters = () => {
        const {
            stores: {
                taxiStore: {
                    taxiResultsStore: {
                        taxisComparationStore: { typesTabSelected, selectedTypesTab },
                    },
                },
            },
        } = this.rootStore;
        typesTabSelected(selectedTypesTab);
    };

    @action
    resetChanges = () => {
        this.seats = this.seatsTemp;
        this.excludedProviders = this.excludedProvidersTemp;
        this.excludedTypes = this.excludedTypesTemp;
    };

    @action
    seatsChanged = (value: number) => {
        this.seats = value;
    };

    @computed
    get canDecrease() {
        return this.seats > 1;
    }

    @computed
    get canIncrease() {
        return this.seats < 6;
    }

    @action
    seatsDecreased = () => {
        if (!this.canDecrease) return;
        this.seats -= 1;
    };

    @action
    seatsIncreased = () => {
        if (!this.canIncrease) return;
        this.seats += 1;
    };

    @action
    typeSelected = (item: any) => {
        const i = this.excludedTypes.indexOf(item);
        if (i !== -1) {
            this.excludedTypes.splice(i, 1);
        } else {
            this.excludedTypes.push(item);
        }
    };

    @action
    providerSelectd = (item: any) => {
        const i = this.excludedProviders.indexOf(item.type);
        if (i !== -1) {
            this.excludedProviders.splice(i, 1);
        } else {
            this.excludedProviders.push(item.type);
        }
    };
}

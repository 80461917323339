const Firebase = {
    links: () => {
        return {
            getInitialLink: () => {},
        };
    },
};
const FirebaseAnalytics = { logEvent: () => {}, setCurrentScreen: () => {} };

export default Firebase;
export { FirebaseAnalytics };

import Axios, { AxiosPromise } from 'axios';

import { LatLng } from 'modules/maps/maps';
import getEnv from 'modules/config/config';

import proxy from '../Proxy';
import { generateUUID } from '../Utils';
import {
    GetSuggestionDetailsResponse,
    GoogleGetDirectionResponse,
    GetSuggestionsResponse,
    GetNearbyPlacesResponse,
} from 'domain/api/geolocation';

export type GoogleTravelMode = 'driving' | 'walking' | 'bicycling';

const instance = Axios.create({
    baseURL: 'https://maps.googleapis.com/maps/api',
    timeout: 10000,
});

class GoogleClient {
    apiKey: string;
    sessionId: string;

    constructor() {
        this.apiKey = getEnv('GOOGLE_API_KEY');
        this.sessionId = generateUUID();
        instance.interceptors.request.use(request => {
            console.log('[GoogleClient] Starting Request:', { ...request });
            return proxy(request);
        });
        instance.interceptors.response.use(
            response => {
                console.log('[GoogleClient] Response:', { ...response });
                return response;
            },
            error => {
                console.log('[GoogleClient] Response error:', { ...error });
                return Promise.reject(error);
            }
        );
    }

    getSuggestions = (
        address: string,
        location: LatLng | null,
        radius?: number
    ): AxiosPromise<GetSuggestionsResponse> =>
        instance.get('place/autocomplete/json', {
            params: {
                input: address,
                key: this.apiKey,
                location: !!location ? `${location.latitude},${location.longitude}` : null,
                radius: !!radius ? `${radius}` : '15000',
                sessionToken: this.sessionId,
            },
        });

    getSuggestionDetails = (placeId: number | string): AxiosPromise<GetSuggestionDetailsResponse> =>
        instance.get('place/details/json', {
            params: {
                placeid: placeId,
                key: this.apiKey,
                fields: 'address_component,adr_address,formatted_address,geometry',
                sessionToken: this.sessionId,
            },
        });

    getDirection = (
        pickupLocation: LatLng,
        destinationLocation: LatLng,
        mode: GoogleTravelMode = 'driving'
    ): AxiosPromise<GoogleGetDirectionResponse> =>
        instance.get('directions/json', {
            params: {
                origin: pickupLocation,
                destination: destinationLocation,
                key: this.apiKey,
                departure_time: 'now',
                units: 'metric',
                mode,
            },
        });

    getLocationNameForCoordinates = ({ latitude, longitude }: LatLng): AxiosPromise<any> =>
        instance.get('geocode/json', {
            params: {
                latlng: `${latitude},${longitude}`,
                key: this.apiKey,
            },
        });

    getNearbyPlaces = (
        { latitude, longitude }: LatLng,
        type?: string,
        radius: number = 15000
    ): AxiosPromise<GetNearbyPlacesResponse> =>
        instance.get('place/nearbysearch/json', {
            params: {
                location: `${latitude},${longitude}`,
                type,
                radius: `${radius}`,
                key: this.apiKey,
                sessionToken: this.sessionId,
            },
        });
}

export default new GoogleClient();

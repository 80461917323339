/* eslint-disable camelcase */
import { observable, action, computed, flow } from 'mobx';
import { persist } from 'mobx-persist';

import Alert from 'modules/alert/alert';

import BaseStore from './BaseStore';
import RestClient from '../services/RestClient';
import RootStore, { PersistDataStore, UserLifecycle } from './RootStore';
import { ArroResponse, BoltResponse, ViaResponse } from 'domain/services';
import { Analytics, Localizable } from '../lib';
import { Images } from '../themes';
import { showErrorAlert, showErrorAlertWithText, providerInfo } from '../services/Utils';
import {
    ArroServiceStore,
    BoltServiceStore,
    CurbServiceStore,
    LyftServiceStore,
    UberServiceStore,
    ViaServiceStore,
} from './services';

export interface ServiceType {
    name: string;
    connected: boolean;
    backgroundColor: string;
    backgroundColorLinked: string;
    logo: any;
    logoLinked: any;
    linking?: boolean;
    booking?: boolean;
}

export const linkableServices: Array<ServiceType> = [
    {
        name: 'Arro',
        connected: false,
        backgroundColorLinked: '#E5E5EA',
        backgroundColor: '#4ebed5',
        logo: Images.services.arroDisconnected,
        logoLinked: Images.services.arro,
    },
    {
        name: 'Bolt',
        connected: false,
        backgroundColorLinked: '#E5E5EA',
        backgroundColor: '#37b777',
        logo: Images.services.boltDisconnected,
        logoLinked: Images.services.bolt,
    },
    {
        name: 'Taxify',
        connected: false,
        backgroundColorLinked: '#E5E5EA',
        backgroundColor: '#37b777',
        logo: Images.services.boltDisconnected,
        logoLinked: Images.services.bolt,
    },
    {
        name: 'Curb',
        connected: false,
        backgroundColorLinked: '#E5E5EA',
        backgroundColor: '#1FB484',
        logo: Images.services.curbDisconnected,
        logoLinked: Images.services.curb,
    },
    {
        name: 'Lyft',
        connected: false,
        backgroundColorLinked: '#E5E5EA',
        backgroundColor: '#FE00BF',
        logo: Images.services.lyftDisconnected,
        logoLinked: Images.services.lyft,
    },
    {
        name: 'Uber',
        connected: false,
        backgroundColorLinked: '#E5E5EA',
        backgroundColor: '#1C162E',
        logo: Images.services.uberDisconnected,
        logoLinked: Images.services.uber,
    },
    {
        name: 'Via',
        connected: false,
        backgroundColorLinked: '#E5E5EA',
        backgroundColor: '#3aa5de',
        logo: Images.services.viaDisconnected,
        logoLinked: Images.services.via,
    },
];

export default class ServicesStore extends BaseStore implements PersistDataStore, UserLifecycle {
    arroServiceStore = new ArroServiceStore(this);
    boltServiceStore = new BoltServiceStore(this);
    curbServiceStore = new CurbServiceStore(this);
    lyftServiceStore = new LyftServiceStore(this);
    uberServiceStore = new UberServiceStore(this);
    viaServiceStore = new ViaServiceStore(this);

    @observable
    allServices: Array<ServiceType> = [];

    @observable
    refreshing = false;

    @observable
    providerPopup: string | null = null;

    @observable
    navigateFromOptions?: boolean;

    @persist
    @observable
    shouldShowServicesModal = false;

    @persist
    @observable
    servicesModalCount = 0;

    @observable
    servicesModalVisible = false;

    @observable
    sevicesModalShownThisSession = false;

    @observable
    keepModal: boolean = false;

    hydrateStore = (hydrate: Function) => hydrate('modalShownCount', this);

    onUserReady = () => {
        this.getServices();
    };

    @action
    setRefreshing = (refreshing: boolean) => {
        this.refreshing = refreshing;
    };

    @computed
    get services() {
        const {
            filtersStore: { regionProviders },
        } = this.rootStore.stores;
        return this.allServices.filter(
            service =>
                !!service.linking &&
                (service.connected ||
                    !!regionProviders.find(regionProvider =>
                        service.name.toLowerCase().includes(regionProvider.toLowerCase())
                    ))
        );
    }

    @action
    navigateToItemInfo = () => {
        const {
            stores: {
                navigationStore: { navigate },
            },
        } = this.rootStore;
        this.hideServicesModal();
        setTimeout(() => {
            navigate('ConnectingInfo', { navigateFromOptions: this.navigateFromOptions });
            Analytics.trackServiceLinking(
                !!this.providerPopup ? this.providerPopup.toLowerCase() : 'unknown',
                'connecting',
                'connecting_info_screen'
            );
            Analytics.trackScreenView('04-02_00_linkAcknowledgement');
        }, 0);
    };

    navigateToItem = () => {
        const {
            stores: {
                navigationStore: { navigate },
            },
        } = this.rootStore;
        if (this.providerPopup) {
            switch (this.providerPopup.toLowerCase()) {
                case 'arro':
                    navigate('ProviderConnectingArro');
                    break;
                case 'curb':
                    navigate('ProviderConnectingCurb');
                    break;
                case 'lyft':
                    navigate('LyftConnectingRouter');
                    break;
                case 'uber':
                    navigate('UberConnectingRouter');
                    break;
                case 'via':
                    navigate('ProviderConnectingVia');
                    break;
                case 'taxify':
                    navigate('ProviderConnectingBolt');
                    break;
                default: {
                    if (this.navigateFromOptions) {
                        navigate('MainView');
                        Analytics.trackScreenView('02_Map');
                        break;
                    } else {
                        navigate('ServicesRouter');
                        Analytics.trackScreenView('04-02_Accounts');
                        break;
                    }
                }
            }
        } else if (this.navigateFromOptions) {
            navigate('MainView');
            Analytics.trackScreenView('02_Map');
        } else {
            navigate('ServicesRouter');
            Analytics.trackScreenView('04-02_Accounts');
        }
    };

    @action
    showProviderPopup = (providerName: string, navigateFromOptions: boolean = false) => {
        this.providerPopup = providerName.toLowerCase();
        this.navigateFromOptions = navigateFromOptions;
        if (this.providerPopup === 'lyft') {
            this.lyftServiceStore.initializeLyftClient();
        }
        this.navigateToItemInfo();
    };

    @action
    hideProviderPopup = (connected: boolean = false) => {
        const {
            stores: {
                navigationStore: { navigate, goBack },
                taxiStore,
            },
        } = this.rootStore;
        if (!connected) {
            Analytics.trackServiceLinking(
                !!this.providerPopup ? this.providerPopup.toLowerCase() : 'unknown',
                'connecting',
                'cancel'
            );
        }
        if (this.navigateFromOptions) {
            navigate('MainView');
            Analytics.trackScreenView(`02${taxiStore.taxiState === 'taxis' ? '-03' : ''}_Options`);
        } else {
            navigate('Services');
            Analytics.trackScreenView('04-02_Accounts');
        }
        this.providerPopup = null;
        this.arroServiceStore.clearStore();
        this.boltServiceStore.clearStore();
        this.curbServiceStore.clearStore();
        this.uberServiceStore.clearStore();
        this.lyftServiceStore.clearStore();
        this.viaServiceStore.clearStore();
    };

    @action
    setShouldShowServicesModal = () => {
        this.shouldShowServicesModal = true;
    };

    @action
    toggleServicesModal = () => {
        this.getServices();
        if (
            !this.sevicesModalShownThisSession &&
            this.servicesModalCount < 3 &&
            this.services.find(service => !service.connected)
        ) {
            this.servicesModalCount++;
            this.sevicesModalShownThisSession = true;
            this.servicesModalVisible = true;
        }
    };

    @action
    hideServicesModal = () => {
        this.servicesModalVisible = false;
    };

    @action
    dontShowServicesModalAgain = () => {
        Alert.alert('Are you sure?', 'Pricing will not be exact without linked accounts.', [
            { text: 'No', onPress: () => {} },
            {
                text: 'Yes',
                onPress: () => {
                    this.hideServicesModal();
                    this.servicesModalCount = 4;
                },
            },
        ]);
    };

    revokeProvider = flow(function*(this: ServicesStore, provider: any) {
        try {
            this.refreshing = true;
            yield RestClient.revokeProvider(provider.name.toLowerCase());
            yield this.getServices();
        } catch (error) {
            showErrorAlert(error);
        } finally {
            this.refreshing = false;
        }
    }).bind(this);

    handleCurbLogin = flow(function*(this: ServicesStore, email: string, password: string) {
        try {
            const {
                taxiStore: {
                    taxiResultsStore: { getTaxiTypes },
                    selectedTaxiStore: { getExactPrice },
                },
                authStore: { fetchUser },
                paymentMethodsStore: { getPaymentsForProvider },
            } = this.rootStore.stores;

            this.refreshing = true;
            const { status } = yield RestClient.connectProviderCurb(email, password);
            if (!!status && status === 204) {
                Analytics.trackServiceLinking('curb', 'connecting', 'connected');
                yield this.getServices();
            }
            this.hideProviderPopup(true);
            getPaymentsForProvider('curb');
            getTaxiTypes(true);
            getExactPrice();
            fetchUser();
        } catch (error) {
            console.log('Bellhop CURB login error', { ...error });
            throw error;
        } finally {
            this.refreshing = false;
        }
    }).bind(this);

    handleUberLogin = flow(function*(this: ServicesStore, { userUUID, apiToken }) {
        try {
            const {
                taxiStore: {
                    taxiResultsStore: { getTaxiTypes },
                    selectedTaxiStore: { getExactPrice },
                },
                authStore: { fetchUser },
                paymentMethodsStore: { getPaymentsForProvider },
            } = this.rootStore.stores;
            this.refreshing = true;
            const { status } = yield RestClient.loginUber({ userUUID, apiToken });
            if (!!status && status === 204) {
                Analytics.trackServiceLinking('uber', 'connecting', 'connected');
                yield this.getServices();
            }
            this.hideProviderPopup(true);
            getPaymentsForProvider('uber');
            getTaxiTypes(true);
            getExactPrice();
            fetchUser();
        } catch (error) {
            console.log('Bellhop UBER login error', { ...error });
            throw error;
        } finally {
            this.refreshing = false;
        }
    }).bind(this);

    handleLyftLogin = flow(function*(
        this: ServicesStore,
        { access_token, expires_in, refresh_token, session_id, session }
    ) {
        try {
            const {
                taxiStore: {
                    taxiResultsStore: { getTaxiTypes },
                    selectedTaxiStore: { getExactPrice },
                },
                authStore: { fetchUser },
                paymentMethodsStore: { getPaymentsForProvider },
            } = this.rootStore.stores;
            this.refreshing = true;
            const { status } = yield RestClient.loginLyft({
                access_token,
                expires_in,
                refresh_token,
                session_id,
                session,
            });
            if (!!status && status === 204) {
                Analytics.trackServiceLinking('lyft', 'connecting', 'connected');
                yield this.getServices();
            }
            this.hideProviderPopup(true);
            getPaymentsForProvider('lyft');
            getTaxiTypes(true);
            getExactPrice();
            fetchUser();
        } catch (error) {
            console.log('Bellhop LYFT login error', { ...error });
            throw error;
        } finally {
            this.refreshing = false;
        }
    }).bind(this);

    handleViaLogin = flow(function*(
        this: ServicesStore,
        { auth_token, id, acct_type }: ViaResponse
    ) {
        try {
            const {
                taxiStore: {
                    taxiResultsStore: { getTaxiTypes },
                    selectedTaxiStore: { getExactPrice },
                },
                authStore: { fetchUser },
                paymentMethodsStore: { getPaymentsForProvider },
            } = this.rootStore.stores;
            this.refreshing = true;
            const { status } = yield RestClient.loginVia({
                auth_token,
                id,
                acct_type,
            });
            if (!!status && status === 204) {
                Analytics.trackServiceLinking('via', 'connecting', 'connected');
                yield this.getServices();
            }
            this.hideProviderPopup(true);
            getPaymentsForProvider('via');
            getTaxiTypes(true);
            getExactPrice();
            fetchUser();
        } catch (error) {
            console.log('Bellhop VIA login error\n', { ...error });
            throw error;
        } finally {
            this.refreshing = false;
        }
    }).bind(this);

    handleArroLogin = flow(function*(
        this: ServicesStore,
        { access_token, refresh_token, expires_in, token_type }: ArroResponse
    ) {
        try {
            const {
                taxiStore: {
                    taxiResultsStore: { getTaxiTypes },
                    selectedTaxiStore: { getExactPrice },
                },
                authStore: { fetchUser },
                paymentMethodsStore: { getPaymentsForProvider },
            } = this.rootStore.stores;
            this.refreshing = true;
            const { status } = yield RestClient.loginArro({
                access_token,
                refresh_token,
                expires_in,
                token_type,
            });
            if (!!status && status === 204) {
                Analytics.trackServiceLinking('arro', 'connecting', 'connected');
                yield this.getServices();
            }
            this.hideProviderPopup(true);
            getPaymentsForProvider('arro');
            getTaxiTypes(true);
            getExactPrice();
            fetchUser();
        } catch (error) {
            console.log('Bellhop ARRO login error\n', { ...error });
            throw error;
        } finally {
            this.refreshing = false;
        }
    }).bind(this);

    handleBoltLogin = flow(function*(this: ServicesStore, { phone, phone_uuid }: BoltResponse) {
        try {
            const {
                taxiStore: {
                    taxiResultsStore: { getTaxiTypes },
                    selectedTaxiStore: { getExactPrice },
                },
                authStore: { fetchUser },
                paymentMethodsStore: { getPaymentsForProvider },
            } = this.rootStore.stores;
            this.refreshing = true;
            const { status } = yield RestClient.loginBolt({ phone, phone_uuid });
            if (!!status && status === 204) {
                Analytics.trackServiceLinking('bolt', 'connecting', 'connected');
                yield this.getServices();
            }
            this.hideProviderPopup(true);
            getPaymentsForProvider('taxify');
            getTaxiTypes(true);
            getExactPrice();
            fetchUser();
        } catch (error) {
            console.log('Bellhop BOLT login error\n', { ...error });
            throw error;
        } finally {
            this.refreshing = false;
        }
    }).bind(this);

    getServices = flow(function*(this: ServicesStore) {
        this.refreshing = true;
        try {
            const [{ data: featuresData }, { data: connectedServicesData }] = yield Promise.all([
                RestClient.getProfileFeatures(),
                RestClient.getProviders(),
            ]);
            this.allServices = Object.entries(featuresData)
                .map(([name, properties]) => {
                    const locallyLinkable = linkableServices.find(
                        linkableService => linkableService.name.toLowerCase() === name
                    );
                    if (locallyLinkable) {
                        return {
                            ...locallyLinkable,
                            ...properties,
                            connected: !!connectedServicesData.find(
                                ({ provider }: { provider: string }) =>
                                    provider
                                        .toLowerCase()
                                        .includes(locallyLinkable.name.toLowerCase())
                            ),
                        };
                    }
                })
                .filter(service => !!service);
            this.refreshing = false;
        } catch (error) {
            this.refreshing = false;
            showErrorAlert(error);
        }
    }).bind(this);

    handleOptionsServiceItemClick = (item: ServiceType) => {
        if (item.connected) {
            Alert.alert(
                'Alert',
                `${Localizable.t('services.disconnectAlert.confirmation')} ${
                    providerInfo(item.name).name
                }?`,

                [
                    {
                        text: Localizable.t('no'),
                        onPress: () => console.log('Cancel Pressed'),
                        style: 'cancel',
                    },
                    { text: Localizable.t('yes'), onPress: () => this.revokeProvider(item) },
                ]
            );
            return;
        }
        this.showProviderPopup(item.name, true);
    };
}

import { StyleSheet } from 'react-native';
import { Fonts, Colors, Metrics } from 'themes';

export default StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'row',
        backgroundColor: Colors.darkerGrayBackgroundColor,
        marginHorizontal: 10,
        borderRadius: 10,
    },
    titleView: {
        paddingLeft: 15,
        paddingVertical: 10,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    title: {
        color: Colors.black,
        fontFamily: Fonts.type.base,
        fontWeight: '600',
        fontSize: Metrics.normalize(18),
    },
    infoButton: {
        alignSelf: 'stretch',
        alignItems: 'center',
        justifyContent: 'center',
        paddingHorizontal: 5,
    },
    infoButtonIcon: {
        width: 12,
        height: 12,
    },
    buttonView: {
        flex: 1,
        paddingVertical: 10,
        paddingRight: 15,
        justifyContent: 'center',
        alignItems: 'flex-end',
    },

    button: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    buttonText: {
        color: Colors.black,
        fontFamily: Fonts.type.base,
        fontWeight: '600',
        fontSize: Metrics.normalize(14),
        marginRight: 10,
    },
    buttonImage: {
        height: 15,
        width: 15,
    },
    moreView: {
        backgroundColor: '#ADB4BD',
        borderRadius: 8,
        paddingVertical: 3,
        paddingHorizontal: 8,
        marginRight: 10,
    },
    moreText: {
        color: Colors.darkerGrayBackgroundColor,
        fontFamily: Fonts.type.base,
        fontWeight: '600',
        fontSize: Metrics.normalize(14),
    },
});

import React from 'react';
import { View, TouchableOpacity, Text, Image } from 'react-native';

import { Images } from 'themes';
import AddressPickerStyles from '../suggestions/styles/AddressPickerStyles';

interface Props {
    item: any;
    selectSugestion: (props: any) => void;
}

const SmallMapAddressPickerSuggestion = ({ item, selectSugestion }: Props) => {
    const {
        structured_formatting: { main_text: title, secondary_text: subTitle },
    } = item;

    const icon = Images.mapLanding.recent;

    return (
        <TouchableOpacity onPress={() => selectSugestion(item)} key={title}>
            <View
                key={title}
                style={[
                    AddressPickerStyles.suggestionItemContainer,
                    {
                        backgroundColor: 'transparent',
                    },
                ]}
            >
                <View key={title} style={AddressPickerStyles.suggestionsItemLeftContainer}>
                    <Image key={title} source={icon} style={AddressPickerStyles.suggestionIcon} />
                </View>
                <View style={AddressPickerStyles.textView} key={`${title} sadfgadsfgfds`}>
                    <Text
                        key={title}
                        allowFontScaling={false}
                        style={[AddressPickerStyles.suggestionTitle]}
                    >
                        {title}
                    </Text>
                    {(subTitle && subTitle.length && (
                        <Text
                            key={`${title} subtitle`}
                            allowFontScaling={false}
                            style={[AddressPickerStyles.suggestionDescription]}
                        >
                            {subTitle}
                        </Text>
                    )) ||
                        null}
                </View>
            </View>
        </TouchableOpacity>
    );
};

export default SmallMapAddressPickerSuggestion;

import { StyleSheet, Platform } from 'react-native';
import { Fonts, Metrics, Colors } from '../../../../themes';

export default StyleSheet.create({
    container: {
        paddingLeft: 5,
        paddingBottom: 5,
        paddingRight: 5,
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
    },
    etaView: {
        position: 'absolute',
        top: 4,
        left: 20,
        paddingHorizontal: 10,
        paddingVertical: 5,
        borderRadius: 15,
        backgroundColor: '#37454e',
        shadowColor: 'black',
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.05,
        shadowRadius: 5,
        elevation: 5,
        zIndex: 4,
    },
    etaText: {
        color: '#fff',
        fontSize: Metrics.normalize(9),
        fontFamily: Fonts.type.base,
        fontWeight: '400',
    },
    innerContainer: {
        alignItems: 'center',
        backgroundColor: 'white',
        borderRadius: 10,
        elevation: 7,
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: 5,
        padding: 10,
        shadowColor: 'black',
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.2,
        shadowRadius: 5,
        zIndex: 3,
    },
    title: {
        minWidth: 70,
        maxWidth: 200,
        fontSize: Metrics.normalize(12),
        color: Colors.darkTextColor,
        fontWeight: '700',
        fontFamily: Fonts.type.base,
    },
    image: {
        marginLeft: 10,
        height: Metrics.normalize(10),
    },
});

import React, { Component } from 'react';
import { TextInputMask } from 'react-native-masked-text';
import { View, Text, Image } from 'react-native';
import { observer, inject } from 'mobx-react';

import KeyboardAwareScrollView from 'modules/keyboardAwareScrollView/keyboardAwareScrollView';
import AdditionalInfoStyles from '../../styles/AdditionalInfoStyles';
import ServicesStore from '../../../../stores/ServicesStore';
import TitleNavigationBar from '../../../navigationBars/TitleNavigationBar';
import { Colors, Images } from '../../../../themes';
import { ErrorMessage, NextButton } from '../../providerConnectingComponents';
import { Localizable, Analytics } from '../../../../lib';
import { Stores } from '../../../../stores/RootStore';

interface Props {
    servicesStore: ServicesStore;
}

@inject(({ servicesStore }: Stores) => ({ servicesStore }))
@observer
export default class OTP extends Component<Props, {}> {
    componentDidMount() {
        Analytics.trackScreenView('04-02-01b_linkPhoneVerify');
        Analytics.trackServiceLinking('bolt', 'connecting', 'otp_screen');
    }

    render() {
        const {
            servicesStore: {
                hideProviderPopup,
                boltServiceStore: {
                    onOTPSubmit,
                    onPhoneNumberSubmit,
                    setOTP,
                    errorMessage,
                    OTP,
                    phoneNumber,
                },
            },
        } = this.props;
        return (
            <View style={AdditionalInfoStyles.container}>
                <TitleNavigationBar title="Bolt" handleBackClick={() => hideProviderPopup()} />
                <KeyboardAwareScrollView
                    contentContainerStyle={{ paddingHorizontal: 20 }}
                    keyboardShouldPersistTaps="handled"
                >
                    <Text style={AdditionalInfoStyles.title}>
                        {Localizable.t('additionalInfo.titleValidateNumber')}
                    </Text>
                    <Text style={AdditionalInfoStyles.description}>
                        {Localizable.t('additionalInfo.pinSentTo')}{' '}
                        <Text style={AdditionalInfoStyles.phoneNumber}>{phoneNumber}</Text>
                    </Text>
                    <View style={AdditionalInfoStyles.inputBorder}>
                        <TextInputMask
                            style={[
                                AdditionalInfoStyles.textInput,
                                { fontWeight: OTP.length ? '300' : '100' },
                            ]}
                            keyboardType="numeric"
                            type="custom"
                            options={{
                                mask: '9999',
                                validator(pass) {
                                    return pass.length === 4;
                                },
                            }}
                            placeholder={Localizable.t(
                                'additionalInfo.placeholderVerificationCode4digit'
                            )}
                            placeholderTextColor={Colors.defaultTextColor}
                            value={OTP}
                            onChangeText={setOTP}
                            autoFocus
                            autoCorrect={false}
                            selectionColor={Colors.obiTeal}
                            onSubmitEditing={onOTPSubmit}
                            returnKeyType="done"
                        />
                        {OTP.length === 4 && (
                            <Image
                                source={Images.landing.correct}
                                style={AdditionalInfoStyles.correctInputImage}
                                resizeMode="contain"
                            />
                        )}
                    </View>

                    {errorMessage.length !== 0 && <ErrorMessage message={errorMessage} />}
                    <Text style={AdditionalInfoStyles.resendText} onPress={onPhoneNumberSubmit}>
                        {Localizable.t('additionalInfo.resendPin')}
                    </Text>
                    <NextButton onPress={onOTPSubmit} active={OTP.length === 4} />
                </KeyboardAwareScrollView>
            </View>
        );
    }
}

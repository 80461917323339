import React, { Component } from 'react';
import { Marker } from 'modules/maps/maps';
import { observer } from 'mobx-react';
import { ImageSourcePropType, View } from 'react-native';

interface Props {
    markerProps?: {};
    additionalProps?: any;
    anchor?: {
        x: number;
        y: number;
    };
    key: any;
    zIndex: number;
    onPress?: () => void;
    coordinate: any;
    rotation?: number;
    title?: string;
    name?: string;
    image?: ImageSourcePropType;
}

interface State {
    tracksViewChanges: boolean;
}

@observer
export default class CustomMarker extends Component<Props, State, {}> {
    state = {
        tracksViewChanges: true,
    };

    componentDidUpdate(prevProps: Props, prevState: State) {
        const { tracksViewChanges } = this.state;

        if (this.props !== prevProps) {
            this.setState(() => ({
                tracksViewChanges: true,
            }));
        } else if (tracksViewChanges) {
            setTimeout(() => {
                this.setState({
                    tracksViewChanges: false,
                });
            }, 600);
        }
    }

    render() {
        const { tracksViewChanges } = this.state;
        const { additionalProps, children, ...markerProps } = this.props;
        return (
            <Marker tracksViewChanges={tracksViewChanges} {...markerProps}>
                {children}
            </Marker>
        );
    }
}

import { StyleSheet } from 'react-native';
import { Fonts, Colors, Metrics } from '../../../themes';

export default StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: Colors.typesBackgroundColor,
    },
    rowView: {
        marginHorizontal: 16,
        marginVertical: 8,
        borderRadius: 6,
        shadowColor: 'black',
        shadowRadius: 2,
        shadowOpacity: 0.2,
        shadowOffset: { width: 0, height: 0 },
        elevation: 2,
        padding: 10,
        alignItems: 'center',
        // justifyContent: 'flex-start',
        flexDirection: 'row',
    },
    refreshingView: {
        alignItems: 'center',
        justifyContent: 'center',
        margin: 20,
    },
    brand: {
        marginRight: 20,
        marginLeft: 6,
    },
    serviceText: {
        fontFamily: Fonts.type.base,
        fontWeight: '500',
        fontSize: Metrics.normalize(14),
        color: Colors.serviceNameColor,
        opacity: 0.6,
        marginRight: 8,
    },
    descriptionText: {
        fontFamily: Fonts.type.base,
        fontWeight: '400',
        fontSize: Metrics.normalize(15),
        color: Colors.darkGreyTextColor,
        margin: 16,
    },
    descriptionText2: {
        fontFamily: Fonts.type.base,
        marginTop: 10,
        textAlign: 'center',
        color: Colors.grayTextColor,
        fontSize: Metrics.normalize(12),
        margin: 16,
    },
    descriptionBoldText: {
        fontFamily: Fonts.type.base,
        fontWeight: '700',
        fontSize: Metrics.normalize(15),
        color: Colors.darkGreyTextColor,
        margin: 16,
        marginTop: 0,
    },
    arrow: {
        height: 30,
        marginRight: 6,
        width: 10,
        opacity: 0.6,
    },
    check: {
        height: 30,
        marginRight: 6,
        width: 20,
        opacity: 0.7,
    },
});

import React from 'react';
import { observer, inject } from 'mobx-react';
import { Platform, Image } from 'react-native';
import { Marker } from 'modules/maps/maps';

import CustomMarker from '../../../taxis/Marker';
import TaxiStore from '../../../../stores/TaxiStore';
import styles from '../bikes/styles';
import { Images } from '../../../../themes';
import { Localizable } from '../../../../lib';
import { Stores } from '../../../../stores/RootStore';

interface Props {
    taxiStore: TaxiStore;
}

const DriverMarker = ({ taxiStore }: Props) => {
    const { taxiRouteStore } = taxiStore;
    if (!taxiRouteStore.showDriverMarker) {
        return null;
    }
    const commonProps = {
        key: 'driverKey',
        zIndex: 10,
        title: Localizable.t('taxi.driver'),
        coordinate: taxiStore.currentRideStatus.location,
        rotation: taxiStore.currentRideStatus.location.bearing || 0,
    };

    return Platform.select({
        android: <Marker {...commonProps} image={Images.icons.driver} />,
        default: (
            <CustomMarker {...commonProps}>
                <Image source={Images.icons.driver} style={styles.driverMarker} />
            </CustomMarker>
        ),
    });
};

export default inject(({ taxiStore }: Stores) => ({ taxiStore }))(observer(DriverMarker));

/* eslint-disable camelcase */
import React from 'react';
import { View, TouchableOpacity, Text, Image } from 'react-native';
import Animatable from 'modules/animatable/animatable';
import { observer, inject } from 'mobx-react';
import GradientButton from '../button/GradientButton';
import PaymentMethodsPopupStyles from './styles/PaymentMethodsPopupStyles';
import PaymentMethodsStore from '../../stores/PaymentMethodsStore';
import { Images } from '../../themes';
import { Stores } from '../../stores/RootStore';
import { creditCardBrandIcon } from '../../services/Utils';
import { Localizable } from '../../lib';
import AddressStore from '../../stores/AddressStore';

interface Props {
    paymentMethodsStore: PaymentMethodsStore;
    addressStore: AddressStore;
}

export default inject(({ paymentMethodsStore, addressStore }: Stores) => ({
    paymentMethodsStore,
    addressStore,
}))(
    observer(
        ({
            paymentMethodsStore: {
                paymentsPopupShown,
                hidePaymentsPopup,
                paymentMethods,
                lastUsedPaymentId,
                selectCard,
                selectedPaymentMethodId,
            },
        }: Props) => {
            const onConfirmClick = () => {
                if (selectedPaymentMethodId === '' && lastUsedPaymentId !== '') {
                    selectCard(lastUsedPaymentId);
                }
                // getTaxiVenues();
                hidePaymentsPopup();
            };
            return paymentsPopupShown ? (
                <Animatable.View
                    animation="fadeIn"
                    duration={300}
                    style={PaymentMethodsPopupStyles.container}
                    useNativeDriver
                >
                    <View style={PaymentMethodsPopupStyles.innerContainer}>
                        <View style={PaymentMethodsPopupStyles.topView}>
                            <Text style={PaymentMethodsPopupStyles.titleText}>
                                {Localizable.t('taxis.paymentMethodPopup.paymentMethod')}
                            </Text>
                            <TouchableOpacity onPress={hidePaymentsPopup}>
                                <View style={PaymentMethodsPopupStyles.closeView}>
                                    <Image
                                        style={PaymentMethodsPopupStyles.closeButton}
                                        resizeMode="contain"
                                        source={Images.settings.close}
                                    />
                                </View>
                            </TouchableOpacity>
                        </View>
                        <View style={PaymentMethodsPopupStyles.separator} />
                        {paymentMethods.map(({ type, payment_method_id, description }, i) => {
                            const brand = type.toLowerCase();
                            const card = creditCardBrandIcon(brand);
                            return (
                                <TouchableOpacity
                                    key={payment_method_id}
                                    onPress={() => selectCard(payment_method_id)}
                                >
                                    <View
                                        style={[
                                            PaymentMethodsPopupStyles.paymentView,
                                            (selectedPaymentMethodId === payment_method_id ||
                                                (selectedPaymentMethodId === '' &&
                                                    lastUsedPaymentId === payment_method_id)) &&
                                                PaymentMethodsPopupStyles.activePaymentView,
                                        ]}
                                    >
                                        {card && (
                                            <Image
                                                source={card}
                                                style={PaymentMethodsPopupStyles.paymentCardImage}
                                                resizeMode="contain"
                                            />
                                        )}
                                        <View style={PaymentMethodsPopupStyles.empty} />
                                        <Text style={PaymentMethodsPopupStyles.paymentCardText}>
                                            *{description}
                                        </Text>
                                    </View>
                                </TouchableOpacity>
                            );
                        })}
                        <GradientButton
                            style={PaymentMethodsPopupStyles.confirmButton}
                            title={Localizable.t('taxis.paymentMethodPopup.confirm')}
                            onPress={onConfirmClick}
                            active={!!selectedPaymentMethodId || !!lastUsedPaymentId}
                        />
                    </View>
                </Animatable.View>
            ) : null;
        }
    )
);

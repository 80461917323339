import { StyleSheet } from 'react-native';
import { Metrics } from '../../../../themes';

export default StyleSheet.create({
    container: {
        marginLeft: 10,
    },
    transparent: {
        opacity: 0,
    },
});

import React from 'react';
import { View, Image, TouchableOpacity, StyleProp, ViewStyle } from 'react-native';

import { Images, Metrics } from '../../themes';
import EmptyNavigationBarStyles from './styles/EmptyNavigationBarStyles';

interface Props {
    handleBackClick: () => void;
    style?: StyleProp<ViewStyle>;
}

export default ({ handleBackClick, style }: Props) => (
    <View style={EmptyNavigationBarStyles.container}>
        <TouchableOpacity
            hitSlop={{ bottom: 10, top: 10, left: 10, right: 10 }}
            onPress={handleBackClick}
            style={[EmptyNavigationBarStyles.button, style]}
        >
            <Image
                source={Images.icons.back}
                resizeMode="contain"
                style={EmptyNavigationBarStyles.image}
            />
        </TouchableOpacity>
    </View>
);

import React from 'react';
import MapView from 'modules/maps/maps';
import { Platform, Image } from 'react-native';
import { observer } from 'mobx-react';

import Location from 'domain/app/Location';
import { Metrics, Images } from '../../../../../themes';
import styles from '../styles';

interface Props {
    coords: Location;
}

export default observer(({ coords: { latitude, longitude } }: Props) => {
    const commonProps = {
        anchor: { x: 0.5, y: 0.5 },
        coordinate: { latitude, longitude },
        // zIndex: index,
        // rotation: marker.bearing || 0,
    };
    return Platform.OS === 'ios' ? (
        <MapView.Marker {...commonProps}>
            <Image source={Images.bikes.route.biking} style={styles.bikeMarkerRideImage} />
        </MapView.Marker>
    ) : (
        <MapView.Marker {...commonProps} image={Images.bikes.route.biking} />
    );
});

import React from 'react';
import { observer, inject } from 'mobx-react';

import { TaxiStore } from 'stores';
import { Stores } from 'stores/RootStore';

import CustomMarker from '../../../taxis/Marker';
import { RidePopup } from '../../../taxis';

interface Props {
    taxiStore: TaxiStore;
}

export default inject(({ taxiStore }: Stores) => ({ taxiStore }))(
    observer(({ taxiStore }: Props) => {
        const {
            taxiResultsStore: { getTaxiTypes },
            taxiRouteStore,
            taxiRideStore,
        } = taxiStore;
        if (!taxiRouteStore.showPickupMarker) {
            return null;
        }
        return (
            <CustomMarker
                anchor={{ x: 0, y: 1 }}
                key="pickup"
                zIndex={11}
                coordinate={taxiRideStore.currentRide.pickup}
                onPress={() => (taxiStore.taxiState === 'start' ? getTaxiTypes(false) : {})}
            >
                <RidePopup
                    title={taxiRouteStore.pickupMarkerDescription}
                    duration={taxiStore.currentEta}
                />
            </CustomMarker>
        );
    })
);

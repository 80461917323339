import { observable, flow } from 'mobx';

import RestClient from '../services/RestClient';
import Ride from 'domain/app/Ride';
import { showErrorAlert } from '../services/Utils';

export default class RideHistoryStore {
    @observable
    rides: Array<Ride> = [];

    @observable
    refreshing: boolean = false;

    getRides = flow(function*(this: RideHistoryStore) {
        try {
            this.refreshing = true;
            const { data } = yield RestClient.getRequests();
            this.rides = data.filter(
                ({ pickup, destination, ask, status, fees }: Ride) =>
                    pickup &&
                    destination &&
                    ask &&
                    ask.on_demand &&
                    (status === 'COMPLETED' || status === 'CANCELED') &&
                    fees !== 0
            );
        } catch (error) {
            showErrorAlert(error);
        } finally {
            this.refreshing = false;
        }
    }).bind(this);
}

/* eslint-disable eqeqeq */
/* eslint-disable no-bitwise */
import axios from 'axios';
import moment from 'moment';
import * as RNLocalize from 'react-native-localize';
import { Platform } from 'react-native';
import { CountryCode } from 'libphonenumber-js';

import DeviceInfo from 'modules/deviceInfo/deviceInfo';
import proxy from '../Proxy';
import {
    UberCredentials,
    UberPhoneCredentials,
    UberOTPCredentials,
    UberTOTPCredentials,
    UberPasswordCredentials,
    UberEmailCredentials,
} from 'domain/services/Uber/UberCredentials';

const instance = axios.create({
    baseURL: `https://cn-geo1.uber.com/rt/silk-screen/submit-form`,
    timeout: 40000,
});

function getInitRequest(number: string, countryCode: CountryCode) {
    return {
        formContainerAnswer: {
            formAnswer: {
                flowType: 'INITIAL',
                screenAnswers: [
                    {
                        screenType: 'PHONE_NUMBER_INITIAL',
                        fieldAnswers: [
                            { fieldType: 'PHONE_COUNTRY_CODE', phoneCountryCode: `${countryCode}` },
                            { fieldType: 'PHONE_NUMBER', phoneNumber: `${number}` },
                            { fieldType: 'THIRD_PARTY_CLIENT_ID', thirdPartyClientID: '' },
                            { fieldType: 'MOBILE_VERIFICATION_REQUEST_ID' },
                        ],
                    },
                ],
                // deviceData:
                //     '{"androidId":"934e63544bf93f13","version":"4.254.10002","batteryStatus":"charging","carrier":"","carrierMcc":"260","carrierMnc":"02","cpuAbi":"armeabi, armeabi-v7a","deviceIds":{"authId":"READ_PHONE_STATE_DISABLED","permId":"810cce61934461888d1998c85100a4d9","googleAdvertisingId":"9bd13691-75e0-4c44-9822-d8e4cf2a21d5","deviceImei":"354903084157343","udid":"b3c70de1-adc8-436d-b609-6ccdd1da8ac4","perfId":"A160FF0F-2492-2955-3A7D-3F31B0F494CF"},"ipAddress":"192.168.0.148","deviceModel":"SM-J320FN","deviceOsName":"Android","deviceOsVersion":"5.1.1","batteryLevel":1.0,"deviceAltitude":0.0,"deviceLongitude":0.0,"deviceLatitude":0.0,"locationServiceEnabled":false,"mockGpsOn":true,"emulator":false,"rooted":false,"course":0.0,"speed":0.0,"unknownSources":true,"horizontalAccuracy":0.0,"wifiConnected":true,"verticalAccuracy":0.0,"libCount":0,"systemTimeZone":"Europe/Warsaw","versionChecksum":"93cb8d480bf5621c001cb647837d87f8","sourceApp":"rider","epoch":1.553791791245E12,"specVersion":"2.0"}',
                productConstraints: { autoSMSVerificationSupported: false },
                firstPartyClientID: 'zozycDbnl17oSjKXdw_x_QuNvq5wfRHq',
            },
        },
    };
}

function getOTPRequest(otp: string, sessionId: string) {
    return {
        formContainerAnswer: {
            inAuthSessionID: `${sessionId}`,
            formAnswer: {
                flowType: 'INITIAL',
                screenAnswers: [
                    {
                        screenType: 'PHONE_OTP',
                        fieldAnswers: [{ fieldType: 'PHONE_SMS_OTP', phoneSMSOTP: otp }],
                    },
                ],
                // deviceData:
                //     '{"androidId":"934e63544bf93f13","version":"4.254.10002","batteryStatus":"charging","carrier":"","carrierMcc":"260","carrierMnc":"02","cpuAbi":"armeabi, armeabi-v7a","deviceIds":{"authId":"READ_PHONE_STATE_DISABLED","permId":"810cce61934461888d1998c85100a4d9","googleAdvertisingId":"9bd13691-75e0-4c44-9822-d8e4cf2a21d5","deviceImei":"354903084157343","udid":"b3c70de1-adc8-436d-b609-6ccdd1da8ac4","perfId":"A160FF0F-2492-2955-3A7D-3F31B0F494CF"},"ipAddress":"192.168.0.148","deviceModel":"SM-J320FN","deviceOsName":"Android","deviceOsVersion":"5.1.1","batteryLevel":1.0,"deviceAltitude":0.0,"deviceLongitude":0.0,"deviceLatitude":0.0,"locationServiceEnabled":false,"mockGpsOn":true,"emulator":false,"rooted":false,"course":0.0,"speed":0.0,"unknownSources":true,"horizontalAccuracy":0.0,"wifiConnected":true,"verticalAccuracy":0.0,"libCount":0,"systemTimeZone":"Europe/Warsaw","versionChecksum":"93cb8d480bf5621c001cb647837d87f8","sourceApp":"rider","epoch":1.553791791245E12,"specVersion":"2.0"}',
                productConstraints: { autoSMSVerificationSupported: false },
                firstPartyClientID: 'zozycDbnl17oSjKXdw_x_QuNvq5wfRHq',
            },
        },
    };
}

function getTOTPRequest(totp: string, sessionId: string) {
    return {
        formContainerAnswer: {
            inAuthSessionID: sessionId,
            formAnswer: {
                firstPartyClientID: 'zozycDbnl17oSjKXdw_x_QuNvq5wfRHq',
                flowType: 'SIGN_IN',
                productConstraints: { autoSMSVerificationSupported: false },
                screenAnswers: [
                    {
                        screenType: 'TOTP_VERIFICATION',
                        fieldAnswers: [{ fieldType: 'TOTP', totpAnswer: totp }],
                    },
                ],
                // deviceData:
                //     '{"androidId":"934e63544bf93f13","version":"4.254.10002","batteryStatus":"charging","carrier":"","carrierMcc":"260","carrierMnc":"02","cpuAbi":"armeabi, armeabi-v7a","deviceIds":{"authId":"READ_PHONE_STATE_DISABLED","permId":"810cce61934461888d1998c85100a4d9","googleAdvertisingId":"9bd13691-75e0-4c44-9822-d8e4cf2a21d5","deviceImei":"354903084157343","udid":"b3c70de1-adc8-436d-b609-6ccdd1da8ac4","perfId":"A160FF0F-2492-2955-3A7D-3F31B0F494CF"},"ipAddress":"192.168.0.148","deviceModel":"SM-J320FN","deviceOsName":"Android","deviceOsVersion":"5.1.1","batteryLevel":1.0,"deviceAltitude":0.0,"deviceLongitude":0.0,"deviceLatitude":0.0,"locationServiceEnabled":false,"mockGpsOn":true,"emulator":false,"rooted":false,"course":0.0,"speed":0.0,"unknownSources":true,"horizontalAccuracy":0.0,"wifiConnected":true,"verticalAccuracy":0.0,"libCount":0,"systemTimeZone":"Europe/Warsaw","versionChecksum":"93cb8d480bf5621c001cb647837d87f8","sourceApp":"rider","epoch":1.553791791245E12,"specVersion":"2.0"}',
            },
        },
    };
}

function getPasswordRequest(password: string, sessionId: string) {
    return {
        formContainerAnswer: {
            inAuthSessionID: sessionId,
            formAnswer: {
                // deviceData:
                //     '{"cpuAbi":"armeabi, armeabi-v7a","horizontalAccuracy":0,"emulator":false,"carrierMnc":"","verticalAccuracy":0,"course":0,"batteryLevel":1.0,"deviceLatitude":0.0,"carrier":"","epoch":1554992948735,"deviceModel":"sdk_google_phone_armv7","libCount":0,"deviceIds":{"authId":"READ_PHONE_STATE_DISABLED","deviceImei":"7136132141030221387","googleAdvertisingId":"21f29fd2-fbf3-4954-b9fd-76d8b3bf7bf4","perfId":"46DFC790-0FF7-4E55-9A28-E0D1079BE3CD","permId":"1e27cf7c90ec482492a140ecd7a1130d","udid":"bc2ffdb0-1e75-4d21-871b-d39a6fb087e6"},"carrierMcc":"","locationServiceEnabled":false,"sourceApp":"rider","version":"4.254.10002","unknownSources":false,"deviceLongitude":0.0,"ipAddress":"192.168.0.445","rooted":false,"deviceOsVersion":"7.1.1","androidId":"eba6396cf9f46fcd","specVersion":"2.0","mockGpsOn":false,"wifiConnected":false,"batteryStatus":"charging","versionChecksum":"3ebbae724d9c772117039c3138bfcfbc","deviceAltitude":0,"deviceOsName":"Android","speed":0,"systemTimeZone":"America/New_York"}',
                firstPartyClientID: 'zozycDbnl17oSjKXdw_x_QuNvq5wfRHq',
                flowType: 'SIGN_IN',
                productConstraints: { autoSMSVerificationSupported: false },
                screenAnswers: [
                    {
                        screenType: 'PASSWORD',
                        fieldAnswers: [{ fieldType: 'PASSWORD', password }],
                    },
                ],
            },
        },
    };
}
function getCaptchRequest(captchToken: string, sessionId: string) {
    return {
        formContainerAnswer: {
            formAnswer: {
                // deviceData:
                //     '{"cpuAbi":"armeabi, armeabi-v7a","horizontalAccuracy":0,"emulator":false,"carrierMnc":"","verticalAccuracy":0,"course":0,"batteryLevel":1.0,"deviceLatitude":0.0,"carrier":"","epoch":1554992948876,"deviceModel":"sdk_google_phone_armv7","libCount":0,"deviceIds":{"authId":"READ_PHONE_STATE_DISABLED","deviceImei":"1795505007410133545","googleAdvertisingId":"8a688263-0067-4317-b5cf-a9f5ecbc257f","perfId":"CB3F6900-D655-4822-B493-9F29B563EC2B","permId":"9e3b5da8dab54cda8f7d4eed9ad58275","udid":"bda21990-26a1-4bcd-869f-f55e1aea4093"},"carrierMcc":"","locationServiceEnabled":false,"sourceApp":"rider","version":"4.254.10002","unknownSources":false,"deviceLongitude":0.0,"ipAddress":"192.168.0.162","rooted":false,"deviceOsVersion":"7.1.1","androidId":"57493f33e1cb6318","specVersion":"2.0","mockGpsOn":false,"wifiConnected":false,"batteryStatus":"charging","versionChecksum":"3ebbae724d9c772117039c3138bfcfbc","deviceAltitude":0,"deviceOsName":"Android","speed":0,"systemTimeZone":"America/New_York"}',
                firstPartyClientID: 'zozycDbnl17oSjKXdw_x_QuNvq5wfRHq',
                flowType: 'INITIAL',
                productConstraints: { autoSMSVerificationSupported: false },
                screenAnswers: [
                    {
                        fieldAnswers: [
                            {
                                captchaToken: captchToken,
                                fieldType: 'CAPTCHA_TOKEN',
                            },
                        ],
                        screenType: 'CAPTCHA',
                    },
                ],
            },
            inAuthSessionID: sessionId,
        },
    };
}
function getPasswordRevoveryRequest(email: string, sessionId: string) {
    return {
        formContainerAnswer: {
            formAnswer: {
                // deviceData:
                //     '{"cpuAbi":"armeabi, armeabi-v7a","horizontalAccuracy":0,"emulator":false,"carrierMnc":"","verticalAccuracy":0,"course":0,"batteryLevel":1.0,"deviceLatitude":0.0,"carrier":"","epoch":1554992948876,"deviceModel":"sdk_google_phone_armv7","libCount":0,"deviceIds":{"authId":"READ_PHONE_STATE_DISABLED","deviceImei":"1795505007410133545","googleAdvertisingId":"8a688263-0067-4317-b5cf-a9f5ecbc257f","perfId":"CB3F6900-D655-4822-B493-9F29B563EC2B","permId":"9e3b5da8dab54cda8f7d4eed9ad58275","udid":"bda21990-26a1-4bcd-869f-f55e1aea4093"},"carrierMcc":"","locationServiceEnabled":false,"sourceApp":"rider","version":"4.254.10002","unknownSources":false,"deviceLongitude":0.0,"ipAddress":"192.168.0.162","rooted":false,"deviceOsVersion":"7.1.1","androidId":"57493f33e1cb6318","specVersion":"2.0","mockGpsOn":false,"wifiConnected":false,"batteryStatus":"charging","versionChecksum":"3ebbae724d9c772117039c3138bfcfbc","deviceAltitude":0,"deviceOsName":"Android","speed":0,"systemTimeZone":"America/New_York"}',
                firstPartyClientID: 'zozycDbnl17oSjKXdw_x_QuNvq5wfRHq',
                flowType: 'ACCOUNT_RECOVERY',
                productConstraints: { autoSMSVerificationSupported: false },
                screenAnswers: [
                    {
                        fieldAnswers: [
                            {
                                emailAddress: email,
                                fieldType: 'EMAIL_ADDRESS',
                            },
                        ],
                        screenType: 'EMAIL',
                    },
                ],
            },
            inAuthSessionID: sessionId,
        },
    };
}
function getEmailRequest(email: string, sessionId: string) {
    return {
        formContainerAnswer: {
            formAnswer: {
                // deviceData:
                //     '{"cpuAbi":"armeabi, armeabi-v7a","horizontalAccuracy":0,"emulator":false,"carrierMnc":"","verticalAccuracy":0,"course":0,"batteryLevel":1.0,"deviceLatitude":0.0,"carrier":"","epoch":1554992948735,"deviceModel":"sdk_google_phone_armv7","libCount":0,"deviceIds":{"authId":"READ_PHONE_STATE_DISABLED","deviceImei":"7136132141030221387","googleAdvertisingId":"21f29fd2-fbf3-4954-b9fd-76d8b3bf7bf4","perfId":"46DFC790-0FF7-4E55-9A28-E0D1079BE3CD","permId":"1e27cf7c90ec482492a140ecd7a1130d","udid":"bc2ffdb0-1e75-4d21-871b-d39a6fb087e6"},"carrierMcc":"","locationServiceEnabled":false,"sourceApp":"rider","version":"4.254.10002","unknownSources":false,"deviceLongitude":0.0,"ipAddress":"192.168.0.445","rooted":false,"deviceOsVersion":"7.1.1","androidId":"eba6396cf9f46fcd","specVersion":"2.0","mockGpsOn":false,"wifiConnected":false,"batteryStatus":"charging","versionChecksum":"3ebbae724d9c772117039c3138bfcfbc","deviceAltitude":0,"deviceOsName":"Android","speed":0,"systemTimeZone":"America/New_York"}',
                firstPartyClientID: 'zozycDbnl17oSjKXdw_x_QuNvq5wfRHq',
                flowType: 'SIGN_UP',
                productConstraints: { autoSMSVerificationSupported: false },
                screenAnswers: [
                    {
                        screenType: 'EMAIL',
                        fieldAnswers: [
                            {
                                fieldType: 'EMAIL_ADDRESS',
                                emailAddress: email,
                            },
                        ],
                    },
                ],
            },
            inAuthSessionID: sessionId,
        },
    };
}
function getCredentialsRequest(
    password: string,
    firstName: string,
    lastName: string,
    sessionId: string
) {
    return {
        formContainerAnswer: {
            formAnswer: {
                // deviceData:
                //     '{"cpuAbi":"armeabi, armeabi-v7a","horizontalAccuracy":0,"emulator":false,"carrierMnc":"","verticalAccuracy":0,"course":0,"batteryLevel":1.0,"deviceLatitude":0.0,"carrier":"","epoch":1554992948735,"deviceModel":"sdk_google_phone_armv7","libCount":0,"deviceIds":{"authId":"READ_PHONE_STATE_DISABLED","deviceImei":"7136132141030221387","googleAdvertisingId":"21f29fd2-fbf3-4954-b9fd-76d8b3bf7bf4","perfId":"46DFC790-0FF7-4E55-9A28-E0D1079BE3CD","permId":"1e27cf7c90ec482492a140ecd7a1130d","udid":"bc2ffdb0-1e75-4d21-871b-d39a6fb087e6"},"carrierMcc":"","locationServiceEnabled":false,"sourceApp":"rider","version":"4.254.10002","unknownSources":false,"deviceLongitude":0.0,"ipAddress":"192.168.0.445","rooted":false,"deviceOsVersion":"7.1.1","androidId":"eba6396cf9f46fcd","specVersion":"2.0","mockGpsOn":false,"wifiConnected":false,"batteryStatus":"charging","versionChecksum":"3ebbae724d9c772117039c3138bfcfbc","deviceAltitude":0,"deviceOsName":"Android","speed":0,"systemTimeZone":"America/New_York"}',
                firstPartyClientID: 'zozycDbnl17oSjKXdw_x_QuNvq5wfRHq',
                flowType: 'SIGN_UP',
                productConstraints: { autoSMSVerificationSupported: false },
                screenAnswers: [
                    {
                        screenType: 'PASSWORD',
                        fieldAnswers: [
                            {
                                fieldType: 'PASSWORD',
                                password,
                            },
                        ],
                    },
                    {
                        screenType: 'FULL_NAME',
                        fieldAnswers: [
                            {
                                fieldType: 'FIRST_NAME',
                                firstName,
                            },
                            {
                                fieldType: 'LAST_NAME',
                                lastName,
                            },
                        ],
                    },
                    {
                        screenType: 'LEGAL',
                        fieldAnswers: [
                            {
                                fieldType: 'LEGAL_CONFIRMATION',
                                legalConfirmation: true,
                            },
                        ],
                    },
                ],
            },
            inAuthSessionID: sessionId,
        },
    };
}
function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        const r = (Math.random() * 16) | 0;
        const v = c == 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
}

class UberClient {
    constructor() {
        instance.interceptors.request.use(request => {
            console.log('[UberClient] Starting Request:', request);
            return proxy(request);
        });
        instance.interceptors.response.use(
            response => {
                console.log('[UberClient] Response:', response);
                return response;
            },
            error => {
                console.log('[UberClient] Response error:', { ...error });
                return Promise.reject(error);
            }
        );
        this.sessionUUID = uuidv4();
    }

    sessionUUID: string;

    getHeaders = () => {
        const platformRelatedHeaders =
            Platform.OS === 'android'
                ? {
                      'x-uber-device-sdk': DeviceInfo.getApiLevel(), // android sdk
                      'x-uber-device-serial': DeviceInfo.getSerialNumber(), // UUID
                  }
                : {
                      'x-uber-device-ids': `aaaid:${DeviceInfo.getUniqueId()}`,
                  };

        return {
            'X-Uber-RedirectCount': 0,
            'X-Uber-DCURL': 'https://cn-geo1.uber.com/',
            'x-uber-client-name': 'client',
            'x-uber-client-id': Platform.OS === 'ios' ? 'com.ubercab.UberClient' : 'com.ubercab',
            'x-uber-device-os': DeviceInfo.getSystemVersion(), // android version
            'x-uber-client-session': this.sessionUUID, // unique per session
            'x-uber-device': Platform.OS === 'ios' ? 'iphone' : 'android',
            'x-uber-device-epoch': moment().unix(), // current timestamp
            'x-uber-device-id':
                Platform.OS === 'ios'
                    ? DeviceInfo.getUniqueId()
                    : DeviceInfo.getUniqueId().replace(/-/g, ''), // UUID.randomUUID.toString.replace("-", "")
            'x-uber-device-mobile-iso2': RNLocalize.getCountry(),
            'x-uber-device-model': DeviceInfo.getDeviceId(), // device model
            'x-uber-request-uuid': uuidv4(), // UUID differrent with every request
            'x-uber-device-language': RNLocalize.getLocales()[0].languageCode,
            'x-uber-client-user-session-id': this.sessionUUID, // UUID
            'x-uber-client-version': '4.288.10000',
            'Content-Type': 'application/json; charset=UTF-8',
            'User-Agent':
                Platform.OS === 'ios' ? 'client/iphone/4.254.10002' : 'okhttp/3.12.0-uber2',
            ...platformRelatedHeaders,
        };
    };

    initializeUberConnection = ({ phoneNumber, countryCode }: UberPhoneCredentials) => {
        return instance.post('', getInitRequest(phoneNumber, countryCode), {
            headers: this.getHeaders(),
        });
    };

    sendOTP = ({ otp, sessionId }: UberOTPCredentials) => {
        return instance.post('', getOTPRequest(otp, sessionId), {
            headers: this.getHeaders(),
        });
    };

    sendTOTP = ({ totp, sessionId }: UberTOTPCredentials) => {
        return instance.post('', getTOTPRequest(totp, sessionId), {
            headers: this.getHeaders(),
        });
    };

    sendPassword = ({ password, sessionId }: UberPasswordCredentials) => {
        return instance.post('', getPasswordRequest(password, sessionId), {
            headers: this.getHeaders(),
        });
    };

    sendPasswordRecovery = ({ email, sessionId }: UberEmailCredentials) => {
        return instance.post('', getPasswordRevoveryRequest(email, sessionId), {
            headers: this.getHeaders(),
        });
    };

    sendEmail = ({ email, sessionId }: UberEmailCredentials) => {
        return instance.post('', getEmailRequest(email, sessionId), {
            headers: this.getHeaders(),
        });
    };

    sendCredentials = ({ password, firstName, lastName, sessionId }: UberCredentials) => {
        return instance.post('', getCredentialsRequest(password, firstName, lastName, sessionId), {
            headers: this.getHeaders(),
        });
    };
}

export default new UberClient();

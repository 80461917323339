import React, { Component } from 'react';
import MapView, { PROVIDER_GOOGLE, Marker } from 'modules/maps/maps';
import { View, StyleSheet, Platform } from 'react-native';
import { observer, inject } from 'mobx-react';

import { BikeStore, MapStore, AddressStore, TaxiStore } from 'stores';
import { Stores } from 'stores/RootStore';

import {
    BikeMarkers,
    DestinationLocationMarker,
    PickupLocationMarker,
    DriverMarker,
    PickupMarker,
    DestinationMarker,
    PlainMarkers,
} from '../marker';
import BikeRouteMarkers from '../marker/bikes/routeMarkers/BikeRouteMarkers';
import MapStyle from './mapJsonStyle/MapStyle.json';
import PolyLineRoute from '../polylineRoute/PolyLineRoute';
import PolyLineRouteBikes from '../polylineRoute/PolyLineRouteBikes';

interface Props {
    bikeStore: BikeStore;
    bottomPadding: number;
    taxiStore: TaxiStore;
    topPadding: number;
    mapStore: MapStore;
    addressStore: AddressStore;
}

interface State {
    initialState: boolean;
    mapHack: boolean;
}

@inject(({ mapStore, taxiStore, bikeStore, addressStore }: Stores) => ({
    mapStore,
    taxiStore,
    bikeStore,
    addressStore,
}))
@observer
export default class Map extends Component<Props, State> {
    state = {
        initialState: true,
        mapHack: false,
    };

    onMapReady: (() => void) | undefined = () => {
        const {
            taxiStore: { getUserLocation },
        } = this.props;
        getUserLocation(true, false, 'Map Component');
    };

    onMapLayout = () => {
        const { initialState } = this.state;
        if (initialState) {
            this.setState({ initialState: false }, () => {
                setTimeout(() => {
                    this.setState(({ mapHack }) => ({
                        mapHack: !mapHack,
                    }));
                }, 300);
            });
        }
    };

    componentDidUpdate(prevProps: Props) {
        const { bottomPadding, topPadding } = this.props;
        const { bottomPadding: bottomPaddingPrev, topPadding: topPaddingPrev } = prevProps;

        if (bottomPadding !== bottomPaddingPrev || topPadding !== topPaddingPrev) {
            this.setState(({ mapHack }) => ({ mapHack: !mapHack }));
        }
    }

    render() {
        const { mapHack } = this.state;
        const { taxiStore, bikeStore, mapStore, addressStore } = this.props;
        const {
            taxiRouteStore: {
                markersOrderLat,
                markersOrderLng,
                driverMarkerOrderDestination,
                driverMarkerOrderPickup,
            },
            userPanMap,
        } = taxiStore;
        const {
            onRegionChange,
            onRegionChangeComplete,
            onUserLocationChange,
            mapInstance,
            lastRegion,
        } = mapStore;

        return (
            <View
                onMoveShouldSetResponder={userPanMap}
                style={[StyleSheet.absoluteFill, { marginTop: -!!mapHack, paddingTop: +!!mapHack }]}
            >
                <MapView
                    options={{
                        gestureHandling: 'greedy',
                        mapTypeControl: false,
                        scrollwheel: true,
                        fullscreenControl: false,
                        panControl: false,
                        scaleControl: false,
                        zoomControl: false,
                        streetViewControl: false,
                        styles: MapStyle,
                    }}
                    customMapStyle={Platform.select({ ios: MapStyle })}
                    enableScrollGesturesDuringRotateOrZoom={false}
                    mapPadding={mapStore.padding}
                    onLayout={this.onMapLayout}
                    onMapReady={this.onMapReady}
                    onPanDrag={userPanMap}
                    onStartShouldSetResponder={Platform.select({ ios: userPanMap })}
                    onRegionChange={onRegionChange}
                    onRegionChangeComplete={onRegionChangeComplete}
                    onUserLocationChange={onUserLocationChange}
                    pitchEnabled={false}
                    provider={PROVIDER_GOOGLE}
                    ref={mapInstance}
                    showsCompass={false}
                    showsIndoors={false}
                    showsMyLocationButton={false}
                    showsUserLocation={taxiStore.locationGranted}
                    style={{ flex: 1 }}
                    initialRegion={{
                        latitude: 40.7523041,
                        longitude: -73.9908447,
                        latitudeDelta: 0.1,
                        longitudeDelta: 0.1,
                    }}
                    lastRegion={lastRegion}
                >
                    <BikeMarkers key="BikeMarkers" />
                    <PolyLineRouteBikes key="PolyLineRouteBikes" />
                    <BikeRouteMarkers key="BikeRouteMarkers" />
                    {!bikeStore.selectedBike && (
                        <>
                            <PolyLineRoute key="PolyLineRoute" />
                            <PlainMarkers key="PlainMarkers" />
                            <DriverMarker key="DriverMarker" />
                            <PickupMarker key="PickupMarker" />
                            <DestinationMarker key="DestinationMarker" />
                            <PickupLocationMarker key="PickupLocationMarker" />
                            <DestinationLocationMarker key="DestinationLocationMarker" />
                        </>
                    )}
                </MapView>
            </View>
        );
    }
}

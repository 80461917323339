import mixpanel from 'mixpanel-browser';

export default {
    sharedInstanceWithToken: async apiToken => {
        await mixpanel.init(apiToken);
    },
    trackChargeWithProperties: async (price, properties) => {
        if (!!properties) {
            await mixpanel.people.track_charge(price, properties);
        } else {
            await mixpanel.people.track_charge(price);
        }
    },
    registerSuperPropertiesOnce: async superproperties => {
        await mixpanel.register_once(superproperties);
    },
    increment: async (property, number) => {
        if (!!number) {
            await mixpanel.people.increment(property, number);
        } else {
            await mixpanel.people.increment(property);
        }
    },
    identify: async user_id => {
        await mixpanel.identify(user_id);
    },
    set: async properties => {
        await mixpanel.people.set(properties);
    },
    setPushRegistrationId: () => {},
};

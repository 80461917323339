import MapView, { Region, EventUserLocation, LatLng, Camera } from 'modules/maps/maps';
import React from 'react';
import { PixelRatio, Platform } from 'react-native';
import { isIphoneX } from 'react-native-iphone-x-helper';
import { observable, action, computed } from 'mobx';

import AnimateCamera from 'domain/app/AnimateCamera';
import BaseStore from './BaseStore';
import { Metrics } from '../themes';

export default class MapStore extends BaseStore {
    @observable.ref
    mapInstance: React.RefObject<MapView> = React.createRef();

    @observable
    lastUserLatitude = 0;

    @observable
    lastUserLongitude = 0;

    @observable
    lastRegionLatitude = 0;

    @observable
    lastRegionLongitude = 0;

    @observable
    lastRegion = { latitude: 0, longitude: 0 };

    @observable
    blockLocationOnMap: boolean = false;

    @observable
    userLocationLoaded: boolean = false;

    @action
    onRegionChange = (region: Region) => {
        const {
            taxiStore: { regionChanged },
        } = this.rootStore.stores;
        regionChanged(region);
    };

    @action
    setUserLocationLoaded = () => {
        this.userLocationLoaded = true;
    };

    @action
    toggleBlockLocationOnMap = (blockLocation: boolean) => {
        this.blockLocationOnMap = blockLocation;
    };

    @action
    onRegionChangeComplete = (region: Region) => {
        const { latitude, longitude } = region;
        const newLat = Number(latitude.toFixed(4));
        const newLng = Number(longitude.toFixed(4));
        if (this.lastRegionLatitude === newLat && this.lastRegionLongitude === newLng) {
            return;
        }
        this.lastRegionLatitude = newLat;
        this.lastRegionLongitude = newLng;
        this.lastRegion = region;
        const {
            taxiStore: { regionChangeComplete },
        } = this.rootStore.stores;
        regionChangeComplete(region);
        if (!this.userLocationLoaded) {
            this.setUserLocationLoaded();
        }
    };

    @action
    onUserLocationChange = ({ nativeEvent: { coordinate } }: EventUserLocation) => {
        if (!this.blockLocationOnMap) {
            const { latitude, longitude } = coordinate;
            const newLat = Number(latitude.toFixed(4));
            const newLng = Number(longitude.toFixed(4));
            if (this.lastUserLatitude === newLat && this.lastUserLongitude === newLng) {
                return;
            }
            this.lastUserLatitude = newLat;
            this.lastUserLongitude = newLng;

            const {
                taxiStore: { updateCurrentUserLocation },
            } = this.rootStore.stores;

            updateCurrentUserLocation(
                { coordinate: { ...coordinate, latitude: newLat, longitude: newLng } },
                null,
                'MapStore onUserLocationChange'
            );
        }
    };

    animateCamera = ({ latitude, longitude, altitude, zoom }: AnimateCamera) => {
        const camera = {
            center: {
                latitude,
                longitude,
            },
            //    pitch: number,
            //    heading: number,

            // Only on iOS MapKit, in meters. The property is ignored by Google Maps.
            //    altitude: number.

            // Only when using Google Maps.
            //    zoom: number
        };
        if (this.mapInstance.current) {
            this.mapInstance.current.animateCamera(camera, { duration: 500 });
        }
    };

    @computed
    get padding() {
        const {
            suggestionsStore: { favorites },
            taxiStore,
            taxiStore: { taxiRideStore },
            bikeStore,
        } = this.rootStore.stores;

        let bottomPadding = favorites.length === 2 ? 260 : favorites.length === 1 ? 210 : 190;
        let topPadding = 0;

        if (isIphoneX()) {
            if (favorites.length < 2) {
                topPadding = 0;
            } else {
                topPadding = 20;
            }
        } else if (favorites.length < 2) {
            topPadding = 20;
        } else {
            topPadding = 60;
        }

        if (taxiStore.taxiState === 'taxis') {
            bottomPadding = 360;
            topPadding = 30;
        } else if (taxiStore.taxiState === 'ride') {
            if (taxiRideStore.currentRideStatus.status === 'IN_PROGRESS') {
                bottomPadding = 170;
                topPadding = 95;
            } else if (taxiRideStore.currentRideStatus.location) {
                bottomPadding = 160;
                topPadding = 95;
            } else if (taxiRideStore.currentRideStatus.status === 'PENDING') {
                topPadding = 40;
                bottomPadding = 0;
            } else {
                topPadding = 95;
                bottomPadding = 110;
            }
        } else if (taxiStore.taxiState === 'confirm') {
            topPadding = 40;
            bottomPadding = 360;
        } else if (
            taxiStore.taxiState === 'selectDestination' ||
            taxiStore.taxiState === 'selectPickup'
        ) {
            // if (Platform.OS === 'android') {
            //     topPadding = 100;
            // } else {
            //     topPadding = 0;
            // }
            topPadding = 0;
            bottomPadding = 100;
        }

        if (
            taxiRideStore.rideRequestInProgress ||
            (!!taxiRideStore.currentRideStatus &&
                taxiRideStore.currentRideStatus.status === 'PENDING' &&
                !taxiRideStore.currentRideStatus.driver)
        ) {
            if (!taxiRideStore.rideRequestInProgress) {
                bottomPadding = 100;
            } else {
                bottomPadding = 0;
            }
        }

        if (bikeStore.selectedBike) {
            bottomPadding = 435;
        }
        if (bikeStore.selectedBikeOnMap) {
            bottomPadding = 220;
        }
        return {
            left: 0,
            right: 0,
            top: Metrics.paddingTop + topPadding,
            bottom: bottomPadding - Metrics.paddingBottom,
        };
    }

    fitToCoordinates = (points: Array<LatLng>) => {
        if (!points || !points.length || !this.mapInstance.current) {
            return;
        }

        const { bottom: bottomPadding, top: topPadding } = this.padding;

        const padding = Platform.select({
            android: {
                top: (Metrics.paddingTop + topPadding) * PixelRatio.get(),
                left: 60 * PixelRatio.get(),
                right: 60 * PixelRatio.get(),
                bottom: bottomPadding * PixelRatio.get(),
            },
            ios: {
                top: 0,
                left: 80,
                right: 80,
                bottom: 20,
            },
            web: {
                top: (Metrics.paddingTop + topPadding) * PixelRatio.get(),
                left: 60 * PixelRatio.get(),
                right: 60 * PixelRatio.get(),
                bottom: bottomPadding * PixelRatio.get(),
            },
        });

        if (this.mapInstance.current && this.mapInstance.current.fitToCoordinates) {
            this.mapInstance.current.fitToCoordinates(points.slice(), {
                edgePadding: padding,
                animated: true,
            });
        }
    };

    updateRegion = (region: Region) => {
        if (this.mapInstance.current) {
            this.mapInstance.current.animateToRegion(region);
        }
    };
}

import { StyleSheet } from 'react-native';
import { Colors, Metrics, Fonts } from '../../../themes';

export default StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: Colors.white,
    },
    titleView: {
        alignSelf: 'stretch',
        paddingHorizontal: 25,
        paddingVertical: 15,
        backgroundColor: Colors.white,
    },
    title: {
        fontFamily: Fonts.type.base,
        fontWeight: '600',
        fontSize: Metrics.normalize(28),
        color: Colors.black,
    },
    emptyHeight5: {
        height: 5,
    },
    subtitle: {
        fontFamily: Fonts.type.base,
        fontWeight: '400',
        fontSize: Metrics.normalize(16),
        color: Colors.darkGreyTextColor,
    },
    sectionHeader: {
        alignSelf: 'stretch',
        paddingVertical: 15,
        paddingHorizontal: 20,
    },
    sectionHeaderText: {
        fontFamily: Fonts.type.base,
        fontWeight: '500',
        fontSize: Metrics.normalize(11),
        color: Colors.grayTextColor,
    },
    sectionSeparator: {
        alignSelf: 'stretch',
        height: 15,
        backgroundColor: Colors.grayBackgroundColor,
    },
    itemSeparator: {
        alignSelf: 'stretch',
        marginHorizontal: 20,
        height: 1,
        backgroundColor: Colors.grayBackgroundColor,
    },
    itemContainer: {
        flex: 1,
        flexDirection: 'row',
        paddingVertical: 30,
        paddingHorizontal: 25,
    },
    itemInnerContainer: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
    },
    itemImage: {
        height: 25,
        width: 25,
        marginRight: 20,
    },
    itemTitle: {
        fontFamily: Fonts.type.base,
        fontWeight: '500',
        fontSize: Metrics.normalize(17),
        color: Colors.darkGreyTextColor,
    },
    itemArrowRightView: {
        flex: 0.2,
        alignItems: 'flex-end',
        justifyContent: 'center',
    },
    itemArrowRight: {
        height: 20,
        width: 20,
    },
});

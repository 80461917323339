/* eslint-disable no-underscore-dangle */
import React, { Component, RefObject } from 'react';
import { View, TouchableOpacity, Image, Animated, StyleSheet, Text, Platform } from 'react-native';
import { observer, inject } from 'mobx-react';
import { observable } from 'mobx';

import BottomDrawer, { YID, Snap } from '../drawer/BottomDrawer';
import InviteFriendsStyles from './styles/InviteFriendsStyles';
import ReferralList from './ReferralList';
import { Images, Metrics } from 'themes';
import { Stores, LayoutStore, NavigationStore, ReferralStore } from 'stores';
import { Localizable, Analytics } from 'lib';

interface Props {
    layoutStore: LayoutStore;
    navigationStore: NavigationStore;
    referralStore: ReferralStore;
}

@inject(({ layoutStore, navigationStore, referralStore }: Stores) => ({
    layoutStore,
    navigationStore,
    referralStore,
}))
@observer
export default class InviteFriends extends Component<Props, {}> {
    @observable.ref
    bottomDrawerRef: RefObject<BottomDrawer> = React.createRef();

    @observable
    currentSnap: Snap = 'up';

    bottomDrawerSnapPoints: Array<YID> = [
        {
            y: this.props.layoutStore.screenHeight / 8,
            id: 'up',
        },
        { y: Metrics.screenHeight, id: 'closed' },
    ];

    bottomDrawerInitialPoint: number = this.bottomDrawerSnapPoints[1].y;

    deltaY = new Animated.Value((this.props.layoutStore.screenHeight * 7) / 8);

    bottomDrawerInterpolation: any = {
        inputRange: this.bottomDrawerSnapPoints.map(point => point.y),
        outputRange: [0.6, 0.2],
    };

    bottomDrawerBoundaries: any = {
        bottom: Metrics.screenHeight,
        top: this.bottomDrawerSnapPoints[0].y,
    };

    componentDidMount() {
        const {
            referralStore: { setInviteFriendsCounterInterval },
        } = this.props;
        setInviteFriendsCounterInterval();
        Analytics.trackScreenView('04_Referrals');
    }

    componentWillUnmount() {
        const {
            referralStore: { clearInviteFriendsCounterInterval },
        } = this.props;
        clearInviteFriendsCounterInterval();
    }

    setCurrentSnap = (newSnap: Snap) => {
        this.currentSnap = newSnap;
    };

    onClose = () => {
        const {
            referralStore: { toggleReferralDrawer },
        } = this.props;
        toggleReferralDrawer();
        Analytics.trackScreenView('02_Map');
    };

    render() {
        const { layoutStore, referralStore } = this.props;
        return referralStore.shouldShowReferralDrawer ? (
            <View style={StyleSheet.absoluteFill} pointerEvents="box-none">
                <BottomDrawer
                    handleBackClick={
                        Platform.OS === 'web' ? referralStore.toggleReferralDrawer : undefined
                    }
                    backgroundOpacityInterpolation={this.bottomDrawerInterpolation}
                    boundaries={this.bottomDrawerBoundaries}
                    currentSnap={this.currentSnap}
                    deltaY={this.deltaY}
                    initialPoint={this.bottomDrawerInitialPoint}
                    onClose={this.onClose}
                    openAnimated={{ toIndex: 0 }}
                    ref={this.bottomDrawerRef}
                    setCurrentSnap={this.setCurrentSnap}
                    snapPoints={this.bottomDrawerSnapPoints}
                >
                    <View
                        style={[
                            InviteFriendsStyles.interactableContainer,
                            { height: (layoutStore.screenHeight * 7) / 8 },
                        ]}
                    >
                        <TouchableOpacity style={InviteFriendsStyles.interactabeIndicatorView}>
                            <View style={InviteFriendsStyles.interactableIndicator} />
                        </TouchableOpacity>
                        <Animated.View
                            style={[
                                InviteFriendsStyles.interactableInner,
                                {
                                    opacity: this.deltaY.interpolate({
                                        inputRange: [
                                            this.bottomDrawerSnapPoints[0].y,
                                            this.bottomDrawerSnapPoints[1].y,
                                        ],
                                        outputRange: [1, 0],
                                        extrapolate: 'clamp',
                                    }),
                                },
                            ]}
                        >
                            <View style={InviteFriendsStyles.topView}>
                                <Image
                                    source={Images.inviteFriends.inviteFriends}
                                    style={InviteFriendsStyles.inviteFriendsImage}
                                    resizeMode="contain"
                                />
                                <Text style={InviteFriendsStyles.title}>
                                    {Localizable.t('inviteFriends.inviteFriends')}
                                </Text>
                                <Text style={InviteFriendsStyles.text} numberOfLines={2}>
                                    {Localizable.t('inviteFriends.inviteFriendsText1stLine')}
                                    {'\n'}
                                    {Localizable.t('inviteFriends.inviteFriendsText2ndLine')}
                                </Text>
                                <View style={InviteFriendsStyles.referralCounterView}>
                                    <View style={InviteFriendsStyles.referralCounterLeftPanel}>
                                        <Image
                                            source={Images.inviteFriends.referralCounter}
                                            style={InviteFriendsStyles.referralCounterImage}
                                            resizeMode="contain"
                                        />
                                        <Text style={InviteFriendsStyles.referralCounterTitle}>
                                            {Localizable.t('inviteFriends.yourReferrals')}
                                        </Text>
                                    </View>
                                    <Text style={InviteFriendsStyles.referralCounter}>
                                        {referralStore.inviteCounter || 0}
                                    </Text>
                                </View>
                            </View>
                            <View
                                style={{
                                    flex: 1,
                                    marginBottom:
                                        Metrics.paddingBottom === 0 ? 5 : Metrics.paddingBottom,
                                    justifyContent: 'center',
                                }}
                            >
                                <ReferralList />
                            </View>
                            {/* <TouchableOpacity
                                style={InviteFriendsStyles.inviteFriendsButton}
                                onPress={() => {}}
                            >
                                <Text style={InviteFriendsStyles.inviteFriendsButtonText}>
                                    Invite Friends
                                </Text>
                            </TouchableOpacity> */}
                        </Animated.View>
                    </View>
                </BottomDrawer>
            </View>
        ) : null;
    }
}

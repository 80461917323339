import React from 'react';
import { View, Text, Image } from 'react-native';
import { observer } from 'mobx-react';

import Prediction from 'domain/app/Prediction';
import PredictionListStyles from './styles/PredictionListStyles';
import { Images, Colors } from '../../themes';

const propsFoPeriod: any = {
    UP: {
        name: 'Up',
        height: 70,
        icon: Images.predictions.up,
        backgroundColor: Colors.predictions.up.backgroundColor,
        color: Colors.predictions.up.color,
    },
    DOWN: {
        name: 'Down',
        height: 110,
        icon: Images.predictions.down,
        backgroundColor: Colors.predictions.down.backgroundColor,
        color: Colors.predictions.down.color,
    },
    SAME: {
        name: 'Same',
        height: 85,
        icon: Images.predictions.same,
        backgroundColor: Colors.predictions.same.backgroundColor,
        color: Colors.predictions.same.color,
    },
};

const PredictionList = ({ predictions }: { predictions: Array<Prediction> }) => (
    <View style={PredictionListStyles.container}>
        {predictions.map(prediction => {
            return (
                <View key={prediction.period} style={PredictionListStyles.innerContainer}>
                    <View
                        style={[
                            PredictionListStyles.predictionView,
                            { ...propsFoPeriod[prediction.prediction] },
                        ]}
                    >
                        <Image source={propsFoPeriod[prediction.prediction].icon} />
                        <Text
                            style={[
                                PredictionListStyles.predictionText,
                                { color: propsFoPeriod[prediction.prediction].color },
                            ]}
                        >
                            {propsFoPeriod[prediction.prediction].name}
                        </Text>
                    </View>
                    <Text style={PredictionListStyles.predictionPeriod}>
                        {' '}
                        {prediction.period}
                        {' min'}
                    </Text>
                </View>
            );
        })}
    </View>
);

export default observer(PredictionList);

import React from 'react';
import { Platform, TouchableOpacity, View, Text, Image } from 'react-native';

import SortTypeButtonStyles from './styles/SortTypeButtonStyles';
import { Colors, Images } from '../../../../themes';

interface Props {
    ascending: boolean;
    name: string;
    index: number;
    onPress: () => void;
    selected: boolean;
}

export default ({ ascending, name, onPress, selected }: Props) => (
    <TouchableOpacity style={SortTypeButtonStyles.sortTypeButton} onPress={onPress}>
        <View
            style={[
                SortTypeButtonStyles.sortTypeInner,
                Platform.OS !== 'web' && SortTypeButtonStyles.sortTypeInnerMobile,
                {
                    backgroundColor: selected ? Colors.white : Colors.transparent,
                },
            ]}
        >
            <View style={SortTypeButtonStyles.textView}>
                <Text
                    style={[
                        SortTypeButtonStyles.typeTitleText,
                        {
                            color: Colors.black,
                        },
                    ]}
                >
                    {name}
                </Text>
            </View>
            {selected && (
                <View style={SortTypeButtonStyles.imageView}>
                    <Image
                        source={
                            ascending ? Images.icons.sortAscending : Images.icons.sortDescending
                        }
                        style={SortTypeButtonStyles.image}
                        resizeMode="contain"
                    />
                </View>
            )}
        </View>
    </TouchableOpacity>
);

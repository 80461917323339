import React from 'react';
import { ScrollView, Text, Image, View, TouchableOpacity, Platform } from 'react-native';
import { observer, inject } from 'mobx-react';

import PaymentMethodsStore from '../../../stores/PaymentMethodsStore';
import RideDetailsSummaryScrollViewStyles from './styles/RideDetailsSummaryScrollViewStyles';
import RouteView from '../RouteView';
import TaxiStore from '../../../stores/TaxiStore';
import { Images } from '../../../themes';
import { Localizable } from '../../../lib';
import { Stores } from '../../../stores/RootStore';
import { ScrollViewStore } from 'containers/taxis/RideDetailsView';

interface Props {
    paymentMethodsStore: PaymentMethodsStore;
    taxiStore: TaxiStore;
    scrollViewStore: ScrollViewStore;
}

export default inject(({ paymentMethodsStore, taxiStore }: Stores) => ({
    paymentMethodsStore,
    taxiStore,
}))(
    observer(
        ({
            taxiStore,
            taxiStore: { selectedTaxiStore },
            paymentMethodsStore,
            scrollViewStore,
        }: Props) => {
            return (
                <ScrollView
                    style={RideDetailsSummaryScrollViewStyles.scrollView}
                    onContentSizeChange={(width, height) =>
                        scrollViewStore.setContentHeight(height)
                    }
                    scrollEnabled={!scrollViewStore.contentFitInParent}
                >
                    <Text style={RideDetailsSummaryScrollViewStyles.headerText}>
                        {Localizable.t('taxis.rideDetailsSummary.trip')}
                    </Text>
                    <RouteView />
                    <Text style={RideDetailsSummaryScrollViewStyles.headerText}>
                        {Localizable.t('taxis.rideDetailsSummary.fare')}
                    </Text>
                    <View style={RideDetailsSummaryScrollViewStyles.shadowView}>
                        <View style={RideDetailsSummaryScrollViewStyles.valueRow}>
                            <Text style={RideDetailsSummaryScrollViewStyles.valueSmallTitleText}>
                                {selectedTaxiStore.selectedTaxi.provider.company}{' '}
                                {Localizable.t('taxis.rideDetailsSummary.fareSmall')}
                            </Text>
                            <View style={RideDetailsSummaryScrollViewStyles.empty} />
                            <Text style={RideDetailsSummaryScrollViewStyles.valueSmallText}>
                                {selectedTaxiStore.selectedTaxiPrice}
                            </Text>
                        </View>
                        <View style={RideDetailsSummaryScrollViewStyles.valueRow}>
                            <Text style={RideDetailsSummaryScrollViewStyles.valueSmallTitleText}>
                                {Localizable.t('taxis.rideDetailsSummary.fees')}
                            </Text>
                            <View style={RideDetailsSummaryScrollViewStyles.empty} />
                            <Text style={RideDetailsSummaryScrollViewStyles.valueSmallText}>
                                {Localizable.t('taxis.rideDetailsSummary.noFee')}
                            </Text>
                        </View>
                        <View style={RideDetailsSummaryScrollViewStyles.separator} />
                        <View style={RideDetailsSummaryScrollViewStyles.valueRow}>
                            <Text style={RideDetailsSummaryScrollViewStyles.valueTitleText}>
                                {Localizable.t('taxis.rideDetailsSummary.totalPrice')}{' '}
                                {selectedTaxiStore.selectedTaxi.price_info &&
                                    selectedTaxiStore.selectedTaxi.price_info.currency && (
                                        <Text
                                            style={
                                                RideDetailsSummaryScrollViewStyles.valueCurrencyText
                                            }
                                        >
                                            ({selectedTaxiStore.selectedTaxi.price_info.currency})
                                        </Text>
                                    )}
                            </Text>
                            <View style={RideDetailsSummaryScrollViewStyles.empty} />
                            <Text style={RideDetailsSummaryScrollViewStyles.valueText}>
                                {selectedTaxiStore.selectedTaxiPrice}
                            </Text>
                        </View>
                    </View>
                    {!!selectedTaxiStore.selectedTaxi.booking_enabled &&
                    paymentMethodsStore.paymentMethods.length ? (
                        <>
                            <Text style={RideDetailsSummaryScrollViewStyles.headerText}>
                                Payment
                            </Text>
                            <View style={RideDetailsSummaryScrollViewStyles.shadowView}>
                                <View style={RideDetailsSummaryScrollViewStyles.valueRow}>
                                    <TouchableOpacity
                                        onPress={() =>
                                            paymentMethodsStore.showPaymentsPopup(
                                                selectedTaxiStore.selectedTaxi.service_provider.toLowerCase()
                                            )
                                        }
                                        style={
                                            RideDetailsSummaryScrollViewStyles.touchableContainer
                                        }
                                    >
                                        <View
                                            style={
                                                RideDetailsSummaryScrollViewStyles.cardIconContainer
                                            }
                                        >
                                            <Image
                                                source={paymentMethodsStore.cardIcon}
                                                style={RideDetailsSummaryScrollViewStyles.cardImage}
                                                resizeMode="contain"
                                            />
                                        </View>
                                        <Text
                                            style={
                                                RideDetailsSummaryScrollViewStyles.cardDescription
                                            }
                                        >
                                            ****{' '}
                                            {paymentMethodsStore.cardInfo
                                                ? paymentMethodsStore.cardInfo.description
                                                : ''}
                                        </Text>
                                        <View style={RideDetailsSummaryScrollViewStyles.flexOne} />
                                        <Text
                                            style={RideDetailsSummaryScrollViewStyles.paymentChange}
                                        >
                                            CHANGE
                                        </Text>
                                        <Image
                                            source={Images.pickupArrow}
                                            style={RideDetailsSummaryScrollViewStyles.paymentIcon}
                                            resizeMode="contain"
                                        />
                                    </TouchableOpacity>
                                </View>
                            </View>
                        </>
                    ) : null}
                    <Text style={RideDetailsSummaryScrollViewStyles.disclaimerText}>
                        {selectedTaxiStore.fareWarning}
                    </Text>
                </ScrollView>
            );
        }
    )
);

import React from 'react';
import { Image, View, Text } from 'react-native';

import TripSavingsItemStyles from './TripSavingsItemStyles';
import { Images, Metrics, Colors } from '../../../../themes';
import { Localizable } from '../../../../lib';
import { providerInfo } from '../../../../services/Utils';

interface Props {
    item: {
        provider: string;
        destination: string;
        distance: string;
        savings: string;
    };
    index: number;
    section: any;
}

const TripSavingsItem = ({ item, index }: Props) => {
    const { provider, destination, distance, savings } = item;
    const imageSource = providerInfo(provider).icon;
    return (
        <View
            style={[
                TripSavingsItemStyles.itemContainer,
                index !== 0 && {
                    borderTopWidth: Metrics.normalize(1),
                    borderTopColor: Colors.lightGrayColor,
                },
            ]}
        >
            <Image source={imageSource} style={TripSavingsItemStyles.providerIcon} />
            <View style={TripSavingsItemStyles.innerItemContainer}>
                <Text numberOfLines={1} style={TripSavingsItemStyles.destinationText}>
                    {Localizable.t('savings.tripSavingsItem.to')}
                    {` ${destination}`}
                </Text>
                <View style={TripSavingsItemStyles.distanceContainer}>
                    <Image
                        resizeMode="contain"
                        source={Images.icons.greyPin}
                        style={TripSavingsItemStyles.distanceIcon}
                    />
                    <Text style={TripSavingsItemStyles.distanceText}>
                        {Localizable.t('savings.tripSavingsItem.distance')}
                        {` ${distance} `}
                        {Localizable.t('savings.tripSavingsItem.miles')}
                    </Text>
                </View>
            </View>
            <View style={TripSavingsItemStyles.savedContainer}>
                <Text style={TripSavingsItemStyles.savedValueText}>{`$${savings}`}</Text>
                <Text style={TripSavingsItemStyles.savedText}>
                    {Localizable.t('savings.tripSavingsItem.saved')}
                </Text>
            </View>
        </View>
    );
};

export default TripSavingsItem;

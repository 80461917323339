import React from 'react';
import { Text, TouchableOpacity, Image, View } from 'react-native';

import DrawerItem from 'domain/app/DrawerItem';
import DrawerListItemStyles from './styles/DrawerListItemStyles';

interface Props {
    item: DrawerItem;
}

const DrawerListItem = ({ item }: Props) => {
    const { name, image, onPress } = item;

    return (
        <TouchableOpacity onPress={onPress} key={name} style={DrawerListItemStyles.container}>
            <Image source={image} style={DrawerListItemStyles.image} />
            <View style={DrawerListItemStyles.textView}>
                <Text style={DrawerListItemStyles.text}>{name}</Text>
            </View>
        </TouchableOpacity>
    );
};

export default DrawerListItem;

import React, { ReactElement } from 'react';
import { Text, TextStyle } from 'react-native';
import InfoLabelStyles from './ClickableInfoLabelStyles';

export interface ClickableInfoChildProps {
    text: string;
    clickableText: string;
    clickableTextStyle: TextStyle;
    handleClickableTextClick: () => void;
    index?: number;
}

const ClickableInfoLabelChild = ({
    text,
    clickableText,
    clickableTextStyle,
    handleClickableTextClick,
    index,
}: ClickableInfoChildProps) => (
    <>
        {index !== 0 && ' '}
        {text}{' '}
        <Text style={clickableTextStyle} onPress={handleClickableTextClick}>
            {clickableText}
        </Text>
    </>
);

interface Props {
    childrenProps: Array<ClickableInfoChildProps>;
}

const ClickableInfoLabel = ({ childrenProps }: Props) => (
    <Text style={InfoLabelStyles.label}>
        {childrenProps.map((props: ClickableInfoChildProps, index) => (
            <ClickableInfoLabelChild {...props} index={index} key={props.clickableText} />
        ))}
    </Text>
);

export default ClickableInfoLabel;

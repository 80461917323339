import { StyleSheet } from 'react-native';
import { Fonts, Metrics, Colors } from '../../../themes';

export default StyleSheet.create({
    container: {
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
        position: 'absolute',
        backgroundColor: Colors.darkPurple,
        zIndex: 2,
    },
    mapLoadingContainer: {
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
        position: 'absolute',
        zIndex: 1,
    },
    imageContainer: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    mapLoadingPopupImageContainer: {
        position: 'absolute',
        left: 0,
        right: 0,
        alignItems: 'center',
        justifyContent: 'center',
    },
    background: {
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
        position: 'absolute',
    },
    icon: {
        width: 185,
        height: 70,
    },
    farewellIcon: {
        width: 85,
        height: 85,
    },
    poweredByImage: {
        width: 90,
        height: 47,
        marginTop: 30,
    },
    bottomView: {
        backgroundColor: 'white',
        borderTopLeftRadius: 16,
        borderTopRightRadius: 16,
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
    },
    bubbles: {
        position: 'absolute',
        top: 30,
        left: 20,
        width: Metrics.screenWidth - 40,
        height: (Metrics.screenWidth - 40) * 0.9,
    },
    rideText: {
        fontSize: Metrics.normalize(14),
        fontWeight: '400',
        fontFamily: Fonts.type.base,
        backgroundColor: 'transparent',
        color: 'white',
        alignSelf: 'flex-end',
    },
    topIcon: {
        width: 20,
        height: 20,
    },
    topText: {
        fontSize: Metrics.normalize(14),
        fontWeight: '400',
        fontFamily: Fonts.type.base,
        backgroundColor: 'transparent',
        color: Colors.obiTeal,
        marginLeft: 16,
    },
    confirmButton: {
        marginTop: 6,
    },
    blackLine: {
        width: 35,
        height: 3,
        backgroundColor: 'black',
        marginVertical: 10,
    },
    title: {
        fontSize: Metrics.normalize(32),
        fontWeight: '400',
        fontFamily: Fonts.type.base,
        backgroundColor: 'transparent',
        color: 'black',
        marginBottom: 16,
    },
    description: {
        fontSize: Metrics.normalize(17),
        fontWeight: '300',
        fontFamily: Fonts.type.base,
        backgroundColor: 'transparent',
        color: Colors.grayTextColor,
        marginBottom: 10,
        lineHeight: Metrics.normalize(25),
    },
    socialButton: {
        width: Metrics.screenWidth - 60,
        height: (Metrics.screenWidth - 60) * 0.19,
    },
    emailText: {
        marginTop: 10,
        fontSize: Metrics.normalize(14),
        fontWeight: '300',
        fontFamily: Fonts.type.base,
        backgroundColor: 'transparent',
        color: 'black',
        textAlign: 'center',
        marginBottom: 60,
    },
    contentContainer: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    contentText: {
        color: 'white',
        // color: '#D8D8D8',
        fontSize: Metrics.normalize(16),
        marginBottom: 14,
    },
    contentBackgroundImage: {
        height: 44,
        width: 168,
        // justifyContent: 'center',
        alignItems: 'center',
        paddingTop: 4,
    },
});

import { observable, action, flow } from 'mobx';

import { LocationsStore } from '.';
import { GeolocationService, GoogleClient } from 'services';
import { handleError } from '../services/Utils';

export default class FavoriteAddressStore {
    locationsStore: LocationsStore;

    suggestionSelected: any;

    type: any;

    @observable suggestions: Array<any> = [];

    @observable address: string = '';

    constructor(suggestionSelected: any, type: any, locationsStore: LocationsStore) {
        this.locationsStore = locationsStore;
        this.suggestionSelected = suggestionSelected;
        this.type = type;
    }

    @action
    updateAddress = (address: string) => {
        this.address = address;
        this.getSuggestions(address);
    };

    @action
    getSuggestions = flow(function*(this: FavoriteAddressStore, address: string) {
        if (!address || address.length === 0) {
            this.suggestions = [];
            return;
        }

        const {
            rootStore: {
                stores: { taxiStore },
            },
        } = this.locationsStore;

        try {
            const {
                data: { predictions },
            } = yield GoogleClient.getSuggestions(address, taxiStore.userLocation);
            this.parseSuggestions(predictions, address);
        } catch (error) {
            handleError(error);
        }
    }).bind(this);

    @action
    parseSuggestions = (predictions: Array<any>, address: string) => {
        this.suggestions = [];
        const locationsArray = this.locationsStore.getLocations(address);
        this.suggestions.push(...locationsArray);
        this.suggestions.push(...predictions);
    };

    @action
    selectSuggestion = (suggestion: any) => {
        const formattedAddress = suggestion.structured_formatting.main_text;

        this.locationsStore.addFavorite(suggestion, this.type, () => {
            this.suggestionSelected(formattedAddress);
        });
    };
}

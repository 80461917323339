import React, { Component } from 'react';
import { View, Text, Image, TouchableOpacity } from 'react-native';
import Modal from 'modules/modal/modal';
import { observer } from 'mobx-react';
import moment from 'moment';
import styles from './styles';
import { providerInfo } from 'services/Utils';
import { Localizable } from 'lib';
import { Images } from 'themes';
import RouteView from './routeView/RouteView';
import DataInfo from './dataInfo/DataInfo';
import TripSavingsMap from './map/TripSavingsMap';

interface Props {
    modalStore: any;
}

interface State {}

@observer
export default class TripSavingsModal extends Component<Props, State, {}> {
    render() {
        const {
            modalStore: {
                provider = '',
                pickup,
                destination,
                pickupTime,
                arrivedTime,
                distance,
                duration,
                totalSavings = 0,
                modalVisible,
                hideModal,
                seats,
                currency,
                pickupLocation,
                destinationLocation,
                route,
            },
        } = this.props;
        const { latitude, longitude } = pickupLocation || {};
        const newPickupLocation = { latitude, longitude };
        return (
            <Modal
                hideModalContentWhileAnimating
                isVisible={modalVisible}
                onBackdropPress={hideModal}
                style={styles.container}
            >
                <View style={styles.contentContainer}>
                    <View style={styles.topContainer}>
                        <Image source={providerInfo(provider).icon} style={styles.providerIcon} />
                        <View style={styles.detailsView}>
                            <Text style={styles.nameText}>{providerInfo(provider).name}</Text>
                            {seats && (
                                <Text style={styles.detailText}>
                                    {seats > 1 && '1-'}
                                    {seats} {Localizable.t('rideDetails.seats')}
                                </Text>
                            )}
                        </View>
                        <View style={styles.flexOne} />
                        <TouchableOpacity style={styles.hideModalButton} onPress={hideModal}>
                            <Image
                                source={Images.settings.close}
                                style={styles.closeImage}
                                resizeMode="contain"
                            />
                        </TouchableOpacity>
                    </View>
                    <View style={styles.tripSavingsMap}>
                        <TripSavingsMap
                            pickupLocation={newPickupLocation}
                            destinationLocation={destinationLocation}
                            route={route.slice()}
                        />
                    </View>
                    <RouteView
                        arrivedTime={arrivedTime}
                        arrivedAt={moment(arrivedTime).format('h:mm a')}
                        date={moment(pickupTime).format('h:mm a')}
                        destination={destination}
                        pickup={pickup}
                    />
                    <View style={styles.bottomContainer}>
                        <View style={styles.rowContainer}>
                            <DataInfo label="DISTANCE" data={`${distance} mi`} />
                            {!!arrivedTime && [
                                <View style={styles.separator} />,
                                <DataInfo label="DURATION" data={`${duration} min`} />,
                            ]}
                        </View>
                        <Text style={styles.totalSavingsLabel}>Total savings</Text>
                        <Text style={styles.totalSavingsValue}>
                            {`${currency}${totalSavings.toFixed(2)}`}
                        </Text>
                    </View>
                </View>
            </Modal>
        );
    }
}

import React, { Component } from 'react';
import { View, Text, Image } from 'react-native';
import { observer, inject } from 'mobx-react';
import { computed } from 'mobx';

import FiltersStore from '../../../stores/FiltersStore';
import RidePopupStyles from './styles/RidePopupStyles';
import TaxiStore from '../../../stores/TaxiStore';
import { Images } from '../../../themes';
import { Stores } from '../../../stores/RootStore';

interface InjectedProps {
    filtersStore: FiltersStore;
    taxiStore: TaxiStore;
}

interface OwnProps {
    title: string;
    arrivalTime?: any;
    duration?: number | string | null;
}

type Props = InjectedProps & OwnProps;

export default observer(({ title, duration }: Props) => {
    const shouldDisplayEta = !!duration && duration !== 'N/A';
    return (
        <View style={[RidePopupStyles.container, { paddingTop: shouldDisplayEta ? 15 : 5 }]}>
            <View style={RidePopupStyles.innerContainer}>
                <Text style={RidePopupStyles.title}>{title}</Text>
                <Image source={Images.pickupArrowGrey} style={RidePopupStyles.image} />
            </View>
            {shouldDisplayEta && (
                <View style={RidePopupStyles.etaView}>
                    <Text style={RidePopupStyles.etaText}>{duration} min</Text>
                </View>
            )}
        </View>
    );
});

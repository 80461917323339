import { StyleSheet } from 'react-native';
import { Fonts, Metrics } from 'themes';

export default StyleSheet.create({
    bestValueView: {
        paddingHorizontal: 7,
        paddingVertical: 3,
        borderRadius: 10,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#50C96A',
    },
    bestValueIcon: {
        width: 11,
        height: 11,
    },
});

import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Image, Keyboard, Platform, EmitterSubscription, View } from 'react-native';

import Animatable from 'modules/animatable/animatable';
import { isIphoneX } from 'modules/iPhoneXHelper/iPhoneXHelper';

import AddressStore from '../../stores/AddressStore';
import CenterMapButton from '../map/centerMapButton/CenterMapButton';
import MapAddressPickerStyles from './styles/MapAddressPickerStyles';
import SmallMapAddressPicker from './SmallMapAddressPicker';
import SuggestionsStore from '../../stores/SuggestionsStore';
import TaxiStore from '../../stores/TaxiStore';
import { Images } from '../../themes';
import { Localizable, Analytics } from '../../lib';
import { Stores } from '../../stores/RootStore';
import KeyboardStore from 'stores/subsidiaryStores/KeyboardStore';
import { showAlert } from 'services/Utils';

interface Props {
    addressStore: AddressStore;
    suggestionsStore: SuggestionsStore;
    taxiStore: TaxiStore;
}

@inject(({ addressStore, taxiStore }: Stores) => ({
    addressStore,
    taxiStore,
}))
@observer
export default class MapAddressPicker extends Component<Props> {
    keyboardStore = new KeyboardStore();

    keyboardDidShowListener?: EmitterSubscription;

    keyboardDidHideListener?: EmitterSubscription;

    componentDidMount() {
        const {
            props: { taxiStore },
            keyboardStore: { onKeyboardShow, onKeyboardHide },
        } = this;
        this.keyboardDidShowListener = Keyboard.addListener('keyboardDidShow', onKeyboardShow);
        this.keyboardDidHideListener = Keyboard.addListener(
            Platform.OS === 'ios' ? 'keyboardWillHide' : 'keyboardDidHide',
            onKeyboardHide
        );
        Analytics.trackScreenView(
            `02-02a_Use map to set ${taxiStore.taxiState === 'selectPickup' ? 'pickup' : 'dropoff'}`
        );
    }

    componentWillUnmount() {
        if (this.keyboardDidShowListener) {
            this.keyboardDidShowListener.remove();
        }
        if (this.keyboardDidHideListener) {
            this.keyboardDidHideListener.remove();
        }
    }

    render() {
        const {
            addressStore: {
                smallAddressPickerStore: {
                    confirmLocation,
                    confirmLocationButtonActive,
                    confirmLocationButtonAlert,
                },
            },
            taxiStore,
        } = this.props;

        return taxiStore.taxiState === 'selectPickup' ||
            taxiStore.taxiState === 'selectDestination' ? (
            <>
                <SmallMapAddressPicker key="AddressPicker" />
                <Animatable.View
                    key="AddressPickerView"
                    animation="bounce"
                    duration={300}
                    pointerEvents="box-none"
                    style={MapAddressPickerStyles.container}
                    useNativeDriver
                >
                    <Image
                        source={Images.mapLanding.pin}
                        style={[MapAddressPickerStyles.image, { marginTop: isIphoneX() ? 30 : 0 }]}
                    />
                </Animatable.View>
                <CenterMapButton
                    bottom={this.keyboardStore.keyboardHeight + 110}
                    key="CenterButton"
                />
                <View
                    style={[
                        MapAddressPickerStyles.confirmView,
                        { bottom: this.keyboardStore.keyboardHeight },
                    ]}
                >
                    <GradientButton
                        title={
                            taxiStore.taxiState === 'selectPickup'
                                ? Localizable.t('taxi.confirmPickup')
                                : Localizable.t('taxi.confirmDestination')
                        }
                        handlePress={() => {
                            confirmLocationButtonActive
                                ? confirmLocation()
                                : showAlert(confirmLocationButtonAlert);
                        }}
                    />
                </View>
            </>
        ) : null;
    }
}

import React, { RefObject, createRef } from 'react';
import { observable, action, computed, autorun } from 'mobx';
import { TextInput, Platform } from 'react-native';

import BaseStore from './BaseStore';
import RestClient from '../services/RestClient';
import SmallAddressPickerStore from './addressStores/SmallAddressPickerStore';
import Venue from 'domain/app/Venue';
import { Analytics } from '../lib';
import { handleError, trimAddress, distance, distanceBetweenCoords } from '../services/Utils';

const NYC_coords = {
    coords: {
        latitude: 40.634954,
        longitude: -73.867459,
    },
    radius: 24,
};

type FocusedInputType = 'pickup' | 'destination';

export default class AddressStore extends BaseStore {
    smallAddressPickerStore = new SmallAddressPickerStore(this.rootStore);

    @observable
    pickup: string | null = null;

    @observable
    destination: string | null = null;

    @observable
    pickupLocation: any = null;

    @observable
    destinationLocation: any = null;

    @observable
    selectedField: any;

    @observable
    currentType?: string;

    @observable
    correctPickupAvailable: boolean = false;

    @observable
    pickupTime: number | null = null;

    @observable
    firstPickupTimeLoaded: boolean = false;

    @observable
    pickupTimeLoading: boolean = true;

    @observable
    focusedInput: FocusedInputType | null = null;

    @observable
    venues: Array<Venue> = [];

    @observable
    venueSelected: boolean = false;

    @observable
    showVenuesPopup: boolean = false;

    @observable.ref
    destinationInputRef: RefObject<TextInput> = createRef();

    @observable.ref
    pickupInputRef: RefObject<TextInput> = createRef();

    @action
    setPickup = (pickup: string | null) => {
        const {
            addressStore: {
                smallAddressPickerStore: { toggleShowSuggestions },
            },
        } = this.rootStore.stores;
        toggleShowSuggestions(false);
        this.pickup = pickup;
    };

    @action
    setPickupLocation = pickup => {
        this.pickupLocation = pickup;
        if (!this.correctPickupAvailable) {
            const {
                taxiStore: { currentUserLocation },
            } = this.rootStore.stores;
            if (
                currentUserLocation &&
                currentUserLocation.coordinate &&
                distance(pickup, currentUserLocation.coordinate) < 100
            ) {
                this.correctPickupAvailable = true;
            }
        }
    };

    @action
    setDestination = (destination: string | null) => {
        const {
            addressStore: {
                smallAddressPickerStore: { toggleShowSuggestions },
            },
        } = this.rootStore.stores;
        toggleShowSuggestions(false);
        this.destination = destination;
    };

    @action
    setDestinationLocation = destination => {
        this.destinationLocation = destination;
    };

    @action
    setPickupTime = time => {
        this.pickupTime = time;
        this.firstPickupTimeLoaded = true;
    };

    @action
    setPickupTimeLoading = loading => {
        this.pickupTimeLoading = loading;
    };

    @action
    setFocusedInput = (focusedInput: FocusedInputType) => {
        this.focusedInput = focusedInput;
    };

    @action
    setVenueSelected = venueSelected => {
        this.venueSelected = venueSelected;
    };

    @action
    setSelectedField = selectedField => {
        this.selectedField = selectedField;
    };

    // Address picker

    @action
    onFocusStart = type => {
        const {
            taxiStore: { taxiState, setTaxiState, setSuggestionsState },
        } = this.rootStore.stores;
        this.currentType = type;
        if (type === 'pickup') {
            setSuggestionsState('pickup');
            if (taxiState !== 'start') {
                this.setSelectedField('pickup');
                setTaxiState('start');
            }
        } else {
            setSuggestionsState('destination');
            if (taxiState !== 'start') {
                this.setSelectedField('destination');
                setTaxiState('start');
            }
        }
        this.setFocusedInput(type);
    };

    @action
    onFocusEnd = type => {
        if (type === 'pickup' && (!this.pickup || !this.pickup.length) && this.pickupLocation) {
            this.setPickup(trimAddress(this.pickupLocation.address));
        }
        this.setFocusedInput(null);
    };

    @action
    switchPickupWithLocation = () => {
        const [tempAddress, tempLocation] = [this.pickup, this.pickupLocation];
        this.updatePickupText(this.destination);
        this.updateDestinationText(tempAddress);
        if (!this.pickup || this.pickup.length === 0) {
            this.pickupInputRef.current?.focus();
        } else if (!this.destination || this.destination.length === 0) {
            this.destinationInputRef.current?.focus();
        }
        this.pickupLocation = this.destinationLocation ? this.destinationLocation : tempLocation;
        this.destinationLocation = tempLocation;
    };

    @action
    updatePickupText = (pickup: string | null) => {
        const {
            taxiStore: { resetPath, handleSuggestionType },
        } = this.rootStore.stores;
        this.pickup = pickup;
        resetPath();
        // handleSuggestionType(pickup, true);
    };

    @action
    updateDestinationText = (destination: string | null) => {
        const {
            taxiStore: { resetPath, handleSuggestionType },
        } = this.rootStore.stores;
        this.destination = destination;
        resetPath();
        // handleSuggestionType(destination, false);
    };

    @action
    showDestination = () => {
        const {
            taxiStore: { setTaxiState, taxiState },
            onboardingStore: {
                exampleRouteStore: { setExampleDestinationTooltipVisible },
            },
        } = this.rootStore.stores;
        Analytics.trackScreenView('Destination');
        this.setSelectedField('destination');
        setExampleDestinationTooltipVisible(false);
        if (taxiState !== 'start') {
            setTaxiState('start');
            return;
        }
        setTimeout(
            () => this.destinationInputRef.current?.focus(),
            Platform.select({ web: 200, default: 0 })
        );
    };

    @action
    showPickup = () => {
        const {
            taxiStore: { setTaxiState, taxiState },
        } = this.rootStore.stores;
        Analytics.trackScreenView('Pickup');

        this.setSelectedField('pickup');
        if (taxiState !== 'start') {
            setTaxiState('start');
            return;
        }
        setTimeout(
            () => this.pickupInputRef.current?.focus(),
            Platform.select({ web: 200, default: 0 })
        );
    };

    getTaxiVenues = () => {
        const {
            stores: {
                taxiStore: {
                    selectedTaxiStore,
                    taxiRideStore: { requestRide },
                },
                appStore: { showLoading, hideLoading },
            },
        } = this.rootStore;

        if (selectedTaxiStore.selectedTaxi.service_provider !== 'LYFT' || this.venueSelected) {
            requestRide();
            return;
        }
        showLoading();
        RestClient.getTaxiVenues(this.pickupLocation.latitude, this.pickupLocation.longitude)
            .then(response => {
                hideLoading();
                if (
                    response.data &&
                    response.data.length &&
                    response.data[0] &&
                    response.data[0].zones &&
                    response.data[0].zones.length
                ) {
                    this.showVenuesPopup = true;
                    this.venues = this.parseVenues(response.data[0].zones);
                } else {
                    requestRide();
                }
            })
            .catch(error => {
                hideLoading();
                handleError(error);
                requestRide();
            });
    };

    parseVenues = venues =>
        venues.reduce((sum, venue) => {
            venue.locations.map(location =>
                sum.push({
                    name: `${venue.name}`,
                    description: `${location.name}`,
                    info: venue.info,
                    location: {
                        latitude: location.latitude,
                        longitude: location.longitude,
                    },
                })
            );
            return sum;
        }, []);

    @action
    selectVenue = (index: number) => {
        const {
            taxiStore: {
                location,
                selectedTaxiStore: { getExactPrice },
            },
        } = this.rootStore.stores;

        const venue = this.venues[index];
        this.setPickupLocation(
            location(venue.name, venue.location.latitude, venue.location.longitude)
        );
        this.setPickup(venue.name);
        this.venueSelected = true;
        getExactPrice();
    };

    @action
    clearVenue = () => {
        this.venueSelected = false;
    };

    @action
    hideVenuesPopup = () => {
        this.showVenuesPopup = false;
    };

    @action
    setCurrentType = (type: string) => {
        this.currentType = type;
    };

    @computed
    get testPickupMarkerProps() {
        return {
            key: 'testPickupMarker',
            zIndex: 1,
            coordinate: this.pickupLocation,
        };
    }

    @computed
    get showPickupDeleteButton(): boolean {
        return this.focusedInput === 'pickup' && !!this.pickup && this.pickup.length > 0;
    }

    @computed
    get showDestinationDeleteButton(): boolean {
        return (
            this.focusedInput === 'destination' && !!this.destination && this.destination.length > 0
        );
    }

    @computed
    get pickupAddressSocketString(): string {
        return this.pickup ? this.pickup.replace('Work: ', '').replace('Home: ', '') : '';
    }

    @computed
    get destinationAddressSocketString(): string {
        return this.destination ? this.destination.replace('Work: ', '').replace('Home: ', '') : '';
    }

    @computed
    get isNewYork(): boolean {
        return (
            this.pickupLocation &&
            distanceBetweenCoords({
                startCoord: this.pickupLocation,
                endCoord: NYC_coords.coords,
                unit: 'MILES',
            }) < NYC_coords.radius
        );
    }
}

import React, { Component } from 'react';
import { View, Image, Text } from 'react-native';
import { observer, inject } from 'mobx-react';

import BikeStore from '../../../stores/BikeStore';
import GradientButton from '../../GradientButton';
import RouteView from './routeView/RouteView';
import BikeDetailsViewStyles from './BikeDetailsViewStyles';
import { bikeProviderInfo } from '../../../services/Utils';
import { Stores } from '../../../stores/RootStore';

interface Props {
    bikeStore: BikeStore;
}

const BikeDetailsView = ({ bikeStore }: Props) => {
    return (
        <View style={BikeDetailsViewStyles.container} pointerEvents="box-none">
            <View style={BikeDetailsViewStyles.innerContainer}>
                <View style={BikeDetailsViewStyles.rowContainer}>
                    <Image
                        resizeMode="contain"
                        source={
                            bikeStore.selectedBike &&
                            bikeProviderInfo(bikeStore.selectedBike.provider).icon
                        }
                        style={BikeDetailsViewStyles.bikeImage}
                    />
                    <View style={BikeDetailsViewStyles.detailsView}>
                        <Text style={BikeDetailsViewStyles.nameText}>
                            {bikeStore.selectedBike && bikeStore.selectedBike.provider}
                        </Text>
                    </View>
                </View>
                <RouteView />
                <GradientButton
                    style={BikeDetailsViewStyles.confirmButton}
                    title="Navigate"
                    handlePress={bikeStore.confirmBike}
                />
            </View>
        </View>
    );
};

export default inject(({ bikeStore }: Stores) => ({ bikeStore }))(observer(BikeDetailsView));

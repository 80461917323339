import { StyleSheet } from 'react-native';
import { Metrics } from '../../../../themes';

export default StyleSheet.create({
    arrowContainer: {
        height: Metrics.normalize(11),
        width: 80,
        zIndex: 4,
        overflow: 'hidden',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: -1,
    },
    arrowInnerContainer: {
        transform: [
            {
                scaleX: 0.5,
            },
        ],
    },
    arrow: {
        width: Metrics.normalize(20),
        height: Metrics.normalize(20),
        backgroundColor: 'white',
        zIndex: 4,
        elevation: 7,
        transform: [{ translateY: Metrics.normalize(-10) }, { rotateZ: '45deg' }],
        shadowColor: 'black',
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.4,
        shadowRadius: 5,
    },
});

import { StyleSheet } from 'react-native';
import { Metrics } from 'themes';

export default StyleSheet.create({
    container: {
        // backgroundColor: 'green',
    },
    scrollView: {
        margin: Metrics.normalize(10),
        backgroundColor: 'blue',
    },
    contentContainer: {
        backgroundColor: 'white',
        paddingVertical: Metrics.normalize(20),
        paddingHorizontal: Metrics.normalize(16),
        borderRadius: Metrics.normalize(16),
    },
});

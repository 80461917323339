import { observable, action, computed, flow } from 'mobx';
import { persist } from 'mobx-persist';

import { BaseStore } from 'stores';
import { RestClient, GoogleClient } from 'services';
import { PersistDataStore, UserLifecycle } from './RootStore';
import { handleError } from 'services/Utils';

type FavType = 'home' | 'work';
// | 'other';

export default class LocationsStore extends BaseStore implements PersistDataStore, UserLifecycle {
    @persist('list')
    @observable
    list: Array<any> = [];

    @persist('object')
    @observable
    favorite: {} = {
        home: null,
        work: null,
        // other: null,
    };

    @persist('list')
    @observable
    favorites: Array<any> = [];

    hydrateStore = (hydrate: Function) => {
        return hydrate('list', this);
    };

    onUserReady = () => {
        this.fetchFavorites();
    };

    @action
    clearStore = () => {
        this.list = [];
        this.favorite = {
            home: null,
            work: null,
            // other: null,
        };
        this.favorites = [];
    };

    @action
    add = (location: any, type?: any) => {
        const newLocation = this.list.find(e => e.id === location.id) || location;

        newLocation.saved = true;
        newLocation.count = newLocation.count ? newLocation.count + 1 : 1;
        if (type) {
            newLocation.type = type;
        }
        const list = this.list.filter(e => e.id !== location.id);
        list.push(newLocation);

        this.list = list.sort((a, b) => {
            const aCount = a.count || 1;
            const bCount = b.count || 1;
            return aCount - bCount;
        });
    };

    addFavorite = flow(function*(
        this: LocationsStore,
        suggestion: any,
        type: any,
        added: Function
    ) {
        try {
            const {
                data: {
                    result: {
                        geometry: {
                            location: { lat, lng },
                        },
                    },
                },
            } = yield GoogleClient.getSuggestionDetails(suggestion.place_id);
            yield RestClient.setDestination(type, suggestion.description, lat, lng);
            this.fetchFavorites(() => added());
        } catch (error) {
            added();
            handleError(error);
        }
    }).bind(this);

    @action
    fetchFavorites = (fetched?: () => {}) => {
        RestClient.getDestinations()
            .then(response => {
                this.favorites = response.data;
                if (fetched) {
                    fetched();
                }
            })
            .catch(() => {
                if (fetched) {
                    fetched();
                }
            });
    };

    @computed
    get home() {
        return this.checkFavorite('home');
    }

    @computed
    get work() {
        return this.checkFavorite('work');
    }

    // @computed
    // get other() {
    //     return this.checkFavorite('other');
    // }

    @computed
    get recent() {
        return this.list;
    }

    @action
    getLocations = (query: string) => {
        const filteredList = this.list
            .filter(
                e =>
                    e.structured_formatting.main_text.toLowerCase().indexOf(query.toLowerCase()) >=
                    0
            )
            .reverse();
        return filteredList;
    };

    @action
    getFilteredFavorite = (type: FavType, address: any) =>
        address.length === 0 ||
        (this.getFavorites[type].location &&
            this.getFavorites[type].location.name.toLowerCase().indexOf(address.toLowerCase()) >= 0)
            ? this.getFavorites[type]
            : null;

    @computed
    get getFavorites() {
        return {
            home: this.checkFavorite('home'),
            work: this.checkFavorite('work'),
            // other: this.checkFavorite('other'),
        };
    }

    checkFavorite = (type: string) => {
        const filteredFavorites = this.favorites.filter(
            favorite => favorite.name && favorite.name === type
        );
        return filteredFavorites.length
            ? filteredFavorites[filteredFavorites.length - 1]
            : { name: type, empty: true };
    };

    @computed
    get favCount() {
        return [
            this.home,
            this.work,
            // this.other
        ].filter(fav => !fav.empty).length;
    }
}

import { StyleSheet } from 'react-native';
import { Fonts, Metrics, Colors } from '../../../../themes';

export default StyleSheet.create({
    addressContainer: {
        flexDirection: 'row',
        marginHorizontal: 6,
        // marginVertical: 6,
        backgroundColor: 'white',
        // height: 100,
        // justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'stretch',
    },
    routeImage: {
        marginHorizontal: 16,
        width: 30,
        justifyContent: 'center',
        alignItems: 'center',
    },
    text: {
        fontFamily: Fonts.type.base,
        fontWeight: '300',
        fontSize: Metrics.normalize(13),
        color: Colors.grayTextColor,
    },
    textPrimary: {
        fontFamily: Fonts.type.base,
        fontWeight: '500',
        fontSize: Metrics.normalize(15),
        color: Colors.darkTextColor,
        width: Metrics.screenWidth / 2,
    },
    timeText: {
        fontFamily: Fonts.type.base,
        fontWeight: '300',
        fontSize: Metrics.normalize(13),
        color: Colors.obiTeal,
        marginRight: 10,
        textAlign: 'left',
    },
    separator: { flex: 1 },
    routeViewContainer: { alignSelf: 'stretch' },
});

import React from 'react';
import { Animated, Text, View, TouchableOpacity } from 'react-native';

import InfoPopupStyles from './styles/InfoPopupStyles';

interface Props {
    text: string;
    animatedValue: Animated.Value;
    onPress: () => void;
}

export default ({ text, animatedValue, onPress }: Props) => (
    <Animated.View
        style={[
            InfoPopupStyles.container,
            {
                transform: [
                    {
                        scaleY: animatedValue.interpolate({
                            inputRange: [0, 100],
                            outputRange: [0, 1],
                        }),
                    },
                ],
            },
        ]}
    >
        <View style={InfoPopupStyles.textView}>
            <Text style={InfoPopupStyles.text}>{text}</Text>
        </View>
        <TouchableOpacity style={InfoPopupStyles.buttonView} onPress={onPress}>
            <Text style={InfoPopupStyles.buttonText}>Ok</Text>
        </TouchableOpacity>
    </Animated.View>
);

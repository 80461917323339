import { View, Text } from 'react-native';

View.fadeOut = props => {};

const Animatable = {
    View,
    Text,
};

export default Animatable;

import { StyleSheet } from 'react-native';
import { Metrics } from 'themes';

export default StyleSheet.create({
    animatedContainer: {
        alignItems: 'flex-end',
        position: 'absolute',
        right: 10,
    },
    container: {
        shadowColor: 'black',
        shadowRadius: 5,
        shadowOpacity: 0.2,
        shadowOffset: { width: 0, height: 0 },
        elevation: 20,
        borderRadius: 9.38,
        padding: 15.625,
        backgroundColor: 'white',
    },
    image: {
        width: 18.75,
        height: 18.75,
    },
});

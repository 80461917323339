import React from 'react';
import { View, Text, Image } from 'react-native';
import { observer, inject } from 'mobx-react';

import RouteViewStyles from './styles/RouteViewStyles';
import TaxiStore from '../../stores/TaxiStore';
import { Images } from '../../themes';
import { Localizable } from '../../lib';
import { Stores } from '../../stores/RootStore';
import { AddressStore } from 'stores';

interface Props {
    addressStore: AddressStore;
    taxiStore: TaxiStore;
}

export default inject(({ addressStore, taxiStore }: Stores) => ({
    addressStore,
    taxiStore,
}))(
    observer(({ addressStore, taxiStore, taxiStore: { selectedTaxiStore } }: Props) => {
        return (
            <View style={RouteViewStyles.addressContainer}>
                <Image
                    source={Images.icons.route}
                    style={RouteViewStyles.routeImage}
                    resizeMode="contain"
                />
                <View style={RouteViewStyles.addressView}>
                    <View style={RouteViewStyles.addressInnerView}>
                        <Text style={RouteViewStyles.addressValueText}>
                            {Localizable.t('taxis.routeView.pickup').toUpperCase()}
                        </Text>
                        <Text style={RouteViewStyles.addressText} numberOfLines={1}>
                            {addressStore.pickup}
                        </Text>
                    </View>
                    <View style={RouteViewStyles.addressInnerView}>
                        <Text style={RouteViewStyles.addressValueText}>
                            {Localizable.t('taxis.routeView.destination').toUpperCase()}
                        </Text>
                        <Text style={RouteViewStyles.addressText} numberOfLines={1}>
                            {addressStore.destination}
                        </Text>
                    </View>
                </View>
                <View style={RouteViewStyles.deeplinkContainer}>
                    <View style={RouteViewStyles.deeplinkInnerContainer}>
                        <Text style={RouteViewStyles.timeText} numberOfLines={1}>
                            {Localizable.t('taxis.routeView.pickupIn').toUpperCase()}
                        </Text>
                        <Text style={RouteViewStyles.timeValueText}>
                            {selectedTaxiStore.nearbyEta} {Localizable.t('taxis.routeView.minutes')}
                        </Text>
                    </View>
                    {(selectedTaxiStore.selectedTaxi.service_provider !== 'OLA' && (
                        <View style={RouteViewStyles.deeplinkInnerContainer}>
                            <Text style={RouteViewStyles.timeText} numberOfLines={1}>
                                {Localizable.t('taxis.routeView.arriveAt').toUpperCase()}
                            </Text>
                            <Text style={RouteViewStyles.timeValueText}>
                                {taxiStore.arrivalTime}
                            </Text>
                        </View>
                    )) || <View style={RouteViewStyles.deeplinkContainer} />}
                </View>
            </View>
        );
    })
);

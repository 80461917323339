import { StyleSheet } from 'react-native';
import { Fonts, Metrics, Colors } from '../../../themes';

export default StyleSheet.create({
    outerContainer: { flex: 1, backgroundColor: 'white' },
    container: {
        flex: 1,
    },
    contentView: {
        padding: 16,
    },
    headerText: {
        fontFamily: Fonts.type.base,
        fontWeight: '400',
        fontSize: Metrics.normalize(14),
        color: Colors.grayTextColor,
        marginBottom: 3,
    },
    separator: {
        backgroundColor: Colors.grayTextColor,
        height: 1,
        opacity: 0.2,
        marginVertical: 14,
    },
    typeView: {
        marginVertical: 8,
        borderRadius: 5,
        paddingVertical: 8,
        paddingHorizontal: 8,
        justifyContent: 'center',
        alignItems: 'center',
    },
    typeText: {
        fontFamily: Fonts.type.base,
        fontWeight: '500',
        fontSize: Metrics.normalize(11),
        color: 'white',
    },
    flexRow: { flexDirection: 'row' },
    flexOne: { flex: 1 },
    gradientButton: { marginTop: 30 },
});

import React, { PureComponent } from 'react';
import { SceneView, createNavigator, StackRouter } from '@react-navigation/core';

import { Profile, FavoriteAddressPicker } from 'containers/profile';
import { AdditionalInfo, CountryPicker } from 'containers/setup';

interface ProfileNavigatorViewProps {
    navigation: any;
    descriptors: any;
}

class ProfileNavigatorView extends PureComponent<ProfileNavigatorViewProps> {
    render() {
        const {
            descriptors,
            navigation: { state, screenProps },
        } = this.props;
        const { getComponent, navigation } = descriptors[state.routes[state.index].key];

        return (
            <SceneView
                style={{ flex: 1 }}
                component={getComponent()}
                navigation={navigation}
                screenProps={screenProps}
            />
        );
    }
}

const ProfileNavigator = createNavigator(
    ProfileNavigatorView,
    StackRouter(
        {
            Profile: {
                name: 'Profile',
                screen: Profile,
                path: '',
            },
            FavoriteAddressPicker: {
                name: 'FavoriteAddressPicker',
                screen: FavoriteAddressPicker,
            },
            AdditionalInfo: {
                name: 'AdditionalInfo',
                screen: AdditionalInfo,
            },
            CountryPicker: {
                name: 'CountryPicker',
                screen: CountryPicker,
            },
        },
        {}
    ),
    { navigationOptions: {} }
);

export default ProfileNavigator;

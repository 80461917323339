import { StyleSheet } from 'react-native';
import { Colors, Metrics, Fonts } from '../../../../themes';

export default StyleSheet.create({
    container: {
        marginHorizontal: 16,
        marginVertical: 6,
        borderRadius: 6,
        shadowColor: 'black',
        shadowRadius: 2,
        shadowOpacity: 0.2,
        shadowOffset: { width: 0, height: 0 },
        elevation: 2,
        flexDirection: 'row',
    },
    innerContainer: {
        backgroundColor: 'white',
        borderTopEndRadius: 8,
        borderBottomEndRadius: 8,
        flexDirection: 'row',
        padding: 6,
        flex: 1,
    },
    imageView: {
        alignItems: 'center',
        justifyContent: 'center',
        padding: 10,
        borderRadius: 6,
    },
    image: {
        width: 30,
        height: 30,
    },
    textView: {
        marginLeft: 10,
        flex: 1,
    },
    nameText: {
        fontFamily: Fonts.type.base,
        marginTop: 4,
        fontWeight: '700',
        fontSize: Metrics.normalize(16),
        color: Colors.black,
    },
    serviceText: {
        fontFamily: Fonts.type.base,
        marginTop: 6,
        fontWeight: '400',
        fontSize: Metrics.normalize(12),
        color: Colors.grayTextColor,
    },
    rightContainer: {
        width: 56,
        padding: 10,
        alignItems: 'center',
        justifyContent: 'center',
    },
    rightContainerImage: { width: 20, height: 20 },
});

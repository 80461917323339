export interface TypeNameItem {
    name: string;
}

export interface TypeName {
    type: string;
    name: string;
}

const isStandard = (item: any): boolean =>
    !isShared(item) &&
    !isXL(item) &&
    !isCarSeat(item) &&
    (item.service_level === 'Taxi' || item.service_level === 'Standard');

const isXL = (item: any): boolean =>
    item.provider.company.toLowerCase().includes('xl') ||
    item.provider.company.toLowerCase().includes('suv') ||
    item.service_level === 'XL';

const isShared = (item: any): boolean =>
    (item.shared || item.service_level === 'Pool') && !isXL(item);

const isLuxury = (item: any): boolean =>
    !isShared(item) && !isXL(item) && item.service_level === 'Luxury';

const isWAV = (item: any): boolean => item.service_level === 'WAV';

const isCarSeat = (item: any): boolean =>
    item.provider.company.toLowerCase().includes('seat') ||
    item.provider.company.toLowerCase().includes('car seat');

export const defaultTaxiServiceTypes: any = {
    standard: {
        name: 'Standard',
        func: isStandard,
    },
    shared: {
        name: 'Shared/Pool',
        func: isShared,
    },
    carSeat: {
        name: 'Car Seat',
        func: isCarSeat,
    },
    xl: {
        name: 'XL (4+ seats)',
        func: isXL,
    },
    luxury: {
        name: 'Luxury/Premium',
        func: isLuxury,
    },
    WAV: {
        name: 'Wheelchair accessible',
        func: isWAV,
    },
};

export const defaultProviders: TypeName[] = [
    {
        type: 'UBER',
        name: 'Uber',
    },
    {
        type: 'LYFT',
        name: 'Lyft',
    },
    {
        type: 'JUNO',
        name: 'Juno',
    },
    {
        type: 'CURB',
        name: 'Curb',
    },
    {
        type: 'FLYWHEEL',
        name: 'Flywheel',
    },
    {
        type: 'TAXIFY',
        name: 'Bolt',
    },
    {
        type: 'ARRO',
        name: 'Arro',
    },
    {
        type: 'OLA',
        name: 'Ola',
    },
    {
        type: 'MYTAXI',
        name: 'Free Now',
    },
    {
        type: 'EASYTAXI',
        name: 'Easytaxi',
    },
    {
        type: 'WAAVE',
        name: 'Waave',
    },
    {
        type: 'LECAB',
        name: 'Le Cab',
    },
    {
        type: 'ADDISON_LEE',
        name: 'Addison Lee',
    },
    {
        type: 'CARMEL',
        name: 'Carmel',
    },
    {
        type: 'VIA',
        name: 'Via',
    },
    {
        type: 'KABBEE',
        name: 'Kabbee',
    },
    {
        type: 'KAPTEN',
        name: 'Kapten',
    },
    {
        type: 'GETT',
        name: 'Gett',
    },
    {
        type: 'RIDE_AUSTIN',
        name: 'Ride Austin',
    },
    {
        type: 'GRAB',
        name: 'Grab',
    },
    {
        type: 'GOJEK',
        name: 'Gojek',
    },
    {
        type: 'DIDI',
        name: 'DiDi',
    },
    {
        type: 'YANDEX',
        name: 'Yandex',
    },
    {
        type: 'TAXIS99',
        name: 'Taxis99',
    },
    {
        type: 'BEAT',
        name: 'Beat',
    },
    {
        type: 'ARECIBO',
        name: 'Arecibo',
    },
];

export { isStandard, isShared, isXL, isLuxury, isCarSeat, isWAV };

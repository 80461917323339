import { StyleSheet } from 'react-native';
import { Metrics, Fonts, Colors } from 'themes';

export default StyleSheet.create({
    scrollView: {
        paddingTop: Metrics.paddingTop + 40,
        paddingBottom: Metrics.paddingBottom + (Metrics.paddingBottom === 0 ? 40 : 0),
    },
    container: {
        flex: 1,
    },
    topView: {
        flex: 2.5,
        alignSelf: 'stretch',
        alignItems: 'center',
        justifyContent: 'space-evenly',
    },
    title: {
        textAlign: 'center',
        fontFamily: Fonts.type.base,
        fontWeight: '700',
        color: Colors.black,
        fontSize: Metrics.normalize(26),
    },
    image: {
        width: Metrics.screenWidth - 100,
    },
    buttonsView: {
        flex: 1,
        alignSelf: 'stretch',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    exampleButton: {
        alignSelf: 'stretch',
        alignItems: 'center',
        justifyContent: 'center',
        marginHorizontal: 35,
        paddingVertical: 15,
        borderRadius: 10,
        borderWidth: 1,
        backgroundColor: Colors.obiTeal,
        borderColor: Colors.obiTeal,
    },
    exampleButtonText: {
        fontFamily: Fonts.type.base,
        fontWeight: '300',
        color: 'white',
        fontSize: Metrics.normalize(16),
    },
    bottomView: {
        marginTop: 10,
        alignSelf: 'stretch',
        alignItems: 'center',
        justifyContent: 'center',
    },
    horizontalLine: {
        height: 0.5,
        borderRadius: 100,
        alignSelf: 'stretch',
        backgroundColor: Colors.grayTextColor,
    },
    termsView: {
        paddingTop: 20,
        paddingBottom: Metrics.paddingBottom + (Metrics.paddingBottom === 0 ? 10 : 0),
        paddingHorizontal: 50,
        alignItems: 'center',
        justifyContent: 'center',
    },
    termsText: {
        textAlign: 'center',
        fontFamily: Fonts.type.base,
        fontWeight: '400',
        color: Colors.grayTextColor,
        fontSize: Metrics.normalize(13),
    },
    termsUnderlinedText: {
        textDecorationLine: 'underline',
        fontWeight: '500',
    },
});

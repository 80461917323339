import { StyleSheet, Platform } from 'react-native';
import { Fonts, Metrics, Colors } from '../../../themes';

export default StyleSheet.create({
    container: {
        position: 'absolute',
        top: Platform.OS === 'ios' ? Metrics.paddingTop + 10 : 10,
        left: 10,
        right: 10,
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: 'transparent',
    },
    middleView: {
        flex: 1,
    },
    iconView: {
        height: 60,
        width: 60,
        alignItems: 'center',
        justifyContent: 'center',
    },
    iconBackground: {
        width: 40,
        height: 40,
        borderRadius: 20,
        backgroundColor: 'white',
        alignItems: 'center',
        justifyContent: 'center',
        shadowColor: 'black',
        shadowRadius: 5,
        shadowOpacity: 0.2,
        shadowOffset: { width: 0, height: 0 },
        elevation: 10,
    },
    iconInnerBackground: {
        width: 35,
        height: 35,
        borderRadius: 17.5,
        backgroundColor: Colors.darkPurple,
        alignItems: 'center',
        justifyContent: 'center',
    },
    icon: {
        width: 18,
        height: 18,
    },
});

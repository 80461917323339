import { StyleSheet } from 'react-native';
import { Fonts, Metrics, Colors } from '../../../../themes';

export default StyleSheet.create({
    addressContainer: {
        flexDirection: 'row',
        marginHorizontal: 6,
        marginVertical: 6,
        backgroundColor: 'white',
        height: 100,
    },
    routeImage: {
        height: 70,
        width: 30,
        marginRight: 16,
        marginVertical: 12,
    },
    innerContainer: {
        flex: 2,
        marginRight: 20,
    },
    innerContainerView: {
        flex: 1,
        justifyContent: 'center',
    },
    addressValueText: {
        fontFamily: Fonts.type.base,
        fontWeight: '300',
        fontSize: Metrics.normalize(13),
        color: Colors.grayTextColor,
    },
    addressText: {
        fontFamily: Fonts.type.base,
        fontWeight: '500',
        fontSize: Metrics.normalize(15),
        color: Colors.darkTextColor,
        marginBottom: 3,
        width: Metrics.screenWidth / 2,
    },
    timeText: {
        fontFamily: Fonts.type.base,
        fontWeight: '300',
        fontSize: Metrics.normalize(13),
        color: Colors.grayTextColor,
        marginBottom: 3,
    },
    timeValueText: {
        fontFamily: Fonts.type.base,
        fontWeight: '500',
        fontSize: Metrics.normalize(15),
        color: Colors.darkTextColor,
    },
});

import React from 'react';
import { observer, inject } from 'mobx-react';

import BikeStore from '../../../../stores/BikeStore';
import CustomMarker from '../../../taxis/Marker';
import TaxiStore from '../../../../stores/TaxiStore';
import { RidePopup } from '../../../taxis';
import { Stores } from '../../../../stores/RootStore';

interface Props {
    taxiStore: TaxiStore;
}

export default inject(({ taxiStore }: Stores) => ({ taxiStore }))(
    observer(({ taxiStore }: Props) => {
        const { taxiRouteStore, taxiRideStore } = taxiStore;
        if (!taxiRouteStore.showDestinationMarker) {
            return null;
        }
        return (
            <CustomMarker
                anchor={{ x: 0, y: taxiRouteStore.markersOrderLat ? 1 : 0 }}
                key="destination"
                zIndex={11}
                coordinate={taxiRideStore.currentRide.destination}
            >
                <RidePopup
                    title={taxiRouteStore.destinationMarkerDescription}
                    duration={taxiStore.currentEta}
                />
            </CustomMarker>
        );
    })
);

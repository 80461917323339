import { observable, action } from 'mobx';
import { Platform } from 'react-native';
import platform from 'platform';

import BaseStore from './BaseStore';

export default class LayoutStore extends BaseStore {
    @observable screenHeight = 0;

    @observable screenWidth = 0;

    @observable layoutReady = false;

    @action
    onAppLayout = ({
        nativeEvent: {
            layout: { width, height },
        },
    }) => {
        if (height > this.screenHeight) {
            this.screenHeight = height;
        }
        this.screenWidth = width;
        this.layoutReady = true;
    };
}

import React from 'react';
import { View, ActivityIndicator } from 'react-native';
import { inject, observer } from 'mobx-react';

import AppStore from '../stores/AppStore';
import LoadingStyles from './styles/LoadingStyles';
import { Stores } from '../stores/RootStore';

interface Props {
    appStore: AppStore;
}

export default inject(({ appStore }: Stores) => ({ appStore }))(
    observer(({ appStore }: Props) => {
        if (appStore.loading) {
            return (
                <View style={LoadingStyles.container}>
                    <ActivityIndicator size="large" />
                </View>
            );
        }

        return null;
    })
);

import { observable, action, computed } from 'mobx';

import BaseStore from '../BaseStore';
import Prediction from 'domain/app/Prediction';

export default class PredictionStore extends BaseStore {
    @observable
    predictions: Prediction[] = [];

    @observable
    prevPredictions: Prediction[] = [];

    @observable
    predictionsScreenShow = false;

    @observable
    predictionsTooltipShow = true;

    @observable
    preferedPredictionsPeriod = 5;

    @computed
    get estimate() {
        return (this.predictions.length && this.predictions[0].prediction) || null;
    }

    @computed
    get tooltipShow() {
        return this.estimate !== null && this.predictionsTooltipShow;
    }

    @action
    togglePredictionsScreen = (show: boolean) => {
        this.predictionsScreenShow = show;
    };

    @action
    setPredictions = (predictions: Prediction[]) => {
        this.prevPredictions = this.predictions;
        this.predictions = predictions;
    };

    @action
    togglePredictionsTooltip = (show: boolean) => {
        this.predictionsTooltipShow = show;
    };

    @action
    setPreferedPredictionsPeriod = (period: number) => {
        this.preferedPredictionsPeriod = period;
    };
}

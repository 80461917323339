import { StyleSheet } from 'react-native';
import { Colors, Fonts, Metrics } from 'themes';

export default StyleSheet.create({
    newProviderView: {
        width: 40,
        backgroundColor: Colors.transparent,
        paddingVertical: 2,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 5,
        borderWidth: 1,
        borderColor: '#ec7429',
    },
    newProviderText: {
        fontFamily: Fonts.type.base,
        fontWeight: '400',
        fontSize: Metrics.normalize(9),
        color: '#ec7429',
    },
});

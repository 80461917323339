import React, { Component } from 'react';
import {
    View,
    Text,
    SectionList,
    Keyboard,
    ViewStyle,
    StyleProp,
    EmitterSubscription,
} from 'react-native';
import { observer } from 'mobx-react';
import { observable, action } from 'mobx';

import AddressPickerStyles from './styles/AddressPickerStyles';
import KeyTitleData from 'domain/app/KeyTitleData';
import SuggestionsListItem from './SuggestionsListItem';
import SuggestionsListItemUseLocation from './SuggestionsListItemUseLocation';
import SuggestionsListItemSetFavorite from './SuggestionsListItemSetFavorite';
import KeyboardStore from 'stores/subsidiaryStores/KeyboardStore';

interface Props {
    suggestionSelected: Function;
    sections: KeyTitleData[];
    style?: StyleProp<ViewStyle>;
    extraData?: any;
}

@observer
export default class SuggestionsListView extends Component<Props> {
    keyboardStore = new KeyboardStore();

    keyboardDidShowListener?: EmitterSubscription;

    keyboardDidHideListener?: EmitterSubscription;

    constructor(props: Props) {
        super(props);
        this.keyboardStore = new KeyboardStore();
    }

    componentDidMount() {
        const { onKeyboardShow, onKeyboardHide } = this.keyboardStore;
        this.keyboardDidShowListener = Keyboard.addListener('keyboardDidShow', onKeyboardShow);
        this.keyboardDidHideListener = Keyboard.addListener('keyboardDidHide', onKeyboardHide);
    }

    componentWillUnmount() {
        if (this.keyboardDidShowListener) {
            this.keyboardDidShowListener.remove();
        }
        if (this.keyboardDidHideListener) {
            this.keyboardDidHideListener.remove();
        }
    }

    renderHeader = ({ section }) => {
        const key = section.key || 'empty';
        const text =
            section.data && section.data.length && section.title && section.title.toUpperCase();
        return (
            <View key={key}>
                {(text && (
                    <Text allowFontScaling={false} style={AddressPickerStyles.headerText} key={key}>
                        {text}
                    </Text>
                )) ||
                    null}
            </View>
        );
    };

    renderSuggestion = ({ item, index }: { item: any; index: number }) => {
        const { suggestionSelected } = this.props;
        if (item.current || item.select) {
            return (
                <SuggestionsListItemUseLocation
                    item={item}
                    index={index}
                    suggestionSelected={suggestionSelected}
                />
            );
        }
        if (item.empty && item.name) {
            return (
                <SuggestionsListItemSetFavorite
                    item={item}
                    index={index}
                    suggestionSelected={suggestionSelected}
                />
            );
        }
        return (
            <SuggestionsListItem
                item={item}
                index={index}
                suggestionSelected={suggestionSelected}
            />
        );
    };

    render() {
        const { style, sections } = this.props;
        const { keyboardShown, keyboardHeight } = this.keyboardStore;
        return (
            <SectionList
                bounces={false}
                testID="list_suggestions"
                contentContainerStyle={{
                    paddingBottom: keyboardShown ? keyboardHeight : 0,
                }}
                style={style}
                keyboardShouldPersistTaps="always"
                renderSectionHeader={this.renderHeader}
                renderItem={this.renderSuggestion}
                sections={sections}
                keyExtractor={(item, index) => `key-${index}`}
            />
        );
    }
}

import { StyleSheet } from 'react-native';
import { Colors, Metrics, Fonts } from 'themes';

export default StyleSheet.create({
    container: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
    },
    innerContainer: {
        // height: 300,
        backgroundColor: 'white',
        borderTopLeftRadius: 16,
        borderTopRightRadius: 16,
        shadowColor: '#000000',
        shadowOffset: { width: 0, height: 0 },
        shadowRadius: 4,
        shadowOpacity: 0.2,
        alignContent: 'center',
    },
    changePredictionPossibleView: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 30,
        marginBottom: 20,
    },
    changePredictionPossibleText: {
        color: Colors.darkTextColor,
        marginLeft: 10,
        fontFamily: Fonts.type.base,
        fontSize: Metrics.normalize(13),
        fontWeight: '500',
    },
    changePredictionText: {
        marginHorizontal: 22,
        marginBottom: 15,
        color: 'black',
        fontSize: Metrics.normalize(18),
        fontWeight: '600',
        textAlign: 'center',
        fontFamily: Fonts.type.base,
    },
    togglePredictionButton: {
        marginHorizontal: 15,
        marginVertical: 10,
    },
    hidePredictionsText: {
        marginHorizontal: 15,
        marginVertical: 10,
        marginBottom: 20,
        textAlign: 'center',
        color: Colors.obiTeal,
        fontWeight: '500',
        fontSize: Metrics.normalize(15),
        fontFamily: Fonts.type.base,
    },
    // background: {
    //     position: 'absolute',
    //     top: 0,
    //     left: 0,
    //     right: 0,
    //     bottom: 0,
    //     backgroundColor: 'black',
    // },
    // rowContainer: {
    //     flexDirection: 'row',
    //     justifyContent: 'center',
    //     backgroundColor: 'white',
    //     paddingHorizontal: 12,
    //     height: 65,
    //     shadowColor: 'black',
    //     alignItems: 'center',
    // },
    // firstItemContainer: {
    //     backgroundColor: 'white',
    //     marginHorizontal: 4,
    //     shadowColor: 'black',
    //     borderColor: Colors.destinationColor,
    //     borderRadius: 10,
    //     borderWidth: 2,
    //     overflow: 'hidden',
    // },
    // rowProviderView: {
    //     width: 35,
    //     height: 35,
    //     marginRight: 20,
    //     borderRadius: 17.5,
    //     shadowColor: 'black',
    //     shadowRadius: 4,
    //     shadowOpacity: 0.4,
    //     shadowOffset: { width: 0, height: 0 },
    //     elevation: 2,
    // },
    // rowProviderIcon: {
    //     width: 35,
    //     height: 35,
    //     borderRadius: 17.5,
    // },
    // rowCompanyText: {
    //     fontFamily: Fonts.type.base,
    //     fontSize: Metrics.normalize(17),
    //     fontWeight: '600',
    //     color: Colors.darkTextColor,
    //     marginBottom: 4,
    // },
    // rowEtaIcon: {
    //     width: 10,
    //     height: 10,
    //     opacity: 0.6,
    //     marginRight: 4,
    // },
    // rowValueText: {
    //     fontFamily: Fonts.type.base,
    //     fontSize: Metrics.normalize(11),
    //     fontWeight: '300',
    //     color: Colors.grayTextColor,
    // },
    // smallRowValueText: {
    //     fontFamily: Fonts.type.base,
    //     fontSize: Metrics.normalize(9),
    //     fontWeight: '400',
    //     color: Colors.grayTextColor,
    //     marginRight: 4,
    // },
    // rowEstimateText: {
    //     fontFamily: Fonts.type.base,
    //     fontSize: 18,
    //     fontWeight: '700',
    //     color: Colors.darkTextColor,
    // },
    // typeTitleText: {
    //     fontFamily: Fonts.type.base,
    //     fontWeight: '700',
    //     fontSize: Metrics.normalize(16),
    //     backgroundColor: 'transparent',
    // },
    // typeIndicator: {
    //     position: 'absolute',
    //     left: 0,
    //     right: 0,
    //     bottom: 0,
    //     height: 3,
    //     backgroundColor: Colors.obiTeal,
    // },
    // rowEtaDestinationText: {
    //     fontFamily: Fonts.type.base,
    //     fontSize: Metrics.normalize(16),
    //     fontWeight: '700',
    //     color: Colors.darkGreyTextColor,
    //     textAlign: 'right',
    // },
    // valueContainer: {
    //     flex: 3,
    //     justifyContent: 'center',
    //     alignItems: 'center',
    // },
    // typesSeparator: {
    //     backgroundColor: Colors.grayTextColor,
    //     width: 1,
    //     opacity: 0.2,
    //     height: 30,
    //     marginHorizontal: 5,
    // },
    // opensInAppText: {
    //     fontFamily: Fonts.type.base,
    //     fontSize: Metrics.normalize(7),
    //     fontWeight: '400',
    //     color: Colors.grayTextColor,
    //     marginRight: 3,
    // },
    // bestValueHeader: {
    //     padding: 8,
    //     backgroundColor: Colors.destinationColor,
    //     flex: 1,
    //     flexDirection: 'row',
    //     alignItems: 'center',
    // },
    // bestValueText: {
    //     fontFamily: Fonts.type.base,
    //     fontSize: Metrics.normalize(12),
    //     fontWeight: '700',
    //     color: 'white',
    //     marginLeft: 6,
    // },
    // bookNowText: {
    //     fontFamily: Fonts.type.base,
    //     fontSize: Metrics.normalize(10),
    //     fontWeight: '900',
    //     color: 'white',
    //     marginRight: 5,
    // },
    // bestValueSignalBarsIcon: {
    //     height: Metrics.normalize(12),
    //     width: Metrics.normalize(12),
    //     marginLeft: 5,
    // },
    // bestValueBookNowIcon: {
    //     width: Metrics.normalize(10),
    //     height: Metrics.normalize(10),
    //     marginRight: 5,
    // },
});

import React, { Component } from 'react';
import moment from 'moment';
import { Image, View, Text, FlatList, TouchableOpacity } from 'react-native';
import { NavigationParams } from 'react-navigation';
import { observer, inject } from 'mobx-react';

import getEnv from 'modules/config/config';
import TitleNavigationBar from 'components/navigationBars/TitleNavigationBar';

import { Colors, Images, Fonts } from '../../themes';
import { Analytics, Localizable } from '../../lib';
import RideHistoryStyles from './styles/RideHistoryStyles';
import RideHistoryStore from '../../stores/RideHistoryStore';
import { distanceBetweenCoords } from '../../services/Utils';
import AuthStore from '../../stores/AuthStore';
import AppStore from '../../stores/AppStore';
import { Stores } from '../../stores/RootStore';
import NavigationStore from 'stores/navigationStores/NavigationStore';

interface Props {
    authStore: AuthStore;
    appStore: AppStore;
    navigation: NavigationParams;
    navigationStore: NavigationStore;
}

@inject(({ appStore, authStore, navigationStore }: Stores) => ({
    appStore,
    authStore,
    navigationStore,
}))
@observer
export default class RideHistory extends Component<Props, {}> {
    rideHistoryStore: RideHistoryStore;

    constructor(props: Props) {
        super(props);
        this.rideHistoryStore = new RideHistoryStore();
    }
    componentDidMount() {
        this.rideHistoryStore.getRides();
    }

    navigateToReceipt = item => {
        const {
            navigationStore: { navigate },
        } = this.props;
        navigate('RideReceipt', { rideItem: item });
    };

    renderRow = ({ item }) => {
        return (
            <TouchableOpacity key={item.ask.schedule} onPress={() => this.navigateToReceipt(item)}>
                <View style={RideHistoryStyles.rowView}>
                    <View style={RideHistoryStyles.mapView}>
                        <Image
                            style={RideHistoryStyles.flexOne}
                            source={{
                                uri: `https://maps.googleapis.com/maps/api/staticmap?center=${
                                    item.destination.latitude
                                },${
                                    item.destination.longitude
                                }&zoom=13&size=600x300&maptype=roadmap&key=${getEnv(
                                    'GOOGLE_API_KEY'
                                )}`,
                            }}
                        />
                    </View>
                    <View style={RideHistoryStyles.infoView}>
                        <View style={RideHistoryStyles.addressView}>
                            <View
                                style={[
                                    RideHistoryStyles.addressDot,
                                    { backgroundColor: Colors.darkPurple },
                                ]}
                            />
                            <Text style={RideHistoryStyles.pickupText}>
                                {item.ask.pickup_address}
                            </Text>
                        </View>

                        <View style={RideHistoryStyles.addressView}>
                            <View
                                style={[
                                    RideHistoryStyles.addressDot,
                                    { backgroundColor: Colors.destinationColor },
                                ]}
                            />
                            <Text style={RideHistoryStyles.destinationText}>
                                {item.ask.destination_address}
                            </Text>
                        </View>
                        <View style={RideHistoryStyles.separator} />
                        <View style={RideHistoryStyles.flexRow}>
                            <View style={RideHistoryStyles.cellView}>
                                <Image
                                    resizeMode="contain"
                                    style={RideHistoryStyles.cellImage}
                                    source={Images.icons.rideDate}
                                />
                                <View>
                                    <Text style={RideHistoryStyles.cellTitleText}>
                                        {Localizable.t('history.tripDate')}
                                    </Text>
                                    <Text style={RideHistoryStyles.cellValueText}>
                                        {moment(
                                            item.ask.schedule,
                                            "YYYY-MM-DD'T'HH:mm:ss.SSS'Z'"
                                        ).format('MM/DD/YY')}
                                    </Text>
                                </View>
                                <View style={RideHistoryStyles.cellSeparator} />
                            </View>
                            <View style={[RideHistoryStyles.cellView, { flex: 3 }]}>
                                <Image
                                    resizeMode="contain"
                                    style={RideHistoryStyles.cellImage}
                                    source={Images.icons.rideDistance}
                                />
                                <View>
                                    <Text style={RideHistoryStyles.cellTitleText}>
                                        {Localizable.t('history.tripDistance')}
                                    </Text>
                                    <Text style={RideHistoryStyles.cellValueText}>
                                        {distanceBetweenCoords({
                                            startCoord: item.pickup,
                                            endCoord: item.destination,
                                            unit: 'MILES',
                                        })}{' '}
                                        mi
                                    </Text>
                                </View>
                                <View style={RideHistoryStyles.cellSeparator} />
                            </View>
                            <View style={RideHistoryStyles.cellView}>
                                <Image
                                    resizeMode="contain"
                                    style={RideHistoryStyles.cellImage}
                                    source={Images.icons.rideCost}
                                />
                                <View>
                                    <Text style={RideHistoryStyles.cellTitleText}>
                                        {Localizable.t('history.tripFare')}
                                    </Text>
                                    <Text
                                        style={[
                                            RideHistoryStyles.cellValueText,
                                            { color: Colors.destinationColor },
                                        ]}
                                    >
                                        {item.offer.rate}
                                        {item.fee}
                                    </Text>
                                </View>
                            </View>
                        </View>
                    </View>
                </View>
            </TouchableOpacity>
        );
    };

    renderEmpty = () =>
        (this.rideHistoryStore.refreshing && <View />) || (
            <Text style={RideHistoryStyles.renderEmpty}>{Localizable.t('taxi.emptyHistory')}</Text>
        );

    render() {
        const {
            appStore,
            authStore,
            navigationStore: { goBack },
        } = this.props;
        return (
            <View style={RideHistoryStyles.container}>
                <TitleNavigationBar
                    authStore={authStore}
                    appStore={appStore}
                    title={Localizable.t('history.title')}
                    handleBackClick={() => goBack()}
                />
                <FlatList
                    refreshing={this.rideHistoryStore.refreshing}
                    onRefresh={this.rideHistoryStore.getRides}
                    keyExtractor={item => item.ask.schedule}
                    data={this.rideHistoryStore.rides}
                    renderItem={this.renderRow}
                    ListEmptyComponent={this.renderEmpty}
                />
            </View>
        );
    }
}

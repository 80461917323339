/* eslint-disable camelcase */
/* eslint-disable no-empty */
import * as Localize from 'react-native-localize';
import { Platform, Linking } from 'react-native';
import { observable, action, flow } from 'mobx';
import { isValidNumber, AsYouType, parse, getPhoneCode } from 'libphonenumber-js';

import ServicesStore from '../ServicesStore';
import countryCodes from '../../lib/flags';
import CurbClient from 'services/providers/CurbClient';
import { Analytics } from '../../lib';

export default class CurbServiceStore {
    servicesRootStore: ServicesStore;

    constructor(servicesStore: ServicesStore) {
        this.servicesRootStore = servicesStore;
        this.getCarrierInfo();
    }

    @observable phoneNumber: string = '';

    @observable countryCode: any = '1';

    @observable countryName: any = 'us';

    @observable asYouType: AsYouType = new AsYouType(this.countryName.toUpperCase());

    @observable validPhoneNumber: boolean = false;

    @observable firstName: string = '';

    @observable lastName: string = '';

    @observable email: string = '';

    @observable password: string = '';

    @observable errorMessage: string = '';

    @action
    clearStore = () => {
        const { setRefreshing } = this.servicesRootStore;
        this.getCarrierInfo();
        this.firstName = '';
        this.lastName = '';
        this.asYouType = new AsYouType(this.countryName.toUpperCase());
        this.email = '';
        this.password = '';
        this.errorMessage = '';
        setRefreshing(false);
    };

    @action
    clearError = () => {
        this.errorMessage = '';
    };

    @action
    getCarrierInfo = () => {
        let countryCode = 'us';
        try {
            countryCode = Localize.getCountry();
        } catch (error) {}
        const codes = countryCodes.filter(
            item => item.code.toLowerCase() === countryCode.toLowerCase()
        );
        if (codes.length > 0) {
            this.countryName = codes[0].code.toLowerCase();
            this.countryCode = codes[0].dial_code.replace(/\D/g, '');
        }
    };

    @action
    selectCountry = country => {
        this.countryCode = country.dial_code.replace(/\D/g, '');
        this.countryName = country.code.toLowerCase();
        this.asYouType = new AsYouType(this.countryName.toUpperCase());
        this.clearError();
    };

    @action
    setCurbPhoneNumber = (text: string) => {
        this.asYouType.reset();
        if (this.phoneNumber.length - text.length === 1 && this.phoneNumber.slice(-1) === ')') {
            this.phoneNumber = text.replace('(', '');
        } else {
            this.phoneNumber = this.asYouType.input(text);
        }
        this.validPhoneNumber = isValidNumber(text, this.countryName.toUpperCase());
        this.clearError();
    };

    @action
    setCurbFirstName = (text: string) => {
        this.firstName = text;
        this.clearError();
    };

    @action
    setCurbLastName = (text: string) => {
        this.lastName = text;
        this.clearError();
    };

    @action
    setCurbEmail = (text: string) => {
        this.email = text;
        this.clearError();
    };

    @action
    setCurbPassword = (text: string) => {
        this.password = text;
        this.clearError();
    };

    onCurbConnectingCredentialSubmit = flow(function*(this: CurbServiceStore) {
        const { setRefreshing, handleCurbLogin } = this.servicesRootStore;
        try {
            setRefreshing(true);
            yield handleCurbLogin(this.email, this.password);
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                this.errorMessage = error.response.data.message;
            } else {
                this.errorMessage = 'Account linking failed.';
            }
        } finally {
            setRefreshing(false);
        }
    }).bind(this);

    onCurbRegisterCredentialsSubmit = flow(function*(this: CurbServiceStore) {
        const { setRefreshing, hideProviderPopup } = this.servicesRootStore;
        try {
            setRefreshing(true);
            yield CurbClient.register({
                first_name: this.firstName,
                last_name: this.lastName,
                email: this.email,
                password: this.password,
                phone: `${this.countryCode}${this.phoneNumber}`,
            });
            yield CurbClient.acceptTerms({ email: this.email, password: this.password });
            yield this.onCurbConnectingCredentialSubmit();
            Analytics.trackCreateProviderAccount('Curb');
        } catch (err) {
            if (err.response.data && err.response.data.errors) {
                const {
                    response: {
                        data: { errors },
                    },
                } = err;
                this.errorMessage = errors[errors.length - 1].error.message;
            } else {
                this.errorMessage = 'Registration failed.';
            }
        } finally {
            setRefreshing(false);
        }
    }).bind(this);

    downloadApp = () => {
        Linking.openURL(
            Platform.OS === 'android'
                ? 'https://play.google.com/store/apps/details?id=com.ridecharge.android.taximagic'
                : 'https://apps.apple.com/app/curb-the-taxi-app/id299226386'
        )
            .then(() => {
                Analytics.trackServiceLinking('curb', 'connecting', 'download_app');
            })
            .catch(() => {});
    };
}

import { StyleSheet } from 'react-native';
import { Fonts, Metrics, Colors } from '../../../themes';

export default StyleSheet.create({
    container: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#000000cc',
    },
    innerContainer: {
        backgroundColor: Colors.white,
        paddingVertical: 16,
        width: Metrics.screenWidth - 80,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 6,
    },
    topView: {
        flexDirection: 'row',
        marginBottom: 10,
        alignItems: 'center',
        paddingHorizontal: 16,
    },
    titleText: {
        fontFamily: Fonts.type.base,
        fontWeight: '700',
        fontSize: Metrics.normalize(20),
        color: Colors.darkTextColor,
        flex: 1,
    },
    descriptionText: {
        fontFamily: Fonts.type.base,
        fontWeight: '400',
        fontSize: Metrics.normalize(13),
        color: Colors.darkGreyTextColor,
        marginVertical: 16,
    },
    closeView: {
        backgroundColor: Colors.closeButtonBackgroundColor,
        width: 30,
        height: 30,
        borderRadius: 15,
        alignItems: 'center',
        justifyContent: 'center',
    },
    closeButton: {
        width: 15,
        height: 15,
    },
    separator: {
        backgroundColor: Colors.grayTextColor,
        height: 1,
        opacity: 0.1,
        alignSelf: 'stretch',
        marginBottom: 10,
    },
    confirmButton: {
        marginTop: 20,
        width: Metrics.screenWidth - 120,
    },
    paymentView: {
        marginHorizontal: 16,
        width: Metrics.screenWidth - 120,
        alignItems: 'center',
        padding: 14,
        borderRadius: 6,
        borderWidth: 1,
        flexDirection: 'row',
        marginBottom: 10,
        borderColor: Colors.lightGrayColor,
    },
    activePaymentView: {
        borderColor: Colors.obiTeal,
        shadowColor: 'black',
        shadowRadius: 3,
        shadowOpacity: 0.2,
        shadowOffset: { width: 0, height: 0 },
        elevation: 2,
    },
    paymentCardImage: {
        width: 60,
        height: 20,
        marginRight: 8,
    },
    paymentCardText: {
        fontFamily: Fonts.type.base,
        fontWeight: '600',
        fontSize: Metrics.normalize(18),
        color: Colors.darkTextColor,
        backgroundColor: 'transparent',
    },
    typeView: {
        borderRadius: 3,
        paddingVertical: 2,
        paddingHorizontal: 8,
        justifyContent: 'center',
        alignItems: 'center',
    },
    typeText: {
        fontFamily: Fonts.type.base,
        fontWeight: '500',
        fontSize: Metrics.normalize(11),
        color: 'white',
    },
    addButton: {
        width: Metrics.screenWidth - 120,
        borderStyle: 'dashed',
        borderColor: Colors.lightGrayColor,
        borderRadius: 4,
        borderWidth: 1,
        alignItems: 'center',
        justifyContent: 'center',
        margin: 10,
        padding: 12,
    },
    addText: {
        fontFamily: Fonts.type.base,
        fontWeight: '500',
        fontSize: Metrics.normalize(16),
        color: Colors.grayTextColor,
    },
    empty: {
        flex: 1,
    },
});

import React, { Component, RefObject } from 'react';
import { observer, inject } from 'mobx-react';
import { observable } from 'mobx';
import { TextInputMask } from 'react-native-masked-text';
import {
    View,
    Text,
    TextInput,
    TouchableOpacity,
    Image,
    Keyboard,
    EmitterSubscription,
    LayoutChangeEvent,
    ScrollView,
} from 'react-native';

import AdditionalInfoStore, { AdditionaInfoType } from 'stores/AdditionalInfoStore';
import AdditionalInfoStyles from './styles/AdditionalInfoStyles';
import EmptyNavigationBar from '../../components/navigationBars/EmptyNavigationBar';
import { AuthStore, KeyboardStore, LayoutStore, NavigationStore } from 'stores';
import { Colors, Images } from 'themes';
import { Localizable } from 'lib';
import { Stores } from 'stores/RootStore';

interface Props {
    authStore: AuthStore;
    additionalInfoStore: AdditionalInfoStore;
    navigation: any;
    navigationStore: NavigationStore;
}

@inject(({ authStore, additionalInfoStore, navigationStore }: Stores) => ({
    authStore,
    additionalInfoStore,
    navigationStore,
}))
@observer
export default class AdditionalInfo extends Component<Props> {
    keyboardStore = new KeyboardStore();

    keyboardDidShowListener?: EmitterSubscription;

    keyboardDidHideListener?: EmitterSubscription;

    numberRef: RefObject<TextInput> = React.createRef();

    @observable
    inputViewBottom: number = 0;

    @observable
    inputViewBottomLayout: number = 0;

    componentDidMount() {
        const {
            props: {
                additionalInfoStore: { setInitialType },
                navigation,
            },
        } = this;
        setInitialType(navigation);
        this.keyboardDidShowListener = Keyboard.addListener('keyboardDidShow', this.onKeyboardShow);
        this.keyboardDidHideListener = Keyboard.addListener('keyboardDidHide', this.onKeyboardHide);
    }

    componentWillUnmount() {
        if (this.keyboardDidShowListener) {
            this.keyboardDidShowListener.remove();
        }
        if (this.keyboardDidHideListener) {
            this.keyboardDidHideListener.remove();
        }
    }

    onKeyboardShow = (event: any) => {
        const {
            keyboardStore: { onKeyboardShow },
        } = this;
        onKeyboardShow(event);
        if (this.keyboardStore.keyboardHeight < this.inputViewBottomLayout) {
            this.inputViewBottom = 80;
        }
    };

    onKeyboardHide = () => {
        const {
            keyboardStore: { onKeyboardHide },
        } = this;
        onKeyboardHide();
        this.inputViewBottom = 0;
    };

    onInputViewLayout = ({
        nativeEvent: {
            layout: { y, height },
        },
    }: LayoutChangeEvent) => {
        this.inputViewBottomLayout = y + height;
    };

    title = () => {
        const {
            additionalInfoStore: { type },
        } = this.props;
        switch (type) {
            case AdditionaInfoType.EMAIL:
                return Localizable.t('additionalInfo.titleEmail');
            case AdditionaInfoType.PASSWORD:
                return Localizable.t('additionalInfo.titlePassword');
            case AdditionaInfoType.FIRSTNAME:
                return Localizable.t('additionalInfo.titleFirstname');
            case AdditionaInfoType.LASTNAME:
                return Localizable.t('additionalInfo.titleLastname');
            case AdditionaInfoType.PHONENUMBER:
                return Localizable.t('additionalInfo.titlePhone');
            case AdditionaInfoType.VALIDATENUMBER:
                return Localizable.t('additionalInfo.titleValidateNumber');
            case AdditionaInfoType.FORGOT:
                return Localizable.t('additionalInfo.titleForgotPassword');
            default:
                return '';
        }
    };

    description = () => {
        let text;
        const {
            additionalInfoStore: { type, phoneNumber },
        } = this.props;
        switch (type) {
            case AdditionaInfoType.EMAIL:
                text = Localizable.t('additionalInfo.titleEmail');
                break;
            case AdditionaInfoType.PASSWORD:
                text = Localizable.t('additionalInfo.titlePassword');
                break;
            case AdditionaInfoType.FIRSTNAME:
                text = Localizable.t('additionalInfo.titleFirstname');
                break;
            case AdditionaInfoType.LASTNAME:
                text = Localizable.t('additionalInfo.titleLastname');
                break;
            case AdditionaInfoType.PHONENUMBER:
                text = Localizable.t('additionalInfo.willSendCode');
                break;
            case AdditionaInfoType.VALIDATENUMBER:
                text = Localizable.t('additionalInfo.sentVerificationCode');
                break;
            case AdditionaInfoType.FORGOT:
                text = Localizable.t('additionalInfo.titleForgotPassword');
                break;
            default:
                text = '';
                break;
        }

        if (type === AdditionaInfoType.VALIDATENUMBER) {
            return <Text style={AdditionalInfoStyles.description}>{`${text} ${phoneNumber}`}</Text>;
        }

        return <Text style={AdditionalInfoStyles.description}>{text}</Text>;
    };

    placeholder = () => {
        const {
            additionalInfoStore: { type },
        } = this.props;
        switch (type) {
            case AdditionaInfoType.EMAIL:
                return Localizable.t('additionalInfo.placeholderEmail');
            case AdditionaInfoType.PASSWORD:
            case AdditionaInfoType.FORGOT:
                return Localizable.t('additionalInfo.placeholderPassword');
            case AdditionaInfoType.FIRSTNAME:
                return Localizable.t('additionalInfo.placeholderFirstname');
            case AdditionaInfoType.LASTNAME:
                return Localizable.t('additionalInfo.placeholderLastname');
            case AdditionaInfoType.PHONENUMBER:
                return Localizable.t('additionalInfo.placeholderPhone');
            default:
                return '';
        }
    };

    value = () => {
        const {
            additionalInfoStore: {
                type,
                phoneNumber,
                email,
                password,
                firstName,
                lastName,
                newPassword,
            },
        } = this.props;
        switch (type) {
            case AdditionaInfoType.EMAIL:
                return email;
            case AdditionaInfoType.PASSWORD:
                return password;
            case AdditionaInfoType.FIRSTNAME:
                return firstName;
            case AdditionaInfoType.LASTNAME:
                return lastName;
            case AdditionaInfoType.PHONENUMBER:
                return phoneNumber;
            case AdditionaInfoType.FORGOT:
                return newPassword;
            default:
                return '';
        }
    };

    keyboardType = () => {
        const {
            additionalInfoStore: { type },
        } = this.props;
        switch (type) {
            case AdditionaInfoType.EMAIL:
                return 'email-address';
            case AdditionaInfoType.PHONENUMBER:
                return 'phone-pad';
            default:
                return 'default';
        }
    };

    submitButtonText = () => {
        const {
            additionalInfoStore: { type },
        } = this.props;
        switch (type) {
            case AdditionaInfoType.PHONENUMBER:
                return 'Send code';
            default:
                return 'Submit';
        }
    };

    handleNextClick = () => {
        const {
            additionalInfoStore: { nextClicked },
        } = this.props;
        nextClicked();
    };

    render() {
        const {
            props: {
                additionalInfoStore: {
                    handleBackClick,
                    type,
                    countrySelected,
                    countryName,
                    countryCode,
                    phoneNumber,
                    parseNumber,
                    submitClicked,
                    valueChanged,
                    isValid,
                    resend,
                    validationCode,
                },
                navigationStore: { navigate },
            },
            keyboardStore: { keyboardHeight },
        } = this;
        return (
            <View style={AdditionalInfoStyles.container}>
                <EmptyNavigationBar handleBackClick={handleBackClick} />
                <ScrollView
                    contentContainerStyle={[
                        AdditionalInfoStyles.scrollView,
                        { bottom: this.inputViewBottom },
                    ]}
                    keyboardShouldPersistTaps="handled"
                >
                    <Text style={AdditionalInfoStyles.title}>{this.title()}</Text>
                    {this.description()}
                    <View
                        style={AdditionalInfoStyles.inputBorder}
                        onLayout={this.onInputViewLayout}
                    >
                        {type === AdditionaInfoType.PHONENUMBER && (
                            <TouchableOpacity
                                onPress={() =>
                                    navigate('CountryPicker', {
                                        onClose: country => {
                                            countrySelected(country);
                                            if (this.numberRef.current) {
                                                this.numberRef.current.focus();
                                            }
                                        },
                                    })
                                }
                            >
                                <View style={AdditionalInfoStyles.countryContainer}>
                                    <Image
                                        source={Images.flags[countryName]}
                                        style={AdditionalInfoStyles.countryIcon}
                                        resizeMode="contain"
                                    />
                                    <Image
                                        source={Images.icons.countryArrow}
                                        style={AdditionalInfoStyles.countryArrow}
                                        resizeMode="contain"
                                    />
                                    <Text style={AdditionalInfoStyles.countryText}>
                                        +{countryCode}
                                    </Text>
                                </View>
                            </TouchableOpacity>
                        )}
                        {(type === AdditionaInfoType.PHONENUMBER && (
                            <TextInput
                                ref={this.numberRef}
                                style={[
                                    AdditionalInfoStyles.textInput,
                                    { fontWeight: this.value().length ? '300' : '100' },
                                ]}
                                keyboardType="phone-pad"
                                placeholder={this.placeholder()}
                                placeholderTextColor={Colors.lightGrayColor}
                                value={phoneNumber}
                                secureTextEntry={false}
                                onChangeText={text => {
                                    parseNumber(text);
                                }}
                                autoFocus
                                autoCorrect={false}
                                selectionColor={Colors.obiTeal}
                                onSubmitEditing={submitClicked}
                            />
                        )) ||
                            (type === AdditionaInfoType.VALIDATENUMBER && (
                                <TextInputMask
                                    style={[
                                        AdditionalInfoStyles.textInput,
                                        { fontWeight: validationCode.length ? '300' : '100' },
                                    ]}
                                    keyboardType="numeric"
                                    type="custom"
                                    options={{
                                        mask: '9999',
                                        validator(pass) {
                                            return pass.length === 4;
                                        },
                                    }}
                                    placeholder={Localizable.t(
                                        'additionalInfo.placeholderVerificationCode4digit'
                                    )}
                                    placeholderTextColor={Colors.defaultTextColor}
                                    value={validationCode}
                                    onChangeText={valueChanged}
                                    autoFocus
                                    autoCorrect={false}
                                    selectionColor={Colors.obiTeal}
                                    onSubmitEditing={submitClicked}
                                    returnKeyType="done"
                                />
                            )) || (
                                <TextInput
                                    style={[
                                        AdditionalInfoStyles.textInput,
                                        { fontWeight: this.value().length ? '300' : '100' },
                                    ]}
                                    value={this.value()}
                                    onChangeText={valueChanged}
                                    autoCapitalize={
                                        type === AdditionaInfoType.PASSWORD ||
                                        type === AdditionaInfoType.FORGOT ||
                                        type === AdditionaInfoType.EMAIL
                                            ? 'none'
                                            : 'sentences'
                                    }
                                    placeholder={this.placeholder()}
                                    autoFocus
                                    autoCorrect={false}
                                    keyboardType={this.keyboardType()}
                                    selectionColor={Colors.obiTeal}
                                    placeholderTextColor={Colors.defaultTextColor}
                                    secureTextEntry={
                                        type === AdditionaInfoType.PASSWORD ||
                                        type === AdditionaInfoType.FORGOT
                                    }
                                    onSubmitEditing={submitClicked}
                                />
                            )}
                        {type === AdditionaInfoType.VALIDATENUMBER && isValid() && (
                            <Image
                                source={Images.landing.correct}
                                style={AdditionalInfoStyles.correctImage}
                                resizeMode="contain"
                            />
                        )}
                    </View>
                </ScrollView>
                <View
                    style={[
                        AdditionalInfoStyles.submitButtonView,
                        { bottom: keyboardHeight === 0 ? 0 : keyboardHeight - 40 },
                    ]}
                >
                    {type === AdditionaInfoType.VALIDATENUMBER ? (
                        <View style={AdditionalInfoStyles.resendView}>
                            <Text style={AdditionalInfoStyles.resendText}>
                                Didn't receive a code?
                                <Text style={AdditionalInfoStyles.resendTextRed} onPress={resend}>
                                    {'  '}Resend{'  '}
                                </Text>
                            </Text>
                        </View>
                    ) : (
                        <TouchableOpacity
                            style={AdditionalInfoStyles.submitButton}
                            onPress={this.handleNextClick}
                            disabled={!isValid()}
                        >
                            <View style={AdditionalInfoStyles.submitButtonTextView}>
                                <Text style={AdditionalInfoStyles.submitButtonText}>
                                    {this.submitButtonText()}
                                </Text>
                            </View>
                            <View style={AdditionalInfoStyles.submitButtonImageView}>
                                <Image
                                    source={Images.forwardArrow}
                                    resizeMode="contain"
                                    style={AdditionalInfoStyles.submitButtonImage}
                                />
                            </View>
                        </TouchableOpacity>
                    )}
                </View>
            </View>
        );
    }
}

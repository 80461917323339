import React from 'react';
import { Text, TouchableOpacity, View } from 'react-native';
import { Colors } from 'themes';
import styles from './styles';

interface Props {
    state?: string;
    action?: () => void;
    text?: string;
}

const ModalButton = ({ state = 'primary', action = () => {}, text = '' }: Props) => (
    <TouchableOpacity onPress={action}>
        <View
            style={[
                styles.buttonContainer,
                { backgroundColor: state === 'primary' ? Colors.obiTeal : '#EAEAED' },
            ]}
        >
            <Text style={[styles.buttonLabel, { color: state === 'primary' ? 'white' : 'black' }]}>
                {text}
            </Text>
        </View>
    </TouchableOpacity>
);

export default ModalButton;

import { StyleSheet } from 'react-native';
import { Colors, Fonts, Metrics } from 'themes';

export default StyleSheet.create({
    container: {
        paddingHorizontal: 15,
        paddingVertical: 10,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: Colors.darkerGrayBackgroundColor,
        marginTop: 5,
        marginHorizontal: 10,
        borderRadius: 10,
        flexDirection: 'row',
    },
    textView: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    text: {
        color: Colors.grayTextColor,
        fontFamily: Fonts.type.base,
        fontWeight: '500',
        fontSize: Metrics.normalize(12),
    },
    buttonView: {
        paddingLeft: 25,
        paddingRight: 10,
        alignItems: 'center',
        justifyContent: 'center',
    },
    buttonText: {
        color: Colors.black,
        fontFamily: Fonts.type.base,
        fontWeight: '600',
        fontSize: Metrics.normalize(14),
    },
});

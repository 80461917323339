import React from 'react';
import { View } from 'react-native';
import MaterialIndicator from 'modules/materialIndicator/materialIndicator';
import { Metrics, Colors } from '../../../themes';
import styles from './styles/EtaLoadingStyles';

export default () => (
    <View style={styles.container}>
        <MaterialIndicator size={Metrics.normalize(20)} color={Colors.obiTeal} />
    </View>
);

import React from 'react';
import { View } from 'react-native';
import ArrowDownStyles from './styles/ArrowDownStyles';

const ArrowDown = () => (
    <View style={ArrowDownStyles.arrowContainer}>
        <View style={ArrowDownStyles.arrowInnerContainer}>
            <View style={ArrowDownStyles.arrow} />
        </View>
    </View>
);

export default ArrowDown;

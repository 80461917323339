import { StyleSheet, Platform } from 'react-native';
import { Fonts, Metrics, Colors } from 'themes';

export default StyleSheet.create({
    container: {
        alignItems: 'center',
        backgroundColor: 'transparent',
        flexDirection: 'row',
        paddingTop: Metrics.paddingTop + (Platform.OS === 'ios' ? 10 : 5),
        paddingHorizontal: 10,
        paddingBottom: 5,
    },
    background: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        height: 60,
        width: Metrics.screenWidth,
    },
    backButton: {
        height: 50,
        width: 50,
        alignItems: 'center',
        justifyContent: 'center',
    },
    backButtonImage: {
        width: 18,
        height: 18,
    },
    avatarView: {
        marginRight: 16,
        shadowColor: 'black',
        shadowRadius: 3,
        shadowOpacity: 0.5,
        shadowOffset: { width: 0, height: 0 },
        elevation: 2,
        width: 25,
        height: 25,
        borderRadius: 12.5,
    },
    avatarImage: {
        width: 25,
        height: 25,
        borderRadius: 12.5,
    },
    titleView: {
        flex: 1,
        paddingHorizontal: 16,
        alignItems: 'center',
        justifyContent: 'center',
    },
    titleImage: {
        height: 25,
        width: 100,
    },
    titleText: {
        fontFamily: Fonts.type.base,
        fontWeight: '700',
        fontSize: Metrics.normalize(15),
        color: Colors.darkTextColor,
        flex: 1,
        marginTop: 16,
    },
    bikesAvailableIcon: {
        width: 30,
        height: 30,
    },
});

import React from 'react';
import { View, TouchableOpacity, Image } from 'react-native';

import NavigationBarStyles from './styles/NewNavigationBarStyles';
import { Images } from 'themes';

interface Props {
    backImage?: Object;
    handleOptionsClick?: () => void;
    handleBackClick: () => void;
}

export default ({ backImage, handleBackClick, handleOptionsClick }: Props) => {
    return (
        <View style={NavigationBarStyles.container}>
            <View style={NavigationBarStyles.iconView}>
                <TouchableOpacity
                    onPress={handleBackClick}
                    style={NavigationBarStyles.iconBackground}
                >
                    <Image
                        source={backImage || Images.icons.back}
                        style={NavigationBarStyles.icon}
                        resizeMode="contain"
                    />
                </TouchableOpacity>
            </View>
            <View style={NavigationBarStyles.middleView} />
            <View style={NavigationBarStyles.iconView}>
                {handleOptionsClick && (
                    <TouchableOpacity
                        onPress={handleOptionsClick}
                        style={NavigationBarStyles.iconBackground}
                    >
                        <View style={NavigationBarStyles.iconInnerBackground}>
                            <Image
                                source={Images.icons.options}
                                style={NavigationBarStyles.icon}
                                resizeMode="contain"
                            />
                        </View>
                    </TouchableOpacity>
                )}
            </View>
        </View>
    );
};

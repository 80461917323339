import React, { Component } from 'react';
import { TextInput, View, Text, StyleSheet } from 'react-native';
import KeyboardAwareScrollView from 'modules/keyboardAwareScrollView/keyboardAwareScrollView';
import { observer, inject } from 'mobx-react';
import { NavigationParams } from 'react-navigation';

import AdditionalInfoStyles from '../../styles/AdditionalInfoStyles';
import NavigationStore from '../../../../stores/NavigationStore';
import ServicesStore from '../../../../stores/ServicesStore';
import { Stores } from '../../../../stores/RootStore';
import TitleNavigationBar from '../../../navigationBars/TitleNavigationBar';
import { Colors } from '../../../../themes';
import { InfoLabel, NextButton, ErrorMessage } from '../../providerConnectingComponents';
import { Localizable, Analytics } from '../../../../lib';
import ClickableInfoLabel, {
    ClickableInfoChildProps,
} from '../../providerConnectingComponents/clickableInfoLabel/ClickableInfoLabel';

interface OwnProps {
    navigation: NavigationParams;
}

interface InjectedProps {
    servicesStore: ServicesStore;
    navigationStore: NavigationStore;
}

type Props = InjectedProps & OwnProps;

@inject(({ servicesStore, navigationStore }: Stores) => ({
    servicesStore,
    navigationStore,
}))
@observer
export default class ProviderConnectingCurb extends Component<Props> {
    passwordInputRef: React.RefObject<TextInput> = React.createRef();

    focusPasswordInput = () => {
        if (this.passwordInputRef && this.passwordInputRef.current) {
            this.passwordInputRef.current.focus();
        }
    };

    componentDidMount() {
        Analytics.trackServiceLinking('curb', 'connecting', 'credentials_screen');
    }

    render() {
        const {
            servicesStore: {
                hideProviderPopup,
                curbServiceStore: {
                    clearStore,
                    email,
                    password,
                    errorMessage,
                    setCurbEmail,
                    setCurbPassword,
                    onCurbConnectingCredentialSubmit,
                },
            },
            navigationStore: { navigate },
            navigation: {
                state: {
                    params: { navigateFromOptions },
                },
            },
        } = this.props;

        return (
            <View
                style={[
                    StyleSheet.absoluteFill,
                    {
                        backgroundColor: Colors.typesBackgroundColor,
                    },
                ]}
            >
                <TitleNavigationBar title="Curb" handleBackClick={() => hideProviderPopup()} />
                <KeyboardAwareScrollView
                    contentContainerStyle={{ paddingHorizontal: 20 }}
                    keyboardShouldPersistTaps="handled"
                >
                    <Text style={AdditionalInfoStyles.title}>
                        {Localizable.t('additionalInfo.enterCredentials')}
                    </Text>

                    <Text style={AdditionalInfoStyles.description}>
                        {Localizable.t('additionalInfo.placeholderEmail')}
                    </Text>
                    <View style={AdditionalInfoStyles.inputBorder}>
                        <TextInput
                            style={[
                                AdditionalInfoStyles.textInput,
                                {
                                    fontWeight: email.length ? '300' : '100',
                                },
                            ]}
                            keyboardType="email-address"
                            placeholder={Localizable.t('additionalInfo.placeholderEmail')}
                            placeholderTextColor={Colors.lightGrayColor}
                            value={email}
                            secureTextEntry={false}
                            onChangeText={setCurbEmail}
                            autoCapitalize="none"
                            autoFocus
                            autoCorrect={false}
                            selectionColor={Colors.obiTeal}
                            onSubmitEditing={this.focusPasswordInput}
                            returnKeyType="next"
                        />
                    </View>

                    <Text style={AdditionalInfoStyles.description}>
                        {Localizable.t('additionalInfo.placeholderPassword')}
                    </Text>
                    <View style={AdditionalInfoStyles.inputBorder}>
                        <TextInput
                            style={[
                                AdditionalInfoStyles.textInput,
                                {
                                    fontWeight: password.length ? '300' : '100',
                                },
                            ]}
                            ref={this.passwordInputRef}
                            keyboardType="default"
                            placeholder={Localizable.t('additionalInfo.placeholderPassword')}
                            placeholderTextColor={Colors.lightGrayColor}
                            value={password}
                            secureTextEntry
                            onChangeText={setCurbPassword}
                            autoCorrect={false}
                            selectionColor={Colors.obiTeal}
                            returnKeyType="done"
                        />
                    </View>
                    {!!errorMessage.length && <ErrorMessage message={errorMessage} />}
                    <ClickableInfoLabel
                        childrenProps={[
                            {
                                text: 'Create',
                                clickableText: 'Curb account',
                                clickableTextStyle: {
                                    fontWeight: '500',
                                    color: '#1FB484',
                                },
                                handleClickableTextClick: () => {
                                    clearStore();
                                    navigate('ProviderRegisterCurb');
                                },
                            },
                        ]}
                    />
                    <InfoLabel info={Localizable.t('additionalInfo.curb.permissionClick')} />
                    <InfoLabel info={Localizable.t('additionalInfo.curb.info')} />
                    <NextButton
                        onPress={onCurbConnectingCredentialSubmit}
                        active={!errorMessage.length}
                    />
                </KeyboardAwareScrollView>
            </View>
        );
    }
}

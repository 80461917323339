import { StyleSheet } from 'react-native';
import { Colors, Metrics } from '../../../themes';

export default StyleSheet.create({
    sectionList: {
        flex: 1,
        alignSelf: 'stretch',
        paddingHorizontal: 16,
        marginTop: 10,
    },
});

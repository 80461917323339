/* eslint-disable no-alert */
import React, { Component } from 'react';
import { View, Text, SectionList, Platform } from 'react-native';
import { inject, observer } from 'mobx-react';
import { NavigationParams } from 'react-navigation';

import Alert from 'modules/alert/alert';

import Service from './service/Service';
import ServicesListStyles from './ServicesListStyles';
import ServicesStore, { ServiceType } from '../../../stores/ServicesStore';
import { Localizable } from '../../../lib';
import { Stores } from '../../../stores/RootStore';
import { providerInfo } from 'services/Utils';

interface Props {
    servicesStore: ServicesStore;
    keepModal: boolean;
}

@inject(({ servicesStore }: Stores) => ({ servicesStore }))
@observer
export default class ServicesList extends Component<Props> {
    handleClick = (item: ServiceType) => {
        const {
            servicesStore: { revokeProvider, showProviderPopup, hideServicesModal },
            keepModal,
        } = this.props;

        if (item.connected) {
            if (Platform.OS === 'web') {
                const confirmed = window.confirm(
                    `${Localizable.t('services.disconnectAlert.confirmation')} ${
                        providerInfo(item.name).name
                    }?`
                );
                if (confirmed) {
                    revokeProvider(item);
                }
            } else {
                Alert.alert(
                    'Alert',
                    `${Localizable.t('services.disconnectAlert.confirmation')} ${
                        providerInfo(item.name).name
                    }?`,
                    [
                        {
                            text: Localizable.t('no'),
                            onPress: () => console.log('Cancel Pressed'),
                            style: 'cancel',
                        },
                        { text: Localizable.t('yes'), onPress: () => revokeProvider(item) },
                    ]
                );
            }
            return;
        }

        hideServicesModal(keepModal);
        if (keepModal) {
            setTimeout(() => {
                showProviderPopup(item.name);
            }, 600);
        } else {
            showProviderPopup(item.name);
        }
    };

    renderItem = ({ item, index }: { item: ServiceType; index: number }) => {
        return <Service item={item} index={index} handleClick={() => this.handleClick(item)} />;
    };

    renderHeader = ({ section }) => {
        if (section.title.length) {
            return <Text style={ServicesListStyles.header}>{section.title}</Text>;
        }
        return null;
    };

    render() {
        const {
            servicesStore: { refreshing, getServices, services },
        } = this.props;
        const linkedServices = services.filter(item => item.connected);
        const notLinkedServices = services.filter(item => !item.connected);
        const sections = [{ key: 'notLinked', title: '', data: notLinkedServices }];
        if (linkedServices.length) {
            sections.push({ key: 'Linked', title: 'Linked', data: linkedServices });
        }
        return (
            <SectionList
                sections={sections}
                renderItem={this.renderItem}
                renderSectionHeader={this.renderHeader}
                keyExtractor={item => item.name}
                onRefresh={getServices}
                refreshing={refreshing}
                style={ServicesListStyles.list}
            />
        );
    }
}

import React from 'react';
import { View, Text, Image, ImageSourcePropType } from 'react-native';
import { observer } from 'mobx-react';

import DriverAvatarStyles from './DriverAvatarStyles';
import { Images } from '../../../themes';

interface Props {
    imageSource: ImageSourcePropType;
    rating?: number;
}

const DriverAvatar = ({ imageSource, rating }: Props) => {
    return (
        <View style={DriverAvatarStyles.container}>
            <Image source={imageSource} style={DriverAvatarStyles.avatarImage} resizeMode="cover" />
            {rating && (
                <View style={DriverAvatarStyles.ratingView}>
                    <Image source={Images.icons.starGold} style={DriverAvatarStyles.ratingImage} />
                    <Text style={DriverAvatarStyles.ratingText}>{rating.toFixed(1)}</Text>
                </View>
            )}
        </View>
    );
};

export default observer(DriverAvatar);

import { StyleSheet } from 'react-native';
import { Fonts, Metrics, Colors } from 'themes';

export default StyleSheet.create({
    container: {
        backgroundColor: Colors.white,
        paddingHorizontal: 10,
        paddingBottom: 10,
    },
    titleView: {
        marginHorizontal: 6,
        flexDirection: 'row',
        alignItems: 'center',
    },
    titleText: {
        fontFamily: Fonts.type.base,
        fontWeight: '700',
        fontSize: Metrics.normalize(28),
        color: '#2D2D2D',
        flex: 1,
    },
    backButton: {
        height: 50,
        width: 50,
        alignItems: 'center',
        justifyContent: 'center',
    },
    backButtonImage: {
        width: 15,
        height: 15,
    },
    avatarView: {
        shadowColor: 'black',
        shadowRadius: 3,
        shadowOpacity: 0.5,
        shadowOffset: { width: 0, height: 0 },
        elevation: 2,
        width: 30,
        height: 30,
        borderRadius: 15,
    },
    avatarImage: {
        width: 30,
        height: 30,
        borderRadius: 15,
    },
    separator: {
        position: 'absolute',
        bottom: 0,
        right: 0,
        left: 0,
        backgroundColor: Colors.grayTextColor,
        height: 1,
        opacity: 0.1,
    },
});

import React, { Component, ClassicComponent } from 'react';
import {
    View,
    TouchableOpacity,
    Text,
    Image,
    ActivityIndicator,
    ViewStyle,
    ViewProps,
} from 'react-native';
import Animatable from 'modules/animatable/animatable';
import { observer, inject } from 'mobx-react';

import { Images, Colors } from '../../themes';

import GradientButton from '../GradientButton';

import SeatsPopupStyles from './styles/SeatsPopupStyles';
import TaxiStore from '../../stores/TaxiStore';
import { Stores } from '../../stores/RootStore';
import FiltersStore from '../../stores/FiltersStore';
import { Localizable } from '../../lib';

interface Props {
    taxiStore: TaxiStore;
    filtersStore: FiltersStore;
}

export default inject(({ filtersStore, taxiStore }: Stores) => ({ filtersStore, taxiStore }))(
    observer(
        ({
            taxiStore: {
                selectedTaxiStore,
                selectedTaxiStore: { seatsConfirmed, hideSeatsPopup, seatsChanged },
            },
            filtersStore: { seats },
        }: Props) => {
            const price = selectedTaxiStore.exactPrice
                ? selectedTaxiStore.exactPrice.display
                : selectedTaxiStore.selectedTaxi.price_info.rate_estimate;
            return (
                <Animatable.View
                    animation="fadeIn"
                    duration={300}
                    style={SeatsPopupStyles.container}
                    useNativeDriver
                >
                    <Animatable.View style={SeatsPopupStyles.innerContainer} useNativeDriver>
                        <View style={SeatsPopupStyles.topView}>
                            <Text style={SeatsPopupStyles.titleText}>
                                {Localizable.t('taxis.seatsPopup.poolRides')}
                            </Text>
                            <TouchableOpacity onPress={() => hideSeatsPopup()}>
                                <View style={SeatsPopupStyles.closeView}>
                                    <Image
                                        style={SeatsPopupStyles.closeButton}
                                        resizeMode="contain"
                                        source={Images.settings.close}
                                    />
                                </View>
                            </TouchableOpacity>
                            <View style={SeatsPopupStyles.separator} />
                        </View>
                        <Text style={SeatsPopupStyles.descriptionText}>
                            {Localizable.t('taxis.seatsPopup.poolRidesDescription')}
                        </Text>
                        <View style={SeatsPopupStyles.seatsView}>
                            <Text style={SeatsPopupStyles.seatsTitleText}>
                                {Localizable.t('taxis.seatsPopup.numberOfSeats')}
                            </Text>
                            <View style={SeatsPopupStyles.seatsValueView}>
                                <TouchableOpacity onPress={() => seatsChanged(1)}>
                                    <Text
                                        style={[
                                            SeatsPopupStyles.seatsValueText,
                                            {
                                                color:
                                                    seats === 1
                                                        ? Colors.obiTeal
                                                        : Colors.lightGrayColor,
                                            },
                                        ]}
                                    >
                                        1
                                    </Text>
                                </TouchableOpacity>
                                <TouchableOpacity onPress={() => seatsChanged(2)}>
                                    <Text
                                        style={[
                                            SeatsPopupStyles.seatsValueText,
                                            {
                                                color:
                                                    seats === 2
                                                        ? Colors.obiTeal
                                                        : Colors.lightGrayColor,
                                            },
                                        ]}
                                    >
                                        2
                                    </Text>
                                </TouchableOpacity>
                            </View>
                        </View>
                        <Text style={SeatsPopupStyles.feesText}>
                            {Localizable.t('taxis.seatsPopup.totalFaresAndFees')}
                        </Text>
                        {(selectedTaxiStore.exactPriceLoading && <ActivityIndicator />) || (
                            <Text style={SeatsPopupStyles.feesValueText}>{price}</Text>
                        )}
                        <GradientButton
                            style={SeatsPopupStyles.confirmButton}
                            title={Localizable.t('taxis.seatsPopup.confirm')}
                            handlePress={seatsConfirmed}
                        />
                    </Animatable.View>
                </Animatable.View>
            );
        }
    )
);

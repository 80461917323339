/* eslint-disable no-empty */
/* eslint-disable no-restricted-syntax */
import * as Localize from 'react-native-localize';
import { AsYouType, isValidNumber, parse, getPhoneCode } from 'libphonenumber-js';
import { NavState, Platform, Linking } from 'react-native';
import { observable, action } from 'mobx';

import ServicesStore from '../ServicesStore';
import UberClient from 'services/providers/UberClient';
import countryCodes from '../../lib/flags';
import shareMessage from '../../services/ShareUtils';
import { UberResponse, UberView } from 'domain/services/Uber/UberResponse';
import { findNumberInString } from '../../services/Utils';
import RestClient from '../../services/RestClient';
import { Analytics } from '../../lib';

export default class UberServiceStore {
    servicesRootStore: ServicesStore;

    constructor(servicesStore: ServicesStore) {
        this.servicesRootStore = servicesStore;
        this.getCarrierInfo();
    }

    @observable activeView: UberView = 'PHONE';

    @observable sessionId = '';

    @observable phoneNumber: string = '';

    @observable countryCode: any = '1';

    @observable countryName: any = 'us';

    @observable asYouType: AsYouType = new AsYouType(this.countryName.toUpperCase());

    @observable validPhoneNumber: boolean = false;

    @observable OTP = '';

    @observable email = '';

    @observable firstName = '';

    @observable lastName = '';

    @observable password = '';

    @observable passRecoveryPossible: boolean = false;

    @observable passRecoveryEmailHint: string = '';

    @observable passRecoveryEmail: string = '';

    @observable passRecoverySuccess: boolean = false;

    @observable captchaToken = '';

    @observable TOTP = '';

    @observable errorMessage = '';

    @observable errorOptions = {
        start: 0,
        end: 0,
        number: '',
    };

    @action
    clearStore = () => {
        this.getCarrierInfo();
        this.activeView = 'PHONE';
        this.sessionId = '';
        this.phoneNumber = '';
        this.validPhoneNumber = false;
        this.OTP = '';
        this.email = '';
        this.firstName = '';
        this.lastName = '';
        this.password = '';
        this.passRecoveryPossible = false;
        this.passRecoveryEmailHint = '';
        this.passRecoveryEmail = '';
        this.passRecoverySuccess = false;
        this.captchaToken = '';
        this.TOTP = '';
        this.errorMessage = '';
        this.errorOptions = {
            start: 0,
            end: 0,
            number: '',
        };
    };

    @action
    getCarrierInfo = () => {
        let countryCode = 'us';
        try {
            countryCode = Localize.getCountry();
        } catch (error) {}
        const codes = countryCodes.filter(
            item => item.code.toLowerCase() === countryCode.toLowerCase()
        );
        if (codes.length > 0) {
            this.countryName = codes[0].code.toLowerCase();
            this.countryCode = codes[0].dial_code.replace(/\D/g, '');
            this.asYouType = new AsYouType(this.countryName.toUpperCase());
        }
    };

    @action
    selectCountry = country => {
        this.countryCode = country.dial_code.replace(/\D/g, '');
        this.countryName = country.code.toLowerCase();
        this.asYouType = new AsYouType(this.countryName.toUpperCase());
        this.clearError();
    };

    @action
    clearError = () => {
        this.errorMessage = '';
    };

    @action
    setPhoneNumber = (text: string) => {
        this.asYouType.reset();
        if (this.phoneNumber.length - text.length === 1 && this.phoneNumber.slice(-1) === ')') {
            this.phoneNumber = text.replace('(', '');
        } else {
            this.phoneNumber = this.asYouType.input(text);
        }
        this.validPhoneNumber = isValidNumber(text, this.countryName.toUpperCase());
        this.clearError();
    };

    rawPhoneNumber = () => {
        if (this.phoneNumber.indexOf('+') >= 0) {
            const numberInfo = parse(this.phoneNumber);
            if (numberInfo && numberInfo.country) {
                const countryCode = getPhoneCode(numberInfo.country);
                this.countryCode = countryCode;
                return this.phoneNumber.replace(`+${countryCode}`, '').replace(/\D/g, '');
            }
        }
        return this.phoneNumber.replace(/\D/g, '');
    };

    @action
    onUberPhoneNumberSubmit = () => {
        const { setRefreshing } = this.servicesRootStore;
        setRefreshing(true);
        UberClient.initializeUberConnection({
            phoneNumber: this.rawPhoneNumber(),
            countryCode: this.countryCode,
        })
            .then(this.processUberResponse('PHONE_NUMBER'))
            .catch(this.processUberError('PHONE_NUMBER'));
    };

    @action
    setUberOTP = (text: string) => {
        this.OTP = text;
        this.clearError();
    };

    @action
    onUberOTPSubmit = () => {
        const { setRefreshing } = this.servicesRootStore;
        setRefreshing(true);
        UberClient.sendOTP({
            otp: this.OTP,
            sessionId: this.sessionId,
        })
            .then(this.processUberResponse('PHONE_SMS_OTP'))
            .catch(this.processUberError('PHONE_SMS_OTP'));
    };

    @action
    setUberEmail = (text: string) => {
        this.email = text;
        this.clearError();
    };

    @action
    onUberEmailSubmit = () => {
        const { setRefreshing } = this.servicesRootStore;
        setRefreshing(true);
        UberClient.sendEmail({
            email: this.email,
            sessionId: this.sessionId,
        })
            .then(this.processUberResponse('EMAIL'))
            .catch(this.processUberError('EMAIL'));
    };

    // register ==

    @action
    setUberFirstName = (text: string) => {
        this.firstName = text;
        this.clearError();
    };

    @action
    setUberLastName = (text: string) => {
        this.lastName = text;
        this.clearError();
    };

    @action
    setUberPassword = (text: string) => {
        this.password = text;
        this.clearError();
    };

    @action
    onUberCredentialsSubmit = () => {
        const { setRefreshing } = this.servicesRootStore;
        setRefreshing(true);
        UberClient.sendCredentials({
            password: this.password,
            firstName: this.firstName,
            lastName: this.lastName,
            sessionId: this.sessionId,
        })
            .then(this.processUberResponse('CREDENTIALS'))
            .catch(this.processUberError('CREDENTIALS'));
    };

    // ==

    @action
    onUberPasswordSubmit = () => {
        const { setRefreshing } = this.servicesRootStore;
        setRefreshing(true);
        UberClient.sendPassword({
            password: this.password,
            sessionId: this.sessionId,
        })
            .then(this.processUberResponse('PASSWORD'))
            .catch(this.processUberError('PASSWORD'));
    };

    @action
    onUberPasswordRecoverySubmit = () => {
        const { setRefreshing } = this.servicesRootStore;
        setRefreshing(true);
        UberClient.sendPasswordRecovery({
            email: this.passRecoveryEmail,
            sessionId: this.sessionId,
        })
            .then(this.processUberResponse('PASSWORD_RECOVERY'))
            .catch(this.processUberError('PASSWORD_RECOVERY'));
    };

    @action
    setUberEmailRecovery = (text: string) => {
        this.passRecoveryEmail = text;
        this.clearError();
    };

    @action
    setUberTOTP = (text: string) => {
        this.TOTP = text;
        this.clearError();
    };

    @action
    onUberTOTPSubmit = () => {
        const { setRefreshing } = this.servicesRootStore;
        setRefreshing(true);
        UberClient.sendTOTP({
            totp: this.TOTP,
            sessionId: this.sessionId,
        })
            .then(this.processUberResponse('TOTP_VERIFICATION'))
            .catch(this.processUberError('TOTP_VERIFICATION'));
    };

    @action
    onUberCaptchaSubmit = (token: string) => {
        const { setRefreshing } = this.servicesRootStore;
        setRefreshing(true);

        // RestClient.loginUber({ captchaToken: token, sessionId: this.sessionId })
        //     .then(res => {
        //         console.log('Uber login res: ', res);
        //         const {
        //             data: { next_view, session_id },
        //             status,
        //         } = res;
        //         this.sessionId = session_id;
        //         this.setUberNextView(next_view, status);
        //         const { setRefreshing } = this.servicesRootStore;
        //          setRefreshing(false);
        //     })
        //     .catch(err => {
        //         console.log('Uber login err', err);
        //         console.log('Uber login err', { ...err });
        //         const {
        //             response: {
        //                 data: { message },
        //             },
        //         } = err;
        //         const { setRefreshing } = this.servicesRootStore;
        //         setRefreshing(false);

        //         this.errorMessage = message;
        //     });
    };

    onNumberClicked = () => {
        shareMessage({
            message: 'START',
            channel: 'sms',
            phoneNumber: this.errorOptions.number,
        });
    };

    @action
    processUberResponse = (fieldName: string) => ({ data }: { data: UberResponse }) => {
        const { setRefreshing } = this.servicesRootStore;
        setRefreshing(false);
        if (fieldName === 'PASSWORD_RECOVERY') {
            this.passRecoverySuccess = true;
        }

        if (data.form) {
            const {
                form: { screens, flowType },
                alternateForms: alternateFormsArray = [],
                inAuthSessionID,
            } = data;
            const [{ screenType }] = screens;

            let nextScreen = screenType;

            if (flowType === 'SIGN_UP') {
                if (
                    screens.length === 3 &&
                    screens[0].screenType === 'PASSWORD' &&
                    screens[1].screenType === 'FULL_NAME' &&
                    screens[2].screenType === 'LEGAL'
                ) {
                    nextScreen = 'CREDENTIALS';
                }
            } else if (flowType === 'SIGN_IN') {
                console.log('flow type: SIGN_IN');
            }

            const emailRecoveryForm = alternateFormsArray.find(
                formObject => formObject.flowType === 'ACCOUNT_RECOVERY'
            );

            if (emailRecoveryForm) {
                this.passRecoveryPossible = true;
                this.passRecoveryEmailHint = emailRecoveryForm.screens
                    .find(screen => screen.screenType === 'EMAIL')
                    .fields.find(field => field.fieldType === 'EMAIL_ADDRESS').hintValue;
            }

            this.setUberNextView(nextScreen, inAuthSessionID);
        } else if (data.apiToken) {
            const { apiToken, userUUID } = data;
            this.servicesRootStore.handleUberLogin({ userUUID, apiToken });

            if (fieldName === 'CREDENTIALS') {
                Analytics.trackCreateProviderAccount('Uber');
            }
        }
    };

    @action
    processUberError = (fieldName: string) => errorResponse => {
        console.log('UBER err: ', fieldName, { ...errorResponse });

        const { setRefreshing } = this.servicesRootStore;
        setRefreshing(false);

        const {
            response: {
                data: {
                    screenErrors: [{ errors }],
                },
            },
        } = errorResponse;
        if (
            errors.PHONE_NUMBER &&
            errors.PHONE_NUMBER.errorType &&
            errors.PHONE_NUMBER.errorType === 'MOBILE_INVALID'
        ) {
            const { message } = errors.PHONE_NUMBER;
            const possibleNumber = findNumberInString(message);
            if (possibleNumber) {
                const { start, end, phoneNumber, formattedNumber } = possibleNumber;
                this.errorOptions = {
                    start,
                    end,
                    number: phoneNumber,
                };
            }
        } else {
            this.errorOptions = {
                start: 0,
                end: 0,
                number: '',
            };
        }
        const firstError = Object.values(errors).find(
            errorObject => errorObject.message && errorObject.message.length > 0
        );
        this.errorMessage = firstError ? firstError.message : '';
        // }
    };

    @action
    setUberNextView = (uberNextView: UberView, sessionId: string) => {
        const {
            rootStore: {
                stores: {
                    navigationStore: { navigate },
                },
            },
        } = this.servicesRootStore;
        this.clearError();
        if (this.activeView === uberNextView) {
            return;
        }
        this.sessionId = sessionId;
        this.activeView = uberNextView;
        switch (uberNextView) {
            case 'EMAIL':
                navigate('Email');
                break;
            case 'PHONE_OTP':
                navigate('OTP');
                break;
            case 'CREDENTIALS':
                navigate('Credentials');
                break;
            case 'PASSWORD':
                navigate('Password');
                break;
            case 'CAPTCHA':
                navigate('Captcha');
                break;
            case 'TOTP_VERIFICATION':
                navigate('TOTP');
                break;
            default:
                navigate('Services');
                Analytics.trackScreenView('04-02_Accounts');
                break;
        }
    };

    @action
    onCaptchaNavStateChanged = (state: NavState) => {
        const { url } = state;
        if (url && url.includes('token') && !this.captchaToken.length) {
            const [, token] = url.split('token=');
            this.captchaToken = token;
            this.onUberCaptchaSubmit(token);
        }
    };

    downloadApp = () => {
        Linking.openURL(
            Platform.OS === 'android'
                ? 'https://play.google.com/store/apps/details?id=com.ubercab'
                : 'https://apps.apple.com/app/uber/id368677368'
        )
            .then(() => {
                Analytics.trackServiceLinking('uber', 'connecting', 'download_app');
            })
            .catch(() => {});
    };
}

import { observable, action } from 'mobx';

export default class KeyboardStore {
    @observable
    keyboardShown = false;

    @observable
    keyboardHeight = 0;

    @action
    onKeyboardShow = ({ endCoordinates: { height } }: { endCoordinates: { height: number } }) => {
        this.keyboardHeight = height;
        this.keyboardShown = true;
    };

    @action
    onKeyboardHide = () => {
        this.keyboardHeight = 0;
        this.keyboardShown = false;
    };
}

import React from 'react';
import { TouchableOpacity, Text, Image, View } from 'react-native';

import InviteFriendsListItemStyles from './styles/InviteFriendsListItemStyles';
import { Images } from '../../themes/index';

interface Props {
    icon: any;
    text: string;
    onPress: () => void;
    index?: number;
}

export default ({ icon, text, onPress, index }: Props) => (
    <TouchableOpacity style={InviteFriendsListItemStyles.container} onPress={onPress}>
        <Image style={InviteFriendsListItemStyles.image} source={icon} resizeMode="contain" />
        <View style={InviteFriendsListItemStyles.innerContainer}>
            <Text style={InviteFriendsListItemStyles.text}>{text}</Text>
        </View>
        <Image
            source={Images.inviteFriends.select}
            style={InviteFriendsListItemStyles.selectImage}
        />
    </TouchableOpacity>
);

import Config from 'react-native-config';
import LottieView from 'lottie-react-native';
import React, { Component } from 'react';
import { NavigationParams } from 'react-navigation';
import { observer, inject } from 'mobx-react';
import { StyleSheet, Platform, View, BackHandler } from 'react-native';
import { computed } from 'mobx';

import Alert from 'modules/alert/alert';

import BikeDetailsView from '../../components/taxis/bikes/BikeDetailsView';
import BikeInfoBottomView from '../../components/taxis/bikes/bikeInfoBottomView/BikeInfoBottomView';
import BikeStore from '../../stores/BikeStore';
import Button from '../../components/Button';
import InfoScreen from './FeatureInformationPopup';
import MainAddressPicker from 'components/addressPickers/MainAddressPicker';
import Map from '../../components/map';
import MapAddressPicker from 'components/addressPickers/MapAddressPicker';
import MapLanding from '../../components/landing/MapLanding';
import PredictionsView from '../../components/predictions/PredictionsView';
import RideAddressPicker from 'components/addressPickers/RideAddressPicker';
import RideDetailsView from './RideDetailsView';
import RideFinishedView from '../../components/taxis/rideFinishedView/RideFinishedView';
import TaxiMapStyles from './styles/TaxiMapStyles';
import { Images } from '../../themes';
import { Localizable } from '../../lib';
import {
    BottomRideView,
    DefaultPopup,
    FiltersPopup,
    PaymentMethodsPopup,
    SeatsPopup,
    SurgeConfirmation,
    TaxisComparation,
    TopRideSummary,
    VenuesPopup,
    ChangeRideStatusButton,
} from 'components/taxis';
import AddressStore from '../../stores/AddressStore';
import AppStore from '../../stores/AppStore';
import AuthStore from '../../stores/AuthStore';
import FiltersStore from '../../stores/FiltersStore';
import InviteFriends from '../../components/inviteFriends/InviteFriends';
import LinkingStore from '../../stores/LinkingStore';
import NavigationStore from 'stores/navigationStores/NavigationStore';
import OptionsNavigationBar from 'components/navigationBars/OptionsNavigationBar';
import Options from '../setup/Options';
import OptionsStore from '../../stores/OptionsStore';
import PredictionStore from '../../stores/dataStores/PredictionStore';
import SplashScreenStore from '../../stores/SplashScreenStore';
import TaxiStore from '../../stores/TaxiStore';
import TooltipStore from '../../stores/TooltipStore';
import { ReferralStore } from 'stores';
import { Stores } from '../../stores/RootStore';

interface Props {
    addressStore: AddressStore;
    appStore: AppStore;
    authStore: AuthStore;
    bikeStore: BikeStore;
    filtersStore: FiltersStore;
    linkingStore: LinkingStore;
    navigation: NavigationParams;
    navigationStore: NavigationStore;
    optionsStore: OptionsStore;
    predictionStore: PredictionStore;
    referralStore: ReferralStore;
    splashScreenStore: SplashScreenStore;
    taxiStore: TaxiStore;
    tooltipStore: TooltipStore;
}

@inject(
    ({
        addressStore,
        appStore,
        authStore,
        bikeStore,
        filtersStore,
        linkingStore,
        navigationStore,
        optionsStore,
        predictionStore,
        referralStore,
        splashScreenStore,
        taxiStore,
        tooltipStore,
    }: Stores) => ({
        addressStore,
        appStore,
        authStore,
        bikeStore,
        filtersStore,
        linkingStore,
        navigationStore,
        optionsStore,
        predictionStore,
        referralStore,
        splashScreenStore,
        taxiStore,
        tooltipStore,
    })
)
@observer
export default class MainView extends Component<Props> {
    componentDidMount() {
        const {
            navigationStore: { setMainViewRef },
        } = this.props;
        setMainViewRef(this);
    }

    handleBackClick = (shouldCloseApp?: boolean) => {
        const {
            taxiStore: { centerMap, taxiState, newDestinationAddressFocused, handleBackClick },
            navigation,
            navigationStore,
            navigationStore: {
                mapLandingRefStore,
                mapLandingRefStore: { mapLandingBackHandler },
            },
            predictionStore: {
                predictionsScreenShow,
                setPredictions,
                togglePredictionsTooltip,
                togglePredictionsScreen,
            },
            bikeStore: { selectedBikeOnMap, clearBikeOnMap, selectedBike, clearBikeSelection },
        } = this.props;

        if (predictionsScreenShow) {
            setPredictions([]);
            togglePredictionsTooltip(true);
            togglePredictionsScreen(false);
        }
        if (selectedBikeOnMap) {
            clearBikeOnMap();
            centerMap();
            return;
        }
        if (selectedBike) {
            clearBikeSelection();
            centerMap();
            return;
        }
        if (
            (taxiState === 'start' &&
                !selectedBikeOnMap &&
                mapLandingRefStore.mapLandingCurrentSnap === 'down') ||
            (taxiState === 'ride' && !newDestinationAddressFocused)
        ) {
            if (shouldCloseApp === true) {
                BackHandler.exitApp();
                return;
            }

            if (Platform.OS === 'web') {
                navigationStore.openDrawer();
            } else {
                navigation.openDrawer();
            }
        }
        if (
            taxiState === 'start' &&
            !selectedBikeOnMap &&
            !!mapLandingBackHandler &&
            mapLandingRefStore.mapLandingCurrentSnap !== 'down'
        ) {
            mapLandingBackHandler();
        }

        // if (taxiState === 'confirm') {
        //     console.log('rideDetailsViewRef', rideDetailsViewRef);
        //     // setTaxiState('taxis');
        // }
        handleBackClick();
    };

    handleOptionsClick = () => {
        const { optionsStore } = this.props;
        optionsStore.toggleOptionsOpened();
    };

    @computed
    get title() {
        const {
            taxiStore: {
                taxiState,
                taxiRideStore: { currentRideStatus, rideRequestInProgress },
                selectedTaxiStore,
            },
            addressStore: { focusedInput },
        } = this.props;

        if (taxiState === 'locations' && focusedInput === 'pickup') {
            return Localizable.t('taxi.enterPickup');
        }
        if (taxiState === 'locations') {
            return Localizable.t('taxi.enterDestination');
        }
        if (taxiState === 'taxis') {
            return Localizable.t('taxi.selectRide');
        }
        if (taxiState === 'confirm') {
            if (rideRequestInProgress) {
                return Localizable.t('taxi.requestingRide');
            }
            return `${Localizable.t('taxi.book')} ${
                selectedTaxiStore.selectedTaxi.provider.company
            }`;
        }
        if (taxiState === 'selectPickup') {
            return Localizable.t('taxi.selectPickup');
        }

        if (taxiState === 'selectDestination') {
            return Localizable.t('taxi.selectDestination');
        }
        if (currentRideStatus && currentRideStatus.location && currentRideStatus.driver) {
            if (currentRideStatus.status_v2 === 'ARRIVED') {
                return Localizable.t('taxi.driverArrived');
            }
            if (currentRideStatus.status_v2 === 'IN_PROGRESS') {
                return Localizable.t('taxi.tripInProgress');
            }
            return Localizable.t('taxi.driverEnRoute');
        }
        if (currentRideStatus && currentRideStatus.status === 'PENDING') {
            return Localizable.t('taxi.findingRide');
        }
        return Localizable.t('taxi.transport');
    }

    setLottieAnimation = animation => {
        this.animation = animation;
        if (animation) {
            animation.play();
        }
    };

    renderBottom = () => {
        const {
            predictionStore,
            bikeStore,
            taxiStore,
            taxiStore: { taxiRideStore },
        } = this.props;
        if (predictionStore.predictionsScreenShow) {
            return <PredictionsView />;
        }
        if (bikeStore.selectedBike) {
            return <BikeDetailsView />;
        }
        if (bikeStore.selectedBikeOnMap) {
            return <BikeInfoBottomView />;
        }
        if (taxiRideStore.rideRequestInProgress) {
            return null;
        }
        if (taxiStore.taxiState === 'ride' && !!taxiRideStore.currentRideStatus) {
            return <BottomRideView />;
        }
        if (
            (taxiStore.taxiState === 'taxis' || taxiStore.taxiState === 'confirm') &&
            !bikeStore.selectedBike
        ) {
            return <TaxisComparation />;
        }
        return null;
    };

    @computed
    get shouldShowAnimation() {
        const {
            taxiStore: {
                taxiRideStore: { currentRideStatus, rideRequestInProgress },
            },
        } = this.props;
        return (
            rideRequestInProgress ||
            (!!currentRideStatus &&
                currentRideStatus.status === 'PENDING' &&
                !currentRideStatus.driver)
        );
    }

    render() {
        const {
            addressStore,
            addressStore: { showVenuesPopup, selectVenue, hideVenuesPopup },
            predictionStore,
            bikeStore: { selectedBikeOnMap },
            tooltipStore,
            taxiStore,
            taxiStore: {
                taxiRideStore,
                taxiRideStore: { handleCancelRide, setRideFinished, hideSurgePopup, handleSurge },
                selectedTaxiStore,
            },
            navigationStore,
            linkingStore,
            linkingStore: { registerForPush, hidePushNeededPopup },
            filtersStore,
            optionsStore,
            referralStore,
        } = this.props;
        return (
            <View style={TaxiMapStyles.container} testID="view_main">
                <View style={StyleSheet.absoluteFill}>
                    <Map />
                    {this.shouldShowAnimation && (
                        <View pointerEvents="box-none" style={TaxiMapStyles.animationContainer}>
                            <View pointerEvents="none" style={TaxiMapStyles.lottieViewContainer}>
                                <LottieView
                                    style={TaxiMapStyles.lottieView}
                                    ref={this.setLottieAnimation}
                                    source={Images.animations.lookingForDriver}
                                    loop
                                    imageAssetsFolder="lottie"
                                />
                            </View>
                            {!taxiRideStore.rideRequestInProgress && (
                                <View
                                    pointerEvents="box-none"
                                    style={TaxiMapStyles.cancelRequestView}
                                >
                                    <Button
                                        title="Cancel Request"
                                        active={false}
                                        handlePress={handleCancelRide}
                                    />
                                </View>
                            )}
                        </View>
                    )}
                </View>
                <OptionsNavigationBar
                    backImage={
                        (taxiStore.taxiState === 'start' && !selectedBikeOnMap) ||
                        (taxiStore.taxiState === 'ride' && !taxiStore.newDestinationAddressFocused)
                            ? Images.icons.menu
                            : predictionStore.predictionsScreenShow
                            ? null
                            : Images.icons.back
                    }
                    handleBackClick={this.handleBackClick}
                    handleOptionsClick={optionsStore.toggleOptionsOpened}
                />
                {this.renderBottom()}
                {taxiStore.taxiState === 'confirm' && <RideDetailsView />}
                {taxiStore.taxiState === 'ride' && Config.TEST === '1' && (
                    <ChangeRideStatusButton />
                )}
                {taxiStore.taxiState !== 'start' &&
                    taxiStore.taxiState !== 'confirm' &&
                    taxiStore.taxiState !== 'ride' &&
                    taxiStore.taxiState !== 'selectPickup' &&
                    taxiStore.taxiState !== 'selectDestination' &&
                    taxiStore.taxiState !== 'taxis' && (
                        <MainAddressPicker pointerEvents="box-none" editable={false} />
                    )}
                {(taxiStore.taxiState === 'selectPickup' ||
                    taxiStore.taxiState === 'selectDestination') && <MapAddressPicker />}
                {taxiStore.taxiState === 'start' && !selectedBikeOnMap && <MapLanding />}
                {!!taxiRideStore.currentRideStatus &&
                    !(
                        taxiRideStore.currentRideStatus.status === 'PENDING' &&
                        !!taxiRideStore.currentRideStatus.driver
                    ) && <RideAddressPicker />}
                {!!taxiRideStore.currentRideStatus &&
                    taxiRideStore.currentRideStatus.status === 'PENDING' &&
                    !!taxiRideStore.currentRideStatus.driver && (
                        <TopRideSummary taxiStore={taxiStore} />
                    )}
                {taxiRideStore.rideFinished && (
                    <RideFinishedView
                        closeView={() => setRideFinished(false)}
                        requestId={taxiRideStore.activeRideRequestId}
                    />
                )}
                {selectedTaxiStore.showSeatsPopup && <SeatsPopup />}
                <PaymentMethodsPopup />
                {taxiRideStore.showSurgePopup && (
                    <SurgeConfirmation
                        url={taxiRideStore.surgeUrl}
                        handleSurge={handleSurge}
                        cancel={hideSurgePopup}
                    />
                )}
                {showVenuesPopup && (
                    <VenuesPopup
                        venues={addressStore.venues}
                        venueSelected={selectVenue}
                        hidePopup={hideVenuesPopup}
                    />
                )}
                {linkingStore.pushNeededPopupShown && (
                    <DefaultPopup
                        title={Localizable.t('taxis.mainView.notifications')}
                        description={Localizable.t('taxis.mainView.enableNotifications')}
                        icon={Images.popups.notification}
                        skipable={false}
                        buttonTitle={Localizable.t('taxis.mainView.enable')}
                        accepted={() => {
                            hidePushNeededPopup();
                            registerForPush();
                        }}
                        step={1}
                        maxSteps={1}
                    />
                )}
                {filtersStore.filtersPopupShown && <FiltersPopup />}
                {tooltipStore.informationScreenShow && <InfoScreen />}
                {referralStore.shouldShowReferralDrawer && <InviteFriends />}
                <Options />
            </View>
        );
    }
}

const analytics = {
    setup: async () => {},
    screen: async () => {},
    identify: async () => {},
    track: async () => {},
};
const Options = {};
const JsonMap = {};

export default analytics;
export { Options, JsonMap };

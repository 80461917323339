/* eslint-disable camelcase */
import moment from 'moment';
import { persist } from 'mobx-persist';
import { observable, action, computed, flow } from 'mobx';
import { AppStateStatus, AppState, BackHandler, Platform } from 'react-native';

import BaseStore from './BaseStore';
import { Analytics } from 'lib';
import { AppLifecycle, PersistDataStore } from './RootStore';

export default class AppStore extends BaseStore implements AppLifecycle, PersistDataStore {
    hydrateStore = (hydrate: Function) => {
        return hydrate('appStore', this);
    };

    @observable
    loading: boolean | null = null;

    @observable
    profile: boolean = false;

    @observable
    appHydrated: boolean = false;

    @observable
    appState: AppStateStatus = AppState.currentState;

    @observable
    lastActive?: string;

    @persist
    @observable
    termsAccepted: boolean = false;

    @persist
    @observable
    showAddToScreenPopup: boolean = true;

    appDidMount = flow(function*(this: AppStore) {
        const {
            hydrateStores,
            onAppStarted,
            stores: {
                authStore,
                authStore: { initAuth },
                referralStore: { generateDynamicLink },
            },
        } = this.rootStore;
        yield Analytics.init(authStore.user?.user_id);
        yield hydrateStores();
        this.appHydrated = true;
        yield initAuth();
        onAppStarted();
        generateDynamicLink();
    }).bind(this);

    onAppStarted = () => {
        this.addEventsListeners();
    };

    onAppFinished = () => {
        this.removeEventsListeners();
    };

    @action
    acceptTermsAndConditions = () => {
        this.termsAccepted = true;
    };

    addEventsListeners = () => {
        const {
            stores: {
                navigationStore: { handleHardwareBackClick },
            },
        } = this.rootStore;
        AppState.addEventListener('change', this.onAppStateChanged);
        BackHandler.addEventListener('hardwareBackPress', handleHardwareBackClick);
    };

    removeEventsListeners = () => {
        const {
            stores: {
                navigationStore: { handleHardwareBackClick },
            },
        } = this.rootStore;
        AppState.removeEventListener('change', this.onAppStateChanged);
        BackHandler.removeEventListener('hardwareBackPress', handleHardwareBackClick);
    };

    @action
    appWillUnmount = () => {
        const { onAppFinished } = this.rootStore;
        onAppFinished();
    };

    @action
    onAppStateChanged = (nextAppState: AppStateStatus) => {
        const { onAppWilGoToForeground, onAppWillGoToBackground } = this.rootStore;
        if (
            (this.appState === 'inactive' || this.appState === 'background') &&
            nextAppState === 'active'
        ) {
            this.reactivateAppAfterBackground();
            onAppWilGoToForeground();
        } else if (nextAppState === 'background') {
            this.setLastActive();
            onAppWillGoToBackground();
        }
        this.appState = nextAppState;
    };

    @action
    setLastActive = () => {
        this.lastActive = moment().toISOString();
    };

    @action
    reactivateAppAfterBackground = () => {
        const {
            taxiStore,
            taxiStore: {
                getUserLocation,
                selectedTaxiStore: {
                    selectedTaxiDeeplinkStore,
                    selectedTaxiDeeplinkStore: { clearDeeplinkStart },
                },
                taxiRideStore: { resetRide },
            },
        } = this.rootStore.stores;
        if (this.lastActive && this.lastActive.length && taxiStore.taxiState === 'start') {
            getUserLocation(true, false, 'AppStore reactivateAppAfterBackground');
        }
        if (selectedTaxiDeeplinkStore.deeplinkStart) {
            if (moment().diff(selectedTaxiDeeplinkStore.deeplinkStart, 'minute') >= 10) {
                resetRide();
            }
            clearDeeplinkStart();
        }
    };

    @action
    showLoading = () => {
        this.loading = true;
    };

    @action
    hideLoading = () => {
        this.loading = false;
    };

    @action
    hideAddToScreenPopup = () => {
        this.showAddToScreenPopup = false;
    };

    @computed
    get shouldShowAddToScreenInstruction(): boolean {
        return (
            Platform.OS === 'web' &&
            !(
                window.matchMedia('(display-mode: standalone)').matches ||
                window.navigator.standalone
            ) &&
            this.showAddToScreenPopup
        );
    }
}

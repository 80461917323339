import React, { RefObject } from 'react';
import { FlatList } from 'react-native';
import { observable, action, computed } from 'mobx';

import RootStore from 'stores/RootStore';
import { Localizable } from 'lib';
import {
    isXL,
    isShared,
    isCarSeat,
    isStandard,
    isLuxury,
} from '../../../domain/serviceProviders/TaxiServices';

export type SelectedTypesTab =
    | { sort: 'cheapest'; type: 'ascending' }
    | { sort: 'cheapest'; type: 'descending' }
    | { sort: 'fastest'; type: 'ascending' }
    | { sort: 'fastest'; type: 'descending' };

export type Section = 'Shared' | 'Standard' | 'XL' | 'Luxury' | 'Other';

export type State = 'hidden' | 'minimized' | 'maximized';

export interface SectionData {
    data: Array<any>;
    key: string;
    state: State;
    title: Section;
    description: string;
}

export interface SectionsState {
    Shared: State;
    Standard: State;
    XL: State;
    Luxury: State;
    Other: State;
}

export default class TaxisComparationStore {
    rootStore: RootStore;

    @observable.ref
    flatListRef: RefObject<FlatList<any>> = React.createRef();

    @observable
    selectedTypesTab: SelectedTypesTab = { sort: 'cheapest', type: 'ascending' };

    @observable
    sectionsState: SectionsState = {
        Shared: 'minimized',
        Standard: 'minimized',
        XL: 'minimized',
        Luxury: 'minimized',
        Other: 'minimized',
    };

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
    }

    @action
    typesTabSelected = (type: SelectedTypesTab) => {
        this.selectedTypesTab = type;
    };

    @action
    setSectionState = (section: Section, state: State) => {
        this.sectionsState[section] = state;
    };

    handleSectionHeaderClick = (section: SectionData) => {
        switch (this.sectionsState[section.title]) {
            case 'maximized':
                this.setSectionState(section.title, 'hidden');
                break;
            case 'minimized':
                if (this[section.key].length <= 2) {
                    this.setSectionState(section.title, 'hidden');
                } else {
                    this.setSectionState(section.title, 'maximized');
                }
                break;
            default:
                this.setSectionState(section.title, 'maximized');
                break;
        }
    };

    @computed
    get shared() {
        const {
            taxiStore: { taxiResultsStore },
        } = this.rootStore.stores;
        return taxiResultsStore.rideTypesOrdered.filter(isShared);
    }

    @computed
    get standard() {
        const {
            taxiStore: { taxiResultsStore },
        } = this.rootStore.stores;
        return taxiResultsStore.rideTypesOrdered.filter(isStandard);
    }

    @computed
    get xl() {
        const {
            taxiStore: { taxiResultsStore },
        } = this.rootStore.stores;
        return taxiResultsStore.rideTypesOrdered.filter(isXL);
    }

    @computed
    get luxury() {
        const {
            taxiStore: { taxiResultsStore },
        } = this.rootStore.stores;
        return taxiResultsStore.rideTypesOrdered.filter(isLuxury);
    }

    @computed
    get other() {
        const {
            taxiStore: { taxiResultsStore },
        } = this.rootStore.stores;
        return taxiResultsStore.rideTypesOrdered.filter(
            item =>
                (!isStandard(item) && !isShared(item) && !isXL(item) && !isLuxury(item)) ||
                isCarSeat(item)
        );
    }

    @computed
    get sharedSectionData() {
        switch (this.sectionsState.Shared) {
            case 'maximized':
                return this.shared;
            case 'minimized':
                return this.shared.length >= 2
                    ? [this.shared[0], this.shared[1]]
                    : this.shared.length === 1
                    ? [this.shared[0]]
                    : [];
            default:
                return [];
        }
    }

    @computed
    get standardSectionData() {
        switch (this.sectionsState.Standard) {
            case 'maximized':
                return this.standard;
            case 'minimized':
                return this.standard.length >= 2
                    ? [this.standard[0], this.standard[1]]
                    : this.standard.length === 1
                    ? [this.standard[0]]
                    : [];
            default:
                return [];
        }
    }

    @computed
    get xlSectionData() {
        switch (this.sectionsState.XL) {
            case 'maximized':
                return this.xl;
            case 'minimized':
                return this.xl.length >= 2
                    ? [this.xl[0], this.xl[1]]
                    : this.xl.length === 1
                    ? [this.xl[0]]
                    : [];
            default:
                return [];
        }
    }

    @computed
    get luxurySectionData() {
        switch (this.sectionsState.Luxury) {
            case 'maximized':
                return this.luxury;
            case 'minimized':
                return this.luxury.length >= 2
                    ? [this.luxury[0], this.luxury[1]]
                    : this.luxury.length === 1
                    ? [this.luxury[0]]
                    : [];
            default:
                return [];
        }
    }

    @computed
    get otherSectionData() {
        switch (this.sectionsState.Other) {
            case 'maximized':
                return this.other;
            case 'minimized':
                return this.other.length >= 2
                    ? [this.other[0], this.other[1]]
                    : this.other.length === 1
                    ? [this.other[0]]
                    : [];
            default:
                return [];
        }
    }

    @computed
    get sections(): Array<SectionData> {
        return [
            {
                key: 'shared',
                title: 'Shared',
                data: this.sharedSectionData,
                state: this.sectionsState.Shared,
                description: Localizable.t('taxis.comparationSections.shared'),
            },
            {
                key: 'standard',
                title: 'Standard',
                data: this.standardSectionData,
                state: this.sectionsState.Standard,
                description: Localizable.t('taxis.comparationSections.standard'),
            },
            {
                key: 'xl',
                title: 'XL',
                data: this.xlSectionData,
                state: this.sectionsState.XL,
                description: Localizable.t('taxis.comparationSections.xl'),
            },

            {
                key: 'luxury',
                title: 'Luxury',
                data: this.luxurySectionData,
                state: this.sectionsState.Luxury,
                description: Localizable.t('taxis.comparationSections.luxury'),
            },
            {
                key: 'other',
                title: 'Other',
                data: this.otherSectionData,
                state: this.sectionsState.Other,
                description: Localizable.t('taxis.comparationSections.other'),
            },
        ];
    }

    @computed
    get sectionsEmpty(): boolean {
        return (
            this.standard.length === 0 &&
            this.shared.length === 0 &&
            this.xl.length === 0 &&
            this.luxury.length === 0 &&
            this.other.length === 0
        );
    }
}

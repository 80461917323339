/* eslint-disable no-underscore-dangle */
/* eslint-disable no-plusplus */
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Animated, PanResponder, PanResponderInstance, Easing } from 'react-native';
import { observable, action } from 'mobx';

type OnDragState = 'start' | 'end';

interface Props {
    snapPoints: Array<SnapPoint>;
    animatedValueY: Animated.Value;
    initialPosition: { y: number };
    onSnap?: (event: { nativeEvent: { id: string; index: number } }) => void;
    onSnapStart?: (event: { nativeEvent: { id: string; index: number } }) => void;
    onDrag?: (event: {
        nativeEvent: { state: OnDragState; index: number; targetSnapPointId?: string };
    }) => void;
    boundaries?: { bottom?: number; top?: number };
}

interface SnapPoint {
    id: string;
    y: number;
}

function findInitialSnapIndex(props: Props): number {
    const {
        snapPoints,
        initialPosition: { y },
    } = props;
    const snapIndex = snapPoints.findIndex(snapPoint => snapPoint.y === y);
    return snapIndex === -1 ? 0 : snapIndex;
}

@observer
class View extends Component<Props> {
    @observable
    currentSnapIndex: number = findInitialSnapIndex(this.props);

    panResponder: PanResponderInstance = { panHandlers: {} };

    @action
    setCurrentSnapIndex = (index: number) => {
        this.currentSnapIndex = index;
    };

    findClosestPointIndex = (touchPoint: number) => {
        const { snapPoints } = this.props;
        return snapPoints.indexOf(
            snapPoints.reduce(
                (prev, curr) =>
                    Math.abs(prev.y - touchPoint) < Math.abs(curr.y - touchPoint) ? prev : curr,
                { y: Infinity, id: '' }
            )
        );
    };

    snapTo = ({ index, touchEndPoint }: { index: number; touchEndPoint?: number }) => {
        const { animatedValueY, snapPoints, onSnap, onSnapStart } = this.props;
        const animationTime =
            index === this.currentSnapIndex
                ? 100
                : touchEndPoint
                ? Math.abs(touchEndPoint - snapPoints[index].y)
                : Math.abs(snapPoints[this.currentSnapIndex].y - snapPoints[index].y) / 2;
        if (onSnapStart) {
            onSnapStart({ nativeEvent: { id: snapPoints[index].id, index } });
        }
        Animated.timing(animatedValueY, {
            toValue: snapPoints[index].y,
            duration: animationTime,
            easing: Easing.ease,
        }).start(() => {
            if (onSnap) {
                onSnap({ nativeEvent: { id: snapPoints[index].id, index } });
            }
        });
        this.setCurrentSnapIndex(index);
    };

    render() {
        const { animatedValueY, children, snapPoints } = this.props;
        return (
            <Animated.View
                style={{
                    transform: [
                        {
                            translateY: animatedValueY.interpolate({
                                inputRange: [snapPoints[0].y, snapPoints[1].y],
                                outputRange: [snapPoints[0].y, snapPoints[1].y],
                            }),
                        },
                    ],
                }}
                {...this.panResponder.panHandlers}
            >
                {children}
            </Animated.View>
        );
    }
}

const Interactable = {
    View,
};

export default Interactable;

import { StyleSheet } from 'react-native';
import { Fonts, Metrics, Colors } from 'themes';

export default StyleSheet.create({
    interactableContainer: {
        backgroundColor: Colors.transparent,
    },
    interactabeIndicatorView: {
        backgroundColor: Colors.transparent,
        alignItems: 'center',
        justifyContent: 'center',
        paddingVertical: 8,
    },
    interactableIndicator: {
        width: 45,
        height: 5,
        borderRadius: 10,
        backgroundColor: Colors.typesBackgroundColor,
    },
    interactableInner: {
        flex: 1,
        backgroundColor: Colors.white,
        borderTopLeftRadius: 16,
        borderTopRightRadius: 16,
        shadowColor: '#000000',
        shadowOffset: { width: 0, height: 0 },
        shadowRadius: 4,
        shadowOpacity: 0.2,
        elevation: 20,
    },
    topView: {
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: Metrics.normalize(40),
        paddingBottom: 10,
    },
    inviteFriendsImage: {
        width: 80,
        height: 80,
        marginBottom: 10,
    },
    title: {
        fontFamily: Fonts.type.base,
        fontWeight: '400',
        fontSize: Metrics.normalize(24),
    },
    text: {
        marginTop: Metrics.normalize(15),
        fontWeight: '400',
        fontFamily: Fonts.type.base,
        textAlign: 'center',
        lineHeight: Metrics.normalize(26),
        color: Colors.grayTextColor,
        fontSize: Metrics.normalize(16),
    },
    referralCounterView: {
        alignSelf: 'stretch',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        marginHorizontal: 30,
        marginTop: Metrics.normalize(30),
        marginBottom: Metrics.normalize(5),
        paddingVertical: 12,
        paddingHorizontal: 20,
        borderColor: Colors.lightGrayColor,
        borderWidth: 1,
        borderRadius: 7,
    },
    referralCounterLeftPanel: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    referralCounterImage: {
        width: 20,
        height: 20,
    },
    referralCounterTitle: {
        fontWeight: '400',
        fontFamily: Fonts.type.base,
        color: Colors.grayTextColor,
        fontSize: Metrics.normalize(18),
        marginLeft: 15,
    },
    referralCounter: {
        fontWeight: '400',
        fontFamily: Fonts.type.base,
        color: Colors.darkGreyTextColor,
        fontSize: Metrics.normalize(20),
    },
    inviteFriendsButton: {
        marginTop: 5,
        marginBottom: Metrics.paddingBottom === 0 ? 15 : Metrics.paddingBottom,
        alignSelf: 'stretch',
        paddingVertical: 15,
        marginHorizontal: 20,
        borderRadius: 7,
        backgroundColor: Colors.obiTeal,
        alignItems: 'center',
        justifyContent: 'center',
    },
    inviteFriendsButtonText: {
        fontWeight: '400',
        fontFamily: Fonts.type.base,
        color: 'white',
        fontSize: Metrics.normalize(16),
    },
});

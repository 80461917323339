import React, { Component, RefObject, createRef } from 'react';
import { View, Text, TouchableOpacity, Image, StyleSheet, Animated, Linking } from 'react-native';
import { inject, observer } from 'mobx-react';

import AuthModalStyles from './styles/AuthModalStyles';
import AuthStore from '../../stores/AuthStore';
import BottomDrawer, { YID } from '../drawer/BottomDrawer';
import { Images, Metrics } from '../../themes';
import { Localizable } from '../../lib';
import { Stores } from '../../stores/RootStore';
import NavigationStore from 'stores/navigationStores/NavigationStore';

interface Props {
    authStore: AuthStore;
    navigationStore: NavigationStore;
}

@inject(({ authStore, navigationStore }: Stores) => ({ authStore, navigationStore }))
@observer
export default class AuthModal extends Component<Props> {
    bottomDrawerRef: RefObject<BottomDrawer> = createRef();

    snapPoints: Array<YID> = [
        {
            y: (Metrics.screenHeight * 2) / 3 - 50,
            id: 'up',
        },
        { y: Metrics.screenHeight, id: 'closed' },
    ];

    initialPoint = this.snapPoints[1].y;

    deltaY = new Animated.Value(this.initialPoint);

    boundaries = {
        top: this.snapPoints[0].y,
    };

    close = () => {
        if (this.bottomDrawerRef.current && this.bottomDrawerRef.current.wrappedInstance) {
            this.bottomDrawerRef.current.wrappedInstance.setSnap('closed');
        }
    };

    handleSignupButtonClick = () => {
        const {
            navigationStore: { navigate },
        } = this.props;
        this.close();
        navigate('AdditionalInfo', { type: 'phone' });
    };

    openTermsOfService = () => {
        Linking.openURL(
            'https://bellhop.app/terms/?utm_source=bellhop-app&utm_medium=app-menu&utm_campaign=terms'
        );
    };

    openPrivacyPolicy = () => {
        Linking.openURL(
            'https://bellhop.app/privacy/?utm_source=bellhop-app&utm_medium=app-menu&utm_campaign=privacy'
        );
    };

    render() {
        const { authStore } = this.props;
        return authStore.authPopupVisible ? (
            <View style={StyleSheet.absoluteFill}>
                <BottomDrawer
                    boundaries={this.boundaries}
                    deltaY={this.deltaY}
                    initialPoint={this.initialPoint}
                    onClose={authStore.dismissAuthPopup}
                    openAnimated={{ toIndex: 0 }}
                    ref={this.bottomDrawerRef}
                    snapPoints={this.snapPoints}
                >
                    <View
                        style={[
                            AuthModalStyles.interactableContainer,
                            { height: Metrics.screenHeight / 3 },
                        ]}
                    >
                        <View style={AuthModalStyles.indicatorView}>
                            <View style={AuthModalStyles.indicator} />
                        </View>
                        <View style={AuthModalStyles.modalContainer}>
                            <View style={AuthModalStyles.skipView}>
                                <TouchableOpacity
                                    style={AuthModalStyles.skipButton}
                                    onPress={this.close}
                                >
                                    <Text style={AuthModalStyles.skipButtonText}>
                                        {Localizable.t('authPopup.skip')}
                                    </Text>
                                </TouchableOpacity>
                            </View>
                            <Text style={AuthModalStyles.title}>
                                {Localizable.t('authPopup.auth')}
                            </Text>
                            <Text style={AuthModalStyles.subtitle}>
                                {Localizable.t('authPopup.create')}
                            </Text>
                            <TouchableOpacity
                                style={AuthModalStyles.signupButton}
                                onPress={this.handleSignupButtonClick}
                            >
                                <Image
                                    source={Images.icons.phone}
                                    style={AuthModalStyles.signupButtonIcon}
                                    resizeMode="contain"
                                />
                                <Text style={AuthModalStyles.signupButtonText}>
                                    {Localizable.t('authPopup.enter')}
                                </Text>
                            </TouchableOpacity>
                            <Text style={AuthModalStyles.bottomText}>
                                {Localizable.t('authPopup.accept')}
                                {'\n'}
                                <Text
                                    style={AuthModalStyles.bottomTextUnderlined}
                                    onPress={this.openTermsOfService}
                                >
                                    {Localizable.t('authPopup.termsOfService')}
                                </Text>
                                {' & '}
                                <Text
                                    style={AuthModalStyles.bottomTextUnderlined}
                                    onPress={this.openPrivacyPolicy}
                                >
                                    {Localizable.t('authPopup.privacyPolicy')}
                                </Text>
                            </Text>
                        </View>
                    </View>
                </BottomDrawer>
            </View>
        ) : null;
    }
}

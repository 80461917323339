import React, { Component } from 'react';
import { SectionList, View, Text, TouchableOpacity, Image, Switch } from 'react-native';

import { Images } from '../../themes';

import GroupedListViewStyles from './styles/GroupedListViewStyles';

interface Props {
    sections: Array<any>;
}

interface Item {
    item: {
        icon: number;
        title: string;
        onPress: () => void;
        description?: string;
        enabled?: boolean;
        value?: number;
        onValueChanged?: () => void;
        switch?: boolean;
    };
}

export default class GroupedListView extends Component<Props> {
    renderRow = ({ item }: Item) => (
        <TouchableOpacity
            onPress={() => {
                if (item.onPress) {
                    item.onPress();
                }
            }}
        >
            <View style={GroupedListViewStyles.rowView}>
                {item.icon && <Image source={item.icon} style={GroupedListViewStyles.icon} />}
                {(item.description && (
                    <View style={GroupedListViewStyles.descriptionView}>
                        <Text style={GroupedListViewStyles.rowText}>{item.title}</Text>
                        <Text style={GroupedListViewStyles.descriptionText}>
                            {item.description}
                        </Text>
                    </View>
                )) || <Text style={GroupedListViewStyles.rowText}>{item.title}</Text>}
                <Text style={GroupedListViewStyles.valueText}>{item.value}</Text>
                {(item.switch && (
                    <Switch value={item.enabled} onValueChange={item.onValueChanged} />
                )) || (
                    <Image
                        resizeMode="contain"
                        source={Images.settings.rightArrow}
                        style={GroupedListViewStyles.arrow}
                    />
                )}
            </View>
        </TouchableOpacity>
    );

    renderHeader = ({ section }: any) => (
        <View style={GroupedListViewStyles.headerContainer}>
            <Text style={GroupedListViewStyles.sectionText}>{section.title}</Text>
            <View
                style={[
                    GroupedListViewStyles.separator,
                    {
                        position: 'absolute',
                        left: 0,
                        right: 0,
                        bottom: 0,
                    },
                ]}
            />
        </View>
    );

    renderFooter = ({ section }: any) => <View style={GroupedListViewStyles.separator} />;

    renderSeparator = () => (
        <View style={GroupedListViewStyles.separatorContainer}>
            <View style={[GroupedListViewStyles.separator, { marginLeft: 55 }]} />
        </View>
    );

    render() {
        const { sections } = this.props;

        return (
            <SectionList
                renderItem={this.renderRow}
                renderSectionHeader={this.renderHeader}
                sections={sections}
                renderSectionFooter={this.renderFooter}
                ItemSeparatorComponent={this.renderSeparator}
            />
        );
    }
}

import { StyleSheet, Platform } from 'react-native';
import { Fonts, Metrics, Colors } from '../../../../themes';

export default StyleSheet.create({
    container: {
        alignSelf: 'stretch',
        flexDirection: 'row',
        paddingVertical: 15,
    },
    iconView: {
        width: 50,
        height: 50,
        justifyContent: 'center',
    },
    iconInnerView: {
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 20,
        width: 50,
        height: 50,
    },
    icon: {
        width: 35,
        height: 30,
    },
    titleView: {
        flex: 2,
        justifyContent: 'center',
        alignItems: 'flex-start',
        paddingLeft: Platform.OS === 'android' ? 10 : 15,
    },
    title: {
        fontFamily: Fonts.type.base,
        fontWeight: '500',
        fontSize: Metrics.normalize(15),
        color: Colors.black,
    },
    availabilityTextView: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    availabilityText: {
        fontFamily: Fonts.type.base,
        fontWeight: '300',
        fontSize: Metrics.normalize(12.13),
        color: Colors.lightGrayText,
        marginRight: 5,
    },
    availabilityIcon: {
        width: 11,
        height: 11,
    },
    buttonView: {
        flex: 2,
        alignItems: 'flex-end',
        justifyContent: 'center',
    },
    linkButton: {
        paddingHorizontal: 16,
        paddingVertical: 4,
        borderRadius: 16,
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
    },
    linkButtonText: {
        fontFamily: Fonts.type.base,
        fontWeight: '600',
        fontSize: Metrics.normalize(12),
    },
    linkButtonIcon: {
        width: 10,
        height: 10,
        marginLeft: 5,
    },
});

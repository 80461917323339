/* eslint-disable global-require */
/* eslint-disable import/no-unresolved */

const images = {
    addressPicker: {
        add: require('../images/addressPicker/add.png'),
        close: require('../images/addressPicker/close.png'),
        switch: require('../images/addressPicker/switch.png'),
        destinationFocused: require('../images/addressPicker/address_picker_destination_focused.png'),
        pickupFocused: require('../images/addressPicker/address_picker_pickup_focused.png'),
        centerMap: require('../images/addressPicker/center_map.png'),
        currentLocation: require('../images/addressPicker/current_location.png'),
    },
    animations: {
        lookingForDriver: require('../images/animations/lookingForDriver.json'),
    },
    badges: {
        bestValue: require('../images/badges/best_value.png'),
        surgeGreen: require('../images/badges/surge_green.png'),
        surgeYellow: require('../images/badges/surge_yellow.png'),
        surgeRed: require('../images/badges/surge_red.png'),
    },
    bikes: {
        icon: {
            active: require('../images/bikes/icon/bike_active.png'),
            inactive: require('../images/bikes/icon/bike_inactive.png'),
            disabled: require('../images/bikes/icon/bike_disabled.png'),
        },
        citi: {
            provider: require('../images/bikes/citi/citibike.png'),
            pin: {
                full: require('../images/bikes/citi/pin/citi_full.png'),
                empty: require('../images/bikes/citi/pin/citi_empty.png'),
            },
        },
        scoot: {
            provider: require('../images/bikes/scoot/scoot.png'),
            pin: {
                full: require('../images/bikes/scoot/pin/scoot_full.png'),
                empty: require('../images/bikes/scoot/pin/scoot_empty.png'),
            },
        },
        pace: {
            provider: require('../images/bikes/pace/pace.png'),
            pin: {
                full: require('../images/bikes/pace/pin/pace_full.png'),
                empty: require('../images/bikes/pace/pin/pace_empty.png'),
            },
        },
        jump: {
            provider: require('../images/bikes/jump/jumpLogo.png'),
            pin: {
                full: require('../images/bikes/jump/pin/jump_pin.png'),
            },
        },
        bluebikes: {
            provider: require('../images/bikes/bluebikes/bluebikes.png'),
            pin: {
                full: require('../images/bikes/bluebikes/pin/bluebikes_full.png'),
                empty: require('../images/bikes/bluebikes/pin/bluebikes_empty.png'),
            },
        },
        fordgobike: {
            provider: require('../images/bikes/fordgobike/fordgobike.png'),
            pin: {
                full: require('../images/bikes/fordgobike/pin/ford_full.png'),
                empty: require('../images/bikes/fordgobike/pin/ford_empty.png'),
            },
        },
        biketown: {
            provider: require('../images/bikes/biketown/biketown.png'),
            pin: {
                full: require('../images/bikes/pin/biketown_full.png'),
                empty: require('../images/bikes/pin/biketown_empty.png'),
            },
        },
        cogo: {
            provider: require('../images/bikes/cogo/cogo.png'),
            pin: {
                full: require('../images/bikes/cogo/pin/cogo_full.png'),
                empty: require('../images/bikes/cogo/pin/cogo_empty.png'),
            },
        },
        divvy: {
            provider: require('../images/bikes/divvy/divvy.png'),
            pin: {
                full: require('../images/bikes/divvy/pin/divvy_full.png'),
                empty: require('../images/bikes/divvy/pin/divvy_empty.png'),
            },
        },
        capitalbikeshare: {
            provider: require('../images/bikes/capitalbikeshare/capitalbikeshare.png'),
            pin: {
                full: require('../images/bikes/capitalbikeshare/pin/capital_full.png'),
                empty: require('../images/bikes/capitalbikeshare/pin/capital_empty.png'),
            },
        },
        niceride: {
            provider: require('../images/bikes/niceride/niceride.png'),
            pin: {
                full: require('../images/bikes/niceride/pin/nicebikes_full.png'),
                empty: require('../images/bikes/niceride/pin/nicebikes_empty.png'),
            },
        },
        metrobikeshare: {
            provider: require('../images/bikes/metrobikeshare/metro.jpg'),
            pin: {
                full: require('../images/bikes/metrobikeshare/pin/metro_full.png'),
                empty: require('../images/bikes/metrobikeshare/pin/metro_empty.png'),
            },
        },
        bird: {
            provider: require('../images/bikes/bird/bird.png'),
            pin: {
                full: require('../images/bikes/bird/pin/bird_pin.png'),
                empty: require('../images/bikes/bird/pin/bird_full.png'),
            },
        },
        lime: {
            provider: require('../images/bikes/lime/lime.png'),
            pin: {
                full: require('../images/bikes/lime/pin/lime_pin.png'),
                empty: require('../images/bikes/lime/pin/lime_full.png'),
            },
        },
        route: {
            biking: require('../images/bikes/route/biking.png'),
            dots: require('../images/bikes/route/dots.png'),
            line: require('../images/bikes/route/line.png'),
            location: require('../images/bikes/route/location.png'),
            pin: require('../images/bikes/route/pin.png'),
            pointEnd: require('../images/bikes/route/point_end.png'),
            pointStart: require('../images/bikes/route/point_start.png'),
            walking: require('../images/bikes/route/walking.png'),
        },
    },
    cards: {
        amex: require('../images/cards/card_amex.png'),
        diners: require('../images/cards/card_dinners.png'),
        discover: require('../images/cards/card_discover.png'),
        jcb: require('../images/cards/card_jcb.png'),
        mastercard: require('../images/cards/card_mastercard.png'),
        visa: require('../images/cards/card_visa.png'),
    },
    driverRating: {
        starGold: require('../images/driverRating/star_gold.png'),
        starGray: require('../images/driverRating/star_gray.png'),
    },
    help: {
        arrowRight: require('../images/help/arrow_right.png'),
        helpCenter: require('../images/help/help_center.png'),
        contact: require('../images/help/contact.png'),
        privacyPolicy: require('../images/help/privacy_policy.png'),
        termsOfService: require('../images/help/terms_of_service.png'),
    },
    icons: {
        newProvider: require('../images/icons/learn_about_new_provider.png'),
        back: require('../images/icons/back.png'),
        bellhopLogo: require('../images/icons/bellhop_logo.png'),
        bookNow: require('../images/icons/book_now.png'),
        car: require('../images/icons/car.png'),
        centerMap: require('../images/icons/centermap.png'),
        chromeDots: require('../images/icons/chrome_dots.png'),
        closeBikeView: require('../images/icons/close_bike_view.png'),
        connection: require('../images/icons/connection.png'),
        countryArrow: require('../images/icons/country_arrow.png'),
        creditCard: require('../images/icons/credit_card.png'),
        defaultAvatar: require('../images/icons/default_avatar.png'),
        destinationHome: require('../images/icons/destination_home.png'),
        destinationWork: require('../images/icons/destination_work.png'),
        distanceGray: require('../images/icons/distance_gray.png'),
        dots: require('../images/icons/dots.png'),
        drawerArrow: require('../images/icons/drawer_arrow.png'),
        drawerStar: require('../images/icons/drawer_star.png'),
        driver: require('../images/icons/new_car.png'),
        durationGray: require('../images/icons/duration_gray.png'),
        externalLinkBlack: require('../images/icons/external_black.png'),
        fancyDot: require('../images/icons/fancy_dot.png'),
        filter: require('../images/icons/filter.png'),
        greyPin: require('../images/icons/greypin.png'),
        information: require('../images/icons/information.png'),
        locationDot: require('../images/icons/locationDot.png'),
        locationGrey: require('../images/icons/location_grey.png'),
        markerDrop: require('../images/icons/drop.png'),
        markerPickup: require('../images/icons/pickup.png'),
        menu: require('../images/icons/menu.png'),
        options: require('../images/icons/options.png'),
        phone: require('../images/icons/phone.png'),
        pickupMarker: require('../images/icons/pickup_marker.png'),
        refresh: require('../images/icons/refresh.png'),
        rideCost: require('../images/icons/ride_cost.png'),
        rideDate: require('../images/icons/ride_date.png'),
        rideDistance: require('../images/icons/ride_distance.png'),
        ridesClock: require('../images/icons/rides_clock.png'),
        route: require('../images/icons/route.png'),
        showAll: require('../images/icons/show_all_arrow.png'),
        hideAll: require('../images/icons/hide_all_arrow.png'),
        sortAscending: require('../images/icons/sort_ascending_arrow.png'),
        sortDescending: require('../images/icons/sort_descending_arrow.png'),
        safariMenu: require('../images/icons/safari_menu.png'),
        safariAddToHomescreen: require('../images/icons/safari_add_to_homescreen.png'),
        seats: require('../images/icons/seats.png'),
        starGold: require('../images/icons/star_gold.png'),
        switch: require('../images/icons/switch.png'),
        timeGray: require('../images/icons/time_gray.png'),
    },
    inviteFriends: {
        email: require('../images/inviteFriends/email.png'),
        facebook: require('../images/inviteFriends/facebook.png'),
        inviteFriends: require('../images/inviteFriends/invite_friends.png'),
        link: require('../images/inviteFriends/link.png'),
        more: require('../images/inviteFriends/more.png'),
        referralCounter: require('../images/inviteFriends/referral_counter.png'),
        select: require('../images/inviteFriends/select.png'),
        sms: require('../images/inviteFriends/sms.png'),
        twitter: require('../images/inviteFriends/twitter.png'),
    },
    landing: {
        arrowActive: require('../images/landing/arrow_active.png'),
        arrowInactive: require('../images/landing/arrow_inactive.png'),
        correct: require('../images/landing/correct.png'),
        farewellIcon: require('../images/landing/farewell.png'),
        logo: require('../images/landing/logo.png'),
        Purple: require('../images/landing/logo_purple.png'),
        poweredBy: require('../images/landing/powered_by.png'),
    },
    menu: {
        inviteFriends: require('../images/menu/invite_friends.png'),
        logout: require('../images/menu/log_out.png'),
        providers: require('../images/menu/providers.png'),
        tripHistory: require('../images/menu/trip_history.png'),
        userImage: require('../images/menu/user_image.png'),
    },
    mapLanding: {
        current: require('../images/mapLanding/current.png'),
        destination: require('../images/mapLanding/destination.png'),
        empty: require('../images/mapLanding/empty.png'),
        favorite: require('../images/mapLanding/favorite.png'),
        home: require('../images/mapLanding/home.png'),
        pickup: require('../images/mapLanding/pickup.png'),
        pin: require('../images/mapLanding/current_pin.png'),
        recent: require('../images/mapLanding/recent.png'),
        search: require('../images/mapLanding/search.png'),
        select: require('../images/mapLanding/select.png'),
        suggestion: require('../images/mapLanding/suggestion.png'),
    },
    onboarding: {
        taxisComparation: require('../images/onboarding/taxis_comparation.png'),
        plane: require('../images/onboarding/plane.png'),
        train: require('../images/onboarding/train.png'),
    },
    options: {
        closeOrange: require('../images/options/close_orange.png'),
        modeRides: require('../images/options/mode_rides.png'),
        modeBikes: require('../images/options/mode_bikes.png'),
        modeScooters: require('../images/options/mode_scooters.png'),
    },
    popups: {
        bubbles: require('../images/popups/bg_bubbles.png'),
        button: require('../images/popups/bg_gradient.png'),
        location: require('../images/popups/location.png'),
        notification: require('../images/popups/notification.png'),
        skip: require('../images/popups/skip.png'),
    },
    predictions: {
        up: require('../images/predictions/up.png'),
        down: require('../images/predictions/down.png'),
        same: require('../images/predictions/same.png'),
        alert: require('../images/predictions/alert.png'),
    },
    providers: {
        addisonLee: require('../images/providers/addison_lee.png'),
        arecibo: require('../images/providers/arecibo.png'),
        arro: require('../images/providers/arro.png'),
        beat: require('../images/providers/beat.png'),
        bolt: require('../images/providers/bolt.jpg'),
        carmel: require('../images/providers/carmel.jpg'),
        cabify: require('../images/providers/cabify.png'),
        curb: require('../images/providers/curb.png'),
        didi: require('../images/providers/didi.png'),
        easytaxi: require('../images/providers/easytaxi.png'),
        flywheel: require('../images/providers/flywheel.png'),
        freeNow: require('../images/providers/free_now.png'),
        gett: require('../images/providers/gett.png'),
        grab: require('../images/providers/grab.jpg'),
        gojek: require('../images/providers/gojek.jpg'),
        juno: require('../images/providers/juno.png'),
        kabbee: require('../images/providers/kabbee.png'),
        kapten: require('../images/providers/kapten.png'),
        lecab: require('../images/providers/lecab.png'),
        lyft: require('../images/providers/lyft.png'),
        ola: require('../images/providers/ola.png'),
        rideAustin: require('../images/providers/ride_austin.jpg'),
        taxis99: require('../images/providers/taxis99.png'),
        uber: require('../images/providers/uber.png'),
        via: require('../images/providers/via.jpg'),
        waave: require('../images/providers/waave.png'),
        yandex: require('../images/providers/yandex.png'),
    },
    savings: {
        background: require('../images/savings/background.png'),
        blueRectangle: require('../images/savings/savings_blue.png'),
        distance: require('../images/savings/total_distance.png'),
        icon: require('../images/savings/savings_icon.png'),
        money: require('../images/savings/money_saved.png'),
        pinkRectangle: require('../images/savings/savings_pink.png'),
        time: require('../images/savings/time_Saving.png'),
    },
    services: {
        arro: require('../images/services/arro.png'),
        arroDisconnected: require('../images/services/arro_disconnected.png'),
        bolt: require('../images/services/bolt.png'),
        boltDisconnected: require('../images/services/bolt_disconnected.png'),
        check: require('../images/services/checkmark.png'),
        close: require('../images/services/close.png'),
        curb: require('../images/services/curb.png'),
        curbDisconnected: require('../images/services/curb_disconnected.png'),
        lyft: require('../images/services/lyft.png'),
        lyftDisconnected: require('../images/services/lyft_disconnected.png'),
        plus: require('../images/services/plus.png'),
        uber: require('../images/services/uber.png'),
        uberDisconnected: require('../images/services/uber_disconnected.png'),
        userImage: require('../images/services/userImage.png'),
        via: require('../images/services/via.png'),
        viaDisconnected: require('../images/services/via_disconnected.png'),
        link: require('../images/services/link.png'),
        linked: require('../images/services/linked.png'),
        available: require('../images/services/available.png'),
        unavailable: require('../images/services/unavailable.png'),
    },
    settings: {
        car: require('../images/settings/car.png'),
        close: require('../images/settings/close.png'),
        connect: require('../images/settings/connect.png'),
        currency: require('../images/settings/currency.png'),
        email: require('../images/settings/email.png'),
        faq: require('../images/settings/help.png'),
        globe: require('../images/settings/globe.png'),
        home: require('../images/settings/home.png'),
        language: require('../images/settings/language.png'),
        name: require('../images/settings/name.png'),
        notifications: require('../images/settings/notifications.png'),
        phone: require('../images/settings/phone.png'),
        privacy: require('../images/settings/privacy.png'),
        privacyPolicy: require('../images/settings/privacy_policy.png'),
        rightArrow: require('../images/settings/arrow.png'),
        terms: require('../images/settings/terms.png'),
        work: require('../images/settings/work.png'),
    },
    tooltip: {
        close: require('../images/tooltip/close.png'),
        info: require('../images/tooltip/info.png'),
        closeScreen: require('../images/tooltip/close.png'),
        dollar: require('../images/tooltip/dollar.png'),
        circle: require('../images/tooltip/circle.png'),
    },
    battery: require('../images/battery.png'),
    bellhopIcon: require('../images/bellhop_icon.png'),
    bellhopIcon2: require('../images/bellhop_icon_2.png'),
    bellhopLogo: require('../images/bellhop_logo.png'),
    check: require('../images/check.png'),
    dollar: require('../images/dollar.png'),
    forwardArrow: require('../images/forward_arrow.png'),
    gradientButton: require('../images/gradient_button.png'),
    mapBackground: require('../images/map_background.png'),
    pickupArrow: require('../images/pickup_arrow.png'),
    pickupArrowGrey: require('../images/pickup_arrow_grey.png'),
    pickupArrowWhite: require('../images/pickup_arrow_white.png'),
    pickupGradient: require('../images/bg_pickup_gradient.png'),
    stockAvatar: require('../images/stockAvatar.png'),
    stockCar: require('../images/stock_car.png'),
    topGradient: require('../images/top_gradient.png'),
    flags: {
        ad: require('../images/flags/ad.png'),
        ae: require('../images/flags/ae.png'),
        af: require('../images/flags/af.png'),
        ag: require('../images/flags/ag.png'),
        ai: require('../images/flags/ai.png'),
        al: require('../images/flags/al.png'),
        am: require('../images/flags/am.png'),
        ao: require('../images/flags/ao.png'),
        ar: require('../images/flags/ar.png'),
        at: require('../images/flags/at.png'),
        au: require('../images/flags/au.png'),
        aw: require('../images/flags/aw.png'),
        ax: require('../images/flags/ax.png'),
        az: require('../images/flags/az.png'),
        ba: require('../images/flags/ba.png'),
        bb: require('../images/flags/bb.png'),
        bd: require('../images/flags/bd.png'),
        be: require('../images/flags/be.png'),
        bf: require('../images/flags/bf.png'),
        bg: require('../images/flags/bg.png'),
        bh: require('../images/flags/bh.png'),
        bi: require('../images/flags/bi.png'),
        bj: require('../images/flags/bj.png'),
        bm: require('../images/flags/bm.png'),
        bn: require('../images/flags/bn.png'),
        bo: require('../images/flags/bo.png'),
        br: require('../images/flags/br.png'),
        bs: require('../images/flags/bs.png'),
        bt: require('../images/flags/bt.png'),
        bw: require('../images/flags/bw.png'),
        by: require('../images/flags/by.png'),
        bz: require('../images/flags/bz.png'),
        ca: require('../images/flags/ca.png'),
        cc: require('../images/flags/cc.png'),
        cd: require('../images/flags/cd.png'),
        cf: require('../images/flags/cf.png'),
        ch: require('../images/flags/ch.png'),
        ck: require('../images/flags/ck.png'),
        cl: require('../images/flags/cl.png'),
        cm: require('../images/flags/cm.png'),
        cn: require('../images/flags/cn.png'),
        co: require('../images/flags/co.png'),
        cr: require('../images/flags/cr.png'),
        cu: require('../images/flags/cu.png'),
        cv: require('../images/flags/cv.png'),
        cx: require('../images/flags/cx.png'),
        cy: require('../images/flags/cy.png'),
        cz: require('../images/flags/cz.png'),
        de: require('../images/flags/de.png'),
        dj: require('../images/flags/dj.png'),
        dk: require('../images/flags/dk.png'),
        dm: require('../images/flags/dm.png'),
        do: require('../images/flags/do.png'),
        dz: require('../images/flags/dz.png'),
        ec: require('../images/flags/ec.png'),
        ee: require('../images/flags/ee.png'),
        eg: require('../images/flags/eg.png'),
        er: require('../images/flags/er.png'),
        es: require('../images/flags/es.png'),
        et: require('../images/flags/et.png'),
        fi: require('../images/flags/fi.png'),
        fj: require('../images/flags/fj.png'),
        fk: require('../images/flags/fk.png'),
        fm: require('../images/flags/fm.png'),
        fo: require('../images/flags/fo.png'),
        fr: require('../images/flags/fr.png'),
        ga: require('../images/flags/ga.png'),
        gb: require('../images/flags/gb.png'),
        gd: require('../images/flags/gd.png'),
        ge: require('../images/flags/ge.png'),
        gh: require('../images/flags/gh.png'),
        gi: require('../images/flags/gi.png'),
        gl: require('../images/flags/gl.png'),
        gm: require('../images/flags/gm.png'),
        gn: require('../images/flags/gn.png'),
        gr: require('../images/flags/gr.png'),
        gt: require('../images/flags/gt.png'),
        gu: require('../images/flags/gu.png'),
        gw: require('../images/flags/gw.png'),
        hk: require('../images/flags/hk.png'),
        hn: require('../images/flags/hn.png'),
        hr: require('../images/flags/hr.png'),
        ht: require('../images/flags/ht.png'),
        hu: require('../images/flags/hu.png'),
        id: require('../images/flags/id.png'),
        ie: require('../images/flags/ie.png'),
        il: require('../images/flags/il.png'),
        in: require('../images/flags/in.png'),
        io: require('../images/flags/io.png'),
        iq: require('../images/flags/iq.png'),
        ir: require('../images/flags/ir.png'),
        is: require('../images/flags/is.png'),
        it: require('../images/flags/it.png'),
        jm: require('../images/flags/jm.png'),
        jo: require('../images/flags/jo.png'),
        jp: require('../images/flags/jp.png'),
        ke: require('../images/flags/ke.png'),
        kg: require('../images/flags/kg.png'),
        kh: require('../images/flags/kh.png'),
        ki: require('../images/flags/ki.png'),
        km: require('../images/flags/km.png'),
        kn: require('../images/flags/kn.png'),
        kr: require('../images/flags/kr.png'),
        ky: require('../images/flags/ky.png'),
        kz: require('../images/flags/kz.png'),
        la: require('../images/flags/la.png'),
        lb: require('../images/flags/lb.png'),
        lc: require('../images/flags/lc.png'),
        li: require('../images/flags/li.png'),
        lk: require('../images/flags/lk.png'),
        lr: require('../images/flags/lr.png'),
        ls: require('../images/flags/ls.png'),
        lt: require('../images/flags/lt.png'),
        lu: require('../images/flags/lu.png'),
        lv: require('../images/flags/lv.png'),
        ly: require('../images/flags/ly.png'),
        ma: require('../images/flags/ma.png'),
        mc: require('../images/flags/mc.png'),
        md: require('../images/flags/md.png'),
        mg: require('../images/flags/mg.png'),
        mh: require('../images/flags/mh.png'),
        mk: require('../images/flags/mk.png'),
        ml: require('../images/flags/ml.png'),
        mm: require('../images/flags/mm.png'),
        mn: require('../images/flags/mn.png'),
        mo: require('../images/flags/mo.png'),
        mp: require('../images/flags/mp.png'),
        mq: require('../images/flags/mq.png'),
        mr: require('../images/flags/mr.png'),
        ms: require('../images/flags/ms.png'),
        mt: require('../images/flags/mt.png'),
        mu: require('../images/flags/mu.png'),
        mv: require('../images/flags/mv.png'),
        mw: require('../images/flags/mw.png'),
        mx: require('../images/flags/mx.png'),
        mz: require('../images/flags/mz.png'),
        na: require('../images/flags/na.png'),
        ne: require('../images/flags/ne.png'),
        nf: require('../images/flags/nf.png'),
        ng: require('../images/flags/ng.png'),
        nl: require('../images/flags/nl.png'),
        no: require('../images/flags/no.png'),
        np: require('../images/flags/np.png'),
        nr: require('../images/flags/nr.png'),
        nu: require('../images/flags/nu.png'),
        nz: require('../images/flags/nz.png'),
        om: require('../images/flags/om.png'),
        pa: require('../images/flags/pa.png'),
        pe: require('../images/flags/pe.png'),
        pf: require('../images/flags/pf.png'),
        pg: require('../images/flags/pg.png'),
        ph: require('../images/flags/ph.png'),
        pk: require('../images/flags/pk.png'),
        pl: require('../images/flags/pl.png'),
        pn: require('../images/flags/pn.png'),
        pr: require('../images/flags/pr.png'),
        pt: require('../images/flags/pt.png'),
        pw: require('../images/flags/pw.png'),
        py: require('../images/flags/py.png'),
        ro: require('../images/flags/ro.png'),
        ru: require('../images/flags/ru.png'),
        rw: require('../images/flags/rw.png'),
        sa: require('../images/flags/sa.png'),
        sb: require('../images/flags/sb.png'),
        sc: require('../images/flags/sc.png'),
        sd: require('../images/flags/sd.png'),
        se: require('../images/flags/se.png'),
        sg: require('../images/flags/sg.png'),
        si: require('../images/flags/si.png'),
        sk: require('../images/flags/sk.png'),
        sl: require('../images/flags/sl.png'),
        sm: require('../images/flags/sm.png'),
        sn: require('../images/flags/sn.png'),
        so: require('../images/flags/so.png'),
        sr: require('../images/flags/sr.png'),
        st: require('../images/flags/st.png'),
        sv: require('../images/flags/sv.png'),
        sz: require('../images/flags/sz.png'),
        tc: require('../images/flags/tc.png'),
        td: require('../images/flags/td.png'),
        tg: require('../images/flags/tg.png'),
        th: require('../images/flags/th.png'),
        tj: require('../images/flags/tj.png'),
        tk: require('../images/flags/tk.png'),
        tm: require('../images/flags/tm.png'),
        tn: require('../images/flags/tn.png'),
        to: require('../images/flags/to.png'),
        tr: require('../images/flags/tr.png'),
        tt: require('../images/flags/tt.png'),
        tv: require('../images/flags/tv.png'),
        tw: require('../images/flags/tw.png'),
        tz: require('../images/flags/tz.png'),
        ua: require('../images/flags/ua.png'),
        ug: require('../images/flags/ug.png'),
        us: require('../images/flags/us.png'),
        uy: require('../images/flags/uy.png'),
        uz: require('../images/flags/uz.png'),
        va: require('../images/flags/va.png'),
        vc: require('../images/flags/vc.png'),
        ve: require('../images/flags/ve.png'),
        vg: require('../images/flags/vg.png'),
        vi: require('../images/flags/vi.png'),
        vn: require('../images/flags/vn.png'),
        vu: require('../images/flags/vu.png'),
        ws: require('../images/flags/ws.png'),
        ye: require('../images/flags/ye.png'),
        za: require('../images/flags/za.png'),
        zm: require('../images/flags/zm.png'),
        zw: require('../images/flags/zw.png'),
    },
};

export default images;

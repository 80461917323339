import { StyleSheet } from 'react-native';
import { Colors, Metrics, Fonts } from '../../../themes';

export default StyleSheet.create({
    container: {
        backgroundColor: 'white',
        borderRadius: 16,
        alignItems: 'center',
        paddingBottom: 10,
    },
    closeButton: {
        alignSelf: 'flex-end',
    },
    closeButtonImage: {
        alignSelf: 'flex-end',
        margin: 20,
    },
    linkAccountsText: {
        textAlign: 'center',
        fontFamily: Fonts.type.base,
        fontWeight: '700',
        fontSize: 20,
        marginVertical: 14,
    },
    nextLevelText: {
        textAlign: 'center',
        color: Colors.grayTextColor,
        fontSize: 15,
        marginHorizontal: 10,
    },
    servicesList: {
        flex: 1,
        alignSelf: 'stretch',
        marginVertical: 8,
        marginHorizontal: 20,
    },
    notNowButton: {
        alignSelf: 'stretch',
        marginHorizontal: 15,
        alignItems: 'center',
        justifyContent: 'center',
        paddingVertical: 15,
        backgroundColor: Colors.obiTeal,
        borderRadius: 8,
    },
    notNowText: {
        color: '#fff',
        fontSize: Metrics.normalize(18),
        fontFamily: Fonts.type.base,
        fontWeight: '400',
    },
    separator: {
        height: 10,
    },
    dontShowButton: {
        alignItems: 'center',
        alignSelf: 'stretch',
        backgroundColor: Colors.transparent,
        justifyContent: 'center',
        paddingVertical: 15,
    },
    dontShowText: {
        color: Colors.darkTextColor,
        fontSize: Metrics.normalize(11),
        fontFamily: Fonts.type.base,
        fontWeight: '300',
    },
});

import React, { Component } from 'react';
import { View } from 'react-native';
import Modal from 'modules/modal/modal';
import { observer, inject } from 'mobx-react';

import ModalStore from 'stores/ModalStore';
import ModalStyles from './ModalStyles';
import { ModalButton, ModalText, ModalTitle } from './modalElements';
import { Stores } from 'stores/RootStore';

interface Props {
    modalStore: ModalStore;
}

@inject(({ modalStore }: Stores) => ({
    modalStore,
}))
@observer
class MyModal extends Component<Props, {}> {
    getElementForType = ({ type, ...props }, index: number) => {
        switch (type) {
            case 'title': {
                return <ModalTitle key={`${type}${props.text.slice(0, 4)}  ${index}`} {...props} />;
            }
            case 'text': {
                return <ModalText key={`${type}${props.text.slice(0, 4)}  ${index}`} {...props} />;
            }
            case 'button': {
                return (
                    <ModalButton key={`${type}${props.text.slice(0, 4)}  ${index}`} {...props} />
                );
            }
            default: {
                return <View key={`${type}${props.text.slice(0, 4)}  ${index}`} />;
            }
        }
    };

    render() {
        const {
            modalStore: { modalVisible, hideModal, modalSections },
        } = this.props;
        return (
            <Modal
                // animationIn="zoomInDown"
                // animationOut="zoomOutUp"
                hideModalContentWhileAnimating
                isVisible={modalVisible}
                onBackdropPress={hideModal}
                style={ModalStyles.container}
            >
                <View style={ModalStyles.contentContainer}>
                    {modalSections.map(this.getElementForType)}
                </View>
            </Modal>
        );
    }
}
export default MyModal;

/* eslint-disable no-underscore-dangle */
import React, { Component, RefObject } from 'react';
import Interactable from 'react-native-interactable';
import { observer, inject } from 'mobx-react';
import {
    View,
    TouchableOpacity,
    Image,
    Animated,
    LayoutAnimation,
    FlatList,
    Platform,
    StyleSheet,
} from 'react-native';

import BikeStore from '../../../stores/BikeStore';
import BottomDrawer, { Snap, YID } from '../../drawer/BottomDrawer';
import CenterMapButton from '../../map/centerMapButton/CenterMapButton';
import ComparationList from './list/ComparationList';
import FiltersStore from '../../../stores/FiltersStore';
import GroupedComparationList from './list/groupedComparationList/GroupedComparationList';
import LayoutStore from '../../../stores/LayoutStore';
import PriceRefresh from './header/PriceRefresh';
import ServicesStore from '../../../stores/ServicesStore';
import SortTypeButton from './header/SortTypeButton';
import TaxiStore from '../../../stores/TaxiStore';
import TaxisComparationStyles from './styles/TaxisComparationStyles';
import { Images, Metrics } from '../../../themes';
import { SelectedTypesTab } from '../../../stores/dataStores/taxiResultsStore/TaxisComparationStore';
import { Stores } from '../../../stores/RootStore';
import { observable } from 'mobx';
import { AddressStore } from 'stores';
import { Analytics } from 'lib';

interface Props {
    addressStore: AddressStore;
    bikeStore: BikeStore;
    filtersStore: FiltersStore;
    layoutStore: LayoutStore;
    servicesStore: ServicesStore;
    taxiStore: TaxiStore;
}

interface State {
    height: number;
}

@inject(
    ({ addressStore, servicesStore, filtersStore, layoutStore, taxiStore, bikeStore }: Stores) => ({
        addressStore,
        bikeStore,
        filtersStore,
        layoutStore,
        servicesStore,
        taxiStore,
    })
)
@observer
export default class TaxisComparation extends Component<Props, State> {
    @observable.ref
    flatListRef: RefObject<FlatList<any>> = React.createRef();

    @observable.ref
    bottomDrawerRef: RefObject<BottomDrawer> = React.createRef();

    @observable
    currentSnap: Snap = 'down';

    bottomDrawerSnapPoints: Array<YID> = [
        { y: 150, id: 'up' },
        { y: this.props.layoutStore.screenHeight - 350, id: 'down' },
        { y: this.props.layoutStore.screenHeight, id: 'closed' },
    ];

    bottomDrawerInitialPoint: number = this.bottomDrawerSnapPoints[2].y;

    deltaY: Animated.Value = new Animated.Value(this.bottomDrawerSnapPoints[2].y);

    bottomDrawerInterpolation: any = {
        inputRange: this.bottomDrawerSnapPoints.map(point => point.y),
        outputRange: [0.6, 0.2],
    };

    bottomDrawerBoundaries: Interactable.IBoundaries = {
        bottom: Metrics.screenHeight,
        top: this.bottomDrawerSnapPoints[0].y,
    };

    state: { height: number } = {
        height: this.props.layoutStore.screenHeight - 350,
    };

    componentDidMount() {
        const {
            addressStore,
            servicesStore,
            servicesStore: { toggleServicesModal, setShouldShowServicesModal },
        } = this.props;
        if (servicesStore.shouldShowServicesModal) {
            toggleServicesModal();
        } else {
            setShouldShowServicesModal();
        }
        Analytics.trackScreenView(`02-03_Compare${addressStore.isNewYork ? '_Grouped' : ''}`);
    }

    onDrag = (event: Interactable.IDragEvent) => {
        const {
            nativeEvent: { state, y },
        } = event;
        if (state && state === 'start') {
            const { height } = this.state;
            const { layoutStore } = this.props;
            const intH = Math.abs(layoutStore.screenHeight - this.bottomDrawerSnapPoints[0].y);
            if (height !== intH) {
                this.setState({ height: intH });
            }
        }
    };

    onSnap = (event: Interactable.ISnapEvent) => {
        const { height } = this.state;
        const { layoutStore } = this.props;
        const {
            nativeEvent: { index, id },
        } = event;
        const intH = Math.abs(layoutStore.screenHeight - this.bottomDrawerSnapPoints[index].y);
        if (height !== intH) {
            this.setState({ height: intH });
        }
    };

    onSnapStart = (event: Interactable.ISnapEvent) => {
        const {
            nativeEvent: { id },
        } = event;
        if (
            this.bottomDrawerRef.current &&
            this.bottomDrawerRef.current.wrappedInstance &&
            this.bottomDrawerRef.current.wrappedInstance.snapView.current &&
            id === 'closed'
        ) {
            this.bottomDrawerRef.current.wrappedInstance.snapView.current.snapTo({ index: 1 });
        }
    };

    selectTypeButton = (type: SelectedTypesTab) => {
        const {
            taxiStore: {
                taxiResultsStore: {
                    taxisComparationStore: { typesTabSelected },
                },
            },
        } = this.props;
        LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
        typesTabSelected(type);
    };

    selectCheapest = () => {
        const {
            taxiStore: {
                taxiResultsStore: {
                    taxisComparationStore: { selectedTypesTab, flatListRef },
                },
            },
        } = this.props;
        if (selectedTypesTab.sort === 'cheapest' && selectedTypesTab.type === 'ascending') {
            this.selectTypeButton({ sort: 'cheapest', type: 'descending' });
        } else if (selectedTypesTab.sort === 'cheapest' && selectedTypesTab.type === 'descending') {
            this.selectTypeButton({ sort: 'cheapest', type: 'ascending' });
        } else {
            this.selectTypeButton({ sort: 'cheapest', type: 'ascending' });
            if (flatListRef.current) {
                flatListRef.current.scrollToOffset({
                    animated: false,
                    offset: 0,
                });
            }
        }
    };

    selectFastest = () => {
        const {
            taxiStore: {
                taxiResultsStore: {
                    taxisComparationStore: { selectedTypesTab, flatListRef },
                },
            },
        } = this.props;
        if (selectedTypesTab.sort === 'fastest' && selectedTypesTab.type === 'ascending') {
            this.selectTypeButton({ sort: 'fastest', type: 'descending' });
        } else if (selectedTypesTab.sort === 'fastest' && selectedTypesTab.type === 'descending') {
            this.selectTypeButton({ sort: 'fastest', type: 'ascending' });
        } else {
            this.selectTypeButton({ sort: 'fastest', type: 'ascending' });
            if (flatListRef.current) {
                flatListRef.current.scrollToOffset({
                    animated: false,
                    offset: 0,
                });
            }
        }
    };

    render() {
        const {
            addressStore,
            taxiStore: { taxiResultsStore },
            filtersStore: { toggleFiltersPopup },
            layoutStore: { screenHeight },
        } = this.props;
        const { taxisComparationStore } = taxiResultsStore;
        const { height } = this.state;
        return (
            <View style={StyleSheet.absoluteFill} pointerEvents="box-none">
                <CenterMapButton
                    bottom={this.bottomDrawerInitialPoint + 10}
                    inputRange={[0, screenHeight - 160]}
                    outputRange={[0, screenHeight - 160]}
                    deltaY={this.deltaY}
                />
                <BottomDrawer
                    boundaries={{ bottom: screenHeight - 240, top: 150 }}
                    currentSnap={this.currentSnap}
                    deltaY={this.deltaY}
                    initialPoint={this.bottomDrawerInitialPoint}
                    onDrag={this.onDrag}
                    onSnap={this.onSnap}
                    onSnapStart={this.onSnapStart}
                    openAnimated={{ toIndex: 1 }}
                    ref={this.bottomDrawerRef}
                    snapPoints={this.bottomDrawerSnapPoints}
                    withBackgroundOpacity={false}
                >
                    <View style={[TaxisComparationStyles.interactable, { height }]}>
                        {Platform.OS !== 'web' && (
                            <View style={TaxisComparationStyles.indicator}>
                                <View style={TaxisComparationStyles.indicatorInner} />
                            </View>
                        )}
                        <View style={TaxisComparationStyles.interactableInner}>
                            <View style={TaxisComparationStyles.top}>
                                <View style={TaxisComparationStyles.comparationOptions}>
                                    <PriceRefresh />
                                    <View style={TaxisComparationStyles.buttonsContainer}>
                                        <SortTypeButton
                                            name="Cheapest"
                                            index={0}
                                            onPress={this.selectCheapest}
                                            selected={
                                                taxisComparationStore.selectedTypesTab.sort ===
                                                'cheapest'
                                            }
                                            ascending={
                                                taxisComparationStore.selectedTypesTab.type ===
                                                'ascending'
                                            }
                                        />
                                        <SortTypeButton
                                            name="Fastest"
                                            index={1}
                                            onPress={this.selectFastest}
                                            selected={
                                                taxisComparationStore.selectedTypesTab.sort ===
                                                'fastest'
                                            }
                                            ascending={
                                                taxisComparationStore.selectedTypesTab.type ===
                                                'ascending'
                                            }
                                        />
                                    </View>
                                    <TouchableOpacity
                                        style={[
                                            TaxisComparationStyles.sideButton,
                                            { marginLeft: 10 },
                                        ]}
                                        onPress={() => toggleFiltersPopup(true)}
                                    >
                                        <Image
                                            source={Images.icons.filter}
                                            resizeMode="contain"
                                            style={TaxisComparationStyles.sideImage}
                                        />
                                    </TouchableOpacity>
                                </View>
                            </View>
                            {addressStore.isNewYork ? (
                                <GroupedComparationList />
                            ) : (
                                <ComparationList />
                            )}
                        </View>
                    </View>
                </BottomDrawer>
            </View>
        );
    }
}

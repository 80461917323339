import React from 'react';
import { View, Text, TouchableOpacity } from 'react-native';

import Modal from 'modules/modal/modal';

import LogoutModalStyles from './styles/LogoutModalStyles';
import { AuthStore } from 'stores';
import { Localizable } from 'lib';
import { useStores } from 'containers/App';

interface Props {
    authStore: AuthStore;
}

export default () => {
    const {
        stores: {
            authStore,
            authStore: { logout, dismissLogoutPopup },
        },
    } = useStores();
    return (
        <Modal
            animationIn="zoomIn"
            animationOut="zoomOut"
            isVisible={authStore.logoutPopupVisible}
            avoidKeyboard
        >
            <View style={LogoutModalStyles.container}>
                <View style={LogoutModalStyles.innerContainer}>
                    <Text style={LogoutModalStyles.logoutText}>
                        {Localizable.t('logoutModal.areYouSure')}
                    </Text>
                    <View style={LogoutModalStyles.separator} />
                    <View style={LogoutModalStyles.separator} />
                    <TouchableOpacity
                        style={LogoutModalStyles.noButton}
                        onPress={dismissLogoutPopup}
                    >
                        <Text style={LogoutModalStyles.noText}>{Localizable.t('no')}</Text>
                    </TouchableOpacity>
                    <View style={LogoutModalStyles.separator} />
                    <TouchableOpacity style={LogoutModalStyles.yesButton} onPress={logout}>
                        <Text style={LogoutModalStyles.yesText}>{Localizable.t('yes')}</Text>
                    </TouchableOpacity>
                </View>
            </View>
        </Modal>
    );
};

import React from 'react';
import { View, Text } from 'react-native';
import styles from './styles';

interface Props {
    text?: string;
    key: string;
}

const ModalTitle = ({ text }: Props) => (
    <View style={styles.container}>
        <Text style={styles.title}>{text}</Text>
    </View>
);

export default ModalTitle;

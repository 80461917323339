import React, { Component } from 'react';
import moment from 'moment';
import { SectionList, TouchableOpacity } from 'react-native';
import { observer } from 'mobx-react';

import TripSavingsItem from './item/TripSavingsItem';
import TripSavingsHeader from './header/TripSavingsHeader';
import TripSavingsListStyles from './TripSavingsListStyles';
import SavingsItem from 'domain/app/SavingsItem';

const getTitleForDate = (date: Date): string => {
    if (moment(date).isSame(moment(), 'day')) {
        return 'TODAY';
    }
    if (moment(date).isSame(moment().subtract(1, 'day'), 'day')) {
        return 'YESTERDAY';
    }
    return moment(date).format('dddd, MMMM Do YYYY');
};

interface Props {
    tripList: Array<{}>;
    onItemClick: Function;
}

@observer
export default class TripSavingsList extends Component<Props, {}> {
    getSectionsFromData = (data: Array<any>) =>
        data.reduce((prev, curr) => {
            if (!prev.length) {
                prev.push({ title: getTitleForDate(curr.date), data: [curr] });
                return prev;
            }
            const lastKnownDate = prev[prev.length - 1].data[0].date;
            if (moment(lastKnownDate).isSame(moment(curr.date), 'day')) {
                prev[prev.length - 1].data.push(curr);
                return prev;
            }
            prev.push({ title: getTitleForDate(curr.date), data: [curr] });
            return prev;
        }, []);

    renderItem = ({ item, index, section }: { item: SavingsItem; index: number; section: any }) => {
        const { onItemClick } = this.props;
        return (
            <TouchableOpacity
                onPress={() => {
                    onItemClick(item);
                }}
            >
                <TripSavingsItem item={item} index={index} section={section} />
            </TouchableOpacity>
        );
    };

    renderSectionHeader = ({ section }) => <TripSavingsHeader section={section} />;

    render() {
        const { tripList } = this.props;
        const sectionData = this.getSectionsFromData(tripList.slice());
        return (
            <SectionList
                style={TripSavingsListStyles.sectionList}
                sections={sectionData}
                renderItem={this.renderItem}
                renderSectionHeader={this.renderSectionHeader}
            />
        );
    }
}

import { StyleSheet } from 'react-native';
import { Colors, Metrics, Fonts } from '../../../../themes';

export default StyleSheet.create({
    itemContainer: {
        flexDirection: 'row',
        padding: 10,
        paddingVertical: Metrics.normalize(15),
        alignItems: 'center',
    },
    innerItemContainer: {
        flex: 1,
    },
    providerIcon: {
        width: Metrics.normalize(50),
        height: Metrics.normalize(50),
        borderRadius: Metrics.normalize(25),
        marginRight: Metrics.normalize(8),
    },
    distanceIcon: {
        width: Metrics.normalize(15),
        height: Metrics.normalize(15),
        marginRight: 4,
    },
    destinationText: {
        fontSize: Metrics.normalize(16),
        fontWeight: '400',
        color: Colors.black,
        marginBottom: 4,
        fontFamily: Fonts.type.base,
    },
    distanceText: {
        fontFamily: Fonts.type.base,
        fontSize: Metrics.normalize(13),
        fontWeight: '400',
        color: Colors.grayTextColor,
    },
    savedValueText: {
        fontSize: Metrics.normalize(18),
        fontWeight: '600',
        color: Colors.black,
        fontFamily: Fonts.type.base,
    },
    savedText: {
        fontSize: Metrics.normalize(14),
        fontWeight: '400',
        color: Colors.priceColor,
        fontFamily: Fonts.type.base,
    },
    distanceContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    savedContainer: {
        justifyContent: 'center',
        alignItems: 'flex-end',
    },
});

import React from 'react';
import { Text, TouchableOpacity, ImageBackground, View, ViewStyle } from 'react-native';

import GradientButtonStyles from './GradientButtonStyles';
import { Images, Colors } from '../../themes';

interface Props {
    onPress: () => void;
    title: string;
    style?: ViewStyle;
    active?: boolean;
}

const GradientButton = ({ onPress, title, style, active = true }: Props) => (
    <TouchableOpacity style={style} onPress={onPress} disabled={!active}>
        <View style={GradientButtonStyles.container}>
            <ImageBackground
                style={GradientButtonStyles.imageBackground}
                source={active ? Images.gradientButton : null}
            >
                <Text
                    style={[
                        GradientButtonStyles.title,
                        { color: active ? 'white' : Colors.grayTextColor },
                    ]}
                >
                    {title}
                </Text>
            </ImageBackground>
        </View>
    </TouchableOpacity>
);

GradientButton.defaultProps = {
    onPress: () => {},
    style: {},
};

export default GradientButton;

import { StyleSheet } from 'react-native';

export default StyleSheet.create({
    faresRefresh: {
        marginRight: 10,
        borderRadius: 21,
        width: 42,
        height: 42,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'white',
    },
    refreshImage: {
        width: 16,
        height: 16,
    },
});
